import { useContext, useEffect, useState } from "react";
import MapContext from "../MapContext";
import OLVectorLayer from "ol/layer/Vector";
import OLVectorSource from "ol/source/Vector";
import MapUtils from "../../../utils/MapUtils";
import LayerStyles from "../LayerStyles";

const VectorLayer = ({ features, style, zIndex = 0 }) => {
	const { map } = useContext(MapContext);
	const [layer, setLayer] = useState();

	useEffect(() => {
		if (!map) return;
		let vectorLayer = new OLVectorLayer({
			source: new OLVectorSource({ features: [] }),
			style
		});

		map.addLayer(vectorLayer);
		vectorLayer.setZIndex(zIndex);
		if (map.vectorLayer) {
			map.vectorLayer.push(vectorLayer);
		} else {
			map.vectorLayer = [vectorLayer];
		}

		setLayer(vectorLayer);

		return () => {
			if (map) {
				map.removeLayer(vectorLayer);
				setLayer(null);
			}
		};
	}, [map, style, zIndex]);

	useEffect(() => {
		if (layer) {
			layer.getSource().clear();
			if (features?.length) {
				const olFeatures = features.map(f => {
					const olFeature = MapUtils.toOLFeature(f);
					if (f.style) {
						olFeature.setStyle(LayerStyles[f.style]);
					}
					return olFeature;
				});
				layer.getSource().addFeatures(olFeatures);
			}
		}
	}, [layer, features]);

	return null;
};

export default VectorLayer;