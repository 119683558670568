import { TextField } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const getPatternValue = (pattern) => {
  if (pattern === 'phone') {
    return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
  }
  if (pattern === 'email') {
    return /^(("[\w-\s]+")|([\w-]+(?:.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
  }
  return pattern;
}

export default function VTextField({ name, label, register, errors, error, helperText,
  required, minLength, maxLength, pattern, className, ...rest }) {

  const { t } = useTranslation();

  let rules = {};
  if (required) {
    rules.required = { value: true, message: t('validation.required') };
  }
  if (minLength) {
    rules.minLength = { value: minLength, message: t('validation.short') }
  }
  if (maxLength) {
    rules.maxLength = { value: maxLength, message: t('validation.long') }
  }
  if (pattern) {
    rules.pattern = { value: getPatternValue(pattern), message: t('validation.invalid') }
  }

  if (!label && !!name) {
    label = `table.${name}`;
  }
  if (!error) {
    error = !!(errors && errors[name]);
  }
  if (!helperText) {
    helperText = errors && errors[name] && errors[name].message;
  }

  return <TextField
    name={name}
    label={t(label)}
    inputRef={!!register ? register(rules) : null}
    error={error}
    helperText={helperText}
    variant="outlined"
    className={"VaalTextField ".concat(className || '')}
    {...rest} />;
}