import { Box, makeStyles, Toolbar } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory, useParams, useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Heading, NavButton, Paragraph, TabContainer } from 'styleguide';
import { ApplicationStatus, ApplicationType, ClassifierType } from '../../constants/classifierConstants';
import { clearError, fetchSelected } from '../../stores/application/application';
import ApplicationUtils from '../../utils/ApplicationUtils';
import AuthUtils from '../../utils/AuthUtils';
import DateUtils from '../../utils/DateUtils';
import TableUtils from '../../utils/TableUtils';
import { CenteredLoadingIndicator } from '../form/LoadingIndicator';
import ScrollToTopOnMount from '../form/ScrollToTopOnMount';
import ApplicationActions from './ApplicationActions';
import ApplicationDetails from './ApplicationDetails';
import ApplicationStatusLabel from './ApplicationStatusLabel';
import { PlanInitiateForm, PlanInvalidateForm } from './plan/PlanDocumentForm';
import PlanDocumentTable from './plan/PlanDocumentTable';
import PlanLocationView from './plan/PlanLocationView';
import PlanPartyTable from './plan/PlanPartyTable';
import { ApplicationSendNotificationForm } from './plan/SendNotificationForm';
import { SigningForm } from './SigningView';

const useStyles = makeStyles((theme) => ({
  flex: {
    flexGrow: 1
  },
  header: {
    paddingLeft: 16
  },
  toolbar: {
    padding: 0
  },
}));

export default function ApplicationView({ mapView }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const compact = useSelector(state => state.global.mobileView) || mapView;
  const { path, url } = useRouteMatch();

  const application = useSelector(state => state.application.selected);
  const error = useSelector(state => state.application.error);
  const { classifiers } = useSelector(state => state.classifier);
  const authUser = useSelector(state => state.auth.authUser);

  const applicationId = application?.id;

  //fetch selected
  const shouldFetch = !applicationId || applicationId !== Number(id);
  useEffect(() => shouldFetch && dispatch(fetchSelected(id)),
    [shouldFetch, id, dispatch]);

  useEffect(() => {
    if(error) {
      history.length > 1 ? history.goBack() : history.replace('/');
      dispatch(clearError());
    }
  }, [error, history, dispatch]);

  const handleClose = () => {
    history.goBack();
  };

  if (!id || !application || shouldFetch || error) {
    return <CenteredLoadingIndicator />;
  }

  const title = t('application.view.header', { number: application.applicationNumber });
  const tabs = [<NavLink key="main" to={`${url}`} exact replace>{t('application.view.details')}</NavLink>];
  if ((ApplicationType.DP === application.applicationType || ApplicationType.YP === application.applicationType || ApplicationType.KT === application.applicationType) && (
    ApplicationStatus.processing === application.status || ApplicationUtils.isFinalized(application)
  )) {
    tabs.push(<NavLink key="location" to={`${url}/location`} replace>{t('plan.view.locations')}</NavLink>);
    tabs.push(<NavLink key="party" to={`${url}/party`} replace>{t('plan.view.parties')}</NavLink>);
    tabs.push(<NavLink key="document" to={`${url}/document`} replace>{t('plan.view.documents')}</NavLink>);
  }
  if (AuthUtils.isGov(authUser) || ApplicationUtils.isRelated(authUser, application)) {
    tabs.push(<NavLink key="action" to={`${url}/action`} replace>{t('application.view.actions')}</NavLink>);
  }

  const planReadOnly = ApplicationStatus.processing !== application.status || !ApplicationUtils.isHandler(authUser, application);

  return <div>
    <ScrollToTopOnMount />
    <Toolbar className={classes.toolbar}>
      <NavButton onClick={handleClose} action='back' color='secondary'></NavButton>
      <span className={classes.header}>
        <Heading level='3'>{title}</Heading>
        <Paragraph fontSize='14B'>{application.location}</Paragraph>
      </span>

      <span className={classes.flex}></span>

      <Box textAlign="right">
        <ApplicationStatusLabel statusClassifier={TableUtils.getClassifier(classifiers, ClassifierType.applicationStatus, application.status)} />
        {!!application.deadline && <Heading level='4'>
          {`${t('application.proceedingsDeadline')} ${DateUtils.formatDate(application.proceedingsDeadline)}`}
        </Heading>}
      </Box>
    </Toolbar>

    {(tabs.length > 1) &&
      <Box py={compact ? 2 : 5}>
        <TabContainer width='100%' size={compact ? 'small' : 'big'}>
          {tabs}
        </TabContainer>
      </Box>
    }

    <Route path={`${path}`} render={() => <ApplicationDetails title={title} />} exact />
    <Route path={`${path}/action`} render={() => <ApplicationActions />} />
    <Route path={`${path}/document`} render={() => <PlanDocumentTable compact={compact} readOnly={planReadOnly} />} />
    <Route path={`${path}/location`} render={() => <PlanLocationView readOnly={planReadOnly} />} />
    <Route path={`${path}/party`} render={() => <PlanPartyTable compact={compact} readOnly={planReadOnly} />} />
    <Route path={`${path}/sendNotification`} component={ApplicationSendNotificationForm} />
    <Route path={`${path}/initiate`} component={PlanInitiateForm} />
    <Route path={`${path}/invalidate`} component={PlanInvalidateForm} />
    <Route path={`${path}/sign`} render={() => <SigningForm application={application} />} />
  </div >;
}