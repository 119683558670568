import { Grid, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import DialogForm from '../form/DialogForm';
import VTextField from '../form/VTextField';
import { addClassifier, fetchClassifier, fetchClassifiers, invalidateClassifier, saveClassifier } from '../../stores/admin/classifier';
import ConfirmButton from '../table/ConfirmButton';

const useStyles = makeStyles({
  appBar: {
    position: 'relative',
  },
  formBlock: {
    maxWidth: 846,
    margin: 'auto',
    padding: 25
  },
  formDivider: {
    height: 8
  },
  status: {
    marginLeft: 20,
    verticalAlign: 'middle',
    display: 'inline-flex'
  }
});

export default function ClassifierForm() {
  const { t } = useTranslation();
  const { classifierId, typeCode } = useParams();
  const dispatch = useDispatch();

  const { types, classifier, isLoading } = useSelector(state => state.classifier);

  const { register, errors, handleSubmit, reset } = useForm({
    defaultValues: classifier
  });
  const classes = useStyles();
  const history = useHistory();

  const [shouldRefreshClassifiers, setShouldRefreshClassifiers] = useState(false);

  useEffect(() => dispatch(fetchClassifier(parseInt(classifierId), typeCode)), [dispatch, classifierId, typeCode]);
  useEffect(() => reset(classifier), [classifier, reset]);

  if (!classifier || !classifier.typeCode) {
    return <></>;
  }

  const type = types.find(t => t.code === classifier.typeCode);

  const onSubmit = (data) => {
    if (data.id) {
      dispatch(saveClassifier(data));
    } else {
      data.id = undefined;
      dispatch(addClassifier(data));
    }
    setShouldRefreshClassifiers(true);
  };

  const onInvalidate = () => {
    dispatch(invalidateClassifier(classifier)).then(() => {
      dispatch(fetchClassifiers());
      history.goBack();
    });
  };

  const onClose = () => {
    if (shouldRefreshClassifiers) {
      dispatch(fetchClassifiers());
    }
    return false;
  };

  const title = classifier.id ?
    t('admin.classifierForm.title', { type: type.title, code: classifier.itemCode }) :
    t('admin.classifierForm.titleNew', { type: type.title });

  return <DialogForm title={title} onSubmit={handleSubmit(onSubmit)} onClose={onClose} disabled={isLoading}
    actions={!!classifier.id && !!classifier.valid && !type?.readonly &&
      <ConfirmButton aria-label="set classifier invalid" color="tertiary"
        message={t('admin.classifierForm.confirmSetInvalid')}
        btnText={t('admin.classifierForm.invalidate')}
        disabled={isLoading}
        onConfirm={onInvalidate} />
    }>
    <input type="hidden" name="id" ref={register} />
    <input type="hidden" name="typeCode" ref={register} />
    <Paper elevation={0} className={classes.formBlock}>
      <Grid container direction="row" spacing={4} justifyContent="space-evenly" alignItems="baseline">
        <Grid container item lg={6} direction="column" spacing={4} alignItems="center">
          <Grid item sm={12}>
            <VTextField name="itemCode" required
              disabled={isLoading || !!classifier.id} maxLength={100}
              register={register} errors={errors}
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="title" required
              disabled={isLoading} maxLength={100}
              register={register} errors={errors}
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="titleEng"
              disabled={isLoading} maxLength={100}
              register={register} errors={errors}
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="description"
              disabled={isLoading} maxLength={100}
              register={register} errors={errors}
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="descriptionEng"
              disabled={isLoading} maxLength={100}
              register={register} errors={errors}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  </DialogForm>;
}