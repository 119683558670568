import { KeyboardDatePicker } from '@material-ui/pickers';
import { isValid } from 'date-fns';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Icon } from 'styleguide';

function DatePicker({ error, helperText, label, onChange, onBlur, value, name, ref, ...rest }) {
  return <KeyboardDatePicker
    disableToolbar
    error={error}
    helperText={helperText}
    autoOk
    format="dd.MM.yyyy"
    variant="inline"
    inputVariant="outlined"
    label={label}
    className="VaalTextField"
    keyboardIcon={<Icon icon='calendar' />}
    onChange={onChange}
    onBlur={onBlur}
    value={value}
    name={name}
    inputRef={ref}
    {...rest}
  />;
}

export default function VDatePicker({ item, name, label, control, errors, required, ...rest }) {
  const { t } = useTranslation();

  if (!control) {
    return <DatePicker
      label={t(label)}
      name={name}
      {...rest}
    />;
  }

  let rules = {
    validate: date => isValid(new Date(date))
  };
  if (required) {
    rules.required = { value: true, message: t('validation.required') };
  }

  if (!label) {
    label = `table.${name}`;
  }

  const getValidationMessage = (errors) => {
    if (errors && errors[name]) {
      return errors[name].type === 'validate' ? t('validation.invalid') : errors[name].message;
    }
  };

  return <Controller
    render={({ onChange, onBlur, value, name, ref }) => (
      <DatePicker
        error={!!(errors && errors[name])}
        helperText={getValidationMessage(errors)}
        label={t(label)}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        name={name}
        inputRef={ref}
        {...rest}
      />
    )}
    name={name}
    defaultValue={!!item ? item[name] || null : null}
    control={control}
    rules={rules}
  />;
}