import { RoleAuthorities, UserType } from "../constants/authConstants";

const AuthUtils = {
  hasAuthority(user, authority) {
    return user && user.authorities && user.authorities.includes(authority);
  },
  hasAnyAuthority(user, authorities) {
    return user && user.authorities && authorities && user.authorities.some(a => authorities.includes(a));
  },
  hasRole(user, role) {
    return role && RoleAuthorities[role] && this.hasAnyAuthority(user, RoleAuthorities[role]);
  },
  isAuthorised(user, authority, role) {
    if (role) {
      return this.hasRole(user, role);
    }
    if (Array.isArray(authority)) {
      return this.hasAnyAuthority(user, authority);
    }
    return (authority && this.hasAuthority(user, authority));
  },
  isCitizen: (user) => UserType.citizen === user?.type,
  isPartner: (user) => UserType.partner === user?.type,
  isGov: (user) => UserType.gov === user?.type,
  isSubstituter: (user, substitutedId) => user.substitutedUserIds?.includes(substitutedId),
  getDefaultDomain: (user, allowedDomains) => !!user?.domain && allowedDomains.includes(user.domain) ? user.domain : allowedDomains[0],
};
export default AuthUtils;