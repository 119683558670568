import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/admin';
import { MapConstants } from '../../constants/mapConstants';
import i18n from '../../i18n';
import MapUtils from '../../utils/MapUtils';
import RegisterUtils from '../../utils/RegisterUtils';
import StoreUtils from '../../utils/StoreUtils';

// Slice
const slice = createSlice({
  name: 'map',
  initialState: {
    layerDrawerOpen: true,
    zoom: MapConstants.initialZoom,
    center: MapConstants.initialCenter,
    extent: null,
    activeFeatures: [],
    layers: [],
    layerGroups: [],
    groupedWMSLayers: [],
    layersLoaded: false,
    activeControl: null,
    selectActive: true,
    wmtsCapabilities: {},
    vectorLayerTypes: [],
    activeDrawVectorLayer: null,
    isLoading: false,
    error: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    layerDrawerVisibilityChanged: (state, action) => {
      state.layerDrawerOpen = (action.payload !== undefined ? action.payload : !state.layerDrawerOpen);
    },
    layerGroupOpenChanged: (state, action) => {
      const group = findLayerGroup(state, action.payload.id);
      group.open = !group.open;
    },
    subGroupOpenChanged: (state, action) => {
      const subGroup = findSubGroup(state, action.payload.id);
      subGroup.open = !subGroup.open;
    },
    layerVisibilityChanged: (state, action) => {
      const layer = action.payload;
      if(state.activeDrawVectorLayer && layer.serviceType === "VECTOR" && state.activeDrawVectorLayer === layer.layerName){
        return;
      }
      setLayerVisibility(state, layer, !layer.visible);
      groupLayers(state);
    },
    layerGroupVisibilityChanged: (state, action) => {
      const group = findLayerGroup(state, action.payload.id);
      setGroupVisibility(state, group, !group.visible);
      groupLayers(state);
    },
    subGroupVisibilityChanged: (state, action) => {
      const subGroup = findSubGroup(state, action.payload.id);
      setSubGroupVisibility(state, subGroup, !subGroup.visible);
      groupLayers(state);
    },
    layersVisibilityChanged: (state, action) => {
      const registerObjectType = action.payload.registerObjectType;
      const domain = RegisterUtils.getObjectDef(registerObjectType).domain;
      if (domain) {
        const visibility = action.payload.visible;
        state.layerGroups
          .filter(g => g.domain === domain)
          .forEach(g => setGroupVisibility(state, g, visibility));
        state.layerGroups
          .forEach(g => g.subGroups
            .filter(sg => sg.domain === domain)
            .forEach(sg => setSubGroupVisibility(state, sg, visibility))
          );
        state.layers
          .filter(l => {
            if (l.domain === domain) {
              return true;
            }
            if (!!l.registerObjectType) {
              const objects = l.registerObjectType.split(',');
              return objects.some(o => o === registerObjectType);
            }
            return false;
          })
          .forEach(l => setLayerVisibility(state, l, visibility));

        groupLayers(state);
      }
    },
    activeControlChanged: (state, action) => {
      if (action.payload.active || (action.payload.active === undefined && state.activeControl !== action.payload.control)) {
        state.activeControl = action.payload.control;
        if (state.selectActive) {
          state.selectActive = false;
        }
      } else {
        state.activeControl = null;
        if (!state.selectActive) {
          state.selectActive = true;
        }
      }
    },
    selectActiveChanged: (state, action) => {
      if (action.payload !== state.selectActive) {
        state.selectActive = action.payload;
      }
    },
    layersSuccess: (state, action) => {
      const language = i18n.language;
      const layerTitleField = language === 'et-EE' ? 'titleEst' : 'titleEng';
      const groupTitleField = language === 'et-EE' ? 'title' : 'titleEng';

      const domain = action.payload?.user?.domain;
      if (action.payload?.data) {
        state.layers = action.payload?.data;
        state.layers.forEach(l => l.titleEst = l.title);
      }
      state.layerGroups = [];
      state.vectorLayerTypes = [];
      state.layers.forEach(layer => {
        layer.title = layer[layerTitleField];
        layer.layerLayerName = layer['layerName'];
        
        if(layer.serviceType === 'VECTOR') {
          state.vectorLayerTypes.push({
            name: layer.title,
            type: layer.layerLayerName
          });
        }

        let group, subGroup;
        if (layer.mapGroup.parentGroup) {
          group = layer.mapGroup.parentGroup;
          subGroup = layer.mapGroup;
        } else {
          group = layer.mapGroup;
        }

        if (group.domain && group.domain === domain) {
          group.visible = true;
          if (subGroup) subGroup.visible = true;
          layer.visible = true;
        }
        if (subGroup?.domain && subGroup.domain === domain) {
          subGroup.visible = true;
          layer.visible = true;
        }
        if (layer.domain && layer.domain === domain) {
          layer.visible = true;
        }

        let existing = state.layerGroups.find(g => g.id === group.id);
        if (existing) {
          if (subGroup && !existing.subGroups.some(sg => sg.id === subGroup.id)) {
            existing.subGroups.push({
              id: subGroup.id,
              parentId: group.id,
              title: subGroup[groupTitleField],
              visible: !!subGroup.visible,
              domain: subGroup.domain,
              order: subGroup.order
            });
            existing.subGroups.sort((a, b) => (a.order > b.order) ? 1 : -1);
          }
        } else {
          let subGroups = [];
          if (subGroup) {
            subGroups.push({
              id: subGroup.id,
              parentId: group.id,
              title: subGroup[groupTitleField],
              visible: !!subGroup.visible,
              domain: subGroup.domain,
              order: subGroup.order
            });
          }
          state.layerGroups.push({
            id: group.id,
            title: group[groupTitleField],
            domain: group.domain,
            order: group.order,
            visible: !!group.visible,
            subGroups: subGroups
          });
        }
      });
      state.layerGroups.sort((a, b) => (a.order > b.order) ? 1 : -1);
      state.layers.sort((a, b) => {
        const aGroupOrder = a.mapGroup.parentGroup?.order || a.mapGroup.order;
        const bGroupOrder = b.mapGroup.parentGroup?.order || b.mapGroup.order;
        if (aGroupOrder === bGroupOrder) {
          const aSubOrder = a.mapGroup.parentGroup ? a.mapGroup.order : a.order;
          const bSubOrder = b.mapGroup.parentGroup ? b.mapGroup.order : b.order;
          return aSubOrder === bSubOrder ? a.order - b.order : aSubOrder - bSubOrder;
        } else {
          return aGroupOrder - bGroupOrder;
        }
      });
      groupLayers(state);
      state.layersLoaded = true;
      state.isLoading = false;
    },
    activeFeaturesSuccess: (state, action) => {
      if (action.payload.clearAll) {
        state.activeFeatures = [];
      }
      if (action.payload.features?.length) {
        state.activeFeatures = state.activeFeatures.concat(action.payload.features);
        if (action.payload.center) {
          state.extent = MapUtils.getFeaturesExtent(MapUtils.toOLFeatures(state.activeFeatures));
        }
      }
    },
    viewSuccess: (state, action) => {
      if (action.payload.extent) {
        state.extent = action.payload.extent;
      } else {
        state.center = action.payload.center;
        state.zoom = action.payload.zoom;
        state.extent = null;
      }
    },
    wmtsCapabilitiesSuccess: (state, action) => {
      state.wmtsCapabilities[action.payload.url] = action.payload.capabilities;
    },
    activeDrawVectorLayerSuccess: (state, action) => {
      state.activeDrawVectorLayer = action.payload;
    },
  },
});

export default slice.reducer;

// Actions

const { startLoading, hasError,
  layerDrawerVisibilityChanged, layerGroupOpenChanged, subGroupOpenChanged,
  layerVisibilityChanged, layersVisibilityChanged, subGroupVisibilityChanged, layerGroupVisibilityChanged,
  layersSuccess, activeControlChanged, selectActiveChanged, activeDrawVectorLayerSuccess,
  activeFeaturesSuccess, viewSuccess, wmtsCapabilitiesSuccess } = slice.actions;

export const toggleLayerDrawer = (open) => async dispatch => {
  dispatch(layerDrawerVisibilityChanged(open));
};

export const toggleLayerGroup = (group) => async dispatch => {
  dispatch(layerGroupOpenChanged(group));
};

export const toggleSubGroup = (subGroup) => async dispatch => {
  dispatch(subGroupOpenChanged(subGroup));
};

export const toggleGroupVisibility = (group) => async dispatch => {
  dispatch(layerGroupVisibilityChanged(group));
};

export const toggleSubGroupVisibility = (subGroup) => async dispatch => {
  dispatch(subGroupVisibilityChanged(subGroup));
};

export const toggleLayer = (layer) => async dispatch => {
  dispatch(layerVisibilityChanged(layer));
};

export const showLayersByRegisterObjectType = (registerObjectType) => async dispatch => {
  dispatch(layersVisibilityChanged({ registerObjectType: registerObjectType, visible: true }));
};

export const toggleActiveControl = (control, active) => async dispatch => {
  dispatch(activeControlChanged({ control, active }));
};

export const toggleSelectActive = (active) => async dispatch => {
  dispatch(selectActiveChanged(active));
};

export const fetchLayers = (user) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get('map').then((response) => dispatch(layersSuccess({ user: user, data: response.data })))
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const setActiveGeometries = (geometries, append, dontCenter, style) => async dispatch => {
  dispatch(activeFeaturesSuccess({ features: MapUtils.geometriesToFeatures(geometries, style), clearAll: !append, center: !dontCenter }))
};

export const setActiveFeatures = (features, append, dontCenter) => async dispatch => {
  dispatch(activeFeaturesSuccess({ features: features, clearAll: !append, center: !dontCenter }));
};

export const setCenterZoom = (center, zoom) => async dispatch => {
  dispatch(viewSuccess({ center, zoom }));
};

export const setExtent = (extent) => async dispatch => {
  dispatch(viewSuccess({ extent }));
};

export const centerFeatures = (features) => async dispatch => {
  dispatch(viewSuccess({ extent: MapUtils.getFeaturesExtent(features) }));
};

const setGroupVisibility = (state, group, visibility) => {
  if (group.subGroups) {
    group.subGroups.forEach(sg => setSubGroupVisibility(state, sg, visibility));
  }

  const layersWithoutOrder = visibility ? state.layers.filter(l => l.mapGroup?.id === group.id && l.order === 0) : [];
  if(layersWithoutOrder.length > 0){
    layersWithoutOrder.forEach(l => l.visible = visibility);
    state.layers.filter(l => l.mapGroup?.id === group.id && l.order !== 0 && l.visible).forEach(l => l.visible = !visibility);
  } else {
    state.layers.filter(l => l.mapGroup?.id === group.id && l.visible !== visibility).forEach(l => l.visible = visibility);
  } 
  group.visible = visibility;
};

const setSubGroupVisibility = (state, subGroup, visibility) => {
  subGroup.visible = visibility;
  state.layers.filter(l => l.mapGroup?.id === subGroup.id).forEach(l => l.visible = visibility);
  checkGroupVisibility(state, findLayerGroup(state, subGroup.parentId));
};

const setLayerVisibility = (state, layer, visibility) => {
  state.layers.find(l => l.id === layer.id).visible = visibility;
  const group = findLayerGroup(state, layer.mapGroup?.id);
  if (group) {
    checkGroupVisibility(state, group);
  } else {
    const subGroup = findSubGroup(state, layer.mapGroup?.id);
    checkSubGroupVisibility(state, subGroup);
  }
};

const checkGroupVisibility = (state, group) => {
  const visibility = group.subGroups.every(sg => sg.visible) && state.layers.filter(l => l.mapGroup?.id === group.id).every(l => l.visible);
  if (visibility !== group.visible) {
    group.visible = visibility;
  }
};

const checkSubGroupVisibility = (state, subGroup) => {
  const visibility = state.layers.filter(l => l.mapGroup?.id === subGroup.id).every(l => l.visible);
  if (visibility !== subGroup.visible) {
    subGroup.visible = visibility;
  }
  checkGroupVisibility(state, findLayerGroup(state, subGroup.parentId));
};

const groupLayers = (state) => {
  const visibleLayers = state.layers.filter(l => l.visible && l.serviceType === 'WMS');
  const groupedLayers = visibleLayers.filter(l => !l.serverUrl.startsWith('/') || !l.mapGroup.parentGroup);
  state.layerGroups.forEach(g => g.subGroups.forEach(sg => {
    const sgLayers = visibleLayers.filter(l => l.serverUrl.startsWith('/') && l.mapGroup.id === sg.id);
    if (sgLayers.length > 0) {
      const first = sgLayers[0];
      groupedLayers.push({
        serverUrl: first.serverUrl,
        zIndex: first.zIndex,
        queryable: first.queryable,
        title: first.title,
        layerName: sgLayers.sort((a, b) => (a.zIndex - b.zIndex)).map(l => l.layerName).join(','),
        style: sgLayers.map(l => l.style).join(','),
        groupedLayers: sgLayers.map(l => ({ layerName: l.layerName, geometryName: l.geometryName }))
      })
    }
  }));
  state.groupedWMSLayers = groupedLayers;
};

const findLayerGroup = (state, id) => state.layerGroups.find(lg => lg.id === id);
const findSubGroup = (state, id) => state.layerGroups.flatMap(lg => lg.subGroups).find(sg => sg.id === id);

export const getCapabilitiesUrl = (version) => {
  return `/geoserver/ows?service=wms&version=${version}&request=GetCapabilities`;
};

export const getRoadShapeUrl = () => `${api.defaults.baseURL}/map/roadShape`;

export const changeLayersLanguage = () => async dispatch => {
  dispatch(layersSuccess());
};

export const setWMTSCapabilities = (url, capabilities) => async dispatch => {
  dispatch(wmtsCapabilitiesSuccess({url, capabilities}));
};

export const setActiveDrawVectorLayer = (type) => async dispatch => {
  dispatch(activeDrawVectorLayerSuccess(type));
};