import { IconButton } from "@material-ui/core";
import { cloneDeep } from "lodash";
import { RegisterObjectDefs } from "../constants/registerConstants";
import i18n from 'i18next';
import { Icon } from "styleguide";
import { showWarning } from "../stores/notification";
import { setDetails } from "../stores/register/register";
import AuthUtils from "./AuthUtils";
import { Authority } from "../constants/authConstants";
import { Domain } from "../constants/classifierConstants";

const RegisterUtils = {
  objectDefs: RegisterObjectDefs,
  setObjectDefs(basicObjectTypes) {
    let basicObjectDefs = {};
    basicObjectTypes.forEach(bot => {
      basicObjectDefs[bot.id] = cloneDeep(RegisterObjectDefs.basicObject);
      basicObjectDefs[bot.id].columns.splice(3, 0, { field: "basicObjectKind", type: "classifier", classifierType: bot.classifierType.code, required: true, filter: true });
      basicObjectDefs[bot.id].columns.splice(8, 0, { field: "geometry", tableOnly: true, showMapButton: true, type: "geometry", geometryTypes: [bot.geometryType], required: true });
      basicObjectDefs[bot.id].domain = bot.domain;
      basicObjectDefs[bot.id].title = bot.title;
      basicObjectDefs[bot.id].titleEst = bot.title;
      basicObjectDefs[bot.id].titleEng = bot.titleEng || bot.title;
    });
    this.objectDefs = { ...RegisterObjectDefs, ...basicObjectDefs };
    this.changeObjectDefLanguage();
  },
  changeObjectDefLanguage() {
    Object.keys(this.objectDefs).forEach(key => {
      if (this.objectDefs[key].superObjectType === 'basicObject') {
        this.objectDefs[key].title = i18n.language === 'en-US' ? this.objectDefs[key].titleEng : this.objectDefs[key].titleEst;
      }
    });
  },
  getObjectDef(objectType) {
    return this.objectDefs[objectType];
  },
  getRegisterColumns(objectType, handleShowMap, hideRelated) {
    if (!objectType || !this.getObjectDef(objectType)) {
      return [];
    }
    return this.getObjectDef(objectType).columns.filter(column =>
      !column.detailsOnly &&
      (!column.showMapButton || handleShowMap) &&
      (!column.relation || !hideRelated || column.includeInMapDialog)
    ).map(obj => {
      if (obj.showMapButton) {
        return {
          ...obj, width: 80, renderCell: (column, row) => {
            return row[column.field] && <IconButton size="small" onClick={(e) => { e.stopPropagation(); handleShowMap(row); }}><Icon icon="map"></Icon></IconButton>
          }
        };
      } else {
        return { ...obj, headerName: this.getColumnName(objectType, obj) }
      }
    }
    );
  },
  getRegisterGeometryColumns(objectType) {
    return this.getObjectDef(objectType).columns.filter(c => c.type === 'geometry');
  },
  getRegisterGeometries(objectType, object) {
    return this.getRegisterGeometryColumns(objectType).filter(column => object[column.field]).map(column => object[column.field]);
  },
  getRegisterRelationGeometries(objectType, object) {
    return this.getObjectDef(objectType).columns.filter(column => column.type === 'relation' && object[column.field] && this.getObjectDef(column.relation)).flatMap(column => this.getRegisterGeometries(column.relation, object[column.field]));
  },
  getSuperObjectType(objectType) {
    return this.getObjectDef(objectType)?.superObjectType || objectType;
  },
  setDrawGeometries(object, drawingFeatures, columns) {
    const geometryColumn = columns.find(c => c.type === 'geometry');
    if (!geometryColumn) {
      return;
    }
    const wrongGeometry = drawingFeatures.find(f => !geometryColumn.geometryTypes.includes(f.geometry.type));
    if (wrongGeometry) {
      return {
        drawType: i18n.t(`geometry.type.${wrongGeometry.geometry.type}`),
        objectType: geometryColumn.geometryTypes.map(geometryType => i18n.t(`geometry.type.${geometryType}`)).join(',')
      };
    }
    if (drawingFeatures.length === 1) {
      const geometry = drawingFeatures[0].geometry;
      object[geometryColumn.field] = geometry;
    } else {
      object.drawGeometries = drawingFeatures.map(feature => feature.geometry);
    }
  },
  getColumnName(objectType, column) {
    return i18n.t(column.headerKey || `register.${this.getSuperObjectType(objectType)}.${column.field}`);
  },
  addNew: (parentType, parentObject, newItemObjectType, drawingFeatures, history) => async dispatch => {
    let newItem = {};
    const columns = RegisterUtils.getObjectDef(newItemObjectType).columns;
    const relationColumns = columns.filter(c => c.type === 'relation');
    if (relationColumns.some(c => c.relation === parentType)) {
      newItem[parentType] = parentObject;
    }

    const setParentRelations = (parent) => !!parent && relationColumns
      .forEach(c => !newItem[c.relation] && parent[c.relation] && (newItem[c.relation] = parent[c.relation]));

    //parent object can have common upper relation (cable->parentCable->shieldRegion)
    setParentRelations(parentObject);
    //parent object can have parent object common relation (roadSign->crosswalk->roadSection->road)
    RegisterUtils.getObjectDef(parentType).columns
      .filter(c => c.type === 'relation')
      .forEach(c => setParentRelations(parentObject[c.relation]));

    if (drawingFeatures?.length) {
      const fault = RegisterUtils.setDrawGeometries(newItem, drawingFeatures, columns);
      if (fault) {
        dispatch(showWarning('validation.wrongGeometryType', fault));
        return;
      }
    }

    if (newItemObjectType === 'servitudeArea') {
      newItem.postApiPathPrefix = `${parentType}/${parentObject.id}/`;
      newItem.domain = RegisterUtils.getObjectDef(parentType).domain;
    }

    dispatch(setDetails(newItemObjectType, newItem));
    history.push(`/register/edit/${newItemObjectType}`);
  },
  canEditDomain: (domain, authUser) => {
    if(AuthUtils.hasAuthority(authUser, Authority.UC31_register_object_data)){
      return true;
    }
    if(Domain.streetlight === domain) {
      return AuthUtils.hasAuthority(authUser, Authority.UC31_TV_register_object_aata);
    }
    return false;
  },
  isBasicObjectDomain: (domain) => [Domain.infrastructure, Domain.landscaping].includes(domain),
};
export default RegisterUtils;