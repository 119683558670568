import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import logoVaal from '../assets/images/logo_vaal.png';
import iconNavActive from '../assets/images/icon_nav_active.svg';
import { Toolbar, IconButton, Badge, ButtonGroup, Button, Link, Dialog, Slide, List, ListItem, Popper, Paper, ListItemIcon, ListItemText, Divider, ClickAwayListener, Box } from '@material-ui/core';
import { Button as VaalButton, Heading, Icon, Colors, Shadows, Label, Paragraph, Tooltip } from 'styleguide';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { changeRole, logout } from '../stores/auth';
import AuthUtils from '../utils/AuthUtils';
import { Authority, AuthRole, UserType } from '../constants/authConstants';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import { fetchLastRows, setRead } from '../stores/admin/userNotification';
import RouteUtils from '../utils/RouteUtils';
import { saveUserLanguage } from '../stores/admin/userProfile';
import ReactGA from 'react-ga4';
import { changeClassifierLanguage } from '../stores/admin/classifier';
import { changeLayersLanguage } from '../stores/map/map';
import UserManuals from '../components/user/UserManuals';
import RegisterUtils from '../utils/RegisterUtils';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: 'white',
    [theme.breakpoints.up('md')]: {
      height: 102
    },
    boxShadow: Shadows.shadow1,
    zIndex: theme.zIndex.drawer + 1
  },
  flex: {
    flexGrow: 1
  },
  logoVaal: {
    height: 18,
    [theme.breakpoints.up('sm')]: {
      width: 70,
      marginLeft: 12,
      marginRight: 30
    },
    [theme.breakpoints.down('sm')]: {
      width: '50%',
      marginRight: -24
    },
  },
  userButton: {
    margin: 8,
    height: 44
  },
  roleButtonText: {
    opacity: 0.48
  },
  userNameButton: {
    opacity: 0.9,
    whiteSpace: 'nowrap'
  },
  navLink: {
    display: 'inline-block',
    lineHeight: '99px',
    paddingLeft: 20,
    paddingRight: 20,
    fontWeight: 'normal',
    borderTop: '3px solid transparent',
    '&:hover': {
      textDecoration: 'none',
      borderColor: theme.palette.primary.light
    }
  },
  navActive: {
    borderColor: theme.palette.primary.main
  },
  navListItem: {
    paddingLeft: 27
  },
  navActiveMobile: {
    background: `url(${iconNavActive}) no-repeat center left`
  },
  navBottomBarMobile: {
    position: 'absolute',
    bottom: 32,
    paddingLeft: 24,
    paddingRight: 24,
    width: '100%',
    display: 'flex'
  },
  userPanelPopper: {
    zIndex: 3
  },
  userPanel: {
    width: 296,
    boxShadow: Shadows.shadow1,
    paddingTop: 10,
    paddingBottom: 10
  },
  userPanelContent: {
    paddingLeft: 10
  },
  userPanelDivider: {
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: Colors.hall3
  },
  notificationPanel: {
    minWidth: 296,
    maxWidth: 420,
    boxShadow: Shadows.shadow1,
    paddingTop: 10,
    paddingBottom: 10
  },
  readNotification: {
    opacity: 0.5
  },
  loadingCell: {
    borderRadius: 4,
    backgroundColor: Colors.hall3,
    height: 14,
    width: '50%',
    margin: '5px 0px'
  },
  nav: {
    height: '100%',
    whiteSpace: 'nowrap',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function LoadingListItems({ count }) {
  const classes = useStyles();

  const rows = [];
  for (let index = 0; index < count; index++) {
    rows.push(<ListItem key={index}><ListItemText className={classes.loadingCell} /></ListItem>);
  }
  return rows;
}

function UserNotifications({ compact }) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const unreadCount = useSelector(state => state.userNotification.unreadCount);
  const lastRows = useSelector(state => state.userNotification.lastRows);
  const isLoading = useSelector(state => state.userNotification.isLoading);

  let popperAnchor = useRef(null);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (compact) {
      return handleOpenTable();
    }
    setOpen(true);
    dispatch(fetchLastRows());
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenTable = () => {
    history.push('/notification');
    setOpen(false);
  };

  const handleOpenRow = (row) => {
    dispatch(setRead(row))
    setOpen(false);
    RouteUtils.navigateToObject(history, row.relatedTable, row.relatedId);
  };

  let count;
  if (unreadCount > 9) {
    count = "9+";
  } else if (unreadCount < 0) {
    count = 0;
  } else {
    count = unreadCount;
  }

  return <>
    <IconButton aria-label="open notifications panel" ref={popperAnchor} onClick={handleClick}>
      <Badge badgeContent={count} color="error" aria-label="unread notifications" overlap="rectangular">
        <Icon icon='bell'></Icon>
      </Badge>
    </IconButton>

    {!compact && <Popper open={open} anchorEl={popperAnchor.current} placement="bottom"
      modifiers={{ offset: { enabled: true, offset: '0, 34' } }} className={classes.userPanelPopper}>
      <Paper elevation={0} className={classes.notificationPanel}>
        <ClickAwayListener onClickAway={handleClose}>
          <List>
            <ListItem>
              <Label m={2} fontWeight='bold'>{t('userNotification.title')}</Label>
              <Box flexGrow={1} />
              <VaalButton size="extra-small" color="tertiary" onClick={handleOpenTable}>
                {t('userNotification.openTable')}
              </VaalButton>
            </ListItem>
            <Divider className={classes.userPanelDivider} />
            {isLoading && <LoadingListItems count={4} />}
            {!isLoading && lastRows.map((row, index) => (
              <Tooltip key={index} title={row.content || ''} placement="left" onOpen={() => !!row.content && dispatch(setRead(row))}>
                <ListItem button onClick={() => handleOpenRow(row)}>
                  <ListItemText disableTypography className={!!row.read ? classes.readNotification : ''}
                    secondary={<Paragraph fontSize="14B">{row.title || t(`userNotification.type.${row.notificationType}`)}</Paragraph>} />
                </ListItem>
              </Tooltip>
            ))}
          </List>
        </ClickAwayListener>
      </Paper>
    </Popper>}
  </>;
}

function LanguageChangeButton({ authenticated, ...rest }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const handleLanguageChange = () => {
    const newLanguage = i18n.language === "et-EE" ? "en-US" : "et-EE";
    i18n.changeLanguage(newLanguage).then(() => {
      sessionStorage.setItem('vaalLanguage', newLanguage);
      if (authenticated) {
        dispatch(saveUserLanguage(newLanguage));
      }
      dispatch(changeClassifierLanguage());
      dispatch(changeLayersLanguage());
      RegisterUtils.changeObjectDefLanguage();
    });
  };
  return <Button onClick={handleLanguageChange} {...rest}>{t('header.languageBtn')}</Button>;
}

function NavItemLink(props) {
  const { route, classes } = props;

  return (
    <Link className={classes.navLink}
      activeClassName={classes.navActive}
      component={NavLink}
      color="inherit"
      to={route.to} exact={route.exact}>
      {route.label}
    </Link>
  );
}

function UserPanel({ roles, handleLogout, handleChangeRole }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const openUserProfile = () => {
    history.push('/profile');
  };

  return <div>
    <span className={classes.userPanelContent}>
      <Button onClick={openUserProfile}>{t('header.userProfileBtn')}</Button>
    </span>
    <Divider className={classes.userPanelDivider} />
    {!!roles.length && <List>
      <ListItem>
        <Label m={2} fontWeight='bold'>{t('header.userPanel.roleSelect')}</Label>
      </ListItem>
      {roles.map((role) => (
        <ListItem button key={role.userId} onClick={() => handleChangeRole(role.userId)}>
          <ListItemIcon>
            <Icon icon="user" size="40" />
          </ListItemIcon>
          <ListItemText primary={t(`header.userType.${role.userType}`)} secondary={role.name} />
        </ListItem>
      ))}
    </List>}
    <Divider className={classes.userPanelDivider} />
    <span className={classes.userPanelContent}>
      <Button m={8} onClick={handleLogout}>{t('header.logoutBtn')}</Button>
    </span>
  </div>;
}

function DesktopHeader({ routes, authUser, authenticated, handleLogin, handleLogout, handleChangeRole, userPanelOpen, toggleUserPanel }) {
  const { t } = useTranslation();
  const classes = useStyles();

  let popperAnchor = useRef(null);

  const handleClose = (event) => {
    if (popperAnchor.current && popperAnchor.current.contains(event.target)) {
      return;
    }
    toggleUserPanel(false);
  };

  return <>
    <nav className={classes.nav}>
      {routes.map((userRoute, index) => {
        return <NavItemLink key={index} route={userRoute} classes={classes} />
      })}
    </nav>

    <span className={classes.flex}></span>

    <UserManuals />

    {(!authenticated || AuthUtils.isCitizen(authUser)) && <LanguageChangeButton authenticated={authenticated} />}

    {authenticated && <Box paddingRight={3}><UserNotifications /></Box>}

    <ButtonGroup disableElevation aria-label="user info" variant="contained" color={userPanelOpen ? 'primary' : 'secondary'} className={classes.userButton}>
      {!authenticated && <Button onClick={handleLogin}>{t('header.loginBtn')}</Button>}
      {authenticated &&
        <Button onClick={toggleUserPanel} className={classes.userNameButton}>
          <span className={classes.roleButtonText}>{t(`header.userType.${authUser.type}`)}</span>&nbsp;
          <span>{authUser.fullName}</span>
        </Button>
      }
      {authenticated &&
        <Button
          onClick={toggleUserPanel}
          size="small" ref={popperAnchor}
          aria-label="select role"
        >
          <Icon icon={userPanelOpen ? "arrowUp" : "arrowDown"} color="white"></Icon>
        </Button>
      }
    </ButtonGroup>
    {authenticated && <Popper open={userPanelOpen} anchorEl={popperAnchor.current} placement="bottom-end" modifiers={{ offset: { enabled: true, offset: '0, 34' } }}
      className={classes.userPanelPopper}>
      <Paper elevation={0} className={classes.userPanel}>
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <UserPanel
              handleLogout={handleLogout} handleChangeRole={handleChangeRole}
              roles={authUser.roles.filter(r => r.userId !== authUser.id)}
            />
          </div>
        </ClickAwayListener>
      </Paper>
    </Popper>}
  </>;
}

function ListItemLink(props) {
  const { route, onClick, classes } = props;

  return (
    <li>
      <ListItem button component={NavLink} onClick={onClick} className={classes.navListItem}
        activeClassName={classes.navActiveMobile} to={route.to} exact={route.exact}>
        <Heading level="3L">{route.label}</Heading>
      </ListItem>
    </li>
  );
}

function MobileHeader({ routes, authUser, authenticated, handleLogin, handleLogout, handleChangeRole, userPanelOpen, toggleUserPanel }) {
  const classes = useStyles();
  const [navOpen, setNavOpen] = useState(false);

  const handleNavOpen = () => {
    setNavOpen(true);
  };

  const handleNavClose = () => {
    setNavOpen(false);
  };

  const handleOpenUserPanel = () => {
    if (authenticated) {
      toggleUserPanel();
    } else {
      handleLogin();
    }
  };

  return <>
    <IconButton onClick={handleNavOpen} aria-label="open navigation panel">
      <Icon icon='menu'></Icon>
    </IconButton>

    <span className={classes.flex}></span>

    {authenticated && <Box paddingRight={1}><UserNotifications compact /></Box>}

    <IconButton aria-label="login or open user panel" onClick={handleOpenUserPanel}>
      <Icon icon='user' size='30px'></Icon>
    </IconButton>

    <Dialog fullScreen open={navOpen} onClose={handleNavClose} TransitionComponent={Transition}>
      <IconButton onClick={handleNavClose} aria-label="close">
        <Icon icon="times" />
      </IconButton>
      <List>
        {routes.map((userRoute, index) => {
          return <ListItemLink key={index} route={userRoute} onClick={handleNavClose} classes={classes} />
        })}
      </List>

      <div className={classes.navBottomBarMobile}>
        {(!authenticated || AuthUtils.isCitizen(authUser)) && <LanguageChangeButton authenticated={authenticated} />}
        <span className={classes.flex} />
        <UserManuals />
      </div>
    </Dialog>

    {authenticated && <Dialog fullScreen open={userPanelOpen} onClose={toggleUserPanel} TransitionComponent={Transition}>
      <IconButton onClick={() => toggleUserPanel(false)} aria-label="close">
        <Icon icon="times" />
      </IconButton>
      <UserPanel
        handleLogout={handleLogout} handleChangeRole={handleChangeRole}
        roles={authUser.roles.filter(r => r.userId !== authUser.id)}
      />
    </Dialog>}
  </>;
}

export default function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const location = useLocation();

  const authUser = useSelector(state => state.auth.authUser);
  const authenticated = useSelector(state => state.auth.authenticated);
  const desktop = !useSelector(state => state.global.mobileView);

  const [userPanelOpen, setUserPanelOpen] = useState(false);

  const handleLogin = () => {
    window.location.href = "/vaal-auth/oauth2/authorization/govsso";
  };

  const toggleUserPanel = (open) => {
    setUserPanelOpen(typeof open === 'boolean' ? open : !userPanelOpen);
  };

  const handleLogout = () => {
    dispatch(logout());
    setUserPanelOpen(false);
  };

  const handleChangeRole = (userId) => {
    dispatch(changeRole(userId));
    setUserPanelOpen(false);
  };

  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }
  }, [location]);

  const homepageRoute = { to: "/", label: t('homepage.title'), exact: true };
  const mapRoute = { to: "/map", label: t('map.title') };
  const registerRoute = { to: "/register", label: t('register.title') };
  const reportRoute = { to: "/report", label: t('report.title') };
  const searchRoute = { to: "/search", label: t('search.title') };
  const planningRoute = { to: "/plan", label: t('planning.title') };

  const userRoutes = [mapRoute];
  if (desktop) {
    userRoutes.push(reportRoute);
  }
  userRoutes.push(registerRoute);
  userRoutes.push(searchRoute);
  switch (authUser?.type) {
    case UserType.citizen:
      userRoutes.unshift(planningRoute);
      if (AuthUtils.hasAuthority(authUser, Authority.UC6_my_applications)) {
        userRoutes.unshift({ to: "/application", label: t('myApplications.title') });
      }
      userRoutes.unshift(homepageRoute);
      break;
    case UserType.partner:
      userRoutes.unshift(planningRoute);
      if (AuthUtils.hasAuthority(authUser, Authority.UC19_partner_application)) {
        userRoutes.unshift({ to: "/application", label: t('application.title') });
      }
      if (AuthUtils.hasAuthority(authUser, Authority.UC27_partner_work_order)) {
        userRoutes.unshift({ to: "/workOrder", label: t('workOrder.title') });
      }
      if (process.env.REACT_APP_SVMS === 'true' && AuthUtils.hasAuthority(authUser, Authority.SVMS_UC1_view)) {
        userRoutes.push({ to: "/monitoring", label: t('header.monitoring') });
      }
      break;
    case UserType.gov:
      if (AuthUtils.hasAuthority(authUser, Authority.UC26_work_orders)) {
        userRoutes.unshift({ to: "/workOrder", label: t('workOrder.title') });
      }
      userRoutes.unshift(planningRoute);
      if (AuthUtils.hasAnyAuthority(authUser, [Authority.UC7_application, Authority.UC7_view_application])) {
        userRoutes.unshift({ to: "/application", label: t('application.title') });
      }
      userRoutes.unshift(homepageRoute);
      if (AuthUtils.hasRole(authUser, AuthRole.Admin)) {
        userRoutes.push({ to: "/admin", label: t('admin.title') });
      }
      if (process.env.REACT_APP_SVMS === 'true' && AuthUtils.hasAuthority(authUser, Authority.SVMS_UC1_view)) {
        userRoutes.push({ to: "/monitoring", label: t('header.monitoring') });
      }
      break;
    default:
      userRoutes.splice(1, 0, planningRoute);
  }

  if (authUser && location.pathname === '/' && !userRoutes.includes(homepageRoute)) {
    return <Redirect to={userRoutes[0].to} />;
  }

  return (
    <AppBar elevation={0} color="transparent" position="static">
      <Toolbar className={classes.header}>
        <Link component={NavLink} to="/" className={classes.logoVaal}>
          <img src={logoVaal} alt="logo" />
        </Link>

        {desktop ?
          <DesktopHeader routes={userRoutes} authUser={authUser} authenticated={authenticated}
            handleLogin={handleLogin} handleLogout={handleLogout} handleChangeRole={handleChangeRole}
            userPanelOpen={userPanelOpen} toggleUserPanel={toggleUserPanel} /> :
          <MobileHeader routes={userRoutes} authUser={authUser} authenticated={authenticated}
            handleLogin={handleLogin} handleLogout={handleLogout} handleChangeRole={handleChangeRole}
            userPanelOpen={userPanelOpen} toggleUserPanel={toggleUserPanel} />
        }
      </Toolbar>
    </AppBar>
  );
}