import { Box, Grid, IconButton, makeStyles } from "@material-ui/core";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Colors, Icon, Paragraph } from "styleguide";
import { fetchUserProfile } from "../../stores/admin/userProfile";
import { resetSubmitted, saveProposal } from "../../stores/planning/planning";
import DialogForm from "../form/DialogForm";
import { CenteredLoadingIndicator } from "../form/LoadingIndicator";
import VTextField from "../form/VTextField";
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    [theme.breakpoints.up('md')]: {
      width: 500,
      margin: 'auto'
    },
  },
  formBlockContainer: {
    padding: 12,
    backgroundColor: Colors.hall3,
    borderRadius: 4
  },
  uploadRow: {
    cursor: 'pointer'
  },
  dragActiveUpload: {
    backgroundColor: Colors.withOpacity(Colors.sinineVaal, 0.1),
  },
}));

const getDefaultValues = (profile) => ({
  email: profile?.email,
  phone: profile?.phone
});

export default function ProposalForm() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams();
  const { isLoading, submitted } = useSelector(state => state.planning);
  const profile = useSelector(state => state.userProfile.profile);

  const { register, errors, reset, handleSubmit } = useForm({
    defaultValues: getDefaultValues(profile)
  });

  const [file, setFile] = useState(null);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open
  } = useDropzone({
    noClick: true,
    onDrop: files => {
      setFile(files[0]);
    }
  });

  useEffect(() => submitted && dispatch(resetSubmitted()) && history.goBack(), [submitted, dispatch, history]);
  useEffect(() => reset(getDefaultValues(profile)), [reset, profile]);

  const shouldFetchProfile = !profile;
  useEffect(() => shouldFetchProfile && dispatch(fetchUserProfile()), [dispatch, shouldFetchProfile]);

  if (!profile) {
    return <CenteredLoadingIndicator />;
  }

  const onSubmit = (inputData) => {
    let data = cloneDeep(inputData);
    data.file = file;
    dispatch(saveProposal(id, data));
  };

  return <DialogForm
    title={t('planning.view.addProposal')}
    onSubmit={handleSubmit(onSubmit)}
    disabled={isLoading}
  >
    <Grid container direction="column" spacing={4} justifyContent="center" className={classes.formContainer}>
      <Grid item>
        <VTextField name="email" label="plan.party.email"
          disabled={isLoading} maxLength={100} pattern="email" required
          register={register} errors={errors} fullWidth
        />
      </Grid>
      <Grid item>
        <VTextField name="phone" label={t('plan.party.phone')}
          disabled={isLoading} maxLength={100} pattern="phone"
          register={register} errors={errors} fullWidth
        />
      </Grid>
      <Grid item>
        <VTextField name="content" label={t('planning.operation.proposal')}
          disabled={isLoading} maxLength={2000} multiline minRows={3}
          register={register} errors={errors} fullWidth required
        />
      </Grid>
      <Grid item>
        {!!file ?
          <Grid container direction="row" justifyContent="space-between" className={classes.formBlockContainer}>
            <Paragraph fontSize='12'>{file.name}</Paragraph>
            <IconButton size="small" onClick={() => setFile(null)} aria-label="delete file">
              <Icon icon="delete" />
            </IconButton>
          </Grid>
          :
          <div {...getRootProps({ onClick: e => e.preventDefault(), className: clsx(classes.formBlockContainer, isDragActive && classes.dragActiveUpload) })}>
            <Grid container direction="row" className={classes.uploadRow} onClick={open}>
              <Icon icon='add' color={Colors.sinineVaal} />
              <Box marginLeft={2}>
                <Paragraph fontSize='14' color={Colors.sinineVaal}>{t('register.relatedDocument.chooseFile')}</Paragraph>
              </Box>
              <input type="file" {...getInputProps()} />
            </Grid>
          </div>
        }
      </Grid>
    </Grid>
  </DialogForm>;
}
