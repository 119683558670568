import { Box, Collapse, Grid, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { BasicLabel, Heading, Icon, Paragraph, Tooltip } from 'styleguide';
import { ClassifierType, PlanningDocumentType } from '../../constants/classifierConstants';
import { deleteDocument, getDocumentDownloadUrl } from '../../stores/planning/planningDocument';
import { toggleOperation } from '../../stores/planning/planningOperation';
import PlanningUtils from '../../utils/PlanningUtils';
import ClassifierLabel from '../form/ClassifierLabel';
import LinkButton from '../LinkButton';
import ConfirmButton from '../table/ConfirmButton';
import CroppedText from '../table/CroppedText';
import SimplePageableTable from '../table/SimplePageableTable';
import PlanningDocumentVersions from './PlanningDocumentVersions';

const useStyles = makeStyles((theme) => ({
  iconCell: {
    verticalAlign: 'middle'
  },
  buttonsBlock: {
    float: 'right'
  },
  editButton: {
    marginLeft: 8,
    marginRight: 8,
  }
}));

function OperationTable({ planningId, operation, readOnly, disabled, showRestricted }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const documents = useSelector(state => state.planningDocument.rows);
  const authUser = useSelector(state => state.auth.authUser);

  const operationDocuments = documents?.filter(doc => doc.operationId === operation.id) || [];

  const documentColumns = useMemo(() => {
    const canDelete = (document) => {
      if (document.createdById === authUser.id) {
        switch (document.documentType) {
          case PlanningDocumentType.initiate:
          case PlanningDocumentType.accept:
          case PlanningDocumentType.establish:
          case PlanningDocumentType.complete:
          case PlanningDocumentType.invalidate:
            return false;
          default:
            return true;
        }
      }
      return false;
    };

    const handleEdit = (event, document) => {
      event.stopPropagation();
      if (document.createdById === authUser.id) {
        history.push(`editDocument/${document.id}`);
      } else {
        history.push(`editDocumentLimited/${document.id}`);
      }
    };

    const handleOpenDocument = (row) => {
      const url = !!row.fileName ? getDocumentDownloadUrl(planningId, row.id) : row.url;
      if (url) {
        window.open(url, '_blank', 'noopener,noreferrer')
      }
    };

    const downloadFileButton = (row) => (!!row.url || !!row.fileName) && <>
      {!!row.fileName && <PlanningDocumentVersions planningId={planningId} documentId={row.id} />}
      <IconButton onClick={() => handleOpenDocument(row)} title={t('fileUpload.download')}>
        <Icon icon="downloadFile" />
      </IconButton>
    </>;

    let c = [
      { field: 'description', headerName: t('planning.document.description'), filter: true },
      { field: 'number', headerName: t('planning.document.number'), filter: true },
      { field: 'documentDate', headerName: t('planning.document.documentDate'), type: 'date' },
      {
        field: "documentType", headerName: t('planning.document.documentType'), filter: true,
        type: "classifier", classifierType: ClassifierType.planningDocumentType,
      },
      {
        field: 'content', headerName: t('planning.document.content'), filter: true, renderCell: (column, row) =>
          <CroppedText value={row.content} length={30} />
      },
      {
        field: 'authorName', headerName: t('planning.document.author'), filter: true, renderCell: (column, row) => {
          if (row.authorId && row.createdByName) {
            return <Tooltip title={t('planning.document.createdBy', { name: row.createdByName })} label={row.authorName} />
          }
          return row.authorName;
        }
      },
    ];
    if (showRestricted) {
      c.push({ field: 'isPublic', headerName: t('planning.document.isPublic'), type: 'boolean', filter: true });
      c.push({ field: 'isPlan', headerName: t('planning.document.isPlan'), type: 'boolean', filter: true });
      if (!readOnly) {
        c.push({
          field: 'action', renderCell: (column, cell) => <Box textAlign="right" whiteSpace="nowrap">
            <IconButton onClick={(e) => handleEdit(e, cell)} aria-label="edit document" title={t('planning.document.editButton')}>
              <Icon icon="pencil" />
            </IconButton>
            {canDelete(cell) &&
              <ConfirmButton
                title={t('planning.document.deleteButton')}
                message={t('form.confirmDelete')} icon="delete"
                onConfirm={() => dispatch(deleteDocument(planningId, cell.id))}
                aria-label="delete document"
              />
            }
            {downloadFileButton(cell)}
          </Box>
        });
      }
    }
    if (readOnly) {
      c.push({
        field: 'action', width: 20, renderCell: (column, row) => <Box textAlign="right" whiteSpace="nowrap">{downloadFileButton(row)}</Box>
      });
    }
    return c;
  }, [t, planningId, dispatch, readOnly, showRestricted, history, authUser]);

  const handleToggle = (event) => {
    if (event?.target?.tagName !== 'BUTTON') {
      dispatch(toggleOperation(operation.id));
    }
  };

  return <>
    <TableRow className={operation.open ? 'collapsible-table-row-opened' : 'collapsible-table-row'}
      onClick={handleToggle}>
      <TableCell component='th' align='left'>
        <Icon icon={operation.open ? 'minus' : 'plus'} className={classes.iconCell} />
      </TableCell>
      <TableCell component='th' scope='row'>
        <Grid container direction="row" justifyContent="space-between">
          <Heading level="4">
            <ClassifierLabel classifierType={ClassifierType.planningOperationType} code={operation.operationType} />
            <BasicLabel isNumeric>{operationDocuments.length}</BasicLabel>
          </Heading>
          <Box marginRight={1}>
            {PlanningUtils.getOperationPeriodLabel(operation)}
            {!readOnly &&
              <LinkButton to={`editOperation/${operation.id}`} size="extra-small" color="tertiary" disabled={disabled} className={classes.editButton}>
                {t('button.edit')}
              </LinkButton>
            }
            {!readOnly &&
              <LinkButton to={`addOperationDocument/${operation.id}`} size="extra-small" disabled={disabled} className={classes.editButton}>
                {t('planning.document.addButton')}
              </LinkButton>
            }
          </Box>
        </Grid>
      </TableCell>
    </TableRow>
    <TableRow className='collapsed-table'>
      <TableCell style={{ padding: 0 }} colSpan={3}>
        <Collapse in={operation.open} timeout='auto' unmountOnExit>
          <Box padding={3}>
            <Grid container direction="row" spacing={2}>
              {!!operation.supervisorId && <>
                <Grid item>
                  <Paragraph fontSize="14B">{t('planning.operation.supervisor')}</Paragraph>
                </Grid>
                <Grid item>
                  {operation.supervisorName}
                </Grid>
              </>}
              {!!operation.executorId && <>
                <Grid item>
                  <Paragraph fontSize="14B">{t('planning.operation.executor')}</Paragraph>
                </Grid>
                <Grid item>
                  {operation.executorName}
                </Grid>
              </>}
              {!!operation.isPublic && <Grid item>
                <Paragraph fontSize="14B">{t('planning.operation.isPublic')}</Paragraph>
              </Grid>}

              <Grid item xs={12}>
                <Box whiteSpace="pre-line">
                  {operation.description}
                </Box>
              </Grid>
            </Grid>
          </Box>
          {(operationDocuments.length > 0) && <Box margin={0}>
            <SimplePageableTable
              rows={operationDocuments}
              columns={documentColumns}
              sort={{ field: 'documentDate', ascending: false }}
              pageSize={10}
            />
          </Box>}
        </Collapse>
      </TableCell>
    </TableRow>
  </>;
}

export default function PlanningOperations({ readOnly, showRestricted }) {
  const { t } = useTranslation();

  const planning = useSelector(state => state.planning.selected);
  const operations = useSelector(state => state.planningOperation.rows);
  const isLoading = useSelector(state => state.planningOperation.isLoading);
  const compact = useSelector(state => state.global.mobileView);

  return <div>
    {!readOnly && <Box textAlign="right" paddingBottom={3} marginTop={-2}>
      <LinkButton to="editOperation" size="extra-small">{t('planning.operation.add')}</LinkButton>
    </Box>}
    <div className='VTable'>
      <TableContainer component={Paper}>
        <Table aria-label="register related objects table" size={compact ? 'small' : 'medium'}>
          <TableBody>
            {operations?.map(operation => (
              <OperationTable key={operation.id} planningId={planning.id} operation={operation} readOnly={readOnly} disabled={isLoading} showRestricted={showRestricted} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  </div>;
}