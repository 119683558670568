import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { TabContainer } from "styleguide";

export default function NavigationTabs({ children, size }) {
  const location = useLocation();
  const mobile = useSelector(state => state.global.mobileView);

  return <TabContainer
    size={size || (mobile ? 'small' : 'big')}
    withPopper={mobile}
    currentPath={location.pathname}>
    {children.map((child, index) => React.cloneElement(
      child,
      { key: index, className: `${child.props.className} ${location.pathname === child.props.to ? 'active' : ''}` },
      child.props.children
    ))}
  </TabContainer>;
}