import { Box, Grid, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Colors, Icon, Paragraph, TableHeader } from 'styleguide';
import { ClassifierType } from '../../../constants/classifierConstants';
import ClassifierSelect from '../../form/ClassifierSelect';
import VTextField from '../../form/VTextField';
import ConfirmButton from '../../table/ConfirmButton';
import SimplePageableTable from '../../table/SimplePageableTable';
import clsx from 'clsx';
import ApplicationUtils from '../../../utils/ApplicationUtils';
import { addPlanFile, deletePlanFile, getFileDownloadUrl, resetSubmitted } from '../../../stores/application/applicationFile';
import PlanningUtils from '../../../utils/PlanningUtils';

const useStyles = makeStyles((theme) => ({
  addDocumentContainer: {
    marginBottom: 72,
    padding: 26
  },
  container: {
    height: 306,
    margin: '40px auto',
    backgroundColor: Colors.hall3,
    padding: 20,
    borderRadius: 8
  },
  uploadContiner: {
    width: '100%',
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    padding: 12,
    backgroundColor: Colors.hall3,
    borderRadius: 8
  },
  dragActiveUpload: {
    backgroundColor: Colors.withOpacity(Colors.sinineVaal, 0.1),
  }
}));

function AddDocumentForm({ type, onConfirm, onClose, isLoading, compact }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { register, control, errors, handleSubmit, setValue } = useForm({
    defaultValues: {}
  });
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({
    onDrop: files => {
      setValue("file", files[0]);
    }
  });

  useEffect(() => {
    register({ name: "file" });
  }, [register]);
  const selectedFile = acceptedFiles[0];
  const gridSize = compact ? 12 : 3;

  return <Paper elevation={1} className={classes.addDocumentContainer}>
    <form onSubmit={handleSubmit(onConfirm)}>
      <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <Grid item xs={gridSize}>
          <VTextField name="description" label="register.relatedDocument.description"
            disabled={isLoading} required maxLength={200}
            register={register} errors={errors} fullWidth
          />
        </Grid>
        <Grid item xs={gridSize}>
          <ClassifierSelect item={{}} name="documentType" label="register.relatedDocument.documentType"
            disabled={isLoading} required classifierType={ClassifierType.planningDocumentType}
            control={control} errors={errors} formatItemTitle={PlanningUtils.formatPlanningDocumentType} sortBy="itemCode"
          />
        </Grid>
        {type === 'FILE' && <Grid item xs={gridSize}>
          {!selectedFile && <div {...getRootProps({ onClick: e => e.preventDefault(), className: clsx(classes.uploadContiner, isDragActive && classes.dragActiveUpload) })}>
            <Icon icon='add' color={Colors.sinineVaal} />
            <Box marginLeft={2}>
              <Paragraph fontSize='14' color={Colors.sinineVaal}>{t('register.relatedDocument.chooseFile')}</Paragraph>
            </Box>
            <input type="file" name="file" {...getInputProps()} />
          </div>}
          {selectedFile && <div className={classes.uploadContiner}>
            <Paragraph fontSize='14' color={Colors.sinineVaal}>{selectedFile.path}</Paragraph></div>}
        </Grid>}
        {type === 'LINK' && <Grid item xs={gridSize}>
          <VTextField name="url" label="register.relatedDocument.link"
            disabled={isLoading} required maxLength={1000}
            register={register} errors={errors} fullWidth
          />
        </Grid>}
        <Grid item>
          <Button type="submit" size="small" aria-label="submit new document form" disabled={isLoading || (type === 'FILE' && !selectedFile)}>
            {t(type === 'FILE' ? 'register.relatedDocument.saveButton' : 'register.relatedDocument.addLink')}
          </Button>
          <Box marginLeft={1} display="inline">
            <Button size="small" color="secondary" onClick={onClose} aria-label="cancel" disabled={isLoading}>
              {t('button.cancelAlt')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  </Paper>;
}

export default function PlanDocumentTable({ compact, readOnly }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [addFileType, setAddFileType] = useState(null);
  const application = useSelector(state => state.application.selected);
  const files = useSelector(state => state.applicationFile.rows);
  const submitted = useSelector(state => state.applicationFile.submitted);
  const isLoading = useSelector(state => state.applicationFile.isLoading);

  useEffect(() => submitted && dispatch(resetSubmitted()) && setAddFileType(null), [submitted, dispatch]);

  const columns = [
    { field: 'description', headerName: t('register.relatedDocument.description'), filter: true },
    { field: 'created', headerName: t('register.relatedDocument.created'), type: 'date' },
    {
      field: "documentType", headerName: t('register.relatedDocument.documentType'),
      type: "classifier", classifierType: ClassifierType.planningDocumentType,
      formatClassifier: PlanningUtils.formatPlanningDocumentType
    },
    {
      field: 'action', width: 50, renderCell: (column, cell) => !readOnly && ApplicationUtils.isPlanFile(cell) &&
        <Box textAlign="right">
          <ConfirmButton
            message={t('form.confirmDelete')} icon="delete" disabled={isLoading}
            onConfirm={() => dispatch(deletePlanFile(application.id, cell.id))}
            aria-label="delete file"
          />
        </Box>
    },
  ];

  const addNewButtons = !readOnly && <>
    <Button
      size="extra-small"
      onClick={() => setAddFileType('FILE')}
      disabled={isLoading || !!addFileType}
    >
      {t(compact ? 'register.relatedDocument.addFileShort' : 'register.relatedDocument.addFile')}
    </Button>
    <Button
      size="extra-small"
      onClick={() => setAddFileType('LINK')}
      disabled={isLoading || !!addFileType}
    >
      {t('register.relatedDocument.addLink')}
    </Button>
  </>;

  const handleFileUpload = (data) => {
    dispatch(addPlanFile(application.id, data));
  };

  const handleRowSelection = (row) => {
    const url = !!row.fileName ? getFileDownloadUrl(application.id, row.id) : row.url;
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer')
    }
  };

  const showTable = !!files && (!compact || !addFileType);

  return <div>
    {addFileType && <AddDocumentForm type={addFileType} isLoading={isLoading}
      onConfirm={handleFileUpload} onClose={() => setAddFileType(null)} compact={compact} />}

    {showTable && <SimplePageableTable
      rows={files}
      columns={columns}
      header={<TableHeader buttons={addNewButtons}>{t('plan.view.documents')}</TableHeader>}
      sort={{ field: 'created', ascending: false }}
      pageSize={25}
      handleRowSelection={handleRowSelection}
    />}
  </div>;
}