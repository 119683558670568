import { Box, Grid, makeStyles, Paper, TableCell, Toolbar } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { Colors, Button, Heading, Icon, NavButton, Paragraph, TableHeader } from 'styleguide';
import { ClassifierType, Domain } from '../../constants/classifierConstants';
import i18n from '../../i18n';
import { fetchGet, fetchRowData, toggleRow } from '../../stores/register/registerReport';
import ChartUtils from '../../utils/ChartUtils';
import DateUtils from '../../utils/DateUtils';
import PdfUtils from '../../utils/PdfUtils';
import TableUtils from '../../utils/TableUtils';
import { CenteredLoadingIndicator } from '../form/LoadingIndicator';
import SimplePageableTable from '../table/SimplePageableTable';

const useStyles = makeStyles((theme) => ({
  flex: {
    flexGrow: 1
  },
  header: {
    paddingLeft: 16
  },
  toolbar: {
    padding: 0,
  },
  exportChart: {
    right: 24,
    top: 24,
    float: 'right',
    zIndex: 2
  },
  iconCell: {
    verticalAlign: 'middle',
    marginRight: 8
  },
}));

const getNetworkTypeTitle = (type, classifiers) => {
  const pipeType = TableUtils.getClassifierTitle(classifiers, ClassifierType.pipeType, type);
  if (pipeType) {
    return pipeType;
  } else {
    return i18n.t(`register.${type}.label`);
  }
};

export default function RainwaterNetworkReport() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const reportName = 'network';
  const rows = useSelector(state => state.registerReport.rows[reportName]);
  const isLoading = useSelector(state => state.registerReport.isLoading);
  const { classifiers } = useSelector(state => state.classifier);
  const compact = useSelector(state => state.global.mobileView);

  const title = t(`report.SV.network`);
  const subTitle = t('report.query');

  const columns = useMemo(() => {
    let columns = [
      {
        field: 'type', headerName: t('report.SV.networkType'), notSortable: true,
        renderCell: (column, cell) => <Grid container>
          {!!cell.type && <Icon icon={cell.open ? 'minus' : 'plus'} className={classes.iconCell} />}
          <Paragraph fontSize='14B'>
            {!!cell.type ? getNetworkTypeTitle(cell.type, classifiers) : t('report.table.total')}
          </Paragraph>
        </Grid>
      },
      {
        field: 'length', headerName: t(`report.table.length`), notSortable: true, renderCell: (column, cell) => {
          const value = `${TableUtils.formatNumber(cell.length) || 0} ${t('report.table.unitM')}`;
          if (!cell.type) {
            return <Paragraph fontSize='14B'>{value}</Paragraph>;
          }
          return value;
        }
      },
    ];
    return columns;
  }, [t, classifiers, classes]);

  const shouldFetch = !rows;
  useEffect(() => shouldFetch && dispatch(fetchGet(Domain.rainwater, reportName)), [dispatch, shouldFetch]);

  const handleClose = () => history.goBack();

  const header = <TableHeader>{t('report.table.rainwaterNetworkTitle')}</TableHeader>;

  if (!rows || isLoading) {
    return <CenteredLoadingIndicator />;
  }

  const handleChartExport = () => {
    PdfUtils.saveImage('chart', `${subTitle} ${title} ${DateUtils.nowISODate()}`);
  };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }) => {
    if (percent < 0.05) {
      return null;
    }
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" fontWeight="bold" fontSize="22px" stroke="black" strokeWidth="1px"
        textAnchor={'middle'} dominantBaseline="central">
        {`${name} ${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const handleRowSelection = (row) => {
    if (row.type) {
      if (!row.data) {
        dispatch(fetchRowData(Domain.rainwater, reportName, row, row.type));
      }
      dispatch(toggleRow(row, !row.open, reportName));
    }
  };

  const handleRenderCollapsibleRow = (row) => {
    if (row.open && row.data) {
      return <>
        <TableCell>{row.data.map((d, i) => <div key={i}>{d.title}</div>)}</TableCell>
        <TableCell>{row.data.map((d, i) => <div key={i}>{`${TableUtils.formatNumber(d.length)} ${t('report.table.unitM')}`}</div>)}</TableCell>
      </>;
    }
  };

  return <div>
    <Toolbar className={classes.toolbar}>
      <NavButton onClick={handleClose} action='back' color='secondary'></NavButton>
      <span className={classes.header}>
        <Heading level={compact ? '4' : '3'}>{title}</Heading>
        <Paragraph fontSize={compact ? '14' : '14B'}>{subTitle}</Paragraph>
      </span>
    </Toolbar>

    <Box py={4}>
      <SimplePageableTable
        id="report-table"
        rows={rows}
        columns={columns}
        header={header}
        pageSize={200}
        disableLoading
        loading={isLoading || !rows}
        loadingRowsCount={1}
        onRenderCollapsibleRow={handleRenderCollapsibleRow}
        onRowSelected={handleRowSelection}
      />
    </Box>

    {!compact && rows && <Paper>
      <Button size="extra-small" color="tertiary" onClick={handleChartExport}
        disabled={isLoading || !rows?.length} className={classes.exportChart}>
        <Icon icon="files" color={Colors.sinineVaal} /> {t('report.exportPng')}
      </Button>
      <ResponsiveContainer width="100%" height={600} id="chart">
        <PieChart
          width={500}
          height={600}
          margin={{
            top: -50,
            right: 20,
            left: 0,
            bottom: 100,
          }}>
          <Pie
            data={rows.filter(r => !!r.type)}
            labelLine={false}
            label={renderCustomizedLabel}
            fill="#8884d8"
            dataKey="length"
          >
            {rows.map((row, index) => (
              <Cell
                key={`cell-${index}`}
                fill={ChartUtils.getGraphColor(index)}
                name={getNetworkTypeTitle(row.type, classifiers)} />
            ))}
          </Pie>
          <Tooltip />
          <Legend iconSize={16} iconType="square" formatter={ChartUtils.renderLegendText} wrapperStyle={{ position: 'relative' }} />
        </PieChart>
      </ResponsiveContainer>
    </Paper>}
  </div>;
}