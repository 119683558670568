import { useContext, useEffect } from "react";
import MapContext from "../MapContext";
import axios from "axios";
import WMTS, {optionsFromCapabilities} from 'ol/source/WMTS.js';
import { WMTSCapabilities } from "ol/format";
import TileLayer from "ol/layer/Tile";
import { useDispatch, useSelector } from "react-redux";
import { setWMTSCapabilities } from "../../../stores/map/map";
import { showWarning } from "../../../stores/notification";
import config from "../../../config.json";

const parser = new WMTSCapabilities();

const WMTSLayer = ({ url, layer, zIndex = 0, queryable = false, title, styles, infoFormat, proxyUrl, authUser, authPass }) => {
	const { map } = useContext(MapContext);
	const dispatch = useDispatch();
	const { wmtsCapabilities } = useSelector(state => state.map);


	useEffect(() => {
		if (!map) return;

		let wmtsLayer;

		const getHeadersWithAuth = () => ({ 
			headers: {'Authorization': 'Basic ' + window.btoa(authUser + ":" + authPass)} 
		});

		const tileLoadFunction = async (tile, src) => {
			const response = await fetch(src, getHeadersWithAuth());
			const imageData = await response.blob();
			tile.getImage().src = window.URL.createObjectURL(imageData);
		};

    const fetchCapabilities = async (url) => {
			await axios.get(`${proxyUrl}/rest/WMTSCapabilities.xml`, getHeadersWithAuth()).then((response) => {
				if(response.status === 200){			
					dispatch(setWMTSCapabilities(url, response.data.replaceAll(url, proxyUrl)));
				}
      }, (error) => {
				console.warn(error);
				dispatch(showWarning('error.map.loadLayer'));
			});
    };

		const getFeatureInfo = async (infoUrl, tileMatrixSet, tileMatrix, tileCol, tileRow, pixels) => {   
    	infoUrl = infoUrl.replace('{style}','');
			infoUrl = infoUrl.replace('{TileMatrixSet}',tileMatrixSet);
			infoUrl = infoUrl.replace('{TileMatrix}', tileMatrix);
			infoUrl = infoUrl.replace('{TileRow}',tileRow);
			infoUrl = infoUrl.replace('{TileCol}',`${tileCol}/${pixels[0]}/${pixels[1]}`);
			infoUrl = infoUrl.replace('image/png8', infoFormat);
			infoUrl += '&feature_count=50';

			return await axios.get(infoUrl, getHeadersWithAuth()).then((response) => {
				if (response.status === 200 && response.data) {
					let features = response.data?.features?.map(feature => {
						let parsed = {
							'Id': feature.properties.id,
							'MKM kiht': feature.properties.layer,
							'MKM sümbol': feature.properties.symbol,
							'Objekti klass': feature.properties.object_class,
							'Objekti kirjeldus': feature.properties.kirjeldus
						};
						const extId = feature.properties.ext_job_id;
						if(extId) {
							parsed['Geoarhiivi töö'] = config.GEOVEEB_URL + extId;
						}
						parsed.geometry = feature.geometry;
						parsed.id = feature.id;
						return parsed;
					});

					return features;
				}
				return null;
			});
		};

		const addLayer = (config) => {
			const result = parser.read(config);
			
			const options = optionsFromCapabilities(result, {
				layer,
				matrixSet: 'EPSG:3301',
				crossOrigin: 'Anonymous',
				requestEncoding: 'rest',
			});

			const source = new WMTS(options);
			source.setTileLoadFunction(tileLoadFunction);

			wmtsLayer = new TileLayer({
				opacity: 0.7,
				source,
				zIndex,
				queryable,
				minZoom: 3
			});
			wmtsLayer.set('title', title);
			wmtsLayer.set('layerLayerName', layer);
			wmtsLayer.set('getFeatureInfo', getFeatureInfo);

			map.addLayer(wmtsLayer);
		}

		const existingConfig = wmtsCapabilities[url];
		if(!existingConfig){
			fetchCapabilities(url);
		} else {
			addLayer(existingConfig);
		}

		return () => {
			if (map && wmtsLayer) {
				map.removeLayer(wmtsLayer);
			}
		};
	}, [map, url, layer, zIndex, queryable, title, styles, infoFormat, wmtsCapabilities, dispatch, proxyUrl, authUser, authPass]);

	return null;
};

export default WMTSLayer;