import { createSlice } from '@reduxjs/toolkit';
import { api } from "../api/masterPlanApi";
import StoreUtils from '../utils/StoreUtils';
import { showSuccess, showWarning } from './notification';
import { toggleActiveControl } from './map/map';

// Slice
const slice = createSlice({
  name: 'masterPlan',
  initialState: {
    job: null,
    isLoading: false,
    error: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    jobSuccess: (state, action) => {
      state.job = action.payload;
      state.isLoading = false;
    }
  },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, jobSuccess } = slice.actions;

export const exportArea = (type, clip, wkt, layers) => async dispatch => {
  dispatch(startLoading());
  try {
    if(layers.includes('koondplaani_kihid')){
      layers = [];
    } 
    const body = {
      output: type,
      clip, 
      wkt,
      layers: layers.filter(l => l.startsWith('kpviimsivv_ext:')).map(l => l.replace('kpviimsivv_ext:', 'mkm_'))
    };
    await api.post('/', body).then((response) => {
      const job = response.data;
      dispatch(checkJobStatus(job));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const checkJobStatus = (job) => async dispatch => {
  switch(job.status){
    case 'enqueued':
    case 'processing':
    case 'created':
      dispatch(jobSuccess(job));
      setTimeout(() => dispatch(getJobStatus(job)), 1000);
      return;
    case 'completed':
      window.open(`${api.defaults.baseURL}/file/${job.jobID}/${job.token}`, '_blank', 'noopener,noreferrer');
      dispatch(jobSuccess(null));
      dispatch(showSuccess('map.masterPlan.donwloaded'));
      dispatch(toggleActiveControl('download-master-plan'));
      return;
    default: 
      dispatch(jobSuccess(null));
      dispatch(showWarning('map.masterPlan.error'));
  }
};

export const getJobStatus = (job) => async dispatch => {
  try {
    await api.get(`/job/${job.jobID}/${job.token}`).then((response) => {
      dispatch(checkJobStatus(response.data));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};
