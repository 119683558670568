import React from 'react';
import Footer from './Footer';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      paddingTop: 70,
      paddingBottom: 100,
      paddingLeft: 152,
      paddingRight: 152
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 40,
      paddingBottom: 10,
      paddingLeft: 4,
      paddingRight: 4
    },
  },
}));

export default function PageContainer({ children }) {
  const classes = useStyles();

  return <div className={classes.container}>
    {children}
    <Footer/>
  </div>;
}