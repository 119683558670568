import { useContext, useEffect } from "react";
import MapContext from "../MapContext";
import OLImageLayer from "ol/layer/Image";
import OLImageWms from "ol/source/ImageWMS";

const ImageWMSLayer = ({ url, layers, zIndex = 0, queryable = false, title, layerLayerName, styles, geometryName, groupedLayers, cqlFilter, infoFormat }) => {
	const { map } = useContext(MapContext);

	useEffect(() => {
		if (!map) return;

		let wmsLayer = new OLImageLayer({
			source: new OLImageWms({
				url: url,
				crossOrigin: "Anonymous",
				params: {
					"LAYERS": layers,
					"TILED": true,
					"VERSION": "1.1.1",
					"STYLES": styles || '',
					"CQL_FILTER": cqlFilter
				},
				serverType: "geoserver"
			}),
			zIndex,
			queryable,
			minZoom: 3
		});
		wmsLayer.set('title', title);
		wmsLayer.set('layerLayerName', layerLayerName);
		wmsLayer.set('geometryName', geometryName);
		wmsLayer.set('groupedLayers', groupedLayers);
		wmsLayer.set('infoFormat', infoFormat || 'application/json');

		map.addLayer(wmsLayer);

		return () => {
			if (map) {
				map.removeLayer(wmsLayer);
			}
		};
	}, [map, url, layers, zIndex, queryable, title, layerLayerName, styles, geometryName, groupedLayers, cqlFilter, infoFormat]);

	return null;
};

export default ImageWMSLayer;