import { Grid, Link, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from 'styleguide';
import Paragraph from 'styleguide/build/components/Typography/Paragraph/Paragraph';
import PageContainer from '../parts/PageContainer';
import { getCapabilitiesUrl } from '../stores/map/map';
import RouteUtils from '../utils/RouteUtils';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1000,
    margin: 'auto'
  },
  description: {
    paddingTop: 24,
    paddingBottom: 18,
    whiteSpace: 'pre-line',
  },
  licence: {
    paddingBottom: 24,
    whiteSpace: 'pre-line',
    fontSize: 12,
    lineHeight: '18px',
    letterSpacing: 0.6,
  },
  link: {
    paddingLeft: 16
  }
}));

function GetCapabilitiesLink({ version }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return <Link href={getCapabilitiesUrl(version)} className={classes.link} target="_blank" rel="noreferrer">{t(`openData.wms${version}`)}</Link>;
}

export default function OpenData() {
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    RouteUtils.setDocumentTitle('openData');
  }, []);

  return <PageContainer>
    <Grid container direction="column" className={classes.container}>
      <Heading level="3">{t('openData.wms')}</Heading>

      <Paragraph fontSize="12" className={classes.description}>{t('openData.wmsDescription')}</Paragraph>

      <div className={classes.licence}>
        <span>{t('openData.licenceBefore')}</span>
        <Link href="https://creativecommons.org/licenses/by/4.0/" target="_blank" rel="noreferrer">{t('openData.licenceLink')}</Link>
        <span>{t('openData.licenceAfter')}</span>
      </div>

      <Paragraph fontSize="14B">
        {t('openData.getCapabilities')}
        <GetCapabilitiesLink version="1.1.1" />
        <GetCapabilitiesLink version="1.3.0" />
      </Paragraph>
    </Grid>
  </PageContainer>;
}