import { makeStyles, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Heading, Shadows } from 'styleguide';
import { addLocation, deleteLocation } from '../../../stores/application/applicationLocation';
import Controls from '../../map/controls/Controls';
import SelectFeatureControl from '../../map/controls/SelectFeatureControl';
import Layers from '../../map/layers/Layers';
import Map from '../../map/Map';
import { PageableTableContainer } from '../../table/PageableTable';
import ConfirmButton from '../../table/ConfirmButton';
import AddressSearch from '../../map/AddressSearch';
import LoadingIndicator from '../../form/LoadingIndicator';
import { setActiveGeometries, toggleSelectActive } from '../../../stores/map/map';
import MapUtils from '../../../utils/MapUtils';
import { toggleLoadingOverlay } from '../../../stores/notification';
import ActiveFeaturesLayer from '../../map/layers/ActiveFeaturesLayer';
import ZoomControl from '../../map/controls/ZoomControl';
import { ApplicationType } from '../../../constants/classifierConstants';
import { updateRelatedPlanning } from '../../../stores/application/applicationPurpose';
import { fetchTypeConfig } from '../../../stores/application/application';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    paddingBottom: 88
  },
  tableContainer: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 177,
      marginRight: 177
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 4,
      marginRight: 4
    },
    maxHeight: 300,
    overflow: 'auto',
    position: 'absolute',
    bottom: 100,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: Shadows.shadow2
  }
}));

export default function Step1Location({ application }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { layers, center, zoom, extent } = useSelector(state => state.map);
  const { cadastre } = useSelector(state => state.feature);
  const { rows } = useSelector(state => state.applicationLocation);
  const applicationId = application.id;
  const isYp = ApplicationType.YP === application.applicationType;

  const { typeConfig } = useSelector(state => state.application);
  const applicationType = application?.applicationType;
  const shouldFetchType = !!applicationId && (typeConfig?.applicationType !== applicationType);
  useEffect(() => shouldFetchType && dispatch(fetchTypeConfig(applicationId, applicationType)),
    [dispatch, applicationId, applicationType, shouldFetchType]);

  //set map extent after load
  useEffect(() => {
    if (rows?.length) {
      const geometries = rows.map(r => r.geometry);
      dispatch(setActiveGeometries(geometries, false));
    }
    if (ApplicationType.KT === application.applicationType && typeConfig) {
      dispatch(updateRelatedPlanning(applicationId, typeConfig));
    }
  }, [dispatch, rows, application, applicationId, typeConfig]);
  //add cadastre
  useEffect(() => cadastre && !!applicationId && dispatch(addLocation(applicationId, cadastre, rows)),
    [applicationId, cadastre, rows, dispatch]);
  //map select control active
  useEffect(() => dispatch(toggleSelectActive(true)), [dispatch]);

  if (!rows || !layers) {
    return <LoadingIndicator />;
  }

  let columns = [
    { field: 'address', headerName: t('application.applicationLocation.address') },
    { field: 'cadastre', headerName: t('application.applicationLocation.cadastre') },
    { field: 'purposes', headerName: t('application.applicationLocation.purpose') },
    { field: 'areaAndUnit', headerName: t('application.applicationLocation.area'), renderCell: (column, row) => row.areaAndUnit ? MapUtils.formatArea(row.areaAndUnit) : '' },
    {
      field: 'action', width: 50, renderCell: (column, cell) => !isYp &&
        <ConfirmButton message={t('application.applicationLocation.confirmDelete')} icon="close" size="small"
          onConfirm={() => dispatch(deleteLocation(application.id, cell.id))} aria-label="delete application location" />
    }
  ];

  const handleAddressSelect = (address) => {
    if (address.cadastre) {
      dispatch(toggleLoadingOverlay(true));
      dispatch(addLocation(applicationId, { cadastre: address.cadastre, coordinates: address.coordinates }));
    }
  };

  return <div className={classes.container}>
    <Map center={center} zoom={zoom} extent={extent}>
      <Layers>
        {MapUtils.getDefaultBaseLayer(layers)}
        {MapUtils.getCadastreLayer(layers)}
        <ActiveFeaturesLayer />
      </Layers>
      <Controls>
        <ZoomControl />
        {!isYp && <SelectFeatureControl onClick={MapUtils.handleCadastreSelect} />}
      </Controls>
      {!isYp && <AddressSearch onSelect={handleAddressSelect} />}
    </Map>

    <Paper className={classes.tableContainer}>
      <Heading level="4" margin="26px">{t('application.applicationLocation.title')}</Heading>

      <PageableTableContainer
        rows={rows}
        columns={columns}
        totalRows={rows.length}
        size={'small'}
      />
    </Paper>
  </div>;
}
