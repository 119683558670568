import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Tooltip, VChip } from 'styleguide';
import DialogForm from '../../form/DialogForm';
import VTextField from '../../form/VTextField';
import ControlledAutocomplete from '../../form/ControlledAutocomplete';
import { resetSubmitted, sendNotifications } from '../../../stores/application/applicationApplicant';
import { resetSubmitted as resetSubmittedPlanning, sendNotifications as sendNotificationsPlanning } from '../../../stores/planning/planningParticipant';
import { ClassifierType } from '../../../constants/classifierConstants';
import TableUtils from '../../../utils/TableUtils';
import LoadingIndicator from '../../form/LoadingIndicator';
import DateUtils from '../../../utils/DateUtils';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    [theme.breakpoints.up('md')]: {
      width: 800,
      margin: 'auto'
    },
  }
}));

function SendNotificationForm({ applicants, handleSubmitNotification, files, isLoading, operations, directPath }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const authUser = useSelector(state => state.auth.authUser);
  const classifiers = useSelector(state => state.classifier.classifiers);

  const planningUrl = window.location.origin + directPath;
  const { register, control, errors, handleSubmit, setError } = useForm({
    defaultValues: {
      content: t('plan.notification.defaultContent', {
        handlerName: authUser.fullName,
        link: planningUrl
      }),
      recipients: applicants?.filter(a => a.isSelected).filter((value, idx, self) => self.findIndex(v => v.code === value.code) === idx),
      files: []
    }
  });


  if (!applicants || !files) {
    return <LoadingIndicator />;
  }

  const title = t('plan.notification.send');

  const onSubmit = (inputData) => {
    let data = {
      title: inputData.title,
      content: inputData.content,
      fileIds: inputData.files.map(f => f.id),
      recipients: inputData.recipients
    };

    if (data.recipients.length === 0) {
      setError('recipients', { type: 'required', message: t('validation.required') });
      return;
    }
    if (inputData.operation) {
      data.operationId = inputData.operation.id;
    }

    handleSubmitNotification(data);
  };

  const getApplicantOptionLabel = (o) => o.fullName;

  return <DialogForm
    title={title}
    mainAction={
      <Button type="submit" disabled={isLoading}>
        {t('button.send')}
      </Button>
    }
    onSubmit={handleSubmit(onSubmit)}
    disabled={isLoading}
  >
    <Grid container direction="column" spacing={4} justifyContent="center" className={classes.formContainer}>
      {!!operations && <Grid item>
        <ControlledAutocomplete name="operation" label={t('planning.operation.label')}
          disabled={isLoading} control={control} errors={errors} required
          options={operations} getOptionLabel={(o) => TableUtils.getClassifierTitle(classifiers, ClassifierType.planningOperationType, o.operationType)}
          getOptionSelected={(option, value) => option.id === value.id}
        />
      </Grid>}
      <Grid item>
        <ControlledAutocomplete
          options={applicants}
          getOptionLabel={getApplicantOptionLabel}
          getOptionSelected={(option, value) => option.code === value.code}
          disabled={isLoading}
          label={t('plan.notification.recipients')}
          errors={errors}
          name="recipients"
          control={control}
          required multiple
          defaultValue={[]}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Tooltip key={index} title={option.email}>
                <span>
                  <VChip label={getApplicantOptionLabel(option)} {...getTagProps({ index })} />
                </span>
              </Tooltip>
            ))
          }
        />
      </Grid>
      <Grid item>
        <ControlledAutocomplete
          options={files.filter(f => !!f.fileName)}
          getOptionLabel={(option) => `${TableUtils.getClassifierTitle(classifiers, ClassifierType.planningDocumentType, option.documentType) || ''} - ${option.fileName}`}
          getOptionSelected={(option, value) => option.id === value.id}
          disabled={isLoading}
          label={t('plan.notification.documents')}
          errors={errors}
          name="files"
          control={control}
          required multiple
          defaultValue={[]}
        />
      </Grid>
      <Grid item>
        <VTextField name="title" label={t('plan.notification.title')}
          register={register} errors={errors} fullWidth disabled={isLoading} maxLength={200} required />
      </Grid>
      <Grid item>
        <VTextField name="content" label={t('plan.notification.content')}
          register={register} errors={errors} fullWidth disabled={isLoading} multiline minRows={4} required />
      </Grid>
    </Grid>
  </DialogForm>;
}

export function ApplicationSendNotificationForm() {
  const dispatch = useDispatch();
  const history = useHistory();

  const application = useSelector(state => state.application.selected);
  const applicants = useSelector(state => state.applicationApplicant.rows);
  const submitted = useSelector(state => state.applicationApplicant.submitted);
  const isLoading = useSelector(state => state.applicationApplicant.isLoading);
  const files = useSelector(state => state.applicationFile.rows);

  const applicationId = application.id;

  useEffect(() => {
    if (submitted) {
      history.goBack();
      dispatch(resetSubmitted());
    }
  }, [submitted, dispatch, history]);

  const handleSubmit = (data) => {
    dispatch(sendNotifications(applicationId, data))
  };

  return <SendNotificationForm
    applicants={applicants}
    files={files}
    handleSubmitNotification={handleSubmit}
    isLoading={isLoading}
    directPath={`/application/view/${applicationId}`}
  />;
}

export function PlanningSendNotificationForm() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { operationId } = useParams();

  const planning = useSelector(state => state.planning.selected);
  const participants = useSelector(state => state.planningParticipant.rows);
  const submitted = useSelector(state => state.planningParticipant.submitted);
  const isLoading = useSelector(state => state.planningParticipant.isLoading);
  const documents = useSelector(state => state.planningDocument.rows);
  const operations = useSelector(state => state.planningOperation.rows);

  const activeOperations = !operationId && operations?.filter(o => DateUtils.isFutureDate(o.endDate));

  useEffect(() => {
    if (submitted) {
      history.goBack();
      dispatch(resetSubmittedPlanning());
    }
  }, [submitted, dispatch, history]);

  const handleSubmit = (data) => {
    if (operationId) {
      data.operationId = operationId;
    }
    dispatch(sendNotificationsPlanning(planning.id, data))
  };

  return <SendNotificationForm
    applicants={participants}
    files={documents}
    handleSubmitNotification={handleSubmit}
    isLoading={isLoading}
    operations={activeOperations}
    directPath={`/plan/view/${planning.id}`}
  />;
}