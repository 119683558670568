import { makeStyles } from '@material-ui/core';
import React from 'react';
import { BasicLabel, TableHeader } from 'styleguide';

const useStyles = makeStyles((theme) => ({
  totalElementsLabel: {
    verticalAlign: 'middle'
  },
}));

export function TotalElementsLabel({ children }) {
  const classes = useStyles();

  if (!children) {
    return '';
  }
  return <BasicLabel isNumeric className={classes.totalElementsLabel}>{children}</BasicLabel>;
}

export default function TableHeaderWithCount({ children, count, ...rest }) {
  return <TableHeader {...rest}>
    {children}
    <TotalElementsLabel>{count}</TotalElementsLabel>
  </TableHeader>;
}