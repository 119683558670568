import { Divider, Grid, makeStyles, MenuItem, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ClassifierType } from '../../constants/classifierConstants';
import { add, fetchMapLayers, save } from '../../stores/admin/mapLayer';
import { fetchLayers } from '../../stores/map/map';
import ClassifierSelect from '../form/ClassifierSelect';
import ControlledAutocomplete from '../form/ControlledAutocomplete';
import ControlledSelect from '../form/ControlledSelect';
import DialogForm from '../form/DialogForm';
import VCheckbox from '../form/VCheckbox';
import VNumberField from '../form/VNumberField';
import VTextField from '../form/VTextField';

const useStyles = makeStyles({
  formBlock: {
    maxWidth: 846,
    margin: 'auto',
    padding: 25
  },
});

export default function MapLayerForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { selected, isLoading } = useSelector(state => state.mapLayer);
  const groups = useSelector(state => state.mapGroup.rows);
  const authUser = useSelector(state => state.auth.authUser);

  const { register, control, errors, handleSubmit, reset } = useForm({
    defaultValues: selected
  });
  const classes = useStyles();

  const [shouldRefreshTable, setShouldRefreshTable] = useState(false);

  useEffect(() => reset(selected), [selected, reset]);

  if (!selected) {
    return <></>;
  }

  const onSubmit = (data) => {
    if (data.id) {
      dispatch(save(data));
    } else {
      data.id = undefined;
      dispatch(add(data));
    }
    setShouldRefreshTable(true);
  };

  const onClose = () => {
    if (shouldRefreshTable) {
      dispatch(fetchMapLayers());
      dispatch(fetchLayers(authUser));
    }
    return false;
  };

  const title = selected.id ?
    t('admin.mapLayer.form.label', { title: selected.title }) :
    t('admin.mapLayer.form.labelNew');

  return <DialogForm
    title={title}
    onSubmit={handleSubmit(onSubmit)}
    onClose={onClose}
    disabled={isLoading}>
    <input type="hidden" name="id" ref={register} />
    <Paper elevation={0} className={classes.formBlock}>
      <Grid container direction="row" spacing={4} justifyContent="space-evenly" alignItems="baseline">
        <Grid container item lg={7} direction="column" spacing={4}>
          <Grid item sm={12}>
            <ControlledAutocomplete name="mapGroup" label={t('admin.mapLayer.mapGroup')}
              disabled={isLoading} control={control} errors={errors}
              options={groups} getOptionLabel={(o) => o.title}
              getOptionSelected={(option, value) => option.id === value.id} required
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="title" required label="admin.mapLayer.title"
              disabled={isLoading} maxLength={100}
              register={register} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="titleEng" label="admin.mapLayer.titleEng"
              disabled={isLoading} maxLength={100}
              register={register} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="description" multiline label="admin.mapLayer.description"
              disabled={isLoading}
              register={register} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}><Divider /></Grid>
          <Grid item sm={12}>
            <VTextField name="layerName" label="admin.mapLayer.layerName"
              disabled={isLoading} maxLength={200}
              register={register} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="serverUrl" label="admin.mapLayer.serverUrl"
              disabled={isLoading} maxLength={200}
              register={register} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <ControlledSelect name="serviceType" label={t('admin.mapLayer.serviceType')}
              disabled={isLoading} maxLength={100}
              control={control} errors={errors} fullWidth required
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value="WMS">WMS</MenuItem>
              <MenuItem value="WMTS">WMTS</MenuItem>
              <MenuItem value="TMS">TMS</MenuItem>
              <MenuItem value="VECTOR">Joonistuskiht</MenuItem>
            </ControlledSelect>
          </Grid>
          <Grid item sm={12}>
            <ControlledSelect name="legendType" label={t('admin.mapLayer.legendType')}
              disabled={isLoading} maxLength={100}
              control={control} errors={errors} fullWidth
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value="ICON">{t('admin.mapLayer.legendIcon')}</MenuItem>
              <MenuItem value="EXTENDED">{t('admin.mapLayer.legendExtended')}</MenuItem>
            </ControlledSelect>
          </Grid>
          <Grid item sm={12}>
            <VNumberField name="order" required label="admin.mapLayer.order"
              disabled={isLoading} control={control} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <VNumberField name="zIndex" required label="admin.mapLayer.zIndex"
              disabled={isLoading} control={control} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <VCheckbox name="queryable" label={t('admin.mapLayer.queryable')}
              control={control} errors={errors} disabled={isLoading} />
          </Grid>
          <Grid item sm={12}>
            <VCheckbox name="visible" label={t('admin.mapLayer.visible')}
              control={control} errors={errors} disabled={isLoading} />
          </Grid>
          <Grid item sm={12}><Divider /></Grid>
          <Grid item sm={12}>
            <VCheckbox name="isPublic" label={t('admin.mapLayer.isPublic')}
              control={control} errors={errors} disabled={isLoading} />
          </Grid>
          <Grid item sm={12}>
            <VCheckbox name="isGov" label={t('admin.mapLayer.isGov')}
              control={control} errors={errors} disabled={isLoading} />
          </Grid>
          <Grid item sm={12}>
            <VCheckbox name="isPartner" label={t('admin.mapLayer.isPartner')}
              control={control} errors={errors} disabled={isLoading} />
          </Grid>
          <Grid item sm={12}><Divider /></Grid>
          <Grid item sm={12}>
            <ClassifierSelect item={selected} name="domain" label="admin.mapLayer.domain" fullWidth
              disabled={isLoading} control={control} errors={errors} classifierType={ClassifierType.domain}
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="geometryName" label="admin.mapLayer.geometryName"
              disabled={isLoading} maxLength={100}
              register={register} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="registerObjectType" label="admin.mapLayer.registerObjectType"
              disabled={isLoading} maxLength={100}
              register={register} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="style" label="admin.mapLayer.style"
              disabled={isLoading} maxLength={100}
              register={register} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="infoFormat" label="admin.mapLayer.infoFormat"
              disabled={isLoading} maxLength={100}
              register={register} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="proxyUrl" label="admin.mapLayer.proxyUrl"
              disabled={isLoading} maxLength={100}
              register={register} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="proxyUser" label="admin.mapLayer.proxyUser"
              disabled={isLoading} maxLength={100}
              register={register} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="proxyPass" label="admin.mapLayer.proxyPass"
              disabled={isLoading} maxLength={100}
              register={register} errors={errors} fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  </DialogForm >;

}