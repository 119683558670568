import { Grid, makeStyles, Paper, List, ListItem, ListItemIcon, ListItemText, Card, CardHeader, Divider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Colors, Icon } from 'styleguide';
import { Controller, useForm } from 'react-hook-form';
import DialogForm from '../form/DialogForm';
import { addRolePermission, addRole, fetchPermissions, fetchRole, fetchRoles, removeRolePermission, saveRole } from '../../stores/admin/role';
import { cloneDeep } from 'lodash';
import VTextField from '../form/VTextField';
import ValidIcon from '../table/ValidIcon';
import VDateRangePicker from '../form/VDateRangePicker';
import clsx from 'clsx';

const useStyles = makeStyles({
  appBar: {
    position: 'relative',
  },
  featured: {
    backgroundColor: Colors.hall3,
  },
  formBlock: {
    maxWidth: 943,
    margin: 'auto',
    padding: 25
  },
  formDivider: {
    height: 8
  },
  status: {
    marginLeft: 20,
    verticalAlign: 'middle',
    display: 'inline-flex'
  }
});

function PermissionList({ items, permissions, title, onClick, icon }) {
  return <Grid item xs={10} lg={5}>
    <Card>
      <CardHeader title={title} />
      <Divider />
      <List dense component="div" role="list">
        {items.map((item) => {
          const labelId = `transfer-list-item-${item}-label`;

          return (
            <ListItem key={item} role="listitem" button onClick={() => onClick(item)}>
              <ListItemIcon><Icon icon={icon}></Icon></ListItemIcon>
              <ListItemText id={labelId} primary={permissions.find(p => p.code === item)?.title} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  </Grid>;
}

export default function RoleForm() {
  const { t } = useTranslation();
  let { roleId } = useParams();

  const dispatch = useDispatch();
  const { role, permissions, isLoading } = useSelector(state => state.role);
  const { control, register, errors, handleSubmit, reset, getValues, setError } = useForm({
    defaultValues: role
  });
  const classes = useStyles();

  const [shouldRefreshRoles, setShouldRefreshRoles] = useState(false);

  useEffect(() => dispatch(fetchRole(roleId)), [dispatch, roleId]);

  useEffect(() => permissions.length <= 0 && dispatch(fetchPermissions()), [dispatch, permissions]);

  useEffect(() => reset(role), [role, reset]);

  if (!role || !permissions || (roleId && !role.id)) {
    return <div></div>;
  }

  const onSubmit = (inputData) => {
    //TODO cleanup
    let data = cloneDeep(inputData);
    if (data?.validPeriod && data?.validPeriod.from) {
      data.validFromDate = data.validPeriod.from;
      data.validToDate = data.validPeriod.to;
      data.validPeriod = undefined;
    } else {
      setError("validPeriod", { type: "manual", message: t('validation.required') });
      return;
    }

    data.permissions = role.permissions;
    if (data.id) {
      dispatch(saveRole(data));
    } else {
      dispatch(addRole(data));
    }
    setShouldRefreshRoles(true);
  };

  const onClose = () => {
    if (shouldRefreshRoles) {
      dispatch(fetchRoles());
    }
    return false;
  };

  const title = role.id ?
    <div>
      {t('admin.roleForm.title', { role: `${role.title}` })}
      <div className={classes.status}><ValidIcon validFrom={role.validFromDate} validTo={role.validToDate} /></div>
    </div> : t('admin.roleForm.titleNew');

  const availablePermissions = permissions.flatMap((p) => p.code).filter(p => !role.permissions.includes(p));
  availablePermissions.sort();

  const onAddPermission = (permission) => dispatch(addRolePermission(getValues(), permission));
  const onRemovePermission = (permission) => dispatch(removeRolePermission(getValues(), permission));

  return <DialogForm title={title} onSubmit={handleSubmit(onSubmit)} onClose={onClose} disabled={isLoading}>
    <input type="hidden" name="id" ref={register} />
    <Paper elevation={0} className={classes.formBlock}>
      <Grid container direction="row" spacing={4} justifyContent="space-evenly" alignItems="baseline">
        <Grid container item lg={6} direction="column" spacing={4} alignItems="flex-start">
          <Grid item sm={12}>
            <VTextField name="title"
              disabled={isLoading} maxLength={100}
              register={register} errors={errors}
            />
          </Grid>
        </Grid>
        <Grid container item lg={6} direction="column" spacing={4} alignItems="flex-end">
          <Grid item sm={12}>
            <Controller
              render={(props) => (
                <VDateRangePicker
                  label={t('table.validPeriod')}
                  disabled={isLoading}
                  fromDisabled={!!role.id}
                  error={!!(errors && errors.validPeriod)}
                  helperText={errors && errors.validPeriod && errors.validPeriod.message}
                  value={props.value}
                  onChange={props.onChange}
                />
              )}
              name="validPeriod"
              defaultValue={{ from: role.validFromDate, to: role.validToDate }}
              control={control}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>

    <div className={classes.formDivider}></div>

    <Paper elevation={0} className={clsx(classes.formBlock, classes.featured)}>
      <Grid container>
        <PermissionList items={role.permissions} permissions={permissions} title={t('admin.roleForm.addedPermissions')} onClick={onRemovePermission} icon="tick" />
        <Grid item xs={2}>

        </Grid>
        <PermissionList items={availablePermissions} permissions={permissions} title={t('admin.roleForm.notAddedPermissions')} onClick={onAddPermission} icon="times" />
      </Grid>
    </Paper>
  </DialogForm>;
}