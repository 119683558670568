import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/workOrder';
import StoreUtils from '../../utils/StoreUtils';
import TableUtils from '../../utils/TableUtils';
import { showSuccess } from '../notification';
import { resetRows as resetLogRows } from '../activityLog';
import AuthUtils from '../../utils/AuthUtils';
import RegisterUtils from '../../utils/RegisterUtils';

const apiPath = "workOrder";
const initialPageable = {
  pageSize: TableUtils.getPageSize(178),
  pageNumber: 0,
  sort: [{
    field: "priority",
    ascending: true
  }, {
    field: "deadline",
    ascending: true
  }],
  loaded: false,
  last: true
};

// Slice
const slice = createSlice({
  name: 'workOrder',
  initialState: {
    rows: [],
    selected: null,
    files: [],
    pageable: initialPageable,
    totalElements: null,
    filter: {},
    userFilter: [],
    isLoading: false,
    isLoadingDomain: null,
    error: false,
    submitted: false,
    statistics: null
  },
  reducers: {
    startLoading: (state, action) => {
      state.isLoading = true;
      state.isLoadingDomain = action.payload;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    rowsSuccess: (state, action) => {
      if (state.isLoadingDomain === action.payload.domain) {
        const data = action.payload.data;
        state.rows = TableUtils.mergeArrayById(state.rows, data.content);
        state.pageable.last = data.last;
        state.totalElements = data.totalElements;
        state.pageable.loaded = true;
        state.isLoading = false;
        state.isLoadingDomain = null;
      }
    },
    resetRowsSuccess: (state) => {
      state.pageable.pageNumber = 0;
      state.pageable.loaded = false;
      state.totalElements = null;
      state.rows = [];
    },
    pageableSuccess: (state, action) => {
      if (action.payload.pageNumber) {
        state.pageable.pageNumber += action.payload.pageNumber;
      }
      if (action.payload.sort) {
        state.pageable.sort = action.payload.sort;
        state.pageable.pageNumber = 0;
        state.rows = [];
      }
      state.pageable.loaded = false;
    },
    filterSuccess: (state, action) => {
      if (action.payload.reset) {
        state.filter = {};
        return;
      }
      if ((state.filter[action.payload.field] || '') !== action.payload.value) {
        state.filter[action.payload.field] = action.payload.value;
        state.pageable.pageNumber = 0;
        state.rows = [];
        state.pageable.loaded = false;
      }
    },
    userFilterSuccess: (state, action) => {
      state.userFilter = action.payload || [];
    },
    selectedSuccess: (state, action) => {
      let fulfiller = null;
      let isGov = false;
      const userFulfiller = action.payload?.userFulfiller;
      const company = action.payload?.cooperationPartnerCompany;
      if (userFulfiller) {
        fulfiller = {
          id: userFulfiller.id,
          title: getFulfillerTitle(action.payload)
        };
        isGov = AuthUtils.isGov(action.payload.userFulfiller)
      } else if (company) {
        fulfiller = {
          id: company.id,
          title: company.title,
          isCompany: true
        };
      }
      state.selected = {
        ...action.payload,
        fulfiller,
        isGov,
        sendNotifications: (action.payload.notificationList?.length > 0)
      };
      state.files = [];
      state.isLoading = false;
    },
    submittedSuccess: (state, action) => {
      state.submitted = action.payload;
    },
    filesSuccess: (state, action) => {
      state.files = action.payload;
      if (action.payload.length && !state.selected.sendFiles) {
        state.selected.sendFiles = true;
      }
      state.isLoading = false;
    },
    addFileSuccess: (state, action) => {
      state.files.push(action.payload);
      state.isLoading = false;
    },
    removeFileSuccess: (state, action) => {
      state.files.splice(action.payload, 1);
      state.isLoading = false;
    },
    statisticsSuccess: (state, action) => {
      state.statistics = action.payload;
    }
  },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, rowsSuccess, pageableSuccess, filterSuccess, selectedSuccess,
  userFilterSuccess, submittedSuccess, resetRowsSuccess,
  filesSuccess, addFileSuccess, removeFileSuccess, statisticsSuccess } = slice.actions;

export const updatePageable = (pageNumber, sort) => async dispatch => {
  dispatch(pageableSuccess({ pageNumber: pageNumber, sort: sort }));
};

export const updateFilter = (field, value) => async dispatch => {
  dispatch(filterSuccess({ field, value }));
};

export const updateUserFilter = (userFilter) => async dispatch => {
  dispatch(userFilterSuccess(userFilter));
  dispatch(resetRows());
};

export const setDetails = (selected) => async dispatch => {
  dispatch(selectedSuccess(selected));
  if (selected.id)
    dispatch(fetchFiles(selected.id));
};

export const fetchRows = (domain, pageable, filter, columns, userRestriction) => async dispatch => {
  dispatch(startLoading(domain));
  try {
    const path = domain === 'archive' ? 'archived' : `active/${domain}`;
    let userRestParam = '';
    if (userRestriction.includes('created')) {
      userRestParam += '&showCreatedByMe=true';
    }
    if (userRestriction.includes('forFulfillment')) {
      userRestParam += '&showForFulfillmentByMe=true';
    }
    if (userRestriction.includes('notified')) {
      userRestParam += '&showNotified=true';
    }
    if (userRestriction.includes('fulfilled')) {
      userRestParam += '&showFulfilled=true';
    }
    await api.get(`${apiPath}/${path}?${TableUtils.pageableToParams(pageable, filter, columns)}${userRestParam}`).then((response) => {
      dispatch(rowsSuccess({ data: response.data, domain: domain }));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchRowsPartner = (isArchive, pageable, filter, columns, userRestriction) => async dispatch => {
  const path = isArchive ? 'archived' : 'active';
  dispatch(startLoading(path));
  try {
    let userRestParam = '';
    if (userRestriction.includes('forFulfillment')) {
      userRestParam += '&showForFulfillmentByMe=true';
    }
    if (userRestriction.includes('fulfilled')) {
      userRestParam += '&showFulfilled=true';
    }
    await api.get(`${apiPath}/${path}?${TableUtils.pageableToParams(pageable, filter, columns)}${userRestParam}`).then((response) => {
      dispatch(rowsSuccess({ data: response.data, domain: path }));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchSelected = (id) => async dispatch => {
  dispatch(startLoading());
  if (isNaN(id)) {
    dispatch(add({}));
    return;
  }
  try {
    await api.get(`${apiPath}/${id}`).then((response) => {
      dispatch(selectedSuccess(response.data));
      dispatch(fetchFiles(id));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchFiles = (id) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(`${apiPath}/${id}/file`).then((response) => {
      dispatch(filesSuccess(response.data?.fileInfoList));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
}

export const add = (object, files, isDone, callback) => async dispatch => {
  dispatch(startLoading());
  try {
    let formData = new FormData();
    formData.append('workOrder', new Blob([JSON.stringify(object)], { type: "application/json" }));
    if (files.length) {
      let documentTypes = [];
      files.forEach(file => {
        formData.append('files', file.file);
        documentTypes.push(file.documentType);
      });
      formData.append('documentTypes', new Blob([JSON.stringify(documentTypes)], { type: "application/json" }));
    }
    let path = apiPath;
    if (isDone) {
      path += '?submit=true';
    }
    await api.post(path, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then((response) => {
      dispatch(selectedSuccess(response.data));
      dispatch(resetRowsSuccess());
      dispatch(showSuccess("form.saved"));
      dispatch(submittedSuccess(true));
      if (callback) {
        callback(response.data.id);
      }
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const save = (object, isDone) => async dispatch => {
  dispatch(startLoading());
  try {
    let path = `${apiPath}/${object.id}`;
    if (isDone) {
      path += '?submit=true';
    }
    await api.patch(path, object).then((response) => {
      dispatch(resetRowsSuccess());
      dispatch(showSuccess("form.saved"));
      dispatch(submittedSuccess(true));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const handleWorkOrderDone = (workOrder) => async dispatch => {
  if (workOrder.userFulfiller?.id === workOrder.userAuthor?.id) {
    dispatch(takeForFulfillment(workOrder.id));
  } else {
    dispatch(sendForFulfillment(workOrder.id));
  }
};

export const saveDeadline = (objectId, deadline, reason) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath}/${objectId}/deadline`, { deadline, reason }).then((response) => {
      dispatch(resetRowsSuccess());
      dispatch(showSuccess("form.saved"));
      dispatch(submittedSuccess(true));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const sendForFulfillment = (workOrderId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath}/${workOrderId}/sendForFulfillment`).then((response) => {
      dispatch(resetRowsSuccess());
      dispatch(showSuccess("workOrder.notification.sentForFulfillment"));
      dispatch(submittedSuccess(true));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const takeForFulfillment = (workOrderId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath}/${workOrderId}/takeForFulfillment`).then((response) => {
      dispatch(setDetails(response.data));
      dispatch(resetRowsSuccess());
      dispatch(showSuccess("workOrder.notification.takenForFulfillment"));
      dispatch(resetLogRows());
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const setDeleted = (workOrderId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.delete(`${apiPath}/${workOrderId}`).then((response) => {
      dispatch(resetRowsSuccess());
      dispatch(showSuccess("workOrder.notification.deleted"));
      dispatch(submittedSuccess(true));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const setInvalid = (workOrderId, reason) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath}/${workOrderId}/setInvalid`, { reason }).then((response) => {
      dispatch(resetRowsSuccess());
      dispatch(showSuccess("workOrder.notification.invalidated"));
      dispatch(submittedSuccess(true));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const setCompleted = (workOrderId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath}/${workOrderId}/setCompleted`).then((response) => {
      dispatch(resetRowsSuccess());
      dispatch(showSuccess("workOrder.notification.completed"));
      dispatch(submittedSuccess(true));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const sendForRevision = (workOrderId, reason, deadline) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath}/${workOrderId}/sendForRevision`, { reason, deadline }).then((response) => {
      dispatch(resetRowsSuccess());
      dispatch(showSuccess("workOrder.notification.sentForRevision"));
      dispatch(submittedSuccess(true));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const setFulfilled = (workOrderId, data, files) => async dispatch => {
  dispatch(startLoading());
  try {
    let formData = new FormData();
    if (data.fulfillmentComment) {
      formData.append('comment', data.fulfillmentComment);
    }
    formData.append('fulfillmentDatetime', data.fulfillmentDatetime);
    if (files.length) {
      files.forEach(file => {
        formData.append('files', file);
      });
    }
    await api.patch(`${apiPath}/${workOrderId}/setFulfilled`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then((response) => {
      dispatch(resetRowsSuccess());
      dispatch(showSuccess("workOrder.notification.fulfilled"));
      dispatch(submittedSuccess(true));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const resetSubmitted = () => async dispatch => {
  dispatch(submittedSuccess(false));
};

export const resetRows = () => async dispatch => {
  dispatch(resetRowsSuccess());
};

export const getFileDownloadUrl = (workOrderId, fileId) => `${api.defaults.baseURL}/${apiPath}/${workOrderId}/file/${fileId}`;

export const addFile = (workOrderId, data) => async dispatch => {
  if (workOrderId) {
    dispatch(startLoading());
    try {
      let formData = new FormData();
      Object.keys(data).forEach(key => formData.append(key, data[key]));
      await api.post(`${apiPath}/${workOrderId}/file`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then((response) => {
        dispatch(addFileSuccess(response.data));
      })
    }
    catch (e) {
      dispatch(StoreUtils.handleError(e, hasError));
    }
  } else {
    dispatch(addFileSuccess({ documentType: data.documentType, fileName: data.file.path }));
  }
};

export const removeFile = (workOrderId, fileId, index) => async dispatch => {
  if (workOrderId) {
    dispatch(startLoading());
    try {
      await api.delete(`${apiPath}/${workOrderId}/file/${fileId}`).then((response) => {
        dispatch(removeFileSuccess(index));
      })
    }
    catch (e) {
      dispatch(StoreUtils.handleError(e, hasError));
    }
  } else {
    dispatch(removeFileSuccess(index));
  }
};

export const addWithRegisterObject = (domain, objectType, objectId, geometries) => async dispatch => {
  let geometry;
  if (geometries?.length) {
    if (geometries.length === 1) {
      geometry = geometries[0];
    } else {
      const geometryType = geometries[0].type;
      geometry = {
        type: `Multi${geometryType}`,
        coordinates: geometries.filter(g => g.type === geometryType).map(g => g.coordinates)
      }
    }
  }
  dispatch(setDetails({
    domain: domain,
    relatedTable: RegisterUtils.getSuperObjectType(objectType),
    relatedId: objectId,
    geometry: geometry
  }));
};

export const getFulfillerTitle = (workOrder) => {
  let fulfiller = '';
  if (workOrder.userFulfiller) {
    fulfiller = workOrder.userFulfiller?.fullName;
    if (workOrder.cooperationPartnerCompany) {
      fulfiller += ` (${workOrder.cooperationPartnerCompany.title})`;
    }
  } else if (workOrder.cooperationPartnerCompany) {
    fulfiller = workOrder.cooperationPartnerCompany.title;
  }
  return fulfiller;
};

export const fetchMyActive = () => async dispatch => {
  try {
    return await api.get(`${apiPath}/myActive`).then((response) => response.data);
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchWorkOrderStatistics = () => async dispatch => {
  try {
    await api.get(`${apiPath}/proceedingStatistics`).then((response) => {
      dispatch(statisticsSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};