import { Fab, makeStyles, Slide } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Colors, Icon, Shadows } from "styleguide";

const useStyles = makeStyles(() => ({
  scrollToTop: {
    position: 'fixed',
    bottom: 24,
    right: 24,
    boxShadow: Shadows.shadow3,
    zIndex: 2
  },
}));

export default function ScrollToTop() {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  useEffect(() => {
    const toggleVisibility = () => setIsVisible(window.pageYOffset > 500);

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return <Slide direction="up" in={isVisible} mountOnEnter unmountOnExit>
    <Fab color="secondary" className={classes.scrollToTop} onClick={scrollToTop} aria-label="scroll to top">
      <Icon icon="arrowUp" color={Colors.hall4} />
    </Fab>
  </Slide>;
}