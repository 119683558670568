import { configureStore, combineReducers } from '@reduxjs/toolkit';
import activityLog from './activityLog';
import address from './address';
import auth from './auth';
import application from './application/application';
import applicationLocation from './application/applicationLocation';
import applicationFile from './application/applicationFile';
import applicationApplicant from './application/applicationApplicant';
import applicationPurpose from './application/applicationPurpose';
import applicationReport from './application/applicationReport';
import clause from './application/clause';
import clauseFile from './application/clauseFile';
import sign from './application/sign';
import classifier from './admin/classifier';
import user from './admin/user';
import userNotification from './admin/userNotification';
import userNotificationRequest from './admin/userNotificationRequest';
import userProfile from './admin/userProfile';
import userManual from './admin/userManual';
import role from './admin/role';
import partner from './admin/partner';
import basicObjectType from './admin/basicObjectType';
import applicationType from './admin/applicationType';
import clauseConf from './admin/clauseConf';
import notification from './notification';
import map from './map/map';
import mapLayer from './admin/mapLayer';
import mapGroup from './admin/mapGroup';
import feature from './map/feature';
import register from './register/register';
import registerDocument from './register/registerDocument';
import registerReport from './register/registerReport';
import registerInfrastructure from './register/registerInfrastructure';
import registerCombine from './register/registerCombine';
import search from './search';
import workOrder from './workOrder/workOrder';
import workOrderReport from './workOrder/workOrderReport';
import global from './global';
import planning from './planning/planning';
import planningLocation from './planning/planningLocation';
import planningDocument from './planning/planningDocument';
import planningParticipant from './planning/planningParticipant';
import planningOperation from './planning/planningOperation';
import planningOperationPaged from './planning/planningOperationPaged';
import planningTempObject from './planning/planningTempObject';
import masterPlan from './masterPlan';
import cad2GeoJson from './cad2GeoJson';

const reducer = {
  activityLog,
  address,
  auth,
  application,
  applicationLocation,
  applicationFile,
  applicationApplicant,
  applicationPurpose,
  applicationReport,
  clause,
  clauseFile,
  sign,
  classifier,
  user,
  userNotification,
  userNotificationRequest,
  userProfile,
  userManual,
  role,
  partner,
  basicObjectType,
  applicationType,
  clauseConf,
  notification,
  map,
  mapLayer,
  mapGroup,
  feature,
  register,
  registerDocument,
  registerReport,
  registerInfrastructure,
  registerCombine,
  search,
  workOrder,
  workOrderReport,
  global,
  planning,
  planningLocation,
  planningDocument,
  planningParticipant,
  planningOperation,
  planningOperationPaged,
  planningTempObject,
  masterPlan,
  cad2GeoJson,
};

const store = configureStore({
  reducer: combineReducers(reducer)
});

if (process.env.REACT_APP_SVMS === 'true') {
  import('../svms/stores').then(r => {
    if (r && r.default) {
      const newRootReducer = combineReducers({
        ...reducer,
        ...r.default
      });

      store.replaceReducer(newRootReducer);
    }
  });
}

export default store;