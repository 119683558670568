import { Box, Grid, IconButton, makeStyles, Paper, TableCell, Toolbar } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Colors, Button, Heading, Icon, NavButton, Paragraph, TableHeader } from 'styleguide';
import { Domain } from '../../constants/classifierConstants';
import { setActiveGeometries, toggleActiveControl, toggleLayerDrawer } from '../../stores/map/map';
import { fetchGet, fetchRowData, toggleRow } from '../../stores/register/registerReport';
import ChartUtils from '../../utils/ChartUtils';
import DateUtils from '../../utils/DateUtils';
import PdfUtils from '../../utils/PdfUtils';
import RegisterUtils from '../../utils/RegisterUtils';
import TableUtils from '../../utils/TableUtils';
import { CenteredLoadingIndicator } from '../form/LoadingIndicator';
import SimplePageableTable from '../table/SimplePageableTable';

const useStyles = makeStyles((theme) => ({
  flex: {
    flexGrow: 1
  },
  header: {
    paddingLeft: 16
  },
  toolbar: {
    padding: 0,
  },
  exportChart: {
    right: 24,
    top: 24,
    float: 'right',
    zIndex: 2
  },
  iconCell: {
    verticalAlign: 'middle',
    marginRight: 8
  },
}));

export default function DitchNetworkReport() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const reportName = 'ditchNetwork';
  const rows = useSelector(state => state.registerReport.rows[reportName]);
  const isLoading = useSelector(state => state.registerReport.isLoading);
  const compact = useSelector(state => state.global.mobileView);

  const title = t(`report.SV.ditchNetwork`);
  const subTitle = t('report.query');

  const columns = useMemo(() => {
    const handleShowOnMap = (basin) => {
      if (basin.data?.length) {
        const geometryColumns = RegisterUtils.getRegisterGeometryColumns('ditch');
        if (geometryColumns.length) {
          dispatch(setActiveGeometries(
            geometryColumns.flatMap(geometryColumn => basin.data.filter(i => i[geometryColumn.field]).map(i => i[geometryColumn.field]))
          ));
          dispatch(toggleLayerDrawer(false));
          dispatch(toggleActiveControl(null, false));
          history.push(`/map`);
        }
      }
    };

    let columns = [
      {
        field: 'title', headerName: t('report.SV.ditchNetwork'), notSortable: true,
        renderCell: (column, cell) => <Grid container>
          {!!cell.title && <Icon icon={cell.open ? 'minus' : 'plus'} className={classes.iconCell} />}
          <Paragraph fontSize='14B'>
            {!!cell.title ? cell.title : t('report.table.total')}
          </Paragraph>
          {!!cell.open && <IconButton onClick={() => handleShowOnMap(cell)} size="small" title={t('button.showOnMap')}><Icon icon="map" /></IconButton>}
        </Grid>
      },
      {
        field: 'length', headerName: t('report.table.length'), notSortable: true, renderCell: (column, cell) => {
          const value = `${TableUtils.formatNumber(cell.length) || 0} ${t('report.table.unitM')}`;
          if (!cell.title) {
            return <Paragraph fontSize='14B'>{value}</Paragraph>;
          }
          return value;
        }
      },
      {
        field: 'area', headerName: t(`report.table.area`), notSortable: true, renderCell: (column, cell) => {
          const value = <span>
            {`${TableUtils.formatNumber(cell.area) || 0} ${t('report.table.unitM')}`}
            <sup>2</sup>
          </span>;
          if (!cell.title) {
            return <Paragraph fontSize='14B'>{value}</Paragraph>;
          }
          return value;
        }
      },
    ];
    return columns;
  }, [t, classes, history, dispatch]);

  const shouldFetch = !rows;
  useEffect(() => shouldFetch && dispatch(fetchGet(Domain.rainwater, reportName)), [dispatch, shouldFetch]);

  const handleClose = () => history.goBack();

  const header = <TableHeader>{t('report.table.ditchNetworkTitle')}</TableHeader>;

  if (!rows || isLoading) {
    return <CenteredLoadingIndicator />;
  }

  const handleChartExport = () => {
    PdfUtils.saveImage('chart', `${subTitle} ${title} ${DateUtils.nowISODate()}`);
  };

  const handleRowSelection = (row) => {
    if (row.title) {
      if (!row.data) {
        dispatch(fetchRowData(Domain.rainwater, reportName, row, row.id));
      }
      dispatch(toggleRow(row, !row.open, reportName));
    }
  };

  const handleRenderCollapsibleRow = (row) => {
    if (row.open && row.data) {
      return <>
        <TableCell>{row.data.map((d, i) => <div key={i}>{d.title || d.code}</div>)}</TableCell>
        <TableCell>{row.data.map((d, i) => <div key={i}>{`${TableUtils.formatNumber(d.length)} ${t('report.table.unitM')}`}</div>)}</TableCell>
        <TableCell />
      </>;
    }
  };

  return <div>
    <Toolbar className={classes.toolbar}>
      <NavButton onClick={handleClose} action='back' color='secondary'></NavButton>
      <span className={classes.header}>
        <Heading level={compact ? '4' : '3'}>{title}</Heading>
        <Paragraph fontSize={compact ? '14' : '14B'}>{subTitle}</Paragraph>
      </span>
    </Toolbar>

    <Box py={4}>
      <SimplePageableTable
        id="report-table"
        rows={rows}
        columns={columns}
        header={header}
        pageSize={200}
        disableLoading
        loading={isLoading || !rows}
        loadingRowsCount={1}
        onRenderCollapsibleRow={handleRenderCollapsibleRow}
        onRowSelected={handleRowSelection}
      />
    </Box>

    {!compact && rows && <Paper>
      <Button size="extra-small" color="tertiary" onClick={handleChartExport}
        disabled={isLoading || !rows?.length} className={classes.exportChart}>
        <Icon icon="files" color={Colors.sinineVaal} /> {t('report.exportPng')}
      </Button>
      <ResponsiveContainer width="100%" height={600} id="chart">
        <BarChart
          width={500}
          height={600}
          data={rows.filter(r => !!r.title)}
          margin={{
            top: 50,
            right: 50,
            left: 30,
            bottom: 100,
          }}>
          <CartesianGrid vertical={false} />
          <XAxis dataKey="title" />
          <YAxis yAxisId="left" orientation="left" unit="m" />
          <YAxis yAxisId="right" orientation="right" unit="m2" />
          <Tooltip />
          <Legend iconSize={16} iconType="square" formatter={ChartUtils.renderLegendText} wrapperStyle={{ position: 'relative' }} />
          <Bar yAxisId="left" dataKey="length" name={t('report.table.length')} fill={ChartUtils.getGraphColor(0)} radius={4}
            label={{ position: 'top' }} />
          <Bar yAxisId="right" dataKey="area" name={t(`report.table.area`)} fill={ChartUtils.getGraphColor(1)} radius={4}
            label={{ position: 'top' }} />
        </BarChart>
      </ResponsiveContainer>
    </Paper>}
  </div>;
}