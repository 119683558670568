import { MenuItem } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ControlledSelect from './ControlledSelect';
import TableUtils from '../../utils/TableUtils';

export default function ClassifierSelect({ classifierType, label, filter, displayClearValue = true, sortBy, disabled, formatItemTitle, ...rest }) {
  const { t } = useTranslation();

  const { classifiers } = useSelector(state => state.classifier);

  let filteredClassifiers = classifiers.filter(c => (!!disabled || c.valid) && c.typeCode === classifierType);

  if (filter) {
    filteredClassifiers = filteredClassifiers.filter(classifier => filter(classifier));
  }

  if (sortBy) {
    filteredClassifiers = TableUtils.sortArrayByString(filteredClassifiers, sortBy);
  }

  const getItemTitle = (classifier) => {
    if(formatItemTitle) {
      return formatItemTitle(classifier);
    }
    return classifier.title;
  }

  return <ControlledSelect displayClearValue={displayClearValue} label={t(label)} disabled={disabled} {...rest}>
    {filteredClassifiers.map((classifier, index) => (
      <MenuItem key={index} value={classifier.itemCode}>{getItemTitle(classifier)}</MenuItem>
    ))}
  </ControlledSelect>;
}