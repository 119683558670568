import { Box, Button, ClickAwayListener, Fade, FormControlLabel, Grid, IconButton, List, ListItem, ListItemText, makeStyles, Paper, Popper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { Colors, Checkbox, Icon, Paragraph, TableHeader } from 'styleguide';
import TableUtils from '../../utils/TableUtils';
import GovUserSelect from '../form/GovUserSelect';
import VTextField from '../form/VTextField';

const useStyles = makeStyles({
  loadMoreButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: 16,
    fontWeight: 700,
    padding: 32,
    display: 'block',
    width: 400
  },
  loadingCell: {
    borderRadius: 4,
    backgroundColor: Colors.hall3,
    height: 14,
    width: '50%',
    margin: '5px 0px'
  },
  filterButton: {
    color: Colors.sinineVaal,
    fontSize: 'inherit',
    fontWeight: 'bold',
    lineHeight: '16px',
    letterSpacing: 0.6,
    padding: 0,
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: Colors.withOpacity(Colors.sinineVaal, 0.3),
    borderRadius: 0,
    minWidth: 32,
    '&:hover': {
      backgroundColor: 'inherit'
    },
    '&.open': {
      borderBottomColor: Colors.sinineVaal
    }
  },
  filterField: {
    display: 'flex'
  },
  clearButton: {
    color: Colors.hapumarjapuu,
    fontWeight: 'normal',
    float: 'right',
    '&:hover': {
      backgroundColor: 'inherit'
    },
  },
  disablePadding: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0
  },
  filterPopper: {
    zIndex: 2000
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: Colors.hall3,
    zIndex: 2,
  },
  stickyHeaderTableContainer: {
    overflowX: 'initial'
  },
  expandedRow: {
    backgroundColor: Colors.hall3
  },
  expandButton: {
    paddingLeft: 0
  },
  iconCell: {
    verticalAlign: 'middle'
  },
  expandedValue: {
    textAlign: 'right'
  }
});

export function LoadingRows({ rowCount, columnCount }) {
  const classes = useStyles();

  let columns = [];
  for (let index = 0; index < columnCount; index++) {
    columns.push(
      <TableCell key={index}><div className={classes.loadingCell}></div></TableCell>
    );
  }

  let rows = [];
  for (let index = 0; index < rowCount; index++) {
    rows.push(<TableRow key={`loading-${index}`}>{columns}</TableRow>);
  }
  return rows;
}

function HeaderWithFilter({ filter, column, onSearch, classifiers }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const value = !!filter ? filter[column.field] : null;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleClose = (event) => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleSearch = (value) => {
    onSearch(column.field, value);
    setOpen(false);
  };

  const handleChangeClassifier = (classifierCode) => {
    let newValues = value ? [...value] : [];
    if (newValues.includes(classifierCode)) {
      newValues = newValues.filter(c => c !== classifierCode);
    } else {
      newValues.push(classifierCode);
    }
    handleSearch(newValues);
  };

  const handleChangeBoolean = (newValue) => {
    handleSearch(value?.includes(newValue) ? [] : [newValue]);
  };

  let filterComponent;
  switch (column.type) {
    case 'classifier':
      filterComponent = <List dense classes={{ root: classes.disablePadding }}>
        {classifiers.filter(c => c.typeCode === column.classifierType).map((classifier, index) => (
          <ListItem key={index} classes={{ root: classes.disablePadding }}>
            <ListItemText
              classes={{ root: classes.disablePadding }}
              secondary={<FormControlLabel
                control={<Checkbox checked={value?.includes(classifier.itemCode) || false} onClick={() => handleChangeClassifier(classifier.itemCode)} size="small" />}
                label={<Paragraph fontSize='12'>{classifier.title}</Paragraph>}
              />}
            />
          </ListItem>
        ))}
      </List>;
      break;
    case 'customIdList':
      filterComponent = <List dense classes={{ root: classes.disablePadding }}>
        {column.listValues?.map((listItem, index) => (
          <ListItem key={index} classes={{ root: classes.disablePadding }}>
            <ListItemText
              classes={{ root: classes.disablePadding }}
              secondary={<FormControlLabel
                control={<Checkbox checked={value?.includes(listItem.id) || false} onClick={() => handleChangeClassifier(listItem.id)} size="small" />}
                label={<Paragraph fontSize='12'>{listItem[column.listLabelKey]}</Paragraph>}
              />}
            />
          </ListItem>
        ))}
      </List>;
      break;
    case 'boolean':
      filterComponent = <List dense classes={{ root: classes.disablePadding }}>
        <ListItem classes={{ root: classes.disablePadding }}>
          <ListItemText
            classes={{ root: classes.disablePadding }}
            secondary={<FormControlLabel
              control={<Checkbox checked={value?.includes(true) || false} onClick={() => handleChangeBoolean(true)} size="small" />}
              label={<Paragraph fontSize='12'>{t('form.true')}</Paragraph>}
            />}
          />
        </ListItem>
        <ListItem classes={{ root: classes.disablePadding }}>
          <ListItemText
            classes={{ root: classes.disablePadding }}
            secondary={<FormControlLabel
              control={<Checkbox checked={value?.includes(false) || false} onClick={() => handleChangeBoolean(false)} size="small" />}
              label={<Paragraph fontSize='12'>{t('form.false')}</Paragraph>}
            />}
          />
        </ListItem>
      </List>;
      break;
    case 'govUser':
      filterComponent = <GovUserSelect
        label={column.headerName}
        className={classes.filterField}
        defaultValue={value}
        handleChange={handleSearch}
        autoFocus autoHighlight disablePortal
      />;
      break;
    default:
      filterComponent = <VTextField
        label={column.headerName}
        className={classes.filterField}
        defaultValue={value}
        onKeyDown={(event) => (event.key === 'Enter') && handleSearch(event.target.value)}
        autoFocus
      />;
  }

  if (!filterComponent) {
    return <span>{column.headerName}</span>;
  }

  return <>
    <Button onClick={handleClick} className={`${classes.filterButton} ${open && 'open'}`}>{column.headerName}</Button>
    <Popper open={open} anchorEl={anchorEl} placement="bottom" transition modifiers={{ offset: { enabled: true, offset: '0, 16' } }} className={classes.filterPopper}>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper elevation={2}>
            <ClickAwayListener onClickAway={handleClose}>
              <Box p={3} pb={4}>
                {filterComponent}
                <Button onClick={() => handleSearch('')} className={classes.clearButton} size="small">{t('button.clear')}</Button>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  </>;
}

function TableCellContent({ column, row, columnIndex, classifiers }) {
  let content = '';
  if (row && column.renderCell) {
    content = column.renderCell(column, row);
  } else {
    const value = TableUtils.getCellValue(column, row, classifiers);

    if (columnIndex === 0) {
      content = <Paragraph fontSize='14B'>{value}</Paragraph>
    } else {
      content = value;
    }
  }

  return content || '';
};

function PageableTableRow({ columns, renderedColumns, row, onRenderCollapsibleRow, rowClassNameFunction,
  onRowSelected, onRowMouseEnter, onRowMouseLeave, classifiers }) {
  const classes = useStyles();
  const mobile = useSelector(state => state.global.mobileView);

  const [open, setOpen] = useState(false);

  const isColumnsHidden = mobile && columns.length > renderedColumns.length;

  let collapsedRow, hiddenColumnsRow, className = '';
  if (onRenderCollapsibleRow) {
    const data = onRenderCollapsibleRow(row);
    if (data) {
      className = "collapsible-row-opened ";
      collapsedRow = <TableRow className={className}>
        {data}
      </TableRow>;
    }
  }
  if (isColumnsHidden && open) {
    className = "collapsible-row-opened ";
    hiddenColumnsRow = <TableRow className={className}>
      <TableCell colSpan={3}>
        <Grid container direction="row" justifyContent="space-between">
          {columns.map((column, index) => {
            if (index >= renderedColumns.length) {
              return <React.Fragment key={index}>
                <Grid item xs={6}>
                  <Paragraph fontSize="14B">{column.headerName}</Paragraph>
                </Grid>
                <Grid item xs={6} className={classes.expandedValue}>
                  <TableCellContent column={column} row={row} columnIndex={index} classifiers={classifiers} />
                </Grid>
              </React.Fragment>
            }
            return "";
          })}
        </Grid>
      </TableCell>
    </TableRow>;
  }
  if (rowClassNameFunction) {
    className += rowClassNameFunction(row);
  }

  const toggleOpen = (event) => {
    event.stopPropagation();
    setOpen(!open);
  };

  return <React.Fragment>
    <TableRow
      onClick={() => onRowSelected && onRowSelected(row)}
      onMouseEnter={() => onRowMouseEnter && onRowMouseEnter(row)}
      onMouseLeave={() => onRowMouseLeave && onRowMouseLeave(row)}
      selected={row?.rowSelected}
      className={className}>
      {!!isColumnsHidden && <TableCell align='left' onClick={toggleOpen} >
        <Icon icon={open ? 'minus' : 'plus'} className={classes.iconCell} />
      </TableCell>}
      {renderedColumns.map((column, columnIndex) => (
        <TableCell key={columnIndex}>
          <TableCellContent column={column} row={row} columnIndex={columnIndex} classifiers={classifiers} />
        </TableCell>
      ))}
    </TableRow>
    {hiddenColumnsRow}
    {collapsedRow}
  </React.Fragment>;
}

export default function PageableTable({ id, rows, columns, filter, pageable, loading, ariaLabel, size, loadingRowsCount,
  onUpdatePageable, onUpdateFilter, onRowSelected, onRowMouseEnter, onRowMouseLeave, stickyHeader, onRenderCollapsibleRow,
  rowClassNameFunction }) {
  const { t } = useTranslation();
  const { classifiers } = useSelector(state => state.classifier);
  const classes = useStyles();

  const mobile = useSelector(state => state.global.mobileView);

  if (!columns) {
    return "";
  }

  const renderedColumns = !mobile ? columns : columns.slice(0, 2);
  const isColumnsHidden = mobile && columns.length > renderedColumns.length;

  const sortableTable = !!pageable;

  const renderSortIcon = (field, sort) => {
    let icon = 'sort';
    let ascending = true;
    let currentSort = Array.isArray(sort) ? sort.find(s => s.field === field) : sort;
    if (currentSort?.field === field) {
      icon += currentSort.ascending ? 'Asc' : 'Desc';
      ascending = !currentSort.ascending;
    }
    return <IconButton size="small" onClick={() => handleSort(field, ascending)} disabled={loading}><Icon icon={icon} /></IconButton>;
  };

  const renderHeader = (column, sort, sortableTable) => {
    if (!!column.renderHeader) {
      return column.renderHeader(column);
    }
    if (!column.headerName) {
      return "";
    }
    let headerText = column.headerName;
    if (sortableTable && column.filter) {
      headerText = <HeaderWithFilter filter={filter} column={column} onSearch={onUpdateFilter} classifiers={classifiers} />;
    }
    return <>
      {headerText}
      {sortableTable && !column.notSortable && renderSortIcon(TableUtils.getSortField(column), sort)}
    </>;
  };

  const handleSort = (field, ascending) => {
    onUpdatePageable(null, { field, ascending });
  };

  return (<>
    <Table aria-label={ariaLabel} size={size} id={id}>
      <TableHead>
        <TableRow>
          {!!isColumnsHidden && <TableCell />}
          {renderedColumns.map((column, index) => (
            <TableCell key={index} width={column.width} className={stickyHeader && classes.stickyHeader}>{renderHeader(column, pageable?.sort, sortableTable)}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) =>
          <PageableTableRow key={index}
            row={row} classifiers={classifiers}
            columns={columns} renderedColumns={renderedColumns}
            onRenderCollapsibleRow={onRenderCollapsibleRow}
            rowClassNameFunction={rowClassNameFunction}
            onRowSelected={onRowSelected}
            onRowMouseEnter={onRowMouseEnter} onRowMouseLeave={onRowMouseLeave}
          />
        )}
        {loading && <LoadingRows columnCount={renderedColumns.length} rowCount={loadingRowsCount} />}
      </TableBody>
      {!loading && !rows?.length && <TableFooter>
        <TableRow>
          <TableCell>
            <Paragraph fontSize='14' margin="10px">{t('table.noRows')}</Paragraph>
          </TableCell>
        </TableRow>
      </TableFooter>}
    </Table>
  </>);
}

export function PageableTableContainer({ header, headerText, rows, totalRows, disableLoading, singleRowOpenable, open, setOpen, ...props }) {
  const { pageable, onUpdatePageable, loading, stickyHeader } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const handleNextPage = () => {
    onUpdatePageable(1);
  };

  let showLoadMore = !disableLoading && pageable && !pageable.last;
  let loadingRowsCount = totalRows || pageable?.pageSize;
  let tableRows = rows;
  let tableHeader = header || (!!headerText ? <TableHeader>{headerText}</TableHeader> : '');
  let closedTableEffect;
  if (singleRowOpenable) {
    if (!open) {
      loadingRowsCount = 1;
    }
    if (rows.length > 1) {
      tableHeader = <TableHeader collapsible isOpen={open} onToggleOpen={setOpen}>{headerText}</TableHeader>;
      if (!open) {
        tableRows = rows.slice(0, 1);
        closedTableEffect = <div className="closed-table-effect"><div></div><div></div></div>;
        showLoadMore = false;
      }
    }
  }

  return (
    <div className='VTable'>
      <TableContainer component={Paper} className={stickyHeader && classes.stickyHeaderTableContainer}>
        {!!tableHeader && tableHeader}
        <PageableTable rows={tableRows} loadingRowsCount={loadingRowsCount} {...props} />
      </TableContainer>
      {showLoadMore && <Button className={classes.loadMoreButton} color="primary" size="large" onClick={handleNextPage} disabled={loading}>{t('table.loadMore')}</Button>}
      {closedTableEffect}
    </div>
  );
}

export function InfiniteScrollTableContainer(props) {
  const { singleRowOpenable, pageable, onUpdatePageable, rows, disableLoading } = props;
  const [open, setOpen] = useState(false);

  const tableContainer = <PageableTableContainer open={open} setOpen={setOpen} {...props} />;
  if (disableLoading || (singleRowOpenable && !open)) {
    return tableContainer;
  }

  return <InfiniteScroll
    dataLength={rows.length}
    next={() => onUpdatePageable(1)}
    hasMore={!pageable.last}
  >
    {tableContainer}
  </InfiniteScroll>;
}