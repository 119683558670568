import { IconButton } from "@material-ui/core";
import { ProcedureObjectDefs } from "../constants/procedureConstants";
import i18n from 'i18next';
import { Icon } from "styleguide";

const ProcedureUtils = {
  objectDefs: ProcedureObjectDefs,
  getObjectDef(objectType) {
    return this.objectDefs[objectType];
  },
  getProcedureColumns(objectType) {
    if (!objectType || !this.getObjectDef(objectType)) {
      return [];
    }
    return this.getObjectDef(objectType).columns.filter(column =>
      !column.detailsOnly &&
      (!column.showMapButton) &&
      (!column.relation || column.includeInMapDialog)
    ).map(obj => {
      if (obj.showMapButton) {
        return {
          ...obj, width: 80, renderCell: (column, row) => {
            return row[column.field] && <IconButton size="small" onClick={(e) => { e.stopPropagation() }}><Icon icon="map"></Icon></IconButton>
          }
        };
      } else {
        return { ...obj, headerName: this.getColumnName(objectType, obj) }
      }
    }
    );
  },
  getSuperObjectType(objectType) {
    return this.getObjectDef(objectType)?.superObjectType || objectType;
  },
  getColumnName(objectType, column) {
    return i18n.t(column.headerKey || `procedure.${this.getSuperObjectType(objectType)}.${column.field}`);
  }
};

export default ProcedureUtils;
