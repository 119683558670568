import { Grid, IconButton, makeStyles, Paper, Toolbar } from '@material-ui/core';
import { clone } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { Colors, Heading, Icon, Shadows } from 'styleguide';
import { Authority } from '../../constants/authConstants';
import { addDrawingGeometryToQueue } from '../../stores/map/feature';
import AuthUtils from '../../utils/AuthUtils';
import MapUtils from '../../utils/MapUtils';
import ClauseFeatureView from '../application/clause/ClauseFeatureView';
import PlanningFeatureView from '../planning/PlanningFeatureView';
import RegisterView from '../register/RegisterView';
import ToolbarButton from '../table/ToolbarButton';
import PlanTempObjectView from '../planning/PlanningTempObjectView';
import PlanTempObjectEdit from '../planning/PlanningTempObjectEdit';

const useStyles = makeStyles((theme) => ({
  dialog: {
    height: 'calc(100vh - 150px)',
    [theme.breakpoints.up('md')]: {
      width: 410,
      right: 100,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      right: 0,
    },
    overflow: 'auto',
    position: 'absolute',
    bottom: 24,
    boxShadow: Shadows.shadow2,
    padding: 12
  },
  close: {
    float: 'right',
    paddingTop: 18
  },
  detailRowDense: {
    padding: '2px 2px'
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
    width: '40%',
    display: 'inline-block'
  },
  flex: {
    flexGrow: 1
  },
  header: {
    paddingLeft: 16
  },
  toolbar: {
    padding: 0
  },
  fileLink: {
    textDecoration: 'none',
    fontWeight: 'bold',
    color: Colors.sinineVaal
  }
}));

const validateUrlPattern = (url) => {
  const pattern = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
  if (pattern.test(url)) {
    return true;
  }
  return false;
};

function GenericJsonView() {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { authUser } = useSelector(state => state.auth);

  const title = location.state.data.mapLayerTitle;
  const mapLayerLayerName = location.state.data.mapLayerLayerName;

  const data = clone(location.state.data);
  delete data.mapLayerTitle;
  delete data.mapLayerLayerName;

  if (!data) {
    history.goBack();
  }

  const prettyfyKey = (key) => {
    if(MapUtils.plankLayers.includes(mapLayerLayerName)){
      return key;
    }
    return MapUtils.prettyfyKey(key);
  }

  const handleAddToDrawingLayer = (event) => {
    event.stopPropagation();
    dispatch(addDrawingGeometryToQueue(data.geometry));
  };

  return <div>
    <Toolbar className={classes.toolbar}>
      <span className={classes.header}>
        <Heading level='4'>{title}</Heading>
      </span>
      <span className={classes.flex}></span>
      {data.geometry && AuthUtils.hasAuthority(authUser, Authority.UC40_object_add_drawing) && 
        <ToolbarButton compact={true} onClick={handleAddToDrawingLayer} icon="pencil" label={t('button.addToDrawingLayer')} />
      }
    </Toolbar>

    <Grid container spacing={0}>
      {Object.keys(data).filter(key => key !== 'id' && key !== 'geometry').map((key, index) => (
        <Grid key={index} item xs={12} lg={12}>
          <div className={classes.detailRowDense}>
            <span className={classes.label}>{prettyfyKey(key)}</span>
            {!!data[key] && validateUrlPattern(data[key]) ?
              <a href={data[key]}
                target="_blank"
                rel="noreferrer"
                className={classes.fileLink}
                title={t('link.open')}>
                {data[key]}
              </a>
              : data[key]}
          </div>
        </Grid>
      ))}
    </Grid>
  </div>;
}

export default function FeatureDetailsDialog() {
  const history = useHistory();
  const classes = useStyles();

  const handleClose = () => {
    history.replace('/map');
  };

  return (
    <Paper className={classes.dialog}>
      <span className={classes.close}>
        <IconButton onClick={handleClose} size="small"><Icon icon="times" /></IconButton>
      </span>
      <Route path="/map/f/r/viewPlanning/:objectType/:id" render={() => <PlanningFeatureView mapView />}/>
      <Route path="/map/f/r/viewClause/:id" render={() => <ClauseFeatureView mapView />}/>
      <Route path="/map/f/r/view/:objectType/:id" render={() => <RegisterView mapView />} />
      <Route path="/map/f/r/viewCenter/:objectType/:id" render={() => <RegisterView mapView mapCenter />} />
      <Route path="/map/f/g/view" component={GenericJsonView} />
      <Route path="/map/f/p/view" component={PlanTempObjectView}/>
      <Route path="/map/f/p/edit" component={PlanTempObjectEdit}/>
    </Paper>
  );
}