import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import FeatureDetailsDialog from '../components/map/FeatureDetailsDialog';
import { Route } from 'react-router-dom';
import DefaultMap from '../components/map/DefaultMap';
import MultiDrawControl from '../components/map/controls/MultiDrawControl';
import ExportPdfControl from '../components/map/controls/ExportPdfControl';
import MeasureControl from '../components/map/controls/MeasureControl';
import { useSelector } from 'react-redux';
import RouteUtils from '../utils/RouteUtils';
import AuthUtils from '../utils/AuthUtils';
import { Authority } from '../constants/authConstants';
import DownloadMasterPlanControl from '../components/map/controls/DownloadMasterPlanControl';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      height: "calc(100vh - 102px)"
    },
    [theme.breakpoints.down('sm')]: {
      height: "calc(100vh - 56px)"
    },
  },
}));

export default function MainMap() {
  const classes = useStyles();
  const mobileView = useSelector(state => state.global.mobileView);
  const { authUser } = useSelector(state => state.auth);

  useEffect(() => {
    RouteUtils.setDocumentTitle('map');
  }, []);

  const controls = [<ExportPdfControl />];
  if(AuthUtils.isGov(authUser) || AuthUtils.isPartner(authUser)){
    controls.push(<MultiDrawControl />);
  }
  if (!mobileView) {
    controls.push(<MeasureControl />);
    if(AuthUtils.hasAnyAuthority(authUser, [Authority.UC39_master_plan_export, Authority.UC39_partner_master_plan_export])){
      controls.push(<DownloadMasterPlanControl />);
    }
  }

  return (
    <div className={classes.container}>
      <DefaultMap extraControls={controls} />

      <Route path="/map/f" component={FeatureDetailsDialog} />
    </div>
  );
}