import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Colors, Icon } from "styleguide";

function MobileUserFilter({ value, items, onChange, disabled }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(value?.length > 0);

  const handleChange = (event, filter) => {
    if (!filter?.length) {
      setOpen(false);
    }
    onChange(event, filter);
  };

  return <div>
    {!open &&
      <Button onClick={() => setOpen(!open)} size="extra-small" color="tertiary">
        <Icon icon="filters" color={Colors.sinineVaal} /> {t('button.filter')}
      </Button>
    }
    {!!open && <UserFilterButtons value={value} items={items} onChange={handleChange} disabled={disabled} orientation="vertical" />}
  </div>;
}

function UserFilterButtons({ value, items, onChange, disabled, orientation }) {
  return <ToggleButtonGroup exclusive
    className="VaalToggleButton"
    value={value}
    onChange={onChange}
    aria-label='user filter buttons'
    orientation={orientation || 'horizontal'}
  >
    {items.map(item =>
      <ToggleButton key={item.value} value={item.value} aria-label='filter button' disabled={disabled}>
        {item.label}
      </ToggleButton>
    )}
  </ToggleButtonGroup>;
}

export default function UserFilter(props) {
  const mobile = useSelector(state => state.global.mobileView);

  if (!props?.items?.length) {
    return "";
  }

  if (mobile && props.items.length > 1) {
    return <MobileUserFilter {...props} />;
  }
  return <UserFilterButtons {...props} />;
}