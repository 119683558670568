import React, { useEffect } from 'react';
import PageContainer from '../parts/PageContainer';
import { PlanningStatus } from '../constants/classifierConstants';
import { Redirect, Route, useLocation } from 'react-router';
import PlanningTable from '../components/planning/PlanningTable';
import PlanningView from '../components/planning/PlanningView';
import OperationsTable from '../components/planning/OperationsTable';
import RouteUtils from '../utils/RouteUtils';

function PlanningRoute({ status }) {
  return <Route path={`/plan/${status}`} render={() => <PlanningTable status={status} />} exact />;
}

export default function Planning() {
  const location = useLocation();

  useEffect(() => {
    RouteUtils.setDocumentTitle('planning');
  }, []);

  switch (location.pathname) {
    case '/plan':
    case '/plan/':
      return <Redirect to="/plan/public" />;
    case `/plan/${PlanningStatus.completed}`:
    case `/plan/${PlanningStatus.invalid}`:
      return <Redirect to={`/plan/archive`} />
    default:
  }
  if (location.pathname === '/plan' || location.pathname === '/plan/') {
    return <Redirect to={`/plan/${PlanningStatus.initiated}`} />;
  }

  return <PageContainer>
    <Route path={`/plan/public`} component={OperationsTable} exact />
    <PlanningRoute status={PlanningStatus.initiated} />
    <PlanningRoute status={PlanningStatus.accepted} />
    <PlanningRoute status={PlanningStatus.established} />
    <PlanningRoute status="archive" />

    <Route path="/plan/view/:id" component={PlanningView} />
  </PageContainer>;
}