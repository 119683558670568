import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { debounce, List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { Search, Shadows } from "styleguide";
import { useDispatch, useSelector } from "react-redux";
import { resetAddress, searchAddress } from "../../stores/address";
import { setExtent } from "../../stores/map/map";
import { boundingExtent } from "ol/extent";
import LoadingIndicator from "../form/LoadingIndicator";

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    zIndex: 1100,
    [theme.breakpoints.up('md')]: {
      left: 177,
      top: 72,
      width: 600,
    },
    [theme.breakpoints.down('md')]: {
      left: 15,
      top: 41,
      width: '75%'
    },
  },
  searchBar: {
    borderRadius: 8,
    height: 48,
    width: '100%',
    backgroundColor: 'white',
    boxShadow: Shadows.shadow2
  },
  resultList: {
    backgroundColor: 'white',
    borderRadius: 8,
    marginTop: 5
  }
}));

export default function AddressSearch({ onSelect }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const formEl = useRef(null);

  const { rows, isLoading } = useSelector(state => state.address);

  useEffect(() => dispatch(resetAddress()), [dispatch]);

  const handleSearch = debounce((value) => {
    dispatch(searchAddress(value, true));
  }, 500);

  const handleSelect = (address) => {
    onSelect && onSelect(address);
    if (address.bbox) {
      const coordinates = address.bbox.split(' ').map(coordsString => coordsString.split(',').map(c => Number(c)));
      dispatch(setExtent(new boundingExtent(coordinates)));
    }
    dispatch(resetAddress());
    formEl.current.reset();
  };

  return <div className={classes.container}>
    <form ref={formEl}>
      <Search
        className={classes.searchBar}
        placeholder={t('addressSearch.inputAddressCadastre')}
        onChange={handleSearch}
        minCharacters={3}
      />
    </form>
    {(isLoading || !!rows.length) && <List dense className={classes.resultList}>
      {isLoading && <LoadingIndicator />}
      {!isLoading && rows.map((row, index) => (
        <ListItem key={index} button onClick={() => handleSelect(row)}>
          <ListItemText secondary={row.address} />
        </ListItem>
      ))}
    </List>}
  </div>;
};