import { Box, CircularProgress, Divider, List, ListItem, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Colors, Heading, Paragraph, Tooltip } from 'styleguide';
import { ClassifierType, Domain } from '../../constants/classifierConstants';
import { fetchInfrastructure } from '../../stores/register/registerInfrastructure';
import TableUtils from '../../utils/TableUtils';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 635,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    borderRadius: 16,
    marginBottom: 40,
  },
  paper: {
    borderRadius: 16,
    paddingBottom: 16,
  },
  title: {
    padding: 16,
  },
  listItem: {
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 16,
    paddingBottom: 8,
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  barListItem: {
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 0,
    paddingBottom: 16,
  },
  bar: {
    display: 'inline-block',
    height: 20,
    verticalAlign: 'middle',
  }
}));

function Bar({ value, label, color, index, isLast }) {
  const classes = useStyles();

  const leftRadius = `${index === 0 ? 4 : 0}px`;
  const rightRadius = `${isLast ? 4 : 0}px`;

  const percentage = `${value}%`;
  return <Tooltip title={`${label} ${percentage}`}>
    <div className={classes.bar} style={{
      width: percentage,
      borderRadius: `${leftRadius} ${rightRadius} ${rightRadius} ${leftRadius}`,
      backgroundColor: Colors.withOpacity(color, 1 / (index + 1))
    }}>
      <Paragraph fontSize="12" margin="2px">{(value > 5) ? percentage : ' '}</Paragraph>
    </div>
  </Tooltip>;
}

function BarListItem({ domain, data }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classifiers = useSelector(state => state.classifier.classifiers);

  switch (domain) {
    case Domain.streetlight:
      if (data.lampTypeTotal) {
        const lampTypes = Object.keys(data.lampTypeTotal);
        return <ListItem className={classes.barListItem}>
          {lampTypes.map((type, index) =>
            <Bar key={index}
              value={Math.round(data.lampTypeTotal[type] / data.amountTotal * 100)}
              label={TableUtils.getClassifierTitle(classifiers, ClassifierType.lampType, type)}
              color={Colors.sinineVaal}
              index={index} isLast={index === lampTypes.length - 1}
            />
          )}
        </ListItem>;
      }
      break;
    case Domain.road:
      if (data.roadSectionTypeLengths) {
        const roadTypes = Object.keys(data.roadSectionTypeLengths);
        let total = 0;
        roadTypes.forEach(key => total += data.roadSectionTypeLengths[key]);
        return <ListItem className={classes.barListItem}>
          {roadTypes.map((type, index) =>
            <Bar key={index}
              value={Math.round(data.roadSectionTypeLengths[type] / total * 100)}
              label={TableUtils.getClassifierTitle(classifiers, ClassifierType.roadType, type)}
              color={Colors.lavendel}
              index={index} isLast={index === roadTypes.length - 1}
            />
          )}
        </ListItem>;
      }
      break;
    case Domain.rainwater:
      if (data.lengths) {
        const rainwaterObjects = Object.keys(data.lengths);
        return <ListItem className={classes.barListItem}>
          {rainwaterObjects.map((type, index) =>
            <Bar key={index}
              value={!!data.lengthTotal ? (Math.round(data.lengths[type] / data.lengthTotal * 100)) : 0}
              label={t(`register.${type}.label`)}
              color={Colors.paikesekiir}
              index={index} isLast={index === rainwaterObjects.length - 1}
            />
          )}
        </ListItem>;
      }
      break;
    default:
  }
  return "";
}

function InfrastructureListItem({ domain }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const data = useSelector(state => state.registerInfrastructure.rows)[domain];
  const isLoading = useSelector(state => state.registerInfrastructure.isLoading)[domain];

  const translateKeyPrefix = `homepage.infrastructure.${domain}`;

  let details;
  if (data) {
    switch (domain) {
      case Domain.streetlight:
        details = {
          amountTotal: TableUtils.formatNumber(data.amountTotal || 0),
          powerTotal: TableUtils.formatNumber(data.powerTotal || 0),
        };
        break;
      case Domain.road:
        details = {
          roadAmountTotal: TableUtils.formatNumber(data.roadAmountTotal || 0),
          roadLengthTotal: TableUtils.formatNumber(Math.round(data.roadLengthTotal || 0)),
        };
        break;
      case Domain.rainwater:
        details = {
          drainageBasinCount: TableUtils.formatNumber(data.drainageBasinCount || 0),
          lengthTotal: TableUtils.formatNumber(Math.round(data.lengthTotal || 0)),
        };
        break;
      default:
    }
  }

  return <>
    <ListItem className={classes.listItem}>
      <Paragraph fontSize="14B">
        {t(`${translateKeyPrefix}.title`)}
      </Paragraph >
      <Box flexGrow={1} />
      {isLoading && <CircularProgress color="secondary" thickness={7} />}
      {!isLoading && !!data && <Paragraph>{t(`${translateKeyPrefix}.details`, details)}</Paragraph>}
    </ListItem >
    {!isLoading && !!data && <BarListItem domain={domain} data={data} />}
  </>;
}

export default function InfrastructureBlock() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => dispatch(fetchInfrastructure()), [dispatch])

  return <div className={classes.container}>
    <Paper className={classes.paper}>
      <List disablePadding>
        <ListItem>
          <Heading level="4" className={classes.title}>{t('homepage.block.infrastructure')}</Heading>
        </ListItem>

        <Divider />

        <InfrastructureListItem domain={Domain.streetlight} />
        <InfrastructureListItem domain={Domain.road} />
        <InfrastructureListItem domain={Domain.rainwater} />
      </List>
    </Paper>
  </div>;
}