import { Authority } from "../constants/authConstants";
import { PlanningStatus } from "../constants/classifierConstants";
import AuthUtils from "./AuthUtils";
import DateUtils from "./DateUtils";
import i18n from "../i18n";

const PlanningUtils = {
  isHandler: (authUser, planning) =>
    AuthUtils.hasAuthority(authUser, Authority.UC11_planning) && authUser.id === planning.handlerId,
  isHandlerOrSubstiturer: (authUser, planning) =>
    PlanningUtils.isHandler(authUser, planning) || AuthUtils.isSubstituter(authUser, planning.handlerId),
  isFinalized: (planning) => [PlanningStatus.completed, PlanningStatus.invalid].includes(planning.status),
  getActiveOperations: (operations, operationType) =>
    operations?.filter(op => op.operationType === operationType && DateUtils.isValid(op.startDate, op.endDate)),
  getOperationPeriodLabel: (operation) => {
    let periodKey = 'planning.operation.started';
    if (DateUtils.isValid(operation.startDate, operation.endDate)) {
      periodKey = 'planning.operation.started';
    } else if (!DateUtils.isPast(operation.startDate)) {
      periodKey = 'planning.operation.future';
    } else if (!DateUtils.isFuture(operation.endDate)) {
      periodKey = 'planning.operation.ended';
    }
    return `${i18n.t(periodKey)} ${DateUtils.formatDate(operation.startDate)}-${DateUtils.formatDate(operation.endDate) || '...'}`;
  },
  isParticipant: (authUser, participants) => participants?.some(p => p.fullName === authUser.fullName),
  formatPlanningDocumentType: (classifier) => {
    if(!classifier || !classifier.itemCode) {
      return null;
    }
    if(classifier.itemCode.length <= 5){
      return `${classifier.itemCode} ${classifier.title}`;
    }
    return classifier.title;
  }
};

export default PlanningUtils;