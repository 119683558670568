import { Grid, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import DialogForm from '../form/DialogForm';
import VTextField from '../form/VTextField';
import { add, fetchSelected, save } from '../../stores/admin/partner';
import { ClassifierType } from '../../constants/classifierConstants';
import ClassifierSelect from '../form/ClassifierSelect';
import VDateRangePicker from '../form/VDateRangePicker';
import ValidIcon from '../table/ValidIcon';
import { PageableTableContainer } from '../table/PageableTable';
import { Button, TableHeader } from 'styleguide';
import { LogsTable } from './UserForm';
import AddressSearchTextField from '../form/AddressSearchTextField';

const useStyles = makeStyles({
  appBar: {
    position: 'relative',
  },
  formBlock: {
    maxWidth: 846,
    margin: 'auto',
    padding: 25
  },
  formDivider: {
    height: 8
  },
  tableBlock: {
    maxWidth: 1046,
    margin: 'auto'
  },
  status: {
    marginLeft: 20,
    verticalAlign: 'middle',
    display: 'inline-flex'
  }
});

function UsersTable({ partnerId, rows, isLoading }) {
  const { t } = useTranslation();
  const history = useHistory();

  const columns = useMemo(() => [
    {
      field: 'valid', headerName: t('admin.partner.valid'), width: 150, renderCell: (column, cell) =>
        <ValidIcon valid={cell.valid} />
    },
    { field: 'person', headerName: t('admin.user.code'), type: "relation", relation: "person", relationField: "code" },
    { field: 'person', headerName: t('admin.user.firstName'), type: "relation", relation: "person", relationField: "firstName" },
    { field: 'person', headerName: t('admin.user.lastName'), type: "relation", relation: "person", relationField: "lastName" },
    { field: 'position', headerName: t('admin.partner.position') },
    { field: 'email', headerName: t('admin.user.email') },
    { field: 'phone', headerName: t('admin.user.phone') },
  ], [t]);

  const handleAddNew = () => {
    history.push(`/admin/partner/${partnerId}/user`);
  };

  const handleRowSelection = (row) => {
    history.push(`/admin/partner/${partnerId}/user/${row.id}`);
  };

  const header = () => (
    <TableHeader buttons={<Button size="extra-small" onClick={handleAddNew} disabled={isLoading}>{t('button.add')}</Button>}>
      {t('admin.partner.form.usersTitle')}
    </TableHeader>
  );

  return <PageableTableContainer
    rows={rows}
    columns={columns}
    header={header()}
    loading={isLoading}
    onRowSelected={handleRowSelection}
  />;
}

export default function PartnerForm() {
  const { t } = useTranslation();
  const { partnerId } = useParams();
  const dispatch = useDispatch();

  const { selected, users, logs, isLoading } = useSelector(state => state.partner);

  const { register, control, errors, handleSubmit, reset, setError, setValue } = useForm({
    defaultValues: selected
  });
  const classes = useStyles();

  useEffect(() => partnerId && selected?.id !== parseInt(partnerId) && dispatch(fetchSelected(parseInt(partnerId))), [dispatch, partnerId, selected]);
  useEffect(() => reset(selected), [selected, reset]);

  if (!selected) {
    return <></>;
  }

  const onSubmit = (data) => {
    if (data?.validPeriod && data?.validPeriod.from) {
      data.validFromDate = data.validPeriod.from;
      data.validToDate = data.validPeriod.to;
      data.validPeriod = undefined;
    } else {
      setError("validPeriod", { type: "manual", message: t('validation.required') });
      return;
    }
    if (data.id) {
      dispatch(save(data));
    } else {
      data.id = undefined;
      dispatch(add(data));
    }
  };

  const title = selected.id ?
    <div>{t('admin.partner.form.title', { title: selected.title })}<div className={classes.status}><ValidIcon valid={selected.valid} /></div></div> :
    t('admin.partner.form.titleNew');

  return <DialogForm title={title} onSubmit={handleSubmit(onSubmit)} disabled={isLoading}>
    <input type="hidden" name="id" ref={register} />
    <Paper elevation={0} className={classes.formBlock}>
      <Grid container direction="row" spacing={4} justifyContent="space-evenly" alignItems="baseline">
        <Grid container item lg={6} direction="column" spacing={4} alignItems="flex-start">
          <Grid item sm={12}>
            <VTextField name="regCode" required label="admin.partner.regCode"
              disabled={isLoading} maxLength={8}
              register={register} errors={errors}
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="title" required label="admin.partner.title"
              disabled={isLoading} maxLength={100}
              register={register} errors={errors}
            />
          </Grid>
          <Grid item sm={12}>
            <ClassifierSelect item={selected} name="domain" label="admin.partner.domain" required
              disabled={isLoading} control={control} errors={errors} classifierType={ClassifierType.domain}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              render={(props) => (
                <VDateRangePicker
                  label={t('table.validPeriod')}
                  disabled={isLoading}
                  error={!!(errors && errors.validPeriod)}
                  helperText={errors && errors.validPeriod && errors.validPeriod.message}
                  fromDisabled={true}
                  value={props.value}
                  onChange={props.onChange}
                />
              )}
              name="validPeriod"
              defaultValue={{ from: selected.validFromDate || new Date(), to: selected.validToDate }}
              control={control}
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="notes" label="admin.partner.notes" multiline
              disabled={isLoading} maxLength={2000}
              register={register} errors={errors}
            />
          </Grid>
        </Grid>
        <Grid container item lg={6} direction="column" spacing={4} alignItems="flex-end">
          <Grid item sm={12}>
            <AddressSearchTextField name="address" label="admin.partner.address"
              disabled={isLoading} setValue={setValue} indexName="index"
              register={register} errors={errors}
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="index" label="admin.partner.index"
              disabled={isLoading} maxLength={100}
              register={register} errors={errors}
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="email" label="admin.partner.email"
              disabled={isLoading} maxLength={100}
              pattern="email"
              register={register} errors={errors}
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="phone" label="admin.partner.phone"
              disabled={isLoading} maxLength={100}
              pattern="phone"
              register={register} errors={errors}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>

    <div className={classes.formDivider}></div>

    {selected.id && users !== null && <Paper elevation={0} className={classes.formTable}>
      <UsersTable partnerId={selected.id} rows={users} isLoading={isLoading} />
    </Paper>}

    <div className={classes.formDivider}></div>

    {logs && !!logs.length &&
      <Paper elevation={0} className={classes.formTable}>
        <LogsTable rows={logs} />
      </Paper>
    }
  </DialogForm>;
}