import { useContext, useEffect, useState } from "react";
import MapContext from "../MapContext";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Draw, { createBox } from "ol/interaction/Draw";
import MapControlButton from "./MapControlButton";
import { Paragraph, Radio } from "styleguide";
import { FormControlLabel, RadioGroup } from "@material-ui/core";
import LayerStyles from "../LayerStyles";
import { GeometryType } from "../../../constants/mapConstants";
import { WFS } from 'ol/format';
import Intersects from 'ol/format/filter/Intersects';
import { clearFeatures, fetchWFSGetFeature } from "../../../stores/map/feature";

const BBOX = "bbox";
const AREA = "area";

const createIntersectsFilter = (type, geometryName, feature) => {
  if (type === AREA) {
    return new Intersects(geometryName, feature.getGeometry());
  }
  return null;
};

const addQueryLayer = (queryLayers, geometryName, layerName) => {
  if (!queryLayers[geometryName]) {
    queryLayers[geometryName] = [layerName];
  } else {
    queryLayers[geometryName].push(layerName);
  }
};

const SelectAreaControl = () => {
  const controlName = 'select';
  const dispatch = useDispatch();
  const { map } = useContext(MapContext);
  const { activeControl } = useSelector(state => state.map);
  const [type, setType] = useState(BBOX);

  const { t } = useTranslation();

  const isActive = activeControl === controlName;

  useEffect(() => {
    if (!map || !type) return;

    let draw;

    if (isActive && type) {
      switch (type) {
        case BBOX:
          draw = new Draw({
            type: GeometryType.CIRCLE,
            geometryFunction: createBox(),
            style: LayerStyles.selectingMeasuring
          });
          map.addInteraction(draw);
          break;
        case AREA:
          draw = new Draw({
            type: GeometryType.POLYGON,
            style: LayerStyles.selectingMeasuring
          });
          map.addInteraction(draw);
          break;
        default:
      }

      draw.on('drawend', (event) => {
        dispatch(clearFeatures());

        const feature = event.feature;
        let source;
        const queryLayers = {};
        map.getLayers().forEach((layer) => {
          if (layer.getVisible() && layer.get('queryable')) {
            const url = layer.getSource().getUrl();
            if (url && url.startsWith('/')) {
              if (!source || url.startsWith('/vaal-admin')) {
                source = layer.getSource();
              }

              const geometryName = layer.get('geometryName');
              const groupedLayers = layer.get('groupedLayers');
              if (geometryName) {
                const layerName = layer.getSource().getParams()['LAYERS'];
                addQueryLayer(queryLayers, geometryName, layerName);
              } else if (groupedLayers) {
                groupedLayers.forEach(l => addQueryLayer(queryLayers, l.geometryName, l.layerName));
              }
            }
          }
        });

        if (queryLayers) {
          Object.keys(queryLayers).forEach(geometryName => {
            const featureRequest = new WFS().writeGetFeature({
              srsName: 'EPSG:3301',
              featureTypes: queryLayers[geometryName],
              outputFormat: 'application/json',
              geometryName: geometryName,
              bbox: feature.getGeometry().getExtent(),
              filter: createIntersectsFilter(type, geometryName, feature)
            });

            dispatch(fetchWFSGetFeature(source.getUrl(), featureRequest));
          });
        }
      });
    }

    return () => {
      draw && map.removeInteraction(draw);
    };
  }, [dispatch, map, type, isActive]);

  const handleChange = (event) => {
    setType(event.target.value);
  };

  return <MapControlButton controlName={controlName} icon="selectArea">
    <div className="ol-select-panel">
      <Paragraph>{t('map.control.select')}</Paragraph>

      <RadioGroup aria-label="select tool type radio buttons" value={type} onChange={handleChange}>
        <FormControlLabel value={BBOX} control={<Radio />} label={t('map.control.selectRectangle')} />
        <FormControlLabel value={AREA} control={<Radio />} label={t('map.control.selectArea')} />
      </RadioGroup>
    </div>
  </MapControlButton>;
};

export default SelectAreaControl;