import { CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VAutocomplete } from 'styleguide';
import { fetchGovUsers } from '../../stores/admin/user';
import ControlledAutocomplete from './ControlledAutocomplete';

export default function GovUserSelect({ name, control, required, label, disabled, errors, domain, defaultValue, defaultId, handleChange, ...rest }) {
  const dispatch = useDispatch();
  const { govUsers } = useSelector(state => state.user);

  const shouldFetchGovUsers = govUsers.length === 0;
  useEffect(() => shouldFetchGovUsers && dispatch(fetchGovUsers()), [shouldFetchGovUsers, dispatch]);

  const options = !domain ? govUsers : govUsers.filter(u => u.domain === domain);

  if (!govUsers?.length) {
    return <CircularProgress />;
  }

  let defaultUser = defaultValue || '';
  if (defaultId) {
    defaultUser = govUsers.find(u => u.id === defaultId);
  }

  if (!control) {
    return <VAutocomplete
      label={label}
      value={defaultUser}
      onChange={(event, value) => handleChange(value)}
      options={options}
      disabled={disabled}
      getOptionLabel={(o) => o.fullName || ''}
      {...rest}
    />;
  }

  return <ControlledAutocomplete
    options={options}
    getOptionLabel={(o) => o.fullName}
    getOptionSelected={(option, value) => {
      return option.id === value.id;
    }}
    disabled={disabled}
    label={label}
    errors={errors}
    name={name}
    control={control}
    required={required}
    defaultValue={defaultUser}
    {...rest}
  />;
}