import { Box, FormControlLabel, Grid, IconButton, Paper, Toolbar, makeStyles } from "@material-ui/core";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Colors, Heading, Icon, Shadows } from "styleguide";
import { resetCadResult } from "../../stores/cad2GeoJson";
import { useEffect, useState } from "react";
import { addDrawingGeometryToQueue } from "../../stores/map/feature";
import TableUtils from "../../utils/TableUtils";
import { GeometryType } from "../../constants/mapConstants";
import { showWarning } from "../../stores/notification";
import { centerFeatures } from "../../stores/map/map";
import MapUtils from "../../utils/MapUtils";

const useStyles = makeStyles((theme) => ({
  dialog: {
    height: 'calc(100vh - 150px)',
    [theme.breakpoints.up('md')]: {
      width: 410,
      right: 100,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      right: 0,
    },
    overflow: 'auto',
    position: 'absolute',
    bottom: 24,
    boxShadow: Shadows.shadow2,
    padding: 12
  },
  close: {
    float: 'right',
    paddingTop: 18
  },
  detailRowDense: {
    padding: '2px 2px'
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  symbolLabel: {
    paddingLeft: 16
  },
  flex: {
    flexGrow: 1
  },
  header: {
    paddingLeft: 16
  },
  toolbar: {
    padding: 0
  },
  fileLink: {
    textDecoration: 'none',
    fontWeight: 'bold',
    color: Colors.sinineVaal
  }
}));

const addedFeaturesLimit = 100;

const extractSymbols = (layerFeatures) => {
  const distinctSymbols = [...new Set(layerFeatures.map(f => f.properties?.SYMBOL))];
  const symbols = distinctSymbols.map(symbol => {
    const features = layerFeatures.filter(f => f.properties?.SYMBOL === symbol);
    return { 
      symbol,
      features,
      count: features.length
    };
  });
  return TableUtils.sortArrayByString(symbols, 'symbol');
};

export default function Cad2GeoJsonSelectDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result } = useSelector(state => state.cad2GeoJson);
  const [layers, setLayers] = useState([]);
  const [includePoints, setIncludePoints] = useState(true);
  const [includeLines, setIncludeLines] = useState(true);
  const [includePolygons, setIncludePolygons] = useState(true);

  useEffect(() => {
    if(result){
      const notIncludedGeometryTypes = [];
      if(!includePoints) {
        notIncludedGeometryTypes.push(GeometryType.POINT);
        notIncludedGeometryTypes.push(GeometryType.MULTI_POINT);
      }
      if(!includeLines) {
        notIncludedGeometryTypes.push(GeometryType.LINE_STRING);
        notIncludedGeometryTypes.push(GeometryType.MULTI_LINE_STRING);
      }
      if(!includePolygons) {
        notIncludedGeometryTypes.push(GeometryType.POLYGON);
        notIncludedGeometryTypes.push(GeometryType.MULTI_POLYGON);
      }

      const layers = [];
      const distinctLayers = [...new Set(result.features.map(f => f.properties?.LAYER))];
      distinctLayers.forEach(layer => {
        const features = result.features
          .filter(f => f.properties.LAYER === layer && !notIncludedGeometryTypes.includes(f.geometry.type));
        layers.push({
          layer, 
          symbols: extractSymbols(features),
          features,
          count: features.length,
        });
      });
      setLayers(TableUtils.sortArrayByString(layers, 'layer'));
    }
  }, [result, includePoints, includeLines, includePolygons]);

  if(!result || !result.features?.length) {
    return <></>;
  }

  const handleClose = () => {
    dispatch(resetCadResult());
  };
  
  const handleAddToDrawingLayer = (event, features) => {
    event.stopPropagation();
    if(features.length > 0) {
      if(features.length > 100){
        dispatch(showWarning('map.cad2GeoJson.tooManyFeatures', {limit: addedFeaturesLimit}))
      }
      const limitedFeatures = features.slice(0, addedFeaturesLimit);
      limitedFeatures.forEach(feature => dispatch(addDrawingGeometryToQueue(feature.geometry)));
      dispatch(centerFeatures(MapUtils.toOLFeatures(limitedFeatures)));
    }
  };

  return (
    <Paper className={classes.dialog}>
      <span className={classes.close}>
        <IconButton onClick={handleClose} size="small"><Icon icon="times" /></IconButton>
      </span>
      <Toolbar className={classes.toolbar}>
        <span className={classes.header}>
          <Heading level='4'>{t('map.cad2GeoJson.selectTitle')}</Heading>
        </span>
      </Toolbar>

      <Grid container spacing={0} alignItems="center">
        <Grid item xs={4}>
          <FormControlLabel
            label={t('map.cad2GeoJson.point')}
            control={<Checkbox size="small"
              onChange={(event) => setIncludePoints(event.target.checked)}
              checked={includePoints}
            />}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            label={t('map.cad2GeoJson.line')}
            control={<Checkbox size="small"
              onChange={(event) => setIncludeLines(event.target.checked)}
              checked={includeLines}
            />}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            label={t('map.cad2GeoJson.polygon')}
            control={<Checkbox size="small"
              onChange={(event) => setIncludePolygons(event.target.checked)}
              checked={includePolygons}
            />}
          />
        </Grid>
        <Grid item xs={12}><Box my={1}/></Grid>
        {layers.map((layer) => (
          <Grid key={layer.layer} item xs={12} lg={12} container alignItems="center">
            <Grid item xs={12}>
              <span className={classes.label}>{layer.layer}</span>
            </Grid>
            {layer.symbols.map(symbol => (
              <Grid key={symbol.symbol} item xs={12} container alignItems="center">
                <Grid item xs={6}>
                  <span className={classes.symbolLabel}>{symbol.symbol}</span>
                </Grid>
                <Grid item xs={5}>
                  {t('map.cad2GeoJson.featureCount', {count: symbol.count})}
                </Grid>
                <Grid item xs={1}>
                  <IconButton size="small" onClick={(e) => handleAddToDrawingLayer(e, symbol.features)} title={t('button.addToDrawingLayer')}>
                    <Icon icon="pencil"/>
                  </IconButton>
                </Grid>
              </Grid> 
            ))}
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}