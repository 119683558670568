import { createSlice } from '@reduxjs/toolkit';

// Slice
const slice = createSlice({
  name: 'global',
  initialState: {
    mobileView: true,
    homepageBlocks: null,
  },
  reducers: {
    changedMobileView: (state, action) => {
      state.mobileView = action.payload;
    },
    homepageBlocksSuccess: (state, action) => {
      state.homepageBlocks = action.payload;
    }
  },
});

export default slice.reducer;

// Actions

const { changedMobileView, homepageBlocksSuccess } = slice.actions;

export const changeViewMode = (mobile) => async dispatch => dispatch(changedMobileView(mobile));

export const setHomepageBlocks = (blocks) => async dispatch => dispatch(homepageBlocksSuccess(blocks));