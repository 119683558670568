import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/register';
import { showSuccess } from '../notification';
import StoreUtils from '../../utils/StoreUtils';

// Slice
const slice = createSlice({
  name: 'registerCombine',
  initialState: {
    joinedObjectId: null,
    rows: null,
    isLoading: false
  },
  reducers: {
    startLoading: state => {
      state.joinedObjectId = null;
      state.isLoading = true;
      state.formSaved = false;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    rowsSuccess: (state, action) => {
      state.rows = action.payload;
      state.isLoading = false;
    },
    joinSuccess: (state, action) => {
      state.joinedObjectId = action.payload.data.id;
      state.isLoading = false;
    },
    selectObjectSuccess: (state, action) => {
      state.rows.find(r => r.id === action.payload.id).rowSelected = action.payload.selected;
    },
  },
});

export default slice.reducer;

// Actions

const { rowsSuccess, selectObjectSuccess, joinSuccess, startLoading, hasError } = slice.actions;

export const resetRows = () => async dispatch => {
  dispatch(rowsSuccess(null));
};

export const toggleObjectSelected = (id, selected) => async dispatch => {
  dispatch(selectObjectSuccess({ id, selected }));
};

export const fetchCombineableObjects = (objectType, id) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(`${objectType}/${id}/combine`).then((response) => dispatch(rowsSuccess(response.data)));
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const combineObjects = (objectType, object, combinedObjects) => async dispatch => {
  dispatch(startLoading());
  try {
    let ids = combinedObjects.map(o => o.id);
    ids.push(object.id);
    await api.patch(`${objectType}/combine`, {
      mainObject: object,
      ids: ids
    }).then((response) => {
      dispatch(joinSuccess({ objectType: objectType, data: response.data }));
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};