import { useContext, useEffect } from "react";
import OLZoom from "ol/control/Zoom";
import MapContext from "../MapContext";
import { useTranslation } from "react-i18next";

const ZoomControl = () => {
	const { map } = useContext(MapContext);
	const { t } = useTranslation();

	useEffect(() => {
		if (!map) return;

		let zoomControl = new OLZoom({
			zoomInTipLabel: t('map.control.zoomIn'),
			zoomOutTipLabel: t('map.control.zoomOut')
		});

		map.controls.push(zoomControl);

		return () => map.controls.remove(zoomControl);
	}, [map, t]);

	return null;
};

export default ZoomControl;