import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import jsonp from 'jsonp';
import i18n from '../i18n';
import TableUtils from '../utils/TableUtils';
import { setExternalFeatures } from './map/feature';
import { setActiveFeatures } from './map/map';
import config from '../config.json';

//TODO make configurable
const inAdsGazeteerUrl = config.IN_ADS_GAZETEER_URL;
const ehakCode = config.EHAK_CODE;
const ehrGeoinfoUrl = config.EHR_GEOINFO_URL;

// Slice
const slice = createSlice({
  name: 'address',
  initialState: {
    rows: [],
    fullRows: [],
    isLoading: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    stopLoading: state => {
      state.isLoading = false;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    rowsSuccess: (state, action) => {
      state.rows = action.payload ? action.payload.map(a => ({
        adrId: a.adr_id,
        address: a.ipikkaadress,
        regularAddress: a.pikkaadress,
        shortAddress: a.aadresstekst,
        bbox: a.boundingbox,
        coordinates: [Number(a.viitepunkt_x), Number(a.viitepunkt_y)],
        cadastre: a.tunnus,
        index: a.sihtnumber
      })) : [];
      state.isLoading = false;
    },
    fullRowsSuccess: (state, action) => {
      let rows = [];
      if (action.payload) {
        rows = action.payload.flatMap(a => {
          if (a.appartments) {
            return a.appartments.map(p => ({ ...a, ...p, aadresstekst: a.aadresstekst + '-' + p.tahis, appartments: null, liikVal: 'KORTER' }));
          }
          return a;
        });
        rows = TableUtils.sortArrayByString(rows, 'aadresstekst');
      }
      state.fullRows = rows;
      state.isLoading = false;
    }
  },
});

export default slice.reducer;

// Actions

const { startLoading, stopLoading, hasError, rowsSuccess, fullRowsSuccess } = slice.actions;

const getAdsUrl = (address, onlyCadastres, withoutEhakRestriction) => {
  let url = `${inAdsGazeteerUrl}?address=${address}&features=KATASTRIYKSUS`;
  if (!onlyCadastres) {
    url += ',TANAV,VAIKEKOHT,EHITISHOONE';
  }
  if(!withoutEhakRestriction) {
    url += `&ehak=${ehakCode}`;
  }
  return url;
};

const getAdsUrlForWholeParish = () => {
  return `${inAdsGazeteerUrl}?request=getgeom&ehak=${ehakCode}`;
};

export const searchGeomForWholeParish = () => async dispatch => {
  dispatch(startLoading());
  return await axios.get(getAdsUrlForWholeParish()).then((response) => {
    if (response.data) {
      dispatch(stopLoading());
      return response.data.geom;
    }
  });
};

export const searchAddress = (value, onlyCadastres, withoutEhakRestriction) => async dispatch => {
  dispatch(startLoading());
  jsonp(getAdsUrl(value, onlyCadastres, withoutEhakRestriction), null, function (err, data) {
    if (err) {
      console.error(err.message);
      dispatch(hasError(err.message));
    } else {
      dispatch(rowsSuccess(data.addresses));
    }
  });
};

export const searchAddressAll = (value) => async dispatch => {
  dispatch(startLoading());
  jsonp(`${getAdsUrl(value)}&results=100&appartment=1`, null, function (err, data) {
    if (err) {
      console.error(err.message);
      dispatch(hasError(err.message));
    } else {
      dispatch(fullRowsSuccess(data.addresses));
    }
  });
};

export const resetAddress = () => async dispatch => {
  dispatch(rowsSuccess([]));
};

export const resetFullAddress = () => async dispatch => {
  dispatch(fullRowsSuccess([]));
};

export const loadEhrGeoinfo = (address) => async dispatch => {
  try {
    const params = `?address=${address}`;
    await axios.get(ehrGeoinfoUrl + params).then((response) => {
      if (response.data?.length) {
        const features = response.data.filter(f => f.properties.object_type !== 'KAYK');
        const jsonFeatures = features.map(f => {
          let object = {
            id: f.properties.object_code,
            geometry: f.geometry
          };
          object[i18n.t('map.ehr.object')] = f.properties.ET;
          object[i18n.t('map.ehr.status')] = i18n.t(`map.ehr.statusCode.${f.properties.object_status}`);
          return object;
        });
        dispatch(setExternalFeatures(jsonFeatures, i18n.t('map.ehr.title')));
        dispatch(setActiveFeatures(features, true, true));
      }
    });
  }
  catch (e) {
    console.error(e);
  }
};