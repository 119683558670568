import React from 'react';
import { BasicLabel } from 'styleguide';
import { ApplicationStatus } from '../../constants/classifierConstants';

export default function ApplicationStatusLabel({ statusClassifier }) {
  if (!statusClassifier) {
    return <span></span>;
  }

  let color = '';
  switch (statusClassifier.itemCode) {
    case ApplicationStatus.review:
    case ApplicationStatus.processing:
      color = 'blue';
      break;
    case ApplicationStatus.entered:
    case ApplicationStatus.editing:
      color = 'orange';
      break;
    case ApplicationStatus.submitted:
    case ApplicationStatus.processed:
    case ApplicationStatus.initiated:
    case ApplicationStatus.decisionYes:
      color = 'green';
      break;
    case ApplicationStatus.deleted:
    case ApplicationStatus.cancelled:
    case ApplicationStatus.declined:
    case ApplicationStatus.notInitiated:
    case ApplicationStatus.decisionNo:
      color = 'red';
      break;
    default:
      color = 'grey';
  }

  return <BasicLabel color={color}>{statusClassifier.title}</BasicLabel>;
}
