import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/admin';
import StoreUtils from '../../utils/StoreUtils';

const apiPath = 'manual';
// Slice
const slice = createSlice({
  name: 'userManual',
  initialState: {
    rows: null,
    open: false,
    isLoading: false,
    error: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    rowsSuccess: (state, action) => {
      state.rows = action.payload;
      state.isLoading = false;
    },
    openSuccess: (state, action) => {
      state.open = action.payload;
    }
  },
});

export default slice.reducer;

// Actions

const { rowsSuccess, openSuccess, startLoading, hasError } = slice.actions;

export const fetchUserManuals = () => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(apiPath).then((response) => {
      dispatch(rowsSuccess(response.data?.userManuals));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const setUserManualsOpen = (open) => async dispatch => dispatch(openSuccess(open));

export const getUserManualDownloadUrl = (manual) => `${api.defaults.baseURL}/${apiPath}/${manual.directory}/${manual.fileName}`;
