import { Box, makeStyles, Toolbar } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory, useParams, useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Heading, NavButton, TabContainer } from 'styleguide';
import { ClassifierType } from '../../constants/classifierConstants';
import { fetchSelected } from '../../stores/workOrder/workOrder';
import DateUtils from '../../utils/DateUtils';
import TableUtils from '../../utils/TableUtils';
import LoadingIndicator from '../form/LoadingIndicator';
import ScrollToTopOnMount from '../form/ScrollToTopOnMount';
import WorkOrderActions from './WorkOrderActions';
import WorkOrderDetails from './WorkOrderDetails';
import WorkOrderStatusLabel from './WorkOrderStatusLabel';

const useStyles = makeStyles((theme) => ({
  flex: {
    flexGrow: 1
  },
  header: {
    paddingLeft: 16
  },
  toolbar: {
    padding: 0
  },
  mapContainer: {
    height: 800,
    borderRadius: 8,
    overflow: 'hidden'
  },
  overviewBlock: {
    margin: '20px 0px',
  },
  overviewContainer: {
    [theme.breakpoints.up('md')]: {
      padding: '20px 40px'
    },
    [theme.breakpoints.down('md')]: {
      padding: 4
    },
  }
}));

export default function WorkOrderView({ mapView }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { path, url } = useRouteMatch();

  const { selected } = useSelector(state => state.workOrder);
  const { classifiers } = useSelector(state => state.classifier);
  const compact = useSelector(state => state.global.mobileView) || mapView;

  useEffect(() => id && (!selected || (!!selected.id && Number(id) !== selected.id)) && dispatch(fetchSelected(id)),
    [dispatch, id, selected]);

  const handleClose = () => {
    history.goBack();
  };

  if (!id || !selected) {
    return <LoadingIndicator />;
  }

  return <div>
    <ScrollToTopOnMount />
    <Toolbar className={classes.toolbar}>
      <NavButton onClick={handleClose} action='back' color='secondary'></NavButton>
      <span className={classes.header}>
        <Heading level='3'>{`${t('workOrder.form.title')} nr ${selected.id}`}</Heading>
        <Heading level='4'>{selected.location}</Heading>
      </span>

      <span className={classes.flex}></span>

      <Box textAlign="right">
        <WorkOrderStatusLabel statusClassifier={TableUtils.getClassifier(classifiers, ClassifierType.workOrderStatus, selected.status)} />
        <Heading level='4'>{`${t('workOrder.deadline')} ${DateUtils.formatDateTime(selected.deadline)}`}</Heading>
      </Box>
    </Toolbar>

    <Box py={compact ? 2 : 3}>
      <TabContainer width='100%' size={compact ? 'small' : 'big'}>
        <NavLink to={`${url}`} exact replace>{t('workOrder.view.details')}</NavLink>
        <NavLink to={`${url}/action`} replace>{t('workOrder.view.actions')}</NavLink>
      </TabContainer>
    </Box>

    <Route path={`${path}`} render={() => <WorkOrderDetails />} exact />
    <Route path={`${path}/action`} render={() => <WorkOrderActions />} />
  </div>;
}