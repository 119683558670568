import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/admin';
import TableUtils from '../../utils/TableUtils';
import { fetchClassifierTypes } from './classifier';
import { showSuccess } from '../notification';
import StoreUtils from '../../utils/StoreUtils';

const apiPath = "mapLayer";

const initialPageable = {
  pageSize: TableUtils.getPageSize(178),
  pageNumber: 0,
  sort: {
    field: "title",
    ascending: true
  },
  loaded: false,
  last: true
};

// Slice
const slice = createSlice({
  name: 'mapLayer',
  initialState: {
    rows: [],
    filteredRows: [],
    selected: [],
    pageable: initialPageable,
    filter: {},
    isLoading: false,
    error: false,
    formSaved: false

  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    rowsSuccess: (state, action) => {
      state.rows = action.payload;
      state.pageable.loaded = true;
      TableUtils.setFilteredRows(state);
      state.isLoading = false;
    },
    pageableSuccess: (state, action) => {
      if (action.payload.pageNumber) {
        state.pageable.pageNumber += action.payload.pageNumber;
      }
      if (action.payload.sort) {
        state.pageable.sort = action.payload.sort;
        state.pageable.pageNumber = 0;
        state.rows.sort((a, b) => a[state.pageable.sort.field]?.localeCompare(b[state.pageable.sort.field]) * (state.pageable.sort.ascending ? 1 : -1));
      }
      TableUtils.setFilteredRows(state);
      state.pageable.loaded = true;
    },
    filterSuccess: (state, action) => {
      if ((state.filter[action.payload.field] || '') !== action.payload.value) {
        state.filter[action.payload.field] = action.payload.value;
        state.pageable.pageNumber = 0;
        TableUtils.setFilteredRows(state);
        state.pageable.loaded = true;
      }
    },
    selectedSuccess: (state, action) => {
      state.selected = action.payload;
      state.isLoading = false;
    },
    formSavedSuccess: (state, action) => {
      state.formSaved = action.payload;
    }
  },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, rowsSuccess, pageableSuccess, filterSuccess, selectedSuccess, formSavedSuccess } = slice.actions;

export const updatePageable = (pageNumber, sort) => async dispatch => {
  dispatch(pageableSuccess({ pageNumber: pageNumber, sort: sort }));
};

export const updateFilter = (field, value) => async dispatch => {
  dispatch(filterSuccess({ field, value }));
};

export const setDetails = (selected) => async dispatch => {
  dispatch(selectedSuccess(selected));
};

export const fetchMapLayers = () => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(`${apiPath}`).then((response) => {
      const rows = TableUtils.sortArrayByString(response.data, 'title');
      dispatch(rowsSuccess(rows));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const add = (object) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.post(apiPath, object).then((response) => {
      dispatch(selectedSuccess(response.data));
      dispatch(formSavedSuccess(true));
      dispatch(showSuccess("form.saved"));
      dispatch(fetchClassifierTypes())
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const save = (object) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath}/${object.id}`, object).then((response) => {
      dispatch(selectedSuccess(response.data));
      dispatch(formSavedSuccess(true));
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};