import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/admin';
import DateUtils from '../../utils/DateUtils';
import StoreUtils from '../../utils/StoreUtils';
import TableUtils from '../../utils/TableUtils';
import { showSuccess } from '../notification';

const apiPath = "cooperationPartnerCompany";
const initialPageable = {
  pageSize: TableUtils.getPageSize(178, true, true),
  pageNumber: 0,
  sort: [{
    field: "validToDate",
    ascending: false
  }, {
    field: "title",
    ascending: true
  }],
  loaded: false,
  last: true
};

// Slice
const slice = createSlice({
  name: 'partner',
  initialState: {
    rows: [],
    selected: {},
    users: null,
    logs: null,
    pageable: initialPageable,
    totalElements: null,
    filter: {},
    isLoading: false,
    error: false,
    formSaved: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    rowsSuccess: (state, action) => {
      state.rows = state.rows.concat(action.payload.content);
      state.pageable.last = action.payload.last;
      state.totalElements = action.payload.totalElements;
      state.pageable.loaded = true;
      state.isLoading = false;
    },
    resetRowsSuccess: (state) => {
      state.pageable.pageNumber = 0;
      state.pageable.loaded = false;
      state.rows = [];
    },
    pageableSuccess: (state, action) => {
      if (action.payload.pageNumber) {
        state.pageable.pageNumber += action.payload.pageNumber;
      }
      if (action.payload.sort) {
        state.pageable.sort = action.payload.sort;
        state.pageable.pageNumber = 0;
        state.rows = [];
      }
      state.pageable.loaded = false;
    },
    filterSuccess: (state, action) => {
      if ((state.filter[action.payload.field] || '') !== action.payload.value) {
        state.filter[action.payload.field] = action.payload.value;
        state.pageable.pageNumber = 0;
        state.rows = [];
        state.pageable.loaded = false;
      }
    },
    selectedSuccess: (state, action) => {
      state.selected = action.payload;
      state.isLoading = false;
    },
    usersSuccess: (state, action) => {
      state.users = action.payload;
      state.isLoading = false;
    },
    userSuccess: (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
    },
    formSavedSuccess: (state, action) => {
      state.formSaved = action.payload;
    },
    userLogsSuccess: (state, action) => {
      state.userLogs = action.payload;
    },
    logsSuccess: (state, action) => {
      state.logs = action.payload;
    },
  },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, rowsSuccess, pageableSuccess, filterSuccess, selectedSuccess,
  formSavedSuccess, usersSuccess, userSuccess, userLogsSuccess, logsSuccess, resetRowsSuccess } = slice.actions;

export const updatePageable = (pageNumber, sort) => async dispatch => {
  dispatch(pageableSuccess({ pageNumber: pageNumber, sort: sort }));
};

export const updateFilter = (field, value) => async dispatch => {
  dispatch(filterSuccess({ field, value }));
};

export const setDetails = (selected) => async dispatch => {
  dispatch(selectedSuccess(selected));
  dispatch(usersSuccess(null));
  dispatch(logsSuccess(null));
};

export const fetchRows = (pageable, filter, columns) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(`${apiPath}?${TableUtils.pageableToParams(pageable, filter, columns)}`).then((response) => {
      dispatch(rowsSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchSelected = (id) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(`${apiPath}/${id}`).then((response) => {
      dispatch(selectedSuccess(response.data));
      dispatch(fetchUsers(id));
      dispatch(fetchLogs(id));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const add = (object) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.post(apiPath, object).then((response) => {
      dispatch(selectedSuccess(response.data));
      dispatch(usersSuccess([]));
      dispatch(fetchLogs(response.data.id));
      dispatch(resetRowsSuccess());
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const save = (object) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath}/${object.id}`, object).then((response) => {
      dispatch(selectedSuccess(response.data));
      dispatch(fetchLogs(response.data.id));
      dispatch(resetRowsSuccess());
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const setInvalid = (id) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath}/${id}/setInvalid`).then((response) => {
      dispatch(resetRowsSuccess());
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};


export const resetFormSaved = () => async dispatch => {
  dispatch(formSavedSuccess(false));
};


export const fetchLogs = (partnerId) => async dispatch => {
  try {
    await api.get(`${apiPath}/${partnerId}/log`).then((response) => dispatch(logsSuccess(response.data)))
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchUsers = (id) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(`${apiPath}/${id}/user`).then((response) => {
      dispatch(usersSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchUser = (partnerId, userId) => async dispatch => {
  if (!userId) {
    dispatch(userSuccess({ id: null, person: { id: null }, validFromDate: DateUtils.nowISODate(), validToDate: null }));
    dispatch(userLogsSuccess([]));
    return;
  }
  dispatch(startLoading());
  try {
    await api.get(`${apiPath}/${partnerId}/user/${userId}`).then((response) => {
      dispatch(userSuccess(response.data))
      dispatch(fetchUserLogs(partnerId, userId));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
}

export const addUser = (partnerId, user) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.post(`${apiPath}/${partnerId}/user`, user).then((response) => {
      dispatch(fetchUsers(partnerId));
      dispatch(showSuccess("form.saved"));
      dispatch(formSavedSuccess(true));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const saveUser = (partnerId, user) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath}/${partnerId}/user/${user.id}`, user).then((response) => {
      dispatch(fetchUsers(partnerId));
      dispatch(showSuccess("form.saved"));
      dispatch(formSavedSuccess(true));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchUserLogs = (partnerId, userId) => async dispatch => {
  try {
    await api.get(`${apiPath}/${partnerId}/user/${userId}/log`).then((response) => dispatch(userLogsSuccess(response.data)))
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const getCSVUrl = (pageable, filter, columns) => `${api.defaults.baseURL}/${apiPath}/csv?${TableUtils.pageableToParams(pageable, filter, columns)}`;