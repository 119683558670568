import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ProcedureUtils from '../../utils/ProcedureUtils';
import TableUtils from '../../utils/TableUtils';
import config from "../../config.json";

const useStyles = makeStyles((theme) => ({
  detailRow: {
    padding: '20px 20px'
  },
  detailRowDense: {
    padding: '2px 2px'
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
    width: '40%',
    display: 'inline-block'
  },
  textarea: {
    whiteSpace: 'pre-line'
  },
  actionButton: {
    marginRight: 8,
    marginBottom: 8
  }
}));

export default function PlanningFeatureDetails({objectType}) {
  const classes = useStyles();

  const { selected } = useSelector(state => state.planning);
  const { classifiers } = useSelector(state => state.classifier);

  const objectDef = ProcedureUtils.getObjectDef(objectType);
  let columns = objectDef?.columns.filter(column => !column.tableOnly && column.type !== 'geometry');

  const renderRow = (index, column) => {
    let value = selected[column.field];
    switch (column.type) {
      case 'link':
        if(column.field === 'organizerLink') {
          const path = `/plan/view/${selected.id}`;
          value = <Link to={path}>{`${window.location.origin}${path}`}</Link >;
        } else if(column.field === 'plankLink' && selected.plankSysId) {
          const url = `${config.PLANK_URL}${selected.plankSysId}`;
          value = <Link to={url}>{url}</Link >;
        }  
        break;
      default:
        value = TableUtils.getCellValue(column, selected, classifiers);
    }

    return <div key={index}>
      <div className={classes.detailRowDense}>
        {column.type !== 'textarea' && <span className={classes.label}>{ProcedureUtils.getColumnName(objectType, column)}</span>}
        {value}
      </div>
    </div>;
  }

  return <Grid item xs={12} lg={12}>
    {columns.map((column, index) => renderRow(index, column))}
  </Grid>;
}