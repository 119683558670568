import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/admin';
import StoreUtils from '../../utils/StoreUtils';
import TableUtils from '../../utils/TableUtils';
import { showInfo, showSuccess } from '../notification';

const apiPath = "notificationRequest";
const initialPageable = {
  pageSize: 5,
  pageNumber: 0,
  sort: [{
    field: "requestDatetime",
    ascending: false
  }],
  loaded: false,
  last: true
};

// Slice
const slice = createSlice({
  name: 'userNotificationRequest',
  initialState: {
    rows: [],
    pageable: initialPageable,
    totalElements: null,
    filter: {},
    submitted: false,
    isLoading: false,
    error: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isLoadingSuggestions = false;
    },
    rowsSuccess: (state, action) => {
      state.rows = TableUtils.mergeArrayById(state.rows, action.payload.content);
      state.pageable.last = action.payload.last;
      state.totalElements = action.payload.totalElements;
      state.pageable.loaded = true;
      state.isLoading = false;
    },
    pageableSuccess: (state, action) => {
      if (action.payload.pageNumber) {
        state.pageable.pageNumber += action.payload.pageNumber;
      }
      if (action.payload.sort) {
        state.pageable.sort = action.payload.sort;
        state.pageable.pageNumber = 0;
        state.rows = [];
      }
      state.pageable.loaded = false;
    },
    filterSuccess: (state, action) => {
      if (action.payload.reset) {
        state.filter = {};
        return;
      }
      if ((state.filter[action.payload.field] || '') !== action.payload.value) {
        state.filter[action.payload.field] = action.payload.value;
        state.pageable.pageNumber = 0;
        state.rows = [];
        state.pageable.loaded = false;
      }
    },
    addSuccess: (state, action) => {
      state.rows.unshift(action.payload);
      state.submitted = true;
      state.isLoading = false;
    },
    deletedSuccess: (state, action) => {
      state.rows = state.rows.filter(r => r.id !== action.payload);
      state.isLoading = false;
    },
    submittedSuccess: (state, action) => {
      state.submitted = action.payload;
    },
  },
});

export default slice.reducer;

// Actions

const { rowsSuccess, pageableSuccess, filterSuccess, addSuccess, deletedSuccess, startLoading, hasError, submittedSuccess } = slice.actions;

export const updatePageable = (pageNumber, sort) => async dispatch => {
  dispatch(pageableSuccess({ pageNumber: pageNumber, sort: sort }));
};

export const updateFilter = (field, value) => async dispatch => {
  dispatch(filterSuccess({ field, value }));
};

export const fetchRows = (pageable, filter, columns) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(`${apiPath}?${TableUtils.pageableToParams(pageable, filter, columns)}`).then((response) => {
      dispatch(rowsSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const addNotificationRequest = (object) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.post(apiPath, object).then((response) => {
      dispatch(addSuccess(response.data));
      dispatch(showSuccess("userNotification.request.requestAdded"));
    })
  }
  catch (e) {
    if (e.response?.status === 400 && e.response.data && e.response.data.length) {
      e.response.data.forEach(error => {
        if (error.errorCode === 'ErrorCode.alreadyExists') {
          dispatch(hasError());
          dispatch(showInfo('userNotification.request.alreadyExists'));
        }
      });
    } else {
      dispatch(StoreUtils.handleError(e, hasError));
    }
  }
};

export const deleteNotificationRequest = (id) => async dispatch => {
  try {
    await api.delete(`${apiPath}/${id}`).then(() => {
      dispatch(deletedSuccess(id));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const resetSubmitted = () => async dispatch => {
  dispatch(submittedSuccess(false));
};