import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router";
import { Authority } from "../../constants/authConstants";
import { ApplicationDecision, ApplicationStatus, ApplicationType } from "../../constants/classifierConstants";
import { fetchSelected, makeDecision, setDetails } from "../../stores/application/application";
import AuthUtils from "../../utils/AuthUtils";
import ApplicationUtils from "../../utils/ApplicationUtils";
import { CenteredLoadingIndicator } from "../form/LoadingIndicator";


export default function ApplicationRedirect() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const application = useSelector(state => state.application.selected);
  const authUser = useSelector(state => state.auth.authUser);
  const applicationId = application?.id;

  const isLoaded = !!applicationId && applicationId === Number(id);
  useEffect(() => {
    if (!isLoaded) {
      async function loadData() {
        dispatch(setDetails(null));
        const application = await dispatch(fetchSelected(id));
        if (application) {
          if (authUser &&
            AuthUtils.hasAuthority(authUser, Authority.UC7_application) &&
            application.domain === authUser.domain &&
            ApplicationStatus.submitted === application.status) {
            await dispatch(makeDecision(application.id, ApplicationDecision.review));
          }
        } else {
          history.goBack();
        }
      }
      loadData();
    }
  }, [dispatch, id, isLoaded, authUser, history])

  if (!isLoaded) {
    return <CenteredLoadingIndicator />;
  }
  if (AuthUtils.hasAuthority(authUser, Authority.UC1_application_submit) &&
    (application.status === ApplicationStatus.entered || application.status === ApplicationStatus.editing)) {
    return <Redirect to={`/application/form/${application.id}/5`} />;
  } else if ((ApplicationType.DP === application.applicationType || ApplicationType.KT === application.applicationType)
    && ApplicationUtils.isGovCanAddApplication(authUser)
    && ApplicationUtils.isHandler(authUser, application)
    && application.status === ApplicationStatus.entered) {
    return <Redirect to={`/application/form/${application.id}/5`} />;
  } else if (ApplicationType.YP === application.applicationType
    && ApplicationUtils.isGovCanAddYPApplication(authUser)
    && ApplicationUtils.isHandler(authUser, application)
    && application.status === ApplicationStatus.entered) {
    return <Redirect to={`/application/form/${application.id}/5`} />;
  }
  return <Redirect to={`/application/view/${id}`} />;
}