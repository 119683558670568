import { Box, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Heading, Paragraph } from 'styleguide';
import ApplicationTypeIllustration from '../ApplicationTypeIllustration';
import ApplicationUtils from '../../../utils/ApplicationUtils';

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    textAlign: 'center'
  }
}));

export default function ConfirmCancel({ application, onConfirm, onCancel }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const authUser = useSelector(state => state.auth.authUser);

  return <Grid container direction="column" alignItems="center" justifyContent="center" className={classes.container}>
    <ApplicationTypeIllustration applicationType={application.applicationType} size="100px" />

    <Box paddingTop={6} paddingBottom={2}>
      <Heading level="2">
        {authUser && (ApplicationUtils.isGovCanAddApplication(authUser) || ApplicationUtils.isGovCanAddYPApplication(authUser)) ?
          t('application.notification.confirmDelete') : t('application.notification.confirmCancel')}
      </Heading>
    </Box>

    <Paragraph fontSize="14">{t('application.notification.confirmCancelDescription')}</Paragraph>
  </Grid>;
}