import React from 'react';
import { Illustration } from 'styleguide';
import { ApplicationType } from '../../constants/classifierConstants';

export default function ApplicationTypeIllustration({ applicationType, size }) {
  let illustrationType = '';
  switch (applicationType) {
    case ApplicationType.DP:
      illustrationType = 'detailPlan';
      break;
    case ApplicationType.KT:
      illustrationType = 'detailPlanInvalidate';
      break;
    case ApplicationType.SVTT:
      illustrationType = 'sademevesi';
      break;
    case ApplicationType.TVTT:
      illustrationType = 'valgustus';
      break;
    case ApplicationType.TPT:
      illustrationType = 'teed';
      break;
    case ApplicationType.TETT:
      illustrationType = 'teedAlternative';
      break;
    case 'opis':
      illustrationType = 'menetluses';
      break;
    default:
  }

  return <Illustration type={illustrationType} size={size} />;
}