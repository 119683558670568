import jsPDF from "jspdf";
import i18n from "../i18n";
import DateUtils from "./DateUtils";
import MapUtils from "./MapUtils";
import logoVaal from '../assets/images/logo_vaal.png';
import autoTable from 'jspdf-autotable'
import html2canvas from "html2canvas";

const PdfUtils = {
  format: 'A4',
  widthMm: 210,
  heightMm: 297,
  widthMmA3: 297,
  heightMmA3: 420,
  widthPx: () => PdfUtils.mmToPx(PdfUtils.widthMm),
  heightPx: () => PdfUtils.mmToPx(PdfUtils.heightMm),
  screenToMm: (px, dpi) => Math.round((px * 25.4) / (dpi || MapUtils.mapDpi)),
  mmToPx: (mm, dpi) => Math.round((mm * (dpi || MapUtils.mapDpi)) / 25.4),
  getMapSize: (format) => format === 'A3' ? [280, 370] : [190, 250],
  addHeader: (pdf, format) => {
    let width, height;
    switch (format) {
      case 'landscape':
        width = PdfUtils.heightMm;
        height = PdfUtils.widthMm;
        break;
      case 'A3':
        width = PdfUtils.widthMmA3;
        height = PdfUtils.heightMmA3;
        break;
      default:
        width = PdfUtils.widthMm;
        height = PdfUtils.heightMm;
    }
    pdf.setFontSize(10);
    var img = new Image()
    img.src = logoVaal;
    pdf.addImage(img, 'png', 10, 10, PdfUtils.screenToMm(70, MapUtils.dpi), PdfUtils.screenToMm(18, MapUtils.dpi))

    pdf.text(i18n.t('map.pdf.system'), 10, height - 10);
    pdf.text(DateUtils.formatDate(new Date()), width - 25, height - 10);
  },
  addTitle: (pdf, title) => {
    pdf.setFontSize(20);
    pdf.text(title, PdfUtils.widthMm / 2, 15, { align: 'center' });
  },
  addTable: (pdf, tableId, marginTop = 25, disableStyles, format) => {
    const styles = disableStyles ? {} : {
      valign: 'middle',
      fontSize: 10,
      lineColor: '#e6e8ec',
      lineWidth: 0.01,
      textColor: '#091E42'
    };
    const headStyles = disableStyles ? {} : {
      textColor: '#091E42',
      fillColor: '#F7F9FC',
    };
    autoTable(pdf, {
      html: `#${tableId}`,
      rowPageBreak: 'avoid',
      theme: 'plain',
      styles,
      headStyles,
      margin: { top: marginTop, left: 10, right: 10, bottom: 20 },
      didParseCell: function (data) {
        if (data.column.index === 0 && data.cell.section === 'body') {
          var td = data.cell.raw;
          var img = td.getElementsByTagName('img')[0];
          if (img) {
            const imgWidth = PdfUtils.screenToMm(img.width, MapUtils.dpi);
            if (data.column.minWidth < imgWidth) {
              data.column.minWidth = imgWidth;
            }
            data.row.height = PdfUtils.screenToMm(img.height, MapUtils.dpi) + 2;
          }
        }
      },
      didDrawCell: function (data) {
        if (data.column.index === 0 && data.cell.section === 'body') {
          var td = data.cell.raw;
          var img = td.getElementsByTagName('img')[0];
          if (img) {
            pdf.addImage(
              img.src, data.cell.x + 1, data.cell.y + 1,
              PdfUtils.screenToMm(img.width, MapUtils.dpi),
              PdfUtils.screenToMm(img.height, MapUtils.dpi)
            );
          }
        }
      },
      didDrawPage: function (data) {
        PdfUtils.addHeader(pdf, format);
      }
    });
  },
  addPage: (pdf, format) => {
    pdf.addPage();
    PdfUtils.addHeader(pdf, format);
  },
  addPageLandscape: (pdf) => {
    pdf.addPage(PdfUtils.format, 'landscape');
    PdfUtils.addHeader(pdf, 'landscape');
  },
  addImage: (pdf, elementId, left, top) => {
    return new Promise((resolve, reject) => {
      const element = document.getElementById(elementId);
      if (element) {
        html2canvas(element).then((canvas) => {
          var image = canvas.toDataURL('image/png', 1.0);
          let elementHeight = PdfUtils.screenToMm(element.offsetHeight);
          let elementWidth = PdfUtils.screenToMm(element.offsetWidth);

          const scale = elementWidth / (PdfUtils.widthMm - 20);
          elementWidth = elementWidth / scale;
          elementHeight = elementHeight / scale;

          pdf.addImage(image, 'image/png', left, top, elementWidth, elementHeight);

          resolve();
        });
      } else {
        resolve();
      }
    });
  },
  addImageLandscape: (pdf, elementId, title, noNewPage) => {
    return new Promise((resolve, reject) => {
      const element = document.getElementById(elementId);
      if (element) {
        !noNewPage && PdfUtils.addPageLandscape(pdf);

        html2canvas(element).then((canvas) => {
          var image = canvas.toDataURL('image/png', 1.0);
          let elementHeight = PdfUtils.screenToMm(element.offsetHeight);
          let elementWidth = PdfUtils.screenToMm(element.offsetWidth);

          //in landscape
          const scale = elementWidth / (PdfUtils.heightMm - 20);
          elementWidth = elementWidth / scale;
          elementHeight = elementHeight / scale;

          const imageTop = !title ? 20 : 30;
          if (title) {
            pdf.setFontSize(16);
            pdf.text(title, 10, 25);
          }
          pdf.addImage(image, 'image/png', 10, imageTop, elementWidth, elementHeight);

          resolve();
        });
      } else {
        resolve();
      }
    });
  },
  newPdf: (format) => {
    const pdf = new jsPDF('', 'mm', format || PdfUtils.format);
    PdfUtils.addHeader(pdf, format);
    return pdf;
  },
  newPdfLanscape: (format) => {
    const pdf = new jsPDF('l', 'mm', format || PdfUtils.format);
    PdfUtils.addHeader(pdf, 'landscape');
    return pdf;
  },
  savePdf: (pdf, filename) => {
    const safeFilename = filename.replace(/[^a-z0-9]/gi, '_').toLowerCase();
    pdf.save(safeFilename + '.pdf');
  },
  saveImage: (elementId, filename) => {
    const element = document.getElementById(elementId);
    if (element) {
      html2canvas(element).then((canvas) => PdfUtils.saveCanvas(canvas, filename));
    }
  },
  saveCanvas: (canvas, filename) => {
    const filenameWithExt = `${filename}.png`;
    if (navigator.msSaveBlob) {
      // link download attribute does not work on MS browsers
      navigator.msSaveBlob(canvas.msToBlob(), filenameWithExt);
    } else {
      const link = document.createElement('a');
      link.download = filenameWithExt;
      link.href = canvas.toDataURL();
      link.click();
    }
  },
  drawMapCanvas: (width, height) => {
    const mapCanvas = document.createElement('canvas');
    mapCanvas.width = width;
    mapCanvas.height = height;
    const mapContext = mapCanvas.getContext('2d');
    Array.prototype.forEach.call(
      document.querySelectorAll('.ol-layer canvas'),
      function (canvas) {
        if (canvas.width > 0) {
          const opacity = canvas.parentNode.style.opacity;
          mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
          let matrix;
          const transform = canvas.style.transform;
          if (transform) {
            // Get the transform parameters from the style's transform matrix
            matrix = transform
              .match(/^matrix\(([^(]*)\)$/)[1]
              .split(',')
              .map(Number);
          } else {
            matrix = [
              parseFloat(canvas.style.width) / canvas.width,
              0,
              0,
              parseFloat(canvas.style.height) / canvas.height,
              0,
              0,
            ];
          }
          // Apply the transform to the export map context
          CanvasRenderingContext2D.prototype.setTransform.apply(
            mapContext,
            matrix
          );
          mapContext.drawImage(canvas, 0, 0);
        }
      }
    );
    return mapCanvas;
  }
};

export default PdfUtils;