import { Box, Divider, FormControlLabel, Grid, makeStyles, RadioGroup } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Colors, Heading, Paragraph, Radio, Switch } from 'styleguide';
import { ApplicationType, ClassifierType } from '../../../constants/classifierConstants';
import { savePurposeGroups, setPurposes, setPurposeStatus, togglePurposeGroup } from '../../../stores/application/applicationPurpose';
import TableUtils from '../../../utils/TableUtils';
import VTextField from '../../form/VTextField';
import VNumberField from '../../form/VNumberField';
import VCheckbox from '../../form/VCheckbox';
import VRadio from '../../form/VRadio';
import VDateTimePicker from '../../form/VDateTimePicker';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../../form/LoadingIndicator';
import { fetchTypeConfig } from '../../../stores/application/application';
import { showWarning } from '../../../stores/notification';

const useStyles = makeStyles((theme) => ({
  purposeBlock: {
    backgroundColor: Colors.hall3,
    marginBottom: 1,
    padding: 26,
    borderRadius: 4
  },
  groupBlock: {
    [theme.breakpoints.up('md')]: {
      width: 416,
    },
    [theme.breakpoints.down('md')]: {
      width: '95%',
    },
    flexWrap: 'noWrap',
    paddingTop: 12,
    paddingBottom: 12
  },
  groupDivider: {
    width: '100%'
  },
}));

const getFieldName = (group, purpose, field) => {
  return `${group.purposeGroup}.purposes.${purpose.purposeType}.${field}`;
};

function Purpose({ groupConfig, purposeConfig, purposeValue, classifiers, register, control, errors, disabled }) {
  const classes = useStyles();
  const title = TableUtils.getClassifierTitle(classifiers, ClassifierType.purposeType, purposeConfig.purposeType);

  const commonParams = {
    label: title,
    disabled: disabled,
    errors: errors
  }
  let field = title;
  if (!!purposeConfig.isEnteredNumber) {
    field = <VNumberField name={getFieldName(groupConfig, purposeConfig, 'valueNumber')}
      control={control} {...commonParams} />;
  } else if (!!purposeConfig.isCalculatedNumber) {
    field = <Paragraph fontSize="14">{`${purposeValue?.valueNumber || ''} ${title}`}</Paragraph>;
  } else if (!!purposeConfig.isMarked) {
    field = <VCheckbox name={getFieldName(groupConfig, purposeConfig, 'valueBoolean')}
      control={control} {...commonParams} />;
  } else if (!!purposeConfig.isFreeText) {
    field = <VTextField name={getFieldName(groupConfig, purposeConfig, 'valueString')}
      multiline register={register} {...commonParams} />;
  } else if (!!purposeConfig.isRadio) {
    field = <VRadio name={getFieldName(groupConfig, purposeConfig, 'valueBoolean')}
      control={control} {...commonParams} />;
  } else if (!!purposeConfig.isDatetime) {
    field = <VDateTimePicker name={getFieldName(groupConfig, purposeConfig,
      purposeConfig.purposeType === 'ALATES' ? 'valueDateTimeFrom' : 'valueDateTimeTo')}
      defaultValue={Date.now()}
      fullWidth control={control} {...commonParams} />
  }

  return <Grid container direction="column" className={classes.purposeBlock}>
    <input type="hidden" name={getFieldName(groupConfig, purposeConfig, 'id')} ref={register} />
    <input type="hidden" name={getFieldName(groupConfig, purposeConfig, 'purposeType')} ref={register} />
    <input type="hidden" name={getFieldName(groupConfig, purposeConfig, 'queueNumberInGroup')} ref={register} />

    {field}
  </Grid>
}

function PurposeGroup({ groupConfig, groupValue, classifiers, onGroupToggled, last, register, control, errors, disabled }) {
  const classes = useStyles();

  return <Grid container direction="column" alignItems="center">
    <Grid container direction="row" justifyContent="space-between" className={classes.groupBlock}>
      <Heading level="4">{TableUtils.getClassifierTitle(classifiers, ClassifierType.purposeGroup, groupConfig.purposeGroup)}</Heading>

      <Switch checked={groupValue.isGroupSelected} onChange={() => onGroupToggled(groupValue)} disabled={disabled} />
    </Grid>
    {!last && !groupValue.isGroupSelected && <Divider className={classes.groupDivider} />}
    {groupValue.isGroupSelected && groupConfig.purposes
      .filter(p => !p.isCalculatedNumber).slice()
      .sort((a, b) => (a.queueNumberInGroup - b.queueNumberInGroup))
      .map((purposeConfig, index) => (
        <Purpose key={index} groupConfig={groupConfig} purposeConfig={purposeConfig} classifiers={classifiers}
          register={register} control={control} errors={errors} disabled={disabled} />
      ))}
  </Grid >;
}

function YpAppropriateSelect({ control, defaultValue, disabled }) {
  const { t } = useTranslation();

  return <Box py={2}>
    <Controller
      render={(props) => (
        <RadioGroup aria-label="is application yp appropriate" value={props.value}
          onChange={(event) => props.onChange(event.target.value === 'true')}>
          <FormControlLabel value={true} control={<Radio />} label={t('application.isYpAppropriate')} disabled={disabled} />
          <FormControlLabel value={false} control={<Radio />} label={t('application.isNotYpAppropriate')} disabled={disabled} />
        </RadioGroup>
      )}
      name="isYpAppropriate"
      defaultValue={defaultValue === undefined ? true : defaultValue}
      control={control}
    />
  </Box>;
}

function PurposeGroupForm({ application, typeConfig, purposeGroups, purposeStatus, isLoading, isDirtyStore }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const applicationId = application.id;

  const { classifiers } = useSelector(state => state.classifier);
  const { register, control, errors, handleSubmit, formState, setError } = useForm({
    defaultValues: purposeGroups
  });
  const { isDirty } = formState;
  const shouldSubmitForm = isDirty || isDirtyStore;

  const isDp = ApplicationType.DP === application.applicationType;
  useEffect(() => {
    if (purposeStatus === 'SUBMIT') {
      dispatch(setPurposeStatus('SUBMITTING'));
      handleSubmit((data) => {
        let request = {
          groups: []
        };
        Object.keys(purposeGroups).forEach(purposeGroup => {
          const group = purposeGroups[purposeGroup];
          let newGroup = {
            id: group.id,
            purposeGroup: purposeGroup,
            isGroupSelected: group.isGroupSelected,
            queueNumber: group.queueNumber,
            purposes: []
          };
          if (newGroup.id || newGroup.isGroupSelected) {
            const purposes = data[purposeGroup]?.purposes;
            if (purposes) {
              Object.keys(purposes).forEach(purposeType => {
                if (!purposes[purposeType].id) {
                  purposes[purposeType].id = undefined;
                }
                newGroup.purposes.push(purposes[purposeType]);
              });
            }
            request.groups.push(newGroup);
          }
        });
        request.purpose = data.purpose;
        request.isYpAppropriate = data.isYpAppropriate;

        if (!request.purpose && request.groups.every(g => !g.isGroupSelected)) {
          dispatch(showWarning('ErrorCode.cannotSubmitApplicationNoPurposeSelected'));
          return;
        }
        if (isDp && !request.isYpAppropriate && !request.purpose) {
          dispatch(showWarning('ErrorCode.applicationNotYpPurposeEmpty'));
          setError("purpose", { type: "manual", message: t('validation.required') });
          return;
        }
        if (!shouldSubmitForm) {
          dispatch(setPurposeStatus('OK'));
          return;
        }
        dispatch(savePurposeGroups(applicationId, request, typeConfig));
      })();
    }
  }, [dispatch, typeConfig, applicationId, purposeGroups, purposeStatus, handleSubmit, shouldSubmitForm, isDp, setError, t]);

  const handleGroupToggle = (group) => {
    dispatch(togglePurposeGroup(group));
  };

  return <Box paddingBottom="88px" marginLeft="auto" marginRight="auto">
    <form>
      {typeConfig.groups.slice().sort((a, b) => (a.queueNumber - b.queueNumber)).map((group, index) => (
        <PurposeGroup key={index} groupConfig={group} classifiers={classifiers}
          groupValue={purposeGroups[group.purposeGroup]}
          onGroupToggled={handleGroupToggle}
          last={typeConfig.groups.length === index + 1} disabled={isLoading}
          register={register} control={control} errors={errors} />
      ))}
      <Divider className={classes.groupDivider} />
      <div className={classes.groupBlock}>
        {isDp &&
          <YpAppropriateSelect
            control={control}
            disabled={isLoading}
            defaultValue={application.isYpAppropriate}
          />
        }
        <VTextField name="purpose" label={t('application.purpose')} multiline minRows={10}
          defaultValue={application.purpose} fullWidth
          disabled={isLoading} register={register} errors={errors} />
      </div>
    </form>
  </Box>;
}

export default function Step2Purpose({ application }) {
  const dispatch = useDispatch();
  const { groupsArray, purposeGroups, purposeStatus, isDirtyStore, isLoading } = useSelector(state => state.applicationPurpose);
  const { typeConfig } = useSelector(state => state.application);

  //fetch type config
  const applicationId = application.id;
  const applicationType = application?.applicationType;
  const shouldFetchType = !!applicationId && (typeConfig?.applicationType !== applicationType);
  useEffect(() => shouldFetchType && dispatch(fetchTypeConfig(applicationId, applicationType)),
    [dispatch, applicationId, applicationType, shouldFetchType]);

  //set purpose groups after type config is fetched
  useEffect(() => !!groupsArray && !!typeConfig && !shouldFetchType && dispatch(setPurposes(groupsArray, typeConfig)),
    [dispatch, typeConfig, groupsArray, shouldFetchType]);

  if (shouldFetchType || !purposeGroups) {
    return <LoadingIndicator />;
  }

  return <PurposeGroupForm
    application={application}
    typeConfig={typeConfig}
    purposeGroups={purposeGroups}
    purposeStatus={purposeStatus}
    isLoading={isLoading}
    isDirtyStore={isDirtyStore}
  />;
}
