import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/register';
import { Domain } from '../../constants/classifierConstants';
import StoreUtils from '../../utils/StoreUtils';

const apiPath = (domain) => `${domain}/infrastructure`;

// Slice
const slice = createSlice({
  name: 'registerInfrastructure',
  initialState: {
    rows: {},
    isLoading: {},
    error: false
  },
  reducers: {
    startLoading: (state, action) => {
      state.isLoading[action.payload] = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = {};
    },
    rowsSuccess: (state, action) => {
      state.rows[action.payload.domain] = action.payload.data;
      state.isLoading[action.payload.domain] = false;
    },
  },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, rowsSuccess } = slice.actions;

export const fetchInfrastructure = () => async dispatch => {
  dispatch(fetchDomain(Domain.road));
  dispatch(fetchDomain(Domain.streetlight));
  dispatch(fetchDomain(Domain.rainwater));
};

const fetchDomain = (domain) => async dispatch => {
  dispatch(startLoading(domain));
  try {
    await api.get(apiPath(domain)).then((response) => {
      dispatch(rowsSuccess({ domain: domain, data: response.data }));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};
