import { Box, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar } from '@material-ui/core';
import { getYear } from 'date-fns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Colors, Heading, NavButton, Paragraph, TableHeader } from 'styleguide';
import { ClassifierType } from '../../constants/classifierConstants';
import { fetchWorkOrderReport } from '../../stores/workOrder/workOrderReport';
import ChartUtils from '../../utils/ChartUtils';
import TableUtils from '../../utils/TableUtils';
import { LoadingRows } from '../table/PageableTable';

const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: 16
  },
  toolbar: {
    padding: 0,
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: Colors.hall3,
    zIndex: 2,
  },
  stickyHeaderTableContainer: {
    overflowX: 'initial'
  },
  totalRow: {
    backgroundColor: Colors.hall3
  }
}));

const formatNumber = (value) => {
  if (value === 0 || isNaN(value)) {
    return '';
  }
  return TableUtils.formatNumber(value);
};

function WorkOrderMonth({ month, rows }) {
  return <>
    <TableRow>
      <TableCell></TableCell>
      <TableCell colSpan={6}><b>{month}</b></TableCell>
    </TableRow>
    {rows.map((row, index) => <TableRow key={index}>
      <TableCell colSpan={2}></TableCell>
      <TableCell>{`P${row.priority}`}</TableCell>
      <TableCell>{formatNumber(row.opened)}</TableCell>
      <TableCell>{formatNumber(row.govFulfilled)}</TableCell>
      <TableCell>{formatNumber(row.partnerFulfilled)}</TableCell>
      <TableCell>{formatNumber(Math.round(row.days * 10) / 10)}</TableCell>
    </TableRow>)}
  </>;
}

function WorkOrderDomain({ domain, rows }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classifiers = useSelector(state => state.classifier.classifiers);

  const months = [...new Set(rows.map(r => r.month))];

  return <>
    <TableRow>
      <TableCell colSpan={7}>
        <b>{TableUtils.getClassifierTitle(classifiers, ClassifierType.domain, domain)}</b>
      </TableCell>
    </TableRow>
    {months.map((month, index) => <WorkOrderMonth key={index} month={month} rows={rows.filter(r => r.month === month)} />)}
    <TableRow className={classes.totalRow}>
      <TableCell colSpan={3}><b>{t('report.table.total')}</b></TableCell>
      <TableCell><b>{formatNumber(ChartUtils.arraySum(rows, 'opened'))}</b></TableCell>
      <TableCell><b>{formatNumber(ChartUtils.arraySum(rows, 'govFulfilled'))}</b></TableCell>
      <TableCell><b>{formatNumber(ChartUtils.arraySum(rows, 'partnerFulfilled'))}</b></TableCell>
      <TableCell></TableCell>
    </TableRow>
  </>;
}

export default function WorkOrderReport() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const currentYear = getYear(new Date());
  const { rows, isLoading } = useSelector(state => state.workOrderReport);
  const compact = useSelector(state => state.global.mobileView);

  const [year, setYear] = useState(currentYear);
  const [error, setError] = useState(false);

  const title = t(`report.other.workOrder`);
  const subTitle = t('report.report');

  const handleClose = () => history.goBack();

  const domains = [...new Set(rows?.map(r => r.domain))];

  const handleGenerateReport = () => {
    if (year < 2000 || year > currentYear) {
      setError(true);
      return;
    }
    setError(false);
    dispatch(fetchWorkOrderReport(year))
  };

  return <div>
    <Toolbar className={classes.toolbar}>
      <NavButton onClick={handleClose} action='back' color='secondary'></NavButton>
      <span className={classes.header}>
        <Heading level={compact ? '4' : '3'}>{title}</Heading>
        <Paragraph fontSize={compact ? '14' : '14B'}>{subTitle}</Paragraph>
      </span>
    </Toolbar>

    <Box py={4} className='VTable'>
      <TableContainer component={Paper} className={classes.stickyHeaderTableContainer}>
        <TableHeader buttons={<Box display="flex" alignItems="center">
          <NumberFormat
            customInput={TextField}
            allowNegative={false}
            decimalScale={0}
            onValueChange={(v) => setYear(v.floatValue)}
            value={year}
            variant="outlined"
            className="VaalTextField"
            label={t('report.table.year')}
            error={error}
          />
          <Button onClick={handleGenerateReport} size="small">{t('report.generate')}</Button>
        </Box>}>
          {t('report.other.workOrderTableTitle')}
        </TableHeader>
        <Table size="small">
          <TableHead>
            <TableRow className={classes.stickyHeader}>
              <TableCell>{t('workOrder.domain')}</TableCell>
              <TableCell>{t('report.table.month')}</TableCell>
              <TableCell>{t('workOrder.priority')}</TableCell>
              <TableCell>{t('report.table.workOrderOpened')}</TableCell>
              <TableCell>{t('report.table.workOrderGovFulfilled')}</TableCell>
              <TableCell>{t('report.table.workOrderPartnerFulfilled')}</TableCell>
              <TableCell>{t('report.table.workOrderDays')}</TableCell>
            </TableRow>
          </TableHead>
          {!!rows?.length &&
            <TableBody>
              {domains.map((domain, index) => <WorkOrderDomain key={index} domain={domain} rows={rows.filter(r => r.domain === domain)} />)}
              <TableRow className={classes.totalRow}>
                <TableCell colSpan={3}><b>{t('report.table.totalTotal')}</b></TableCell>
                <TableCell><b>{formatNumber(ChartUtils.arraySum(rows, 'opened'))}</b></TableCell>
                <TableCell><b>{formatNumber(ChartUtils.arraySum(rows, 'govFulfilled'))}</b></TableCell>
                <TableCell><b>{formatNumber(ChartUtils.arraySum(rows, 'partnerFulfilled'))}</b></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          }
          {(!rows || isLoading) &&
            <TableBody>
              <LoadingRows rowCount={1} columnCount={7} />
            </TableBody>
          }
          {!!rows && !rows.length &&
            <TableBody>
              <TableRow>
                <TableCell>
                  <Paragraph fontSize='14' margin="10px">{t('table.noRows')}</Paragraph>
                </TableCell>
              </TableRow>
            </TableBody>
          }
        </Table>
      </TableContainer>
    </Box>
  </div>
}