import axios from 'axios';
import StoreUtils from '../utils/StoreUtils';

const api = axios.create({
  baseURL: '/vaal-work-order',
  headers: {
    'Content-Type': 'application/json'
  },
});

StoreUtils.setDateInterceptor(api);

export { api };