import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/application';
import ApplicationUtils from '../../utils/ApplicationUtils';
import StoreUtils from '../../utils/StoreUtils';
import TableUtils from '../../utils/TableUtils';
import { showSuccess } from '../notification';
import { setApplicationField, setSubmitted } from './application';
import { setFiles } from './clauseFile';

const apiPath = (id) => `application/${id}/clause`;

// Slice
const slice = createSlice({
  name: 'clause',
  initialState: {
    clause: null,
    draftsmen: null,
    isDirty: false,
    isLoading: false,
    error: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    clauseSuccess: (state, action) => {
      let clause = action.payload;
      if (clause) {
        clause.groups = TableUtils.sortArrayByNumber(clause.groups, 'queueNumber');
        clause.groups.forEach((g, index) => {
          g.queueNumber = index + 1;
          g.blocks = TableUtils.sortArrayByNumber(g.blocks, 'queueNumber');
          g.isSelected = g.blocks.some(b => b.isSelected);
          if (ApplicationUtils.hasGroupTitlePlaceholder(g.content) && !g.value) {
            g.editing = true;
          }
        });
      }
      state.clause = clause;
      state.isLoading = false;
      state.isDirty = false;
    },
    groupSuccess: (state, action) => {
      const clause = action.payload;
      if (clause.signatoryName) {
        state.clause.signatoryName = clause.signatoryName;
      }
      if (clause.groups.length === 1) {
        const group = clause.groups[0];
        group.blocks = TableUtils.sortArrayByNumber(group.blocks, 'queueNumber');
        group.isSelected = group.blocks.some(b => b.isSelected);
        const groupIndex = state.clause.groups.findIndex(g => g.id === group.id);
        state.clause.groups[groupIndex] = group;
      }
      state.isLoading = false;
      state.isDirty = false;
    },
    setClauseValueSuccess: (state, action) => {
      state.clause[action.payload.field] = action.payload.value;
      state.isDirty = true;
    },
    setGroupValueSuccess: (state, action) => {
      const data = action.payload;
      state.clause.groups.find(g => g.id === data.group.id)[data.field] = data.value;
      state.isDirty = true;
    },
    setBlockValueSuccess: (state, action) => {
      const data = action.payload;
      const group = state.clause.groups.find(g => g.id === data.block.clauseGroupId);
      group.blocks.find(b => b.id === data.block.id)[data.field] = data.value;
      if (data.field === 'isSelected') {
        group.isSelected = group.blocks.some(b => b.isSelected);
      }
      state.isLoading = false;
      state.isDirty = true;
    },
    setBlockInvalidSuccess: (state, action) => {
      const data = action.payload;
      state.clause
        .groups.find(g => g.id === data.block.clauseGroupId)
        .blocks.find(b => b.id === data.block.id).invalid = data.invalid;
    },
    calculateNumberingSuccess: state => {
      let groupNumber = 1;
      state.clause.groups.forEach(group => {
        if (group.blocks.some(b => b.isSelected)) {
          group.queueStr = groupNumber++;
        }
        let blockNumber = 1;
        group.blocks.forEach(block => {
          if (block.isSelected && block.isNumerable) {
            block.queueStr = !block.indent ? `${group.queueStr}.${blockNumber++}` : ApplicationUtils.indentedBlockSymbol;
          }
        });
      });
    },
    deleteGroupSuccess: (state, action) => {
      state.clause.groups = state.clause.groups.filter(g => g.id !== action.payload);
      state.isLoading = false;
    },
    setDirtySuccess: (state, action) => {
      state.isDirty = action.payload || false;
    },
    draftsmenSuccess: (state, action) => {
      state.draftsmen = action.payload;
      state.isLoading = false;
    }
  },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, clauseSuccess, groupSuccess, setClauseValueSuccess, setBlockValueSuccess, setBlockInvalidSuccess,
  calculateNumberingSuccess, setGroupValueSuccess, deleteGroupSuccess, setDirtySuccess, draftsmenSuccess } = slice.actions;

export const fetchClause = (applicationId) => async dispatch => {
  dispatch(clauseSuccess());
  dispatch(setFiles());
  dispatch(startLoading());
  try {
    await api.get(apiPath(applicationId)).then((response) => {
      dispatch(clauseSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchClauseById = (id) => async dispatch => {
  dispatch(clauseSuccess());
  dispatch(setFiles());
  dispatch(startLoading());
  try {
    await api.get(`${apiPath(id)}/${id}`).then((response) => {
      dispatch(clauseSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
    await api.get(`${apiPath(id)}/public/${id}`).then((response) => {
      dispatch(clauseSuccess(response.data));
    });
  }
};

export const saveClause = (applicationId, clause) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(apiPath(applicationId), clause).then(response => {
      dispatch(clauseSuccess(response.data));
      dispatch(showSuccess('form.saved'));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const saveGroup = (applicationId, clause, group) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(apiPath(applicationId), {
      title: clause.title,
      preamble: clause.preamble,
      geometry: clause.geometry,
      roadNames: clause.roadNames,
      project: clause.project,
      signatoryId: clause.signatoryId,
      groups: !!group ? [group] : []
    }).then(response => {
      if (!group) {
        dispatch(setApplicationField('signatoryId', response.data?.signatoryId));
      }
      dispatch(groupSuccess(response.data));
      dispatch(showSuccess('form.saved'));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
}

export const deleteClause = (applicationId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.delete(apiPath(applicationId)).then(response => {
      dispatch(clauseSuccess());
      dispatch(showSuccess('form.saved'));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const setClauseValue = (field, value) => async dispatch => {
  dispatch(setClauseValueSuccess({ field, value }));
};

export const setGroupValue = (group, field, value) => async dispatch => {
  dispatch(setGroupValueSuccess({ group, field, value }));
};

export const setBlockValue = (block, field, value) => async dispatch => {
  dispatch(setBlockValueSuccess({ block, field, value }));
  dispatch(setBlockInvalidSuccess({ block, invalid: false }));
};

export const setBlockInvalid = (block, invalid) => async dispatch => {
  dispatch(setBlockInvalidSuccess({ block, invalid }));
};

export const calculateNumbering = () => async dispatch => {
  dispatch(calculateNumberingSuccess());
};

export const addGroup = (applicationId, group) => async dispatch => {
  dispatch(startLoading());
  dispatch(setDirtySuccess(true));
  try {
    await api.post(`${apiPath(applicationId)}/group`, group).then(response => {
      dispatch(clauseSuccess(response.data));
      dispatch(showSuccess('form.saved'));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const deleteGroup = (applicationId, groupId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.delete(`${apiPath(applicationId)}/group/${groupId}`).then(response => {
      dispatch(deleteGroupSuccess(groupId));
      dispatch(showSuccess('form.saved'));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const setBlockCadastres = (applicationId, block, cadastres) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath(applicationId)}/cadastre`, { cadastres }).then((response) => {
      const value = `\n ${response.data?.value || ''}`;
      dispatch(setBlockValue(block, 'value', value));
      if (response.data.geometry) {
        dispatch(setClauseValue('geometry', response.data.geometry));
      }
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const getPdfPreviewDownloadUrl = (applicationId) => `${api.defaults.baseURL}/${apiPath(applicationId)}/pdf`;
export const getPdfSignedDownloadUrl = (applicationId) => `${api.defaults.baseURL}/${apiPath(applicationId)}/container`;

export const fetchDraftsmen = (applicationId) => async dispatch => {
  dispatch(draftsmenSuccess());
  dispatch(startLoading());
  try {
    await api.get(apiPath(applicationId) + '/draftsmen').then((response) => {
      dispatch(draftsmenSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const sendToPartner = (applicationId, dto) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(apiPath(applicationId) + '/send', dto).then(response => {
      dispatch(setSubmitted());
      dispatch(clauseSuccess());
      dispatch(showSuccess('form.saved'));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const clauseSubmit = (applicationId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(apiPath(applicationId) + '/submit').then(response => {
      dispatch(setSubmitted());
      dispatch(clauseSuccess());
      dispatch(showSuccess('form.saved'));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const planSubmit = (applicationId, dto) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(apiPath(applicationId) + '/planSubmit', dto).then(response => {
      dispatch(setSubmitted());
      dispatch(showSuccess('form.saved'));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};