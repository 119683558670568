import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { api } from '../../api/application';
import { ApplicantType } from '../../constants/classifierConstants';
import StoreUtils from '../../utils/StoreUtils';
import TableUtils from '../../utils/TableUtils';
import { showSuccess, toggleLoadingOverlay } from '../notification';
import { fetchDocuments } from './planningDocument';

const apiPath = (planningId) => `planning/${planningId}/participant`;

// Slice
const slice = createSlice({
  name: 'planningParticipant',
  initialState: {
    rows: null,
    isLoading: false,
    submitted: false,
    error: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    rowsSuccess: (state, action) => {
      state.rows = action.payload;
      state.isLoading = false;
    },
    insertedSuccess: (state, action) => {
      state.rows = TableUtils.mergeArrayById(state.rows, action.payload);
      state.isLoading = false;
    },
    saveSuccess: (state, action) => {
      const row = state.rows.find(f => f.id === action.payload.id);
      row.email = action.payload.email;
      row.phone = action.payload.phone;
      row.address = action.payload.address;
      state.isLoading = false;
    },
    deletedSuccess: (state, action) => {
      state.rows = state.rows.filter(r => r.id !== action.payload);
      state.isLoading = false;
    },
    resetSuccess: state => {
      state.rows = null;
    },
    submittedSuccess: (state, action) => {
      state.submitted = action.payload;
      state.isLoading = false;
    },
    setSelectedSuccess: (state, action) => {
      const participant = action.payload.participant;
      if (participant) {
        state.rows.filter(a => {
          if (!!a.email) {
            if (participant.id) {
              return a.id === participant.id;
            } else {
              return a.code === participant.code;
            }
          }
          return false;
        }).forEach(a => a.isSelected = action.payload.selected);
      } else {
        state.rows.filter(a => !!a.email).forEach(a => a.isSelected = action.payload.selected);
      }
    },
    ownersSuccess: (state, action) => {
      const owners = cloneDeep(action.payload);
      owners.forEach(o => {
        o.participantType = o.applicantType;
        o.isPlan = true;
      });
      state.rows = [...state.rows?.filter(r => !!r.id && r.particpantType !== ApplicantType.owner), ...owners];
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, rowsSuccess, insertedSuccess, deletedSuccess, resetSuccess, saveSuccess,
  submittedSuccess, setSelectedSuccess, ownersSuccess } = slice.actions;

export const setParticipants = (rows) => async dispatch => {
  dispatch(rowsSuccess(rows));
};

export const resetParticipants = () => async dispatch => {
  dispatch(resetSuccess());
};

export const resetSubmitted = () => async dispatch => {
  dispatch(submittedSuccess(false));
};

export const setParticipantSelected = (selected, participant) => async dispatch => {
  dispatch(setSelectedSuccess({ selected, participant }));
};

export const fetchParticipants = (planningId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(apiPath(planningId)).then((response) => {
      dispatch(rowsSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const addParticipant = (planningId, data) => async dispatch => {
  dispatch(startLoading());
  try {
    return await api.post(`${apiPath(planningId)}`, data).then((response) => {
      dispatch(insertedSuccess(response.data));
      dispatch(showSuccess("form.saved"));
      return response.data;
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const saveParticipant = (planningId, participantId, data) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath(planningId)}/${participantId}`, data).then((response) => {
      dispatch(saveSuccess(response.data));
      dispatch(submittedSuccess(true));
      dispatch(showSuccess("form.saved"));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const deleteParticipant = (planningId, participantId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.delete(`${apiPath(planningId)}/${participantId}`).then((response) => {
      dispatch(deletedSuccess(participantId));
      dispatch(showSuccess("form.saved"));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const sendNotifications = (planningId, dto) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.post(`${apiPath(planningId)}/notify`, dto).then((response) => {
      dispatch(submittedSuccess(true));
      dispatch(setParticipantSelected(false));
      dispatch(showSuccess("form.saved"));
      dispatch(fetchDocuments(planningId));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchOwners = (planningId) => async dispatch => {
  dispatch(toggleLoadingOverlay(true));
  dispatch(startLoading());
  try {
    await api.get(`${apiPath(planningId)}/owners`).then((response) => {
      dispatch(ownersSuccess(response.data));
      dispatch(toggleLoadingOverlay(false));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
    dispatch(toggleLoadingOverlay(false));
  }
};

export const addInvolved = (planningId) => async dispatch => {
  dispatch(startLoading());
  try {
    return await api.post(`${apiPath(planningId)}/involved`).then((response) => {
      dispatch(insertedSuccess(response.data));
      return true;
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};