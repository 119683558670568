import { createSlice } from '@reduxjs/toolkit';
import { api } from '../api/search';
import StoreUtils from '../utils/StoreUtils';
import { XMLParser } from 'fast-xml-parser';
import { showWarning } from './notification';

// Slice
const slice = createSlice({
  name: 'search',
  initialState: {
    suggestions: [],
    rows: null,
    documents: null,
    tableOpen: {},
    isLoading: false,
    isLoadingSuggestions: false,
    isLoadingDocuments: false,
    error: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    startLoadingSuggestions: state => {
      state.isLoadingSuggestions = true;
    },
    startLoadingDocuments: state => {
      state.isLoadingDocuments = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isLoadingSuggestions = false;
    },
    rowsSuccess: (state, action) => {
      state.tableOpen = {};
      const data = action.payload;
      if (data?.hits) {
        state.rows = data.hits?.hits.map(hit => hit._source) || [];
      }
      state.isLoading = false;
    },
    suggestionsSuccess: (state, action) => {
      if (state.isLoadingSuggestions || !action.payload) {
        const hits = action.payload?.hits?.hits;
        state.suggestions = hits?.length ? [...new Set(hits.flatMap(hit => hit.fields.title))] : [];
        state.isLoadingSuggestions = false;
      }
    },
    documentsSuccess: (state, action) => {
      state.documents = action.payload;
      state.isLoadingDocuments = false;
    },
    tableOpenSuccess: (state, action) => {
      state.tableOpen[action.payload.domain] = action.payload.open;
    }
  },
});

export default slice.reducer;

// Actions

const { rowsSuccess, suggestionsSuccess, documentsSuccess, tableOpenSuccess,
  startLoading, startLoadingSuggestions, startLoadingDocuments, hasError } = slice.actions;

export const fetchSearchRows = (value) => async dispatch => {
  dispatch(rowsSuccess());
  dispatch(documentsSuccess());
  dispatch(startLoading());
  try {
    await api.get(`search?value=${value}`).then((response) => dispatch(rowsSuccess(response.data)));
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchSuggestions = (value) => async dispatch => {
  dispatch(startLoadingSuggestions());
  try {
    await api.get(`suggest?value=${value}`).then((response) => dispatch(suggestionsSuccess(response.data)));
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchDocuments = (value) => async dispatch => {
  dispatch(startLoadingDocuments());
  try {
    await api.get(`search/document?value=${value}`).then((response) => {
      const json = new XMLParser({
        ignoreDeclaration: true
      }).parse(response.data);
      const result = json?.AmphoraSearchResult;
      let documents;
      if (result && result.Result === 'OK') {
        if (result.TotalCount === 1) {
          documents = [result.DocumentList?.AmphoraDocument];
        } else if (result.TotalCount > 1) {
          documents = result.DocumentList?.AmphoraDocument;
        }
      } else {
        dispatch(showWarning('error.searchDocuments'));
      }
      dispatch(documentsSuccess(documents));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const getDocumentLink = (id) => `${api.defaults.baseURL}/search/document/${id}`;

export const resetSuggestions = () => async dispatch => dispatch(suggestionsSuccess());

export const setTableOpen = (domain, open) => async dispatch => dispatch(tableOpenSuccess({ domain, open }));