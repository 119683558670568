import { useContext, useEffect, useState } from "react";
import MapContext from "../MapContext";
import OLVectorLayer from "ol/layer/Vector";
import OLVectorSource from "ol/source/Vector";
import { useDispatch, useSelector } from "react-redux";
import { fetchPlanningTempObjects } from "../../../stores/planning/planningTempObject";
import MapUtils from "../../../utils/MapUtils";
import { Feature } from "ol";
import LayerStyles from "../LayerStyles";

const PlanDrawingLayer = ({ type, queryable = false, zIndex = 0, title }) => {
	const { map } = useContext(MapContext);
  const dispatch = useDispatch();
	const [layer, setLayer] = useState();
  const { rows } = useSelector(state => state.planningTempObject);

  const filteredRows = rows[type];

	useEffect(() => {
		if (!map) return;
		let vectorLayer = new OLVectorLayer({
			source: new OLVectorSource({ features: [] }),
			style: layerStyleFunction,
      type,
      queryable,
      title,
		});

		map.addLayer(vectorLayer);
		vectorLayer.setZIndex(zIndex);

		setLayer(vectorLayer);

		return () => {
			if (map) {
				map.removeLayer(vectorLayer);
				setLayer(null);
			}
		};
	}, [map, queryable, title, type, zIndex]);

	useEffect(() => {
		if (layer) {
			layer.getSource().clear();
			if (filteredRows?.length) {
				const olFeatures = filteredRows.map(r => {
          return new Feature({
            id: r.id,
            type: r.type, 
            name: r.name,
            description: r.description, 
            color: r.color,
            procedureType: 'tempObject',
            geometry: MapUtils.toOLGeometry(r.geometry)
          })
				});
				layer.getSource().addFeatures(olFeatures);
			}
		}
	}, [layer, filteredRows]);

  useEffect(() => {
    dispatch(fetchPlanningTempObjects(type));
  }, [dispatch, type]);


	return null;
};

const styles = {};

const layerStyleFunction = (feature, resolution) => {
  const color = feature.get('color');
  if(color) {
    if(!styles[color]){
      styles[color] = LayerStyles.drawedFeatureWithCustomColor(
        MapUtils.convertHexToRgb(color), true
      );
    }
    return styles[color];
  } else {
    return LayerStyles.highlightFeature;
  }
};

export default PlanDrawingLayer;