import { Button, ClickAwayListener, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Paper, Popper } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { Colors, Heading, Icon, Shadows } from 'styleguide';

const useStyles = makeStyles((theme) => ({
  selectPanel: {
    maxHeight: 650,
    width: 296,
    boxShadow: Shadows.shadow1,
    overflowY: 'scroll'
  },
  divider: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  listItem: {
    paddingTop: 4,
    paddingBottom: 4
  },
  popper: {
    zIndex: 2000
  }
}));

export default function TableSelect({ selected, values, handleValueSelected }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  let popperAnchor = useRef(null);

  const toggleOpen = () => setOpen(!open);

  const handleClose = (event) => {
    if (popperAnchor.current && popperAnchor.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleChange = (value) => {
    if (value.code !== selected) {
      handleValueSelected(value);
      toggleOpen();
    }
  };

  if (!values || !values.length) {
    return <></>;
  }

  return <span>
    <Button
      onClick={toggleOpen}
      size="small" ref={popperAnchor}
      aria-label="select role"
    >
      <Heading level="4">{values.find(o => o.code === selected)?.title}</Heading>
      <Icon icon={open ? "arrowUp" : "arrowDown"}></Icon>
    </Button>
    <Popper open={open} anchorEl={popperAnchor.current} placement="right-start" modifiers={{ offset: { enabled: true, offset: '0, 17' } }} className={classes.popper}>
      <Paper elevation={0} className={classes.selectPanel}>
        <ClickAwayListener onClickAway={handleClose}>
          <List>
            {values.map((value) => (<span key={value.code}>
              <ListItem button onClick={() => handleChange(value)} selected={value.code === selected} className={classes.listItem}>
                <ListItemText secondary={value.title} />
                <ListItemSecondaryAction>
                  {value.code === selected && <Icon icon="tick" color={Colors.sinineVaal} />}
                </ListItemSecondaryAction>
              </ListItem>
              {value.divider && <Divider className={classes.divider} />}
            </span>))}
          </List>
        </ClickAwayListener>
      </Paper>
    </Popper>
  </span>;
}