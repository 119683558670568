import { useContext } from "react";
import { getPointResolution } from 'ol/proj';
import MapContext from "../MapContext";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MapUtils from "../../../utils/MapUtils";
import { toggleLoadingOverlay } from "../../../stores/notification";
import MapControlButton from "./MapControlButton";
import { Button, Paragraph } from "styleguide";
import PdfUtils from "../../../utils/PdfUtils";
import AuthUtils from "../../../utils/AuthUtils";

const ExportPdfControl = () => {
  const { map } = useContext(MapContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mobileView = useSelector(state => state.global.mobileView);
  const authUser = useSelector(state => state.auth.authUser);

  const filename = t('map.pdf.filename');

  const handlePdfExport = (format) => {
    document.body.style.cursor = 'progress';
    dispatch(toggleLoadingOverlay(true));

    const dim = PdfUtils.getMapSize(format);
    const size = map.getSize();
    const mapView = map.getView();
    const width = PdfUtils.mmToPx(dim[0]);
    const height = PdfUtils.mmToPx(dim[1]);
    const viewResolution = mapView.getResolution();
    const scale = MapUtils.getScaleForResolution(viewResolution);
    const mapProjection = mapView.getProjection();
    const viewCenter = mapView.getCenter();

    const scaleResolution =
      (scale / 1000) /
      getPointResolution(
        mapProjection,
        MapUtils.mapDpi / 25.4,
        viewCenter
      );

    map.setSize([width, height]);
    mapView.setResolution(scaleResolution);

    map.once('rendercomplete', function () {
      const legendTable = document.getElementById('legend-table');
      legendTable.style.width = width + 'px';

      const mapCanvas = PdfUtils.drawMapCanvas(width, height);

      const pdf = PdfUtils.newPdf(format);
      pdf.setFontSize(10);
      pdf.text(`${t('map.pdf.scale')} 1:${scale}`, format === 'A3' ? 251 : 171, 23);

      PdfUtils.addTitle(pdf, t('map.pdf.title'));

      pdf.addImage(mapCanvas.toDataURL('image/jpeg'), 'JPEG', 10, 25, dim[0], dim[1]);

      PdfUtils.addPage(pdf, format);
      PdfUtils.addTable(pdf, 'legend-table', 25, false, format);
      PdfUtils.savePdf(pdf, filename);

      // Reset original map size
      map.setSize(size);
      map.getView().setResolution(viewResolution);

      document.body.style.cursor = 'auto';
      dispatch(toggleLoadingOverlay(false));
    });
  };

  const handlePngExport = () => {
    const [width, height] = map.getSize();
    const mapCanvas = PdfUtils.drawMapCanvas(width, height);

    PdfUtils.saveCanvas(mapCanvas, filename);
  };

  return <MapControlButton controlName="export" icon="downloadFile">
    <div className="ol-export-panel">
      {!mobileView && <Paragraph>{t('map.control.export')}</Paragraph>}
      <Button size="extra-small" aria-label="export map pdf" onClick={() => handlePdfExport('A4')}>
        {t('map.control.exportPdf')}
      </Button>
      {!mobileView && (AuthUtils.isGov(authUser) || AuthUtils.isPartner(authUser)) &&
        <Button size="extra-small" aria-label="export map pdf" onClick={() => handlePdfExport('A3')}>
          {t('map.control.exportPdfA3')}
        </Button>
      }
      <Button size="extra-small" aria-label="export map png" onClick={handlePngExport}>{t('map.control.exportPng')}</Button>
    </div>
  </MapControlButton>;
};

export default ExportPdfControl;