import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/application';
import StoreUtils from '../../utils/StoreUtils';
import TableUtils from '../../utils/TableUtils';
import { showSuccess } from '../notification';
import { fetchDocuments, resetDocuments } from './planningDocument';
import { fetchOperations, resetOperations } from './planningOperation';
import { fetchParticipants, resetParticipants } from './planningParticipant';
import { resetLocation } from './planningLocation';
import { resetRows as resetLogRows } from '../activityLog';

const apiPath = "planning";
export const getLogsPath = (planningId) => `${apiPath}/${planningId}/log`;

const initialPageable = {
  pageSize: TableUtils.getPageSize(178),
  pageNumber: 0,
  sort: {
    field: "initiatedDate",
    ascending: false
  },
  loaded: false,
  last: true
};

// Slice
const slice = createSlice({
  name: 'planning',
  initialState: {
    rows: [],
    selected: null,
    pageable: initialPageable,
    totalElements: null,
    filter: {},
    userFilter: [],
    isLoading: false,
    isLoadingTab: null,
    error: false,
    submitted: false,
  },
  reducers: {
    startLoading: (state, action) => {
      state.isLoading = true;
      state.isLoadingTab = action.payload;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    rowsSuccess: (state, action) => {
      if (state.isLoadingTab === action.payload.tab) {
        const data = action.payload.data;
        state.rows = TableUtils.mergeArrayById(state.rows, data.content);
        state.pageable.last = data.last;
        state.totalElements = data.totalElements;
        state.pageable.loaded = true;
        state.isLoading = false;
        state.isLoadingTab = null;
      }
    },
    resetRowsSuccess: (state) => {
      state.pageable.pageNumber = 0;
      state.pageable.loaded = false;
      state.rows = [];
    },
    pageableSuccess: (state, action) => {
      if (action.payload.pageNumber) {
        state.pageable.pageNumber += action.payload.pageNumber;
      }
      if (action.payload.sort) {
        state.pageable.sort = action.payload.sort;
        state.pageable.pageNumber = 0;
        state.rows = [];
      }
      state.pageable.loaded = false;
    },
    filterSuccess: (state, action) => {
      if ((state.filter[action.payload.field] || '') !== action.payload.value) {
        state.filter[action.payload.field] = action.payload.value;
        state.pageable.pageNumber = 0;
        state.rows = [];
        state.pageable.loaded = false;
      }
    },
    selectedSuccess: (state, action) => {
      state.selected = action.payload;
      state.isLoading = false;
    },
    submittedSuccess: (state, action) => {
      state.submitted = action.payload;
      state.isLoading = false;
    },
    userFilterSuccess: (state, action) => {
      state.userFilter = action.payload || [];
    },
  },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, rowsSuccess, pageableSuccess, filterSuccess, selectedSuccess,
  resetRowsSuccess, userFilterSuccess, submittedSuccess } = slice.actions;

export const updatePageable = (pageNumber, sort) => async dispatch => {
  dispatch(pageableSuccess({ pageNumber: pageNumber, sort: sort }));
};

export const updateFilter = (field, value) => async dispatch => {
  dispatch(filterSuccess({ field, value }));
};

export const updateUserFilter = (userFilter) => async dispatch => {
  dispatch(userFilterSuccess(userFilter));
  dispatch(resetRows());
};

export const resetRows = () => async dispatch => {
  dispatch(resetRowsSuccess());
};

export const setDetails = (selected) => async dispatch => {
  dispatch(selectedSuccess(selected));
};

export const resetDetails = () => async dispatch => {
  dispatch(selectedSuccess());
  dispatch(resetDocuments());
  dispatch(resetParticipants());
  dispatch(resetOperations());
  dispatch(resetLocation());
};

export const setSubmitted = () => async dispatch => {
  dispatch(submittedSuccess(true));
  dispatch(resetRowsSuccess());
};

export const resetSubmitted = () => async dispatch => {
  dispatch(submittedSuccess(false));
};

export const fetchPlanningRows = (status, pageable, filter, columns, userRestriction) => async dispatch => {
  dispatch(startLoading(status));
  try {
    const path = status === 'archive' ? 'archived' : `active/${status}`;
    let userRestParam = '';
    if (userRestriction?.includes('assignedToMe')) {
      userRestParam += '&assignedToMe=true';
    }
    if (!!filter.handlerId && columns.find(c => c.field === 'handlerId').type !== 'govUser') {
      userRestParam += `&handlerName=${filter.handlerId}`;
    }
    await api.get(`${apiPath}/${path}?${TableUtils.pageableToParams(pageable, filter, columns)}${userRestParam}`).then((response) => {
      dispatch(rowsSuccess({ data: response.data, tab: status }));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchSelected = (id) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(`${apiPath}/${id}`).then((response) => {
      dispatch(selectedSuccess(response.data));
      dispatch(fetchParticipants(id));
      dispatch(fetchOperations(id));
      dispatch(fetchDocuments(id));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const savePlanning = (planningId, data) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath}/${planningId}`, data).then((response) => {
      dispatch(selectedSuccess(response.data));
      dispatch(setSubmitted());
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const planInitiate = (applicationId, dto) => async dispatch => {
  dispatch(startLoading());
  try {
    let formData = new FormData();
    Object.keys(dto).forEach(key => formData.append(key, dto[key]));
    const planning = await api.post(`${apiPath}/application/${applicationId}`, formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    ).then((response) => response.data);
    return planning;
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const planInvalidate = (applicationId, dto) => async dispatch => {
  dispatch(startLoading());
  try {
    let formData = new FormData();
    Object.keys(dto).forEach(key => formData.append(key, dto[key]));
    await api.post(`${apiPath}/application/${applicationId}/planInvalidate`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(() => {
      dispatch(setSubmitted());
      dispatch(selectedSuccess());
      dispatch(showSuccess("form.saved"));
    })
    return true;
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const planMakeDecision = (planningId, decision, dto) => async dispatch => {
  dispatch(startLoading());
  try {
    let formData = new FormData();
    Object.keys(dto).forEach(key => formData.append(key, dto[key]));
    const planning = await api.post(`${apiPath}/${planningId}/decision/${decision}`, formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    ).then((response) => response.data);
    dispatch(resetDetails());
    dispatch(resetRows());
    dispatch(showSuccess("form.saved"));
    return planning;
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const saveProposal = (planningId, dto) => async dispatch => {
  dispatch(startLoading());
  try {
    let formData = new FormData();
    Object.keys(dto).forEach(key => formData.append(key, dto[key]));
    await api.post(`${apiPath}/${planningId}/proposal`, formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    ).then((response) => {
      dispatch(setSubmitted());
      dispatch(fetchParticipants(planningId));
      dispatch(fetchOperations(planningId));
      dispatch(fetchDocuments(planningId));
      dispatch(showSuccess("planning.notification.proposalSubmitted"));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const changeHandler = (planningId, description, handlerId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath}/${planningId}/handler/change`, { description, handlerId }).then((response) => {
      dispatch(submittedSuccess(true));
      dispatch(selectedSuccess());
      dispatch(resetRowsSuccess());
      showSuccess('planning.notification.sentForProcess');
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const planAddPlankBooking = (planningId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.post(`${apiPath}/${planningId}/plank/booking`).then((response) => {
      dispatch(setDetails(response.data));
      dispatch(showSuccess("planning.notification.plankBooked", { plankSysId: response.data.plankSysId }));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const planSubmitToPlank = (planningId, reason) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.post(`${apiPath}/${planningId}/plank/submit`, { reason }).then((response) => {
      dispatch(setDetails(response.data));
      dispatch(showSuccess("planning.notification.plankSubmitted"));
      dispatch(resetLogRows());
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const getPlankExcelUrl = (planningId) => `${api.defaults.baseURL}/${apiPath}/${planningId}/plank/excel`;