import { Divider, List, ListItem, ListItemText, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Heading, Paragraph } from 'styleguide';
import { fetchEntered } from '../../stores/application/application';
import { CenteredLoadingIndicator } from '../form/LoadingIndicator';
import LinkButton from '../LinkButton';
import CroppedText from '../table/CroppedText';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 435,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    borderRadius: 16,
    marginBottom: 40,
  },
  paper: {
    borderRadius: 16,
  },
  title: {
    padding: 16,
  },
  listItem: {
    padding: '8px 16px'
  },
}));

export default function ApplicationReviewBlock() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const isLoading = useSelector(state => state.application.isLoading);
  const [rows, setRows] = useState();

  useEffect(() => {
    async function loadData() {
      const r = await dispatch(fetchEntered());
      setRows(r);
    }
    loadData();
  }, [dispatch]);

  if (!!rows && !rows.length) {
    return "";
  }

  return <div className={classes.container}>
    {!rows && <CenteredLoadingIndicator />}
    {!!rows && <Paper className={classes.paper}>
      <List disablePadding>
        <ListItem>
          <Heading level="4" className={classes.title}>{t('homepage.block.applicationReview')}</Heading>
        </ListItem>

        <Divider />

        {rows.map(row => <ListItem key={row.id} >
          <ListItemText disableTypography className={classes.listItem}
            primary={<Paragraph fontSize="14B"><CroppedText value={row.address} length={70} /></Paragraph>}
            secondary={<Paragraph fontSize="14">{row.location}</Paragraph>} />
          <LinkButton to={`/application/redirect/${row.id}`} size="extra-small" disabled={isLoading}>{t('button.take')}</LinkButton>
        </ListItem>)}
      </List>
    </Paper>}
  </div>;
}