import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContainer } from '../../form/DialogForm';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import NavButton from 'styleguide/build/components/Buttons/NavButton/NavButton';
import { Button, Checkbox, Colors, Heading, Icon, Label } from 'styleguide';
import VTextField from '../../form/VTextField';
import { addGroup, calculateNumbering, deleteGroup, fetchClause, getPdfPreviewDownloadUrl, saveGroup, setBlockCadastres, setBlockInvalid, setBlockValue, setClauseValue, setGroupValue } from '../../../stores/application/clause';
import { Box, Grid, IconButton, InputAdornment, List, ListItem, ListSubheader, makeStyles, Paper } from '@material-ui/core';
import { ApplicationStatus, ApplicationType, ClauseBlockType } from '../../../constants/classifierConstants';
import ClassifierAutocomplete from '../../form/ClassifierAutocomplete';
import ConfirmButton from '../../table/ConfirmButton';
import { showWarning, toggleLoadingOverlay } from '../../../stores/notification';
import { CenteredLoadingIndicator } from '../../form/LoadingIndicator';
import ClauseOverview, { ClauseBlockContent } from './ClauseOverview';
import ApplicationUtils from '../../../utils/ApplicationUtils';
import { closestCenter, DndContext, DragOverlay, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { cloneDeep } from 'lodash';
import MapSelectCadastres from './MapSelectCadastres';
import { setActiveGeometries, toggleLayerDrawer, toggleSelectActive } from '../../../stores/map/map';
import MuiButton from '@material-ui/core/Button';
import { GeometryType } from '../../../constants/mapConstants';
import MapDialog from '../../map/MapDialog';
import PdfUtils from '../../../utils/PdfUtils';
import { addFile, deleteFile, fetchFiles, getFileDownloadUrl, saveFileDescription } from '../../../stores/application/clauseFile';
import { useDropzone } from 'react-dropzone';
import Paragraph from 'styleguide/build/components/Typography/Paragraph/Paragraph';
import RoadSearch from '../../form/RoadSearch';
import MapUtils from '../../../utils/MapUtils';
import { fetchSelected } from '../../../stores/application/application';
import GovUserSelect from '../../form/GovUserSelect';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      width: 1200,
    },
    [theme.breakpoints.down('md')]: {
      width: '95%',
    },
    paddingTop: 12,
    paddingBottom: 88,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  templateRow: {
    lineHeight: '48px',
  },
  headerRow: {
    lineHeight: '50px'
  },
  blockContent: {
    lineHeight: '36px'
  },
  templateClassifier: {
    display: 'inline-block',
    width: 300,
    marginLeft: 8,
    marginRight: 8,
  },
  contentEdit: {
    width: 800
  },
  draggingPaper: {
    backgroundColor: Colors.hall3
  },
  dragActiveUpload: {
    backgroundColor: Colors.withOpacity(Colors.sinineVaal, 0.1),
  },
  indentSymbol: {
    width: 14,
    display: 'inline-block'
  }
}));

function EditContentButton({ onConfirm, defaultValue, label, icon, ...rest }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [reason, setReason] = useState(defaultValue || '');

  useEffect(() => setReason(defaultValue), [defaultValue]);

  return <ConfirmButton {...rest}
    message={label || t('clause.confirmEditContent')}
    icon={icon || 'edit'}
    aria-label="edit text content"
    isColumnLayout maxWidth="lg"
    dialogContent={
      <VTextField autoFocus value={reason} onChange={(event) => setReason(event.target.value)}
        multiline minRows={5} fullWidth className={classes.contentEdit} />
    }
    onConfirm={() => onConfirm(reason)}
    confirmDisabled={!reason}
  />;
}

function EditRoadNamesButton({ onConfirm, defaultValue, geometry, ...rest }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [roadNames, setRoadNames] = useState(defaultValue || '');
  const layers = useSelector(state => state.map.layers);

  useEffect(() => setRoadNames(defaultValue), [defaultValue]);

  const handleSetRoad = (road) => {
    const newValue = (!!roadNames ? `${roadNames}, ` : '') + road.title;
    setRoadNames(newValue);
  };

  const handleFindByGeometry = async () => {
    dispatch(toggleLoadingOverlay(true));
    const features = await MapUtils.fetchFeaturesByLayerName(layers, 'road_status', geometry, dispatch);
    if (features?.length) {
      setRoadNames(features.map(f => f.properties.title).join(', '));
    }
    dispatch(toggleLoadingOverlay(false));
  };

  return <ConfirmButton {...rest}
    message={t('clause.confirmEditRoadNames')}
    icon="edit" enableOnClickPropagation
    aria-label="edit road names"
    isColumnLayout maxWidth="lg"
    dialogContent={<Grid container direction="column" spacing={4}>
      <Grid item>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <RoadSearch handleSetRoad={handleSetRoad} pageSize={7} useAltStyle />
          </Grid>
          <Grid item>
            {!!geometry && <Button onClick={handleFindByGeometry} size="small" color="secondary">
              {t('clause.button.findRoadByGeometry')}
            </Button>}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <VTextField autoFocus value={roadNames} onChange={(event) => setRoadNames(event.target.value)}
          multiline minRows={7} fullWidth className={classes.contentEdit} />
      </Grid>
    </Grid>}
    onConfirm={() => onConfirm(roadNames)}
  />;
}

function Preamble({ clause, disabled }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const isRoadApplication = [ApplicationType.TPT, ApplicationType.TETT].includes(clause.applicationType);

  return <Grid container direction="row">
    {isRoadApplication && <>
      <Grid item xs={11} className={classes.blockContent}>
        <Paragraph fontSize="14B">{t('clause.roadNames')}</Paragraph>
        {clause.roadNames}
      </Grid>
      <Grid item>
        <EditRoadNamesButton onConfirm={(value) => dispatch(setClauseValue('roadNames', value))}
          defaultValue={clause.roadNames} disabled={disabled} geometry={clause.geometry} />
      </Grid>
      <Grid item xs={11} className={classes.blockContent}>
        <Paragraph fontSize="14B">{t('clause.project')}</Paragraph>
        {clause.project}
      </Grid>
      <Grid item>
        <EditContentButton onConfirm={(value) => dispatch(setClauseValue('project', value))}
          defaultValue={clause.project} disabled={disabled} label={t('clause.confirmEditProject')} />
      </Grid>
    </>}

    <Grid item xs={11} className={classes.blockContent}>
      <Paragraph fontSize="14B">{t('clause.preamble')}</Paragraph>
      {ApplicationUtils.replaceClauseVariables(clause.preamble, clause)}
    </Grid>
    <Grid item>
      <EditContentButton onConfirm={(value) => dispatch(setClauseValue('preamble', value))}
        defaultValue={clause.preamble} disabled={disabled} label={t('clause.confirmEditPreamble')} />
    </Grid>
  </Grid>;
}

function Group({ applicationId, clause, group, disabled, handleShowMap, handleNavigation }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const sensors = useSensors(
    useSensor(PointerSensor),
  );
  const [activeBlock, setActiveBlock] = useState();

  const handleSetValue = (field, value) => {
    dispatch(setGroupValue(group, field, value));
  };

  const handleSetEditing = (enabled) => handleSetValue('editing', enabled);

  const handleCopy = () => {
    dispatch(addGroup(applicationId, group));
    handleNavigation(1);
  };

  const handleDelete = () => {
    dispatch(deleteGroup(applicationId, group.id));
  };

  const renderHeader = () => <Grid container direction="row">
    <Grid item>
      <Checkbox
        onChange={handleSelectAll}
        disabled={disabled}
        checked={group.isSelected || false}
      />
    </Grid>
    <Grid item xs={10}>
      <Heading level="3" className={classes.headerRow}>
        {ApplicationUtils.replaceGroupTitle(group.content, group.value)}
      </Heading>
    </Grid>
    <Grid item>
      {ApplicationUtils.hasGroupTitlePlaceholder(group.content) && <span>
        {group.isSelected && <IconButton onClick={() => handleSetEditing(true)} disabled={disabled} aria-label="edit group" title={t('clause.button.editGroup')}>
          <Icon icon="edit" />
        </IconButton>}
        <IconButton onClick={handleCopy} disabled={disabled} aria-label="copy group" title={t('clause.button.copyGroup')}>
          <Icon icon="add" />
        </IconButton>
        <ConfirmButton message={t('form.confirmDelete')} icon="delete" onConfirm={handleDelete} aria-label="delete group"
          title={t('clause.button.deleteGroup')} disabled={disabled} />
      </span>}
    </Grid>
  </Grid>;

  const renderEditingHeader = () => {
    const parts = group.content?.split(ApplicationUtils.templateGroupTitle);
    if (!parts?.length) {
      return;
    }

    return <Heading level="3" className={classes.templateRow}>
      {(parts.length > 1) && parts[0]}
      <div className={classes.templateClassifier}>
        <VTextField value={group.value || ''} disabled={disabled}
          onChange={(e) => handleSetValue('value', e.target.value)}
          onKeyDown={(ev) => ev.key === 'Enter' && handleSetEditing(false)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                {!!group.value && <IconButton onClick={() => handleSetEditing(false)} disabled={disabled}>
                  <Icon icon="tick" />
                </IconButton>}
              </InputAdornment>
            ),
          }}
        />
      </div>
      {parts[parts.length - 1]}
    </Heading>
  };

  const handleDragStart = (event) => {
    setActiveBlock(group.blocks.find(b => b.id === event.active.id));
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = group.blocks.findIndex(b => b.id === active.id);
      const newIndex = group.blocks.findIndex(b => b.id === over.id);

      const items = cloneDeep(arrayMove(group.blocks, oldIndex, newIndex));
      items.forEach((item, index, array) => array[index].queueNumber = index + 1);
      dispatch(setGroupValue(group, 'blocks', items));
      setActiveBlock(null);
    }
  };

  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    dispatch(setGroupValue(group, 'isSelected', checked));
    group.blocks.forEach(b => dispatch(setBlockValue(b, 'isSelected', checked)));
  };

  const handleAddBlock = (block, content) => {
    const newBlock = {
      id: '_' + Date.now(),
      isSelected: true,
      content: content,
      originalBlockId: block.id,
      clauseGroupId: block.clauseGroupId,
      classifierTypeCode: block.classifierTypeCode,
      blockType: block.blockType,
      isNumerable: block.isNumerable,
      queueNumber: block.queueNumber + 1,
      indent: block.indent,
      notSaved: true
    };
    const items = cloneDeep(group.blocks);
    items.splice(block.queueNumber, 0, newBlock);
    items.forEach((item, index, array) => array[index].queueNumber = index + 1);
    dispatch(setGroupValue(group, 'blocks', items));
  };

  return <List
    subheader={
      <ListSubheader disableSticky>
        {group.editing ? renderEditingHeader() : renderHeader()}
      </ListSubheader>
    }>
    {!group.editing &&
      <DndContext
        modifiers={[restrictToVerticalAxis]}
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={group.blocks}
          strategy={verticalListSortingStrategy}
        >
          {group.blocks.map((block, index) =>
            <SortableBlock key={index} block={block} disabled={disabled} groupTitle={group.value} clause={clause}
              handleShowMap={handleShowMap} handleAddBlock={(content) => handleAddBlock(block, content)} />
          )}
        </SortableContext>
        <DragOverlay>
          {activeBlock ? <Paper className={classes.draggingPaper}>
            <Block block={activeBlock} disabled={true} groupTitle={group.value} clause={clause} />
          </Paper> : null}
        </DragOverlay>
      </DndContext>
    }
  </List>
}

function SortableBlock(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.block.id || props.block.fid });

  const style = {
    '--translate-x': transform ? transform.x : 0,
    '--translate-y': transform ? transform.y : 0,
    '--transition': transition,
  };

  return <ListItem ref={setNodeRef} style={style}>
    <Block {...props} attributes={attributes} listeners={listeners} />
  </ListItem>;
}

function Block({ clause, block, disabled, groupTitle, attributes, listeners, handleShowMap, handleAddBlock }) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const classes = useStyles();

  const handleSetValue = (field, value) => {
    dispatch(setBlockValue(block, field, value));
  };

  const handleClassifierChange = (value) => {
    handleSetValue('classifierValue', value);
  };

  const handleClassifierClose = () => {
    handleSetValue('value', block.classifierValue?.length ? block.classifierValue.map(v => v.title).join(', ') : '');
  };

  const handleClassifierClear = () => {
    handleClassifierChange(null);
    handleSetValue('value', null);
  };

  const handleEditContent = (content) => {
    if (ApplicationUtils.hasInputPlaceholder(block.content) && !ApplicationUtils.hasInputPlaceholder(content)) {
      dispatch(showWarning('clause.noTemplatePlaceholder'));
      return;
    }
    handleSetValue('content', content);
  };

  const handleOpenMap = () => {
    handleShowMap(block);
  };

  let commonParams = {
    disabled: disabled || !block.isSelected
  };
  if (block.invalid) {
    commonParams.error = true;
    commonParams.helperText = t('validation.required');
  }
  let content = ApplicationUtils.replaceGroupTitle(block.content, groupTitle);
  content = ApplicationUtils.replaceClauseVariables(content, clause);
  if (ApplicationUtils.hasInputPlaceholder(content)) {
    switch (block.blockType) {
      case ClauseBlockType.template:
        let input;
        if (block.isSelected && !block.value) {
          input = <ClassifierAutocomplete value={block.classifierValue || []}
            handleChange={handleClassifierChange} onClose={handleClassifierClose}
            classifierType={block.classifierTypeCode} autoHighlight multiple disableCloseOnSelect {...commonParams} />;
        } else {
          const inputProps = !block.isSelected ? {} : {
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={handleClassifierClear} disabled={disabled}>
                  <Icon icon="times" />
                </IconButton>
              </InputAdornment>
            ),
          };
          input = <VTextField value={block.value || ''} onChange={(e) => handleSetValue('value', e.target.value)}
            {...commonParams} multiline InputProps={inputProps} />;
        }

        content = <SplitContent content={content}>
          <div className={classes.templateClassifier}>
            {input}
          </div>
        </SplitContent>;
        break;
      case ClauseBlockType.templateFreetext:
        content = <SplitContent content={content}>
          <div className={classes.templateClassifier}>
            <VTextField value={block.value || ''} onChange={(e) => handleSetValue('value', e.target.value)}
              {...commonParams} multiline />
          </div>
        </SplitContent>;
        break;
      case ClauseBlockType.map:
        content = <SplitContent content={content}>
          {!!block.isSelected && <>
            <IconButton title={t('clause.button.chooseOnMap')} onClick={handleOpenMap} disabled={disabled}>
              <Icon icon="position" />
            </IconButton>
            <div>
              <ClauseBlockContent>{block.value?.replace('\n', '')}</ClauseBlockContent>
            </div>
            {!!commonParams.error && <Label color="red">{commonParams.helperText}</Label>}
          </>
          }
        </SplitContent>;
        break;
      default:
    }
  } else {
    content = <ClauseBlockContent>{content}</ClauseBlockContent>;
  }

  return <Grid container direction="row">
    <Grid item>
      <Checkbox
        onChange={(e) => handleSetValue('isSelected', e.target.checked)}
        disabled={disabled}
        checked={block.isSelected || false}
      />
    </Grid>
    <Grid item xs={10} className={classes.blockContent}>
      <BlockIndent block={block}>
        {content}
      </BlockIndent>
    </Grid>
    <Grid item>
      {block.isSelected && <span>
        <EditContentButton onConfirm={handleEditContent} defaultValue={block.content} disabled={disabled}
          title={t('clause.button.editBlock')} />
        <EditContentButton onConfirm={handleAddBlock} disabled={disabled || block.notSaved} icon="add"
          title={t('clause.button.addBlock')} label={t('clause.confirmAddContent')} />
        <IconButton {...attributes} {...listeners} title={t('clause.button.drag')} disabled={disabled}>
          <Icon icon="drag" />
        </IconButton>
      </span>}
    </Grid>
  </Grid>;
}

function SplitContent({ content, children }) {
  const classes = useStyles();
  const parts = content.split(ApplicationUtils.templatePlaceholder);

  return <span className={classes.templateRow}>
    <ClauseBlockContent>{parts[0]}</ClauseBlockContent>
    {children}
    {(parts.length > 1) && <ClauseBlockContent>{parts[1]}</ClauseBlockContent>}
  </span>;
}

function BlockIndent({ block, children }) {
  const classes = useStyles();
  let indent = 0;
  if (block.indent) {
    indent = block.indent * 2;
  } else if (block.isNumerable) {
    indent = 2;
  }

  return <Box paddingLeft={indent}>
    {!!block.indent && !!block.isNumerable && <span className={classes.indentSymbol}>{ApplicationUtils.indentedBlockSymbol}</span>}
    {children}
  </Box>;
}

const calculateStartingStep = (clause, totalSteps) => {
  return clause?.groups?.some(g => g.isSelected) ? totalSteps : 1;
};

function Extras({ applicationId, files, disabled, handleDrawSketch }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleUpload = (uploadedFiles) => {
    if (uploadedFiles && uploadedFiles.length) {
      const file = uploadedFiles[0];
      if (files?.some(f => f.fileName === file.name)) {
        dispatch(showWarning('fileUpload.sameFileNameNotAllowed'));
        return;
      }
      dispatch(addFile(applicationId, file));
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({
    accept: 'image/*',
    onDrop: handleUpload
  });

  if (!files) {
    return <CenteredLoadingIndicator />;
  }

  const handleEditContent = (file, content) => {
    dispatch(saveFileDescription(applicationId, file.id, content));
  };

  const handleDelete = (file) => {
    dispatch(deleteFile(applicationId, file.id));
  };


  return <List disablePadding subheader={
    <ListSubheader disableSticky>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={8}>
          <Heading level="3" className={classes.headerRow}>
            {t('clause.extrasTitle')}
          </Heading>
        </Grid>
        <Grid item>
          <div {...getRootProps({ onClick: e => e.preventDefault(), className: isDragActive ? classes.dragActiveUpload : '' })}>
            <MuiButton color="primary" disabled={disabled}>
              <Icon icon="add" color={Colors.sinineVaal} margin="6px" /> {t('clause.button.addFile')}
            </MuiButton>
            <input type="file" name="file" {...getInputProps()} />
          </div>
        </Grid>
        <Grid item>
          <MuiButton color="primary" onClick={handleDrawSketch} disabled={disabled}>
            <Icon icon="pencil" color={Colors.sinineVaal} margin="6px" /> {t('clause.button.drawSketch')}
          </MuiButton>
        </Grid>
      </Grid>
    </ ListSubheader>
  }>
    {files.map((file, index) => <Grid key={index} container direction="row">
      <Grid item xs={10} className={classes.blockContent}>
        <Paragraph>
          <a href={getFileDownloadUrl(applicationId, file.id)}
            target="_blank" rel="noreferrer"
            title={t('fileUpload.download')}>
            {`${index + 1}. ${file.description || file.fileName}`}
          </a>
        </Paragraph>
      </Grid>
      <Grid item>
        <EditContentButton onConfirm={(content) => handleEditContent(file, content)} defaultValue={file.description} disabled={disabled}
          label={t('clause.confirmEditExtra')} />
        <ConfirmButton message={t('form.confirmDelete')} icon="delete" onConfirm={() => handleDelete(file)} aria-label="delete file"
          title={t('clause.button.deleteFile')} disabled={disabled} />
      </Grid>
    </Grid>
    )}
  </List>
}

function Signers({ application, clause, disabled }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleChangeSignatory = (user) => {
    dispatch(setClauseValue('signatoryId', user?.id))
  };

  const hasDraftsman = !!clause.draftsmanId;

  return <Grid container direction="row" spacing={4} alignItems="center">
    <Grid item xs={12}>
      <Heading level="3">{t('clause.signersTitle')}</Heading>
    </Grid>

    <Grid item xs={12} lg={4}>
      <Paragraph>1. {t('application.handler')}</Paragraph>
    </Grid>
    <Grid item xs={12} lg={8} className={classes.blockContent}>
      <Paragraph>{application.handlerName}</Paragraph>
    </Grid>

    {hasDraftsman && <>
      <Grid item xs={12} lg={4}>
        <Paragraph>2. {t('clause.draftsman')}</Paragraph>
      </Grid>
      <Grid item xs={12} lg={8} className={classes.blockContent}>
        <Paragraph>{clause.signatoryName}</Paragraph>
      </Grid>
    </>}

    {!hasDraftsman && <>
      <Grid item xs={12} lg={4}>
        <Paragraph>2. {t('clause.signatory')}</Paragraph>
      </Grid>
      <Grid item xs={12} lg={4}>
        <GovUserSelect autoFocus autoHighlight
          defaultId={clause.signatoryId} handleChange={handleChangeSignatory} />
      </Grid>
    </>}
  </Grid>;
}

export default function ClauseForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { step, id } = useParams();

  const clause = useSelector(state => state.clause.clause);
  const disabled = useSelector(state => state.clause.isLoading);
  const isDirty = useSelector(state => state.clause.isDirty);
  const files = useSelector(state => state.clauseFile.rows);
  const application = useSelector(state => state.application.selected);
  const authUser = useSelector(state => state.auth.authUser);

  const [showMap, setShowMap] = useState(false);
  const [showCadastresMap, setShowCadastresMap] = useState(false);
  const [showDrawSketch, setShowDrawSketch] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState();
  const [nextStep, setNextStep] = useState();

  //fetch clause
  const shouldFetch = !!id && (!clause || clause.applicationId !== Number(id));
  useEffect(() => shouldFetch && dispatch(fetchClause(id)), [dispatch, id, shouldFetch]);

  //fetch clause files
  const shouldFetchFiles = !shouldFetch && !files;
  useEffect(() => shouldFetchFiles && dispatch(fetchFiles(id)), [dispatch, id, shouldFetchFiles]);

  //redirect on step change
  const shouldRedirect = !isDirty && !!nextStep && step !== nextStep;
  useEffect(() => shouldRedirect && history.replace(`/application/clause/${id}/${nextStep}`), [shouldRedirect, id, nextStep, history]);

  const totalSteps = clause?.groups?.length + 4;

  //redirect to starting step (new clause is 1, modifying clause is last step)
  const startingStep = (!step && !shouldFetch) ? calculateStartingStep(clause, totalSteps) : null;
  useEffect(() => !!startingStep && history.replace(`/application/clause/${id}/${startingStep}`), [startingStep, history, id]);

  //fetch application
  const shouldFetchApplication = !!id && (!application || application.id !== Number(id));
  useEffect(() => shouldFetchApplication && dispatch(fetchSelected(id)),
    [shouldFetchApplication, id, dispatch]);

  if (!clause || !step || !application) {
    return <CenteredLoadingIndicator />;
  }

  const readOnly = !((ApplicationStatus.processing === application.status && ApplicationUtils.isHandler(authUser, application)) ||
    (ApplicationStatus.drafting === application.status && ApplicationUtils.isPartner(authUser, application)));

  const stepNumber = Number(step);

  const currentStepProgress = stepNumber * 100 / totalSteps;
  const currentGroupIndex = stepNumber - 2;

  const validateBlock = (block) => {
    if (block.isSelected && block.blockType !== ClauseBlockType.text && !block.value && ApplicationUtils.hasInputPlaceholder(block.content)) {
      dispatch(setBlockInvalid(block, true));
      return false;
    }
    return true;
  }

  const handleSimpleNavigation = (direction) => setNextStep(stepNumber + direction);

  const handleNavigation = (direction) => {
    if (stepNumber > 1 && clause.groups[currentGroupIndex] && clause.groups[currentGroupIndex].blocks.some(b => !validateBlock(b))) {
      return;
    }
    const next = stepNumber + direction;
    if (isDirty) {
      setNextStep(next);
      if (stepNumber === 1 || stepNumber === totalSteps - 1) {
        dispatch(saveGroup(id, clause));
      } else {
        const group = cloneDeep(clause.groups[currentGroupIndex]);
        group.blocks.forEach(b => {
          if (isNaN(b.id)) {
            b.id = null;
          }
        });
        dispatch(saveGroup(id, clause, group));
      }
    } else {
      history.replace(`/application/clause/${id}/${next}`)
    }
  };

  const handleClose = () => {
    history.goBack();
    return true;
  };

  const handleShowCadastresMap = (block) => {
    if (clause.geometry) {
      dispatch(setActiveGeometries([clause.geometry]));
    }
    setSelectedBlock(block);
    dispatch(toggleSelectActive(true));
    setShowCadastresMap(true);
  };

  const handleMapSubmit = (cadastres) => {
    dispatch(setBlockCadastres(id, selectedBlock, cadastres));
    setSelectedBlock(null);
    setShowCadastresMap(false);
  };

  const renderStep = () => {
    if (readOnly || stepNumber === totalSteps) {
      dispatch(calculateNumbering());
      return <ClauseOverview applicationId={id} application={application} clause={clause} disabled={disabled}
        files={files} step={step} readOnly={readOnly} />;
    } else if (stepNumber === 1) {
      return <Preamble clause={clause} disabled={disabled} />;
    } else if (stepNumber === totalSteps - 2) {
      return <Extras applicationId={id} clause={clause} disabled={disabled} files={files} handleDrawSketch={handleDrawSketch} />;
    } else if (stepNumber === totalSteps - 1) {
      return <Signers application={application} clause={clause} disabled={disabled} />;
    } else {
      return <Group applicationId={id} group={clause.groups[currentGroupIndex]} disabled={disabled} clause={clause}
        handleShowMap={handleShowCadastresMap} handleNavigation={handleSimpleNavigation} />;
    }
  };

  const handleShowArea = () => {
    const geometries = readOnly ? [clause.geometry] : null;
    dispatch(setActiveGeometries(geometries));
    dispatch(toggleLayerDrawer(false));
    setShowMap(true);
  };

  const handleConfirmArea = (geometry) => {
    dispatch(setClauseValue('geometry', geometry));
    let newClause = cloneDeep(clause);
    newClause.geometry = geometry;
    dispatch(saveGroup(id, newClause));
    setShowMap(false);
  };

  const handleDrawSketch = () => {
    if (clause.geometry) {
      dispatch(setActiveGeometries([clause.geometry]));
    }
    dispatch(toggleLayerDrawer(false));
    setShowDrawSketch(true);
  };

  const handleConfirmSketch = () => {
    const elements = document.getElementsByClassName('ol-map');
    if (elements?.length) {
      const mapCanvas = PdfUtils.drawMapCanvas(elements[0].clientWidth, elements[0].clientHeight);
      mapCanvas.toBlob(blob => dispatch(addFile(id, blob, `${t('clause.sketchFilename')}_${Math.floor(Math.random() * 100)}.png`)));
      setShowDrawSketch(false);
    }
  };

  const handleOpenPreview = () => {
    window.open(getPdfPreviewDownloadUrl(id), '_blank', 'noopener,noreferrer');
  };

  let actions = (!readOnly && step > 1) && <NavButton onClick={() => handleNavigation(-1)} disabled={disabled}>{t('button.back')}</NavButton>;

  let mainAction = (readOnly || stepNumber === totalSteps) ? <>
    <Button onClick={handleOpenPreview} disabled={disabled} color="secondary">{t('button.preview')}</Button>
    <Box marginLeft={1}>
      <Button onClick={handleClose} disabled={disabled}>{t('button.close')}</Button>
    </Box>
  </> :
    <Button onClick={() => handleNavigation(1)} disabled={disabled}>{t('button.next')}</Button>;

  let dialogTitle = clause.title;
  if (clause.roadNames) {
    const shortLength = 100;
    const shortRoadNames = clause.roadNames?.length < shortLength ? clause.roadNames : clause.roadNames.substring(0, shortLength) + '...';
    dialogTitle = ApplicationUtils.replaceRoadNames(dialogTitle, shortRoadNames);
  } else {
    dialogTitle = ApplicationUtils.replaceRoadNames(dialogTitle, '');
  }

  return <>
    <DialogContainer
      title={<span>
        <Heading level='3'>{dialogTitle}</Heading>
        <MuiButton color="primary" size="small" onClick={handleShowArea}>
          <Icon icon="earth" color={Colors.sinineVaal} margin="6px" />
          <span>{t('button.showMap')}</span>
        </MuiButton>
      </span>}
      actions={actions}
      mainAction={mainAction}
      disabled={disabled}
      progress={currentStepProgress}
      onClose={handleClose}
    >
      <div className={classes.container}>
        {renderStep()}
      </div>
    </DialogContainer>
    {showCadastresMap && <MapSelectCadastres disabled={disabled} tableTitle={t('form.selectCadastres')}
      onClose={() => setShowCadastresMap(false)} onSubmit={handleMapSubmit} />}
    {showMap && <MapDialog
      geometry={clause.geometry}
      geometryTypes={[GeometryType.MULTI_POLYGON]}
      onConfirm={handleConfirmArea}
      onClose={() => setShowMap(false)}
      readOnly={readOnly}
    />}
    {showDrawSketch && <MapDialog allowMultipleFeatures
      onConfirm={handleConfirmSketch}
      onClose={() => setShowDrawSketch(false)}
    />}
  </>;
}