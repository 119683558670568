import React, { useEffect, useRef, useState } from 'react';
import PageContainer from '../parts/PageContainer';
import AddApplicationBlock from '../components/application/AddApplicationBlock';
import { Authority, UserType } from '../constants/authConstants';
import { useDispatch, useSelector } from 'react-redux';
import MyApplicationsTable from '../components/application/citizen/MyApplicationsTable';
import AuthUtils from '../utils/AuthUtils';
import ToDoBlock from '../components/homepage/ToDoBlock';
import ShortcutBlock from '../components/homepage/ShortcutBlock';
import { ClickAwayListener, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, Popper } from '@material-ui/core';
import ApplicationReviewBlock from '../components/homepage/ApplicationReviewBlock';
import { CenteredLoadingIndicator } from '../components/form/LoadingIndicator';
import WorkOrderActiveBlock from '../components/homepage/WorkOrderActiveBlock';
import InfrastructureBlock from '../components/homepage/InfrastructureBlock';
import ApplicationInOutBlock from '../components/homepage/ApplicationInOutBlock';
import ProceedingStatisticsBlock from '../components/homepage/ProceedingStatisticsBlock';
import { Button, Colors, Icon } from 'styleguide';
import { useTranslation } from 'react-i18next';
import { setHomepageBlocks } from '../stores/global';
import { cloneDeep } from 'lodash';
import RouteUtils from '../utils/RouteUtils';

function CitizenView() {
  return <PageContainer>
    <MyApplicationsTable fixedRows={2} hideTabs />
    <AddApplicationBlock />
  </PageContainer>;
}

const getBlockLocalStorageKey = (block) => `vaalBlockVisible-${block.key}`;

function GovUserView() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authUser = useSelector(state => state.auth.authUser);
  const blocks = useSelector(state => state.global.homepageBlocks);

  const [open, setOpen] = useState(false);
  let popperAnchor = useRef(null);

  useEffect(() => {
    if (!blocks) {
      const isBlockVisible = (block) => {
        return localStorage.getItem(getBlockLocalStorageKey(block)) !== 'false';
      };

      const isApplicationHandler = AuthUtils.hasAuthority(authUser, Authority.UC7_application);
      const allBlocks = [{
        key: 'toDo',
        position: 'left',
        hasPermission: isApplicationHandler,
        visible: true
      }, {
        key: 'workOrderActive',
        position: 'left',
        hasPermission: AuthUtils.hasAuthority(authUser, Authority.UC26_work_orders)
      }, {
        key: 'infrastructure',
        position: 'left',
        hasPermission: true,
      }, {
        key: 'proceedingStatistics',
        position: 'left',
        hasPermission: true
      }, {
        key: 'shortcut',
        position: 'right',
        hasPermission: true
      }, {
        key: 'applicationReview',
        position: 'right',
        hasPermission: isApplicationHandler
      }, {
        key: 'inOut',
        position: 'right',
        hasPermission: isApplicationHandler
      }];
      const allowedBlocks = allBlocks.filter(b => b.hasPermission);
      allowedBlocks.forEach(block => block.visible = isBlockVisible(block));
      dispatch(setHomepageBlocks(allowedBlocks));
    }
  }, [dispatch, authUser, blocks]);

  const toggleOpen = () => setOpen(!open);

  const handleToggleBlock = (block) => {
    const visible = !block.visible;
    localStorage.setItem(getBlockLocalStorageKey(block), visible);
    const newBlocks = cloneDeep(blocks);
    newBlocks.find(b => b.key === block.key).visible = visible;
    dispatch(setHomepageBlocks(newBlocks));
    toggleOpen();
  };

  const renderBlock = (key) => {
    switch (key) {
      case 'toDo':
        return <ToDoBlock />;
      case 'workOrderActive':
        return <WorkOrderActiveBlock />;
      case 'infrastructure':
        return <InfrastructureBlock />;
      case 'proceedingStatistics':
        return <ProceedingStatisticsBlock />;
      case 'shortcut':
        return <ShortcutBlock />;
      case 'applicationReview':
        return <ApplicationReviewBlock />;
      case 'inOut':
        return <ApplicationInOutBlock />;
      default:
        return "";
    }
  }

  if (!blocks) {
    return "";
  }

  return <PageContainer>
    <Grid container direction="row" justifyContent="space-evenly">
      <Grid item xs={12} lg="auto">
        {blocks.filter(block => block.visible && block.position === 'left').map(block => <div key={block.key}>
          {renderBlock(block.key)}
        </div>)}
      </Grid>
      <Grid item xs={12} lg="auto">
        {blocks.filter(block => block.visible && block.position === 'right').map(block => <div key={block.key}>
          {renderBlock(block.key)}
        </div>)}
      </Grid>
      <Grid item xs={12} lg={9}></Grid>
      <Grid item xs={12} lg={3}>
        <span ref={popperAnchor}>
          <Button onClick={toggleOpen} size="extra-small">
            <Icon icon="edit" color="white" /> {t('homepage.addRemoveButton')}
          </Button>
        </span>
      </Grid>
    </Grid>
    <Popper open={open} anchorEl={popperAnchor.current} placement="top" modifiers={{ offset: { enabled: true, offset: '0, 17' } }}>
      <Paper>
        <ClickAwayListener onClickAway={toggleOpen}>
          <List>
            {blocks.map(block => <ListItem key={block.key} button onClick={() => handleToggleBlock(block)} selected={block.visible}>
              <ListItemText secondary={t(`homepage.block.${block.key}`)} />
              <ListItemSecondaryAction>
                {!!block.visible && <Icon icon="tick" color={Colors.sinineVaal} />}
              </ListItemSecondaryAction>
            </ListItem>)}
          </List>
        </ClickAwayListener>
      </Paper>
    </Popper>
  </PageContainer>;
}

export default function Homepage() {
  const authUser = useSelector(state => state.auth.authUser);
  const classifiers = useSelector(state => state.classifier.classifiers);

  useEffect(() => {
    RouteUtils.setDocumentTitle('homepage');
  }, []);

  if (authUser && classifiers?.length) {
    switch (authUser?.type) {
      case UserType.citizen:
        return <CitizenView />;
      case UserType.gov:
        return <GovUserView />;
      default:
    }
  }
  return <CenteredLoadingIndicator />;
}