import { Dialog, DialogActions, DialogContent, DialogContentText, IconButton, makeStyles, Portal } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Paragraph } from 'styleguide';
import VTextField from '../form/VTextField';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 80,
    alignItems: 'baseline'
  },
  paperRow: {
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    },
    [theme.breakpoints.down('md')]: {
      margin: 16
    },
  },
  paperColumn: {
    [theme.breakpoints.down('md')]: {
      margin: 16
    },
  }
}));

export default function ConfirmButton({ message, icon, btnText, onConfirm, confirmDisabled, isColumnLayout, dialogContent, maxWidth,
  enableOnClickPropagation, onOpen, onBeforeOpen, ...rest }) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();

  const open = (event) => {
    if(onBeforeOpen && !onBeforeOpen()){
      return;
    }
    event.stopPropagation();
    setIsOpen(true);
    if (onOpen) {
      onOpen();
    }
  };

  const close = (event) => {
    event.stopPropagation();
    setIsOpen(false);
  };

  return <span>
    {icon &&
      <IconButton onClick={open} {...rest}>
        <Icon icon={icon}></Icon>
      </IconButton>
    }
    {!icon && btnText &&
      <Button onClick={open} {...rest}>{btnText}</Button>
    }

    <Portal>
      <Dialog open={isOpen} classes={{ container: classes.container, paper: isColumnLayout ? classes.paperColumn : classes.paperRow }}
        onClick={(e) => !enableOnClickPropagation && e.stopPropagation()} maxWidth={maxWidth || 'sm'}>
        <DialogContent>
          <DialogContentText>
            <Paragraph fontSize="14B">{t('form.confirm')}</Paragraph>
            <Paragraph fontSize="14">{message}</Paragraph>
          </DialogContentText>
          {!!dialogContent && dialogContent}
        </DialogContent>
        <DialogActions>
          <Button size="small" color="secondary" onClick={close} autoFocus>{t('button.no')}</Button>
          <Button size="small" color="primary" onClick={(e) => { close(e); onConfirm(); }} disabled={confirmDisabled}>{t('button.yes')}</Button>
        </DialogActions>
      </Dialog>
    </Portal>
  </span>;
}

export function ConfirmButtonWithReason({ onConfirm, reasonNotRequired, ...rest }) {
  const { t } = useTranslation();
  const [reason, setReason] = useState('');

  const label = reasonNotRequired ? 'form.reasonNotRequired' : 'form.reason';

  return <ConfirmButton {...rest}
    isColumnLayout
    dialogContent={
      <VTextField autoFocus label={t(label)} value={reason} onChange={(event) => setReason(event.target.value)}
        multiline minRows={3} fullWidth />
    }
    onConfirm={() => onConfirm(reason)}
    confirmDisabled={!reasonNotRequired && !reason}
  />;
}