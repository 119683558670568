import { Box, TableCell } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'styleguide';
import { fetchRows, resetRows, updateFilter, updatePageable } from '../../stores/activityLog';
import { InfiniteScrollTableContainer } from '../table/PageableTable';

export default function ActivityLogTable({ api, fetchLogsUrl, singleRowOpenable }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { rows, filter, pageable, isLoading } = useSelector(state => state.activityLog);

  const columns = useMemo(() => [
    { field: 'fullName', headerName: t('log.author'), },
    {
      field: 'activity', headerName: t('log.activity'),
      renderCell: (column, cell) => t(`log.activityValue.${cell.activity}`)
    },
    { field: 'created', headerName: t('log.date'), type: 'date' },
  ], [t]);

  useEffect(() => dispatch(resetRows()), [dispatch]);
  useEffect(() => !pageable.loaded && !!fetchLogsUrl && dispatch(fetchRows(api, fetchLogsUrl, pageable, filter, columns)),
    [dispatch, api, pageable, filter, columns, fetchLogsUrl]);

  const handleRenderCollapsibleRow = (row) => {
    let content;
    if (row.reason) {
      content = <Box whiteSpace="pre-line">{row.reason}</Box>;
    }
    if (row.contentJson) {
      const data = JSON.parse(row.contentJson);
      if (Array.isArray(data)) {
        const elements = [];
        data.forEach(row => {
          if (typeof row === 'object') {
            if (row.label && row.content) {
              elements.push(
                <Tooltip
                  placement="right"
                  label={row.label}
                  title={<Box whiteSpace="pre-line">{row.content}</Box>}
                />
              );
            }
          } else {
            elements.push(row);
          }
        });
        content = elements.map((row, index) => <div key={index}>{row}</div>);
      }
    }
    if (content) {
      return <TableCell colSpan="100%">{content}</TableCell>;
    }
  };

  return <InfiniteScrollTableContainer
    rows={rows}
    columns={columns}
    filter={filter}
    pageable={pageable}
    headerText={t('log.title')}
    loading={isLoading}
    onUpdatePageable={(pageNumber, sort) => dispatch(updatePageable(pageNumber, sort))}
    onUpdateFilter={(field, value) => dispatch(updateFilter(field, value))}
    onRenderCollapsibleRow={handleRenderCollapsibleRow}
    singleRowOpenable={singleRowOpenable}
  />;
}