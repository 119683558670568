import { TableCell, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  flex: {
    flexGrow: 1
  },
  header: {
    paddingLeft: 16
  },
  toolbar: {
    padding: 0,
  },
  exportChart: {
    right: 24,
    top: 24,
    float: 'right',
    zIndex: 2
  },
  iconCell: {
    verticalAlign: 'middle',
    marginRight: 8
  },
  oddCell: {
    padding: '0 16px',
    backgroundColor: 'white'
  },
  evenCell: {
    padding: '0 16px'
  }
}));

export default function ReportTableCell({getContent, data}) {
  const classes = useStyles();

  const getDetailRowClass = (i) => (i % 2 === 1) ? classes.evenCell : classes.oddCell;

  const getCellContent = (d) => {
    return (getContent && getContent(d)) || <div>&nbsp;</div>;
  }

  return <TableCell style={{ padding: 0 }}>
    {data.map((d, i) => <div key={i} className={getDetailRowClass(i)}>
      {getCellContent(d)}
    </div>)}
  </TableCell>;
}