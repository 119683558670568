import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Icon } from "styleguide";
import { showInfo } from "../../stores/notification";
import { fetchDocumentVersions, getDocumentVersionDownloadUrl } from "../../stores/planning/planningDocument";
import DateUtils from "../../utils/DateUtils";
import TableUtils from "../../utils/TableUtils";


export default function PlanningDocumentVersions({ planningId, documentId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const anchorRef = useRef(null);

  const handleFetch = async (event) => {
    setIsLoading(true);
    const response = await dispatch(fetchDocumentVersions(planningId, documentId));
    if (response?.length) {
      setRows(TableUtils.sortArrayByNumber(response, 'id', true));
    } else {
      dispatch(showInfo('planning.document.noVersions'));
    }
    setIsLoading(false);
  };

  const handleClose = () => {
    setRows([]);
  };

  const handleRowClick = (file) => {
    const url = getDocumentVersionDownloadUrl(planningId, documentId, file.id);
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer')
    }
  };

  return <>
    <IconButton onClick={handleFetch} aria-label="show versions" title={t('planning.document.showVersions')} disabled={isLoading} ref={anchorRef}>
      <Icon icon={isLoading ? 'loading' : 'inProgress'} />
    </IconButton>
    {rows?.length > 0 && <Menu
      anchorEl={anchorRef.current}
      open={true}
      onClose={handleClose}
      elevation={2}
    >
      {rows.map((file, index) =>
        <MenuItem key={file.id} onClick={() => handleRowClick(file)}>
          {`V${rows.length - index} (${DateUtils.formatDate(file.created)}): ${file.fileName}`}
        </MenuItem>
      )}
    </Menu>}
  </>;
}