export const Authority = {
  //admin
  UC5_admin_application_types: "UC5_admin_application_types",
  UC20_admin_roles: "UC20_admin_roles",
  UC21_admin_partners: "UC21_admin_partners",
  UC22_admin_users: "UC22_admin_users",
  UC24_admin_classifiers: "UC24_admin_classifiers",
  UC30_admin_basic_object_types: "UC30_admin_basic_object_types",
  UC33_admin_logs: "UC33_admin_logs",
  UC36_admin_map_layers: "UC36_admin_map_layers",

  //gov
  UC31_register_object_data: "UC31_register_object_data",
  UC7_application: "UC7_application",
  UC7_view_application: "UC7_view_application",
  UC11_planning: "UC11_planning",
  UC26_work_orders: "UC26_work_orders",
  UC28_search_documents: "UC28_search_documents",
  UC29_report: "UC29_report",
  UC37_add_application: "UC37_add_application",
  UC37_add_YP_application: "UC37_add_YP_application",
  UC38_submit_plan_to_plank: "UC38_submit_plan_to_plank",
  UC39_master_plan_export: "UC39_master_plan_export",
  UC40_object_add_drawing: "UC40_object_add_drawing",
  UC41_edit_drawing_layer: "UC41_edit_drawing_layer",

  //svms
  SVMS_UC1_view: "SVMS_UC1_view",
  SVMS_UC1_measuring_device_conf: "SVMS_UC1_measuring_device_conf",
  SVMS_UC2_indicator_type_conf: "SVMS_UC2_indicator_type_conf",
  SVMS_UC5_alert_function_conf: "SVMS_UC5_alert_function_conf",
  SVMS_UC7_alert_analysis: "SVMS_UC7_alert_analysis",
  SVMS_UC11_logs: "SVMS_UC11_logs",

  //citizen
  UC1_application_submit: "UC1_application_submit",
  UC6_my_applications: "UC6_my_applications",

  //partner
  UC19_partner_application: "UC19_partner_application",
  UC27_partner_work_order: "UC27_partner_work_order",
  UC31_view_restricted: "UC31_view_restricted",
  UC31_TV_register_object_aata: "UC31_TV_register_object_aata",
  UC39_partner_master_plan_export: "UC39_partner_master_plan_export",
};

export const AuthRole = {
  Admin: "admin"
};

export const RoleAuthorities = {
  [AuthRole.Admin]: [
    Authority.UC5_admin_application_types,
    Authority.UC20_admin_roles,
    Authority.UC21_admin_partners,
    Authority.UC22_admin_users,
    Authority.UC24_admin_classifiers,
    Authority.UC30_admin_basic_object_types,
    Authority.UC33_admin_logs,
    Authority.UC36_admin_map_layers
  ]
};

export const UserType = {
  citizen: "CITIZEN",
  gov: "GOV",
  partner: "PARTNER"
};