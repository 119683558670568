import { Box, Divider, Grid, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Colors, Heading, Icon } from 'styleguide';
import Paragraph from 'styleguide/build/components/Typography/Paragraph/Paragraph';
import { Authority } from '../../constants/authConstants';
import { ApplicantType, ApplicationLocationType, ClassifierType, PlanningDocumentType, PlanningOperationType, PlanningStatus } from '../../constants/classifierConstants';
import { addNotificationRequest } from '../../stores/admin/userNotificationRequest';
import { toggleFeaturesDialog } from '../../stores/map/feature';
import { setActiveGeometries, toggleActiveControl } from '../../stores/map/map';
import { getDocumentDownloadUrl } from '../../stores/planning/planningDocument';
import { fetchLocations } from '../../stores/planning/planningLocation';
import { addInvolved } from '../../stores/planning/planningParticipant';
import AuthUtils from '../../utils/AuthUtils';
import DateUtils from '../../utils/DateUtils';
import MapUtils from '../../utils/MapUtils';
import PlanningUtils from '../../utils/PlanningUtils';
import ClassifierLabel from '../form/ClassifierLabel';
import LinkButton from '../LinkButton';
import ActiveFeaturesLayer from '../map/layers/ActiveFeaturesLayer';
import Layers from '../map/layers/Layers';
import Map from '../map/Map';
import CroppedText from '../table/CroppedText';
import { getPlankExcelUrl } from '../../stores/planning/planning';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    minHeight: 800,
    borderRadius: 14,
    padding: 8,
  },
  mapContainer: {
    height: 800,
    borderRadius: 8,
    overflow: 'hidden'
  },
  overviewBlock: {
    margin: '20px 0px',
  },
  overviewContainer: {
    [theme.breakpoints.up('md')]: {
      padding: '20px 40px'
    },
    [theme.breakpoints.down('md')]: {
      padding: 4
    },
  },
  actionBlock: {
    backgroundColor: Colors.lainehari,
    borderRadius: 14,
    marginBottom: 40,
    [theme.breakpoints.up('md')]: {
      padding: '20px 40px'
    },
    [theme.breakpoints.down('md')]: {
      padding: 8
    },
  },
  fileRow: {
    backgroundColor: Colors.hall3,
    minHeight: 48,
    borderRadius: 4,
    padding: 15,
    marginBottom: 5
  },
  fileLink: {
    textDecoration: 'none',
    fontWeight: 'bold',
    color: Colors.sinineVaal
  },
  downloadIconContainer: {
    float: 'right'
  },
  downloadIcon: {
    verticalAlign: 'middle'
  }
}));

function DateBlock({ planning, field, document }) {
  const { t } = useTranslation();

  let value = document?.documentDate || planning[field];

  if (!value) {
    return "";
  }

  value = DateUtils.formatDate(value);
  if (document) {
    value = <DocumentDownloadLink planningId={planning.id} document={document}>{value}</DocumentDownloadLink>;
  }

  return <Grid item>
    <Paragraph fontSize="14B">{t(`planning.${field}`)}</Paragraph>
    <Paragraph fontSize="14">{value}</Paragraph>
  </Grid>;
}

function OpinionNotification({ planningId, operations, userParticipants, documents }) {
  const { t } = useTranslation();
  const classes = useStyles();

  let myOpinionOperation;
  const opinionOperations = PlanningUtils.getActiveOperations(operations, PlanningOperationType.opinion);
  if (opinionOperations.length > 0) {
    myOpinionOperation = opinionOperations.find(op => {
      const operationDocuments = documents.filter(doc => doc.operationId === op.id && userParticipants.some(p => p.id === doc.authorId));
      if (operationDocuments.length) {
        const notificationCount = operationDocuments.filter(doc => doc.documentType === PlanningDocumentType.notification).length;
        const replyCount = operationDocuments.filter(doc => doc.documentType === PlanningDocumentType.opinion).length;
        return notificationCount > replyCount;
      }
      return false;
    });
  }
  if (!myOpinionOperation) {
    return "";
  }

  return <Grid container direction="row" className={classes.actionBlock} justifyContent="space-between">
    <Grid item xs={12} lg="auto">
      <Heading level="4">{t('planning.view.opinionAskedFromUser')}</Heading>
    </Grid>
    <Grid item xs={12} lg="auto">
      <Paragraph>{t('planning.operation.deadline', { deadline: DateUtils.formatDate(myOpinionOperation.endDate) })}</Paragraph>
    </Grid>

    <Grid item xs={12}>
      <Box py={2}>
        {myOpinionOperation.description}
      </Box>
    </Grid>

    <Grid item xs={1}></Grid>
    <Grid item>
      <LinkButton to={`${planningId}/addOpinion/${myOpinionOperation.id}`} color="tertiary">{t('planning.action.addOpinion')}</LinkButton>
    </Grid>
  </Grid>;
}

function CoordinationNotification({ planningId, operations, userParticipants, documents }) {
  const { t } = useTranslation();
  const classes = useStyles();

  let myOperation;
  const opinionOperations = PlanningUtils.getActiveOperations(operations, PlanningOperationType.coordination);
  if (opinionOperations.length > 0) {
    myOperation = opinionOperations.find(op => {
      const operationDocuments = documents.filter(doc => doc.operationId === op.id && userParticipants.some(p => p.id === doc.authorId));
      if (operationDocuments.length) {
        const notificationCount = operationDocuments.filter(doc => doc.documentType === PlanningDocumentType.notification).length;
        const replyCount = operationDocuments.filter(doc => doc.documentType === PlanningDocumentType.coordination).length;
        return notificationCount > replyCount;
      }
      return false;
    });
  }
  if (!myOperation) {
    return "";
  }

  return <Grid container direction="row" className={classes.actionBlock} justifyContent="space-between">
    <Grid item xs={12} lg="auto">
      <Heading level="4">{t('planning.view.coordinationAskedFromUser')}</Heading>
    </Grid>
    <Grid item xs={12} lg="auto">
      <Paragraph>{t('planning.operation.deadline', { deadline: DateUtils.formatDate(myOperation.endDate) })}</Paragraph>
    </Grid>

    <Grid item xs={12}>
      <Box py={2}>
        {myOperation.description}
      </Box>
    </Grid>

    <Grid item xs={1}></Grid>
    <Grid item>
      <LinkButton to={`${planningId}/coordinate/${myOperation.id}`} color="tertiary">{t('planning.action.coordinate')}</LinkButton>
      <Box px={1} display="inline" />
      <LinkButton to={`${planningId}/dontCoordinate/${myOperation.id}`} color="tertiary">{t('planning.action.dontCoordinate')}</LinkButton>
    </Grid>
  </Grid>;
}

export function DocumentDownloadLink({ planningId, document, children }) {
  const { t } = useTranslation();
  const classes = useStyles();

  if (!!document?.url || !!document?.fileName) {
    return <a href={document.url || getDocumentDownloadUrl(planningId, document.id)}
      target="_blank" rel="noreferrer"
      className={classes.fileLink}
      title={t('fileUpload.download')}>
      {children}
    </a>;
  }
  return children;
}

function FrontpageDocumentsBlock({ planningId, documents }) {
  const classes = useStyles();

  if (!documents?.length) {
    return "";
  }

  return <Grid item>
    {documents.map((document, index) => (
      <Grid container direction="row" key={index} justifyContent="space-between" alignItems="center" className={classes.fileRow}>
        <Grid item xs={12} lg={7}>
          <DocumentDownloadLink planningId={planningId} document={document}>
            <CroppedText value={document.description || document.number || document.fileName} length={50} />
          </DocumentDownloadLink>
        </Grid>
        <Grid item xs={12} lg={4}>
          <ClassifierLabel classifierType={ClassifierType.planningDocumentType} code={document.documentType} />
        </Grid>
        <Box component={Grid} item xs={1} display={{ xs: "none", md: "block" }}>
          {(!!document?.url || !!document?.fileName) &&
            <DocumentDownloadLink planningId={planningId} document={document}>
              <div className={classes.downloadIconContainer}>
                <Icon icon="downloadFile" className={classes.downloadIcon} />
              </div>
            </DocumentDownloadLink>
          }
        </Box>
      </Grid>
    ))}
  </Grid>
}

export default function PlanningDetails({ readOnly, title }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const planning = useSelector(state => state.planning.selected);
  const planningId = planning.id;
  const locations = useSelector(state => state.planningLocation.rows);
  const shouldFetchLocations = !locations;

  const participants = useSelector(state => state.planningParticipant.rows);
  const operations = useSelector(state => state.planningOperation.rows);
  const documents = useSelector(state => state.planningDocument.rows);
  const authUser = useSelector(state => state.auth.authUser);
  const { layers, center, zoom, extent } = useSelector(state => state.map);

  const [showNotificationButton, setShowNotificationButton] = useState(false);

  useEffect(() => shouldFetchLocations && dispatch(fetchLocations(planningId)), [shouldFetchLocations, planningId, dispatch]);
  // //set map extent after load
  useEffect(() => {
    if (locations?.length) {
      const planGeometries = locations.filter(r => ApplicationLocationType.plan === r.type).map(r => r.geometry);
      dispatch(setActiveGeometries(planGeometries, false, true, MapUtils.secondaryStyle));
      const applicationGeometries = locations.filter(r => ApplicationLocationType.application === r.type).map(r => r.geometry);
      dispatch(setActiveGeometries(applicationGeometries, true, false));
    }
  }, [dispatch, locations]);

  useEffect(() => {
    const allowedStatuses = [PlanningStatus.initiated, PlanningStatus.accepted];
    const canRequestNotifications = !!authUser && !!participants &&
      AuthUtils.hasAuthority(authUser, Authority.UC1_application_submit) &&
      !planning.isParticipant &&
      allowedStatuses.includes(planning.status) &&
      !participants?.some(p => p.fullName === authUser.fullName && ApplicantType.involved === p.participantType);
    setShowNotificationButton(canRequestNotifications);
  }, [authUser, planning, participants]);

  const handleShowMap = () => {
    const planGeometries = locations.filter(r => ApplicationLocationType.plan === r.type).map(r => r.geometry);
    dispatch(setActiveGeometries(planGeometries, false, true, MapUtils.secondaryStyle));
    const applicationGeometries = locations.filter(r => ApplicationLocationType.application === r.type).map(r => r.geometry);
    dispatch(setActiveGeometries(applicationGeometries, true, false));
    dispatch(toggleFeaturesDialog(false));
    dispatch(toggleActiveControl(null, false));
    history.push(`/map`);
  };

  const applicant = participants?.find(p => ApplicantType.applicant === p.participantType);
  const planners = participants?.filter(p => ApplicantType.partyPlanner === p.participantType);

  let showParticipantOpinionBlock = false;
  let showParticipantCoordinationBlock = false;
  const actions = [];
  if (!readOnly) {
    actions.push(<LinkButton key="edit" to={`/plan/view/${planning.id}/edit`} size="extra-small">{t('planning.action.edit')}</LinkButton>);
  } else {
    if (operations?.length) {
      if (AuthUtils.isCitizen(authUser)) {
        showParticipantOpinionBlock = !!planning.isParticipant && participants?.length > 0 && documents?.length > 0;
        let proposalOperations = PlanningUtils.getActiveOperations(operations, PlanningOperationType.draft);
        if (!proposalOperations.length) {
          proposalOperations = PlanningUtils.getActiveOperations(operations, PlanningOperationType.display);
        }
        if (!proposalOperations.length) {
          proposalOperations = PlanningUtils.getActiveOperations(operations, PlanningOperationType.proposal);
        }
        if (proposalOperations.length > 0) {
          actions.push(<LinkButton key="proposal" to={`/plan/view/${planning.id}/addProposal`} size="extra-small">{t('planning.action.submitProposal')}</LinkButton>);
        }
      } else if (AuthUtils.isPartner(authUser)) {
        showParticipantCoordinationBlock = !!planning.isParticipant && participants?.length > 0 && documents?.length > 0;
      }
    }
  }

  const handleRequestNotifications = async () => {
    const addedInvolved = await dispatch(addInvolved(planning.id));
    if (!addedInvolved) {
      return;
    }
    dispatch(addNotificationRequest({
      relatedTable: 'planning',
      relatedId: planning.id,
      title
    }));
    setShowNotificationButton(false);
  };

  const handleGenerateExcel = () => {
    window.open(getPlankExcelUrl(planningId), '_blank', 'noopener,noreferrer');
  };

  const getDocumentByType = (type) => {
    return documents?.find(d => d.documentType === type);
  };

  return <div>
    {showParticipantOpinionBlock && <OpinionNotification
      planningId={planning.id} operations={operations} documents={documents}
      userParticipants={participants.filter(p => p.code === authUser.code)}
    />}
    {showParticipantCoordinationBlock && <CoordinationNotification
      planningId={planning.id} operations={operations} documents={documents}
      userParticipants={participants.filter(p => p.code === authUser.companyCode)}
    />}
    {!showParticipantOpinionBlock && (actions.length > 0) && <Box textAlign="right" marginTop={-2} marginBottom={3}>{actions}</Box>}
    <Paper className={classes.container}>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item xs={12} lg={6} container direction="column" className={classes.overviewContainer} spacing={3}>
          <Grid item>
            <Paragraph fontSize="16">
              <Link to={`/application/view/${planning.applicationId}`}>
                {t(`planning.view.applicationNumber`, { applicationNumber: planning.applicationNumber })}
              </Link>
            </Paragraph>
          </Grid>

          {!!applicant &&
            <Grid item>
              <Paragraph fontSize="14B">{t('planning.view.applicant')}</Paragraph>
              {applicant.fullName}
            </Grid>
          }

          <Grid item><Divider /></Grid>

          {!!planning.name && <Grid item><Heading level="4">{planning.name}</Heading></Grid>}

          {!!planning.purpose && <Grid item>
            <Box whiteSpace="pre-line">{planning.purpose}</Box>
          </Grid>}
          
          {!!planning.description && <Grid item>
            <Box whiteSpace="pre-line">{planning.description}</Box>
          </Grid>}

          <DateBlock planning={planning} field="initiatedDate" document={getDocumentByType(PlanningDocumentType.initiate)} />
          <DateBlock planning={planning} field="acceptedDate" document={getDocumentByType(PlanningDocumentType.accept)} />
          <DateBlock planning={planning} field="establishedDate" document={getDocumentByType(PlanningDocumentType.establish)} />
          <DateBlock planning={planning} field="partiallyInvalidatedDate" document={getDocumentByType(PlanningDocumentType.partiallyInvalidate)} />
          <DateBlock planning={planning} field="archivedDate" document={getDocumentByType(PlanningDocumentType.invalidate)} />

          {planners?.map((planner) => <Grid item key={planner.id}>
            <Paragraph fontSize="14B">{t('planning.view.planner')}</Paragraph>
            {planner.fullName}
          </Grid>)}

          <Grid item>
            <Paragraph fontSize="14B">{!!planning.isYpAppropriate ? t('planning.isYpAppropriate') : t('planning.isNotYpAppropriate')}</Paragraph>
          </Grid>

          <Grid item>
            <Paragraph fontSize="14B">{!!planning.isKshNecessity ? t('planning.isKshNecessity') : t('planning.isNotKshNecessity')}</Paragraph>
            <Box whiteSpace="pre-line">{planning.kshDescription}</Box>
          </Grid>

          {!!planning.plankSysId &&
            <Grid item>
              <Paragraph fontSize="14B">{t('planning.plankSysId')}</Paragraph>
              {planning.plankSysId}
            </Grid>
          }
          
          {!!planning.planId &&
            <Grid item>
              <Paragraph fontSize="14B">{t('planning.planId')}</Paragraph>
              {planning.planId}
            </Grid>
          }
          <FrontpageDocumentsBlock planningId={planning.id} documents={documents?.filter(d => d.isFrontpage)} />
        </Grid>
        <Grid item xs={12} lg={6} className={classes.mapContainer} onClick={handleShowMap}>
          <Map center={center} zoom={zoom} extent={extent} disablePan>
            <Layers>
              {MapUtils.getDefaultBaseLayer(layers)}
              {MapUtils.getCadastreLayer(layers)}
              <ActiveFeaturesLayer />
            </Layers>
          </Map>
        </Grid>
      </Grid>
    </Paper>
    <Box padding={3}>
      {showNotificationButton && 
        <Button color="tertiary" onClick={handleRequestNotifications} size="small">
          <Icon icon="bell" color={Colors.sinineVaal} />{t('planning.view.addInvolved')}
        </Button>
      }
      <Button color="tertiary" onClick={handleGenerateExcel} size="small">
          <Icon icon="downloadFile" color={Colors.sinineVaal} />{t('planning.view.generatePlankExcel')}
        </Button>
    </Box>
  </div>;
}