import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { VAutocomplete } from 'styleguide';
import VTextField from './VTextField';

export default function ControlledAutocomplete({ name, control, required, label, disabled, errors, disableCloseOnSelect,
  handleChange, options, getOptionLabel, getOptionSelected, defaultValue, multiple, renderTags, disableClearable, ...rest }) {
  const { t } = useTranslation();

  let rules = {};
  if (required) {
    rules.required = { value: true, message: t('validation.required') };
  }

  return <Controller
    render={({ value, onBlur, onChange }) => (
      <VAutocomplete
        value={value}
        onChange={(event, value) => {
          if (handleChange) {
            return onChange(handleChange(value));
          }
          return onChange(value);
        }}
        onBlur={onBlur}
        options={options}
        getOptionLabel={getOptionLabel}
        disableClearable={disableClearable}
        disableCloseOnSelect={disableCloseOnSelect}
        getOptionSelected={getOptionSelected}
        disabled={disabled}
        multiple={multiple}
        renderTags={renderTags}
        renderInput={(params) =>
          <VTextField {...params} name={name} label={label} fullWidth errors={errors} {...rest} />
        }
      />
    )}
    name={name}
    defaultValue={defaultValue || null}
    control={control}
    rules={rules}
  />;
}