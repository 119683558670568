import { ClassifierType, Domain } from "./classifierConstants";

export const ProcedureObjectDefs = {
  planningInitiated: {
    domain: Domain.plan,
    columns: [
      { field: "number" },
      { field: "plankSysId" },
      { field: "name" },
      { field: "status", type: "classifier", classifierType: ClassifierType.planningStatus },
      { field: "establishedDate", type: "date", detailsOnly: true },
      { field: "acceptedDate", type: "date", detailsOnly: true },
      { field: "initiatedDate", type: "date" },
      { field: "organizer", detailsOnly: true },
      { field: "planId", detailsOnly: true },
      { field: "id", detailsOnly: true },
      { field: "isKshNecessity", type: "boolean", detailsOnly: true },
      { field: "isYpAppropriate", type: "boolean", detailsOnly: true },
      { field: "purpose", detailsOnly: true },
      { field: "organizerLink", type: "link", detailsOnly: true },
      { field: "plankLink", type: "link", detailsOnly: true }
    ]
  },
  planningAccepted: {
    domain: Domain.plan,
    columns: [
      { field: "number" },
      { field: "plankSysId" },
      { field: "name" },
      { field: "status", type: "classifier", classifierType: ClassifierType.planningStatus },
      { field: "establishedDate", type: "date", detailsOnly: true },
      { field: "acceptedDate", type: "date" },
      { field: "initiatedDate", type: "date" },
      { field: "organizer", detailsOnly: true },
      { field: "planId", detailsOnly: true },
      { field: "id", detailsOnly: true },
      { field: "isKshNecessity", type: "boolean", detailsOnly: true },
      { field: "isYpAppropriate", type: "boolean", detailsOnly: true },
      { field: "purpose", detailsOnly: true },
      { field: "organizerLink", type: 'link', detailsOnly: true },
      { field: "plankLink", type: "link", detailsOnly: true }
    ]
  },
  clause: {
    columns: [
      { field: "clauseNumber", readonly: true },
      { field: "title", readonly: true, type: "title" },
      { field: "proceedingsDate", readonly: true, type: "date" },
      { field: "purpose", readonly: true, detailsOnly: true },
      { field: "applicationLink", readonly: true, detailsOnly: true, type: 'link' },
      { field: "handlerName", readonly: true, detailsOnly: true },
      { field: "organizer", readonly: true, detailsOnly: true },
      { field: "organizerLink", readonly: true, detailsOnly: true, type: 'link' }
    ]
  }
};

