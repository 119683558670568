import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/application';
import StoreUtils from '../../utils/StoreUtils';
import { showSuccess } from '../notification';
import TableUtils from '../../utils/TableUtils';

const apiPath = (applicationId) => `application/${applicationId}/clause/file`;

// Slice
const slice = createSlice({
  name: 'clauseFile',
  initialState: {
    rows: null,
    isLoading: false,
    error: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    rowsSuccess: (state, action) => {
      state.rows = !!action.payload ? TableUtils.sortArrayByNumber(action.payload, 'id') : null;
      state.isLoading = false;
    },
    insertedSuccess: (state, action) => {
      state.rows.push(action.payload);
      state.isLoading = false;
    },
    saveSuccess: (state, action) => {
      state.rows.find(f => f.id === action.payload.id).description = action.payload.description;
    },
    deletedSuccess: (state, action) => {
      state.rows = state.rows.filter(r => r.id !== action.payload);
      state.isLoading = false;
    },
    resetSuccess: state => {
      state.rows = null;
    }
  },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, rowsSuccess, insertedSuccess, deletedSuccess, resetSuccess, saveSuccess } = slice.actions;

export const setFiles = (files) => async dispatch => {
  dispatch(rowsSuccess(files));
};

export const fetchFiles = (applicationId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(apiPath(applicationId)).then((response) => {
      dispatch(rowsSuccess(response.data.fileInfoList));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const addFile = (applicationId, file, filename) => async dispatch => {
  dispatch(startLoading());
  try {
    let formData = new FormData();
    formData.append('file', file, filename || file.name);
    await api.post(apiPath(applicationId), formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then((response) => {
      dispatch(insertedSuccess(response.data));
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const saveFileDescription = (applicationId, fileId, description) => async dispatch => {
  try {
    await api.patch(`${apiPath(applicationId)}/${fileId}`, { description }).then((response) => {
      dispatch(saveSuccess(response.data));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const deleteFile = (applicationId, fileId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.delete(`${apiPath(applicationId)}/${fileId}`).then((response) => {
      dispatch(deletedSuccess(fileId));
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const getFileDownloadUrl = (applicationId, fileId) => `${api.defaults.baseURL}/${apiPath(applicationId)}/${fileId}`;

export const resetFile = () => async dispatch => {
  dispatch(resetSuccess());
};