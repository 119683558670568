import { FormControlLabel } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, VAutocomplete } from 'styleguide';
import ControlledAutocomplete from './ControlledAutocomplete';

export default function ClassifierAutocomplete({ name, control, required, label, disabled, errors, classifierType,
  codeValue, defaultValue, handleChange, ...rest }) {
  const classifiers = useSelector(state => state.classifier.classifiers);

  if (!classifierType) {
    return "";
  }

  const options = classifiers.filter(c => c.valid && c.typeCode === classifierType);

  if (codeValue) {
    defaultValue = options.find(c => c.itemCode === codeValue) || null;
  }

  if (!control) {
    return <VAutocomplete
      label={label}
      value={defaultValue || ''}
      onChange={(event, value) => handleChange(value)}
      options={options}
      disabled={disabled}
      getOptionLabel={(o) => o.title || ''}
      renderOption={(option, { selected }) => (
        <FormControlLabel
          control={<Checkbox checked={selected} />}
          label={option.title}
        />
      )}
      {...rest}
    />;
  }

  return <ControlledAutocomplete
    options={options}
    getOptionLabel={(o) => o.title}
    getOptionSelected={(option, value) => {
      return option.id === value.id;
    }}
    disabled={disabled}
    label={label}
    errors={errors}
    name={name}
    control={control}
    required={required}
    defaultValue={defaultValue}
    {...rest}
  />;
}