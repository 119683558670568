import { Box, Divider, Grid, List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Icon } from 'styleguide';
import { Domain } from '../../constants/classifierConstants';
import ReportTabs from './ReportTabs';
import { useHistory } from 'react-router-dom';
import { getReportCSVUrl, resetRows } from '../../stores/register/registerReport';
import { useDispatch, useSelector } from 'react-redux';
import { getRoadShapeUrl } from '../../stores/map/map';
import AuthUtils from '../../utils/AuthUtils';
import { Authority } from '../../constants/authConstants';
import RegisterUtils from '../../utils/RegisterUtils';

const reportsByDomain = (domain) => {
  switch (domain) {
    case Domain.rainwater:
      return ['settlement'];
    case Domain.streetlight:
      return ['settlement'];
    case Domain.road:
      return ['settlement', 'localList', 'illumination', 'busStops', 'roadDetails', 'traffic'];
    case "other":
      return ['application'];
    default:
      return [];
  }
};

const queriesByDomain = (domain) => {
  switch (domain) {
    case Domain.rainwater:
      return ['network', 'ditchNetwork'];
    case Domain.streetlight:
      return ['lampType', 'cableType', 'shield'];
    case Domain.road:
      return ['surfaces', 'roadType', 'breakdown', 'roadAge', 'busStopType'];
    default:
      return [];
  }
};

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1200,
    margin: 'auto'
  },
  heading: {
    paddingBottom: 16
  }
}));

function ReportListItem({ domain, name, last, onClick }) {
  const { t } = useTranslation();

  const title = RegisterUtils.isBasicObjectDomain(domain) ? RegisterUtils.objectDefs[name]?.title : t(`report.${domain}.${name}`);

  return <React.Fragment>
    <ListItem button disableGutters onClick={() => onClick(name)}>
      <ListItemText secondary={title} secondaryTypographyProps={{ variant: 'h4' }} />
      <Icon icon="arrowRight" />
    </ListItem>
    {!last && <Divider />}
  </React.Fragment>;
}

const handleDownloadCsv = (domain, name) => {
  window.open(getReportCSVUrl(domain, name), '_blank', 'noopener,noreferrer');
};

const handleDownloadRoadShape = () => {
  window.open(getRoadShapeUrl(), '_blank', 'noopener,noreferrer');
};

export default function ReportList({ domain }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const authUser = useSelector(state => state.auth.authUser);
  const { rows: basicObjectTypes } = useSelector(state => state.basicObjectType);


  const reports = reportsByDomain(domain);
  const queries = queriesByDomain(domain);

  if(RegisterUtils.isBasicObjectDomain(domain)) {
    basicObjectTypes
      .filter(bot => bot.domain === domain)
      .sort((a, b) => (a.title || '').localeCompare(b.title))
      .forEach(bot => queries.push(bot.id));
  }

  if (AuthUtils.hasAuthority(authUser, Authority.UC31_register_object_data)) {
    if (Domain.road === domain) {
      reports.push('roadShape');
    }
    if ("other" === domain) {
      reports.push('workOrder');
    }
  }
  const handleClick = (name) => {
    let path;
    if(RegisterUtils.isBasicObjectDomain(domain)) {
      path = `${domain}/${name}`;
    } else {
      switch (name) {
        case 'settlement':
          path = `settlement/${domain}`;
          break;
        case 'localList':
        case 'busStops':
          return handleDownloadCsv(domain, name);
        case 'roadShape':
          return handleDownloadRoadShape();
        default:
          path = name;
      }
    }
    if (path) {
      dispatch(resetRows(name));
      history.push(`/report/${path}`)
    }
  };

  

  return <div className={classes.container}>
    <Box paddingBottom={5}>
      <ReportTabs />
    </Box>

    <Grid container direction="row" justifyContent="space-between">
      {!!reports.length &&
        <Grid item xs={12} lg={5}>
          <Heading level="3" className={classes.heading}>{t('report.title')}</Heading>

          <List>
            {reports.map((name, index) => (
              <ReportListItem key={index} domain={domain} name={name} last={reports.length === index + 1}
                onClick={handleClick} />
            ))}
          </List>
        </Grid>
      }
      {!!queries?.length &&
        <Grid item xs={12} lg={5}>
          <Heading level="3" className={classes.heading}>{t('report.queries')}</Heading>

          <List>
            {queries.map((name, index) => (
              <ReportListItem key={index} domain={domain} name={name} last={queries.length === index + 1}
                onClick={handleClick} />
            ))}
          </List>
        </Grid>
      }
    </Grid>
  </div>;
}