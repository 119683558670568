import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router";
import { showWarning } from "../../stores/notification";

export default function LogoutErrorHandler() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { msg } = useParams();

  dispatch(showWarning(t(`loginError.${msg || 'authentication_failed'}`)));
  return <Redirect to="" />;
}