import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/application';
import StoreUtils from '../../utils/StoreUtils';
import TableUtils from '../../utils/TableUtils';
import { showSuccess } from '../notification';

const apiPath = "applicationType";
const initialPageable = {
  pageSize: TableUtils.getPageSize(178, true, true),
  pageNumber: 0,
  sort: {
    field: "applicationType",
    ascending: true
  },
  loaded: false,
  last: true
};

// Slice
const slice = createSlice({
  name: 'applicationType',
  initialState: {
    rows: [],
    filteredRows: [],
    selected: {},
    pageable: initialPageable,
    filter: {},
    isLoading: false,
    error: false,
    formSaved: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    resetRows: state => {
      state.rows = [];
      state.filteredRows = [];
      state.pageable.loaded = false;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    rowsSuccess: (state, action) => {
      state.rows = action.payload;
      state.pageable.loaded = true;
      TableUtils.setFilteredRows(state);
      state.isLoading = false;
      state.formSaved = false;
    },
    pageableSuccess: (state, action) => {
      if (action.payload.pageNumber) {
        state.pageable.pageNumber += action.payload.pageNumber;
      }
      if (action.payload.sort) {
        state.pageable.sort = action.payload.sort;
        state.pageable.pageNumber = 0;
        state.rows.sort((a, b) => a[state.pageable.sort.field]?.localeCompare(b[state.pageable.sort.field]) * (state.pageable.sort.ascending ? 1 : -1));
      }
      TableUtils.setFilteredRows(state);
      state.pageable.loaded = true;
    },
    filterSuccess: (state, action) => {
      if ((state.filter[action.payload.field] || '') !== action.payload.value) {
        state.filter[action.payload.field] = action.payload.value;
        state.pageable.pageNumber = 0;
        TableUtils.setFilteredRows(state);
        state.pageable.loaded = true;
      }
    },
    selectedSuccess: (state, action) => {
      state.selected = action.payload;
      state.isLoading = false;
    },
    formSavedSuccess: (state, action) => {
      state.formSaved = action.payload;
    },
    addDocumentTypeSuccess: (state, action) => {
      state.selected.documentTypes.push(action.payload);
      state.isLoading = false;
    },
    removeDocumentTypeSuccess: (state, action) => {
      state.selected.documentTypes.splice(state.selected.documentTypes.findIndex(dt => dt.id === action.payload), 1);
      state.isLoading = false;
    },
    addGroupSuccess: (state, action) => {
      state.selected.groups.push(action.payload);
      state.isLoading = false;
    },
    saveGroupSuccess: (state, action) => {
      const index = state.selected.groups.findIndex(g => g.id === action.payload.id);
      state.selected.groups[index] = action.payload;
      state.isLoading = false;
    },
    removeGroupSuccess: (state, action) => {
      const index = state.selected.groups.findIndex(g => g.id === action.payload.id);
      state.selected.groups.splice(index, 1);
      state.isLoading = false;
    },
    addPurposeSuccess: (state, action) => {
      const groupIndex = state.selected.groups.findIndex(g => g.id === action.payload.group.id);
      state.selected.groups[groupIndex].purposes.push(action.payload.purpose);
      state.isLoading = false;
    },
    savePurposeSuccess: (state, action) => {
      const groupIndex = state.selected.groups.findIndex(g => g.id === action.payload.group.id);
      const index = state.selected.groups[groupIndex].purposes.findIndex(p => p.id === action.payload.purpose.id);
      state.selected.groups[groupIndex].purposes[index] = action.payload.purpose;
      state.isLoading = false;
    },
    removePurposeSuccess: (state, action) => {
      const groupIndex = state.selected.groups.findIndex(g => g.id === action.payload.group.id);
      const index = state.selected.groups[groupIndex].purposes.findIndex(p => p.id === action.payload.purpose.id);
      state.selected.groups[groupIndex].purposes.splice(index, 1);
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

// Actions

const {
  startLoading, hasError, rowsSuccess, pageableSuccess, filterSuccess, selectedSuccess, formSavedSuccess,
  addDocumentTypeSuccess, removeDocumentTypeSuccess, resetRows,
  addGroupSuccess, saveGroupSuccess, removeGroupSuccess,
  addPurposeSuccess, savePurposeSuccess, removePurposeSuccess
} = slice.actions;

export const updatePageable = (pageNumber, sort) => async dispatch => {
  dispatch(pageableSuccess({ pageNumber: pageNumber, sort: sort }));
}

export const updateFilter = (field, value) => async dispatch => {
  dispatch(filterSuccess({ field, value }));
}

export const setDetails = (selected) => async dispatch => {
  dispatch(selectedSuccess(selected));
}

export const fetchRows = () => async dispatch => {
  dispatch(startLoading());
  dispatch(resetRows());
  try {
    await api.get(`${apiPath}`).then((response) => dispatch(rowsSuccess(response.data)));
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
}

export const add = (object) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.post(apiPath, object).then((response) => {
      dispatch(selectedSuccess(response.data));
      dispatch(formSavedSuccess(true));
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
}

export const save = (object) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath}/${object.id}`, object).then((response) => {
      dispatch(selectedSuccess(response.data));
      dispatch(formSavedSuccess(true));
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
}

export const addDocumentType = (appTypeId, documentType) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.post(`${apiPath}/${appTypeId}/docType`, {
      documentType: documentType
    }).then((response) => {
      dispatch(addDocumentTypeSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
}

export const removeDocumentType = (documentTypeId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.delete(`${apiPath}/docType/${documentTypeId}`).then((response) => {
      dispatch(removeDocumentTypeSuccess(documentTypeId));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
}

export const addGroup = (appType, group) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.post(`${apiPath}/${appType.id}/group`, group).then((response) => {
      dispatch(addGroupSuccess(response.data));
      dispatch(resetRows());
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
}

export const saveGroup = (group) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath}/group/${group.id}`, group).then((response) => {
      dispatch(saveGroupSuccess(response.data));
      dispatch(resetRows());
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
}

export const removeGroup = (group) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.delete(`${apiPath}/group/${group.id}`).then((response) => {
      dispatch(removeGroupSuccess(response.data));
      dispatch(resetRows());
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
}

export const addPurpose = (group, purpose) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.post(`${apiPath}/group/${group.id}/purpose`, purpose).then((response) => {
      dispatch(addPurposeSuccess({ group: group, purpose: response.data }));
      dispatch(resetRows());
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
}

export const savePurpose = (group, purpose) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath}/purpose/${purpose.id}`, purpose).then((response) => {
      dispatch(savePurposeSuccess({ group: group, purpose: response.data }));
      dispatch(resetRows());
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
}

export const removePurpose = (group, purpose) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.delete(`${apiPath}/purpose/${purpose.id}`).then((response) => {
      dispatch(removePurposeSuccess({ group: group, purpose: response.data }));
      dispatch(resetRows());
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
}