import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, TableHeader, Tooltip } from 'styleguide';
import { getDocumentDownloadUrl, saveDocument } from '../../stores/planning/planningDocument';
import { ClassifierType } from '../../constants/classifierConstants';
import SimplePageableTable from '../table/SimplePageableTable';
import { CenteredLoadingIndicator } from '../form/LoadingIndicator';
import CroppedText from '../table/CroppedText';
import { TotalElementsLabel } from '../table/TableHeaderWithCount';
import TableUtils from '../../utils/TableUtils';
import PlanningUtils from '../../utils/PlanningUtils';
import { Box, IconButton } from '@material-ui/core';
import PlanningDocumentVersions from './PlanningDocumentVersions';
import { cloneDeep } from 'lodash';

export default function PlanningDocuments({ readOnly, showRestricted }) {
  const { t } = useTranslation();
  const dispatch = useDispatch(); 

  const planningId = useSelector(state => state.planning.selected.id);
  const documents = useSelector(state => state.planningDocument.rows);
  const operations = useSelector(state => state.planningOperation.rows);
  const classifiers = useSelector(state => state.classifier.classifiers);

  const columns = useMemo(() => {
    const handleOpenDocument = (row) => {
      const url = !!row.fileName ? getDocumentDownloadUrl(planningId, row.id) : row.url;
      if (url) {
        window.open(url, '_blank', 'noopener,noreferrer')
      }
    };

    const handleToggleIsPlan = (row) => {
      let data = cloneDeep(row);
      data.isPlan = !data.isPlan;
      
      dispatch(saveDocument(planningId, data.id, data));
    };

    let c = [
      { field: 'description', headerName: t('planning.document.description'), filter: true },
      { field: 'number', headerName: t('planning.document.number'), filter: true },
      { field: 'documentDate', headerName: t('planning.document.documentDate'), type: 'date' },
      {
        field: 'documentType', headerName: t('planning.document.documentType'), type: 'classifier', classifierType: ClassifierType.planningDocumentType,
        filter: true, renderCell: (column, row) => {
          const classifier = TableUtils.getClassifier(classifiers, ClassifierType.planningDocumentType, row.documentType);
          let documentType = PlanningUtils.formatPlanningDocumentType(classifier) || row.documentType;
          if (!classifier) {
            documentType = TableUtils.getClassifierTitle(classifiers, ClassifierType.applicationDocumentType, row.documentType) || row.documentType;
          }
          if (row.operationId) {
            const operation = operations?.find(o => o.id === row.operationId);
            if (operation) {
              const operationType = TableUtils.getClassifierTitle(classifiers, ClassifierType.planningOperationType, operation.operationType);
              const period = PlanningUtils.getOperationPeriodLabel(operation);
              return <Tooltip title={<Box whiteSpace="pre-line">{`${operationType}\n${period}`}</Box>} label={documentType} />;
            }
          }
          return documentType;
        }
      },
      {
        field: 'content', headerName: t('planning.document.content'), filter: true, renderCell: (column, row) =>
          <CroppedText value={row.content} length={30} />
      },
      {
        field: 'authorName', headerName: t('planning.document.author'), filter: true, renderCell: (column, row) => {
          if (row.authorId && row.createdByName) {
            return <Tooltip title={t('planning.document.createdBy', { name: row.createdByName })} label={row.authorName} />
          }
          return row.authorName;
        }
      },
    ];
    if (showRestricted) {
      c.push({ field: 'isPublic', headerName: t('planning.document.isPublic'), type: 'boolean', filter: true });
      c.push({ 
        field: 'isPlan', headerName: t('planning.document.isPlan'), type: 'boolean', filter: true, renderCell: (column, row) => {
          const value = TableUtils.getBooleanValue(row.isPlan);
          if(!readOnly){
            const translationKey = row.isPlan ? 'removeIsPlan' : 'addIsPlan';
            return <>
              {value}
              <IconButton onClick={() => handleToggleIsPlan(row)} title={t(`planning.document.${translationKey}`)}>
                <Icon icon="pencil" />
              </IconButton>
            </>;
          }
          return value;
        }}
      );
    }
    c.push({
      field: 'action', width: 75, renderCell: (column, row) => (!!row.url || !!row.fileName) &&
        <Box textAlign="right" whiteSpace="nowrap">
          {!!row.fileName && <PlanningDocumentVersions planningId={planningId} documentId={row.id} />}
          <IconButton onClick={() => handleOpenDocument(row)} title={t('fileUpload.download')}>
            <Icon icon="downloadFile" />
          </IconButton>
        </Box>
    });
    return c;
  }, [t, showRestricted, operations, classifiers, planningId, readOnly, dispatch]);

  if (!documents) {
    return <CenteredLoadingIndicator />;
  }

  return <SimplePageableTable
    rows={documents}
    columns={columns}
    header={<TableHeader>
      {t('plan.view.documents')}
      <TotalElementsLabel>{documents?.length}</TotalElementsLabel>
    </TableHeader>}
    sort={{ field: 'documentDate', ascending: false }}
    pageSize={25}
  />;
}