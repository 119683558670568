import { Box, Divider, Grid, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Colors, Heading, Icon } from 'styleguide';
import { ApplicationFileType, ApplicationLocationType, ApplicationStatus, ApplicationType, ClassifierType, PlanningDocumentType } from '../../constants/classifierConstants';
import { addNotificationRequest } from '../../stores/admin/userNotificationRequest';
import { setActiveGeometries, toggleActiveControl } from '../../stores/map/map';
import AuthUtils from '../../utils/AuthUtils';
import MapUtils from '../../utils/MapUtils';
import ApplicationUtils from '../../utils/ApplicationUtils';
import ActiveFeaturesLayer from '../map/layers/ActiveFeaturesLayer';
import Layers from '../map/layers/Layers';
import Map from '../map/Map';
import { ApplicantsOverview, FileDownloadLink, FilesOverview, LocationsOverview, PurposesOverview } from './ApplicationOverview';
import { toggleFeaturesDialog } from '../../stores/map/feature';
import { useHistory } from 'react-router';
import TableUtils from '../../utils/TableUtils';
import DateUtils from '../../utils/DateUtils';
import { getPdfSignedDownloadUrl } from '../../stores/application/clause';
import { Link } from 'react-router-dom';
import { addInvolved } from '../../stores/application/applicationApplicant';
import { Authority } from '../../constants/authConstants';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    minHeight: 800,
    borderRadius: 14,
    padding: 8,
    marginTop: 44
  },
  mapContainer: {
    height: 800,
    borderRadius: 8,
    overflow: 'hidden'
  },
  overviewBlock: {
    margin: '20px 0px',
  },
  overviewContainer: {
    [theme.breakpoints.up('md')]: {
      padding: '20px 40px'
    },
    [theme.breakpoints.down('md')]: {
      padding: 4
    },
  },
  approvedBlock: {
    backgroundColor: Colors.withOpacity(Colors.maismaa, 0.1),
    borderRadius: 8,
    marginBottom: 8,
    [theme.breakpoints.up('md')]: {
      padding: '20px 40px'
    },
    [theme.breakpoints.down('md')]: {
      padding: 8
    },
  },
  declinedBlock: {
    backgroundColor: Colors.withOpacity(Colors.hapumarjapuu, 0.1),
    borderRadius: 8,
    marginBottom: 8,
    [theme.breakpoints.up('md')]: {
      padding: '20px 40px'
    },
    [theme.breakpoints.down('md')]: {
      padding: 8
    },
  }
}));

function OverviewBlock({ step, children, isDp, isYp, isKT }) {
  const { t } = useTranslation();
  const classes = useStyles();

  let heading;
  if (!!isDp && isDp) {
    heading = t(`plan.overview.step.${step}`);
  } else if (!!isYp && isYp) {
    if (step === '2') {
      heading = t(`plan.overview.step.2YP`);
    } else {
      heading = t(`plan.overview.step.${step}`);
    }
  } else if (!!isKT && isKT) {
    if (step === '2') {
      heading = t(`plan.overview.step.2KT`);
    } else {
      heading = t(`plan.overview.step.${step}`);
    }
  } else {
    heading = t(`application.overview.step.${step}`);
  }

  return <div className={classes.overviewBlock}>
    <Heading level="4">{heading}</Heading>
    <Box paddingTop={2}>
      {children}
    </Box>
  </div>;
}

function DeclinedBlock({ application }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return <Paper className={classes.declinedBlock}>
    <Heading level="4">{t('application.overview.declined')}</Heading>

    <Box paddingTop={2}>
      {application.description}
    </Box>
  </Paper>;
}

function PlanInitiatedBlock({ application, classifiers }) {
  const classes = useStyles();

  return <Paper className={classes.approvedBlock}>
    <Heading level="4">
      <Link to={`/plan/view/${application.planningId}`}>
        {TableUtils.getClassifierTitle(classifiers, ClassifierType.applicationStatus, application.status)}
      </Link>
      <Box display="inline" flexGrow={1} />
      {DateUtils.formatDate(application.proceedingsDate)}
    </Heading>
  </Paper>;
}

function PlanNotInitiatedBlock({ application, classifiers }) {
  const classes = useStyles();

  const document = application.fileInfoList?.find(f => f.type === ApplicationFileType.plan && f.documentType === PlanningDocumentType.decisionNotInitiate);

  return <Paper className={classes.declinedBlock}>
    <Heading level="4">
      {TableUtils.getClassifierTitle(classifiers, ClassifierType.applicationStatus, application.status)}
      <Box display="inline" flexGrow={1} />
      {DateUtils.formatDate(application.proceedingsDate)}
    </Heading>

    {!!document &&
      <Box paddingTop={2}>
        <FileDownloadLink applicationId={application.id} fileId={document.id} url={document.url}>
          {TableUtils.getClassifierTitle(classifiers, ClassifierType.planningDocumentType, document.documentType)}
        </FileDownloadLink>
      </Box>
    }
  </Paper>;
}

function PlanInvalidatedBlock({ application, classifiers }) {
  const classes = useStyles();

  const document = application.fileInfoList?.find(f => f.type === ApplicationFileType.plan && f.documentType === PlanningDocumentType.decisionInvalidate);

  return <Paper className={classes.approvedBlock}>
    <Heading level="4">
      {TableUtils.getClassifierTitle(classifiers, ClassifierType.applicationStatus, application.status)}
      <Box display="inline" flexGrow={1} />
      {DateUtils.formatDate(application.proceedingsDate)}
    </Heading>

    {!!document &&
      <Box paddingTop={2}>
        <FileDownloadLink applicationId={application.id} fileId={document.id} url={document.url}>
          {TableUtils.getClassifierTitle(classifiers, ClassifierType.planningDocumentType, document.documentType)}
        </FileDownloadLink>
      </Box>
    }
  </Paper>;
}

function PlanNotInvalidatedBlock({ application, classifiers }) {
  const classes = useStyles();

  const document = application.fileInfoList?.find(f => f.type === ApplicationFileType.plan && f.documentType === PlanningDocumentType.decisionNotInvalidate);

  return <Paper className={classes.declinedBlock}>
    <Heading level="4">
      {TableUtils.getClassifierTitle(classifiers, ClassifierType.applicationStatus, application.status)}
      <Box display="inline" flexGrow={1} />
      {DateUtils.formatDate(application.proceedingsDate)}
    </Heading>

    {!!document &&
      <Box paddingTop={2}>
        <FileDownloadLink applicationId={application.id} fileId={document.id} url={document.url}>
          {TableUtils.getClassifierTitle(classifiers, ClassifierType.planningDocumentType, document.documentType)}
        </FileDownloadLink>
      </Box>
    }
  </Paper>;
}

function ClauseBlock({ application }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return <Paper className={classes.approvedBlock}>
    <Heading level="4">
      {t('clause.issued')}
      <Box display="inline" flexGrow={1} />
      {DateUtils.formatDate(application.proceedingsDate)}
    </Heading>

    {!!document &&
      <Box paddingTop={2}>
        <FileDownloadLink url={getPdfSignedDownloadUrl(application.id)}>
          {t('clause.download', { nr: `${application.clauseNumber}` })}
        </FileDownloadLink>
      </Box>
    }
  </Paper>;
}

export default function ApplicationDetails({ title }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { selected: application } = useSelector(state => state.application);
  const { classifiers } = useSelector(state => state.classifier);
  const { layers, center, zoom, extent } = useSelector(state => state.map);
  const { authUser } = useSelector(state => state.auth);
  const [showNotificationButton, setShowNotificationButton] = useState(false);

  const files = application?.fileInfoList;
  const locations = application?.locations;

  const isGovUser = AuthUtils.isGov(authUser);
  const extraLayers = isGovUser && MapUtils.getDomainLayers(layers, application.domain);

  //set map extent after load
  useEffect(() => {
    if (locations?.length) {
      const geometries = locations.filter(r => ApplicationLocationType.application === r.type && !!r.geometry).map(r => r.geometry);
      dispatch(setActiveGeometries(geometries));
    }
  }, [dispatch, locations]);

  useEffect(() => {
    const allowedStatuses = [
      ApplicationStatus.submitted, ApplicationStatus.editing, ApplicationStatus.review,
      ApplicationStatus.coordinating, ApplicationStatus.drafting, ApplicationStatus.processing, ApplicationStatus.signing
    ];
    const canRequestNotifications = !!authUser &&
      AuthUtils.hasAuthority(authUser, Authority.UC1_application_submit) &&
      allowedStatuses.includes(application.status) &&
      !ApplicationUtils.isRelated(authUser, application) &&
      !ApplicationUtils.isParty(authUser, application);
    setShowNotificationButton(canRequestNotifications);
  }, [authUser, application]);

  const isDp = ApplicationType.DP === application.applicationType;
  const isYp = ApplicationType.YP === application.applicationType;
  const isKT = ApplicationType.KT === application.applicationType;
  const showDeclinedBlock = ApplicationStatus.declined === application.status && !!application.description;
  const showPlanInitiatedBlock = (isDp || isYp) && ApplicationStatus.initiated === application.status;
  const showPlanNotInitiatedBlock = (isDp || isYp) && ApplicationStatus.notInitiated === application.status;
  const showClauseBlock = (!isDp && !isYp && !isKT) && ApplicationStatus.processed === application.status;
  const showPlanInvalidatedBlock = isKT && ApplicationStatus.decisionYes === application.status;
  const showPlanNotInvalidatedBlock = isKT && ApplicationStatus.decisionNo === application.status;

  const handleRequestNotifications = async () => {
    if (isDp || isYp) {
      const addedInvolved = await dispatch(addInvolved(application.id));
      if (!addedInvolved) {
        return;
      }
    }
    dispatch(addNotificationRequest({
      relatedTable: 'application',
      relatedId: application.id,
      title
    }));
    setShowNotificationButton(false);
  };

  const handleShowMap = () => {
    dispatch(setActiveGeometries(locations.map(l => l.geometry)));
    dispatch(toggleFeaturesDialog(false));
    dispatch(toggleActiveControl(null, false));
    history.push(`/map`);
  };
  return <>
    {showDeclinedBlock && <DeclinedBlock application={application} />}
    {showPlanInitiatedBlock && <PlanInitiatedBlock application={application} classifiers={classifiers} />}
    {showPlanNotInitiatedBlock && <PlanNotInitiatedBlock application={application} classifiers={classifiers} />}
    {showPlanInvalidatedBlock && <PlanInvalidatedBlock application={application} classifiers={classifiers} />}
    {showPlanNotInvalidatedBlock && <PlanNotInvalidatedBlock application={application} classifiers={classifiers} />}
    {showClauseBlock && <ClauseBlock application={application} />}

    <Paper className={classes.container}>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item xs={12} lg={6} container direction="column" className={classes.overviewContainer}>
          <OverviewBlock step='1'>
            <LocationsOverview locations={locations} applicationType={application.applicationType} />
          </OverviewBlock>

          <Divider />
          <OverviewBlock step='2' isDp={isDp} isKT={isKT} isYp={isYp}>
            <PurposesOverview
              groups={application.applicationPurpose.groups}
              description={application.purpose}
              isYpAppropriate={isDp ? application.isYpAppropriate : null}
              classifiers={classifiers}
            />
          </OverviewBlock>

          <Box marginBottom="15px">
            <FilesOverview applicationId={application.id} files={files} />
          </Box>

          <Divider />

          <OverviewBlock step='3'>
            <ApplicantsOverview applicants={application.applicants} />
          </OverviewBlock>
        </Grid>
        <Grid item xs={12} lg={6} className={classes.mapContainer} onClick={handleShowMap}>
          <Map center={center} zoom={zoom} extent={extent} disablePan>
            <Layers>
              {MapUtils.getDefaultBaseLayer(layers)}
              {MapUtils.getCadastreLayer(layers)}
              {extraLayers}
              <ActiveFeaturesLayer />
            </Layers>
          </Map>
        </Grid>
      </Grid >
    </Paper >
    {showNotificationButton && <Box padding={3}>
      <Button color="tertiary" onClick={handleRequestNotifications} size="small">
        <Icon icon="bell" color={Colors.sinineVaal} />{t(isDp ? 'planning.view.addInvolved' : 'application.view.requestNotifications')}
      </Button>
    </Box>
    }
  </>;
}