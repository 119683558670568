import { Divider, Grid, makeStyles, Paper } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { getPdfSignedDownloadUrl } from '../../../stores/application/clause';
import ApplicationUtils from '../../../utils/ApplicationUtils';
import ProcedureUtils from '../../../utils/ProcedureUtils';
import TableUtils from '../../../utils/TableUtils';
import { FileDownloadLink } from '../ApplicationOverview';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  detailRow: {
    padding: '20px 20px'
  },
  detailRowDense: {
    padding: '2px 2px'
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
    width: '40%',
    display: 'inline-block'
  },
  textarea: {
    whiteSpace: 'pre-line'
  },
  actionButton: {
    marginRight: 8,
    marginBottom: 8
  }
}));

export default function ClauseFeatureDetails({ compact }) {
  let objectType = 'clause';
  const classes = useStyles();

  const { clause } = useSelector(state => state.clause);
  const { classifiers } = useSelector(state => state.classifier);

  const objectDef = ProcedureUtils.getObjectDef(objectType);
  let columns = objectDef?.columns.filter(column => !column.tableOnly && column.type !== 'geometry');

  const renderRow = (index, column) => {
    let value = clause[column.field];
    switch (column.type) {
      case 'link':
        if (!!clause.applicationId && clause.applicationId !== null) {
          if (column.field === 'applicationLink') {
            value = <Link to={`/application/redirect/${clause.applicationId}`}>
              {`${window.location.origin}/application/view/${clause.applicationId}`}
            </Link >;
          } else if (column.field === 'organizerLink') {
            value = <FileDownloadLink url={getPdfSignedDownloadUrl(clause.applicationId)}>
              {`${window.location.origin}/vaal-application/application/${clause.applicationId}/clause/container`}
            </FileDownloadLink>;

          }
        }
        break;
      case 'title':
        value = ApplicationUtils.replaceRoadNames(value, clause.roadNames);
        value = ApplicationUtils.replaceProject(value, clause.project);
        let title = value;
        let clauseNumberStr = `nr ${clause.clauseNumber}`;
        title = title.split(clauseNumberStr);
        value = title[0].charAt(0).toUpperCase() + title[0].toLowerCase().slice(1) + clauseNumberStr + title[1].toLowerCase();
        break;
      default:
        value = TableUtils.getCellValue(column, clause, classifiers);
    }

    return <div key={index}>
      <div className={compact ? classes.detailRowDense : classes.detailRow}>
        {column.type !== 'textarea' && <span className={classes.label}>{ProcedureUtils.getColumnName(objectType, column)}</span>}
        {value}
      </div>
      {!compact && <Divider />}
    </div>;
  }

  const renderPaper = (columns) => {
    return <Grid item xs={12} lg={6}>
      <Paper>
        {columns.map((column, index) => renderRow(index, column))}
      </Paper>
    </Grid>;
  }

  const renderLargeView = () => {
    const splitIndex = Math.ceil(columns.length / 2);
    const leftColumns = columns.slice(0, splitIndex);
    const rightColumns = columns.slice(splitIndex);
    return <>
      {renderPaper(leftColumns)}
      {renderPaper(rightColumns)}
    </>;
  };

  const renderCompactView = () => {
    return <Grid item xs={12} lg={12}>
      {columns.map((column, index) => renderRow(index, column))}
    </Grid>;
  };

  return <Grid container spacing={2}>
    {compact ? renderCompactView() : renderLargeView()}
  </Grid>;
}