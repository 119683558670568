import React, { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Colors } from 'styleguide';
import { toggleLoadingOverlay } from '../../stores/notification';
import TableUtils from '../../utils/TableUtils';

export default function ExportCSVButton({ columns, rows, filename, handleFetchData, includeRenderCellColumns }) {
  const { t } = useTranslation();
  const [headers, setHeaders] = useState([]);
  const [data, setData] = useState([]);
  const csvLink = useRef();
  const dispatch = useDispatch();

  const { classifiers } = useSelector(state => state.classifier);

  const convertToCsvDataRow = (columns, row) => {
    let dataRow = {};
    columns.forEach(column => {
      if (includeRenderCellColumns && column.renderCell) {
        dataRow[column.field] = row[column.field];
      } else {
        dataRow[column.field] = TableUtils.getCellValue(column, row, classifiers);
      }
    });
    return dataRow;
  };

  const downloadCSV = async () => {
    dispatch(toggleLoadingOverlay(true));
    const renderedColumns = columns.filter(column => column.headerName && (includeRenderCellColumns || !column.renderCell));

    await setHeaders(
      renderedColumns.map(column => ({ label: column.headerName, key: column.field }))
    );

    let data = rows;
    if (handleFetchData) {
      data = await handleFetchData();
    }

    await setData(
      data.map(row => convertToCsvDataRow(renderedColumns, row))
    );

    csvLink.current.link.click();
    dispatch(toggleLoadingOverlay(false));
  };

  return <>
    <Button size="extra-small" color="tertiary" onClick={downloadCSV}>
      <Icon icon="files" color={Colors.sinineVaal} />
      {t('table.downloadCSV')}
    </Button>
    <CSVLink
      headers={headers}
      data={data}
      filename={`${filename}.csv`}
      className='hidden'
      ref={csvLink}
      target='_blank'
    />
  </>;
};