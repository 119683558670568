import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/application';
import StoreUtils from '../../utils/StoreUtils';
import TableUtils from '../../utils/TableUtils';
import { showSuccess } from '../notification';
import { setDocument } from './planningDocument';

const apiPath = (planningId) => `planning/${planningId}/operation`;

// Slice
const slice = createSlice({
  name: 'planningOperation',
  initialState: {
    rows: null,
    isLoading: false,
    submitted: false,
    error: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    rowsSuccess: (state, action) => {
      state.rows = action.payload;
      state.isLoading = false;
    },
    insertedSuccess: (state, action) => {
      state.rows = TableUtils.mergeArrayById(state.rows, action.payload);
      state.isLoading = false;
    },
    saveSuccess: (state, action) => {
      const row = state.rows.find(f => f.id === action.payload.id);
      row.description = action.payload.description;
      row.startDate = action.payload.startDate;
      row.endDate = action.payload.endDate;
      row.isPublic = action.payload.isPublic;
      row.sendNotification = action.payload.sendNotification;
      row.supervisorId = action.payload.supervisorId;
      row.supervisorName = action.payload.supervisorName;
      row.executorId = action.payload.executorId;
      row.executorName = action.payload.executorName;
      state.isLoading = false;
    },
    resetSuccess: state => {
      state.rows = null;
    },
    submittedSuccess: (state, action) => {
      state.submitted = action.payload;
    },
    toggledOpen: (state, action) => {
      const operation = state.rows.find(row => row.id === action.payload);
      operation.open = !operation.open;
    }
  },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, rowsSuccess, insertedSuccess, resetSuccess, saveSuccess,
  submittedSuccess, toggledOpen } = slice.actions;

export const setOperations = (rows) => async dispatch => {
  dispatch(rowsSuccess(rows));
};

export const resetOperations = () => async dispatch => {
  dispatch(resetSuccess());
};


export const setSubmitted = () => async dispatch => {
  dispatch(submittedSuccess(true));
};

export const resetSubmitted = () => async dispatch => {
  dispatch(submittedSuccess(false));
};

export const toggleOperation = (operationId) => async dispatch => {
  dispatch(toggledOpen(operationId));
};

export const fetchOperations = (planningId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(apiPath(planningId)).then((response) => {
      dispatch(rowsSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const addOperation = (planningId, data) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.post(`${apiPath(planningId)}`, data).then((response) => {
      dispatch(insertedSuccess(response.data));
      dispatch(submittedSuccess(true));
      dispatch(showSuccess("form.saved"));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const saveOperation = (planningId, operationId, data) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath(planningId)}/${operationId}`, data).then((response) => {
      dispatch(saveSuccess(response.data));
      dispatch(submittedSuccess(true));
      dispatch(showSuccess("form.saved"));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const saveOpinion = (planningId, operationId, dto) => async dispatch => {
  dispatch(startLoading());
  try {
    let formData = new FormData();
    Object.keys(dto).forEach(key => formData.append(key, dto[key]));
    await api.post(`${apiPath(planningId)}/${operationId}/opinion`, formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    ).then((response) => {
      dispatch(setSubmitted());
      dispatch(setDocument(response.data));
      dispatch(showSuccess("planning.notification.opinionSubmitted"));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const saveCoordination = (planningId, operationId, coordinate, dto) => async dispatch => {
  dispatch(startLoading());
  try {
    let formData = new FormData();
    Object.keys(dto).forEach(key => formData.append(key, dto[key]));
    await api.post(`${apiPath(planningId)}/${operationId}/coordinate/${coordinate}`, formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    ).then((response) => {
      dispatch(setSubmitted());
      dispatch(setDocument(response.data));
      dispatch(showSuccess(coordinate ? "planning.notification.coordinated" : "planning.notification.notCoordinated"));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};