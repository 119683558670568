import React from 'react';
import { useSelector } from 'react-redux';
import { ClassifierType } from '../../constants/classifierConstants';

export default function ClassifierLabel({ classifierType, code, ...rest }) {
  const { classifiers } = useSelector(state => state.classifier);
  let classifier = classifiers.find(c => c.typeCode === classifierType && c.itemCode === code);
  if (!classifier) {
    if (classifierType === ClassifierType.planningDocumentType) {
      classifier = classifiers.find(c => c.typeCode === ClassifierType.applicationDocumentType && c.itemCode === code);
    }
  }
  return <span {...rest}>{classifier?.title || code}</span>;
} 