import { Box, Grid, List, ListItem, ListItemText, ListSubheader, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Button, Heading, Paragraph } from "styleguide";
import { ClauseBlockType } from "../../../constants/classifierConstants";
import ApplicationUtils from "../../../utils/ApplicationUtils";
import DOMPurify from 'dompurify';
import { getFileDownloadUrl } from "../../../stores/application/clauseFile";
import React from "react";

const useStyles = makeStyles((theme) => ({
  sketchImage: {
    [theme.breakpoints.up('md')]: {
      width: 1100,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    objectFit: 'cover',
    borderRadius: 8,
  },
  signersBlock: {
    paddingTop: 24,
    paddingBottom: 24,
  },
}));

const getBlockContent = (block, group, clause) => {
  let content;
  if (ClauseBlockType.text === block.blockType) {
    content = block.content;
  } else {
    content = block.content.replace('{{}}', block.value);
  }
  content = ApplicationUtils.replaceGroupTitle(content, group.value);
  content = ApplicationUtils.replaceClauseVariables(content, clause);

  const indentWidth = 42;
  const indent = block.indent ? block.indent * indentWidth : 0;

  return <Box display="table">
    <Box paddingLeft={`${indent}px`} display="table-cell"></Box>
    {!!block.queueStr &&
      <Box width={indent ? indentWidth / 3 : indentWidth} display="table-cell">{block.queueStr || ''}</Box>
    }
    <ClauseBlockContent display="table-cell">{content}</ClauseBlockContent>
  </Box>;
};

function OverviewGroup({ clause, group, disabled, readOnly, handleEdit }) {
  const { t } = useTranslation();

  return <List subheader={
    <ListSubheader disableSticky>
      <Heading level="3L">
        {`${group.queueStr || ''} ${ApplicationUtils.replaceGroupTitle(group.content, group.value)}`}
        <Box flexGrow={1} />
        {!readOnly &&
          <Button onClick={() => handleEdit(group.queueNumber + 1)} size="extra-small" color="tertiary" disabled={disabled}>
            {t('button.edit')}
          </Button>
        }
      </Heading>
    </ListSubheader>
  }>
    {group.blocks.filter(b => b.isSelected).map((block, blockIndex) =>
      <ListItem key={blockIndex}>
        {getBlockContent(block, group, clause)}
      </ListItem>
    )}
  </List>;
}

export default function Overview({ applicationId, application, clause, disabled, readOnly, files, step }) {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const handleEdit = (step) => {
    history.replace(`/application/clause/${applicationId}/${step}`)
  };

  return <List>
    <ListItem style={{ alignItems: "start" }}>
      <ClauseBlockContent>{ApplicationUtils.replaceClauseVariables(clause.preamble, clause)}</ClauseBlockContent>
      <Box flexGrow={1} />
      {!readOnly &&
        <Button onClick={() => handleEdit(1)} size="extra-small" color="tertiary" disabled={disabled}>{t('button.edit')}</Button>
      }
    </ListItem>
    <ListItem />
    {clause.groups.filter(g => g.isSelected).map((group, index) => (
      <OverviewGroup key={index} clause={clause} group={group} disabled={disabled} readOnly={readOnly} handleEdit={handleEdit} />
    ))}

    <ListItem>
      <Grid container direction="row" justifyContent="space-around" className={classes.signersBlock}>
        <Paragraph>{application.handlerName}</Paragraph>
        <Paragraph>{clause.signatoryName || ''}</Paragraph>
      </Grid>
    </ListItem>

    {!!files?.length &&
      <List disablePadding subheader={
        <ListSubheader disableSticky>
          <Heading level="3L">
            {t('clause.extrasTitle')}:
            <Box flexGrow={1} />
            {!readOnly &&
              <Button onClick={() => handleEdit(step - 2)} size="extra-small" color="tertiary" disabled={disabled}>{t('button.edit')}</Button>
            }
          </Heading>
        </ListSubheader>
      }>
        {files.map((file, index) => <React.Fragment key={index}>
          <ListItem>
            <ListItemText secondary={`${index + 1}. ${file.description || file.fileName}`} />
          </ListItem>
          <ListItem>
            <img src={getFileDownloadUrl(applicationId, file.id)} alt="clause extra sketch" className={classes.sketchImage} />
          </ListItem>
        </React.Fragment>)}
      </List>
    }
  </List>;
}

export function ClauseBlockContent({ children, display }) {
  if (!children || !children.trim()) {
    return '';
  }

  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(children)
  });

  return <Box whiteSpace="pre-line" display={display || 'inline'} dangerouslySetInnerHTML={sanitizedData()} />;
}