import { Grid, IconButton, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Colors, Icon } from 'styleguide';
import { Controller, useForm } from 'react-hook-form';
import 'date-fns';
import DialogForm from '../form/DialogForm';
import { cloneDeep } from 'lodash';
import VTextField from '../form/VTextField';
import ValidIcon from '../table/ValidIcon';
import VDateRangePicker from '../form/VDateRangePicker';
import { addUser, fetchUser, saveUser, resetFormSaved } from '../../stores/admin/partner';
import { LogsTable } from './UserForm';
import { fetchPerson } from '../../stores/admin/user';
import { showWarning } from '../../stores/notification';

const useStyles = makeStyles({
  appBar: {
    position: 'relative',
  },
  featured: {
    backgroundColor: Colors.hall3,
  },
  formBlock: {
    maxWidth: 943,
    margin: 'auto',
    padding: 0
  },
  substitutesBlock: {
    maxWidth: 943,
    margin: 'auto',
    padding: '50px 20px',
  },
  substitutesDiv: {
    paddingTop: 10
  },
  substitutesRow: {
    padding: 15,
    backgroundColor: Colors.hall3,
    marginTop: 5
  },
  formTable: {
    maxWidth: 1136,
    margin: 'auto'
  },
  formDivider: {
    height: 8
  },
  status: {
    marginLeft: 20,
    verticalAlign: 'middle',
    display: 'inline-flex'
  }
});

export default function PartnerUserForm() {
  const { t } = useTranslation();
  let { partnerId, userId } = useParams();

  const dispatch = useDispatch();
  const history = useHistory();
  const { user, userLogs, isLoading, formSaved } = useSelector(state => state.partner);
  const { control, register, errors, handleSubmit, reset, setError, getValues, setValue } = useForm({
    defaultValues: user
  });
  const classes = useStyles();
  const [disablePerson, setDisablePerson] = useState(!!user?.person?.id);

  useEffect(() => dispatch(fetchUser(partnerId, userId)), [dispatch, partnerId, userId]);
  useEffect(() => {
    reset(user);
    setDisablePerson(!!user?.person?.id);
  }, [user, reset]);
  useEffect(() => formSaved && dispatch(resetFormSaved()) && history.goBack(), [formSaved, dispatch, history]);

  if (!user || (userId && !user.id)) {
    return <div></div>;
  }

  const onSubmit = (inputData) => {
    let data = cloneDeep(inputData);
    if (data?.validPeriod && data?.validPeriod.from) {
      data.validFromDate = data.validPeriod.from;
      data.validToDate = data.validPeriod.to;
      data.validPeriod = undefined;
    } else {
      setError("validPeriod", { type: "manual", message: t('validation.required') });
      return;
    }

    if (data.id) {
      dispatch(saveUser(partnerId, data));
    } else {
      dispatch(addUser(partnerId, data));
    }
  };

  const handleSearchPerson = async () => {
    const code = getValues('person.code');
    if (code?.length === 11) {
      const person = await dispatch(fetchPerson(code, 'cooperationPartnerCompany', partnerId));
      if (person) {
        setValue('person.id', person.id);
        setValue('person.firstName', person.firstName);
        setValue('person.lastName', person.lastName);
        setDisablePerson(true);
      } else {
        dispatch(showWarning('validation.personNotFound'));
      }
    }
  };

  const title = user.id ?
    <div>
      {t('admin.partner.userForm.title', { user: `${user.person.firstName} ${user.person.lastName}` })}
      <div className={classes.status}><ValidIcon valid={user.valid} /></div>
    </div> : t('admin.partner.userForm.titleNew');

  return <DialogForm title={title} onSubmit={handleSubmit(onSubmit)} disabled={isLoading}>
    <input type="hidden" name="id" ref={register} />
    <input type="hidden" name="person.id" ref={register} />
    <Paper elevation={0} className={classes.formBlock}>
      <Grid container direction="row" spacing={4} justifyContent="space-evenly" alignItems="baseline">
        <Grid container item lg={6} direction="column" spacing={4} alignItems="flex-start">
          <Grid item sm={12}>
            <VTextField name="person.code" label={t('admin.user.code')}
              disabled={isLoading || disablePerson} required minLength={11} maxLength={11}
              pattern={/^[0-9]+$/}
              register={register} errors={errors}
              error={!!errors?.person?.code}
              helperText={errors?.person?.code?.message}
              InputLabelProps={{ shrink: true }} autoFocus
            />
            {!disablePerson &&
              <IconButton onClick={handleSearchPerson} disabled={isLoading} title={t('admin.userForm.searchPerson')}>
                <Icon icon="search" />
              </IconButton>
            }
          </Grid>
          <Grid item sm={12}>
            <VTextField name="person.firstName" label={t('admin.user.firstName')}
              disabled={isLoading || disablePerson} required maxLength={100}
              register={register} errors={errors}
              error={!!errors?.person?.firstName}
              helperText={errors?.person?.firstName?.message}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="person.lastName" label={t('admin.user.lastName')}
              disabled={isLoading || disablePerson} required maxLength={100}
              register={register} errors={errors}
              error={!!errors?.person?.lastName}
              helperText={errors?.person?.lastName?.message}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              render={(props) => (
                <VDateRangePicker
                  label={t('table.validPeriod')}
                  disabled={isLoading}
                  fromDisabled={!!user.id}
                  error={!!(errors && errors.validPeriod)}
                  helperText={errors && errors.validPeriod && errors.validPeriod.message}
                  value={props.value}
                  onChange={props.onChange}
                />
              )}
              name="validPeriod"
              defaultValue={{ from: user.validFromDate, to: user.validToDate }}
              control={control}
            />
          </Grid>
        </Grid>
        <Grid container item lg={6} direction="column" spacing={4} alignItems="flex-end">
          <Grid item sm={12}>
            <VTextField name="position" label={t('admin.partner.position')}
              disabled={isLoading} required
              register={register} errors={errors}
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="email" label={t('admin.user.email')}
              disabled={isLoading} required maxLength={100}
              pattern="email"
              register={register} errors={errors}
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="phone" label={t('admin.user.phone')}
              disabled={isLoading} maxLength={100}
              pattern="phone"
              register={register} errors={errors}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>

    <div className={classes.formDivider}></div>

    {userLogs && !!userLogs.length &&
      <Paper elevation={0} className={classes.formTable}>
        <LogsTable rows={userLogs} />
      </Paper>
    }
  </DialogForm>;
}