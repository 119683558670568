"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.GraphColors = void 0;

/**
 * @visibleName Graph Colors
 */
var GraphColors = {
  lavendel1: 'rgba(127, 92, 242, 1)',
  lavendel2: 'rgba(160, 136, 241, 1)',
  lavendel3: 'rgba(191, 173, 248, 1)',
  lavendel4: 'rgba(230, 223, 249, 1)',
  lavendel5: 'rgba(242, 239, 254, 1)',
  deepBlue1: 'rgba(12, 30, 118, 1)',
  deepBlue2: 'rgba(7, 31, 147, 1)',
  deepBlue3: 'rgba(9, 40, 185, 1)',
  deepBlue4: 'rgba(84, 98, 200, 1)',
  deepBlue5: 'rgba(148, 157, 222, 1)',
  vaal1: 'rgba(33, 67, 140, 1)',
  vaal2: 'rgba(42, 86, 177, 1)',
  vaal3: 'rgba(55, 112, 233, 1)',
  vaal4: 'rgba(101, 146, 238, 1)',
  vaal5: 'rgba(165, 191, 243, 1)',
  yellow1: 'rgba(255, 217, 101, 1)',
  yellow2: 'rgba(254, 226, 141, 1)',
  yellow3: 'rgba(255, 236, 195, 1)',
  yellow4: 'rgba(255, 246, 229, 1)',
  yellow5: 'rgba(255, 248, 236, 1)'
};
exports.GraphColors = GraphColors;
var _default = GraphColors;
exports.default = _default;