import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/application';
import { ApplicationDecision, ApplicationType, Domain } from '../../constants/classifierConstants';
import StoreUtils from '../../utils/StoreUtils';
import TableUtils from '../../utils/TableUtils';
import { showSuccess } from '../notification';
import { resetApplicant, setApplicants } from './applicationApplicant';
import { resetFile, setFiles } from './applicationFile';
import { resetLocation, setLocations } from './applicationLocation';
import { resetPurpose, setPurposeGroups } from './applicationPurpose';
import { resetRows as resetLogRows } from '../activityLog';
import { doSign } from './sign';
import { searchGeomForWholeParish } from '../address';

const apiPath = "application";
const initialPageable = {
  pageSize: TableUtils.getPageSize(178),
  pageNumber: 0,
  sort: {
    field: "enterDate",
    ascending: false
  },
  loaded: false,
  last: true
};

// Slice
const slice = createSlice({
  name: 'application',
  initialState: {
    rows: [],
    selected: null,
    pageable: initialPageable,
    totalElements: null,
    filter: {},
    userFilter: [],
    isLoading: false,
    isLoadingDomain: null,
    error: false,
    submitted: false,
    typeConfig: null,
    coordinators: null,
    isPlanLoaded: false,
    statistics: null
  },
  reducers: {
    startLoading: (state, action) => {
      state.isLoading = true;
      state.isLoadingDomain = action.payload;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    rowsSuccess: (state, action) => {
      if (state.isLoadingDomain === action.payload.domain) {
        const data = action.payload.data;
        state.rows = TableUtils.mergeArrayById(state.rows, data.content);
        state.pageable.last = data.last;
        state.totalElements = data.totalElements;
        state.pageable.loaded = true;
        state.isLoading = false;
        state.isLoadingDomain = null;
        state.isPlanLoaded = !!action.payload.plan;
      }
    },
    resetRowsSuccess: (state) => {
      state.pageable.pageNumber = 0;
      state.pageable.loaded = false;
      state.rows = [];
    },
    pageableSuccess: (state, action) => {
      if (action.payload.pageNumber) {
        state.pageable.pageNumber += action.payload.pageNumber;
      }
      if (action.payload.sort) {
        state.pageable.sort = action.payload.sort;
        state.pageable.pageNumber = 0;
        state.rows = [];
      }
      state.pageable.loaded = false;
    },
    filterSuccess: (state, action) => {
      if ((state.filter[action.payload.field] || '') !== action.payload.value) {
        state.filter[action.payload.field] = action.payload.value;
        state.pageable.pageNumber = 0;
        state.rows = [];
        state.pageable.loaded = false;
      }
    },
    selectedSuccess: (state, action) => {
      state.selected = action.payload;
      state.isLoading = false;
    },
    setFieldSuccess: (state, action) => {
      state.selected[action.payload.field] = action.payload.value;
    },
    submittedSuccess: (state, action) => {
      state.submitted = action.payload;
      state.isLoading = false;
    },
    userFilterSuccess: (state, action) => {
      state.userFilter = action.payload || [];
    },
    typeConfigSuccess: (state, action) => {
      state.typeConfig = action.payload;
      state.isLoading = false;
    },
    coordinatorsSuccess: (state, action) => {
      state.coordinators = action.payload;
      state.isLoading = false;
    },
    statisticsSuccess: (state, action) => {
      state.statistics = action.payload;
    }
  },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, rowsSuccess, pageableSuccess, filterSuccess, selectedSuccess,
  submittedSuccess, resetRowsSuccess, setFieldSuccess, userFilterSuccess,
  typeConfigSuccess, coordinatorsSuccess, statisticsSuccess } = slice.actions;

export const updatePageable = (pageNumber, sort) => async dispatch => {
  dispatch(pageableSuccess({ pageNumber: pageNumber, sort: sort }));
};

export const updateFilter = (field, value) => async dispatch => {
  dispatch(filterSuccess({ field, value }));
};

export const updateUserFilter = (userFilter) => async dispatch => {
  dispatch(userFilterSuccess(userFilter));
  dispatch(resetRows());
};

export const resetRows = () => async dispatch => {
  dispatch(resetRowsSuccess());
};

export const setDetails = (selected) => async dispatch => {
  dispatch(resetLocation());
  dispatch(resetPurpose());
  dispatch(resetFile());
  dispatch(resetApplicant());
  dispatch(selectedSuccess(selected));
};

export const setDetailsFull = (selected) => async dispatch => {
  dispatch(selectedSuccess(selected));
  dispatch(setLocations(selected?.locations));
  dispatch(setPurposeGroups(selected?.applicationPurpose?.groups));
  dispatch(setApplicants(selected?.applicants));
  dispatch(setFiles(selected?.fileInfoList));
};

export const setApplicationField = (field, value) => async dispatch => {
  dispatch(setFieldSuccess({ field, value }));
};

export const fetchRows = (pageable, filter, columns, plan) => async dispatch => {
  const domain = !plan ? 'citizen' : 'citizenPlan';
  dispatch(startLoading(domain));
  try {
    const planParam = !!plan ? 'getPlan=true&' : '';
    await api.get(`${apiPath}?${planParam}${TableUtils.pageableToParams(pageable, filter, columns)}`).then((response) => {
      dispatch(rowsSuccess({ data: response.data, domain: domain, plan }));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchRowsGov = (domain, pageable, filter, columns, userRestriction) => async dispatch => {
  dispatch(startLoading(domain));
  try {
    let path, userRestParam = '';

    if(domain === 'archive'){
      path = 'archived/';
      const allowedDomains = [Domain.plan, Domain.rainwater, Domain.streetlight, Domain.road];
      allowedDomains.forEach(d => {
        if(userRestriction.includes(d)){
          path += d;
          return;
        }
      });
    } else {
      path = `active/${domain}`;
    } 

    if (userRestriction.includes('notProcessed')) {
      userRestParam += '&notProcessed=true';
    }
    if (userRestriction.includes('assignedToMe')) {
      userRestParam += '&assignedToMe=true';
    }
    if (userRestriction.includes('forMyReview')) {
      userRestParam += '&forMyReview=true';
    }
    await api.get(`${apiPath}/handler/${path}?${TableUtils.pageableToParams(pageable, filter, columns)}${userRestParam}`).then((response) => {
      dispatch(rowsSuccess({ data: response.data, domain: domain }));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchRowsPartner = (domain, pageable, filter, columns) => async dispatch => {
  dispatch(startLoading(domain));
  try {
    const path = domain === 'archive' ? 'archived' : `active`;
    await api.get(`${apiPath}/partner/${path}?${TableUtils.pageableToParams(pageable, filter, columns)}`).then((response) => {
      dispatch(rowsSuccess({ data: response.data, domain: domain }));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchSelected = (id) => async dispatch => {
  dispatch(startLoading());
  if (isNaN(id) && id !== ApplicationType.YP) {
    dispatch(add({
      applicationType: id
    }));
    return;
  } else if (id === ApplicationType.YP) {
    let geomStr = await dispatch(searchGeomForWholeParish());
    dispatch(add({
      applicationType: id,
      location: geomStr
    }));
    return;
  }
  try {
    return await api.get(`${apiPath}/${id}`).then((response) => {
      dispatch(setDetailsFull(response.data));
      return response.data;
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const add = (object) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.post(apiPath, object).then((response) => {
      dispatch(fetchSelected(response.data.id));
      dispatch(resetRowsSuccess());
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const save = (object) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath}/${object.id}`, object).then((response) => {
      dispatch(selectedSuccess(response.data));
      dispatch(resetRowsSuccess());
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const signApplication = (applicationId, authUser, onError) => async dispatch => {
  try {
    const onSuccess = () => {
      dispatch(selectedSuccess());
      dispatch(resetRowsSuccess());
      dispatch(submittedSuccess(true));
      dispatch(showSuccess("application.notification.submitted"));
    }

    await dispatch(doSign(`application/${applicationId}/sign`, authUser, onSuccess, onError));
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const submitApplication = (applicationId, authUser, onError) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath}/${applicationId}/submit`).then(response => {
      dispatch(selectedSuccess());
      dispatch(resetRowsSuccess());
      dispatch(submittedSuccess(true));
      dispatch(showSuccess("application.notification.submitted"));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const cancel = (applicationId, isGovEnteringApplication, govCanAddYPApplication) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.delete(`${apiPath}/${applicationId}`).then((response) => {
      dispatch(resetRowsSuccess());
      dispatch(submittedSuccess(true));
      if (isGovEnteringApplication || govCanAddYPApplication) {
        dispatch(showSuccess("application.notification.deleted"));
      } else {
        dispatch(showSuccess("application.notification.cancelled"));
      }
      dispatch(selectedSuccess(null));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const setSubmitted = () => async dispatch => {
  dispatch(submittedSuccess(true));
  dispatch(resetRowsSuccess());
};

export const resetSubmitted = () => async dispatch => {
  dispatch(submittedSuccess(false));
};

export const fetchTypeConfig = (applicationId, applicationType) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(`${apiPath}/${applicationId}/applicationPurposes`).then((response) => {
      response.data.applicationType = applicationType;
      dispatch(typeConfigSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const makeDecision = (applicationId, decision, description) => async dispatch => {
  dispatch(startLoading());
  try {
    return await api.patch(`${apiPath}/${applicationId}/handler/decision`, { decision, description }).then((response) => {
      dispatch(setDetailsFull(response.data));
      dispatch(resetRowsSuccess());
      switch (decision) {
        case ApplicationDecision.renew:
          showSuccess('application.notification.sentForRenew');
          dispatch(submittedSuccess(true));
          break;
        case ApplicationDecision.process:
          showSuccess('application.notification.processing');
          break;
        case ApplicationDecision.reject:
          showSuccess('application.notification.rejected');
          dispatch(submittedSuccess(true));
          break;
        default:
      }
      return response.data;
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const sendForReview = (applicationId, description, handlerId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath}/${applicationId}/handler/review`, { description, handlerId }).then((response) => {
      dispatch(submittedSuccess(true));
      dispatch(selectedSuccess());
      dispatch(resetRowsSuccess());
      showSuccess('application.notification.sentForReview');
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const sendForProcess = (applicationId, description, handlerId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath}/${applicationId}/handler/process`, { description, handlerId }).then((response) => {
      dispatch(submittedSuccess(true));
      dispatch(selectedSuccess());
      dispatch(resetRowsSuccess());
      showSuccess('application.notification.sentForProcess');
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const changeHandler = (applicationId, description, handlerId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath}/${applicationId}/handler/change`, { description, handlerId }).then((response) => {
      dispatch(submittedSuccess(true));
      dispatch(selectedSuccess());
      dispatch(resetRowsSuccess());
      showSuccess('application.notification.sentForProcess');
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};


export const fetchCoordinators = (applicationId) => async dispatch => {
  dispatch(coordinatorsSuccess());
  dispatch(startLoading());
  try {
    await api.get(`${apiPath}/${applicationId}/coordinators/all`).then((response) => {
      dispatch(coordinatorsSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const sendForCoordination = (applicationId, dto) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath}/${applicationId}/coordinators/add`, dto).then(response => {
      dispatch(setSubmitted());
      dispatch(selectedSuccess());
      dispatch(showSuccess('form.saved'));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const coordinate = (applicationId, dto) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath}/${applicationId}/coordinate`, dto).then(response => {
      dispatch(setSubmitted());
      dispatch(selectedSuccess());
      dispatch(showSuccess('form.saved'));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const planDontInitiate = (applicationId, dto) => async dispatch => {
  dispatch(startLoading());
  try {
    let formData = new FormData();
    Object.keys(dto).forEach(key => formData.append(key, dto[key]));
    await api.post(`${apiPath}/${applicationId}/planDontInitiate`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(() => {
      dispatch(setSubmitted());
      dispatch(selectedSuccess());
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const planDontInvalidate = (applicationId, dto) => async dispatch => {
  dispatch(startLoading());
  try {
    let formData = new FormData();
    Object.keys(dto).forEach(key => formData.append(key, dto[key]));
    await api.post(`${apiPath}/${applicationId}/planDontInvalidate`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(() => {
      dispatch(setSubmitted());
      dispatch(selectedSuccess());
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const signHandler = (applicationId, authUser, goBack) => async dispatch => {
  try {
    const onSuccess = () => {
      goBack();
      dispatch(submittedSuccess(true));
      dispatch(selectedSuccess());
      dispatch(resetRowsSuccess());
      dispatch(showSuccess('form.saved'));
    }

    await dispatch(doSign(`application/${applicationId}/handler/sign`, authUser, onSuccess, goBack));
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const signSigner = (applicationId, authUser, goBack) => async dispatch => {
  try {
    const onSuccess = () => {
      goBack();
      dispatch(submittedSuccess(true));
      dispatch(selectedSuccess());
      dispatch(resetRowsSuccess());
      dispatch(showSuccess('form.saved'));
    }

    await dispatch(doSign(`application/${applicationId}/signer/sign`, authUser, onSuccess, goBack));
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchNeedToConsider = () => async dispatch => {
  try {
    return await api.get(`${apiPath}/needToConsider`).then((response) => response.data || []);
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchEntered = () => async dispatch => {
  try {
    return await api.get(`${apiPath}/entered`).then((response) => response.data || []);
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchInOut = (threeMonths) => async dispatch => {
  try {
    return await api.get(`${apiPath}/inOut?threeMonths=${threeMonths || false}`).then((response) => response.data || []);
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchApplicationStatistics = () => async dispatch => {
  try {
    await api.get(`${apiPath}/proceedingStatistics`).then((response) => {
      dispatch(statisticsSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const saveDeadline = (applicationId, deadline, reason) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath}/${applicationId}/deadline`, { deadline, reason }).then((response) => {
      dispatch(setApplicationField('deadline', deadline));
      dispatch(setApplicationField('proceedingsDeadline', deadline));
      dispatch(submittedSuccess());
      dispatch(resetRowsSuccess());
      dispatch(resetLogRows());
      dispatch(showSuccess("form.saved"));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const saveSubmissionDate = (applicationId, submissionDate) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath}/${applicationId}/submissionDate`, { submissionDate }).then((response) => {
      dispatch(setApplicationField('submissionDate', submissionDate));
      dispatch(submittedSuccess());
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const clearError = () => async dispatch => dispatch(hasError());