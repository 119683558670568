import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/application';
import StoreUtils from '../../utils/StoreUtils';
import TableUtils from '../../utils/TableUtils';
import { resetCadastre } from '../map/feature';
import { showSuccess, showWarning, toggleLoadingOverlay } from '../notification';

const apiPath = (applicationId, isPlan, isParty) => {
  if (!isPlan) {
    if (!isParty) {
      return `application/${applicationId}/location`;
    } else {
      return `application/${applicationId}/partyLocation`;
    }
  }
  return `application/${applicationId}/planLocation`;
};

// Slice
const slice = createSlice({
  name: 'applicationLocation',
  initialState: {
    rows: null,
    isLoading: false,
    error: false,
    planLocationToggled: true
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    rowsSuccess: (state, action) => {
      state.rows = TableUtils.sortArrayByString(action.payload || [], 'address');
      state.isLoading = false;
    },
    insertedSuccess: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.rows = TableUtils.sortArrayByString(TableUtils.mergeArrayById(action.payload, action.payload), 'address');
      } else {
        state.rows.push(action.payload);
      }
      state.isLoading = false;
    },
    deletedSuccess: (state, action) => {
      state.rows = state.rows.filter(r => r.id !== action.payload);
      state.isLoading = false;
    },
    resetSuccess: state => {
      state.rows = null;
    },
    planLocationToggledSuccess: state => {
      state.planLocationToggled = !state.planLocationToggled;
    }
  },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, rowsSuccess, insertedSuccess, deletedSuccess, resetSuccess, planLocationToggledSuccess } = slice.actions;

export const setLocations = (locations) => async dispatch => {
  dispatch(rowsSuccess(locations));
};

export const fetchLocations = (applicationId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(apiPath(applicationId)).then((response) => {
      dispatch(rowsSuccess(response.data.locations));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const addLocation = (applicationId, cadastre, rows) => async dispatch =>
  dispatch(addLocationRequest(applicationId, cadastre, rows, false, false));

export const addPlanLocation = (applicationId, cadastre, rows) => async dispatch =>
  dispatch(addLocationRequest(applicationId, cadastre, rows, true, false));

export const addPartyLocation = (applicationId, cadastre, rows) => async dispatch =>
  dispatch(addLocationRequest(applicationId, cadastre, rows, false, true));

const addLocationRequest = (applicationId, cadastre, rows, isPlan, isParty) => async dispatch => {
  dispatch(resetCadastre());
  if (rows?.some(r => r.cadastre === cadastre.cadastre)) {
    dispatch(showWarning('error.map.cadastreAlreadyAdded'));
    dispatch(toggleLoadingOverlay(false));
    return;
  }
  dispatch(startLoading());
  try {
    await api.post(apiPath(applicationId, isPlan, isParty), {
      cadastre: cadastre.cadastre
    }).then((response) => {
      dispatch(insertedSuccess(response.data));
      dispatch(showSuccess("form.saved"));
      dispatch(toggleLoadingOverlay(false));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
    dispatch(toggleLoadingOverlay(false));
  }
};

export const deleteLocation = (applicationId, locationId) => async dispatch =>
  dispatch(deleteLocationRequest(applicationId, locationId, false));

export const deletePlanLocation = (applicationId, locationId) => async dispatch =>
  dispatch(deleteLocationRequest(applicationId, locationId, true));

const deleteLocationRequest = (applicationId, locationId, isPlan) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.delete(`${apiPath(applicationId, isPlan)}/${locationId}`).then((response) => {
      dispatch(deletedSuccess(locationId));
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const resetLocation = () => async dispatch => {
  dispatch(resetSuccess());
};


export const addNeighbours = (applicationId) => async dispatch => {
  dispatch(toggleLoadingOverlay(true));
  dispatch(startLoading());
  try {
    await api.post(`${apiPath(applicationId, true)}/addNeighbours`).then((response) => {
      dispatch(insertedSuccess(response.data));
      dispatch(showSuccess("form.saved"));
      dispatch(toggleLoadingOverlay(false));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
    dispatch(toggleLoadingOverlay(false));
  }
};

export const updatePlanLocationToggled = () => async dispatch => {
  dispatch(planLocationToggledSuccess());
};