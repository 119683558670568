import React from 'react';
import { useSelector } from 'react-redux';
import AuthUtils from '../../utils/AuthUtils';

export default function SecureComponent({ component: Component, children, authority, role, ...rest }) {
  const { authUser } = useSelector(state => state.auth);
  
  if (AuthUtils.isAuthorised(authUser, authority, role)) {
    return <Component {...rest}>{children}</Component>;
  }

  return <></>;
}