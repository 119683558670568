import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Button, Colors, Icon, Paragraph } from 'styleguide';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  container: {
    height: 306,
    margin: '40px auto',
    backgroundColor: Colors.hall3,
    padding: 20,
    borderRadius: 8
  },
  dragActive: {
    backgroundColor: Colors.withOpacity(Colors.sinineVaal, 0.1)
  },
  uploadContiner: {
    width: '100%',
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 8,
    borderColor: Colors.hall1,
    borderStyle: 'dashed',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  },
  dragActiveUpload: {
    borderColor: Colors.sinineVaal
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

export default function FileUpload({ handleUpload, isLoading, applicationUpload }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    open,
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({
    noClick: true,
    onDrop: (files) => {
      handleUpload(files);
    }
  });


  return (
    <div className={clsx(classes.container, isDragActive && classes.dragActive)}>
      <div {...getRootProps({ className: clsx(classes.uploadContiner, isDragActive && classes.dragActiveUpload) })}>
        <input {...getInputProps()} />
        <div className={classes.innerContainer}>
          <Icon icon='filePlus' size="50px" margin="10px"></Icon>
          {!isLoading && <Paragraph fontSize='14B'>{applicationUpload ? t('fileUpload.addApplicationFile') : t('fileUpload.addFile')}</Paragraph>}
          {isLoading && <Paragraph fontSize='14B'>{t('fileUpload.loading')}</Paragraph>}
          {applicationUpload ? <Paragraph fontSize='14' margin="10px">{t('fileUpload.helpTextOneFile')}</Paragraph> :
            <Paragraph fontSize='14' margin="10px">{t('fileUpload.helpText')}</Paragraph>}
          <Button color="tertiary" size="extra-small" onClick={open}>{t('button.choose')}</Button>
        </div>
      </div>
    </div>
  );
}
