import React from 'react';
import { withRouter } from 'react-router';
import { Button } from 'styleguide';

function LinkButton(props) {
  const {
    history,
    location,
    match,
    staticContext,
    to,
    onClick,
    ...rest
  } = props;

  return (
    <Button {...rest}
      onClick={(event) => {
        event.stopPropagation();
        onClick && onClick(event);
        history.push(to);
      }}
    />
  )
}

export default withRouter(LinkButton);