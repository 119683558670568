import { formatISO } from "date-fns";
import i18next from "i18next";
import { fetchAuthUser } from "../stores/auth";
import { showWarnings } from "../stores/notification";

const StoreUtils = {
  handleError: (error, hasError) => async dispatch => {
    console.log(error);
    const response = error.response;
    let errorMsgs = [];
    switch (response?.status) {
      case 400:
        if (response.data && response.data.length) {
          response.data.forEach(error => {
            if (error.errorCode) {
              let errorMsg = {
                message: error.errorCode,
                parameters: {}
              };
              if (error.fieldName && error.objectType) {
                errorMsg.parameters.objectType = i18next.t(`objectType.${error.objectType}`);
                errorMsg.parameters.field = i18next.t(`error.${error.objectType}.${error.fieldName}`);
              }
              errorMsgs.push(errorMsg);
            } else {
              errorMsgs.push('error.technical');
            }
          });
        } else {
          errorMsgs.push('error.technical');
        }
        break;
      case 403:
        errorMsgs.push('error.forbidden');
        dispatch(fetchAuthUser()).then((user) => {
          if (user) {
            window.location.href = '/';
          }
        });
        break;
      case 404:
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
        return;
      default:
        errorMsgs.push('error.technical');
    }
    !!hasError && dispatch(hasError(true));
    dispatch(showWarnings(errorMsgs));
  },
  setDateInterceptor: (axios) => {
    axios.interceptors.request.use((config) => {
      config.transformRequest = [function (data, headers) {
        if (headers['Content-Type'] === 'application/json' && data && data instanceof Object) {
          Object.keys(data).forEach(key => {
            if (data[key] && data[key] instanceof Date) {
              data[key] = formatISO(data[key], { representation: 'date' });
            }
          });
          return JSON.stringify(data);
        }
        return data;
      }, ...axios.defaults.transformRequest];
      return config;
    });
  }
};

export default StoreUtils;