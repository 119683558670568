import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { TableHeader } from 'styleguide';
import { useHistory } from "react-router-dom";
import LinkButton from '../LinkButton';
import ValidIcon from '../table/ValidIcon';
import { fetchRoles, invalidateRole, updateFilter, updatePageable } from '../../stores/admin/role';
import ConfirmButton from '../table/ConfirmButton';
import { PageableTableContainer } from '../table/PageableTable';

export default function RoleTable() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();
  const { filteredRows, filter, pageable, isLoading } = useSelector(state => state.role);

  const columns = useMemo(() => [
    { field: 'valid', headerName: t('table.status'), renderCell: (column, cell) => <ValidIcon valid={cell.valid} />, width: 150 },
    { field: 'title', headerName: t('table.title'), filter: true },
    { field: 'validFromDate', headerName: t('table.validFromDate'), type: 'date' },
    { field: 'validToDate', headerName: t('table.validToDate'), type: 'date' },
    {
      field: 'action', width: 150, renderCell: (column, cell) => (
        !!cell.valid &&
        <ConfirmButton
          message={t('admin.roleForm.confirmSetInvalid')}
          icon="delete"
          onConfirm={() => dispatch(invalidateRole(cell))}
          aria-label="set role invalid"
        />
      )
    }
  ], [t, dispatch]);

  useEffect(() => !pageable.loaded && dispatch(fetchRoles(pageable, filter, columns)), [dispatch, pageable, filter, columns]);

  const handleRowSelection = (selected) => history.push(`/admin/role/${selected.id}`);

  const header = () => (
    <TableHeader buttons={<LinkButton size="extra-small" to="/admin/role">{t('button.add')}</LinkButton>}>
      {t('admin.rolesTitle')}
    </TableHeader>
  );

  return <PageableTableContainer
    rows={filteredRows}
    columns={columns}
    filter={filter}
    pageable={pageable}
    header={header()}
    loading={isLoading}
    onRowSelected={handleRowSelection}
    onUpdatePageable={(pageNumber, sort) => dispatch(updatePageable(pageNumber, sort))}
    onUpdateFilter={(field, value) => dispatch(updateFilter(field, value))}
  />;
}