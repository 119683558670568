import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BasicLabel, Button, Colors, Icon, TableHeader } from 'styleguide';
import { fetchAllRowsAndAddToFeatures, fetchObjectRows, fetchObjectRowsCSV, setDetails, setObjectType, updateFilter, updatePageable } from '../../stores/register/register';
import TableSelect from '../table/TableSelect';
import RegisterTabs from './RegisterTabs';
import AuthUtils from '../../utils/AuthUtils';
import { InfiniteScrollTableContainer } from '../table/PageableTable';
import ExportCSVButton from '../table/ExportCSVButton';
import MapUtils from '../../utils/MapUtils';
import RegisterUtils from '../../utils/RegisterUtils';
import { Domain } from '../../constants/classifierConstants';
import { toggleFeaturesDialog } from '../../stores/map/feature';
import { showWarning } from '../../stores/notification';
import { toggleActiveControl } from '../../stores/map/map';

export default function RegisterTable({ domain }) {
  const { t } = useTranslation();
  const history = useHistory();

  const dispatch = useDispatch();
  const { objectType, objectRows, filter, pageable, totalElements, isLoading } = useSelector(state => state.register);
  const { rows: basicObjectTypes } = useSelector(state => state.basicObjectType);
  const { drawingFeatures } = useSelector(state => state.feature);
  const { authUser } = useSelector(state => state.auth);
  const [typeHeadings, setTypeHeadings] = useState([]);
  const registerObjectDefs = RegisterUtils.objectDefs;
  const isBasicObject = Domain.infrastructure === domain || Domain.landscaping === domain;
  const loadTypeHeadings = !isBasicObject || basicObjectTypes.length > 0;

  useEffect(() => loadTypeHeadings && domain && !typeHeadings.length &&
    setTypeHeadings(
      Object.keys(registerObjectDefs)
        .filter(key => registerObjectDefs[key].domain === domain && (!registerObjectDefs[key].authority || AuthUtils.hasAnyAuthority(authUser, registerObjectDefs[key].authority)))
        .map(key => ({ code: key, title: registerObjectDefs[key].title || t(`register.${key}.label`), divider: !!registerObjectDefs[key].divider }))
    ), [typeHeadings, domain, t, registerObjectDefs, loadTypeHeadings, authUser]);

  useEffect(() => typeHeadings.length && (!objectType || !typeHeadings.some(t => t.code === objectType)) && dispatch(setObjectType(typeHeadings[0].code)), [dispatch, typeHeadings, objectType]);

  useEffect(() => objectType && !pageable.loaded && dispatch(fetchObjectRows(objectType, pageable, filter)), [dispatch, objectType, pageable, filter]);

  const handleShowMap = (row) => {
    dispatch(toggleActiveControl(null, false));
    dispatch(MapUtils.showRegisterObjectOnMap(history, objectType, row));
  };

  const handleRowSelection = (selected) => {
    dispatch(setDetails(objectType, selected));
    history.push(`/register/view/${objectType}/${selected.id}`);
  };

  const handleAddNew = () => {
    const newItem = {};
    if (RegisterUtils.getSuperObjectType(objectType) === 'basicObject') {
      newItem.basicObjectType = basicObjectTypes.find(bot => bot.id === Number(objectType));
    }
    if (drawingFeatures?.length) {
      const fault = RegisterUtils.setDrawGeometries(newItem, drawingFeatures, columns);
      if (fault) {
        dispatch(showWarning('validation.wrongGeometryType', fault));
        return;
      }
    }
    dispatch(setDetails(objectType, newItem));
    history.push(`/register/edit/${objectType}`);
  };

  const showAllOnMap = () => {
    if (pageable.last) {
      const geometries = objectRows.flatMap(row => RegisterUtils.getRegisterGeometries(objectType, row));
      dispatch(MapUtils.showOnMap(history, geometries, objectType));
    } else {
      dispatch(fetchAllRowsAndAddToFeatures(objectType, pageable, totalElements, filter));
      dispatch(toggleFeaturesDialog(false));
      history.push(`/map`);
    }
  };

  const columns = RegisterUtils.getRegisterColumns(objectType, handleShowMap);

  const fetchCSVData = () => {
    const maxRows = 5000;
    if (totalElements > maxRows) {
      dispatch(showWarning('error.notAllRowsCSV', maxRows));
    }
    return dispatch(fetchObjectRowsCSV(objectType, pageable, filter, totalElements));
  };

  const header = () => (
    <TableHeader buttons={<>
      <ExportCSVButton
        handleFetchData={fetchCSVData}
        columns={columns}
        filename={t(`register.${RegisterUtils.getSuperObjectType(objectType)}.label`)}
      />
      {totalElements < 250 && columns.some(c => c.type === 'geometry') &&
        <Button
          size="extra-small"
          onClick={showAllOnMap}
          disabled={isLoading}
          color="tertiary"
        >
          <Icon icon="earth" color={Colors.sinineVaal} />{t('button.showMap')}
        </Button>
      }
      {RegisterUtils.getObjectDef(objectType)?.genericAddNew && RegisterUtils.canEditDomain(domain, authUser) &&
        <Button
          size="extra-small"
          onClick={handleAddNew}
        >
          {t('button.add')}
        </Button>
      }
    </>}>
      <TableSelect selected={objectType} values={typeHeadings} handleValueSelected={(type) => dispatch(setObjectType(type.code))} />
      {!!totalElements && <BasicLabel isNumeric>{totalElements}</BasicLabel>}
    </TableHeader>
  );

  return <>
    <RegisterTabs />

    <Box my={5}>
      <InfiniteScrollTableContainer
        rows={objectRows}
        columns={columns}
        filter={filter}
        pageable={pageable}
        header={header()}
        loading={isLoading}
        onRowSelected={handleRowSelection}
        onUpdatePageable={(pageNumber, sort) => dispatch(updatePageable(pageNumber, sort))}
        onUpdateFilter={(field, value) => dispatch(updateFilter(field, value))}
        stickyHeader
      />
    </Box>
  </>;
}