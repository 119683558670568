import { ClickAwayListener, List, ListItem, ListItemText, Paper, debounce, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetAddress, searchAddress } from "../../stores/address";
import { CenteredLoadingIndicator } from "../form/LoadingIndicator";
import VTextField from "./VTextField";

const useStyles = makeStyles((theme) => ({
  addressListPaper: {
    position: 'absolute',
    zIndex: 1200,
    width: 356,
    [theme.breakpoints.down('md')]: {
      marginLeft: -25
    },
  }
}));

export default function AddressSearchTextField({name, label, register, disabled, errors, setValue, indexName, adrIdName = 'adrId' }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { rows, isLoading } = useSelector(state => state.address);
  const [focused, setFocused] = useState(false);

  useEffect(() => dispatch(resetAddress()), [dispatch]);

  const setValues = (adrId, index) => {
    setValue(adrIdName, adrId);
    if(indexName) {
      setValue(indexName, index);
    }
  };

  const handleSearch = debounce((event) => {
    const value = event.target.value;
    if(value && value.length > 2) {
      dispatch(searchAddress(value, false, true));
    } else {
      dispatch(resetAddress());
    }
    setValues();
  }, 500);

  const handleSelect = (address) => {
    setValue(name, address.regularAddress);
    setValues(address.adrId, address.index)
    dispatch(resetAddress());
  };

  const unFocus = () => {
    setTimeout(() => {
      if (focused) setFocused(false)
    }, 300);
  };
  
  const focus =() => {
    if(!focused) setFocused(true)
  };

  return <div>
    <input type="hidden" name={adrIdName} ref={register} />
    <VTextField
      name={name} 
      label={label}
      disabled={disabled} 
      maxLength={500} 
      register={register} 
      errors={errors}
      onChange={handleSearch}
      onBlur={unFocus}
      onFocus={focus}
      multiline
    />
    {(focused && (isLoading || !!rows.length)) && <ClickAwayListener onClickAway={() => dispatch(resetAddress())}>
      <Paper className={classes.addressListPaper}>
        <List dense>
          {isLoading && <CenteredLoadingIndicator />}
          {!isLoading && rows.map((row, index) => (
            <ListItem key={index} button onClick={() => handleSelect(row)}>
              <ListItemText secondary={row.address} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </ClickAwayListener>}
  </div>;
};