import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Paragraph } from 'styleguide';
import { ClassifierType, Domain } from '../../constants/classifierConstants';
import TableUtils from '../../utils/TableUtils';
import TypeReport from './TypeReport';
import ReportTableCell from './ReportTableCell';

export default function RoadSurfacesReport() {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    let columns = [
      {
        field: 'count', headerName: t(`report.table.roadCount`), notSortable: true, renderCell: (column, cell) => {
          const value = `${TableUtils.formatNumber(cell.count) || 0} ${t('report.table.unitPieces')}`;
          if (!cell.type) {
            return <Paragraph fontSize='14B'>{value}</Paragraph>;
          }
          return value;
        }
      },
      {
        field: 'length', headerName: t(`report.table.length`), notSortable: true, renderCell: (column, cell) => {
          const value = `${TableUtils.formatNumber(cell.length) || 0} ${t('report.table.unitM')}`;
          if (!cell.type) {
            return <Paragraph fontSize='14B'>{value}</Paragraph>;
          }
          return value;
        }
      },
      {
        field: 'area', headerName: t(`report.table.area`), notSortable: true, renderCell: (column, cell) => {
          const value = <span>
            {`${TableUtils.formatNumber(cell.area) || 0} ${t('report.table.unitM')}`}
            <sup>2</sup>
          </span>;
          if (!cell.title) {
            return <Paragraph fontSize='14B'>{value}</Paragraph>;
          }
          return value;
        }
      }
    ];
    return columns;
  }, [t]);

  const handleRenderCollapsibleRow = (row) => {
    if (row.open && row.data) {
      return <>
        <ReportTableCell data={row.data} getContent={(d => d.title)}/>
        <ReportTableCell data={row.data}/>
        <ReportTableCell data={row.data} getContent={(d) => `${TableUtils.formatNumber(d.length)} ${t('report.table.unitM')}`}/>
        <ReportTableCell data={row.data} getContent={(d) => !!d.area && `${TableUtils.formatNumber(d.area)} ${t('report.table.unitM2')}`}/>
      </>;
    }
  };

  const charts = [{
    id: 'chart-surfaces-length',
    field: 'length',
    title: t('report.TE.surfacesLengthChartTitle')
  },{
    id: 'chart-surfaces-area',
    field: 'area',
    title: t('report.TE.surfacesAreaChartTitle')
  }];

  return <TypeReport
    classifierType={ClassifierType.coatingType}
    groupedColumns={columns}
    domain={Domain.road}
    reportName="surfaces"
    handleRenderCollapsibleRow={handleRenderCollapsibleRow}
    typeColumnLabel={t('register.roadSurface.coatingType')}
    chartConfigs={charts}
  />;
}