import { Overlay } from "ol";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MapUtils from "../../../utils/MapUtils";
import MapContext from "../MapContext";

const CopyCoordinatesControl = () => {
  const { t } = useTranslation();
  const { map } = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    let coordinatesTooltip;
    const handleRightClick = (event) => {
      event.preventDefault();
      coordinatesTooltip && map.removeOverlay(coordinatesTooltip);

      const coordinates = map.getEventCoordinate(event);
      const x = Math.round(coordinates[0] * 100) / 100;
      const y = Math.round(coordinates[1] * 100) / 100;
      const coordinatesText = `${x} ${y}`;
      MapUtils.copyToClipboard(coordinatesText);

      let coordinatesElement = document.createElement('div');
      coordinatesElement.className = 'ol-coordinates-tooltip';
      coordinatesElement.innerHTML = t('map.control.coordinatesCopied', { coordinates: coordinatesText });
      coordinatesTooltip = new Overlay({
        element: coordinatesElement,
        positioning: 'bottom-center',
        position: coordinates,
        stopEvent: false,
        insertFirst: false,
      });
      map.addOverlay(coordinatesTooltip);
      setTimeout(() => {
        coordinatesTooltip && map.removeOverlay(coordinatesTooltip);
      }, 5000);
    };

    map.getViewport().addEventListener('contextmenu', handleRightClick);

    return () => {
      map && map.getViewport().removeEventListener('contextMenu', handleRightClick);
    };
  }, [map, t]);

  return null;
};

export default CopyCoordinatesControl;