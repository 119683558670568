import { FormControlLabel } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Radio } from 'styleguide';

export default function VRadio({ item, name, label, control, errors, required, disabled, ...rest }) {
  const { t } = useTranslation();

  let rules = {};
  if (required) {
    rules.required = { value: true, message: t('validation.required') };
  }

  if (!label) {
    label = `table.${name}`;
  }

  return <Controller
    name={name}
    control={control}
    defaultValue={!!item && (item[name] || false)}
    rules={rules}
    render={({ onChange, value }) => (
      <FormControlLabel
        label={t(label)}
        control={<Radio
          onChange={(e) => onChange(e.target.checked)}
          disabled={disabled}
          checked={value}
        />}
      />
    )}
  />;
}