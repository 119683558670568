import { Divider, Grid, List, ListItem, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Colors, Heading, Illustration, Paragraph, ProgressBar, Shadows } from 'styleguide';
import { fetchApplicationStatistics } from '../../stores/application/application';
import { fetchWorkOrderStatistics } from '../../stores/workOrder/workOrder';
import ChartUtils from '../../utils/ChartUtils';
import TableUtils from '../../utils/TableUtils';
import { CenteredLoadingIndicator } from '../form/LoadingIndicator';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 635,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    borderRadius: 16,
    marginBottom: 40,
  },
  paper: {
    borderRadius: 16,
  },
  title: {
    padding: 16,
  },
  listItem: {
    [theme.breakpoints.up('md')]: {
      padding: 32,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 32,
      paddingBottom: 32,
      paddingLeft: 8,
      paddingRight: 8
    },
  },
  inProgressBlock: {
    borderRadius: 16,
    [theme.breakpoints.up('md')]: {
      padding: 32,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 32,
      paddingBottom: 32,
      paddingLeft: 8,
      paddingRight: 8
    },
  },
  applicationBlock: {
    backgroundColor: Colors.withOpacity(Colors.lainehari, 0.2),
  },
  workOrderBlock: {
    backgroundColor: Colors.withOpacity(Colors.lavendel, 0.1),
  },
  progressContainer: {
    width: '100%'
  },
  inDeadlineBar: {
    borderRadius: 4
  }
}));

function InProgressBlock({ type, illustration, count, percent }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return <Grid container direction="column" alignItems="center" spacing={1} className={`${classes.inProgressBlock} ${classes[type]}`}>
    <Grid item>
      <Illustration type={illustration} />
    </Grid>
    <Grid item>
      <Heading level="4">
        {t(`homepage.proceedingStatistics.${type}`, { count })}
      </Heading>
    </Grid>
    {!!count && <>
      <Grid item>
        <Paragraph fontSize="16">
          {t('homepage.proceedingStatistics.onTime', { percent })}
        </Paragraph>
      </Grid>
      <Grid item className={classes.progressContainer}>
        <ProgressBar percent={percent} className={classes.inDeadlineBar} color={type === 'workOrderBlock' && Colors.lavendel} />
      </Grid>
    </>}
  </Grid>;
}

export default function ProceedingStatisticsBlock() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [data, setData] = useState();
  const workOrderStats = useSelector(state => state.workOrder.statistics);
  const applicationStats = useSelector(state => state.application.statistics);

  useEffect(() => {
    dispatch(fetchWorkOrderStatistics());
    dispatch(fetchApplicationStatistics());
  }, [dispatch]);
  useEffect(() => {
    if (!!workOrderStats && !!applicationStats) {
      let stats = [];
      applicationStats.handlerStatistics?.forEach(stat => stats.push({
        userId: stat.userId,
        name: stat.handlerName,
        applicationCount: stat.processedApplicationCount
      }));
      workOrderStats.authorStatistics?.forEach(stat => {
        const existingStat = stats.find(e => e.userId === stat.userId);
        if (existingStat) {
          existingStat.workOrderCount = stat.completedWorkOrderCount;
        } else {
          stats.push({
            userId: stat.userId,
            name: stat.authorName,
            workOrderCount: stat.completedWorkOrderCount
          });
        }
      });
      stats.forEach(stat => stat.totalCount = (stat.applicationCount || 0) + (stat.workOrderCount || 0));
      setData(TableUtils.sortArrayByNumber(stats, 'totalCount', true));
    }
  }, [workOrderStats, applicationStats]);

  if (!workOrderStats || !applicationStats || !data) {
    return <CenteredLoadingIndicator />;
  }

  return <div className={classes.container}>
    <Paper className={classes.paper}>
      <List disablePadding>
        <ListItem>
          <Heading level="4" className={classes.title}>{t('homepage.block.proceedingStatistics')}</Heading>
        </ListItem>

        <Divider />

        <ListItem className={classes.listItem}>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={6}>
              <InProgressBlock
                type="applicationBlock" illustration="menetluses"
                count={applicationStats.proceedingCount}
                percent={applicationStats.inDeadlinePercent}
              />
            </Grid>
            <Grid item xs={6}>
              <InProgressBlock
                type="workOrderBlock" illustration="inWork"
                count={workOrderStats.inProgressCount}
                percent={workOrderStats.inDeadlinePercent}
              />
            </Grid>
          </Grid>
        </ListItem>

        {!!data.length &&
          <ListItem className={classes.listItem}>
            <ResponsiveContainer width="100%" height={(70 * data.length) + 100}>
              <BarChart
                layout="vertical"
                data={data}
                margin={{
                  top: 10,
                  right: 16,
                  left: -60,
                  bottom: 0
                }}
                barSize={16}
              >
                <XAxis type="number" axisLine={false} tickLine={false} />
                <YAxis type="category" dataKey="name" axisLine={false} tickLine={false} tick={{
                  dx: 10,
                  dy: -30,
                  fill: Colors.vagaVesi,
                  fontSize: 14,
                  fontWeight: 'bold',
                  textAnchor: 'start',
                  verticalAnchor: 'left',
                  width: 200
                }} />
                <CartesianGrid horizontal={false} stroke={Colors.hall3} />
                <Tooltip cursor={{ fill: 'none' }} itemStyle={{ color: 'white' }} contentStyle={{
                  backgroundColor: Colors.vagaVesi,
                  border: 'none',
                  borderRadius: 8,
                  boxShadow: Shadows.shadow2,
                  color: 'white',
                  fontSize: 12,
                }} />
                <Legend iconSize={16} iconType="square" formatter={ChartUtils.renderLegendText} wrapperStyle={{
                  paddingLeft: 60
                }} />
                <Bar dataKey="applicationCount" fill={Colors.lainehari} name={t('homepage.proceedingStatistics.applications')} radius={4} />
                <Bar dataKey="workOrderCount" fill={Colors.lavendel} name={t('homepage.proceedingStatistics.workOrders')} radius={4} />
              </BarChart>
            </ResponsiveContainer>
          </ListItem>
        }
      </List>
    </Paper>
  </div>;
}