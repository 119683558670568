import { Button, IconButton } from '@material-ui/core';
import React from 'react';
import { Colors, Icon } from 'styleguide';

export default function ToolbarButton({ compact, onClick, icon, label }) {
  return compact ?
    <IconButton onClick={onClick} title={label} size="small">
      <Icon icon={icon} />
    </IconButton> :
    <Button color="primary" size="small" onClick={onClick}>
      <Icon icon={icon} color={Colors.sinineVaal} margin="6px" />
      <span>{label}</span>
    </Button>;
}