import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/application';
import StoreUtils from '../../utils/StoreUtils';
import TableUtils from '../../utils/TableUtils';
import { showSuccess } from '../notification';

const apiPath = (planningId) => `planning/${planningId}/document`;

// Slice
const slice = createSlice({
  name: 'planningDocument',
  initialState: {
    rows: null,
    isLoading: false,
    submitted: false,
    error: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    endLoading: state => {
      state.isLoading = false
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    rowsSuccess: (state, action) => {
      state.rows = action.payload;
      state.isLoading = false;
    },
    insertedSuccess: (state, action) => {
      state.rows = TableUtils.mergeArrayById(state.rows, action.payload);
      state.isLoading = false;
    },
    saveSuccess: (state, action) => {
      const row = state.rows.find(f => f.id === action.payload.id);
      row.description = action.payload.description;
      row.number = action.payload.number;
      row.documentDate = action.payload.documentDate;
      row.isPlan = action.payload.isPlan;
      row.isPublic = action.payload.isPublic;
      row.isFrontpage = action.payload.isFrontpage;
      row.url = action.payload.url;
      row.content = action.payload.content;
      row.authorId = action.payload.authorId;
      row.authorName = action.payload.authorName;
      state.isLoading = false;
    },
    deletedSuccess: (state, action) => {
      state.rows = state.rows.filter(r => r.id !== action.payload);
      state.isLoading = false;
    },
    resetSuccess: state => {
      state.rows = null;
    },
    submittedSuccess: (state, action) => {
      state.submitted = action.payload;
    },
  },
});

export default slice.reducer;

// Actions

const { startLoading, endLoading, hasError, rowsSuccess, insertedSuccess, deletedSuccess, resetSuccess, saveSuccess,
  submittedSuccess } = slice.actions;

export const setDocuments = (files) => async dispatch => {
  dispatch(rowsSuccess(files));
};

export const setDocument = (document) => async dispatch => {
  dispatch(insertedSuccess([document]));
};

export const getDocumentDownloadUrl = (planningId, documentId) => `${api.defaults.baseURL}/${apiPath(planningId)}/${documentId}`;
export const getDocumentVersionDownloadUrl = (planningId, documentId, fileId) =>
  `${api.defaults.baseURL}/${apiPath(planningId)}/${documentId}/version/${fileId}`;

export const resetDocuments = () => async dispatch => {
  dispatch(resetSuccess());
};

export const resetSubmitted = () => async dispatch => {
  dispatch(submittedSuccess(false));
};

export const fetchDocuments = (planningId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(apiPath(planningId)).then((response) => {
      dispatch(rowsSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const addDocument = (planningId, data) => async dispatch => {
  dispatch(startLoading());
  try {
    let formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]));
    const document = await api.post(`${apiPath(planningId)}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then((response) => {
      dispatch(insertedSuccess([response.data]));
      dispatch(submittedSuccess(true));
      dispatch(showSuccess("form.saved"));
      return response.data;
    });
    return document;
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const saveDocument = (planningId, documentId, data) => async dispatch => {
  dispatch(startLoading());
  try {
    const document = await api.patch(`${apiPath(planningId)}/${documentId}`, data).then((response) => response.data);
    dispatch(saveSuccess(document));
    dispatch(showSuccess("form.saved"));
    return document;
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const deleteDocument = (planningId, documentId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.delete(`${apiPath(planningId)}/${documentId}`).then((response) => {
      dispatch(deletedSuccess(documentId));
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const addDocumentVersion = (planningId, documentId, data) => async dispatch => {
  dispatch(startLoading());
  try {
    let formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]));
    await api.post(`${apiPath(planningId)}/${documentId}/version`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    dispatch(endLoading());
    dispatch(showSuccess("form.saved"));
    return true;
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchDocumentVersions = (planningId, documentId) => async dispatch => {
  try {
    return await api.get(`${apiPath(planningId)}/${documentId}/version`).then((response) => response.data);
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};