import { useContext, useEffect } from "react";
import OLMousePosition from "ol/control/MousePosition";
import {createStringXY} from 'ol/coordinate';
import MapContext from "../MapContext";

const MousePositionControl = () => {
	const { map } = useContext(MapContext);

	useEffect(() => {
		if (!map) return;

		let control = new OLMousePosition({
      coordinateFormat: createStringXY(2)
    });

		map.controls.push(control);

		return () => map.controls.remove(control);
	}, [map]);

	return null;
};

export default MousePositionControl;