import { TableCell } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Paragraph } from 'styleguide';
import { ClassifierType, Domain } from '../../constants/classifierConstants';
import TableUtils from '../../utils/TableUtils';
import TypeReport from './TypeReport';

export default function CableTypeReport() {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    let columns = [
      {
        field: 'length', headerName: t(`report.table.length`), notSortable: true, renderCell: (column, cell) => {
          const value = `${TableUtils.formatNumber(cell.length) || 0} ${t('report.table.unitM')}`;
          if (!cell.type) {
            return <Paragraph fontSize='14B'>{value}</Paragraph>;
          }
          return value;
        }
      },
    ];
    return columns;
  }, [t]);

  const handleRenderCollapsibleRow = (row) => {
    if (row.open && row.data) {
      return <>
        <TableCell>{row.data.map((d, i) => <div key={i}>{d.title}</div>)}</TableCell>
        <TableCell>{row.data.map((d, i) => <div key={i}>{`${TableUtils.formatNumber(d.length)} ${t('report.table.unitM')}`}</div>)}</TableCell>
      </>;
    }
  };

  return <TypeReport
    classifierType={ClassifierType.cablingType}
    groupedColumns={columns}
    domain={Domain.streetlight}
    reportName="cableType"
    handleRenderCollapsibleRow={handleRenderCollapsibleRow}
    chartField="length"
    typeColumnLabel={t('register.cable.cablingType')}
  />;
}