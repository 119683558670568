import DateUtils from "./DateUtils";
import i18next from 'i18next';
import { isNumber } from "lodash";
import { ClassifierType } from '../constants/classifierConstants';

const TableUtils = {
  getPageSize(extraHeight, withHeader, withTableHeader) {
    let { innerHeight: height } = window;
    if (extraHeight) {
      height -= extraHeight;
    }
    if (withHeader) {
      height -= 102;
    }
    if (withTableHeader) {
      height -= 85;
    }
    return Math.max(5, Math.round((height - 150) / 66));
  },
  hasColumnField(columns, field) {
    return columns.some(c => c.field === field);
  },
  pageableToParams(pageable, filter, columns) {
    let params = `size=${pageable.pageSize}&page=${pageable.pageNumber}`;
    params += `&${this.filterToSearchParam(filter, columns)}`;
    if (pageable.sort) {
      if (Array.isArray(pageable.sort)) {
        pageable.sort.forEach(sort => params += this.sortToParams(sort));
      } else {
        params += this.sortToParams(pageable.sort);
      }
    }
    return params;
  },
  sortToParams(sort) {
    return `&sort=${sort.field},${sort.ascending ? 'asc' : 'desc'}`;
  },
  filterToSearchParam(filter, columns) {
    const keys = filter && Object.keys(filter);
    if (!filter || !keys.length) {
      return "";
    }
    let params = [];
    keys.filter(key => filter[key]).forEach(key => {
      const column = columns.find(c => c.field === key);
      const value = filter[key];
      switch (column?.type) {
        case 'number':
        case 'integer':
          if (!isNaN(value)) {
            params.push(`${key}:${value}`);
          }
          break;
        case 'classifier':
        case 'boolean':
          if (value?.length > 0) {
            if (column.customFilter) {
              params.push(column.customFilter(value));
            } else {
              params.push(`${key}:${value.join(`,'${key}:`)}`);
            }
          }
          break;
        case 'relation':
          params.push(`${column.relation}_${column.relationField}:*${value}*`);
          break;
        case 'govUser':
          params.push(`${key}:${value.id}`);
          break;
        case 'dateTime':
          if (value?.from) {
            params.push(`${key}>:${DateUtils.formatISODateTimeWOZone(value.from)}`);
          }
          if (value?.to) {
            params.push(`${key}<:${DateUtils.formatISODateTimeWOZone(value.to)}`);
          }
          break;
        default:
          if (!!column && column.customFilter) {
            params.push(column.customFilter(value));
          } else {
            if (key !== 'handlerId') {
              params.push(`${key}:*${value}*`)
            }
          }
      }
    });
    return `search=${params.join(',')}`;
  },
  getSortParam(sort) {
    if (Array.isArray(sort)) {
      return sort.map(s => this.getSortParam(s)).join();
    }
    return `&sort=${sort.field},${sort.ascending ? 'asc' : 'desc'}`
  },
  getSortField(column) {
    return column.sortField || (column.type === 'relation' ? `${column.relation}.${column.relationField}` : column.field);
  },
  getCellValue(column, row, classifiers) {
    if (!row || column.renderCell) {
      return "";
    }
    let value = row[column.field];

    if (column.type === 'relation') {
      const rel = row[column.relation];
      value = rel ? rel[column.relationField] : '';
      if (!value && column.includeInMapDialog) {
        value = row[`${column.relation}${column.relationField.charAt(0).toUpperCase() + column.relationField.slice(1)}`];
      }
    }
    if (value !== undefined && value !== null && value !== '') {
      switch (column.type) {
        case 'classifier':
          const classifier = classifiers.find(c => c.typeCode === column.classifierType && c.itemCode === value);
          if(!classifier){
            if (column.classifierType === ClassifierType.planningDocumentType) {
              value = classifiers.find(c => c.typeCode === ClassifierType.applicationDocumentType && c.itemCode === value)?.title || value;
            }
          }
          if(classifier && column.formatClassifier){
            value = column.formatClassifier(classifier);
          } else {
            value = classifier?.title || value;
          }
          break;
        case 'customIdList':
          value = column.listValues?.find(c => c.id === value)[column.listLabelKey] || value;
          break;
        case 'date':
          value = DateUtils.formatDate(value);
          break;
        case 'dateTime':
          value = DateUtils.formatDateTime(value);
          break;
        case 'boolean':
          value = TableUtils.getBooleanValue(value);
          break;
        case 'geometry':
          value = '';
          break;
        case 'number':
          value = TableUtils.getNumberValue(value);
          break;
        default:
      }

      if (column.unit) {
        value = `${value} ${column.unit}`;
      }
    }
    return value;
  },
  getNumberValue: (value) => {
    if (value !== undefined && value !== null && value !== '') {
      return value.toString().replace('.', ',');
    }
  },
  getBooleanValue: (value) => {
    if (value === true) {
      return i18next.t('form.true');
    }
    if (value === false) {
      return i18next.t('form.false');
    }
    return '';
  },
  sortArrayByNumber(array, field, desc) {
    if (!array || !field) {
      return [];
    }
    let order = 1;
    if (!!desc) {
      order = -1;
    }
    return array.slice().sort((a, b) => (a[field] > b[field]) ? order : order * -1);
  },
  sortArrayByString(array, field) {
    if (!array || !field) {
      return [];
    }
    return array.slice().sort((a, b) => (a[field] || '').localeCompare(b[field]));
  },
  getClassifiersByType(classifiers, type) {
    return TableUtils.sortArrayByString(classifiers.filter(c => c.typeCode === type), 'title');
  },
  getClassifier(classifiers, type, code) {
    return classifiers.find(c => c.typeCode === type && c.itemCode === code);
  },
  getClassifierTitle(classifiers, type, code) {
    return this.getClassifier(classifiers, type, code)?.title;
  },
  textSearch(searchable, value) {
    if (Array.isArray(value)) {
      if (value.length) {
        return searchable && value.includes(searchable);
      }
      return true;
    }
    return searchable && value && searchable.toLowerCase().includes(value.toLowerCase());
  },
  setFilteredRows(state) {
    let filtered = state.rows;
    if (!filtered?.length) {
      return [];
    }

    if (state.pageable.sort) {
      filtered.sort(function (a, b) {
        let aa = a[state.pageable.sort.field];
        let bb = b[state.pageable.sort.field];

        if (aa === bb) {
          return 0;
        }
        if (aa === null || aa === '' || aa === undefined) {
          return 1;
        }
        if (bb === null || bb === '' || bb === undefined) {
          return -1;
        }

        return String(aa).localeCompare(String(bb), undefined, {
          numeric: true,
          sensitivity: 'base'
        }) * (state.pageable.sort.ascending ? 1 : -1);
      });
    }

    if (state.filter) {
      Object.keys(state.filter).forEach(key => {
        if (key && state.filter[key]) {
          filtered = filtered.filter(r => this.textSearch(r[key], state.filter[key]));
        }
      });
    }

    const displayedRows = (state.pageable.pageNumber + 1) * state.pageable.pageSize;
    state.pageable.last = filtered.length <= displayedRows;
    state.filteredRows = filtered.slice(0, displayedRows);
  },
  mergeArrayById(array1, array2) {
    const result = array1.concat(array2);
    return result.filter((item, index, self) => index === self.findIndex((o) => o.id === item.id));
  },
  formatNumber(value) {
    return isNumber(value) ? value.toLocaleString('et-EE') : value;
  }
};

export default TableUtils;