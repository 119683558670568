import { Box, Divider, Grid, makeStyles, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import VTextField from '../../form/VTextField';
import { deleteApplicant, saveApplicants, checkApplicants, setApplicantStatus, toggleWithRepresentative } from '../../../stores/application/applicationApplicant';
import { AddButton, Colors, Heading, TabContainer, Icon } from 'styleguide';
import { cloneDeep } from 'lodash';
import LoadingIndicator from '../../form/LoadingIndicator';
import { ApplicantType, ApplicationType, ClassifierType } from '../../../constants/classifierConstants';
import TableUtils from '../../../utils/TableUtils';
import ConfirmButton from '../../table/ConfirmButton';
import ApplicationUtils from '../../../utils/ApplicationUtils';
import { fetchPerson } from '../../../stores/admin/user';
import { showWarning } from '../../../stores/notification';
import AddressSearchTextField from '../../form/AddressSearchTextField';

const useStyles = makeStyles({
  formBlock: {
    maxWidth: 943,
    margin: 'auto',
    paddingBottom: 100
  },
  partyRow: {
    padding: 15,
    backgroundColor: Colors.hall3,
    marginTop: 5
  },
  deleteButton: {
    float: 'right'
  }
});

function PhysicalJuridicalSelect({ isPhysical, onClick, mobile }) {
  const { t } = useTranslation();

  return <Box paddingBottom={4}>
    <TabContainer width='296px' size={mobile ? 'small' : 'big'}>
      <div className={isPhysical && 'active'}
        onClick={() => !isPhysical && onClick(true)}>
        {t('application.applicant.personal')}
      </div>
      <div className={!isPhysical && 'active'}
        onClick={() => isPhysical && onClick(false)}>
        {t('application.applicant.company')}
      </div>
    </TabContainer >
  </Box>
}

function ApplicantHeading({ heading, handleDelete, isLoading }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return <Grid item sm={12} container direction="row" justifyContent="space-between">
    {!!heading &&
      <Heading level="4">{heading}</Heading>}
    {!!handleDelete &&
      <ConfirmButton message={t('form.confirmDelete')} icon="delete" onConfirm={handleDelete}
        aria-label="delete applicant" disabled={isLoading} className={classes.deleteButton} />}
  </Grid>;
}

function PhysicalApplicantForm({ applicationId, fieldName, heading, register, error, isLoading, defaultValues, handleDelete, readOnlyCode, getValues, setValue }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [disableInputPerson, setDisableInputPerson] = useState(false);
  const [readOnlyInputPerson] = useState(true);
  const [shrinkField, setShrinkField] = useState();
  const authUser = useSelector(state => state.auth.authUser);
  const govCanAddApplication = authUser && ApplicationUtils.isGovCanAddApplication(authUser);
  const govCanAddYPApplication = authUser && ApplicationUtils.isGovCanAddYPApplication(authUser);

  const handleSearchPerson = async () => {
    const code = getValues(`${fieldName}.code`);
    if (code?.length === 11) {
      const person = await dispatch(fetchPerson(code, 'application', applicationId));
      if (person) {
        setDisableInputPerson(false);
        setValue(`${fieldName}.id`, person.id);
        setValue(`${fieldName}.firstName`, person.firstName);
        setValue(`${fieldName}.name`, person.lastName);
        setShrinkField(true);
      } else {
        setDisableInputPerson(false);
        handleCodeInput();
        dispatch(showWarning('validation.personNotFound'));
      }
    }
  };

  const handleCodeInput = () => {
    setValue(`${fieldName}.id`, '');
    setValue(`${fieldName}.firstName`, '');
    setValue(`${fieldName}.name`, '');
    setDisableInputPerson(true);
  };

  return <Grid item>
    <Grid container direction="column" spacing={4} alignItems="flex-start">
      <input type="hidden" name={`${fieldName}.id`} ref={register} defaultValue={defaultValues?.id} />

      <ApplicantHeading heading={heading} handleDelete={handleDelete} isLoading={isLoading} />

      <Grid item sm={12}>
        <VTextField name={`${fieldName}.code`} label={t('application.applicant.code')}
          disabled={govCanAddApplication || govCanAddYPApplication ? isLoading : readOnlyCode || isLoading}
          required minLength={11} maxLength={11}
          pattern={/^[0-9]+$/} defaultValue={defaultValues?.code}
          register={register} error={!!error?.code}
          helperText={govCanAddApplication || govCanAddYPApplication ? error?.person?.code?.message : error?.code?.message}
          InputLabelProps={{ shrink: shrinkField }}
          onChange={handleCodeInput}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
              handleSearchPerson();
            }
          }}
        />
        {(govCanAddApplication || govCanAddYPApplication) && <>
          <IconButton onClick={handleSearchPerson} disabled={isLoading} title={t('admin.userForm.searchPerson')}>
            <Icon icon="search" />
          </IconButton>
        </>
        }
        {!govCanAddApplication && !govCanAddYPApplication && !readOnlyCode &&
          <IconButton onClick={handleSearchPerson} disabled={isLoading} title={t('admin.userForm.searchPerson')}>
            <Icon icon="search" />
          </IconButton>
        }
      </Grid>
      <Grid item sm={12}>
        <VTextField name={`${fieldName}.firstName`} label={t('application.applicant.firstName')}
          disabled={govCanAddApplication || govCanAddYPApplication ? isLoading || disableInputPerson : readOnlyCode || isLoading}
          required maxLength={100} defaultValue={defaultValues?.firstName}
          register={register} error={!!error?.firstName} helperText={error?.firstName?.message}
          InputLabelProps={{ shrink: shrinkField }}
          InputProps={{ readOnly: !!readOnlyInputPerson }}
        />
      </Grid>
      <Grid item sm={12}>
        <VTextField name={`${fieldName}.name`} label={t('application.applicant.name')}
          disabled={govCanAddApplication || govCanAddYPApplication ? isLoading || disableInputPerson : readOnlyCode || isLoading}
          required maxLength={100} defaultValue={defaultValues?.name}
          register={register} error={!!error?.name} helperText={error?.name?.message}
          InputLabelProps={{ shrink: shrinkField }}
          InputProps={{ readOnly: !!readOnlyInputPerson }}
        />
      </Grid>
      <Grid item sm={12}>
        <VTextField name={`${fieldName}.email`} label={t('application.applicant.email')}
          disabled={isLoading} required maxLength={100}
          pattern="email" defaultValue={defaultValues?.email}
          register={register} error={!!error?.email} helperText={error?.email?.message}
        />
      </Grid>
      <Grid item sm={12}>
        <VTextField name={`${fieldName}.phone`} label={t('application.applicant.phone')}
          disabled={isLoading} maxLength={100}
          pattern="phone" defaultValue={defaultValues?.phone}
          register={register} error={!!error?.phone} helperText={error?.phone?.message}
        />
      </Grid>
      <Grid item sm={12}>
        <AddressSearchTextField name={`${fieldName}.address`} label={t('application.applicant.address')}
          disabled={isLoading} setValue={setValue} adrIdName={`${fieldName}.adrId`}
          register={register} 
        />
      </Grid>
    </Grid>
  </Grid>;
}

function JuridicalApplicantForm({ fieldName, heading, register, error, isLoading, defaultValues, handleDelete, getValues, setValue }) {
  const { t } = useTranslation();

  return <Grid item>
    <Grid container direction="column" spacing={4} alignItems="flex-start">
      <input type="hidden" name={`${fieldName}.id`} ref={register} defaultValue={defaultValues?.id} />

      <ApplicantHeading heading={heading} handleDelete={handleDelete} isLoading={isLoading} />

      <Grid item sm={12}>
        <VTextField name={`${fieldName}.code`} label={t('application.applicant.companyCode')}
          disabled={isLoading} required minLength={8} maxLength={8}
          pattern={/^[0-9]+$/} defaultValue={defaultValues?.code}
          register={register} error={!!error?.code} helperText={error?.code?.message}
        />
      </Grid>
      <Grid item sm={12}>
        <VTextField name={`${fieldName}.name`} label={t('application.applicant.companyName')}
          disabled={isLoading} required maxLength={100} defaultValue={defaultValues?.name}
          register={register} error={!!error?.name} helperText={error?.name?.message}
        />
      </Grid>
      <Grid item sm={12}>
        <VTextField name={`${fieldName}.email`} label={t('application.applicant.email')}
          disabled={isLoading} required maxLength={100}
          pattern="email" defaultValue={defaultValues?.email}
          register={register} error={!!error?.email} helperText={error?.email?.message}
        />
      </Grid>
      <Grid item sm={12}>
        <VTextField name={`${fieldName}.phone`} label={t('application.applicant.phone')}
          disabled={isLoading} maxLength={100}
          pattern="phone" defaultValue={defaultValues?.phone}
          register={register} error={!!error?.phone} helperText={error?.phone?.message}
        />
      </Grid>
      <Grid item sm={12}>
        <AddressSearchTextField name={`${fieldName}.address`} label={t('application.applicant.address')}
          disabled={isLoading} setValue={setValue} adrIdName={`${fieldName}.adrId`}
          register={register} 
        />
      </Grid>
    </Grid>
  </Grid>;
}

function ApplicantForm({ applicationId, register, isLoading, errors, defaultValues, getValues, setValue }) {
  return <PhysicalApplicantForm applicationId={applicationId} fieldName="applicant" register={register} error={errors?.applicant} isLoading={isLoading} readOnlyCode
    defaultValues={defaultValues} getValues={getValues} setValue={setValue} />;
}

function ApplicantWithRepresentativeForm({ applicationId, register, isLoading, errors, applicants, getValues, setValue }) {
  const { t } = useTranslation();

  return <Grid container direction="row" spacing={4} alignItems="baseline" justifyContent="space-evenly">
    <JuridicalApplicantForm fieldName="applicant" heading={t('application.applicant.applicant')}
      register={register} error={errors?.applicant} isLoading={isLoading} getValues={getValues} setValue={setValue} />
    <PhysicalApplicantForm applicationId={applicationId} fieldName="representative" heading={t('application.applicant.representative')}
      register={register} error={errors?.representative} isLoading={isLoading} readOnlyCode defaultValues={applicants.representative}
      getValues={getValues} setValue={setValue} />
  </Grid>;
}

function SinglePartyForm({ applicationId, control, register, setValue, mobile, index, errors, isLoading, defaultValues, handleDelete, getValues }) {
  const classes = useStyles();
  const { classifiers } = useSelector(state => state.classifier);

  const fieldName = `parties[${index}]`;
  const isPhysicalFieldName = `${fieldName}.isPhysical`;
  const isPhysicalValue = useWatch({ control, name: isPhysicalFieldName, defaultValue: defaultValues.isPhysical });
  const isPhysical = isPhysicalValue === true || isPhysicalValue === 'true';
  const type = useWatch({ control, name: `${fieldName}.applicantType`, defaultValue: defaultValues.applicantType });
  const typeTitle = TableUtils.getClassifierTitle(classifiers, ClassifierType.applicantType, type);

  return <Grid item>
    <Grid container direction="column" alignItems="center" className={classes.partyRow}>
      <input type="text" name={isPhysicalFieldName} ref={register()} defaultValue={defaultValues?.isPhysical} style={{ display: 'none' }} />
      <input type="hidden" name={`${fieldName}.applicantType`} ref={register()} defaultValue={defaultValues?.applicantType} />

      <PhysicalJuridicalSelect
        isPhysical={isPhysical}
        onClick={(value) => {
          setValue(isPhysicalFieldName, value, { shouldDirty: true })
        }}
        mobile={mobile}
      />

      {isPhysical ?
        <PhysicalApplicantForm applicationId={applicationId} fieldName={fieldName} heading={typeTitle} defaultValues={defaultValues} handleDelete={handleDelete}
          register={register} error={errors?.parties && errors.parties[index]} isLoading={isLoading} getValues={getValues} setValue={setValue} /> :
        <JuridicalApplicantForm fieldName={fieldName} heading={typeTitle} defaultValues={defaultValues} handleDelete={handleDelete}
          register={register} error={errors?.parties && errors.parties[index]} isLoading={isLoading} getValues={getValues} setValue={setValue} />
      }
    </Grid>
  </Grid>;
}

function PartyForm({ applicationId, parties, control, register, setValue, mobile, errors, isLoading, getValues }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'parties',
    keyName: 'fid',
  });

  const handleAddNew = (type) => {
    append({ id: null, applicantType: type, isPhysical: 'true' });
  };

  const handleDelete = (index, id) => {
    if (id) {
      dispatch(deleteApplicant(applicationId, id));
    }
    remove(index);
  };
  return <Grid item>
    <Box py={5}>
      <Divider />
    </Box>

    <Box paddingBottom={1}>
      <Heading level="3L">{t('application.applicant.partyDescription')}</Heading>
    </Box>

    {fields.map((row, index) => (
      <SinglePartyForm key={index}
        applicationId={applicationId}
        index={index}
        control={control}
        register={register}
        setValue={setValue}
        errors={errors}
        isLoading={isLoading}
        mobile={mobile}
        defaultValues={!row.applicantType ? parties[index] : row}
        handleDelete={() => handleDelete(index, row.id)}
        getValues={getValues} />
    ))}

    <div className={classes.partyRow}>
      <AddButton onClick={() => handleAddNew(ApplicantType.partyCoApplicant)}>{t('application.applicant.addCoApplicant')}</AddButton>
    </div>
    <div className={classes.partyRow}>
      <AddButton onClick={() => handleAddNew(ApplicantType.partyPlanner)}>{t('application.applicant.addPlanner')}</AddButton>
    </div>
  </Grid>;
}

export default function Step4Contact({ application }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const mobile = useSelector(state => state.global.mobileView);
  const { applicants, isLoading, applicantStatus, isDirtyStore } = useSelector(state => state.applicationApplicant);
  const { register, control, errors, handleSubmit, reset, formState, setValue, getValues } = useForm({
    defaultValues: applicants
  });
  const { isDirty } = formState;
  const shouldSubmitForm = isDirty || isDirtyStore;

  const applicationId = application.id;

  useEffect(() => reset(applicants), [applicants, reset]);
  useEffect(() => {
    if (applicantStatus === 'SUBMIT') {
      dispatch(setApplicantStatus('SUBMITTING'));
      handleSubmit((inputData) => {
        async function checkData() {
          let data = cloneDeep(inputData);
          if (!shouldSubmitForm) {
            dispatch(setApplicantStatus('OK'));
            return;
          }
          let applicantsAreCorrect = await dispatch(checkApplicants(applicationId, data));
          if (applicantsAreCorrect) {
            dispatch(saveApplicants(applicationId, data));
          } else {
            return;
          }
        }
        checkData();
      })();
    }
  }, [dispatch, applicationId, applicantStatus, handleSubmit, shouldSubmitForm]);

  if (!applicants) {
    return <LoadingIndicator />;
  }

  const withRepresentative = !application.isPhysicalApplicant;

  return <form className={classes.formBlock}>
    <Grid container direction="column" alignItems="center" justifyContent="center">
      <PhysicalJuridicalSelect
        isPhysical={!withRepresentative}
        onClick={(value) => dispatch(toggleWithRepresentative(!value))}
        mobile={mobile}
      />
      {withRepresentative ?
        <ApplicantWithRepresentativeForm
          applicationId={applicationId}
          register={register}
          isLoading={isLoading}
          errors={errors}
          applicants={applicants}
          getValues={getValues}
          setValue={setValue}
        /> :
        <ApplicantForm
          applicationId={applicationId}
          register={register}
          isLoading={isLoading}
          errors={errors}
          getValues={getValues}
          setValue={setValue} />}
      {ApplicationType.DP === application.applicationType &&
        <PartyForm
          applicationId={applicationId}
          parties={applicants.parties}
          control={control}
          register={register}
          setValue={setValue}
          errors={errors}
          isLoading={isLoading}
          mobile={mobile}
          getValues={getValues}
        />
      }
    </Grid>
  </form>;
}
