import { FormControlLabel, Grid, IconButton, makeStyles, Paper, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addUser, fetchPerson, fetchUser, saveUser } from '../../stores/admin/user';
import { Colors, Icon, TableHeader, Heading, VChip, AddButton, Checkbox } from 'styleguide';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import 'date-fns';
import DialogForm from '../form/DialogForm';
import { fetchRoles } from '../../stores/admin/role';
import { cloneDeep } from 'lodash';
import VTextField from '../form/VTextField';
import ValidIcon from '../table/ValidIcon';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClassifierSelect from '../form/ClassifierSelect';
import VDateRangePicker from '../form/VDateRangePicker';
import { ClassifierType } from '../../constants/classifierConstants';
import SimplePageableTable from '../table/SimplePageableTable';
import GovUserSelect from '../form/GovUserSelect';
import { showWarning } from '../../stores/notification';

const useStyles = makeStyles({
  appBar: {
    position: 'relative',
  },
  featured: {
    backgroundColor: Colors.hall3,
  },
  formBlock: {
    maxWidth: 943,
    margin: 'auto',
    padding: 0
  },
  substitutesBlock: {
    maxWidth: 943,
    margin: 'auto',
    padding: '50px 20px',
  },
  substitutesDiv: {
    paddingTop: 10
  },
  substitutesRow: {
    padding: 15,
    backgroundColor: Colors.hall3,
    marginTop: 5
  },
  formTable: {
    maxWidth: 1136,
    margin: 'auto'
  },
  formDivider: {
    height: 8
  },
  status: {
    marginLeft: 20,
    verticalAlign: 'middle',
    display: 'inline-flex'
  }
});

function Substitutes({ control, register, errors, isLoading }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "substitutes", // unique name for your Field Array
    keyName: "fid" //default to "id", you can change the key name
  });

  const handleAddNew = () => {
    append({ substitute: null, validPeriod: null });
  };

  const handleDelete = (index) => {
    remove(index);
  };

  return <div className={classes.substitutesDiv}>
    {fields.map((row, index) => (
      <Grid container key={index} direction="row" justifyContent="space-between" alignItems="center" className={classes.substitutesRow}>
        <Grid item>
          <input type="hidden" name={`substitutes[${index}].id`} ref={register()} defaultValue={row.id} />
          <GovUserSelect name={`substitutes[${index}].substitute`} fullWidth label={t('admin.userSubstitute.substitute')}
            control={control} disabled={isLoading || !!row.id} defaultValue={row.substitute}
            error={!!(errors?.substitutes && errors.substitutes[index]?.substitute)}
            helperText={!!(errors?.substitutes && errors.substitutes[index]?.substitute) && errors.substitutes[index]?.substitute.message} />
        </Grid>
        <Grid item>
          <Controller
            render={(props) => (
              <VDateRangePicker
                label={t('table.validPeriod')}
                error={!!(errors?.substitutes && errors.substitutes[index]?.validPeriod)}
                helperText={!!(errors?.substitutes && errors.substitutes[index]?.validPeriod) && errors.substitutes[index]?.validPeriod.message}
                disabled={isLoading || !!row.id}
                value={props.value}
                onChange={props.onChange}
              />
            )}
            name={`substitutes[${index}].validPeriod`}
            defaultValue={{ from: row.validFromDate || null, to: row.validToDate || null }}
            control={control}
          />
        </Grid>
        <Grid item xs={1}>
          {!!row.id && <IconButton onClick={() => handleDelete(index)} disabled={isLoading}>
            <Icon icon="delete" />
          </IconButton>
          }
        </Grid>
      </Grid>
    ))}
    <div className={classes.substitutesRow}>
      <AddButton onClick={handleAddNew}>{t('admin.userSubstitute.addNew')}</AddButton>
    </div>
  </div>;
}

export function LogsTable({ rows }) {
  const { t } = useTranslation();

  const columns = [
    { field: 'created', headerName: t('admin.userLog.time'), type: 'dateTime' },
    {
      field: 'modifierName', headerName: t('admin.userLog.modifierName'),
      renderCell: (column, cell) => `${cell.creator?.person?.firstName} ${cell.creator?.person?.lastName}`
    },
    { field: 'creator', headerName: t('admin.userLog.modifierPosition'), type: 'relation', relation: 'creator', relationField: 'position' },
    { field: 'content', headerName: t('admin.userLog.content'), filter: true }
  ];

  return <SimplePageableTable
    rows={rows}
    columns={columns}
    header={<TableHeader>{t('admin.userLog.title')}</TableHeader>}
    sort={{ field: 'created', ascending: false }}
    pageSize={5}
  />;
}

export default function UserForm() {
  const { t } = useTranslation();
  let { userId } = useParams();

  const dispatch = useDispatch();
  const { user, logs, isLoading } = useSelector(state => state.user);
  const { control, register, errors, handleSubmit, reset, setError, getValues, setValue } = useForm({
    defaultValues: user
  });
  const classes = useStyles();
  const [disablePerson, setDisablePerson] = useState(!!user?.person?.id);

  useEffect(() => dispatch(fetchUser(userId)), [dispatch, userId]);

  useEffect(() => {
    reset(user);
    setDisablePerson(!!user?.person?.id);
  }, [user, reset]);

  const { rows: roles } = useSelector(state => state.role);
  const shouldFetchRoles = !roles.length;

  useEffect(() => shouldFetchRoles && dispatch(fetchRoles()), [dispatch, shouldFetchRoles]);

  if (!user || (userId && !user.id)) {
    return <div></div>;
  }

  const onSubmit = (inputData) => {
    //TODO cleanup
    let data = cloneDeep(inputData);
    let userRoles = [];
    if (data?.roles?.length) {
      data.roles.forEach((role) => {
        const existing = user.userRoles?.find(r => r.role.id === role.id);
        if (existing) {
          userRoles.push(existing);
        } else {
          userRoles.push({ roleId: role.id });
        }
      });
    } else {
      setError("roles", { type: "manual", message: t('validation.required') });
      return;
    }
    data.roles = userRoles;
    if (data?.substitutes?.length) {
      data.substitutes.forEach((s) => {
        if (s.validPeriod && s.validPeriod.from) {
          s.validFromDate = s.validPeriod.from;
          s.validToDate = s.validPeriod.to;
          s.validPeriod = undefined;
        }
        if (s.substitute) {
          s.substituteId = s.substitute.id;
          s.substitute = undefined;
        }
      });
    }
    if (data?.validPeriod && data?.validPeriod.from) {
      data.validFromDate = data.validPeriod.from;
      data.validToDate = data.validPeriod.to;
      data.validPeriod = undefined;
    } else {
      setError("validPeriod", { type: "manual", message: t('validation.required') });
      return;
    }

    if (data.id) {
      dispatch(saveUser(data));
    } else {
      dispatch(addUser(data));
    }
  };

  const handleSearchPerson = async () => {
    const code = getValues('person.code');
    if (code?.length === 11) {
      const person = await dispatch(fetchPerson(code, 'user', user?.id));
      if (person) {
        setValue('person.id', person.id);
        setValue('person.firstName', person.firstName);
        setValue('person.lastName', person.lastName);
        setDisablePerson(true);
      } else {
        dispatch(showWarning('validation.personNotFound'));
      }
    }
  };

  const title = user.id ?
    <div>
      {t('admin.userForm.title', { user: `${user.person.firstName} ${user.person.lastName}` })}
      <div className={classes.status}><ValidIcon valid={user.valid} /></div>
    </div> : t('admin.userForm.titleNew');

  return <DialogForm title={title} onSubmit={handleSubmit(onSubmit)} disabled={isLoading}>
    <input type="hidden" name="id" ref={register} />
    <input type="hidden" name="person.id" ref={register} />
    <Paper elevation={0} className={classes.formBlock}>
      <Grid container direction="row" spacing={4} justifyContent="space-evenly" alignItems="baseline">
        <Grid container item lg={6} direction="column" spacing={4} alignItems="flex-start">
          <Grid item sm={12}>
            <VTextField name="person.code" label={t('admin.user.code')}
              disabled={isLoading || disablePerson} required minLength={11} maxLength={11}
              pattern={/^[0-9]+$/}
              register={register} errors={errors}
              error={!!errors?.person?.code}
              helperText={errors?.person?.code?.message}
              InputLabelProps={{ shrink: true }} autoFocus
            />
            {!disablePerson &&
              <IconButton onClick={handleSearchPerson} disabled={isLoading} title={t('admin.userForm.searchPerson')}>
                <Icon icon="search" />
              </IconButton>
            }
          </Grid>
          <Grid item sm={12}>
            <VTextField name="person.firstName" label={t('admin.user.firstName')}
              disabled={isLoading || disablePerson} required maxLength={100}
              register={register} errors={errors}
              error={!!errors?.person?.firstName}
              helperText={errors?.person?.firstName?.message}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="person.lastName" label={t('admin.user.lastName')}
              disabled={isLoading || disablePerson} required maxLength={100}
              register={register} errors={errors}
              error={!!errors?.person?.lastName}
              helperText={errors?.person?.lastName?.message}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="email" label={t('admin.user.email')}
              disabled={isLoading} required maxLength={100}
              pattern="email"
              register={register} errors={errors}
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="phone" label={t('admin.user.phone')}
              disabled={isLoading} maxLength={100}
              pattern="phone"
              register={register} errors={errors}
            />
          </Grid>
        </Grid>
        <Grid container item lg={6} direction="column" spacing={4} alignItems="flex-end">
          <Grid item sm={12}>
            <VTextField name="position" label={t('admin.user.position')}
              disabled={isLoading} required
              register={register} errors={errors}
            />
          </Grid>
          <Grid item sm={12}>
            <ClassifierSelect item={user} name="domain" label="admin.user.domain" required
              disabled={isLoading} control={control} errors={errors} classifierType={ClassifierType.domain}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              render={(props) => (
                <VDateRangePicker
                  label={t('table.validPeriod')}
                  disabled={isLoading}
                  error={!!(errors && errors.validPeriod)}
                  helperText={errors && errors.validPeriod && errors.validPeriod.message}
                  value={props.value}
                  onChange={props.onChange}
                />
              )}
              name="validPeriod"
              defaultValue={{ from: user.validFromDate, to: user.validToDate }}
              control={control}
            />
          </Grid>
          <Grid item sm={12}>
            <Controller
              render={({ onChange, ...rest }) => (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  onChange={(event, value) => {
                    return onChange(value);
                  }}
                  options={roles}
                  getOptionLabel={(o) => o.title}
                  closeIcon={<Icon icon="arrowUp" />}
                  popupIcon={<Icon icon="arrowDown" />}
                  disableClearable
                  getOptionSelected={(option, value) => {
                    return option.id === value.id;
                  }}
                  disabled={isLoading}
                  renderInput={(params) =>
                    <TextField {...params} variant="outlined" className="VaalTextField" label={t('admin.userForm.rolesTitle')}
                      error={!!(errors && errors.roles)}
                      helperText={errors && errors.roles && errors.roles.message}
                    />
                  }
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <VChip label={option.title}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderOption={(option, { selected }) => (
                    <FormControlLabel
                      control={<Checkbox checked={selected} />}
                      label={option.title}
                    />
                  )}
                  {...rest}
                />
              )}
              onChange={([, data]) => data}
              name="roles"
              defaultValue={user.roles}
              control={control}
              rules={{ required: { value: true, message: t('validation.required') } }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>

    <div className={classes.formDivider}></div>

    {user.id &&
      <Paper elevation={0} className={classes.substitutesBlock}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Heading level='4'>{t('admin.userSubstitute.title')}</Heading>
            <Substitutes control={control} register={register} errors={errors} isLoading={isLoading} />
          </Grid>
        </Grid>
      </Paper>
    }

    {logs && !!logs.length &&
      <Paper elevation={0} className={classes.formTable}>
        <LogsTable rows={logs} />
      </Paper>
    }
  </DialogForm>;
}