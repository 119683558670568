import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { TabContainer } from 'styleguide';
import { useSelector } from 'react-redux';

export default function MyApplicationsTabs() {
  const { t } = useTranslation();
  const mobile = useSelector(state => state.global.mobileView);

  return <Box marginBottom={5}>
    <TabContainer size={mobile ? 'small' : 'big'}>
      <NavLink to="/application/my" exact>{t('myApplications.title')}</NavLink>
      <NavLink to="/application/my/plans">{t('myApplications.plans')}</NavLink>
      <NavLink to="/application/add">{t('myApplications.addNew')}</NavLink>
    </TabContainer>
  </Box>;
}