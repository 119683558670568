import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/application';
import StoreUtils from '../../utils/StoreUtils';
import TableUtils from '../../utils/TableUtils';
import { showSuccess } from '../notification';
import { ApplicationDocumentType } from '../../constants/classifierConstants';

const apiPath = (applicationId) => `application/${applicationId}/file`;

// Slice
const slice = createSlice({
  name: 'applicationFile',
  initialState: {
    rows: null,
    requiredTypes: null,
    isLoading: false,
    submitted: false,
    error: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    rowsSuccess: (state, action) => {
      state.rows = action.payload;
      state.isLoading = false;
    },
    insertedSuccess: (state, action) => {
      state.rows = TableUtils.mergeArrayById(state.rows, action.payload);
      state.isLoading = false;
    },
    saveSuccess: (state, action) => {
      state.rows.find(f => f.id === action.payload.id).description = action.payload.description;
      state.rows.find(f => f.id === action.payload.id).documentType = action.payload.documentType;
      state.isLoading = false;
    },
    deletedSuccess: (state, action) => {
      state.rows = state.rows.filter(r => r.id !== action.payload);
      state.isLoading = false;
    },
    resetSuccess: state => {
      state.rows = null;
    },
    requiredTypesSuccess: (state, action) => {
      state.requiredTypes = action.payload;
      state.isLoading = false;
    },
    submittedSuccess: (state, action) => {
      state.submitted = action.payload;
    },
  },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, rowsSuccess, insertedSuccess, deletedSuccess, resetSuccess, saveSuccess,
  requiredTypesSuccess, submittedSuccess } = slice.actions;

export const setFiles = (files) => async dispatch => {
  dispatch(rowsSuccess(files));
};

export const fetchFiles = (applicationId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(apiPath(applicationId)).then((response) => {
      dispatch(rowsSuccess(response.data.fileInfoList));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const addFiles = (applicationId, files, description) => async dispatch => {
  dispatch(startLoading());
  try {
    let formData = new FormData();
    files.forEach(f => formData.append('files', f));
    await api.post(apiPath(applicationId) + 's', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then((response) => {
      dispatch(insertedSuccess(response.data.fileInfoList));
      if (!!description) {
        dispatch(saveFileDescription(applicationId, response.data.fileInfoList[0].id,
          description,
          ApplicationDocumentType.application));
      }
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const saveFileDescription = (applicationId, fileId, description, documentType) => async dispatch => {
  const data = { id: fileId, description, documentType };
  dispatch(saveSuccess(data));
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath(applicationId)}/${fileId}`, data).then((response) => {
      dispatch(saveSuccess(response.data));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const deleteFile = (applicationId, fileId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.delete(`${apiPath(applicationId)}/${fileId}`).then((response) => {
      dispatch(deletedSuccess(fileId));
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const getFileDownloadUrl = (applicationId, fileId) => `${api.defaults.baseURL}/${apiPath(applicationId)}/${fileId}`;

export const resetFile = () => async dispatch => {
  dispatch(resetSuccess());
  dispatch(requiredTypesSuccess());
};

export const fetchRequiredTypes = (applicationId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(apiPath(applicationId) + '/requiredTypes').then((response) => {
      dispatch(requiredTypesSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const addPlanFile = (applicationId, data) => async dispatch => {
  dispatch(startLoading());
  try {
    let formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]));
    await api.post(`application/${applicationId}/planFile`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then((response) => {
      dispatch(insertedSuccess([response.data]));
      dispatch(submittedSuccess(true));
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const deletePlanFile = (applicationId, fileId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.delete(`application/${applicationId}/planFile/${fileId}`).then((response) => {
      dispatch(deletedSuccess(fileId));
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const resetSubmitted = () => async dispatch => {
  dispatch(submittedSuccess(false));
};
