import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/workOrder';
import StoreUtils from '../../utils/StoreUtils';

// Slice
const slice = createSlice({
  name: 'workOrderReport',
  initialState: {
    rows: null,
    isLoading: false,
    error: false
  },
  reducers: {
    startLoading: (state, action) => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    rowsSuccess: (state, action) => {
      state.rows = action.payload;
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, rowsSuccess } = slice.actions;

export const fetchWorkOrderReport = (year) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(`workOrder/report/${year}`).then((response) => {
      dispatch(rowsSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};
