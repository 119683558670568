import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/application';
import StoreUtils from '../../utils/StoreUtils';
import MapUtils from '../../utils/MapUtils';
import { showSuccess } from '../notification';
import { clearFeatures, setClickedFeatures, updateFeature } from '../map/feature';
import { clone } from 'lodash';

const apiPath = 'planningTempObject';

// Slice
const slice = createSlice({
  name: apiPath,
  initialState: {
    rows: {},
    selected: null,
    totalElements: null,
    isLoading: false,
    error: false,
  },
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    rowsSuccess: (state, action) => {
      state.rows[action.payload.type] = action.payload.data;
      state.isLoading = false;
    },
    insertSuccess: (state, action) => {
      const data = action.payload;
      state.rows[data.type].push(data);
      state.isLoading = false;
    },
    updateSuccess: (state, action) => {
      const data = action.payload;
      const rows = state.rows[data.type];
      if(rows) {
        const index = rows.findIndex(g => g.id === data.id);
        if(index) {
          rows[index] = data;
        }
      }
      state.isLoading = false;
    },
    deleteSuccess: (state, action) => {
      const data = action.payload;
      const rows = state.rows[data.type];
      if(rows) {
        const index = rows.findIndex(g => g.id === data.id);
        if(index) {
          rows.splice(index, 1);
        }
      }
      state.isLoading = false;
    }
  },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, rowsSuccess, insertSuccess, updateSuccess, deleteSuccess } = slice.actions;

export const fetchPlanningTempObjects = (type) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(`${apiPath}/${type}`).then((response) => {
      dispatch(rowsSuccess({ data: response.data, type: type }));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const updatePlanningTempFeature = (feature, title, planObjectType) => async dispatch => {
  if(feature) {
    let item = feature.getProperties();
    if(item.id) {
      item.type = planObjectType;
      item.geometry = MapUtils.toGeoJSONGeometry(feature);
      item.procedureType = 'tempObject';

      dispatch(updateSuccess(item));
      dispatch(clearFeatures());
      dispatch(setClickedFeatures([clone(item)], title, planObjectType));
    }
  }
};

export const savePlanningTempFeature = (feature, title, planObjectType, color, noClickPropagation) => async dispatch => {
  if(feature && feature.getGeometry()) {
    MapUtils.stripZFromFeature(feature);
    dispatch(startLoading());
    const item = feature.getProperties();
    item.type = planObjectType;
    if(color) {
      item.color = MapUtils.convertRgbToHex(color);
    }
    item.geometry = MapUtils.toGeoJSONGeometry(feature);

    return dispatch(savePlanningTempObject(item)).then(item => {
      if(item) {
        const clonedItem = clone(item);
        clonedItem.procedureType = 'tempObject';
        if(!noClickPropagation) {
          dispatch(clearFeatures());
          dispatch(setClickedFeatures([clonedItem], title, planObjectType));
        }
      }
    });
  }
};

export const savePlanningTempObject = (item) => async dispatch => {
  try {
    if(item.id) {
      return await api.patch(`${apiPath}/${item.id}`, item).then((response) => {
        response.data.procedureType = 'tempObject';
        dispatch(updateFeature(response.data));
        dispatch(updateSuccess(response.data));
        dispatch(showSuccess('form.saved'));
        return response.data;
      });
    } else {
      return await api.post(apiPath, item).then((response) => {
        dispatch(insertSuccess(response.data));
        dispatch(showSuccess('form.saved'));
        return response.data;
      });
    }
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const deletePlanningTempObject = (item) => async dispatch => {
  if(item && item.id){
    dispatch(startLoading());
    try {
      return await api.delete(`${apiPath}/${item.id}`).then(() => {
        dispatch(deleteSuccess(item));
        dispatch(showSuccess('form.saved'));
      });
    }
    catch (e) {
      dispatch(StoreUtils.handleError(e, hasError));
    }
}
};
