import { Box, Grid, IconButton, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Icon, TableHeader } from 'styleguide';
import ClassifierSelect from '../form/ClassifierSelect';
import VTextField from '../form/VTextField';
import ConfirmButton from '../table/ConfirmButton';
import SimplePageableTable from '../table/SimplePageableTable';
import { ClassifierType, Domain, PlanningOperationType } from '../../constants/classifierConstants';
import { addParticipant, deleteParticipant, fetchOwners, resetSubmitted, setParticipantSelected } from '../../stores/planning/planningParticipant';
import { useHistory } from 'react-router';
import { fetchPartnerUsers } from '../../stores/admin/user';
import ControlledAutocomplete from '../form/ControlledAutocomplete';
import { cloneDeep } from 'lodash';
import { TotalElementsLabel } from '../table/TableHeaderWithCount';
import { showWarning } from '../../stores/notification';
import DateUtils from '../../utils/DateUtils';
import TableUtils from '../../utils/TableUtils';
import ExportCSVButton from '../table/ExportCSVButton';

const addByCode = "addByCode";
const addPartner = "addPartner";

const useStyles = makeStyles((theme) => ({
  addPartyContainer: {
    marginBottom: 72,
    padding: 26
  },
  ownerRow: {
    opacity: 0.5
  },
}));

function AddPartyForm({ type, onConfirm, onClose, isLoading, compact }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { register, control, errors, handleSubmit } = useForm({
    defaultValues: {}
  });

  const { partnerUsers } = useSelector(state => state.user);

  const shouldFetchPartners = type === addPartner && partnerUsers.length === 0;
  useEffect(() => shouldFetchPartners && dispatch(fetchPartnerUsers()), [shouldFetchPartners, dispatch]);

  const filteredUsers = partnerUsers.filter(u => u.isCompany && u.domain === Domain.plan);

  const gridSize = compact ? 12 : 3;

  return <Paper elevation={1} className={classes.addPartyContainer}>
    <form onSubmit={handleSubmit(onConfirm)}>
      <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <Grid item xs={gridSize}>
          {type === addByCode &&
            <VTextField name="code" label="plan.party.code"
              disabled={isLoading} required maxLength={11}
              register={register} errors={errors} fullWidth
            />
          }
          {type === addPartner &&
            <ControlledAutocomplete name="partner" label={t('planning.participant.partner')}
              disabled={isLoading} required
              control={control} errors={errors}
              options={filteredUsers}
              getOptionLabel={(o) => o.title}
              getOptionSelected={(option, value) => option.id === value.id}
            />
          }
        </Grid>
        <Grid item xs={gridSize}>
          <ClassifierSelect item={{}} name="participantType" label="plan.party.applicantType"
            disabled={isLoading} required classifierType={ClassifierType.applicantType}
            control={control} errors={errors}
          />
        </Grid>
        <Grid item>
          <Button type="submit" size="small" aria-label="submit new party" disabled={isLoading}>
            {t('button.save')}
          </Button>
          <Box marginLeft={1} display="inline">
            <Button size="small" color="secondary" onClick={onClose} aria-label="cancel" disabled={isLoading}>
              {t('button.cancelAlt')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  </Paper>;
}
export default function PlanningParticipants({ readOnly, showRestricted }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [addingParty, setAddingParty] = useState(null);
  const [allSelected, setAllSelected] = useState(false);
  const planning = useSelector(state => state.planning.selected);
  const participants = useSelector(state => state.planningParticipant.rows);
  const submitted = useSelector(state => state.planningParticipant.submitted);
  const isLoading = useSelector(state => state.planningParticipant.isLoading);
  const compact = useSelector(state => state.global.mobileView);
  const operations = useSelector(state => state.planningOperation.rows);
  const classifiers = useSelector(state => state.classifier.classifiers);

  const activeOperations = operations?.filter(o => DateUtils.isFutureDate(o.endDate));
  const showSendNotification = activeOperations?.length > 0;
  const coordinationOperation = showSendNotification && activeOperations.find(o => o.operationType === PlanningOperationType.coordination);
  const opinionOperation = showSendNotification && activeOperations.find(o => o.operationType === PlanningOperationType.opinion);

  useEffect(() => submitted && dispatch(resetSubmitted()) && setAddingParty(false), [submitted, dispatch]);

  const planningId = planning.id;

  const columns = useMemo(() => {
    const handleAllSelected = (selected) => {
      dispatch(setParticipantSelected(selected));
      setAllSelected(selected);
    };

    const handleEdit = (event, id) => {
      event.stopPropagation()
      history.push(`editParticipant/${id}`);
    };

    const nameColumn = { field: 'fullName', headerName: t('plan.party.name'), filter: true, };
    let c = !showRestricted ? [
      nameColumn,
      {
        field: "participantType", headerName: t('plan.party.applicantType'), filter: true,
        type: "classifier", classifierType: ClassifierType.applicantType,
      }
    ] : [
      { field: 'code', headerName: t('plan.party.code'), filter: true },
      nameColumn,
      { field: 'email', headerName: t('plan.party.email'), filter: true, renderCell: (column, row) => row.email?.replace(/;/g, '; ') },
      { field: 'phone', headerName: t('plan.party.phone'), filter: true },
      { field: 'address', headerName: t('plan.party.address'), filter: true },
      {
        field: "participantType", headerName: t('plan.party.applicantType'), filter: true,
        type: "classifier", classifierType: ClassifierType.applicantType, renderCell: (column, row) => {
          let type = TableUtils.getClassifierTitle(classifiers, ClassifierType.applicantType, row.participantType);
          if (row.partnerId) {
            type += ` (${t('planning.participant.partner')})`;
          } else if (!row.isPlan) {
            type += ` (${t('planning.participant.application')})`;
          }
          return type;
        }
      },
      { field: 'cadastres', headerName: t('plan.party.cadastre'), filter: true },
    ];

    if (!readOnly) {
      c.unshift({
        field: 'selected',
        renderHeader: (column) => <Checkbox onChange={(e) => handleAllSelected(e.target.checked)} checked={allSelected} />,
        renderCell: (column, row) =>
          <Checkbox
            onChange={(e) => dispatch(setParticipantSelected(e.target.checked, row))}
            checked={row.isSelected || false}
            disabled={!row.email}
          />,
      });
      c.push({
        field: 'action', width: 135, renderCell: (column, cell) => !!cell.id && <Box textAlign="right">
          {!cell.partnerId &&
            <IconButton onClick={(e) => handleEdit(e, cell.id)} aria-label="edit document" title={t('planning.document.editButton')}>
              <Icon icon="pencil" />
            </IconButton>
          }
          {!!cell.isPlan &&
            <ConfirmButton
              message={t('form.confirmDelete')} icon="delete"
              onConfirm={() => dispatch(deleteParticipant(planningId, cell.id))}
              aria-label="delete file"
            />
          }
        </Box>
      })
    }
    return c;
  }, [t, planningId, dispatch, readOnly, allSelected, history, showRestricted, classifiers]);

  const handleAskOpinion = () => {
    history.push(`sendNotification/${opinionOperation.id}`);
  };

  const handleSendCoordination = () => {
    const selectedParticipants = participants.filter(p => p.isSelected);
    if (selectedParticipants.some(p => p.isPhysical || !p.partnerId)) {
      dispatch(showWarning('planning.notification.sendCoordinationOnlyPartner'));
      return;
    }
    history.push(`sendNotification/${coordinationOperation.id}`);
  };

  const handleSendNotifications = () => {
    history.push(`sendNotification`);
  };

  const convertToCsvData = async () => {
    return participants.map(p => ({
      ...p,
      email: columns.find(c => c.field === 'email').renderCell(null, p),
      participantType: columns.find(c => c.field === 'participantType').renderCell(null, p)
    }));
  };

  const isParticipantSelected = participants?.some(p => p.isSelected);
  const tableButtons = !readOnly && <>
    <Button
      size="extra-small"
      onClick={() => setAddingParty(addByCode)}
      disabled={isLoading || !!addingParty}
    >
      {t('planning.participant.add')}
    </Button>
    <Button
      size="extra-small"
      onClick={() => setAddingParty(addPartner)}
      disabled={isLoading || !!addingParty}
    >
      {t('planning.participant.addPartner')}
    </Button>
    <Button
      size="extra-small"
      color="secondary"
      onClick={() => dispatch(fetchOwners(planningId))}
      disabled={isLoading || !!addingParty}
    >
      {t('plan.party.findOwners')}
    </Button>
    {!!coordinationOperation &&
      <Button
        size="extra-small" color="secondary"
        onClick={handleSendCoordination}
        disabled={isLoading || !isParticipantSelected}
      >
        {t('planning.action.sendCoordination')}
      </Button>
    }
    {!!opinionOperation &&
      <Button
        size="extra-small" color="secondary"
        onClick={handleAskOpinion}
        disabled={isLoading || !isParticipantSelected}
      >
        {t('planning.action.askOpinion')}
      </Button>
    }
    {showSendNotification &&
      <Button
        size="extra-small"
        color="secondary"
        onClick={handleSendNotifications}
        disabled={isLoading || !isParticipantSelected}
      >
        {t('plan.notification.send')}
      </Button>
    }
    <ExportCSVButton
      handleFetchData={convertToCsvData}
      includeRenderCellColumns
      columns={columns}
      filename={t('plan.view.parties')}
    />
  </>;

  const handleAddParty = (inputData) => {
    let data = cloneDeep(inputData);
    if (addingParty === addPartner) {
      data.partnerId = data.partner.id;
      data.partner = undefined;
    }
    dispatch(addParticipant(planningId, data)).then((participant) => {
      if (participant) {
        setAddingParty(null);
      }
    });
  };

  const showTable = !!participants && (!compact || !addingParty);

  return <div>
    {!!addingParty && <AddPartyForm isLoading={isLoading} type={addingParty}
      onConfirm={handleAddParty} onClose={() => setAddingParty(null)} compact={compact} />}

    {showTable && <SimplePageableTable
      rows={participants}
      columns={columns}
      header={<TableHeader buttons={tableButtons}>
        {t('planning.view.participants')}
        <TotalElementsLabel>{participants?.length}</TotalElementsLabel>
      </TableHeader>}
      sort={{ field: 'name', ascending: true }}
      pageSize={25}
      rowClassNameFunction={(row) => !row?.id && classes.ownerRow}
      onRowSelected={(row) => dispatch(setParticipantSelected(!row.isSelected, row))}
    />}
  </div>;
}