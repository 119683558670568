import React from 'react';
import { BasicLabel } from 'styleguide';
import { WorkOrderStatus } from '../../constants/classifierConstants';

export default function WorkOrderStatusLabel({ statusClassifier }) {
  if (!statusClassifier) {
    return <span></span>;
  }

  let color = '';
  switch (statusClassifier.itemCode) {
    case WorkOrderStatus.takenForFulfillment:
      color = 'blue';
      break;
    case WorkOrderStatus.editing:
      color = 'orange';
      break;
    case WorkOrderStatus.fulfilled:
    case WorkOrderStatus.completed:
      color = 'green';
      break;
    case WorkOrderStatus.deleted:
    case WorkOrderStatus.invalid:
      color = 'red';
      break;
    case WorkOrderStatus.entered:
    default:
      color = 'grey';
  }

  return <BasicLabel color={color}>{statusClassifier.title}</BasicLabel>;
}
