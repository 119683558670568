import React from 'react';
import Layers from './layers/Layers';
import Controls from './controls/Controls';
import Map from './Map';
import TMSLayer from './layers/TMSLayer';
import ZoomControl from './controls/ZoomControl';
import ScaleLineControl from './controls/ScaleLineControl';
import MousePositionControl from './controls/MousePositionControl';
import LayerSwitcherControl from './controls/LayerSwitcherControl';
import LayerDrawer from './LayerDrawer';
import { useSelector } from 'react-redux';
import FeaturesDialog from './FeaturesDialog';
import ImageWMSLayer from './layers/ImageWMSLayer';
import SelectFeatureControl from './controls/SelectFeatureControl';
import ResetControl from './controls/ResetControl';
import ScaleSelectControl from './controls/ScaleSelectControl';
import SearchControl from './controls/SearchControl';
import CopyCoordinatesControl from './controls/CopyCoordinatesControl';
import SelectAreaControl from './controls/SelectAreaControl';
import ActiveFeaturesLayer from './layers/ActiveFeaturesLayer';
import HighlightFeaturesLayer from './layers/HighlightFeaturesLayer';
import { makeStyles } from '@material-ui/core';
import { getYear } from 'date-fns';
import WMTSLayer from './layers/WMTSLayer';
import Cad2GeoJsonSelectDialog from './Cad2GeoJsonSelectDialog';
import PlanDrawingLayer from './layers/PlanDrawingLayer';

const useStyles = makeStyles({
  mapFooterTitle: {
    position: 'absolute',
    width: '110px',
    left: 'calc(50% - 55px)',
    bottom: 0,
    textShadow: '-1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff',
  }
});

const currentYear = getYear(new Date());

export default function DefaultMap({ extraControls, isDialog }) {
  const { layers, groupedWMSLayers, center, zoom, extent } = useSelector(state => state.map);
  const mobileView = useSelector(state => state.global.mobileView);
  const classes = useStyles();

  const controls = [
    <SelectFeatureControl />,
    <LayerSwitcherControl />,
    <ResetControl />,
    <SearchControl />,
  ];
  if (!mobileView) {
    controls.push(
      <ZoomControl />,
      <ScaleSelectControl />,
      <SelectAreaControl />,
      <CopyCoordinatesControl />,
      <ScaleLineControl />,
      <MousePositionControl />,
    );
  }
  if (extraControls?.length) {
    controls.push(...extraControls);
  }

  return <>
    <Map center={center} zoom={zoom} extent={extent}>
      <Layers>
        {layers.filter(l => l.visible && l.serviceType === 'TMS').map((layer, index) => (
          <TMSLayer key={index} url={layer.serverUrl} zIndex={layer.zIndex} queryable={false} />
        ))}
        {layers.filter(l => l.visible && l.serviceType === 'WMTS').map((layer, index) => (
          <WMTSLayer 
            key={index} 
            url={layer.serverUrl} 
            layer={layer.layerName}
            zIndex={layer.zIndex} 
            queryable={layer.queryable} 
            title={layer.title}
            styles={layer.style}
            infoFormat={layer.infoFormat || 'application/json'}
            proxyUrl={layer.proxyUrl}
            authUser={layer.proxyUser}
            authPass={layer.proxyPass}
          />
        ))}
        {groupedWMSLayers.map((layer, index) => (
          <ImageWMSLayer
            key={index}
            url={layer.serverUrl}
            layers={layer.layerName}
            zIndex={layer.zIndex}
            queryable={layer.queryable}
            title={layer.title}
            layerLayerName={layer.layerLayerName}
            styles={layer.style}
            geometryName={layer.geometryName}
            groupedLayers={layer.groupedLayers}
            infoFormat={layer.infoFormat}
          />
        ))}
        {layers.filter(l => l.visible && l.serviceType === 'VECTOR').map((layer, index) => (
          <PlanDrawingLayer 
            key={index} 
            type={layer.layerName} 
            zIndex={layer.zIndex} 
            queryable={layer.queryable} 
            title={layer.title}
          />
        ))}
        <ActiveFeaturesLayer />
        <HighlightFeaturesLayer />
      </Layers>
      <Controls>
        {controls.map((control, index) => <React.Fragment key={index}>{control}</React.Fragment>)}
      </Controls>
    </Map>
    <LayerDrawer isDialog={isDialog} />
    {!isDialog && <FeaturesDialog />}
    <Cad2GeoJsonSelectDialog/>
    <div className={classes.mapFooterTitle}>Maa-amet {currentYear}</div>
  </>;
}