"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Shadows = void 0;

/**
 * @visibleName Shadows
 */
var Shadows = {
  shadow1: '0px 0px 5px rgba(0, 0, 0, 0.05), 0px 25px 30px rgba(0, 0, 0, 0.03)',
  shadow2: '0px 2px 5px rgba(0, 0, 0, 0.12)'
};
exports.Shadows = Shadows;
var _default = Shadows;
exports.default = _default;