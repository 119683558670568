import { IconButton, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Shadows, TableHeader } from 'styleguide';
import { addDrawingGeometryToQueue, setHighlightGeometries, toggleFeaturesDialog } from '../../stores/map/feature';
import RegisterUtils from '../../utils/RegisterUtils';
import { PageableTableContainer } from '../table/PageableTable';
import clsx from 'clsx';
import MapUtils from '../../utils/MapUtils';
import ProcedureUtils from '../../utils/ProcedureUtils';
import AuthUtils from '../../utils/AuthUtils';
import { Authority } from '../../constants/authConstants';
import StopRoundedIcon from '@material-ui/icons/StopRounded';

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxHeight: '30%',
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    overflow: 'auto',
    position: 'absolute',
    bottom: 24,
    left: '50%',
    transform: 'translateX(-50%)',
    boxShadow: Shadows.shadow2
  },
  dialogWithLayerDrawer: {
    marginLeft: 200
  },
  typeTabs: {
    "& button": {
      marginRight: 16
    }
  }
}));

const handleRowSelection = (history, selected, authUser) => {
  if (history.location.pathname.startsWith('/map')) {
    if (selected.registerObjectType) {
      if (selected.url && (selected.registerObjectType === 'streetU' || selected.registerObjectType === 'eyeVi')) {
        try {
          window.open(selected.url, '_blank', 'noopener,noreferrer');
        } catch (e) { }
      } else {
        history.replace({
          pathname: `/map/f/r/view/${selected.registerObjectType}/${selected.id}`,
          state: { mapView: true }
        });
      }
    } else {
      switch(selected.procedureType) {
        case 'planningInitiated':
        case 'planningAccepted':
          history.replace(`/map/f/r/viewPlanning/${selected.procedureType}/${selected.id}`);
          break;
        case 'clause':
          history.replace(`/map/f/r/viewClause/${selected.id}`);
          break;
        case 'tempObject': 
          history.replace({
            pathname: AuthUtils.hasAuthority(authUser, Authority.UC41_edit_drawing_layer) ? '/map/f/p/edit' : '/map/f/p/view',
            state: { data: selected }
          });
          break;
        default:
          history.replace({
            pathname: `/map/f/g/view`,
            state: { data: selected }
          });
      }
    } 
  }
};

const handleClose = () => async dispatch => {
  dispatch(toggleFeaturesDialog(false));
};

export default function FeaturesDialog() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const classes = useStyles();

  const { featuresDialogOpen, objectTypes, objects, highlightFeatures } = useSelector(state => state.feature);
  const { layerDrawerOpen } = useSelector(state => state.map);
  const { authUser } = useSelector(state => state.auth);
  const [objectType, setObjectType] = useState();
  const history = useHistory();

  useEffect(() => objectTypes.length && (!objectType || !objectTypes.includes(objectType)) && setObjectType(objectTypes[0]), [objectType, objectTypes]);
  useEffect(() => !featuresDialogOpen && objects.length === 1 && handleRowSelection(history, objects[0], authUser), [objects, featuresDialogOpen, history, dispatch, authUser]);
  useEffect(() => featuresDialogOpen && objects.length !== 1 && history.replace('/map'), [objects, featuresDialogOpen, history, dispatch]);

  if (!featuresDialogOpen || !objectType) {
    return <></>;
  }

  const typeHeadings = objectTypes.map(ot => {
    if (ot.type === 'register') {
      return { code: ot.code, title: RegisterUtils.getObjectDef(ot.code)?.title || t(`register.${ot.code}.label`) };
    } else if (ot.type === 'procedure') {
      return { code: ot.code, title: ProcedureUtils.getObjectDef(ot.code)?.title || t(`procedure.${ot.code}.label`) };
    } else {
      return { code: ot.title, title: ot.title };
    }
  }).sort((a, b) => a.title.localeCompare(b.title));

  const header = () => (
    <TableHeader buttons={<IconButton edge="end" onClick={() => dispatch(handleClose())}><Icon icon="times" /></IconButton>}>
      <div className={classes.typeTabs}>
        {typeHeadings.map((type, index) => (
          <Button key={index} size="extra-small" color={type.code === objectType.code ? 'secondary' : 'tertiary'} onClick={() => setObjectType(objectTypes.find(ot => ot.code === type.code))}>{type.title}</Button>
        ))}
      </div>
    </TableHeader>
  );

  const handleMouseEnter = (row) => {
    if (row.geometry) {
      dispatch(setHighlightGeometries([row.geometry]));
    }
  };
  const handleMouseLeave = () => {
    if (highlightFeatures.length) {
      dispatch(setHighlightGeometries([]));
    }
  };

  const handleAddToDrawingLayer = (event, geometry) => {
    event.stopPropagation();
    dispatch(addDrawingGeometryToQueue(geometry));
  };

  let rows;
  let columns;
  switch (objectType.type) {
    case 'register':
      columns = RegisterUtils.getRegisterColumns(objectType.code, null, true);
      rows = objects.filter(f => f.registerObjectType === objectType.code);
      break;
    case 'procedure':
      columns = ProcedureUtils.getProcedureColumns(objectType.code);
      rows = objects.filter(f => f.procedureType === objectType.code);
      break;
    case 'json':
      rows = objects.filter(f => f.mapLayerTitle === objectType.title);
      if (rows.length) {
        columns = Object.keys(rows[0])
          .filter(key => key !== 'mapLayerTitle' && key !== 'mapLayerLayerName' && key !== 'geometry' && key !== 'id')
          .map(key => ({ field: key, headerName: MapUtils.prettyfyKey(key) }))
          .splice(0, 5);
      }
      break;
    case 'tempObject':
      rows = objects.filter(f => f.mapLayerTitle === objectType.title);
      if (rows.length) {
        columns = [
          { field: 'name', headerName: t('planning.temp.name') },
          { field: 'description', headerName: t('planning.temp.description') },
          { 
            field: 'color', 
            headerName: t('planning.temp.color'),
            renderCell: (column, row) => <StopRoundedIcon style={{ color: row.color, verticalAlign: 'bottom'  }} fontSize='large'/>,
          },
        ];
      }
      break;
    default:
  }
  if(columns && AuthUtils.hasAuthority(authUser, Authority.UC40_object_add_drawing)) {
    columns.push({
      field: 'action', 
      width: 50, 
      renderCell: (column, row) => {
        return !!row.geometry && 
          <IconButton size="small" onClick={(e) => handleAddToDrawingLayer(e, row.geometry)} title={t('button.addToDrawingLayer')}>
            <Icon icon="pencil"/>
          </IconButton>
      }
    });
  }

  let dialogClass = classes.dialog;
  if (layerDrawerOpen) {
    dialogClass = clsx(classes.dialog, classes.dialogWithLayerDrawer);
  }

  return (
    <Paper className={dialogClass}>
      {!!rows.length && !!columns.length &&
        <PageableTableContainer
          rows={rows}
          columns={columns}
          totalRows={rows.length}
          header={header()}
          onRowMouseEnter={handleMouseEnter}
          onRowMouseLeave={handleMouseLeave}
          onRowSelected={(selected) => handleRowSelection(history, selected, authUser)}
          size="small"
        />}
    </Paper>
  );
}