import { FormControlLabel, Grid, IconButton, makeStyles, Paper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { Checkbox, Icon, VChip, Heading, Colors, Label, Button } from 'styleguide';
import { ApplicationType, ClassifierType, Domain } from '../../constants/classifierConstants';
import { add, addDocumentType, addGroup, addPurpose, fetchRows, removeDocumentType, removeGroup, removePurpose, save, saveGroup, savePurpose } from '../../stores/admin/applicationType';
import ClassifierSelect from '../form/ClassifierSelect';
import DialogForm from '../form/DialogForm';
import VTextField from '../form/VTextField';
import ClassifierLabel from '../form/ClassifierLabel';
import ConfirmButton from '../table/ConfirmButton';
import { cloneDeep } from 'lodash';
import TableUtils from '../../utils/TableUtils';
import GovUserSelect from '../form/GovUserSelect';

const useStyles = makeStyles({
  formBlock: {
    maxWidth: 846,
    margin: 'auto',
    padding: 25
  },
  purposeDiv: {
    paddingTop: 10,
    paddingBottom: 10
  },
  purposeRow: {
    padding: 15,
    backgroundColor: Colors.hall3,
    marginTop: 5
  },
  purposeNumber: {
    paddingRight: 15
  },
  groupNumber: {
    width: 62,
    height: 62,
    borderRadius: '50%',
    textAlign: 'center',
    lineHeight: '62px',
    backgroundColor: Colors.hall3,
    marginRight: 16,
  },
  buttons: {
    marginLeft: 'auto'
  }
});

function PurposeCheckbox({ purpose, field, disabled, onChange }) {
  const { t } = useTranslation();

  return <FormControlLabel
    control={<Checkbox checked={purpose[field] || false} disabled={disabled} onChange={(event) => onChange(field, event.target.checked)} />}
    label={<Label>{t(`admin.applicationType.purpose.${field}`)}</Label>}
  />;
}

function Purpose({ group, purpose, isLoading }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleDelete = () => {
    dispatch(removePurpose(group, purpose))
  };

  const handleQueueChange = (oldQueueNumber, newQueueNumber) => {
    let replacedPurpose = group.purposes.find(p => p.queueNumberInGroup === newQueueNumber);
    if (replacedPurpose) {
      replacedPurpose = cloneDeep(replacedPurpose);
      replacedPurpose.queueNumberInGroup = oldQueueNumber;
      dispatch(savePurpose(group, replacedPurpose));
    }
  }

  const handleMoveUp = () => {
    if (purpose.queueNumberInGroup > 1) {
      let modified = cloneDeep(purpose);
      modified.queueNumberInGroup--;
      handleQueueChange(purpose.queueNumberInGroup, modified.queueNumberInGroup);
      dispatch(savePurpose(group, modified));
    }
  };

  const handleMoveDown = () => {
    let modified = cloneDeep(purpose);
    modified.queueNumberInGroup++;
    handleQueueChange(purpose.queueNumberInGroup, modified.queueNumberInGroup);
    dispatch(savePurpose(group, modified));
  };

  const handleCheckboxChange = (field, value) => {
    let modified = cloneDeep(purpose);
    modified[field] = value;
    dispatch(savePurpose(group, modified));
  };

  return <div className={classes.purposeRow}>
    <Heading level='4'>
      <span className={classes.purposeNumber}>{purpose.queueNumberInGroup}</span>
      <ClassifierLabel code={purpose.purposeType} classifierType={ClassifierType.purposeType} />
      <span className={classes.buttons}>
        <IconButton aria-label="move up" onClick={handleMoveUp} disabled={isLoading}><Icon icon="arrowUp" /></IconButton>
        <IconButton aria-label="move down" onClick={handleMoveDown} disabled={isLoading}><Icon icon="arrowDown" /></IconButton>
        <ConfirmButton message={t('form.confirmDelete')} icon="delete" onConfirm={handleDelete} aria-label="delete group" disabled={isLoading} />
      </span>
    </Heading>
    <PurposeCheckbox purpose={purpose} field="isCalculatedNumber" disabled={isLoading} onChange={handleCheckboxChange} />
    <PurposeCheckbox purpose={purpose} field="isEnteredNumber" disabled={isLoading} onChange={handleCheckboxChange} />
    <PurposeCheckbox purpose={purpose} field="isMarked" disabled={isLoading} onChange={handleCheckboxChange} />
    <PurposeCheckbox purpose={purpose} field="isFreeText" disabled={isLoading} onChange={handleCheckboxChange} />
  </div>;
}

function Groups({ appType, groups, isLoading }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { control, errors } = useForm();

  const handleAddNew = (purposeGroup) => {
    const group = {
      purposeGroup: purposeGroup,
      queueNumber: groups.length ? Math.max.apply(Math, groups.map(g => g.queueNumber)) + 1 : 1
    };
    dispatch(addGroup(appType, group));
  };

  const handleDelete = (group) => {
    dispatch(removeGroup(group))
  };

  const handleQueueChange = (oldQueueNumber, newQueueNumber) => {
    let replacedGroup = groups.find(g => g.queueNumber === newQueueNumber);
    if (replacedGroup) {
      replacedGroup = cloneDeep(replacedGroup);
      replacedGroup.queueNumber = oldQueueNumber;
      dispatch(saveGroup(replacedGroup));
    }
  }

  const handleMoveUp = (group) => {
    if (group.queueNumber > 1) {
      let modified = cloneDeep(group);
      modified.queueNumber--;
      handleQueueChange(group.queueNumber, modified.queueNumber);
      dispatch(saveGroup(modified));
    }
  };

  const handleMoveDown = (group) => {
    let modified = cloneDeep(group);
    modified.queueNumber++;
    handleQueueChange(group.queueNumber, modified.queueNumber);
    dispatch(saveGroup(modified));
  };

  const handleAddNewPurpose = (group, purposeType) => {
    const purpose = {
      purposeType: purposeType,
      queueNumberInGroup: group.purposes.length ? Math.max.apply(Math, group.purposes.map(p => p.queueNumberInGroup)) + 1 : 1
    };
    dispatch(addPurpose(group, purpose));
  };

  return <div>
    {TableUtils.sortArrayByNumber(groups, 'queueNumber').map((group, index) => (
      <div key={index}>
        <Heading level='3'>
          <span className={classes.groupNumber}>{group.queueNumber}</span>
          <ClassifierLabel code={group.purposeGroup} classifierType={ClassifierType.purposeGroup} />
          <span className={classes.buttons}>
            <IconButton aria-label="move up" onClick={() => handleMoveUp(group)} disabled={isLoading}><Icon icon="arrowUp" /></IconButton>
            <IconButton aria-label="move down" onClick={() => handleMoveDown(group)} disabled={isLoading}><Icon icon="arrowDown" /></IconButton>
            <ConfirmButton message={t('form.confirmDelete')} icon="delete" onConfirm={() => handleDelete(group)} aria-label="delete group" />
          </span>
        </Heading>
        <div className={classes.purposeDiv}>
          {TableUtils.sortArrayByNumber(group.purposes, 'queueNumberInGroup').map((purpose, purposeIndex) => (
            <Purpose key={purposeIndex} group={group} purpose={purpose} isLoading={isLoading} />
          ))}
          <div className={classes.purposeRow}>
            <ClassifierSelect item={{}} name="purposeType" label="admin.applicationType.purpose.add"
              disabled={isLoading} classifierType={ClassifierType.purposeType} control={control} errors={errors}
              onChange={(event) => handleAddNewPurpose(group, event.target.value)} displayClearValue={false}
            />
          </div>
        </div>
      </div>
    ))}
    <div>
      <ClassifierSelect item={{}} name="purposeGroup" label="admin.applicationType.group.add"
        disabled={isLoading} classifierType={ClassifierType.purposeGroup} control={control} errors={errors}
        onChange={(event) => handleAddNew(event.target.value)} displayClearValue={false}
      />
    </div>
  </div>;
}

export default function ApplicationTypeForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const history = useHistory();
  const { selected, isLoading, formSaved } = useSelector(state => state.applicationType);
  const { classifiers } = useSelector(state => state.classifier);

  const [documentTypes, setDocumentTypes] = useState([]);
  const [documentTypeClassifiers, setDocumentTypeClassifiers] = useState([]);

  const { register, control, errors, handleSubmit, reset } = useForm({
    defaultValues: selected
  });
  const classes = useStyles();

  useEffect(() => reset(selected), [selected, reset]);
  useEffect(() => formSaved && dispatch(fetchRows()) && history.goBack(), [dispatch, formSaved, history]);
  useEffect(() =>
    setDocumentTypes(documentTypeClassifiers.filter(classifier => selected.documentTypes?.some(dt => dt.documentType === classifier.itemCode)))
    , [documentTypeClassifiers, selected, setDocumentTypes]);

  useEffect(() => setDocumentTypeClassifiers(classifiers?.filter(c => c.typeCode === ClassifierType.applicationDocumentType)), [classifiers]);

  if (!selected?.id) {
    return <Redirect to="/admin/applicationTypes" />;
  }

  const onSubmit = (data) => {
    if (data.id) {
      dispatch(save(data));
    } else {
      data.id = undefined;
      dispatch(add(data));
    }
  };

  const onClose = () => {
    dispatch(fetchRows());
    return false;
  };

  const handleDocumentTypeChange = (value, reason) => {
    switch (reason) {
      case 'select-option':
        const newItem = value.find(c => !documentTypes.includes(c));
        !!newItem && dispatch(addDocumentType(selected.id, newItem.itemCode));
        break;
      case 'remove-option':
        const deleteItem = selected.documentTypes.find(c => value.length === 0 || !value.some(v => v.itemCode === c.documentType));
        !!deleteItem && dispatch(removeDocumentType(deleteItem.id));
        break;
      default:
        return;
    }
    setDocumentTypes(value);
  };

  const handleEditClause = () => {
    history.push(`/admin/clauseConf/${selected.applicationType}`);
  };

  const title = selected.id ?
    t('admin.applicationType.form.label', { code: selected.applicationType }) :
    t('admin.applicationType.form.labelNew');

  return <DialogForm
    title={title}
    onSubmit={handleSubmit(onSubmit)}
    onClose={onClose}
    actions={selected.domain !== Domain.plan && <Button onClick={handleEditClause} disabled={isLoading} color="secondary">
      {t('admin.clauseConf.editButton')}
    </Button>}
    disabled={isLoading}>
    <input type="hidden" name="id" ref={register} />
    <Paper elevation={0} className={classes.formBlock}>
      <Grid container direction="row" spacing={4} justifyContent="space-evenly" alignItems="baseline">
        <Grid container item lg={6} direction="column" spacing={4} alignItems="flex-start">
          <Grid item sm={12}>
            <ClassifierSelect item={selected} name="domain" label="admin.applicationType.domain" required fullWidth
              disabled={isLoading || !!selected.id} control={control} errors={errors} classifierType={ClassifierType.domain}
            />
          </Grid>
          <Grid item sm={12}>
            <ClassifierSelect item={selected} name="applicationType" label="admin.applicationType.title" required fullWidth
              disabled={isLoading || !!selected.id} control={control} errors={errors} classifierType={ClassifierType.applicationType}
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="beCompletedDeadline" label="admin.applicationType.beCompletedDeadline"
              disabled={isLoading} pattern={/^[0-9]+$/}
              register={register} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="reviewDeadline" label="admin.applicationType.reviewDeadline"
              disabled={isLoading} pattern={/^[0-9]+$/}
              register={register} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="deadline" label="admin.applicationType.deadline"
              disabled={isLoading} pattern={/^[0-9]+$/}
              register={register} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <VTextField name="coordinationDeadline" label="admin.applicationType.coordinationDeadline"
              disabled={isLoading} pattern={/^[0-9]+$/}
              register={register} errors={errors} fullWidth
            />
          </Grid>
          {ApplicationType.TVTT === selected.applicationType &&
            <Grid item sm={12}>
              <VTextField name="termsDeadline" label="admin.applicationType.termsDeadline"
                disabled={isLoading} pattern={/^[0-9]+$/}
                register={register} errors={errors} fullWidth
              />
            </Grid>
          }
        </Grid>
        <Grid container item lg={6} direction="column" spacing={4} alignItems="flex-end">
          <Grid item sm={12}>
            <VTextField name="kovNotificationEmail" label="admin.applicationType.kovNotificationEmail"
              disabled={isLoading} maxLength={100}
              pattern="email"
              register={register} errors={errors} fullWidth
            />
          </Grid>
          <Grid item sm={12}>
            <GovUserSelect name="user" label={t('admin.applicationType.user')}
              control={control} disabled={isLoading} errors={errors} />
          </Grid>
          <Grid item sm={12}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              options={documentTypeClassifiers}
              getOptionLabel={(o) => o.title}
              closeIcon={<Icon icon="arrowUp" />}
              popupIcon={<Icon icon="arrowDown" />}
              disableClearable
              getOptionSelected={(option, value) => {
                return option.itemCode === value.itemCode;
              }}
              disabled={isLoading}
              renderInput={(params) =>
                <VTextField {...params} label="admin.applicationType.documentTypes"
                  errors={errors} fullWidth
                />
              }
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <VChip label={option.title}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderOption={(option, { selected }) => (
                <FormControlLabel
                  control={<Checkbox checked={selected} />}
                  label={option.title}
                />
              )}
              onChange={(event, value, reason) => handleDocumentTypeChange(value, reason)}
              value={documentTypes}
            />
          </Grid>
        </Grid>
        <Grid container item lg={12} direction="column" spacing={4}>
          <Grid item sm={12}>
            <Groups appType={selected} groups={selected.groups} isLoading={isLoading} />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  </DialogForm>;
}