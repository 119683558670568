import { makeStyles, Toolbar } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Paragraph } from 'styleguide';
import LoadingIndicator from '../../form/LoadingIndicator';
import ToolbarButton from '../../table/ToolbarButton';
import ClauseFeatureDetails from './ClauseFeatureDetails';
import { fetchClauseById } from '../../../stores/application/clause';
import ProcedureUtils from '../../../utils/ProcedureUtils';

const useStyles = makeStyles((theme) => ({
  flex: {
    flexGrow: 1
  },
  header: {
    paddingLeft: 16
  },
  toolbar: {
    padding: 0
  }
}));

export default function ClauseFeatureView({ mapView }) {
  const { id } = useParams();
  const { path } = useRouteMatch();
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const objectType = 'clause';
  const { clause } = useSelector(state => state.clause);
  const compact = useSelector(state => state.global.mobileView) || mapView;

  const objectDef = ProcedureUtils.getObjectDef(objectType);

  useEffect(() => objectDef && (!clause || Number(id) !== clause.id) && dispatch(fetchClauseById(id)), [dispatch, id, clause, objectDef]);

  useEffect(() => {
    if (clause && !!clause.deleted) {
      if (mapView) {
        history.replace(`/map`);
      } else {
        history.goBack();
      }
    }
  }, [clause, history, mapView]);

  const handleOpenClause = () => {
    history.push(`/application/view/${clause.applicationId}`);
  };

  if (!id || !objectType || !clause || !objectDef) {
    return <LoadingIndicator />;
  }

  return <div>
    <Toolbar className={classes.toolbar}>
      <span className={classes.header}>
        <Paragraph fontSize={mapView ? '14' : '14B'}>{t(`procedure.${objectType}.label`)}</Paragraph>
      </span>
      <span className={classes.flex}></span>
      {mapView && clause.applicationId && <ToolbarButton compact={compact} onClick={handleOpenClause} icon="register" label={t('button.openClause')} />}
    </Toolbar>
    <Route path={`${path}`} render={() => <ClauseFeatureDetails compact={compact} mapView={mapView} />} exact />
  </div>;
}
