import { createSlice } from '@reduxjs/toolkit';
import StoreUtils from '../utils/StoreUtils';
import TableUtils from '../utils/TableUtils';
import { api } from "../api/admin";

const initialPageable = {
  pageSize: TableUtils.getPageSize(178),
  pageNumber: 0,
  sort: {
    field: "created",
    ascending: false
  },
  loaded: false,
  last: true
};

// Slice
const slice = createSlice({
  name: 'activityLog',
  initialState: {
    rows: [],
    objectTypesList: [],
    pageable: initialPageable,
    totalElements: null,
    filter: {},
    isLoading: false,
    error: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    rowsSuccess: (state, action) => {
      if (action.payload.pageable.pageNumber === 0) {
        state.rows = action.payload.content;
      } else {
        state.rows = TableUtils.mergeArrayById(state.rows, action.payload.content);
      }
      state.pageable.last = action.payload.last;
      state.totalElements = action.payload.totalElements;
      state.pageable.loaded = true;
      state.isLoading = false;
    },
    resetRowsSuccess: (state) => {
      state.pageable.pageNumber = 0;
      state.pageable.loaded = false;
      state.rows = [];
    },
    pageableSuccess: (state, action) => {
      if (action.payload.pageNumber) {
        state.pageable.pageNumber += action.payload.pageNumber;
      }
      if (action.payload.sort) {
        state.pageable.sort = action.payload.sort;
        state.pageable.pageNumber = 0;
        state.rows = [];
      }
      state.pageable.loaded = false;
    },
    filterSuccess: (state, action) => {
      if ((state.filter[action.payload.field] || '') !== action.payload.value) {
        state.filter[action.payload.field] = action.payload.value;
        state.pageable.pageNumber = 0;
        state.rows = [];
        state.pageable.loaded = false;
      }
    },
    setFilterSuccess: (state, action) => {
      state.filter = action.payload;
      state.pageable.pageNumber = 0;
      state.rows = [];
      state.pageable.loaded = false;
    },
    objectTypesListSuccess: (state, action) => {
      state.objectTypesList = action.payload;
      state.isLoading = false;
    }
  },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, rowsSuccess, pageableSuccess,
  filterSuccess, setFilterSuccess, resetRowsSuccess, objectTypesListSuccess } = slice.actions;

export const updatePageable = (pageNumber, sort) => async dispatch => {
  dispatch(pageableSuccess({ pageNumber: pageNumber, sort: sort }));
};

export const updateFilter = (field, value) => async dispatch => {
  dispatch(filterSuccess({ field, value }));
};

export const setFilter = (filter) => async dispatch => {
  dispatch(setFilterSuccess(filter));
};

export const fetchRows = (api, url, pageable, filter, columns) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(`${url}?${TableUtils.pageableToParams(pageable, {}, columns)}`).then((response) => {
      dispatch(rowsSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchRowsAdmin = (pageable, filter, columns) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(`activityLog?${TableUtils.pageableToParams(pageable, filter, columns)}`).then((response) => {
      dispatch(rowsSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchObjectTypesAdmin = () => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get('activityLog/objectTypes?').then((response) => {
      dispatch(objectTypesListSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const resetRows = () => async dispatch => {
  dispatch(resetRowsSuccess());
};