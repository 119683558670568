import { Box, Grid } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Icon, TableHeader, Tooltip } from 'styleguide';
import { ClassifierType } from '../../constants/classifierConstants';
import { fetchRows, getFulfillerTitle, resetRows, setDetails, updateFilter, updatePageable, updateUserFilter } from '../../stores/workOrder/workOrder';
import TableUtils from '../../utils/TableUtils';
import { InfiniteScrollTableContainer } from '../table/PageableTable';
import WorkOrderStatusLabel from './WorkOrderStatusLabel';
import WorkOrderTabs from './WorkOrderTabs';
import LinkButton from '../LinkButton';
import CroppedText from '../table/CroppedText';
import { TotalElementsLabel } from '../table/TableHeaderWithCount';
import UserFilter from '../table/UserFilter';

export function WorkOrderNumberWithPriority({number, priority}) {
  let color, icon;

  switch(priority){
    case '1':
      color = 'red';
      icon = 'priorityHighest';
      break;
    case '2': 
      color = 'red';
      icon = 'priorityHigh';
      break;
    case '3':
      color = 'orange';
      icon = 'priorityNormal';
      break;
    case '4':
      color = 'blue';
      icon = 'priorityLow';
      break;
    case '5':
      color = 'blue';
      icon = 'priorityLowest';
      break;
    default:
  }

  return <div>
    {icon && <Icon icon={icon} color={color} style={{display: 'inline-block', verticalAlign: 'middle', width: 16, height: 16, margin: 4}}/>}
    <b style={{display: 'inline-block', verticalAlign: 'middle', marginTop: 1}}>{number}</b>
  </div>;
}

export default function WorkOrderTable({ domain }) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { rows, filter, userFilter, pageable, totalElements, isLoading } = useSelector(state => state.workOrder);
  const { classifiers } = useSelector(state => state.classifier);

  const columns = useMemo(() => [
    { 
      field: 'id', headerName: t('workOrder.queueNumber'), type: 'integer', filter: true, renderCell: (column, cell) => (
        <WorkOrderNumberWithPriority number={cell.id} priority={cell.priority}/>
      )
    },
    { field: 'location', headerName: t('workOrder.location'), filter: true },
    {
      field: 'description', headerName: t('workOrder.description'), filter: true, renderCell: (column, cell) => (
        <CroppedText value={cell.description} />
      )
    },
    {
      field: 'userAuthor_id', headerName: t('workOrder.author'), type: "govUser", filter: true,
      renderCell: (column, cell) => cell?.userAuthor?.fullName, sortField: 'userAuthor.person.firstName'
    },
    { field: "openingDatetime", type: "dateTime", headerName: t('workOrder.openingDatetime') },
    {
      field: 'fulfiller', headerName: t('workOrder.fulfiller'), sortField: 'userFulfiller.person.firstName', renderCell: (column, cell) => {
        const fulfiller = getFulfillerTitle(cell);
        return cell.notificationList?.length ? <Tooltip
          title={<div>
            <div>Teavitatud ametnikud:</div>
            <b>{cell.notificationList.map(n => n.fullName).join(', ')}</b>
          </div>}
          label={fulfiller} /> : fulfiller
      },
    },
    { field: "deadline", type: "dateTime", headerName: t('workOrder.deadline') },
    {
      field: 'status', headerName: t('workOrder.status'),
      type: "classifier", classifierType: ClassifierType.workOrderStatus, filter: true, renderCell: (column, cell) =>
        <WorkOrderStatusLabel statusClassifier={TableUtils.getClassifier(classifiers, ClassifierType.workOrderStatus, cell.status)} />
    },
  ], [t, classifiers]);

  useEffect(() => dispatch(resetRows()), [dispatch, domain]);
  useEffect(() => !!classifiers.length && !pageable.loaded && dispatch(fetchRows(domain, pageable, filter, columns, userFilter)),
    [dispatch, pageable, filter, columns, classifiers, domain, userFilter]);

  const handleRowSelection = (selected) => {
    dispatch(setDetails(selected));
    history.push(`/workOrder/view/${selected.id}`);
  };

  const handleAddNew = () => {
    dispatch(setDetails({ domain: domain }));
  };

  const handleUserFilterChange = (event, filter) => {
    dispatch(updateUserFilter(filter));
  };

  const title = t(domain === 'archive' ? 'workOrder.archiveOrders' : 'workOrder.activeOrders');

  const filterItems = [{
    value: 'created',
    label: t('workOrder.filter.created')
  }, {
    value: 'forFulfillment',
    label: t('workOrder.filter.forFulfillment')
  }, {
    value: 'notified',
    label: t('workOrder.filter.notified')
  }];
  if(domain !== 'archive'){
    filterItems.push({
      value: 'fulfilled',
      label: t('workOrder.filter.fulfilled')
    });
  }
  const header = () => (
    <TableHeader buttons={<>
      {domain !== 'archive' && <LinkButton
        size='extra-small'
        onClick={handleAddNew}
        to={`/workOrder/edit`}
      >
        {t('workOrder.form.add')}
      </LinkButton>}
    </>}>
      <Grid container direction="column">
        <Box paddingBottom={1}>
          {title}
          <TotalElementsLabel>{totalElements}</TotalElementsLabel>
        </Box>
        <UserFilter value={userFilter} items={filterItems} onChange={handleUserFilterChange} disabled={isLoading} />
      </Grid>
    </TableHeader>
  );

  return <div>
    <WorkOrderTabs />

    <Box my={5}>
      <InfiniteScrollTableContainer
        rows={rows}
        columns={columns}
        filter={filter}
        pageable={pageable}
        header={header()}
        loading={isLoading}
        onRowSelected={handleRowSelection}
        onUpdatePageable={(pageNumber, sort) => dispatch(updatePageable(pageNumber, sort))}
        onUpdateFilter={(field, value) => dispatch(updateFilter(field, value))}
      />
    </Box>
  </div>;
}