import { Box, Divider, Grid, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ApplicationLocationType, ApplicationType, Domain } from '../../constants/classifierConstants';
import { setHighlightGeometries, toggleFeaturesDialog } from '../../stores/map/feature';
import { setActiveGeometries, toggleActiveControl } from '../../stores/map/map';
import { fetchLocations } from '../../stores/planning/planningLocation';
import MapUtils from '../../utils/MapUtils';
import ActiveFeaturesLayer from '../map/layers/ActiveFeaturesLayer';
import HighlightFeaturesLayer from '../map/layers/HighlightFeaturesLayer';
import Layers from '../map/layers/Layers';
import Map from '../map/Map';
import { Colors, Heading, Paragraph, Icon, Switch, Button } from 'styleguide';
import { useTranslation } from 'react-i18next';
import AuthUtils from '../../utils/AuthUtils';
import Controls from '../map/controls/Controls';
import ZoomControl from '../map/controls/ZoomControl';
import SelectFeatureControl from '../map/controls/SelectFeatureControl';
import { addNeighbours, addPlanLocation, addPartyLocation, deletePlanLocation, updatePlanLocationToggled } from '../../stores/planning/planningLocation';
import ConfirmButton from '../table/ConfirmButton';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    minHeight: 800,
    borderRadius: 14,
    padding: 8,
  },
  mapContainer: {
    height: 800,
    borderRadius: 8,
    overflow: 'hidden'
  },
  overviewContainer: {
    [theme.breakpoints.up('md')]: {
      padding: '20px 40px'
    },
    [theme.breakpoints.down('md')]: {
      padding: 4
    },
  },
  neighbourButton: {
    float: 'right'
  },
  locationRow: {
    '&:hover': {
      backgroundColor: Colors.hall3
    },
  }
}));

function LocationBlock({ type, readOnly, handleDelete, handleFindNeighbours, locations, isFirstBlock }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const rows = locations?.filter(l => l.type === type);

  const planLocationToggled = useSelector(state => state.planningLocation.planLocationToggled);

  const handleMouseEnter = (location) => {
    if (location?.geometry) {
      dispatch(setHighlightGeometries([location.geometry]));
    }
  };
  const handleMouseLeave = () => {
    dispatch(setHighlightGeometries([]));
  };
  const handleLocationToggle = () => {
    dispatch(updatePlanLocationToggled());
  };

  return <> {!isFirstBlock && <Divider />}
    <Grid item container direction="row">
      <Grid item xs={11}>
        <Box paddingBottom={2} display="inline-block">
          <Heading level="4">{t(`plan.location.${type}`)}</Heading>
        </Box>
      </Grid>
      {!readOnly && !!handleFindNeighbours &&
        <Grid item xs={1}>
          <Button size="extra-small" onClick={handleFindNeighbours} color="secondary" className={classes.neighbourButton}>
            <Icon icon="position" /> {t('plan.location.findNeighbours')}
          </Button>
        </Grid>}
      {!readOnly && (ApplicationLocationType.plan === type || ApplicationLocationType.party === type) &&
        <Grid item xs={1}>
          {ApplicationLocationType.plan === type && <Switch checked={planLocationToggled} onChange={handleLocationToggle} />}
          {ApplicationLocationType.party === type && <Switch checked={!planLocationToggled} onChange={handleLocationToggle} />}
        </Grid>
      }
      {rows?.map((location, index) => <React.Fragment key={index}>
        <Grid item xs={11} className={classes.locationRow}
          onMouseEnter={() => handleMouseEnter(location)} onMouseLeave={handleMouseLeave}>
          <Paragraph fontSize="14B">{location.address}</Paragraph>
          {ApplicationLocationType.application !== type && <Paragraph fontSize="14">{`${location.purposes} | ${location.cadastre} | ${MapUtils.formatArea(location.areaAndUnit)}`}</Paragraph>}
        </Grid>
        {!readOnly && <Grid xs={1} item>
          <ConfirmButton
            message={t('form.confirmDelete')} icon="delete"
            onConfirm={() => handleDelete(location)}
            aria-label="delete location"
            title={t('clause.button.deleteFile')}
          />
        </Grid>}
      </React.Fragment>)}
    </Grid>
  </>;
}

export default function PlanningLocations({ readOnly }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const planning = useSelector(state => state.planning.selected);
  const locations = useSelector(state => state.planningLocation.rows);

  const planLocationToggled = useSelector(state => state.planningLocation.planLocationToggled);

  const cadastre = useSelector(state => state.feature.cadastre);
  const { layers, center, zoom, extent } = useSelector(state => state.map);
  const { authUser } = useSelector(state => state.auth);

  const isGovUser = AuthUtils.isGov(authUser);
  const extraLayers = isGovUser && MapUtils.getDomainLayers(layers, Domain.plan);

  const planningId = planning.id;
  const shouldFetchLocations = !locations;
  useEffect(() => shouldFetchLocations && dispatch(fetchLocations(planningId)), [shouldFetchLocations, planningId, dispatch]);
  // //set map extent after load
  useEffect(() => {
    if (locations?.length) {
      const neighbourGeometries = locations.filter(r => ApplicationLocationType.neighbour === r.type).map(r => r.geometry);
      dispatch(setActiveGeometries(neighbourGeometries, false, true, MapUtils.tertiaryStyle));
      const partyGeometries = locations.filter(r => ApplicationLocationType.party === r.type).map(r => r.geometry);
      dispatch(setActiveGeometries(partyGeometries, true, true, MapUtils.quaternaryStyle));
      const planGeometries = locations.filter(r => ApplicationLocationType.plan === r.type).map(r => r.geometry);
      dispatch(setActiveGeometries(planGeometries, true, true, MapUtils.secondaryStyle));
      const applicationGeometries = locations.filter(r => ApplicationLocationType.application === r.type).map(r => r.geometry);
      dispatch(setActiveGeometries(applicationGeometries, true, false));
    }
  }, [dispatch, locations]);

  //add cadastre
  useEffect(() => cadastre && planLocationToggled && !!planningId && dispatch(addPlanLocation(planningId, cadastre, locations)),
    [planningId, cadastre, planLocationToggled, locations, dispatch]);

  useEffect(() => cadastre && !planLocationToggled && !!planningId && dispatch(addPartyLocation(planningId, cadastre, locations)),
    [planningId, cadastre, planLocationToggled, locations, dispatch]);

  const handleShowMap = () => {
    if (readOnly) {
      dispatch(setActiveGeometries(locations.map(l => l.geometry)));
      dispatch(toggleFeaturesDialog(false));
      dispatch(toggleActiveControl(null, false));
      history.push(`/map`);
    }
  };

  const handleDelete = (location) => {
    dispatch(deletePlanLocation(planningId, location.id));
  };

  const handleFindNeighbours = () => {
    dispatch(addNeighbours(planningId));
  };

  return <Paper className={classes.container}>
    <Grid container direction="row" justifyContent="space-between">
      <Grid item xs={12} lg={6} container direction="column" spacing={5} className={classes.overviewContainer}>
        <LocationBlock type={ApplicationLocationType.application} readOnly isFirstBlock locations={locations} />
        <LocationBlock type={ApplicationLocationType.plan} readOnly={readOnly} handleDelete={handleDelete} locations={locations} />
        <LocationBlock type={ApplicationLocationType.party} readOnly={readOnly} handleDelete={handleDelete} locations={locations} />
        {!planning.applicationNumber.includes(ApplicationType.YP) &&
          <LocationBlock type={ApplicationLocationType.neighbour} readOnly={readOnly}
            handleDelete={handleDelete} handleFindNeighbours={handleFindNeighbours} locations={locations} />
        }
      </Grid>
      <Grid item xs={12} lg={6} className={classes.mapContainer} onClick={handleShowMap}>
        <Map center={center} zoom={zoom} extent={extent} disablePan={readOnly}>
          <Layers>
            {MapUtils.getDefaultBaseLayer(layers)}
            {MapUtils.getCadastreLayer(layers)}
            {extraLayers}
            <ActiveFeaturesLayer />
            <HighlightFeaturesLayer />
          </Layers>
          {!readOnly && <Controls>
            <ZoomControl />
            <SelectFeatureControl onClick={MapUtils.handleCadastreSelect} />
          </Controls>}
        </Map>
      </Grid>
    </Grid>
  </Paper>;
}