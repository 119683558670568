import { FormHelperText } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { VSelectFormControl, VSelectSelect } from 'styleguide';

export default function ControlledSelect({ item, name, label, control, required, children, displayClearValue, errors, disabled,
  onChange: onChangeValue, error, helperText, ...rest }) {
  const { t } = useTranslation();

  let rules = {};
  if (required) {
    rules.required = { value: true, message: t('validation.required') };
  }

  if (!error) {
    error = !!(errors && errors[name]);
  }
  if (!helperText) {
    helperText = errors && errors[name] && errors[name].message;
  }

  return <VSelectFormControl label={label} error={error} fullWidth disabled={disabled}>
    <Controller
      render={({ value, name, onBlur, onChange }) => (<>
        <VSelectSelect
          name={name}
          value={value || ''}
          onChange={(event) => {
            onChange(event);
            onChangeValue && onChangeValue(event);
          }}
          onBlur={onBlur}
          label={label}
          emptyLabel={displayClearValue && t('form.selectNone')}
          disabled={disabled}
          {...rest}>
          {children}
        </VSelectSelect>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </>)}
      name={name}
      defaultValue={(!!item && !!item[name]) ? item[name] : ''}
      control={control}
      rules={rules}
    />
  </VSelectFormControl>;
}