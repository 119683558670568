import { Box, FormControlLabel, Grid, makeStyles, Paper } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Checkbox, Colors, Heading } from 'styleguide';
import { ApplicationStatus, ApplicationType } from '../../../constants/classifierConstants';
import LoadingIndicator from '../../form/LoadingIndicator';
import { LocationsOverview, PurposesOverview, FilesOverview, ApplicantsOverview } from '../ApplicationOverview';
import clsx from 'clsx';
import Paragraph from 'styleguide/build/components/Typography/Paragraph/Paragraph';
import DateUtils from '../../../utils/DateUtils';
import ApplicationUtils from '../../../utils/ApplicationUtils';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      width: 900,
    },
    [theme.breakpoints.down('md')]: {
      width: '95%',
    },
  },
  overviewBlock: {
    backgroundColor: Colors.hall3,
    marginBottom: 20,
    marginTop: 20,
    padding: '18px 26px',
    borderRadius: 8
  },
  filesOverviewBlock: {
    marginBottom: 10,
    marginTop: 20,
  },
  alertBlock: {
    backgroundColor: Colors.withOpacity(Colors.loojang, 0.1)
  }
}));

function OverviewBlock({ applicationId, step, children, paperClass, label, isDp, isYp, isKT }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const handleEdit = () => history.push(`/application/form/${applicationId}/${step}`);
  let heading;
  if (!!label) {
    heading = t(label);
  } else if (!!isDp && isDp) {
    heading = t(`plan.overview.step.${step}`);
  } else if (!!isYp && isYp) {
    if (step === '2') {
      heading = t(`plan.overview.step.2YP`);
    } else {
      heading = t(`plan.overview.step.${step}`);
    }
  } else if (!!isKT && isKT) {
    if (step === '2') {
      heading = t(`plan.overview.step.2KT`);
    } else {
      heading = t(`plan.overview.step.${step}`);
    }
  } else {
    heading = t(`application.overview.step.${step}`);
  }

  return <>
    <Grid container direction="row" justifyContent="space-between" wrap="nowrap">
      <Heading level="4">{heading}</Heading>
      {
        !!step && (!isYp || (step !== '1' && isYp)) &&
        <Button onClick={handleEdit} size="extra-small" color="tertiary">{t('button.edit')}</Button>
      }
    </Grid >
    <Paper elevation={0} className={paperClass || classes.overviewBlock}>
      {children}
    </Paper>
  </>;
}

function HandlerCommentBlock({ application }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return <OverviewBlock label="application.form.handlerComment"
    paperClass={clsx(classes.overviewBlock, classes.alertBlock)}
    isDp={ApplicationType.DP === application.applicationType}
    isYp={ApplicationType.YP === application.applicationType}>
    <Paragraph fontSize="14B">{t('application.form.needsImprovement')}</Paragraph>
    <Paragraph fontSize="14">{`${application.handlerName || ''} | ${t('application.deadline')} ${DateUtils.formatDate(application.deadline)}`}</Paragraph>
    <Box paddingTop={2}>
      {application.description}
    </Box>
  </OverviewBlock>;
}

export default function Step5Overview({ application, consented, onConsented, consentedFinance, onConsentedFinance, isLoading, onDelete }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { classifiers } = useSelector(state => state.classifier);

  const { rows: locations } = useSelector(state => state.applicationLocation);
  const { groupsArray } = useSelector(state => state.applicationPurpose);
  const { applicants } = useSelector(state => state.applicationApplicant);
  const { rows: files } = useSelector(state => state.applicationFile);
  const authUser = useSelector(state => state.auth.authUser);

  const purposeGroups = groupsArray || application?.applicationPurpose?.groups;
  if (isLoading || !purposeGroups || !applicants) {
    return <LoadingIndicator />;
  }

  const isDp = ApplicationType.DP === application.applicationType;
  const isYp = ApplicationType.YP === application.applicationType;
  const isKT = ApplicationType.KT === application.applicationType;
  const showHandlerComment = ApplicationStatus.editing === application.status && !!application.description;

  return <Box paddingLeft={2} paddingBottom={20} marginLeft="auto" marginRight="auto">
    <Grid container direction="column" className={classes.container}>
      {showHandlerComment && <HandlerCommentBlock application={application} />}

      <OverviewBlock applicationId={application.id} step='1' isDp={isDp} isYp={isYp}>
        <LocationsOverview locations={locations} applicationType={application.applicationType} />
      </OverviewBlock>

      <OverviewBlock applicationId={application.id} step='2' isDp={isDp} isYp={isYp} isKT={isKT}>
        <PurposesOverview
          groups={purposeGroups}
          isYpAppropriate={isDp ? application.isYpAppropriate : null}
          description={application.purpose}
          classifiers={classifiers}
        />
      </OverviewBlock>

      <OverviewBlock applicationId={application.id} step='3' isDp={isDp} isYp={isYp}>
        <ApplicantsOverview applicants={applicants} />
      </OverviewBlock>

      <OverviewBlock applicationId={application.id} step='4' paperClass={classes.filesOverviewBlock} isDp={isDp} isYp={isYp}>
        <FilesOverview applicationId={application.id} files={files} />
      </OverviewBlock>

      {(isDp || isYp) &&
        <FormControlLabel
          label={t('application.overview.consentFinance')}
          control={<Checkbox
            onChange={(e) => onConsentedFinance(e.target.checked)}
            disabled={isLoading}
            checked={consentedFinance}
          />}
        />
      }

      <Grid container direction="row" justifyContent="space-between" >
        <Grid item sm={12} lg={9}>
          <FormControlLabel
            label={t('application.overview.consent')}
            control={<Checkbox
              onChange={(e) => onConsented(e.target.checked)}
              disabled={isLoading}
              checked={consented}
            />}
          />
        </Grid>
        <Button onClick={onDelete} size="extra-small" color="secondary">
          {authUser && (ApplicationUtils.isGovCanAddApplication(authUser) || ApplicationUtils.isGovCanAddYPApplication(authUser)) ?
            t('button.deleteApplication') : t('button.cancelApplication')}
        </Button>
      </Grid>
    </Grid>
  </Box >;
}
