import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TableHeader } from 'styleguide';
import { ClassifierType } from '../../../constants/classifierConstants';
import { fetchRows, resetRows, updateFilter, updatePageable } from '../../../stores/application/application';
import RouteUtils from '../../../utils/RouteUtils';
import TableUtils from '../../../utils/TableUtils';
import CroppedText from '../../table/CroppedText';
import { InfiniteScrollTableContainer } from '../../table/PageableTable';
import ApplicationStatusLabel from '../ApplicationStatusLabel';
import MyApplicationsTabs from '../citizen/MyApplicationsTabs';

export default function MyApplicationsTable({ fixedRows, hideTabs, plan }) {
  const { t } = useTranslation();
  const history = useHistory();

  const dispatch = useDispatch();
  const { rows, filter, pageable, isLoading, isPlanLoaded } = useSelector(state => state.application);
  const { classifiers } = useSelector(state => state.classifier);

  const columns = useMemo(() => {
    const c = [
      {
        field: 'applicationType', headerName: t('application.applicationType'),
        type: "classifier", classifierType: ClassifierType.applicationType, filter: true
      },
      { field: 'applicationNumber', headerName: t('application.applicationNumber'), filter: true },
      {
        field: 'location', headerName: t('application.location'), filter: true, renderCell: (column, cell) => (
          <CroppedText value={cell.location} length={50} />
        )
      },
      {
        field: 'status', headerName: t('application.status'), width: 150,
        type: "classifier", classifierType: ClassifierType.applicationStatus, filter: true, renderCell: (column, cell) =>
          <ApplicationStatusLabel statusClassifier={TableUtils.getClassifier(classifiers, ClassifierType.applicationStatus, cell.status)} />
      }
    ];
    if (!plan) {
      c.push({ field: "proceedingsDeadline", type: "date", headerName: t('application.proceedingsDeadline') });
    } else {
      c.push({ field: "proceedingsDate", type: "date", headerName: t('application.proceedingsDate') });
    }
    return c;
  }, [t, classifiers, plan]);

  useEffect(() => !!plan !== !!isPlanLoaded && dispatch(resetRows()), [dispatch, plan, isPlanLoaded]);
  useEffect(() => !!classifiers.length && !pageable.loaded && dispatch(fetchRows(pageable, filter, columns, plan)),
    [dispatch, pageable, filter, columns, classifiers, plan]);

  const handleRowSelection = (selected) => {
    RouteUtils.navigateToObject(history, 'application', selected.id);
  };

  const header = () => (
    <TableHeader>{t(!plan ? 'myApplications.title' : 'myApplications.plans')}</TableHeader>
  );

  return <div>
    {!hideTabs && <MyApplicationsTabs />}

    <InfiniteScrollTableContainer
      rows={!!fixedRows ? rows.slice(0, fixedRows) : rows}
      columns={columns}
      filter={filter}
      pageable={pageable}
      header={header()}
      loading={isLoading}
      onRowSelected={handleRowSelection}
      onUpdatePageable={(pageNumber, sort) => dispatch(updatePageable(pageNumber, sort))}
      onUpdateFilter={(field, value) => dispatch(updateFilter(field, value))}
      disableLoading={!!fixedRows}
      totalRows={fixedRows}
    />
  </div>;
}