import { Grid, List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Heading, Icon, Paragraph } from 'styleguide';
import { ClassifierType } from '../../constants/classifierConstants';
import { CenteredLoadingIndicator } from '../form/LoadingIndicator';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      width: 435,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    marginBottom: 32,
  },
  title: {
    paddingLeft: 18,
    paddingBottom: 36,
  },
}));

function ShortcutLink({ link }) {

  const handleClick = () => {
    if (link.description) {
      window.open(link.description, '_blank', 'noopener,noreferrer');
    }
  };

  return <ListItem button onClick={handleClick}>
    <ListItemText secondary={<Paragraph fontSize="16B">{link.title}</Paragraph>} />
    <Icon icon="arrowRight" />
  </ListItem>;
}

export default function ShortcutBlock() {
  const { t } = useTranslation();
  const classes = useStyles();
  const classifiers = useSelector(state => state.classifier.classifiers);

  if (!classifiers) {
    return <CenteredLoadingIndicator />;
  }

  const shortcuts = classifiers?.filter(c => c.valid && c.typeCode === ClassifierType.shortcut);
  if (!shortcuts.length) {
    return "";
  }

  const splitIndex = Math.ceil(shortcuts.length / 2);
  const leftLinks = shortcuts.slice(0, splitIndex);
  const rightLinks = shortcuts.slice(splitIndex);

  return <div className={classes.container}>
    <Heading level="3" className={classes.title}>{t('homepage.block.shortcut')}</Heading>

    <Grid container direction="row">
      <Grid item xs={12} lg={6}>
        <List disablePadding>
          {leftLinks.map(link => <ShortcutLink key={link.itemCode} link={link} />)}
        </List>
      </Grid>
      <Grid item xs={12} lg={6}>
        <List disablePadding>
          {rightLinks.map(link => <ShortcutLink key={link.itemCode} link={link} />)}
        </List>
      </Grid>
    </Grid>
  </div>;
}