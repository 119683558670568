import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch } from "react-redux";
import { Button } from "styleguide";
import { logout } from "../../stores/auth";

const timeout = 15 * 60 * 1000;
const promptBeforeIdle = 1 * 60 * 1000;

export default function IdleTimer() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [remaining, setRemaining] = useState(timeout);

  const onIdle = () => {
    dispatch(logout());
    setOpen(false);
  };

  const onActive = () => {
    setOpen(false);
  };

  const onPrompt = () => {
    setOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(
      () => setRemaining(Math.ceil(getRemainingTime() / 1000)),
      1000
    );

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
  };
  
  return (
    <Dialog open={open}>
      <DialogTitle>{t('header.idleTimer.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('header.idleTimer.content', {remaining})}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size="small" color="secondary" onClick={onIdle}>{t('header.logoutBtn')}</Button>
        <Button size="small" color="primary" onClick={handleStillHere} autoFocus>{t('header.continueBtn')}</Button>
      </DialogActions>
    </Dialog>
  );
}
