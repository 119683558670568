import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/register';
import ChartUtils from '../../utils/ChartUtils';
import StoreUtils from '../../utils/StoreUtils';
import TableUtils from '../../utils/TableUtils';

const apiPath = (domain, queryName) => `${domain}/report/${queryName}`;

// Slice
const slice = createSlice({
  name: 'registerReport',
  initialState: {
    rows: {},
    isLoading: false,
    error: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    rowsSuccess: (state, action) => {
      state.rows[action.payload.queryName] = action.payload.rows;
      state.isLoading = false;
    },
    toggleRowSuccess: (state, action) => {
      const row = action.payload.row;
      if (state.rows[action.payload.queryName]) {
        state.rows[action.payload.queryName].find(r => equalsRow(r, row)).open = action.payload.open;
      }
    },
    rowDataSuccess: (state, action) => {
      const row = action.payload.row;
      if (state.rows[action.payload.queryName]) {
        state.rows[action.payload.queryName].find(r => equalsRow(r, row)).data = action.payload.data;
      }
    }
  },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, rowsSuccess, toggleRowSuccess, rowDataSuccess } = slice.actions;

export const fetch = (domain, queryName, queryParams) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.post(apiPath(domain, queryName), queryParams).then((response) => {
      const rows = response.data;
      let groupedRows = [];
      const locations = [...new Set(rows.map(r => r.location))];
      const types = [...new Set(rows.filter(r => !!r.type).map(r => r.type))];
      locations.forEach(location => {
        const locationRows = rows.filter(r => r.location === location);
        const totalCount = ChartUtils.arraySum(locationRows, 'count');
        let groupedRow = {
          location: location,
          count: totalCount
        };
        types.forEach(type => {
          groupedRow[type] = ChartUtils.arraySum(locationRows.filter(r => r.type === type), 'count');
        });
        groupedRows.push(groupedRow);
      });
      groupedRows = TableUtils.sortArrayByNumber(groupedRows, 'count', true);

      const totalRow = {
        count: ChartUtils.arraySum(groupedRows, 'count')
      };
      types.forEach(type => {
        totalRow[type] = ChartUtils.arraySum(groupedRows, type);
      });
      groupedRows.push(totalRow)
      dispatch(rowsSuccess({ rows: groupedRows, queryName }));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchGet = (domain, queryName) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(apiPath(domain, queryName)).then((response) => {
      const rows = response.data;
      const totalRow = {
        count: ChartUtils.arraySum(rows, 'count'),
        length: ChartUtils.arraySum(rows, 'length'),
        area: ChartUtils.arraySum(rows, 'area'),
        power: ChartUtils.arraySum(rows, 'power'),
        lamps: ChartUtils.arraySum(rows, 'lamps'),
        shields: ChartUtils.arraySum(rows, 'shields'),
        percentage: 100
      };
      rows.push(totalRow);
      if (queryName === 'busStopType' || queryName === 'roadAge') {
        rows.forEach(r => r.percentage = Math.round((r.count / totalRow.count) * 100));
      }
      dispatch(rowsSuccess({ rows, queryName }));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchPost = (domain, queryName, queryParams) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.post(apiPath(domain, queryName), queryParams).then((response) => {
      const rows = response.data;
      dispatch(rowsSuccess({ rows, queryName }));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchRowData = (domain, queryName, row, parameter) => async dispatch => {
  try {
    await api.get(`${apiPath(domain, queryName)}/${parameter}`).then((response) => {
      dispatch(rowDataSuccess({ row, queryName, data: response.data }));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchCSVData = (domain, queryName, parameter) => async dispatch => {
  try {
    return await api.get(`${apiPath(domain, queryName)}/${parameter}`).then((response) => response.data);
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchRowDataWithParam = (domain, queryName, row, parameter) => async dispatch => {
  try {
    await api.get(`${apiPath(domain, queryName)}${parameter}`).then((response) => {
      dispatch(rowDataSuccess({ row, queryName, data: response.data }));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const toggleRow = (row, open, queryName) => dispatch => dispatch(toggleRowSuccess({ row, queryName, open }));

export const resetRows = (queryName) => dispatch => dispatch(rowsSuccess({ queryName }));

export const getReportCSVUrl = (domain, queryName) => `${api.defaults.baseURL}/${apiPath(domain, queryName)}`;

const equalsRow = (row1, row2) => {
  if (row1.type) {
    return row2.type === row1.type;
  } else if (row1.id) {
    return row2.id === row1.id;
  } else if (row1.location) {
    return row2.location === row1.location;
  } else {
    return row2 === row1;
  }
}