"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Colors = void 0;

/**
 * @visibleName Basic Colors
 */
var Colors = {
  sinineVaal: 'rgba(47, 97, 224, 1)',
  vagaVesi: 'rgba(9, 30, 66, 1)',
  hall1: 'rgba(168, 173, 180, 1)',
  hall2: 'rgba(234, 236, 239, 1)',
  hall3: 'rgba(247, 249, 252, 1)',
  hall4: 'rgba(248, 250, 252, 1)',
  lainehari: 'rgba(166, 212, 255, 1)',
  hapumarjapuu: 'rgba(224, 30, 90, 1)',
  paikesekiir: 'rgba(255, 217, 101, 1)',
  maismaa: 'rgba(54, 179, 126, 1)',
  loojang: 'rgba(239, 127, 87, 1)',
  lavendel: 'rgba(127, 92, 242, 1)',
  withOpacity: function withOpacity(color, opacity) {
    return color.replace(/1\)$/g, "".concat(opacity, ")"));
  }
};
exports.Colors = Colors;
var _default = Colors;
exports.default = _default;