import { AppBar, Dialog, IconButton, makeStyles, Paper, Slide, Toolbar } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Heading, Icon, NavButton, Shadows } from "styleguide";
import { toggleLoadingOverlay } from "../../../stores/notification";
import MapUtils from "../../../utils/MapUtils";
import AddressSearch from "../../map/AddressSearch";
import Controls from "../../map/controls/Controls";
import SelectFeatureControl from "../../map/controls/SelectFeatureControl";
import Map from '../../map/Map';
import Layers from "../../map/layers/Layers";
import { PageableTableContainer } from "../../table/PageableTable";
import { resetCadastre } from "../../../stores/map/feature";
import { useEffect, useState } from "react";
import LoadingIndicator from "../../form/LoadingIndicator";
import ActiveFeaturesLayer from "../../map/layers/ActiveFeaturesLayer";
import ZoomControl from "../../map/controls/ZoomControl";

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    paddingBottom: 88
  },
  tableContainer: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 177,
      marginRight: 177
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 4,
      marginRight: 4
    },
    maxHeight: 300,
    overflow: 'auto',
    position: 'absolute',
    bottom: 100,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: Shadows.shadow2
  },
  toolbar: {
    backgroundColor: 'white',
    [theme.breakpoints.up('md')]: {
      height: 102
    },
  },
  footer: {
    top: 'auto',
    bottom: 0,
    height: 88,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 152,
      paddingRight: 152
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 4,
      paddingRight: 4
    },
  },
  title: {
    flexGrow: 1,
  },
  closeButton: {
    position: 'absolute',
    zIndex: 1200,
    [theme.breakpoints.up('md')]: {
      top: 48,
      right: 176,
    },
    [theme.breakpoints.down('md')]: {
      top: 38,
      right: 10,
    },
  }
}));

export default function MapSelectCadastres({ disabled, onClose, onSubmit, tableTitle }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { layers, center, zoom, extent } = useSelector(state => state.map);
  const { cadastre } = useSelector(state => state.feature);
  const [cadastres, setCadastres] = useState([]);

  //add cadastre
  useEffect(() => {
    if (cadastre) {
      dispatch(resetCadastre());
      setCadastres([...cadastres, cadastre]);
      dispatch(toggleLoadingOverlay(false));
    }
  }, [dispatch, cadastre, cadastres, setCadastres]);

  if (!layers) {
    return <LoadingIndicator />;
  }

  const handleDelete = (value) => {
    setCadastres(cadastres.filter(c => c.cadastre !== value))
  };

  let columns = [
    { field: 'address', headerName: t('application.applicationLocation.address') },
    { field: 'cadastre', headerName: t('application.applicationLocation.cadastre') },
    {
      field: 'action', width: 50, renderCell: (column, cell) =>
        <IconButton onClick={() => handleDelete(cell.cadastre)} disabled={disabled}>
          <Icon icon="close" />
        </IconButton>
    }
  ];

  const handleAddressSelect = (address) => {
    if (address.cadastre) {
      setCadastres([...cadastres, { address: address.shortAddress, cadastre: address.cadastre }]);
    }
  };

  const handleConfirm = () => {
    onSubmit(cadastres.map(c => c.cadastre));
  };

  return <Dialog open={true} fullScreen onClose={onClose} TransitionComponent={Slide}>
    <div className={classes.container}>
      <Map center={center} zoom={zoom} extent={extent}>
        <Layers>
          {MapUtils.getDefaultBaseLayer(layers)}
          {MapUtils.getCadastreLayer(layers)}
          <ActiveFeaturesLayer />
        </Layers>
        <Controls>
          <ZoomControl />
          <SelectFeatureControl onClick={MapUtils.handleCadastreSelect} />
        </Controls>
        <AddressSearch onSelect={handleAddressSelect} />
      </Map>

      <Paper className={classes.tableContainer}>
        <Heading level="4" margin="26px">{tableTitle || t('userNotification.request.addMap')}</Heading>

        <PageableTableContainer
          rows={cadastres}
          columns={columns}
          totalRows={cadastres.length}
          size={'small'}
        />
      </Paper>
      <NavButton onClick={onClose} action='close' disabled={disabled} className={classes.closeButton} />
    </div>
    <AppBar className={classes.footer} color="transparent" position="fixed">
      <Toolbar className={classes.toolbar}>
        <Button disabled={!!disabled} onClick={onClose} color="secondary">{t('button.back')}</Button>
        <span className={classes.title} />
        <Button disabled={disabled || !cadastres.length} onClick={handleConfirm}>{t('button.confirm')}</Button>
      </Toolbar>
    </AppBar>
  </Dialog>;
}