import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import TableUtils from '../../utils/TableUtils';
import { PageableTableContainer } from './PageableTable';

export default function SimplePageableTable({ rows, columns, header, pageSize, sort, loading, handleRowSelection, ...rest }) {
  const initialPageSize = pageSize || 5;
  const [loaded, setLoaded] = useState(false);
  const [state, setState] = useState({
    rows: rows,
    filteredRows: [],
    pageable: {
      pageSize: initialPageSize,
      pageNumber: 0,
      sort: sort || {},
      loaded: false,
      last: rows?.length <= initialPageSize
    },
    filter: {}
  });

  useEffect(() => setLoaded(false), [rows]);
  useEffect(() => {
    if (!loaded) {
      let newState = cloneDeep(state);
      newState.rows = rows?.slice();
      TableUtils.setFilteredRows(newState);
      newState.pageable.loaded = true;
      setState(newState);
      setLoaded(true);
    }
  }, [loaded, rows, state]);

  const updatePageable = (pageNumber, sort) => {
    let newState = cloneDeep(state);
    if (pageNumber) {
      newState.pageable.pageNumber += pageNumber;
    }
    if (sort) {
      newState.pageable.sort = sort;
    }

    TableUtils.setFilteredRows(newState);
    setState(newState);
  };

  const updateFilter = (field, value) => {
    if ((state.filter[field] || '') !== value) {
      let newState = cloneDeep(state);
      newState.filter[field] = value;
      newState.pageable.pageNumber = 0;
      TableUtils.setFilteredRows(newState);
      setState(newState);
    }
  };

  return <PageableTableContainer
    rows={state.filteredRows}
    columns={columns}
    filter={state.filter}
    pageable={state.pageable}
    header={header}
    loading={loading}
    onRowSelected={handleRowSelection}
    onUpdatePageable={updatePageable}
    onUpdateFilter={updateFilter}
    {...rest}
  />;
}