import React from 'react';
import { useSelector } from 'react-redux';
import { BasicLabel } from 'styleguide';
import { ClassifierType, PlanningStatus } from '../../constants/classifierConstants';
import TableUtils from '../../utils/TableUtils';

export default function PlanningStatusLabel({ status }) {
  const { classifiers } = useSelector(state => state.classifier);

  const statusClassifier = !!status && TableUtils.getClassifier(classifiers, ClassifierType.planningStatus, status);

  if (!statusClassifier) {
    return <span></span>;
  }

  let color = '';
  switch (statusClassifier.itemCode) {
    case PlanningStatus.initiated:
    case PlanningStatus.accepted:
      color = 'blue';
      break;
    case PlanningStatus.established:
      color = 'green';
      break;
    case PlanningStatus.partiallyValid:
      color = 'yellow';
      break;
    case PlanningStatus.completed:
    case PlanningStatus.invalid:
      color = 'red';
      break;
    default:
      color = 'grey';
  }

  return <BasicLabel color={color}>{statusClassifier.title}</BasicLabel>;
}
