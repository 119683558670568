import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import AuthUtils from '../../utils/AuthUtils';

export default function SecureRoute({ component: Component, render, authority, role, ...rest }) {
  const { authUser, authenticated, isLoading } = useSelector(state => state.auth);
  if (isLoading) {
    return <></>;
  }

  return <Route {...rest} render={props => {
    if (!authenticated) {
      window.location.href = "/vaal-auth/oauth2/authorization/govsso";
      return <></>;
    }
    if (AuthUtils.isAuthorised(authUser, authority, role)) {
      if (Component) {
        return <Component {...props} />
      } else if (render) {
        return render();
      }
    }

    return <Redirect to="/" />
  }}
  />;
}