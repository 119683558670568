import { Box, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Colors, Icon } from 'styleguide';
import Paragraph from 'styleguide/build/components/Typography/Paragraph/Paragraph';
import { ApplicationFileType, ApplicationLocationType, ApplicationType, ClassifierType } from '../../constants/classifierConstants';
import { getFileDownloadUrl } from '../../stores/application/applicationFile';
import ApplicationUtils from '../../utils/ApplicationUtils';
import MapUtils from '../../utils/MapUtils';
import TableUtils from '../../utils/TableUtils';
import ClassifierLabel from '../form/ClassifierLabel';
import CroppedText from '../table/CroppedText';

const useStyles = makeStyles((theme) => ({
  fileRow: {
    backgroundColor: Colors.hall3,
    minHeight: 48,
    borderRadius: 4,
    padding: 15,
    marginBottom: 5
  },
  fileLink: {
    textDecoration: 'none',
    fontWeight: 'bold',
    color: Colors.sinineVaal
  },
  downloadIconContainer: {
    float: 'right'
  },
  downloadIcon: {
    verticalAlign: 'middle'
  }
}));

export function LocationsOverview({ locations, applicationType }) {
  const { t } = useTranslation();

  const filteredLocations = locations.filter(l => ApplicationLocationType.application === l.type);
  const area = (ApplicationType.DP === applicationType) ? ApplicationUtils.calculateArea(filteredLocations) : null;
  const isYp = ApplicationType.YP === applicationType;

  if (!filteredLocations?.length) {
    return t('application.notification.locationMissing');
  }

  return <div>
    {filteredLocations.map((location, index) => (
      <div key={index}>
        <Paragraph fontSize="14B">{location.address}</Paragraph>
        {!isYp && <Paragraph fontSize="14">{`${location.purposes} | ${location.cadastre} | ${MapUtils.formatArea(location.areaAndUnit)}`}</Paragraph>}
      </div>
    ))}
    {!!area && <Box paddingTop={1}>
      <Paragraph fontSize="14">{t('application.overview.area', { area })}</Paragraph>
    </Box>}
  </div>;
}

function PurposeOverview({ purpose, classifiers }) {
  if (!purpose) {
    return '';
  }
  const title = TableUtils.getClassifierTitle(classifiers, ClassifierType.purposeType, purpose.purposeType);
  let value = '';
  if (purpose.valueBoolean) {
    value = title;
  } else if (purpose.valueString) {
    value = purpose.valueString;
  } else if (purpose.valueNumber) {
    value = `${title} ${purpose.valueNumber}`;
  } else if (purpose.valueDateTimeFrom) {
    value = purpose.valueDateTimeFrom;
  } else if (purpose.valueDateTimeTo) {
    value = purpose.valueDateTimeTo;
  } else {
    return '';
  }

  return <Paragraph fontSize="14">{value}</Paragraph>
}

function PurposeGroupOverview({ group, classifiers }) {
  const title = TableUtils.getClassifierTitle(classifiers, ClassifierType.purposeGroup, group.purposeGroup);

  return <Box paddingY={1}>
    <Paragraph fontSize="14B">{title}</Paragraph>
    {group.purposes
      .slice()
      .sort((a, b) => (a.queueNumberInGroup - b.queueNumberInGroup))
      .map((purpose, index) => (
        <PurposeOverview key={index} purpose={purpose} classifiers={classifiers} />
      ))}
  </Box>
}

export function PurposesOverview({ groups, description, isYpAppropriate, classifiers }) {
  const { t } = useTranslation();

  const renderGroups = () => {
    if (!groups.length) {
      return t('application.notification.purposeMissing');
    }
    return groups
      .filter(g => g.isGroupSelected)
      .slice()
      .sort((a, b) => (a.queueNumber - b.queueNumber))
      .map((group, index) => (
        <PurposeGroupOverview key={index} group={group} classifiers={classifiers} />
      ));
  }

  return <div>
    {renderGroups()}

    {isYpAppropriate !== null && <Box paddingY={1}>
      {!!isYpAppropriate ? t('application.isYpAppropriate') : t('application.isNotYpAppropriate')}
    </Box>}

    <Box whiteSpace="pre-line">{description}</Box>
  </div>
}

export function ApplicantOverview({ applicant, heading, noPadding }) {
  return <Box paddingTop={noPadding ? 0 : 1}>
    <Paragraph fontSize="14B">{heading}</Paragraph>
    <Paragraph fontSize="14">{`${applicant.firstName || ''} ${applicant.name} (${applicant.code})`}</Paragraph>
    <Paragraph fontSize="14">{applicant.address || ''}</Paragraph>
    <Paragraph fontSize="14">{`${!!applicant.phone ? applicant.phone + ',' : ''} ${applicant.email || ''}`}</Paragraph>
  </Box>;
}

export function ApplicantsOverview({ applicants }) {
  const { t } = useTranslation();
  const { classifiers } = useSelector(state => state.classifier);

  return <div>
    {applicants.applicant && <ApplicantOverview applicant={applicants.applicant} heading={t('application.applicant.applicant')} noPadding />}
    {applicants.representative && <ApplicantOverview applicant={applicants.representative} heading={t('application.applicant.representative')} />}
    {applicants.parties?.map((party, index) => (
      <ApplicantOverview key={index} applicant={party}
        heading={TableUtils.getClassifierTitle(classifiers, ClassifierType.applicantType, party.applicantType)} />
    ))}
  </div>;
}

export function FileDownloadLink({ applicationId, fileId, url, children }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return <a href={url || getFileDownloadUrl(applicationId, fileId)}
    target="_blank" rel="noreferrer"
    className={classes.fileLink}
    title={t('fileUpload.download')}>
    {children}
  </a>;
}

export function FilesOverview({ applicationId, files }) {
  const classes = useStyles();

  if (!files?.length) {
    return "";
  }

  return <div>
    {files.filter(f => f.type !== ApplicationFileType.plan).map((file, index) => (
      <Grid container direction="row" key={index} justifyContent="space-between" alignItems="center" className={classes.fileRow}>
        <Grid item xs={12} lg={7}>
          <FileDownloadLink applicationId={applicationId} fileId={file.id}>
            <CroppedText value={file.description || file.fileName} length={50} />
          </FileDownloadLink>
        </Grid>
        <Grid item xs={12} lg={4}>
          <ClassifierLabel classifierType={ClassifierType.applicationDocumentType} code={file.documentType} />
        </Grid>
        <Box component={Grid} item xs={1} display={{ xs: "none", md: "block" }}>
          <div className={classes.downloadIconContainer}>
            <FileDownloadLink applicationId={applicationId} fileId={file.id}>
              <Icon icon="downloadFile" className={classes.downloadIcon} />
            </FileDownloadLink>
          </div>
        </Box>
      </Grid>
    ))}
  </div>
}
