import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/admin';
import StoreUtils from '../../utils/StoreUtils';
import TableUtils from '../../utils/TableUtils';
import { showSuccess } from '../notification';

const initialRole = { id: null, permissions: [], validToDate: null };
const initialPageable = {
  pageSize: TableUtils.getPageSize(178, true, true),
  pageNumber: 0,
  sort: {
    field: "title",
    ascending: true
  },
  loaded: false,
  last: true
};

// Slice
const slice = createSlice({
  name: 'role',
  initialState: {
    rows: [],
    filteredRows: [],
    pageable: initialPageable,
    filter: {},
    role: initialRole,
    permissions: [],
    isLoading: false,
    error: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    rowsSuccess: (state, action) => {
      state.rows = action.payload;
      state.pageable.loaded = true;
      TableUtils.setFilteredRows(state);
      state.isLoading = false;
    },
    pageableSuccess: (state, action) => {
      if (action.payload.pageNumber) {
        state.pageable.pageNumber += action.payload.pageNumber;
      }
      if (action.payload.sort) {
        state.pageable.sort = action.payload.sort;
        state.pageable.pageNumber = 0;
        state.rows.sort((a, b) => a[state.pageable.sort.field]?.localeCompare(b[state.pageable.sort.field]) * (state.pageable.sort.ascending ? 1 : -1));
      }
      TableUtils.setFilteredRows(state);
      state.pageable.loaded = true;
    },
    filterSuccess: (state, action) => {
      if ((state.filter[action.payload.field] || '') !== action.payload.value) {
        state.filter[action.payload.field] = action.payload.value;
        state.pageable.pageNumber = 0;
        TableUtils.setFilteredRows(state);
        state.pageable.loaded = true;
      }
    },
    roleSuccess: (state, action) => {
      state.role = action.payload;
      state.role.permissions.sort();
      state.isLoading = false;
    },
    permissionsSuccess: (state, action) => {
      state.permissions = action.payload;
      state.isLoading = false;
    },
    addPermission: (state, action) => {
      const newRole = action.payload.role;
      newRole.permissions = state.role.permissions.concat(action.payload.permission).sort();
      state.role = newRole;
    },
    removePermission: (state, action) => {
      const newRole = action.payload.role;
      newRole.permissions = state.role.permissions.filter(p => p !== action.payload.permission).sort();
      state.role = newRole;
    }
  },
});

export default slice.reducer;

// Actions

const {
  roleSuccess, rowsSuccess, pageableSuccess, filterSuccess,
  permissionsSuccess, startLoading, hasError,
  addPermission, removePermission
} = slice.actions;

export const updatePageable = (pageNumber, sort) => async dispatch => {
  dispatch(pageableSuccess({ pageNumber: pageNumber, sort: sort }));
}

export const updateFilter = (field, value) => async dispatch => {
  dispatch(filterSuccess({ field, value }));
}

export const fetchRoles = () => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get('role').then((response) => {
      response.data.sort((a, b) => {
        if (a.valid === b.valid) {
          return a.title.localeCompare(b.title)
        } else {
          return a.valid ? -1 : 1;
        }
      });
      dispatch(rowsSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
}

export const fetchRole = (roleId) => async dispatch => {
  if (!roleId) {
    dispatch(roleSuccess(initialRole));
    return;
  }
  dispatch(startLoading());
  try {
    await api.get(`role/${roleId}`).then((response) => dispatch(roleSuccess(response.data)))
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
}

export const addRole = (role) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.post('role', role).then((response) => dispatch(roleSuccess(response.data)))
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
}

export const saveRole = (role) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`role/${role.id}`, role).then((response) => {
      dispatch(roleSuccess(response.data));
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
}

export const fetchPermissions = () => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get('role/permission').then((response) => dispatch(permissionsSuccess(response.data)))
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
}

export const addRolePermission = (role, permission) => async dispatch => {
  dispatch(addPermission({ role, permission }));
}

export const removeRolePermission = (role, permission) => async dispatch => {
  dispatch(removePermission({ role, permission }));
}

export const invalidateRole = (role) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`role/${role.id}/invalidate`).then((response) => {
      dispatch(showSuccess("form.saved"));
    });
    await dispatch(fetchRoles());
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
}