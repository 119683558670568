import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';

// Slice
const slice = createSlice({
  name: 'notification',
  initialState: {
    message: "",
    open: false,
    showLoadingOveray: false,
    type: "info"
  },
  reducers: {
    hidden: state => {
      state.open = false;
      state.message = "";
    },
    visible: (state, action) => {
      state.open = true;
      state.type = action.payload.type;
      if (action.payload.message) {
        state.message = i18n.t(action.payload.message, action.payload.parameters);
      }
      if (action.payload.messages) {
        state.message = action.payload.messages.map(message => {
          if (message instanceof Object && message.message) {
            return i18n.t(message.message, message.parameters);
          } else {
            return i18n.t(message);
          }
        }).join('\n');
      }
    },
    loadingOverlaySuccess: (state, action) => {
      if (state.showLoadingOveray !== action.payload) {
        state.showLoadingOveray = action.payload;
      }
    }
  },
});

export default slice.reducer;

// Actions

const { hidden, visible, loadingOverlaySuccess } = slice.actions;

export const hideNotification = () => async dispatch => {
  dispatch(hidden());
};

export const showInfo = (message, parameters) => async dispatch => {
  dispatch(visible({ type: "info", message, parameters }));
};

export const showSuccess = (message, parameters) => async dispatch => {
  dispatch(visible({ type: "success", message, parameters }));
};

export const showWarning = (message, parameters) => async dispatch => {
  dispatch(visible({ type: "warning", message, parameters }));
};

export const showWarnings = (messages) => async dispatch => {
  dispatch(visible({ type: "warning", messages }));
};

export const toggleLoadingOverlay = (visible) => async dispatch => {
  dispatch(loadingOverlaySuccess(visible));
};
