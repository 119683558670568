import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Grid } from '@material-ui/core';
import { Paragraph } from 'styleguide';
import logoVald from '../assets/images/logo_viimsi.png';
import logoEu from '../assets/images/logo_eu_eesti.png';
import logoRrf from '../assets/images/logo_rrf.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  textPadding: {
    paddingRight: 28
  },
  logoVald: {
    marginTop: 77,
    marginBottom: 40
  },
  logoEu: {
    marginTop: 40,
    [theme.breakpoints.up('md')]: {
      float: 'right',
      marginTop: -158
    },
  },
  divider: {
    marginTop: 100
  }
}));

export default function Footer() {
  const { t } = useTranslation();
  const classes = useStyles();
  const mobile = useSelector(state => state.global.mobileView);

  return <>
    <Divider variant="middle" className={classes.divider} />

    <img src={logoVald} className={classes.logoVald} alt="logo" />

    <Paragraph fontSize='14'>{t('footer.address')}</Paragraph>
    <Paragraph fontSize='14'>
      <span className={classes.textPadding}>{t('footer.phone')}</span>
      <span className={classes.textPadding}>{t('footer.email')}</span>
      <span>{t('footer.help')}</span>
    </Paragraph>
    {!mobile && <Link to="/openData">{t('openData.title')}</Link>}

    <div className={classes.logoEu}>
      <Grid container alignItems="start" spacing={4}>
        <Grid item xs={12} lg={6}>
          <img src={logoEu}  alt="eu logo" />
        </Grid>
        <Grid item xs={12} lg={6}>
          <img src={logoRrf} alt="rrf logo" />
        </Grid>
      </Grid>
    </div>
  </>;
}