import { IconButton, ListSubheader, makeStyles, Menu, MenuItem, Typography } from "@material-ui/core";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "styleguide";
import { UserType } from "../../constants/authConstants";
import { fetchUserManuals, getUserManualDownloadUrl, setUserManualsOpen } from "../../stores/admin/userManual";

const useStyles = makeStyles((theme) => ({
  menu: {
    [theme.breakpoints.up('md')]: {
      marginTop: 28
    },
  },
}));

export default function UserManuals() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { rows, open, isLoading } = useSelector(state => state.userManual);
  const anchorRef = useRef(null);

  const handleOpen = () => {
    if (!rows) {
      dispatch(fetchUserManuals());
    }
    dispatch(setUserManualsOpen(true));
  };

  const handleClose = () => {
    dispatch(setUserManualsOpen(false));
  };

  const handleRowClick = (manual) => {
    const url = getUserManualDownloadUrl(manual);
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer')
    }
  };

  const renderList = (type) => {
    const listItems = [];
    const filteredRows = rows?.filter(r => type === r.directory);
    if (filteredRows?.length) {
      listItems.push(<ListSubheader key={type}>{t(`header.userManuals.${type}`)}</ListSubheader>);
      filteredRows?.map((manual, index) => listItems.push(
        <MenuItem key={type + index} onClick={() => handleRowClick(manual)}>
          <Typography variant="inherit" noWrap>
            {manual.fileName}
          </Typography>
        </MenuItem>
      ));
    }
    return listItems;
  }

  return <>
    <IconButton onClick={handleOpen} aria-label="show user manuals" title={t('header.help')} disabled={isLoading} ref={anchorRef}>
      <Icon icon={isLoading ? 'loading' : 'question'} />
    </IconButton>
    <Menu
      anchorEl={anchorRef.current}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      onClose={handleClose}
      elevation={2}
      variant="menu"
      className={classes.menu}
    >
      {renderList(UserType.citizen)}
      {renderList(UserType.gov)}
      {renderList(UserType.partner)}
    </Menu>
  </>;
}