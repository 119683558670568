import { AppBar, Dialog, makeStyles, Slide, Toolbar } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'styleguide';
import MultiDrawControl from './controls/MultiDrawControl';
import DefaultMap from './DefaultMap';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    paddingBottom: 88
  },
  toolbar: {
    backgroundColor: 'white',
    [theme.breakpoints.up('md')]: {
      height: 102
    },
  },
  footer: {
    top: 'auto',
    bottom: 0,
    height: 88,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 152,
      paddingRight: 152
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 4,
      paddingRight: 4
    },
  },
  title: {
    flexGrow: 1,
  },
  spacer: {
    width: 16
  }
}));


export default function MapDialog({ geometryTypes, geometry, onConfirm, onClose, disabled, actions, readOnly, allowMultipleFeatures }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [feature, setFeature] = useState(geometry);

  const handleConfirm = () => {
    onConfirm(feature);
  };

  const drawControl = !readOnly &&
    <MultiDrawControl restrictedGeometryTypes={geometryTypes} geometry={geometry} setFeatureCallback={setFeature}
      defaultActive allowSingleFeature={!allowMultipleFeatures} />;

  return <Dialog open={true} fullScreen onClose={onClose} TransitionComponent={Slide}>
    <div className={classes.container}>
      <DefaultMap isDialog extraControls={[drawControl]} />
    </div>
    <AppBar className={classes.footer} color="transparent" position="fixed">
      <Toolbar className={classes.toolbar}>
        <Button disabled={!!disabled} onClick={onClose} color="secondary">{t('button.back')}</Button>
        <div className={classes.title}></div>
        {actions}
        <span className={classes.spacer} />
        {!readOnly && <Button disabled={!!disabled} onClick={handleConfirm}>{t('button.confirm')}</Button>}
      </Toolbar>
    </AppBar>
  </Dialog>;
}