import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router';
import WorkOrderReport from '../components/report/WorkOrderReport';
import BusStopTypeReport from '../components/report/BusStopTypeReport';
import CableTypeReport from '../components/report/CableTypeReport';
import DitchNetworkReport from '../components/report/DitchNetworkReport';
import LampTypesReport from '../components/report/LampTypesReport';
import RainwaterNetworkReport from '../components/report/RainwaterNetworkReport';
import ReportList from '../components/report/ReportList';
import RoadAgeReport from '../components/report/RoadAgeReport';
import RoadBreakdownReport from '../components/report/RoadBreakdownReport';
import RoadSurfacesReport from '../components/report/RoadSurfacesReport';
import RoadTypesReport from '../components/report/RoadTypesReport';
import SettlementReport from '../components/report/SettlementReport';
import ShieldReport from '../components/report/ShieldReport';
import { Domain } from '../constants/classifierConstants';
import PageContainer from '../parts/PageContainer';
import ApplicationReport from '../components/report/ApplicationReport';
import RoadDetailsReport from '../components/report/RoadDetailsReport';
import TrafficReport from '../components/report/TrafficReport';
import RoadIlluminationReport from '../components/report/RoadIlluminationReport';
import AuthUtils from '../utils/AuthUtils';
import RouteUtils from '../utils/RouteUtils';
import BasicObjectReport from '../components/report/BasicObjectReport';

const allowedDomains = [Domain.rainwater, Domain.streetlight, Domain.road];

function ReportRoute({ domain }) {
  return <Route path={`/report/${domain}`} render={() => <ReportList domain={domain} />} exact />
}

export default function Report() {
  const location = useLocation();
  const { authUser } = useSelector(state => state.auth);
  const mobile = useSelector(state => state.global.mobileView);

  useEffect(() => {
    RouteUtils.setDocumentTitle('report');
  }, []);

  if (!!mobile) {
    return <Redirect to="/" />;
  }

  if (location.pathname === '/report' || location.pathname === '/report/') {
    const defaultDomain = AuthUtils.getDefaultDomain(authUser, allowedDomains);
    return <Redirect to={`/report/${defaultDomain}`} />
  }

  return <PageContainer>
    <ReportRoute domain={Domain.rainwater} />
    <ReportRoute domain={Domain.streetlight} />
    <ReportRoute domain={Domain.road} />
    <ReportRoute domain={Domain.infrastructure} />
    <ReportRoute domain={Domain.landscaping} />
    <ReportRoute domain="other" />
    <Route path="/report/settlement/:domain" component={SettlementReport} />
    <Route path="/report/surfaces" component={RoadSurfacesReport} />
    <Route path="/report/roadType" component={RoadTypesReport} />
    <Route path="/report/breakdown" component={RoadBreakdownReport} />
    <Route path="/report/busStopType" component={BusStopTypeReport} />
    <Route path="/report/roadAge" component={RoadAgeReport} />
    <Route path="/report/roadDetails" component={RoadDetailsReport} />
    <Route path="/report/traffic" component={TrafficReport} />
    <Route path="/report/illumination" component={RoadIlluminationReport} />
    <Route path="/report/network" component={RainwaterNetworkReport} />
    <Route path="/report/ditchNetwork" component={DitchNetworkReport} />
    <Route path="/report/lampType" component={LampTypesReport} />
    <Route path="/report/cableType" component={CableTypeReport} />
    <Route path="/report/shield" component={ShieldReport} />
    <Route path="/report/workOrder" component={WorkOrderReport} />
    <Route path="/report/application" component={ApplicationReport} />
    <Route path="/report/AR/:basicObjectTypeId" component={BasicObjectReport} />
    <Route path="/report/HH/:basicObjectTypeId" component={BasicObjectReport} />
  </PageContainer>;
}