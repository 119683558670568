import { useContext, useEffect, useRef } from "react";
import OLControl from "ol/control/Control";
import MapContext from "../MapContext";
import { useDispatch, useSelector } from "react-redux";
import { toggleActiveControl } from "../../../stores/map/map";
import { Colors, Icon, Tooltip } from "styleguide";
import { IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const MapControlButton = ({ controlName, icon, children, controlActive, onClick, tooltipPlacement }) => {
  const { map } = useContext(MapContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activeControl, layerDrawerOpen } = useSelector(state => state.map);
  const ref = useRef(null);

  const active = controlActive !== undefined ? controlActive : activeControl === controlName;

  useEffect(() => {
    if (!map || !ref) return;

    const control = new OLControl({ element: ref.current });

    map.controls.push(control);

    return () => map.controls.remove(control);
  }, [dispatch, map, ref]);

  const handleToggleControl = () => {
    if (onClick) {
      return onClick();
    }
    dispatch(toggleActiveControl(controlName));
  };

  const layerDrawerOpenClass = layerDrawerOpen && !tooltipPlacement ? 'layer-drawer-open' : '';

  return <div>
    <div ref={ref} className={`ol-control ol-${controlName} ${layerDrawerOpenClass}`}>
      <Tooltip title={t(`map.control.${controlName}`)} placement={tooltipPlacement || 'right'} onClick={handleToggleControl}>
        <IconButton className={active ? 'active' : ''}>
          <Icon icon={icon} color={active ? 'white' : Colors.vagaVesi} />
        </IconButton>
      </Tooltip>
      {active && children}
    </div>
  </div>;
};

export default MapControlButton;