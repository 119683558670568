import { Grid, IconButton, List, ListItem, makeStyles, Paper } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Route, useHistory, useParams } from "react-router";
import { fetchClauseConf, saveClause, setClauseValue } from "../../stores/admin/clauseConf";
import { CenteredLoadingIndicator } from "../form/LoadingIndicator";
import { Button, Colors, Heading, Icon, Paragraph } from "styleguide";
import { DialogContainer } from "../form/DialogForm";
import ConfirmButton from "../table/ConfirmButton";
import VTextField from "../form/VTextField";
import { closestCenter, DndContext, DragOverlay, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { ClauseBlockContent } from "../application/clause/ClauseOverview";
import { cloneDeep } from "lodash";
import ClauseGroupConfForm from "./ClauseGroupConfForm";
import ClassifierAutocomplete from "../form/ClassifierAutocomplete";
import { ClassifierType } from "../../constants/classifierConstants";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      width: 1200,
    },
    [theme.breakpoints.down('md')]: {
      width: '95%',
    },
    paddingTop: 12,
    paddingBottom: 88,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  blockContent: {
    lineHeight: '36px'
  },
  draggingPaper: {
    backgroundColor: Colors.hall3
  },
}));

export const allowedParameters = [
  'APPLICANT', 'APPLICANT_EMAIL', 'APPLICANT_PHONE', 'APPLICANT_ADDRESS', 'APPLICANT_CONTACTS',
  'REPRESENTATIVE', 'REPRESENTATIVE_EMAIL', 'REPRESENTATIVE_PHONE', 'REPRESENTATIVE_ADDRESS',
  'SUBMISSION_DATE', 'APPLICATION_NUMBER', 'APPLICATION_KATASTRIYKSUS', 'CLAUSE_NUMBER', 'ROAD_NAMES', 'PROJECT'
];

function EditContentButton({ onConfirm, defaultValue, label, icon, ...rest }) {
  const { t } = useTranslation();
  const [reason, setReason] = useState(defaultValue || '');

  useEffect(() => setReason(defaultValue), [defaultValue]);

  return <ConfirmButton {...rest}
    message={label || t('clause.confirmEditContent')}
    title={t('button.edit')}
    icon={icon || 'edit'}
    aria-label="edit text content"
    isColumnLayout maxWidth="md"
    dialogContent={<Grid container direction="row" spacing={2}>
      <Grid item xs={12}>
        <VTextField autoFocus value={reason} onChange={(event) => setReason(event.target.value)}
          multiline minRows={5} fullWidth />
      </Grid>
      <Grid item xs={12}>
        <Paragraph fontSize="14B">{t('admin.clauseConf.allowedParameters')}</Paragraph>
        {allowedParameters.map(param =>
          <Paragraph key={param} fontSize="12">{`{{${param}}} - ${t('admin.clauseConf.blockParameter.' + param)}`}</Paragraph>
        )}
      </Grid>
    </Grid>}
    onConfirm={() => onConfirm(reason)}
    confirmDisabled={!reason}
  />;
}

export function EditGroupTitleButton({ onConfirm, defaultValue, label, icon, ...rest }) {
  const { t } = useTranslation();
  const [title, setTitle] = useState({});

  return <ConfirmButton {...rest}
    message={t('admin.clauseConf.confirmGroupTitle')}
    icon={icon || 'edit'}
    aria-label="edit group title"
    isColumnLayout maxWidth="lg"
    dialogContent={
      <ClassifierAutocomplete codeValue={title?.itemCode || defaultValue}
        handleChange={(value) => setTitle(value)}
        classifierType={ClassifierType.clauseGroup} autoHighlight
      />
    }
    onConfirm={() => onConfirm(title)}
    confirmDisabled={!title}
  />;
}

function ClauseConf() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const clause = useSelector(state => state.clauseConf.clause);
  const disabled = useSelector(state => state.clauseConf.isLoading);

  const sensors = useSensors(
    useSensor(PointerSensor),
  );
  const [activeGroup, setActiveGroup] = useState();

  const handleAddGroup = async (value) => {
    const group = {
      id: "_" + Date.now(),
      clauseGroupCode: value.itemCode,
      clauseGroupCodeTitle: value.title,
      blocks: [],
      queueNumber: clause.groups.length + 1,
      notSaved: true
    };
    await dispatch(setClauseValue('groups', [...clause.groups, group]));
    handleEditGroup(group);
  };

  const handleEditGroup = (group) => {
    history.push(`${clause.applicationType}/group/${group.id}`);
  };

  const handleDragStart = (event) => {
    setActiveGroup(clause.groups.find(g => g.id === event.active.id));
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = clause.groups.findIndex(g => g.id === active.id);
      const newIndex = clause.groups.findIndex(g => g.id === over.id);

      const items = cloneDeep(arrayMove(clause.groups, oldIndex, newIndex));
      items.forEach((item, index, array) => array[index].queueNumber = index + 1);
      dispatch(setClauseValue('groups', items));
      setActiveGroup(null);
    }
  };

  const handleSave = () => {
    dispatch(saveClause(clause));
  };

  return <DialogContainer
    title={t(`admin.clauseConf.formTitle.${clause.applicationType}`)}
    mainAction={<Button onClick={handleSave} disabled={disabled}>{t('button.save')}</Button>}
    disabled={disabled}
  >
    <div className={classes.container}>
      <List disablePadding>
        <ListItem>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={11} className={classes.blockContent}>
              <Heading level="3">{clause.title}</Heading>
            </Grid>
            <Grid item>
              <EditContentButton onConfirm={(value) => dispatch(setClauseValue('title', value))} title={t('button.edit')}
                defaultValue={clause.title} disabled={disabled} label={t('admin.clauseConf.confirmEditTitle')} />
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <Grid container direction="row">
            <Grid item xs={11} className={classes.blockContent}>
              <Paragraph fontSize="14B">{t('clause.preamble')}</Paragraph>
              {clause.preamble}
            </Grid>
            <Grid item>
              <EditContentButton onConfirm={(value) => dispatch(setClauseValue('preamble', value))} title={t('button.edit')}
                defaultValue={clause.preamble} disabled={disabled} label={t('clause.confirmEditPreamble')} />
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={11} className={classes.blockContent}>
              <Paragraph fontSize="14B">{t('admin.clauseConf.groups')}</Paragraph>
            </Grid>
            <Grid item>
              <EditGroupTitleButton onConfirm={handleAddGroup} disabled={disabled} icon="add" />
            </Grid>
          </Grid>
        </ListItem>
        <DndContext
          modifiers={[restrictToVerticalAxis]}
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={clause.groups}
            strategy={verticalListSortingStrategy}
          >
            {clause.groups.map(group =>
              <SortableGroup key={group.id} group={group} disabled={disabled}
                handleEditGroup={handleEditGroup}
              />
            )}
          </SortableContext>
          <DragOverlay>
            {activeGroup ? <Paper className={classes.draggingPaper}>
              <Group group={activeGroup} disabled={true} />
            </Paper> : null}
          </DragOverlay>
        </DndContext>
      </List>
    </div>
  </DialogContainer>;
}

function SortableGroup(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.group.id || props.group.fid });

  const style = {
    '--translate-x': transform ? transform.x : 0,
    '--translate-y': transform ? transform.y : 0,
    '--transition': transition,
  };

  return <ListItem ref={setNodeRef} style={style}>
    <Group {...props} attributes={attributes} listeners={listeners} />
  </ListItem>;
}

function Group({ group, disabled, attributes, listeners, handleEditGroup }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return <Grid container direction="row" alignItems="center">
    <Grid item xs={11} className={classes.blockContent}>
      <ClauseBlockContent>{group.clauseGroupCodeTitle}</ClauseBlockContent>
    </Grid>
    <Grid item>
      <IconButton title={t('button.edit')} disabled={disabled} onClick={() => handleEditGroup(group)}>
        <Icon icon="edit" />
      </IconButton>
      <IconButton {...attributes} {...listeners} title={t('clause.button.drag')} disabled={disabled}>
        <Icon icon="drag" />
      </IconButton>
    </Grid>
  </Grid>;
}

export default function ClauseConfForm() {
  const dispatch = useDispatch();

  const { applicationType } = useParams();

  const clause = useSelector(state => state.clauseConf.clause);
  const disabled = useSelector(state => state.clauseConf.isLoading);

  //fetch clause
  const shouldFetch = !disabled && !!applicationType && (!clause || clause.applicationType !== applicationType);
  useEffect(() => shouldFetch && dispatch(fetchClauseConf(applicationType)), [dispatch, applicationType, shouldFetch]);

  if (!clause) {
    return <CenteredLoadingIndicator />;
  }

  return <div>
    <Route path="/admin/clauseConf/:applicationType" component={ClauseConf} exact />
    <Route path="/admin/clauseConf/:applicationType/group/:groupId" component={ClauseGroupConfForm} />
  </div>;
}