import { Box } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DateUtils from "../../utils/DateUtils";
import ConfirmButton from "../table/ConfirmButton";
import VDatePicker from "./VDatePicker";
import VDateTimePicker from "./VDateTimePicker";
import VTextField from "./VTextField";

export default function ConfirmButtonWithDeadline({ defaultDeadline, onConfirm, isLoading, withTime, ...rest }) {
  const { t } = useTranslation();
  const [reason, setReason] = useState('');
  const [deadline, setDeadline] = useState(defaultDeadline);

  return <ConfirmButton {...rest}
    isColumnLayout
    dialogContent={<>
      <Box py={2}>
        {!!withTime &&
          <VDateTimePicker label={t('form.deadline')} fullWidth disabled={isLoading}
            value={deadline} onChange={(value) => setDeadline(value)} />
        }
        {!withTime &&
          <VDatePicker label={t('form.deadline')} fullWidth disabled={isLoading}
            value={deadline} onChange={(value) => setDeadline(value)} />
        }
      </Box>
      <Box py={2}>
        <VTextField autoFocus label={t('form.reason')} value={reason} onChange={(event) => setReason(event.target.value)}
          multiline minRows={3} fullWidth />
      </Box>
    </>}
    onConfirm={() => onConfirm(reason, deadline)}
    confirmDisabled={!reason || !deadline || DateUtils.isPast(deadline)}
  />;
}