import { Colors, GraphColors } from "styleguide";

const ChartUtils = {
  calculatePercentage: (total, amount) => (Math.round(((amount + Number.EPSILON) * 10000) / total) / 100) || 0,
  arraySum: (array, field) => Math.round(array.reduce((prevCount, current) => prevCount + (current[field] || 0), 0) * 100) / 100,
  /**
   * Get GraphColor based on index
   * @param index 
   * @returns 
   */
  getGraphColor: (index) => {
    let graphColorIndex;
    switch (index) {
      case 0:
        return Colors.sinineVaal;
      case 1:
        return Colors.paikesekiir;
      case 2:
        return Colors.maismaa;
      case 3:
        return Colors.loojang;
      case 4:
        return Colors.hapumarjapuu;
      case 5:
        return Colors.lavendel;
      default:
        graphColorIndex = index - 5;
    }
    // max different idexes supported
    const correctIndex = graphColorIndex % 20;
    // 4 different colors available
    const remainder = correctIndex % 4;
    // every color has shades 1-5
    let colorIndex = Math.round(correctIndex / 4) + ((correctIndex + 1) % 4);
    let colorName;
    switch (remainder) {
      case 0:
        colorName = 'yellow';
        break;
      case 1:
        colorName = 'deepBlue';
        colorIndex = 6 - colorIndex;
        break;
      case 2:
        colorName = 'lavendel';
        colorIndex = 6 - colorIndex;
        break;
      default:
        colorName = 'vaal';
        colorIndex = 6 - colorIndex;
    }
    return GraphColors[colorName + colorIndex];
  },
  renderLegendText: (value) => <span style={{ color: 'black' }}>{value}</span>,
};

export default ChartUtils;