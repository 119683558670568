import { Box, Grid } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { ClassifierType } from '../../constants/classifierConstants';
import { fetchRowsPartner, getFulfillerTitle, resetRows, setDetails, updateFilter, updatePageable, updateUserFilter } from '../../stores/workOrder/workOrder';
import TableUtils from '../../utils/TableUtils';
import { InfiniteScrollTableContainer } from '../table/PageableTable';
import WorkOrderStatusLabel from './WorkOrderStatusLabel';
import TabContainer from 'styleguide/build/components/Navigation/TabContainer/TabContainer';
import { TableHeader } from 'styleguide';
import CroppedText from '../table/CroppedText';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { TotalElementsLabel } from '../table/TableHeaderWithCount';
import { WorkOrderNumberWithPriority } from './WorkOrderTable';

export default function WorkOrderTablePartner({ isArchive }) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { rows, filter, userFilter, pageable, totalElements, isLoading } = useSelector(state => state.workOrder);
  const { classifiers } = useSelector(state => state.classifier);
  const mobile = useSelector(state => state.global.mobileView);

  const columns = useMemo(() => [
    { 
      field: 'id', headerName: t('workOrder.queueNumber'), type: 'integer', filter: true, renderCell: (column, cell) => (
        <WorkOrderNumberWithPriority number={cell.id} priority={cell.priority}/>
      )
    },
    { field: 'location', headerName: t('workOrder.location'), filter: true },
    {
      field: 'description', headerName: t('workOrder.description'), filter: true, renderCell: (column, cell) => (
        <CroppedText value={cell.description} />
      )
    },
    {
      field: 'userAuthor_id', headerName: t('workOrder.author'), type: "govUser", filter: true,
      renderCell: (column, cell) => cell?.userAuthor?.fullName, sortField: 'userAuthor.person.firstName'
    },
    {
      field: 'fulfiller', headerName: t('workOrder.fulfiller'), sortField: 'userFulfiller.person.firstName',
      renderCell: (column, cell) => getFulfillerTitle(cell)
    },
    { field: "deadline", type: "dateTime", headerName: t('workOrder.deadline') },
    {
      field: 'status', headerName: t('workOrder.status'),
      type: "classifier", classifierType: ClassifierType.workOrderStatus, filter: true, renderCell: (column, cell) =>
        <WorkOrderStatusLabel statusClassifier={TableUtils.getClassifier(classifiers, ClassifierType.workOrderStatus, cell.status)} />
    },
  ], [t, classifiers]);

  useEffect(() => dispatch(resetRows()), [dispatch, isArchive]);
  useEffect(() => !!classifiers.length && !pageable.loaded && dispatch(fetchRowsPartner(isArchive, pageable, filter, columns, userFilter)),
    [dispatch, pageable, filter, columns, classifiers, isArchive, userFilter]);

  const handleRowSelection = (selected) => {
    dispatch(setDetails(selected));
    history.push(`/workOrder/view/${selected.id}`);
  };

  const handleUserFilterChange = (event, filter) => {
    dispatch(updateUserFilter(filter));
  };

  const title = t(isArchive ? 'workOrder.archiveOrders' : 'workOrder.myOrders');

  const header = () => (
    <TableHeader>
      <Grid container direction="column">
        <Box paddingBottom={1}>
          {title}
          <TotalElementsLabel>{totalElements}</TotalElementsLabel>
        </Box>
        <ToggleButtonGroup exclusive
          className="VaalToggleButton"
          value={userFilter}
          onChange={handleUserFilterChange}
          aria-label='user filter buttons'
        >
          <ToggleButton value='forFulfillment' aria-label='for fulfillment by me' disabled={isLoading}>
            {t('workOrder.filter.forFulfillment')}
          </ToggleButton>
          {!isArchive && 
            <ToggleButton value='fulfilled' aria-label='fulfilled' disabled={isLoading}>
              {t('workOrder.filter.fulfilled')}
            </ToggleButton>
          }
        </ToggleButtonGroup>
      </Grid>
    </TableHeader>
  );

  return <div>
    <TabContainer size={mobile ? 'small' : 'big'}>
      <NavLink to={`/workOrder`} exact>{t(`workOrder.tabs.active`)}</NavLink>
      <NavLink to={`/workOrder/archive`} exact>{t(`workOrder.tabs.archive`)}</NavLink>
    </TabContainer>

    <Box my={5}>
      <InfiniteScrollTableContainer
        rows={rows}
        columns={columns}
        filter={filter}
        pageable={pageable}
        header={header()}
        loading={isLoading}
        onRowSelected={handleRowSelection}
        onUpdatePageable={(pageNumber, sort) => dispatch(updatePageable(pageNumber, sort))}
        onUpdateFilter={(field, value) => dispatch(updateFilter(field, value))}
      />
    </Box>
  </div>;
}