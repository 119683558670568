import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { TableHeader } from 'styleguide';
import { setHighlightGeometries } from '../../stores/map/feature';
import { PageableTableContainer } from '../table/PageableTable';
import { showWarning } from '../../stores/notification';
import MapUtils from '../../utils/MapUtils';
import RegisterUtils from '../../utils/RegisterUtils';
import { CenteredLoadingIndicator } from '../form/LoadingIndicator';
import { combineObjects, fetchCombineableObjects, toggleObjectSelected } from '../../stores/register/registerCombine';
import ConfirmButton from '../table/ConfirmButton';

export default function RegisterJoinForm({ compact }) {
  let { objectType, id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const { objects } = useSelector(state => state.register);
  const { rows, isLoading, joinedObjectId } = useSelector(state => state.registerCombine);

  const { path } = useRouteMatch();
  const mapView = path.startsWith('/map');

  const item = objects[objectType];

  //load joinable objects
  useEffect(() => !rows && dispatch(fetchCombineableObjects(objectType, id)), [dispatch, objectType, id, rows]);

  //go back if no rows found
  useEffect(() => {
    if (!!rows && rows.length === 0) {
      history.goBack();
      dispatch(showWarning('validation.noJoinableObjectsFound'));
    }
  }, [rows, history, dispatch]);

  //redirect to joined object
  useEffect(() => {
    if (!isLoading && joinedObjectId) {
      history.push({
        pathname: `/register/edit/${objectType}/${joinedObjectId}`,
        state: {
          from: `/register/view/${objectType}/${joinedObjectId}`
        }
      });
    }
  }, [history, isLoading, joinedObjectId, objectType]);

  if (!item || item.id !== Number(id)) {
    history.goBack();
    return;
  }

  if (!rows) {
    return <CenteredLoadingIndicator />;
  }

  const handleRowSelection = (row) => {
    dispatch(toggleObjectSelected(row.id, !row.rowSelected));
  };

  const handleConfirm = () => {
    const combinedObjects = rows.filter(r => r.rowSelected);
    if (combinedObjects.length) {
      dispatch(combineObjects(objectType, item, combinedObjects));
    } else {
      dispatch(showWarning('validation.noRowsSelected'));
    }
  };

  const handleShowMap = (row) => {
    dispatch(MapUtils.showRegisterObjectOnMap(history, objectType, row));
  };

  let columns = RegisterUtils.getRegisterColumns(objectType, handleShowMap, mapView);

  if (compact) {
    columns = columns.slice(0, 3);
  }

  let handleMouseEnter, handleMouseLeave = null;
  if (mapView) {
    handleMouseEnter = (row) => {
      const geometries = RegisterUtils.getRegisterGeometries(objectType, row);
      if (geometries.length) {
        dispatch(setHighlightGeometries(geometries));
      }
    };
    handleMouseLeave = (row) => {
      dispatch(setHighlightGeometries([]));
    };
  }

  const header = () => (
    <TableHeader buttons={
      <ConfirmButton
        message={t('register.form.confirmJoin')}
        btnText={t('button.join')}
        onConfirm={handleConfirm}
        size="extra-small"
        aria-label="join objects button" disabled={isLoading} />
    }>
      {t('register.form.joinTitle')}
    </TableHeader>
  );

  return <PageableTableContainer
    header={header()}
    rows={rows}
    columns={columns}
    loading={isLoading}
    totalRows={rows.count}
    onRowSelected={handleRowSelection}
    onRowMouseEnter={handleMouseEnter}
    onRowMouseLeave={handleMouseLeave}
    size={compact ? 'small' : 'medium'}
  />;
}
