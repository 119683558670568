import { TableCell } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Paragraph } from 'styleguide';
import { ClassifierType, Domain } from '../../constants/classifierConstants';
import TableUtils from '../../utils/TableUtils';
import TypeReport from './TypeReport';

export default function LampTypesReport() {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    let columns = [
      {
        field: 'power', headerName: t(`register.lamp.power`), notSortable: true, renderCell: (column, cell) => {
          const value = `${TableUtils.formatNumber(cell.power) || 0} ${t('report.table.unitW')}`;
          if (!cell.type) {
            return <Paragraph fontSize='14B'>{value}</Paragraph>;
          }
          return value;
        }
      },
      {
        field: 'count', headerName: t(`report.table.lampCount`), notSortable: true, renderCell: (column, cell) => {
          const value = `${TableUtils.formatNumber(cell.count) || 0} ${t('report.table.unitPieces')}`;
          if (!cell.type) {
            return <Paragraph fontSize='14B'>{value}</Paragraph>;
          }
          return value;
        }
      },
    ];
    return columns;
  }, [t]);

  const handleRenderCollapsibleRow = (row) => {
    if (row.open && row.data) {
      return <>
        <TableCell>{row.data.map((d, i) => <div key={i}>{d.title}</div>)}</TableCell>
        <TableCell>{row.data.map((d, i) =>
          <div key={i}>{!!d.power && `${TableUtils.formatNumber(d.power)} ${t('report.table.unitW')}`}</div>
        )}</TableCell>
        <TableCell></TableCell>
      </>;
    }
  };

  return <TypeReport
    classifierType={ClassifierType.lampType}
    groupedColumns={columns}
    domain={Domain.streetlight}
    reportName="lampType"
    handleRenderCollapsibleRow={handleRenderCollapsibleRow}
    chartField="count"
    typeColumnLabel={t('register.lamp.lampType')}
  />;
}