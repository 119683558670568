import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { debounce, List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { Search, Shadows } from "styleguide";
import { useDispatch, useSelector } from "react-redux";
import { loadEhrGeoinfo, resetAddress, searchAddress } from "../../stores/address";
import { setActiveGeometries, setExtent } from "../../stores/map/map";
import { boundingExtent } from "ol/extent";
import LoadingIndicator from "../form/LoadingIndicator";
import { transform } from "ol/proj";
import AuthUtils from "../../utils/AuthUtils";
import { clearFeatures } from "../../stores/map/feature";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-block',
    marginLeft: 6,
    [theme.breakpoints.up('md')]: {
      width: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '80%'
    },
  },
  searchBar: {
    borderRadius: 8,
    height: 48,
    width: '100%',
    backgroundColor: 'white',
    boxShadow: Shadows.shadow2
  },
  resultList: {
    backgroundColor: 'white',
    borderRadius: 8,
    marginTop: 5
  }
}));

function translateCoordinates(input) {
  var coords = [];
  input = input.trim();
  input = input.replace(/\(\)/g);
  if ((input.match(/\s/g) || []).length > 1) {
    //kui mitu tyhikut siis asendame need koik
    input = input.replace(/\s/g, '');
  }
  var currentValue = '';
  var deg = '';
  var min = '';
  var sec = '';
  var degMinSecMode = /°|'|"|’/.test(input);
  for (var i = 0; i < input.length; i++) {
    var char = input[i];
    if (degMinSecMode) {
      if (/^\d$/.test(char)) {
        if (!currentValue) {
          deg += char;
        }
        else if (currentValue.indexOf('.') === -1) {
          min += char;
        }
        else {
          sec += char;
        }
        continue;
      }

      if (/^°|'|"|’$/.test(char)) {
        //lat/lonile saab sisestada ka minutitena, vajab teisendamist
        if (deg) {
          currentValue += deg;
          deg = '';
        }
        else if (min) {
          currentValue = (parseFloat(currentValue) + parseFloat(min) / 60).toString();
          min = '';
        }
        else if (sec) {
          const valueLatLon = parseFloat(currentValue) + (parseFloat(sec) / 3600);
          sec = '';
          coords.push(valueLatLon);
          currentValue = '';
        }
        continue;
      }

      if (/^\.|,|\s|:|;$/.test(char)) {
        if (currentValue && !isNaN(currentValue)) {
          var valueXY;
          if (sec) {
            valueXY = parseFloat(currentValue) + (parseFloat(sec) / 3600);
            sec = '';
          }
          else {
            valueXY = parseFloat(currentValue);
          }
          coords.push(valueXY);
          currentValue = '';
        }
        continue;
      }
    }
    else {
      if (/^\d$/.test(char)) {
        currentValue += char;
        continue;
      }

      if (/^\.|,$/.test(char) && !/\.|,/.test(currentValue)) {
        currentValue += '.';
        continue;
      }

      if (/^\.|,|\s|:|;$/.test(char)) {
        if (currentValue && !isNaN(currentValue)) {
          coords.push(parseFloat(currentValue));
          currentValue = '';
        }
        continue;
      }
    }
  }
  if (currentValue && !isNaN(currentValue)) {
    var value;
    if (sec) {
      value = parseFloat(currentValue) + (parseFloat(sec) / 3600);
      sec = '';
    }
    else {
      value = parseFloat(currentValue);
    }
    coords.push(value);
  }
  if (coords.length === 1 && /\./.test(coords[0])) {
    var stringValue = coords[0].toString();
    coords = stringValue.split('.');
  }

  if (coords.length === 2) {
    var x, y, lat, lon;
    //kontrollime, et oleks ligikaudu eesti piires
    coords.forEach(function (c) {
      if (c >= 360000 && c <= 745000) {
        x = c;
      }
      else if (c >= 6375000 && c <= 6620000) {
        y = c;
      }
      else if (c > 20 && c < 29) {
        lon = c;
      }
      else if (c > 57 && c < 60) {
        lat = c;
      }
    });

    if (lat && lon) {
      return transform([lon, lat], 'EPSG:4326', 'EPSG:3301');
    }
    if (x && y) {
      return [x, y];
    }
  }
  return null;
}

export default function MapSearch() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const formEl = useRef(null);
  const [coordinateResult, setCoordinateResult] = useState(null);

  const { rows, isLoading } = useSelector(state => state.address);
  const authUser = useSelector(state => state.auth.authUser);

  const handleSearch = debounce((value) => {
    if (coordinateResult) setCoordinateResult(null);
    const coordinates = translateCoordinates(value);
    if (coordinates) {
      setCoordinateResult({
        label: t('map.control.coordinateResult', { coordinates: value }),
        coordinates
      });
    } else {
      dispatch(searchAddress(value));
    }
  }, 500);

  const handleSelect = (address) => {
    dispatch(clearFeatures());
    if (address.bbox) {
      const extent = new boundingExtent(address.bbox.split(' ').map(coordsString => coordsString.split(',').map(c => Number(c))));
      dispatch(setExtent(extent));
    }
    if (address.coordinates) {
      dispatch(setActiveGeometries([{
        type: 'Point',
        coordinates: address.coordinates
      }], false, !!address.bbox));
    }

    dispatch(resetAddress());
    if (coordinateResult) setCoordinateResult(null);
    formEl.current.reset();

    if (address.address && (AuthUtils.isGov(authUser) || AuthUtils.isPartner(authUser))) {
      dispatch(loadEhrGeoinfo(address.address));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (coordinateResult) {
      handleSelect(coordinateResult);
    } else if (rows.length > 0) {
      handleSelect(rows[0]);
    }
  };

  return <div className={classes.container}>
    <form ref={formEl} onSubmit={handleSubmit}>
      <Search hideIcon
        className={classes.searchBar}
        placeholder={t('map.control.searchPlaceholder')}
        onChange={handleSearch}
        minCharacters={3}
        autoFocus
      />
    </form>
    {
      (isLoading || !!rows.length || !!coordinateResult) && <List dense className={classes.resultList}>
        {isLoading && <LoadingIndicator />}
        {!isLoading && rows.map((row, index) => (
          <ListItem key={index} button onClick={() => handleSelect(row)}>
            <ListItemText secondary={row.address} />
          </ListItem>
        ))}
        {!!coordinateResult && (
          <ListItem key="coordinates" button onClick={() => handleSelect(coordinateResult)}>
            <ListItemText secondary={coordinateResult.label} />
          </ListItem>
        )}
      </List>
    }
  </div >;
};