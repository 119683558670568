import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/application';
import StoreUtils from '../../utils/StoreUtils';
import { showSuccess } from '../notification';
import { setApplicationField } from './application';

const apiPath = (applicationId) => `application/${applicationId}`;

// Slice
const slice = createSlice({
  name: 'applicationPurpose',
  initialState: {
    groupsArray: null,
    purposeGroups: null,
    purposeStatus: null,
    isDirtyStore: false,
    isLoading: false,
    error: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    groupsArraySuccess: (state, action) => {
      state.groupsArray = action.payload;
    },
    rowsSuccess: (state, action) => {
      const groups = action.payload.groups;
      state.groupsArray = groups;
      const typeConfig = action.payload.typeConfig;
      let purposeGroups = {};
      if (typeConfig?.groups?.length) {
        typeConfig.groups.forEach(groupConfig => {
          const group = groups.find(g => g.purposeGroup === groupConfig.purposeGroup);
          let purposes = {};
          groupConfig.purposes.forEach(purposeConfig => {
            const purpose = group?.purposes.find(p => p.purposeType === purposeConfig.purposeType);
            purposes[purposeConfig.purposeType] = {
              id: purpose?.id,
              purposeType: purposeConfig.purposeType,
              queueNumberInGroup: purposeConfig.queueNumberInGroup,
              valueBoolean: purpose?.valueBoolean || false,
              valueString: purpose?.valueString || null,
              valueNumber: purpose?.valueNumber || null,
              valueDateTimeFrom: purpose?.valueDateTimeFrom || null,
              valueDateTimeTo: purpose?.valueDateTimeTo || null
            };
          });
          purposeGroups[groupConfig.purposeGroup] = {
            id: group?.id,
            isGroupSelected: !!group?.isGroupSelected,
            purposeGroup: groupConfig.purposeGroup,
            queueNumber: groupConfig.queueNumber,
            purposes: purposes
          };
        });
      }
      state.purposeGroups = purposeGroups;
      state.isDirtyStore = false;
      state.isLoading = false;
    },
    relatedPlanningSuccess: (state) => {
      state.isDirtyStore = false;
      state.isLoading = false;
    },
    groupToggled: (state, action) => {
      state.purposeGroups[action.payload.purposeGroup].isGroupSelected = !action.payload.isGroupSelected;
      state.isDirtyStore = true;
    },
    savedSuccess: (state, action) => {
      state.purposeStatus = 'OK';
      state.isDirtyStore = false;
      state.isLoading = false;
    },
    purposeStatusSuccess: (state, action) => {
      state.purposeStatus = action.payload;
    },
    resetSuccess: state => {
      state.groupsArray = null;
      state.purposeGroups = null;
      state.purposeStatus = null;
      state.isDirtyStore = false;
    }
  },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, groupsArraySuccess, rowsSuccess, relatedPlanningSuccess, groupToggled, savedSuccess, purposeStatusSuccess, resetSuccess } = slice.actions;

export const setPurposeGroups = (groups) => async dispatch => {
  dispatch(groupsArraySuccess(groups));
};

export const setPurposes = (groups, typeConfig) => async dispatch => {
  dispatch(rowsSuccess({ groups: groups, typeConfig: typeConfig }));
};

export const fetchPurposes = (applicationId, typeConfig) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(`${apiPath(applicationId)}/purposes`).then((response) => {
      dispatch(setPurposeGroups(response.data.groups));
      dispatch(rowsSuccess({ groups: response.data.groups, typeConfig: typeConfig }));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const updateRelatedPlanning = (applicationId, typeConfig) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath(applicationId)}/relatedPlanning`).then((response) => {
      dispatch(relatedPlanningSuccess());
      dispatch(fetchPurposes(applicationId, typeConfig));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const togglePurposeGroup = (group) => async dispatch => {
  dispatch(groupToggled(group));
};

export const setPurposeStatus = (stepStatus) => async dispatch => {
  dispatch(purposeStatusSuccess(stepStatus));
};

export const savePurposeGroups = (applicationId, purposeGroups, typeConfig) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath(applicationId)}/purposes`, purposeGroups).then((response) => {
      dispatch(rowsSuccess({ groups: response.data.groups, typeConfig: typeConfig }));
      dispatch(savedSuccess());
      dispatch(setApplicationField('purpose', purposeGroups.purpose));
      dispatch(setApplicationField('isYpAppropriate', purposeGroups.isYpAppropriate));
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const resetPurpose = () => async dispatch => {
  dispatch(resetSuccess());
};
