import { Box, Grid, IconButton, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ActivityLogTable from '../table/ActivityLogTable';
import { api } from '../../api/application';
import { changeHandler, coordinate, fetchCoordinators, makeDecision, planDontInitiate, planDontInvalidate, resetSubmitted, saveDeadline, sendForCoordination, sendForProcess, sendForReview, signHandler, signSigner } from '../../stores/application/application';
import { ApplicationDecision, ApplicationStatus, ApplicationType, ClassifierType, CoordinationDecision, Domain } from '../../constants/classifierConstants';
import ConfirmButton, { ConfirmButtonWithReason } from '../table/ConfirmButton';
import { Button, Colors, Icon, Paragraph, VAutocomplete } from 'styleguide';
import { useTranslation } from 'react-i18next';
import GovUserSelect from '../form/GovUserSelect';
import VTextField from '../form/VTextField';
import { clauseSubmit, deleteClause, fetchDraftsmen, sendToPartner } from '../../stores/application/clause';
import { CenteredLoadingIndicator } from '../form/LoadingIndicator';
import VDatePicker from '../form/VDatePicker';
import ApplicationUtils from '../../utils/ApplicationUtils';
import ClassifierAutocomplete from '../form/ClassifierAutocomplete';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import DateUtils from '../../utils/DateUtils';
import ConfirmButtonWithDeadline from '../form/ConfirmButtonWithDeadline';

const useStyles = makeStyles({
  formBlock: {
    maxWidth: 846,
    margin: 'auto',
    padding: 25
  },
  formBlockContainer: {
    padding: 12,
    backgroundColor: Colors.hall3,
    borderRadius: 4
  },
  uploadRow: {
    cursor: 'pointer'
  },
  dragActiveUpload: {
    backgroundColor: Colors.withOpacity(Colors.sinineVaal, 0.1),
  },
  textField: {
    width: 416
  },
  dialogContent: {
    marginBottom: 0
  }
});

export function ConfirmWithHandlerSelect({ domain, onConfirm, isLoading, ...rest }) {
  const { t } = useTranslation();
  const [reason, setReason] = useState('');
  const [handler, setHandler] = useState();

  return <ConfirmButton {...rest}
    isColumnLayout
    dialogContent={<>
      <Box py={2}>
        <GovUserSelect autoFocus autoHighlight
          label={t('application.handler')} domain={domain} defaultValue={handler} handleChange={setHandler} />
      </Box>
      <Box py={2}>
        <VTextField label={t('form.reason')} value={reason} onChange={(event) => setReason(event.target.value)}
          multiline minRows={3} fullWidth />
      </Box>
    </>}
    onConfirm={() => onConfirm(reason, handler)}
    confirmDisabled={!handler}
  />;
}

function ConfirmSendForCoordination({ applicationId, onConfirm, isLoading, ...rest }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [reason, setReason] = useState('');
  const [handlers, setHandlers] = useState([]);
  const [deadline, setDeadline] = useState();

  const coordinators = useSelector(state => state.application.coordinators);

  const submitDisabled = !deadline || !reason || !handlers.length;

  useEffect(() => {
    if (coordinators) {
      setDeadline(coordinators.coordinationDeadline);
    }
  }, [coordinators])

  const handleConfirm = () => {
    const dto = {
      coordinationDeadline: deadline,
      userIds: handlers.map(h => h.userId),
      description: reason
    };
    onConfirm(dto);
  };

  const renderDialogContent = () => {
    if (!coordinators) {
      return <CenteredLoadingIndicator />;
    }
    return <>
      <Box py={2}>
        <VAutocomplete multiple
          value={handlers}
          onChange={(event, value) => setHandlers(value)}
          options={coordinators.coordinators}
          getOptionLabel={(o) => o.fullName}
          disableClearable disableCloseOnSelect
          getOptionSelected={(option, value) => option.userId === value.userId}
          disabled={isLoading}
          renderInput={(params) =>
            <VTextField {...params} label={t('application.coordinators')} fullWidth />
          }
        />
      </Box>
      <Box py={2}>
        <VDatePicker label={t('application.deadline')} value={deadline} onChange={(value) => setDeadline(value)} minDate={new Date()} />
      </Box>
      <Box py={2}>
        <VTextField label={t('form.reason')} value={reason} onChange={(event) => setReason(event.target.value)}
          multiline minRows={3} fullWidth />
      </Box>
    </>;
  };

  return <ConfirmButton {...rest}
    isColumnLayout
    dialogContent={renderDialogContent()}
    onConfirm={handleConfirm}
    onOpen={() => dispatch(fetchCoordinators(applicationId))}
    confirmDisabled={submitDisabled}
  />;
}

function ConfirmCoordinate({ onConfirm, isLoading, ...rest }) {
  const { t } = useTranslation();

  const [reason, setReason] = useState('');
  const [decision, setDecision] = useState(null);

  const submitDisabled = decision === null || (decision?.itemCode === CoordinationDecision.no && !reason);

  const handleConfirm = () => {
    const dto = {
      decision: decision.itemCode,
      reason
    };
    onConfirm(dto);
  };

  const renderDialogContent = () => {
    return <>
      <Box py={2}>
        <ClassifierAutocomplete value={decision} label={t('application.coordinationDecision')}
          handleChange={setDecision} classifierType={ClassifierType.coordinationDecision}
          getOptionLabel={(o) => o.title || ''} autoHighlight />
      </Box>
      <Box py={2}>
        <VTextField label={t('form.reason')} value={reason} onChange={(event) => setReason(event.target.value)}
          multiline minRows={3} fullWidth />
      </Box>
    </>;
  };

  return <ConfirmButton {...rest}
    isColumnLayout
    dialogContent={renderDialogContent()}
    onConfirm={handleConfirm}
    confirmDisabled={submitDisabled}
  />;
}

function ConfirmSubmitPlan({ initiate, onConfirm, isLoading, ...rest }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [file, setFile] = useState(null);
  const [link, setLink] = useState('');
  const [documentDate, setDocumentDate] = useState(new Date());

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open
  } = useDropzone({
    noClick: true,
    onDrop: files => {
      setFile(files[0]);
    }
  });

  const submitDisabled = (!file && !link) || !documentDate;

  const handleConfirm = () => {
    const dto = { initiate, file, url: link, documentDate: DateUtils.formatISODate(documentDate) };
    onConfirm(dto);
  };

  const renderDialogContent = () => {
    return <Grid container direction="column" spacing={4} className={classes.dialogContent}>
      <Grid item>
        {!!file ?
          <Grid container direction="row" justifyContent="space-between" className={classes.formBlockContainer}>
            <Paragraph fontSize='12'>{file.name}</Paragraph>
            <IconButton size="small" onClick={() => setFile(null)} aria-label="delete file">
              <Icon icon="delete" />
            </IconButton>
          </Grid>
          :
          <div {...getRootProps({ onClick: e => e.preventDefault(), className: clsx(classes.formBlockContainer, isDragActive && classes.dragActiveUpload) })}>
            <Grid container direction="row" className={classes.uploadRow} onClick={open}>
              <Icon icon='add' color={Colors.sinineVaal} />
              <Box marginLeft={2}>
                <Paragraph fontSize='14' color={Colors.sinineVaal}>{t('register.relatedDocument.chooseFile')}</Paragraph>
              </Box>
              <input type="file" {...getInputProps()} />
            </Grid>
          </div>
        }
      </Grid>
      <Grid item>
        <VTextField label={t('plan.documentLink')} value={link} onChange={(event) => setLink(event.target.value)} fullWidth
          disabled={!!file} />
      </Grid>
      <Grid item>
        <VDatePicker label={t('plan.documentDate')} value={documentDate} onChange={(value) => setDocumentDate(value)} />
      </Grid>
    </Grid>;
  };

  return <ConfirmButton {...rest}
    isColumnLayout
    dialogContent={renderDialogContent()}
    onConfirm={handleConfirm}
    confirmDisabled={submitDisabled}
  />;
}
function ConfirmWithPartnerSelect({ applicationId, onConfirm, isLoading, ...rest }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [reason, setReason] = useState('');
  const [handler, setHandler] = useState(null);
  const [deadline, setDeadline] = useState();

  const draftsmen = useSelector(state => state.clause.draftsmen);

  const submitDisabled = !deadline || !reason || !handler;

  useEffect(() => {
    if (draftsmen) {
      setDeadline(draftsmen.termsDeadline);
    }
  }, [draftsmen])

  const handleConfirm = () => {
    const dto = {
      termsDeadline: deadline,
      userId: handler.userId,
      reason
    };
    onConfirm(dto);
  };

  const renderDialogContent = () => {
    if (!draftsmen) {
      return <CenteredLoadingIndicator />;
    }
    return <>
      <Box py={2}>
        <VAutocomplete
          value={handler}
          onChange={(event, value) => setHandler(value)}
          options={draftsmen.draftsmen}
          getOptionLabel={(o) => o.name}
          disableClearable
          getOptionSelected={(option, value) => option.userId === value.userId}
          disabled={isLoading}
          renderInput={(params) =>
            <VTextField {...params} label={t('clause.draftsman')} fullWidth />
          }
        />
      </Box>
      <Box py={2}>
        <VDatePicker label={t('application.deadline')} value={deadline} onChange={(value) => setDeadline(value)} minDate={new Date()} />
      </Box>
      <Box py={2}>
        <VTextField label={t('form.reason')} value={reason} onChange={(event) => setReason(event.target.value)}
          multiline minRows={3} fullWidth />
      </Box>
    </>;
  };

  return <ConfirmButton {...rest}
    isColumnLayout
    dialogContent={renderDialogContent()}
    onConfirm={handleConfirm}
    onOpen={() => dispatch(fetchDraftsmen(applicationId))}
    confirmDisabled={submitDisabled}
  />;
}

export default function ApplicationActions() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { selected: application, submitted, isLoading } = useSelector(state => state.application);
  const isLoadingClause = useSelector(state => state.clause.isLoading);
  const authUser = useSelector(state => state.auth.authUser);

  const disabled = isLoading || isLoadingClause;
  const domain = application.domain;
  useEffect(() => {
    if (submitted) {
      dispatch(resetSubmitted());
      history.push(`/application/${domain}`);
    }
  }, [dispatch, history, submitted, domain]);

  const fetchLogsUrl = `application/${application.id}/log`;

  const handleDecision = (decision, reason) => {
    dispatch(makeDecision(application.id, decision, reason));
  };

  const handleSendForReview = (reason, handler) => {
    dispatch(sendForReview(application.id, reason, handler.id));
  };

  const handleSendForProcess = (reason, handler) => {
    dispatch(sendForProcess(application.id, reason, handler.id));
  };

  const handleChangeHandler = (reason, handler) => {
    dispatch(changeHandler(application.id, reason, handler.id));
  };

  const handleSendToPartner = (dto) => {
    dispatch(sendToPartner(application.id, dto));
  };

  const handleSendForCoordination = (dto) => {
    dispatch(sendForCoordination(application.id, dto));
  };

  const handleCoordinate = (dto) => {
    dispatch(coordinate(application.id, dto));
  }

  const handleCreateClause = () => {
    history.push(`/application/clause/${application.id}`);
  };

  const handleDeleteClause = () => {
    dispatch(deleteClause(application.id));
  };

  const handleClauseSubmit = () => {
    dispatch(clauseSubmit(application.id));
  };

  const handleDontInitiatePlan = (dto) => {
    dispatch(planDontInitiate(application.id, dto));
  };

  const handleDontInvalidatePlan = (dto) => {
    dispatch(planDontInvalidate(application.id, dto));
  };

  const handleSignHandler = () => {
    dispatch(signHandler(application.id, authUser, () => history.goBack()));
    history.push('sign');
  };

  const handleSignSigner = () => {
    dispatch(signSigner(application.id, authUser, () => history.goBack()));
    history.push('sign');
  };

  const handleSubmitPlan = () => {
    history.push('initiate');
  }

  const handleInvalidatePlan = () => {
    history.push('invalidate');
  }

  const handleChangeDeadline = (reason, deadline) => {
    dispatch(saveDeadline(application.id, deadline, reason));
  };

  let mainActions = [];
  let secondaryActions = [];

  const createClauseButton = <Button onClick={handleCreateClause} disabled={disabled}>
    {t('application.view.createClause')}
  </Button>;
  const viewClauseButton = <Button onClick={handleCreateClause} disabled={disabled}>
    {t('application.view.viewClause')}
  </Button>;

  const isHandler = ApplicationUtils.isHandler(authUser, application);
  const isHandlerOrSubstituter = ApplicationUtils.isHandlerOrSubstiturer(authUser, application);
  const isPartner = ApplicationUtils.isPartner(authUser, application);

  switch (application.status) {
    case ApplicationStatus.review:
      if (isHandler) {
        mainActions.push(
          <Button onClick={() => handleDecision(ApplicationDecision.process)} disabled={disabled}>
            {t('application.view.process')}
          </Button>
        );
        mainActions.push(
          <ConfirmButtonWithReason message={t('application.view.confirmRenew')} btnText={t('application.view.renew')}
            color="secondary" onConfirm={(reason) => handleDecision(ApplicationDecision.renew, reason)}
            aria-label="send application for renew" disabled={disabled} />
        );

        secondaryActions.push(
          <ConfirmButtonWithReason message={t('application.view.confirmReject')} btnText={t('application.view.reject')}
            color="tertiary" onConfirm={(reason) => handleDecision(ApplicationDecision.reject, reason)}
            aria-label="reject application" disabled={disabled} />
        );
      }

      if (authUser.appDomainSupervisor?.includes(application.domain)) {
        secondaryActions.push(
          <ConfirmWithHandlerSelect message={t('application.view.confirmSendForReview')} btnText={t('application.view.sendForReview')}
            color="secondary" onConfirm={handleSendForReview}
            aria-label="send application for review" disabled={disabled} />
        );
        secondaryActions.push(<ConfirmWithHandlerSelect
          message={t('application.view.confirmSendForProcess')} btnText={t('application.view.sendForProcess')}
          color="secondary" onConfirm={handleSendForProcess}
          aria-label="send application for processing" disabled={disabled} />
        );
      }
      break;
    case ApplicationStatus.processing:
      if (isHandler) {
        if (ApplicationType.DP === application.applicationType || ApplicationType.YP === application.applicationType) {
          mainActions.push(<Button onClick={handleSubmitPlan} aria-label="initiate plan" disabled={disabled}>{t('plan.view.initiate')}</Button>);

          mainActions.push(<ConfirmSubmitPlan initiate={false}
            message={t('plan.view.confirmDontInitiate')} btnText={t('plan.view.dontInitiate')}
            color="secondary" onConfirm={handleDontInitiatePlan}
            aria-label="dont initiate plan" disabled={disabled} />
          );
        } else if (ApplicationType.KT === application.applicationType) {
          mainActions.push(<Button onClick={handleInvalidatePlan} aria-label="invalidate plan" disabled={disabled}>{t('plan.view.invalidate')}</Button>);

          mainActions.push(<ConfirmSubmitPlan initiate={false}
            message={t('plan.view.confirmDontInvalidate')} btnText={t('plan.view.dontInvalidate')}
            color="secondary" onConfirm={handleDontInvalidatePlan}
            aria-label="dont invalidate plan" disabled={disabled} />
          );
        } else {
          mainActions.push(createClauseButton);

          if (Domain.streetlight === application.domain) {
            mainActions.push(<ConfirmWithPartnerSelect
              message={t('application.view.confirmSendToPartner')} btnText={t('application.view.sendToPartner')}
              color="secondary" onConfirm={handleSendToPartner} applicationId={application.id}
              aria-label="send clause to partner" disabled={disabled} />
            );
          }

          if (!!application.signatoryId) {
            mainActions.push(<Button onClick={handleSignHandler} aria-label="sign application" disabled={disabled}>{t('application.view.sign')}</Button>
            );
          }

          secondaryActions.push(<ConfirmSendForCoordination
            message={t('application.view.confirmSendForCoordination')} btnText={t('application.view.sendForCoordination')}
            color="secondary" onConfirm={handleSendForCoordination} applicationId={application.id}
            aria-label="send application for coordination" disabled={disabled} />
          );

          secondaryActions.push(
            <ConfirmButton message={t('application.view.confirmDeleteClause')} btnText={t('application.view.deleteClause')}
              color="tertiary" onConfirm={handleDeleteClause} aria-label="reject application" disabled={disabled} />
          );
        }
        secondaryActions.push(
          <ConfirmButtonWithDeadline
            message={t('application.view.confirmChangeDeadline')}
            btnText={t('application.view.changeDeadline')}
            color="secondary" onConfirm={handleChangeDeadline} defaultDeadline={application.deadline}
            aria-label="change application deadline" disabled={disabled} />
        );
      }
      if (isHandlerOrSubstituter) {
        secondaryActions.push(<ConfirmWithHandlerSelect
          message={t('application.view.confirmSendForProcess')} btnText={t('application.view.sendForProcess')}
          color="secondary" onConfirm={handleChangeHandler}
          aria-label="send application for processing" disabled={disabled} />
        );
      }
      break;
    case ApplicationStatus.drafting:
      if (isHandler) {
        mainActions.push(viewClauseButton);
      }
      if (isPartner) {
        mainActions.push(createClauseButton);
        mainActions.push(<Button onClick={handleClauseSubmit} disabled={disabled}>
          {t('application.view.clauseSubmit')}
        </Button>);
      }
      break;
    case ApplicationStatus.coordinating:
      if (isHandler) {
        mainActions.push(viewClauseButton);
      }
      if (application.isCoordinator) {
        mainActions.push(viewClauseButton);

        mainActions.push(<ConfirmCoordinate
          message={t('application.view.confirmCoordinate')} btnText={t('application.view.coordinate')}
          onConfirm={handleCoordinate} applicationId={application.id} aria-label="coordinate application" disabled={disabled} />
        );
      }
      break;
    case ApplicationStatus.signing:
      if (ApplicationUtils.isSigner(authUser, application)) {
        mainActions.push(viewClauseButton);

        mainActions.push(<Button onClick={handleSignSigner} aria-label="sign application" disabled={disabled}>{t('application.view.sign')}</Button>
        );
      }
      break;
    default:
  }

  return <div>
    <Box paddingBottom={4}>
      <ActivityLogTable
        api={api}
        fetchLogsUrl={fetchLogsUrl}
        singleRowOpenable
      />
    </Box>

    <Grid container direction="row" spacing={4}>
      {mainActions.map((action, index) => <Grid key={index} item>{action}</Grid>)}
      <Box flexGrow={1}></Box>
      {secondaryActions.map((action, index) => <Grid key={index} item>{action}</Grid>)}
    </Grid>
  </div>;
}