import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/application';
import i18n from '../../i18n';
import StoreUtils from '../../utils/StoreUtils';
import {
  getSigningCertificate,
  sign,
  ErrorCode
} from '@web-eid/web-eid-library/dist/es/web-eid';

// Slice
const slice = createSlice({
  name: 'sign',
  initialState: {
    isLoading: false,
    error: false,
    message: '',
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    endLoading: state => {
      state.isLoading = false;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    messageSuccess: (state, action) => {
      state.message = action.payload;
    }
  },
});

export default slice.reducer;

// Actions

const { startLoading, endLoading, hasError, messageSuccess } = slice.actions;

export const doSign = (path, authUser, onSuccess, onError) => async dispatch => {
  dispatch(startLoading());
  try {
    //TODO get method from user
    const signingMethod = authUser.authMethod;
    switch (signingMethod) {
      case null:
      case 'mID':
        dispatch(messageSuccess(i18n.t('sign.midStart')));
        const midSession = await midStart(path);
        if (midSession) {
          dispatch(messageSuccess(i18n.t('sign.midChallenge', { challengeId: midSession.challengeId })));
          dispatch(endLoading());
          await new Promise(resolve => setTimeout(resolve, 5000));
          while (true) {
            const midSignResponse = await midPoll(path, midSession);
            if (midSignResponse?.success) {
              onSuccess();
              return;
            }
            await new Promise(resolve => setTimeout(resolve, 2000));
          }
        }
        break;
      case 'smartid':
        dispatch(messageSuccess(i18n.t('sign.sidStart')));
        const sidSession = await sidStart(path);
        if (sidSession) {
          dispatch(messageSuccess(i18n.t('sign.sidCertificate')));
          while (true) {
            const sidCertResponse = await sidCertPoll(path, sidSession);
            if (sidCertResponse?.success) {
              dispatch(messageSuccess(i18n.t('sign.sidChallenge', { challengeId: sidCertResponse.challengeId })));
              dispatch(endLoading());
              while (true) {
                const sidSignResponse = await sidSignPoll(path, sidCertResponse);
                if (sidSignResponse?.success) {
                  onSuccess();
                  return;
                }
                await new Promise(resolve => setTimeout(resolve, 2000));
              }
            }
            await new Promise(resolve => setTimeout(resolve, 2000));
          }
        }
        break;
      case 'idcard':
        dispatch(messageSuccess(i18n.t('sign.idStart')));
        const lang = i18n.language === "et-EE" ? 'et' : 'en';
        const {
          certificate
        } = await getSigningCertificate({ lang });

        dispatch(messageSuccess(i18n.t('sign.idPrepare')));
        const idPrepareResponse = await idPrepare(path, { certificate });
        if (idPrepareResponse) {
          dispatch(messageSuccess(i18n.t('sign.idSign')));
          const {
            signature
          } = await sign(certificate, idPrepareResponse.dataToSignHash, idPrepareResponse.digestAlgorithm, { lang });

          dispatch(messageSuccess(i18n.t('sign.idFinalize')));
          await idFinalize(path, {
            containerId: idPrepareResponse.containerId,
            signatureId: idPrepareResponse.generatedSignatureId,
            signature
          });
          onSuccess();
          return;
        }
        break;
      default:
        onError();
    }
  } catch (e) {
    if (!e.code || !ErrorCode[e.code]) {
      dispatch(StoreUtils.handleError(e, hasError));
    }
    onError();
  }
};

const midStart = (path) => {
  return api.post(`${path}/mid/start`).then((response) => response.data);
};

const midPoll = (path, data) => {
  return api.patch(`${path}/mid/poll`, data).then((response) => response.data);
};

const sidStart = (path) => {
  return api.post(`${path}/smartid/start`).then((response) => response.data);
};

const sidCertPoll = (path, data) => {
  return api.post(`${path}/smartid/cert/poll`, data).then((response) => response.data);
};

const sidSignPoll = (path, data) => {
  return api.post(`${path}/smartid/signing/poll`, data).then((response) => response.data);
};

const idPrepare = (path, data) => {
  return api.post(`${path}/idcard/prepare-remote-signing`, data).then((response) => response.data);
};

const idFinalize = (path, data) => {
  return api.post(`${path}/idcard/finalize-remote-signing`, data).then((response) => response.data);
};