import React from 'react';
import { useTranslation } from 'react-i18next';
import { BasicLabel } from 'styleguide';
import DateUtils from '../../utils/DateUtils';

export default function ValidIcon({ valid, validFrom, validTo}) {
  const { t } = useTranslation();

  if(valid === undefined){
    if(!validFrom) {
      return <div></div>;
    }

    valid = DateUtils.isValid(validFrom, validTo);
  }

  if(valid) {
    return <BasicLabel color='green'>{t('table.valid')}</BasicLabel>;
  }
  
  return <BasicLabel color='red'>{t('table.invalid')}</BasicLabel>;
}
