import { ClickAwayListener, Grid, IconButton, InputAdornment, List, ListItem, ListItemText, makeStyles, Paper, Popper, TextField } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon, Shadows } from "styleguide";
import DayPicker from 'react-day-picker';
import { addMonths, addYears, endOfMonth, endOfYear, isBefore } from 'date-fns';
import DateUtils from "../../utils/DateUtils";

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: 1500
  },
  datePanel: {
    width: 848,
    height: 303,
    boxShadow: Shadows.shadow1,
    paddingTop: 5,
    paddingBottom: 5,
  },
  periodListItem: {
    fontSize: 12,
    lineHeight: 18
  }
}));

const WEEKDAYS_SHORT = {
  et: ['P', 'E', 'T', 'K', 'N', 'R', 'L']
};
const MONTHS = {
  et: [
    'Jaanuar',
    'Veebruar',
    'Märts',
    'Aprill',
    'Mai',
    'Juuni',
    'Juuli',
    'August',
    'September',
    'Oktoober',
    'November',
    'Detsember',
  ]
};

const WEEKDAYS_LONG = {
  et: [
    'Pühapäev',
    'Esmaspäev',
    'Teisipäev',
    'Kolmapäev',
    'Neljapäev',
    'Reede',
    'Laupäev',
  ]
};

const FIRST_DAY_OF_WEEK = {
  et: 1,
};
// Translate aria-labels
const LABELS = {
  et: { nextMonth: 'Järgmine kuu', previousMonth: 'Eelmine kuu' }
};

const getFormattedValue = (value) => {
  let text = "";
  if (value?.from) {
    text = DateUtils.formatDate(value.from);
  }
  if (value?.to) {
    text += " - ".concat(DateUtils.formatDate(value.to));
  }
  return text;
};

function DatePanel({ open, value, anchorEl, onChange, handleClose, fromDisabled }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [enteredTo, setEnteredTo] = useState();

  const modifiers = { start: from, end: enteredTo };
  const disabledDays = { before: from };
  const selectedDays = [from, { from, to: enteredTo }];

  useEffect(() => {
    setFrom(DateUtils.parse(value?.from));
    setTo(DateUtils.parse(value?.to));
    setEnteredTo(DateUtils.parse(value?.to));
  }, [value]);

  const isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && isBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  const handleDayClick = (day) => {
    if (from && to && day >= from && day <= to && !fromDisabled) {
      handleResetClick();
      return;
    }
    if (!fromDisabled && isSelectingFirstDay(from, to, day)) {
      setFrom(day);
      setTo(null);
      setEnteredTo(null);
      onChange({ from: day, to: null });
    } else if (!isBefore(day, from)) {
      setTo(day);
      setEnteredTo(day);
      onChange({ from: from, to: day });
      handleClose();
    }
  };

  const handleDayMouseEnter = (day) => {
    if (!isSelectingFirstDay(from, to, day)) {
      setEnteredTo(day);
    }
  };

  const handleResetClick = () => {
    setFrom(null);
    setTo(null);
    setEnteredTo(null);
  };

  const handlePeriod = (to) => {
    onChange({
      from: getPeriodStart(),
      to: to
    });
    handleClose();
  };

  const getPeriodStart = () => {
    return from || new Date();
  };

  const locale = "et";
  return <Popper open={open} anchorEl={anchorEl} placement="bottom-end" modifiers={{ offset: { enabled: true, offset: '7, 3' } }} className={classes.popper}>
    <ClickAwayListener onClickAway={() => (open && handleClose())}>
      <Paper elevation={0} className={classes.datePanel}>
        <Grid container>
          <Grid item xs={3}>
            <List dense disablePadding>
              <ListItem button onClick={() => handlePeriod(null)}>
                <ListItemText secondary={t('date.period.indefinite')}></ListItemText>
              </ListItem>
              <ListItem button onClick={() => handlePeriod(endOfMonth(getPeriodStart()))}>
                <ListItemText secondary={t('date.period.endOfMonth')}></ListItemText>
              </ListItem>
              <ListItem button onClick={() => handlePeriod(addMonths(getPeriodStart(), 1))}>
                <ListItemText secondary={t('date.period.month1')}></ListItemText>
              </ListItem>
              <ListItem button onClick={() => handlePeriod(addMonths(getPeriodStart(), 6))}>
                <ListItemText secondary={t('date.period.month6')}></ListItemText>
              </ListItem>
              <ListItem button onClick={() => handlePeriod(endOfYear(getPeriodStart()))}>
                <ListItemText secondary={t('date.period.endOfYear')}></ListItemText>
              </ListItem>
              <ListItem button onClick={() => handlePeriod(addYears(getPeriodStart(), 1))}>
                <ListItemText secondary={t('date.period.year1')}></ListItemText>
              </ListItem>
              <ListItem button onClick={() => handlePeriod(addYears(getPeriodStart(), 2))}>
                <ListItemText secondary={t('date.period.year2')}></ListItemText>
              </ListItem>
              <ListItem button onClick={() => handlePeriod(addYears(getPeriodStart(), 3))}>
                <ListItemText secondary={t('date.period.year3')}></ListItemText>
              </ListItem>
            </List>
          </Grid>
          <Grid item>
            <DayPicker
              locale={locale}
              months={MONTHS[locale]}
              weekdaysLong={WEEKDAYS_LONG[locale]}
              weekdaysShort={WEEKDAYS_SHORT[locale]}
              firstDayOfWeek={FIRST_DAY_OF_WEEK[locale]}
              labels={LABELS[locale]}
              numberOfMonths={2}
              pagedNavigation
              fixedWeeks
              className="Range"
              selectedDays={selectedDays}
              disabledDays={disabledDays}
              modifiers={modifiers}
              onDayClick={handleDayClick}
              onDayMouseEnter={handleDayMouseEnter}
            />
          </Grid>
        </Grid>
      </Paper>
    </ClickAwayListener>
  </Popper>;
}

export default function VDateRangePicker({ label, value, onChange, disabled, fromDisabled, error, helperText }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  let panelAnchor = useRef(null);
  const [formattedValue, setFormattedValue] = useState("");

  useEffect(() => {
    setFormattedValue(getFormattedValue(value));
  }, [value]);

  const handleChange = (value) => {
    setFormattedValue(getFormattedValue(value));
    onChange && onChange(value);
  };

  return <>
    <TextField
      className='VaalTextField'
      label={t(label)}
      variant='outlined'
      value={formattedValue}
      disabled={disabled}
      error={error}
      helperText={helperText}
      InputProps={{
        endAdornment: !disabled && <InputAdornment position="end">
          <IconButton edge="end" onClick={() => setOpen(!open)} ref={panelAnchor}>
            <Icon icon="calendar" />
          </IconButton>
        </InputAdornment>,
      }}
    />
    <DatePanel open={open} anchorEl={panelAnchor.current} value={value} onChange={handleChange} handleClose={() => setOpen(false)} fromDisabled={fromDisabled} />
  </>;
}