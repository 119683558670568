import { useContext, useEffect, useState } from "react";
import MapContext from "../MapContext";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Draw from "ol/interaction/Draw";
import MapControlButton from "./MapControlButton";
import { Checkbox, Paragraph, Radio } from "styleguide";
import { CircularProgress, Divider, FormControlLabel, Grid, RadioGroup } from "@material-ui/core";
import LayerStyles from "../LayerStyles";
import { GeometryType } from "../../../constants/mapConstants";
import WKT from 'ol/format/WKT';
import { exportArea } from "../../../stores/masterPlan";

const controlName = 'download-master-plan';

const DownloadMasterPlanControl = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { map } = useContext(MapContext);

  const { activeControl, layers } = useSelector(state => state.map);
  const { job, isLoading } = useSelector(state => state.masterPlan);

  const [type, setType] = useState('dwg');
  const [clip, setClip] = useState(false);

  const isActive = activeControl === controlName;

  const wmtsLayers = layers.filter(l => l.visible && l.serviceType === 'WMTS');
  const wmtsLayerNames = wmtsLayers.map(l => l.layerName);

  useEffect(() => {
    if (!map) return;

    const draw = new Draw({
      type: GeometryType.POLYGON,
      style: LayerStyles.selectingMeasuring
    });

    draw.on('drawend', (event) => {
      const feature = event.feature;
      const wkt = new WKT().writeGeometry(feature.getGeometry());
      dispatch(exportArea(type, clip, wkt, wmtsLayerNames));
    });

    if (isActive) {
      map.addInteraction(draw);
    }

    return () => {
      draw && map.removeInteraction(draw);
    };
  }, [dispatch, map, isActive, type, clip, wmtsLayerNames]);

  if(!wmtsLayers.length) {
    return <></>;
  }

  const handleChange = (event) => setType(event.target.value);

  const handleChangeClip = (event) => setClip(event.target.checked);

  const renderForm = () => (<>
    <RadioGroup aria-label="select tool type radio buttons" value={type} onChange={handleChange}>
        <FormControlLabel value="dwg" control={<Radio />} label="dwg" />
        <FormControlLabel value="dgn" control={<Radio />} label="dgn" />
        <FormControlLabel value="shp" control={<Radio />} label="shp" />
      </RadioGroup>

      <Divider style={{margin: 10}}/>

      <FormControlLabel
        label={t('map.masterPlan.clipLabel')}
        control={<Checkbox
          onChange={handleChangeClip}
          checked={clip}
        />}
      />
  </>);

  const showLoadingIndicator = isLoading || !!job;

  return <MapControlButton controlName={controlName} icon="downloadMap">
    <div className="ol-download-master-plan-panel">
      <Paragraph>{t(`map.control.${controlName}`)}</Paragraph>

      {!showLoadingIndicator && renderForm()}
      {showLoadingIndicator && <Grid container justifyContent="center">
        <CircularProgress color="secondary" size={80} thickness={7} />
      </Grid>}
    </div>
  </MapControlButton>;
};

export default DownloadMasterPlanControl;