import { createSlice } from '@reduxjs/toolkit';
import { api } from "../api/masterPlanApi";
import StoreUtils from '../utils/StoreUtils';
import { showWarning } from './notification';

const apiPath = '/cad2GeoJson';
// Slice
const slice = createSlice({
  name: 'cad2GeoJson',
  initialState: {
    job: null,
    result: null,
    isLoading: false,
    error: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    jobSuccess: (state, action) => {
      state.job = action.payload;
      state.isLoading = false;
    },
    resultSuccess: (state, action) => {
      state.result = action.payload;
    }
  },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, jobSuccess, resultSuccess } = slice.actions;

export const uploadCadFile = (file) => async dispatch => {
  dispatch(startLoading());
  dispatch(resultSuccess(null));
  try {
    let formData = new FormData();
    formData.append('file', file);

    await api.post(apiPath, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then((response) => {
      const job = response.data;
      job.status = 'enqueued';
      dispatch(checkJobStatus(job));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const checkJobStatus = (job) => async dispatch => {
  switch(job.status){
    case 'enqueued':
    case 'processing':
    case 'created':
      dispatch(jobSuccess(job));
      setTimeout(() => dispatch(getJobStatus(job)), 1000);
      return;
    case 'completed':
      if(job.GeoJSON){
        dispatch(resultSuccess(job.GeoJSON));
      } else {
        dispatch(showWarning('map.cad2GeoJson.dataNotFound'))
      }
      dispatch(jobSuccess(null));
      return;
    default: 
      dispatch(jobSuccess(null));
      dispatch(showWarning('map.cad2GeoJson.error'));
  }
};

export const getJobStatus = (job) => async dispatch => {
  try {
    await api.get(`${apiPath}/job/${job.jobID}`).then((response) => {
      dispatch(checkJobStatus(response.data));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const resetCadResult = () => async dispatch => dispatch(resultSuccess());