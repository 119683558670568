import { toggleLayerDrawer } from "../../../stores/map/map";
import { useDispatch, useSelector } from "react-redux";
import MapControlButton from "./MapControlButton";

const LayerSwitcherControl = () => {
  const dispatch = useDispatch();
  const { layerDrawerOpen } = useSelector(state => state.map);

  return <MapControlButton
    controlName="layer-switcher"
    controlActive={layerDrawerOpen}
    icon="filters"
    onClick={() => dispatch(toggleLayerDrawer())} />;
};

export default LayerSwitcherControl;