import { Grid, makeStyles, MenuItem, Paper } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Heading, Paragraph, VSelect } from 'styleguide';
import { ClassifierType } from '../../constants/classifierConstants';
import { fetchConfig, saveConfig } from '../../stores/admin/userNotification';
import { deleteNotificationRequest, fetchRows, updateFilter, updatePageable } from '../../stores/admin/userNotificationRequest';
import TableUtils from '../../utils/TableUtils';
import VTextField, { getPatternValue } from '../form/VTextField';
import ConfirmButton from '../table/ConfirmButton';
import { PageableTableContainer } from '../table/PageableTable';
import TableHeaderWithCount from '../table/TableHeaderWithCount';

const useStyles = makeStyles((theme) => ({
  configPanel: {
    margin: 0,
    padding: 32,
    borderRadius: 8
  }
}));

function ConfigBlock({ config }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [email, setEmail] = useState(config.email || '');
  const [deadlineReminderNotification, setDeadlineReminderNotification] = useState(config.deadlineReminderNotification);
  const [notificationsToEmail, setNotificationsToEmail] = useState(config.notificationsToEmail);
  const [notificationDaysBefore, setNotificationDaysBefore] = useState(config.notificationDaysBefore || 1);

  const [emailValid, setEmailValid] = useState(true);

  const handleToggleEmail = (event, newValue) => {
    if (newValue !== null) {
      setNotificationsToEmail(newValue);
      dispatch(saveConfig({ email, deadlineReminderNotification, notificationsToEmail: newValue, notificationDaysBefore }));
    }
  };

  const handleToggleDeadline = (event, newValue) => {
    if (newValue !== null) {
      setDeadlineReminderNotification(newValue);
      dispatch(saveConfig({ email, deadlineReminderNotification: newValue, notificationsToEmail, notificationDaysBefore }));
    }
  };

  const handleChangeDays = (event) => {
    const value = event.target.value;
    setNotificationDaysBefore(value);
    dispatch(saveConfig({ email, deadlineReminderNotification, notificationsToEmail, notificationDaysBefore: value }));
  };

  const handleChangeEmail = (event) => {
    const value = event.target.value;
    setEmail(value);
    if (value) {
      if (value.match(getPatternValue('email'))) {
        dispatch(saveConfig({ email: value, deadlineReminderNotification, notificationsToEmail, notificationDaysBefore }));
        setEmailValid(true);
      } else {
        setEmailValid(false);
      }
    }
  };

  return <Grid item container direction="row" justifyContent="space-between">
    <Grid item xs={12} lg={5} component={Paper} className={classes.configPanel}
      container direction="column" spacing={2} justifyContent="space-between" >
      <Grid item>
        <Heading level="3">{t('userNotification.config.withEmail')}</Heading>
      </Grid>
      <Grid item>
        <Paragraph fontSize="12">{t('userNotification.config.withEmailDescription')}</Paragraph>
      </Grid>
      <Grid item>
        <ToggleButtonGroup
          className="VaalToggleButton"
          value={notificationsToEmail}
          onChange={handleToggleEmail}
          exclusive
          aria-label='send notifications to email buttons'
        >
          <ToggleButton value={true} aria-label='yes'>
            {t('button.yes')}
          </ToggleButton>
          <ToggleButton value={false} aria-label='no' >
            {t('button.no')}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item>
        <VTextField value={email} onChange={handleChangeEmail} label={t('userNotification.config.email')} error={!emailValid} />
      </Grid>
    </Grid>

    <Grid item xs={12} lg={5} component={Paper} container direction="column" spacing={2} className={classes.configPanel}>
      <Grid item>
        <Heading level="3">{t('userNotification.config.reminder')}</Heading>
      </Grid>
      <Grid item>
        <Paragraph fontSize="12">{t('userNotification.config.reminderDescription')}</Paragraph>
      </Grid>
      <Grid item>
        <ToggleButtonGroup
          className="VaalToggleButton"
          value={deadlineReminderNotification}
          onChange={handleToggleDeadline}
          exclusive
          aria-label='send deadline notification buttons'
        >
          <ToggleButton value={true} aria-label='yes'>
            {t('button.yes')}
          </ToggleButton>
          <ToggleButton value={false} aria-label='no' >
            {t('button.no')}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item>
        <VSelect value={notificationDaysBefore} label={t('userNotification.config.notificationDaysBefore')} onChange={handleChangeDays}>
          <MenuItem value={1}>{t('userNotification.config.beforeDeadlineValue', { days: 1 })}</MenuItem>
          <MenuItem value={2}>{t('userNotification.config.beforeDeadlineValue', { days: 2 })}</MenuItem>
          <MenuItem value={3}>{t('userNotification.config.beforeDeadlineValue', { days: 3 })}</MenuItem>
          <MenuItem value={5}>{t('userNotification.config.beforeDeadlineValue', { days: 5 })}</MenuItem>
          <MenuItem value={10}>{t('userNotification.config.beforeDeadlineValue', { days: 10 })}</MenuItem>
        </VSelect>
      </Grid>
    </Grid>
  </Grid>;
}

export default function UserNotificationConfig() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const config = useSelector(state => state.userNotification.config);
  const { rows, filter, pageable, totalElements, isLoading } = useSelector(state => state.userNotificationRequest);
  const { classifiers } = useSelector(state => state.classifier);

  const columns = useMemo(() => [
    { field: "requestDatetime", type: "date", headerName: t('userNotification.request.requestDatetime') },
    {
      field: "location", headerName: t('userNotification.location'),
      renderCell: (column, cell) => cell.location?.join(', ')
    },
    {
      field: 'domain', headerName: t('userNotification.domain'),
      renderCell: (column, cell) => cell.domain?.map(d => TableUtils.getClassifierTitle(classifiers, ClassifierType.domain, d)).join(', ')
    },
    { field: 'title', headerName: t('userNotification.request.object'), },
    {
      field: 'action', width: 50, renderCell: (column, cell) =>
        <ConfirmButton message={t('form.confirmDelete')} icon="delete" size="small"
          onConfirm={() => dispatch(deleteNotificationRequest(cell.id))} aria-label="delete notification request" />
    }
  ], [t, dispatch, classifiers]);

  const shouldFetchRows = !pageable.loaded && !!classifiers?.length;
  useEffect(() => shouldFetchRows && dispatch(fetchRows(pageable, filter, columns)), [dispatch, shouldFetchRows, pageable, filter, columns]);

  const shouldFetchConfig = !config;
  useEffect(() => shouldFetchConfig && dispatch(fetchConfig()), [dispatch, shouldFetchConfig]);

  const handleAddRequest = () => {
    history.push('/notification/config/request');
  };

  const tableHeader = <TableHeaderWithCount count={totalElements}
    buttons={<Button size="extra-small" onClick={handleAddRequest}>{t('button.add')}</Button>}>
    {t('userNotification.request.title')}
  </TableHeaderWithCount>;

  return <Grid container direction="column" spacing={5}>
    <Grid item>
      <Paragraph>{t('userNotification.config.description')}</Paragraph>
    </Grid>

    <Grid item>
      <PageableTableContainer
        rows={rows}
        columns={columns}
        filter={filter}
        pageable={pageable}
        header={tableHeader}
        loading={isLoading}
        onUpdatePageable={(pageNumber, sort) => dispatch(updatePageable(pageNumber, sort))}
        onUpdateFilter={(field, value) => dispatch(updateFilter(field, value))}
      />
    </Grid>

    {!!config && <ConfigBlock config={config} />}
  </Grid>;
}