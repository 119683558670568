import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/admin';
import StoreUtils from '../../utils/StoreUtils';
import TableUtils from '../../utils/TableUtils';

const apiPath = "notification";
const initialPageable = {
  pageSize: TableUtils.getPageSize(178),
  pageNumber: 0,
  sort: [{
    field: "sentTime",
    ascending: false
  }],
  loaded: false,
  last: true
};

const lastRowsPageable = {
  pageSize: 4,
  pageNumber: 0,
  sort: [{
    field: "sentTime",
    ascending: false
  }],
  loaded: false,
  last: true
};

// Slice
const slice = createSlice({
  name: 'userNotification',
  initialState: {
    unreadCount: null,
    lastRows: [],
    rows: [],
    pageable: initialPageable,
    totalElements: null,
    filter: {},
    config: null,
    isLoading: false,
    error: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isLoadingSuggestions = false;
    },
    unreadCountSuccess: (state, action) => {
      state.unreadCount = action.payload;
      state.isLoading = false;
    },
    lastRowsSuccess: (state, action) => {
      state.lastRows = action.payload.content;
      state.isLoading = false;
    },
    rowsSuccess: (state, action) => {
      state.rows = state.rows.concat(action.payload.content);
      state.pageable.last = action.payload.last;
      state.totalElements = action.payload.totalElements;
      state.pageable.loaded = true;
      state.isLoading = false;
    },
    pageableSuccess: (state, action) => {
      if (action.payload.pageNumber) {
        state.pageable.pageNumber += action.payload.pageNumber;
      }
      if (action.payload.sort) {
        state.pageable.sort = action.payload.sort;
        state.pageable.pageNumber = 0;
        state.rows = [];
      }
      state.pageable.loaded = false;
    },
    filterSuccess: (state, action) => {
      if (action.payload.reset) {
        state.filter = {};
        return;
      }
      if ((state.filter[action.payload.field] || '') !== action.payload.value) {
        state.filter[action.payload.field] = action.payload.value;
        state.pageable.pageNumber = 0;
        state.rows = [];
        state.pageable.loaded = false;
      }
    },
    readSuccess: (state, action) => {
      const lastRow = state.lastRows.find(r => r.id === action.payload.id);
      let decrementCount = false;
      if (lastRow && !lastRow.read) {
        lastRow.read = action.payload.read;
        decrementCount = true;
      }
      const row = state.rows.find(r => r.id === action.payload.id);
      if (row && !row.read) {
        row.read = action.payload.read;
        decrementCount = true;
      }
      if (decrementCount) {
        state.unreadCount--;
      }
    },
    deletedSuccess: (state, action) => {
      const row = state.rows.find(r => r.id === action.payload);
      if (row) {
        if (!row.read) {
          state.unreadCount--;
        }
        state.rows = state.rows.filter(r => r.id !== action.payload);
      }
      state.isLoading = false;
    },
    configSuccess: (state, action) => {
      state.config = action.payload;
      state.isLoading = false;
    },
  },
});

export default slice.reducer;

// Actions

const { unreadCountSuccess, lastRowsSuccess, rowsSuccess, readSuccess, configSuccess,
  pageableSuccess, filterSuccess, deletedSuccess, startLoading, hasError } = slice.actions;

export const updatePageable = (pageNumber, sort) => async dispatch => {
  dispatch(pageableSuccess({ pageNumber: pageNumber, sort: sort }));
};

export const updateFilter = (field, value) => async dispatch => {
  dispatch(filterSuccess({ field, value }));
};

export const fetchUnreadCount = () => async dispatch => {
  try {
    await api.get(`${apiPath}/unread`).then((response) => {
      dispatch(unreadCountSuccess(response.data));
      setTimeout(() => dispatch(fetchUnreadCount()), 2 * 60 * 1000);
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchLastRows = () => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(`${apiPath}?${TableUtils.pageableToParams(lastRowsPageable)}`).then((response) => {
      dispatch(lastRowsSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchRows = (pageable, filter, columns) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(`${apiPath}?${TableUtils.pageableToParams(pageable, filter, columns)}`).then((response) => {
      dispatch(rowsSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const setRead = (notification) => async dispatch => {
  if (notification.id && !notification.read) {
    try {
      await api.patch(`${apiPath}/${notification.id}/setRead`).then((response) => {
        dispatch(readSuccess(response.data));
      });
    }
    catch (e) {
      dispatch(StoreUtils.handleError(e, hasError));
    }
  }
};

export const deleteNotification = (id) => async dispatch => {
  try {
    await api.delete(`${apiPath}/${id}`).then(() => {
      dispatch(deletedSuccess(id));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchConfig = () => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(`${apiPath}/configure`).then((response) => {
      dispatch(configSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const saveConfig = (config) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`${apiPath}/configure`, config).then((response) => {
      dispatch(configSuccess(config));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};