import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { api } from '../../api/admin';
import { api as applicationApi } from '../../api/application';
import TableUtils from '../../utils/TableUtils';
import { showSuccess } from '../notification';
import StoreUtils from '../../utils/StoreUtils';

const initialUser = { id: null, person: { id: null }, roles: [{}], validToDate: null };
const initialPageable = {
  pageSize: TableUtils.getPageSize(178, true, true),
  pageNumber: 0,
  sort: [{
    field: 'validToDate',
    ascending: false
  }, {
    field: 'person.lastName',
    ascending: true,
  }],
  loaded: false,
  last: true
};

// Slice
const slice = createSlice({
  name: 'user',
  initialState: {
    users: [],
    user: initialUser,
    pageable: initialPageable,
    totalElements: null,
    filter: {},
    isLoading: false,
    error: false,
    logs: [],
    govUsers: [],
    partnerUsers: []
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    endLoading: state => {
      state.isLoading = false;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    usersSuccess: (state, action) => {
      state.users = state.users.concat(action.payload.content);
      state.pageable.last = action.payload.last;
      state.totalElements = action.payload.totalElements;
      state.pageable.loaded = true;
      state.isLoading = false;
    },
    resetRowsSuccess: (state) => {
      state.pageable.loaded = false;
      state.pageable.pageNumber = 0;
      state.users = [];
    },
    pageableSuccess: (state, action) => {
      if (action.payload.pageNumber) {
        state.pageable.pageNumber += action.payload.pageNumber;
      }
      if (action.payload.sort) {
        state.pageable.sort = action.payload.sort;
        state.pageable.pageNumber = 0;
        state.users = [];
      }
      state.pageable.loaded = false;
    },
    filterSuccess: (state, action) => {
      if ((state.filter[action.payload.field] || '') !== action.payload.value) {
        state.filter[action.payload.field] = action.payload.value;
        state.pageable.pageNumber = 0;
        state.pageable.loaded = false;
        state.users = [];
      }
    },
    userSuccess: (state, action) => {
      if (!action.payload) {
        state.user = initialUser;
        return;
      }
      let userRoles = action.payload.roles ? action.payload.roles.filter(r => r.role && r.role.valid) : [];
      state.user = action.payload;
      state.user.roles = userRoles.map(r => r.role);
      state.user.userRoles = userRoles;
      state.isLoading = false;
    },
    logsSuccess: (state, action) => {
      state.logs = action.payload;
    },
    substitutesSuccess: (state, action) => {
      state.user.substitutes = action.payload;
    },
    govUsersSuccess: (state, action) => {
      state.govUsers = action.payload;
      state.isLoading = false;
    },
    partnerUsersSuccess: (state, action) => {
      let companies = new Set();
      let partners = [];
      action.payload.forEach(user => {
        const companyKey = user.cooperationPartnerCompany.id;
        if (!companies.has(companyKey)) {
          companies.add(companyKey);
          partners.push({ ...user.cooperationPartnerCompany, isCompany: true });
        }
        partners.push({ ...user, title: `${user.fullName} (${user.cooperationPartnerCompany?.title})` });
      });
      state.partnerUsers = partners;
      state.isLoading = false;
    }
  },
});

export default slice.reducer;

// Actions

const {
  usersSuccess, userSuccess, startLoading, endLoading, resetRowsSuccess,
  hasError, logsSuccess, substitutesSuccess,
  pageableSuccess, filterSuccess, govUsersSuccess, partnerUsersSuccess
} = slice.actions;

export const resetUser = () => async dispatch => {
  dispatch(userSuccess());
};

export const updatePageable = (pageNumber, sort) => async dispatch => {
  dispatch(pageableSuccess({ pageNumber: pageNumber, sort: sort }));
};

export const updateFilter = (field, value) => async dispatch => {
  dispatch(filterSuccess({ field, value }));
};

export const fetchUsers = (pageable, filter, columns) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(`user?${TableUtils.pageableToParams(pageable, filter, columns)}`).then((response) => dispatch(usersSuccess(response.data)));
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchUser = (userId) => async dispatch => {
  if (!userId) {
    dispatch(userSuccess(cloneDeep(initialUser)));
    dispatch(logsSuccess([]));
    return;
  }
  dispatch(startLoading());
  try {
    await api.get(`user/${userId}`).then((response) => {
      dispatch(userSuccess(response.data))
      dispatch(fetchLogs(userId));
      dispatch(fetchSubstitutes(userId));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const addUser = (user) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.post('user', user).then((response) => {
      dispatch(userSuccess(response.data));
      dispatch(showSuccess("form.saved"));
      dispatch(resetRowsSuccess());
      dispatch(fetchLogs(response.data.id));
      dispatch(fetchSubstitutes(response.data.id));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const saveUser = (user) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`user/${user.id}`, user).then((response) => {
      dispatch(userSuccess(response.data));
      dispatch(showSuccess("form.saved"));
      dispatch(resetRowsSuccess());
      dispatch(fetchLogs(user.id));
      dispatch(fetchSubstitutes(user.id));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchLogs = (userId) => async dispatch => {
  try {
    await api.get(`user/${userId}/log`).then((response) => dispatch(logsSuccess(response.data)))
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchSubstitutes = (userId) => async dispatch => {
  try {
    await api.get(`user/${userId}/substitute`).then((response) => dispatch(substitutesSuccess(response.data)))
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchGovUsers = () => async dispatch => {
  try {
    await api.get('user/gov').then((response) => dispatch(govUsersSuccess(response.data)))
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchPartnerUsers = () => async dispatch => {
  try {
    await api.get('user/partner').then((response) => dispatch(partnerUsersSuccess(response.data)))
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchPerson = (code, objectType, objectId) => async dispatch => {
  dispatch(startLoading());
  try {
    return await api.get(`user/person/${code}`).then((response) => {
      dispatch(endLoading());
      if (response.data) {
        return response.data;
      } else {
        return applicationApi.post(`application/person/${code}`, {
          objectType: objectType,
          objectId: objectId
        }).then((response) => {
          dispatch(endLoading());
          return response.data;
        });
      }
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};