import { TableCell } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Paragraph } from 'styleguide';
import { ClassifierType, Domain } from '../../constants/classifierConstants';
import TableUtils from '../../utils/TableUtils';
import PageableTable from '../table/PageableTable';
import TypeReport from './TypeReport';

const getBooleanCellValue = (column, row) => row.totalRow ?
  <Paragraph fontSize="14B">{row[column.field]}</Paragraph> : TableUtils.getBooleanValue(row[column.field]);

export default function BusStopTypeReport() {
  const { t } = useTranslation();
  const classifiers = useSelector(state => state.classifier.classifiers);

  const columns = useMemo(() => {
    let columns = [
      {
        field: 'count', headerName: t(`report.table.busStopCount`), notSortable: true, renderCell: (column, cell) => {
          const value = `${TableUtils.formatNumber(cell.count) || 0} ${t('report.table.unitPieces')}`;
          if (!cell.type) {
            return <Paragraph fontSize='14B'>{value}</Paragraph>;
          }
          return value;
        }
      },
      {
        field: 'percentage', headerName: t(`report.table.percentage`), notSortable: true, renderCell: (column, cell) => {
          const value = `${TableUtils.formatNumber(cell.percentage) || 0} %`;
          if (!cell.type) {
            return <Paragraph fontSize='14B'>{value}</Paragraph>;
          }
          return value;
        }
      },
    ];
    return columns;
  }, [t]);

  const detailColumns = useMemo(() => {
    let columns = [
      { field: 'title', headerName: t('register.busStop.title'), },
      {
        field: 'waitingRoomType', headerName: t('register.busStop.waitingRoomType'),
        type: 'classifier', classifierType: ClassifierType.waitingRoomType
      },
      {
        field: 'platformType', headerName: t('register.busStop.platformType'),
        renderCell: (column, row) => row.totalRow ?
          <Paragraph fontSize="14B">{row[column.field]}</Paragraph> :
          TableUtils.getClassifierTitle(classifiers, ClassifierType.platformType, row[column.field])
      },
      { field: 'hasBusPocket', headerName: t('register.busStop.hasBusPocket'), type: 'boolean', renderCell: getBooleanCellValue },
      { field: 'hasBench', headerName: t('register.busStop.hasBench'), type: 'boolean', renderCell: getBooleanCellValue },
      { field: 'hasTrashCan', headerName: t('register.busStop.hasTrashCan'), type: 'boolean', renderCell: getBooleanCellValue },
    ];
    return columns;
  }, [t, classifiers]);

  const handleRenderCollapsibleRow = (row) => {
    if (row.open && row.data) {
      const unit = ' ' + t('report.table.unitPieces');
      const totalRow = {
        title: t('report.table.total'),
        platformType: row.data.filter(r => r.platformType).length + unit,
        hasBusPocket: row.data.filter(r => r.hasBusPocket).length + unit,
        hasBench: row.data.filter(r => r.hasBench).length + unit,
        hasTrashCan: row.data.filter(r => r.hasTrashCan).length + unit,
        totalRow: true
      }
      const rows = [...row.data, totalRow];
      return <TableCell colSpan={3} style={{ padding: 0 }}>
        <PageableTable
          rows={rows}
          columns={detailColumns}
          totalRows={rows.count}
        />
      </TableCell>;
    }
  };

  return <TypeReport
    classifierType={ClassifierType.busStopType}
    groupedColumns={columns}
    domain={Domain.road}
    reportName="busStopType"
    handleRenderCollapsibleRow={handleRenderCollapsibleRow}
    chartField="count"
    typeColumnLabel={t('register.busStop.busStopType')}
  />;
}