import { Grid, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import 'date-fns';
import DialogForm from '../form/DialogForm';
import { cloneDeep } from 'lodash';
import VTextField from '../form/VTextField';
import ClassifierSelect from '../form/ClassifierSelect';
import { ClassifierType } from '../../constants/classifierConstants';
import { fetchUserProfile, saveUserProfile } from '../../stores/admin/userProfile';
import { CenteredLoadingIndicator } from '../form/LoadingIndicator';
import DateUtils from '../../utils/DateUtils';
import AuthUtils from '../../utils/AuthUtils';
import TableUtils from '../../utils/TableUtils';
import AddressSearchTextField from '../form/AddressSearchTextField';

const useStyles = makeStyles({
  formBlock: {
    maxWidth: 943,
    margin: 'auto',
    padding: 0,
    paddingBottom: 88
  },
});

function CitizenForm({ profile, isLoading, register, errors, setValue }) {
  const { t } = useTranslation();

  return <Grid container direction="column" spacing={4} alignItems="center">
    <Grid item sm={12}>
      <VTextField name="code" label={t('admin.user.code')} disabled register={register} />
    </Grid>
    <Grid item sm={12}>
      <VTextField name="email" label={t('admin.user.email')}
        disabled={isLoading} required maxLength={100}
        pattern="email"
        register={register} errors={errors}
      />
    </Grid>
    <Grid item sm={12}>
      <VTextField name="phone" label={t('admin.user.phone')}
        disabled={isLoading} maxLength={100}
        pattern="phone"
        register={register} errors={errors}
      />
    </Grid>
    <Grid item sm={12}>
      <AddressSearchTextField name="address" label={t('admin.user.address')}
        disabled={isLoading} setValue={setValue}
        register={register} errors={errors}/>
    </Grid>
  </Grid>;
}

function GovForm({ profile, isLoading, register, control, errors }) {
  const { t } = useTranslation();

  let validPeriod = DateUtils.formatDate(profile.validFromDate);
  if (profile.validToDate) {
    validPeriod += ' ' + DateUtils.formatDate(profile.validToDate);
  }

  const roles = profile.roles?.map(r => r.title).join(', ');

  return <Grid container direction="row" spacing={4} justifyContent="space-evenly" alignItems="baseline">
    <Grid container item xs={12} lg={6} direction="column" spacing={4} alignItems="center">
      <Grid item sm={12}>
        <VTextField name="code" label={t('admin.user.code')} disabled register={register} />
      </Grid>
      <Grid item sm={12}>
        <ClassifierSelect item={profile} name="domain" label="admin.user.domain" required
          disabled={isLoading} control={control} classifierType={ClassifierType.domain}
        />
      </Grid>
      <Grid item sm={12}>
        <VTextField name="email" label={t('admin.user.email')}
          disabled={isLoading} required maxLength={100}
          pattern="email"
          register={register} errors={errors}
        />
      </Grid>
      <Grid item sm={12}>
        <VTextField name="phone" label={t('admin.user.phone')}
          disabled={isLoading} maxLength={100}
          pattern="phone"
          register={register} errors={errors}
        />
      </Grid>
    </Grid>
    <Grid container item xs={12} lg={6} direction="column" spacing={4} alignItems="center">
      <Grid item sm={12}>
        <VTextField name="position" label={t('admin.user.position')} disabled register={register} />
      </Grid>
      <Grid item sm={12}>
        <VTextField value={roles} label={t('admin.user.roles')} disabled />
      </Grid>
      <Grid item sm={12}>
        <VTextField value={validPeriod} label={t('table.validPeriod')} disabled />
      </Grid>
    </Grid>
  </Grid>;
}

function PartnerForm({ profile, isLoading, register, errors }) {
  const { t } = useTranslation();

  const classifiers = useSelector(state => state.classifier.classifiers);

  if (!classifiers) {
    return <CenteredLoadingIndicator />;
  }

  const company = profile.cooperationPartnerCompany;
  let validPeriod = DateUtils.formatDate(company.validFromDate);
  if (company.validToDate) {
    validPeriod += ' ' + DateUtils.formatDate(company.validToDate);
  }
  const domain = TableUtils.getClassifierTitle(classifiers, ClassifierType.domain, company.domain);

  return <Grid container direction="row" spacing={4} justifyContent="space-evenly" alignItems="baseline">
    <Grid container item lg={6} direction="column" spacing={4} alignItems="center">
      <Grid item sm={12}>
        <VTextField name="code" label={t('admin.user.code')} disabled register={register} />
      </Grid>
      <Grid item sm={12}>
        <VTextField name="position" label={t('admin.partner.position')} disabled register={register} />
      </Grid>
      <Grid item sm={12}>
        <VTextField name="email" label={t('admin.user.email')}
          disabled={isLoading} required maxLength={100}
          pattern="email"
          register={register} errors={errors}
        />
      </Grid>
      <Grid item sm={12}>
        <VTextField name="phone" label={t('admin.user.phone')}
          disabled={isLoading} maxLength={100}
          pattern="phone" required
          register={register} errors={errors}
        />
      </Grid>
    </Grid>
    <Grid container item lg={6} direction="column" spacing={4} alignItems="center">
      <Grid item sm={12}>
        <VTextField name="cooperationPartnerCompany.title" label={t('admin.partner.companyName')} disabled register={register} />
      </Grid>
      <Grid item sm={12}>
        <VTextField name="cooperationPartnerCompany.regCode" label={t('admin.partner.regCode')} disabled register={register} />
      </Grid>
      <Grid item sm={12}>
        <VTextField value={domain} label={t('admin.partner.domain')} disabled />
      </Grid>
      <Grid item sm={12}>
        <VTextField value={validPeriod} label={t('table.validPeriod')} disabled />
      </Grid>
    </Grid>
  </Grid>;
}

export default function UserProfileForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { profile, isLoading } = useSelector(state => state.userProfile);
  const { control, register, errors, handleSubmit, reset, setValue } = useForm({
    defaultValues: profile
  });

  useEffect(() => dispatch(fetchUserProfile()), [dispatch]);
  useEffect(() => reset(profile), [reset, profile]);

  if (!profile) {
    return <CenteredLoadingIndicator />;
  }

  const onSubmit = (inputData) => {
    let data = cloneDeep(inputData);
    data.id = profile.id;
    dispatch(saveUserProfile(data));
  };

  const userType = t(`header.userType.${profile.type}`);
  const title = `${profile.fullName} - ${userType}`;

  return <DialogForm title={title} onSubmit={handleSubmit(onSubmit)} disabled={isLoading}>
    <Paper elevation={0} className={classes.formBlock}>
      {AuthUtils.isCitizen(profile) && <CitizenForm profile={profile} isLoading={isLoading} register={register} errors={errors} setValue={setValue}/>}
      {AuthUtils.isGov(profile) && <GovForm profile={profile} isLoading={isLoading} register={register} errors={errors} control={control} />}
      {AuthUtils.isPartner(profile) && <PartnerForm profile={profile} isLoading={isLoading} register={register} errors={errors} />}
    </Paper>
  </DialogForm>;
}