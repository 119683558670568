import { Authority } from "../constants/authConstants";
import { ApplicationFileType, ApplicationStatus } from "../constants/classifierConstants";
import AuthUtils from "./AuthUtils";
import MapUtils from "./MapUtils";
import { Domain } from '../constants/classifierConstants';

const ApplicationUtils = {
  indentedBlockSymbol: '•',
  templatePlaceholder: '{{}}',
  templateGroupTitle: '{{GROUP_TITLE}}',
  templateRoadNames: '{{ROAD_NAMES}}',
  templateProject: '{{PROJECT}}',
  hasInputPlaceholder: (template) => template?.indexOf(ApplicationUtils.templatePlaceholder) !== -1,
  hasGroupTitlePlaceholder: (template) => template?.indexOf(ApplicationUtils.templateGroupTitle) !== -1,
  replaceGroupTitle: (template, groupTitle) => template?.replace(ApplicationUtils.templateGroupTitle, groupTitle || ''),
  replaceRoadNames: (template, roadNames) => template?.replace(ApplicationUtils.templateRoadNames, roadNames || ''),
  replaceProject: (template, project) => template?.replace(ApplicationUtils.templateProject, project || ''),
  replaceClauseVariables: (template, clause) => {
    template = ApplicationUtils.replaceRoadNames(template, clause.roadNames);
    template = ApplicationUtils.replaceProject(template, clause.project);
    return template;
  },
  isApplicant: (authUser, application) => AuthUtils.hasAuthority(authUser, Authority.UC1_application_submit) && authUser.id === application.userId,
  isHandler: (authUser, application) =>
    AuthUtils.hasAuthority(authUser, Authority.UC7_application) && authUser.id === application.handlerId,
  isHandlerOrSubstiturer: (authUser, application) =>
    ApplicationUtils.isHandler(authUser, application) || AuthUtils.isSubstituter(authUser, application.handlerId),
  isPartner: (authUser, application) => AuthUtils.hasAuthority(authUser, Authority.UC19_partner_application) && authUser.id === application.draftsmanId,
  isSigner: (authUser, application) => authUser.id === application.signatoryId,
  isRelated: (authUser, application) =>
    ApplicationUtils.isApplicant(authUser, application) ||
    ApplicationUtils.isHandlerOrSubstiturer(authUser, application) ||
    ApplicationUtils.isPartner(authUser, application),
  isPlanFile: (file) => file.type === ApplicationFileType.plan,
  calculateArea: (locations) => {
    const areas = locations.map(l => Number(l.areaAndUnit));
    const area = areas.reduce((a, b) => a + b, 0);
    if (area) {
      return MapUtils.formatArea(area);
    }
    return null;
  },
  isFinalized: (application) => [ApplicationStatus.processed, ApplicationStatus.initiated, ApplicationStatus.notInitiated, ApplicationStatus.decisionYes, ApplicationStatus.decisionNo].includes(application.status),
  isParty: (authUser, application) =>
    application.applicants?.parties?.some(p => p.code === authUser.code) ||
    application.applicants?.planParties?.some(p => p.code === authUser.code),
  isGovCanAddApplication: (authUser) => AuthUtils.isGov(authUser) && authUser.domain === Domain.plan && AuthUtils.hasAuthority(authUser, Authority.UC37_add_application),
  isGovCanAddYPApplication: (authUser) => AuthUtils.isGov(authUser) && authUser.domain === Domain.plan && AuthUtils.hasAuthority(authUser, Authority.UC37_add_YP_application),
};

export default ApplicationUtils;