import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/admin';
import { showSuccess } from '../notification';
import StoreUtils from '../../utils/StoreUtils';

const apiPath = 'user/profile';
// Slice
const slice = createSlice({
  name: 'userProfile',
  initialState: {
    profile: null,
    isLoading: false,
    error: false,
    submitted: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    profileSuccess: (state, action) => {
      state.profile = action.payload;
      state.isLoading = false;
    },
    submittedSuccess: (state, action) => {
      state.submitted = action.payload;
    }
  },
});

export default slice.reducer;

// Actions

const { profileSuccess, startLoading, hasError } = slice.actions;

export const fetchUserProfile = () => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(apiPath).then((response) => {
      dispatch(profileSuccess(response.data))
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const saveUserProfile = (userProfile) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(apiPath, userProfile).then((response) => {
      dispatch(profileSuccess(response.data))
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const saveUserLanguage = (language) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`user/language?language=${language}`).then((response) => {
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};
