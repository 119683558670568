import { ClickAwayListener, List, ListItem, ListItemText, makeStyles, Paper } from "@material-ui/core";
import { debounce } from "lodash";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Colors, Search, Shadows } from "styleguide";
import { ObjectStatus } from "../../constants/classifierConstants";
import { fetchObjectRowsCSV } from "../../stores/register/register";
import LoadingIndicator from "./LoadingIndicator";


const useStyles = makeStyles((theme) => ({
  searchBar: {
    borderRadius: 8,
    height: 48,
    width: '50%',
    backgroundColor: 'white',
    boxShadow: Shadows.shadow2
  },
  searchBarBordered: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: Colors.hall1,
    height: 48,
    width: '50%',
    backgroundColor: 'white',
  },
  resultList: {
    marginTop: 5,
    position: 'absolute',
    minWidth: '50%',
    zIndex: 2
  }
}));

export default function RoadSearch({ handleSetRoad, pageSize = 10, useAltStyle }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const formEl = useRef(null);

  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = debounce((value) => {
    loadData(value);
  }, 500);

  const loadData = async (value) => {
    setIsLoading(true);
    const pageable = {
      sort: {
        field: 'title',
        ascending: true
      }
    };
    const filter = {
      status: [ObjectStatus.valid],
      title: value
    };
    const data = await dispatch(fetchObjectRowsCSV('road', pageable, filter, 10));
    await setRows(data);
    setIsLoading(false);
  };

  const handleSelect = (road) => {
    handleSetRoad(road);
    setRows([]);
    formEl.current.reset();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return <div>
    <form ref={formEl} onSubmit={handleSubmit}>
      <Search
        className={!useAltStyle ? classes.searchBar : classes.searchBarBordered}
        placeholder={t('report.table.searchRoad')}
        onChange={handleSearch}
        minCharacters={3}
        autoFocus
      />
    </form>
    {(isLoading || !!rows.length) && <ClickAwayListener onClickAway={() => setRows([])}>
      <Paper className={classes.resultList}>
        <List dense>
          {isLoading && <LoadingIndicator />}
          {!isLoading && rows.map((row, index) => (
            <ListItem key={index} button onClick={() => handleSelect(row)}>
              <ListItemText secondary={row.title} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </ClickAwayListener>}
  </div>
}