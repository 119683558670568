import { Box, Grid, TableCell } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Tooltip, VAutocomplete } from "styleguide";
import { fetchRowsAdmin, fetchObjectTypesAdmin, resetRows, setFilter, updateFilter, updatePageable } from "../../stores/activityLog";
import TableUtils from "../../utils/TableUtils";
import { CenteredLoadingIndicator } from "../form/LoadingIndicator";
import VDateTimePicker from "../form/VDateTimePicker";
import VTextField from "../form/VTextField";
import { InfiniteScrollTableContainer } from "../table/PageableTable";
import TableHeaderWithCount from "../table/TableHeaderWithCount";

export default function ActivityLogView() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { rows, filter, pageable, totalElements, isLoading, objectTypesList } = useSelector(state => state.activityLog);

  const [activities, setActivities] = useState(null);
  const [activity, setActivity] = useState(null);
  const [objectTypes, setObjectTypes] = useState(null);
  const [objectType, setObjectType] = useState(null);
  const [loadObjectTypes, setLoadObjectTypes] = useState(true);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [loadRows, setLoadRows] = useState(false);

  const columns = useMemo(() => [
    { field: 'relatedTable', headerName: t('log.relatedTable'), renderCell: (column, row) => t(`objectType.${row.relatedTable}`) },
    { field: 'relatedId', headerName: t('log.relatedId'), },
    { field: 'fullName', headerName: t('log.author'), notSortable: true, },
    {
      field: 'activity', headerName: t('log.activity'),
      renderCell: (column, cell) => t(`log.activityValue.${cell.activity}`)
    },
    { field: 'created', headerName: t('log.date'), type: 'dateTime' },
  ], [t]);

  useEffect(() => {
    const translatedActivities = t('log.activityValue', { returnObjects: true });
    const activities = Object.keys(translatedActivities).map(key => ({
      key,
      title: translatedActivities[key]
    }));
    setActivities(TableUtils.sortArrayByString(activities, 'title'));
  }, [t]);
  useEffect(() => {
    loadObjectTypes && dispatch(fetchObjectTypesAdmin());
    setLoadObjectTypes(false);
    const translatedObjectTypes = t('objectType', { returnObjects: true });
    const objectTypes = objectTypesList.map(key => ({
      key,
      title: translatedObjectTypes[key]
    }));
    setObjectTypes(TableUtils.sortArrayByString(objectTypes, 'title'));
  }, [loadObjectTypes, dispatch, t, objectTypesList]);
  useEffect(() => dispatch(resetRows()), [dispatch]);
  useEffect(() => loadRows && !pageable.loaded && dispatch(fetchRowsAdmin(pageable, filter, columns)),
    [dispatch, pageable, filter, columns, loadRows]);

  const handleRenderCollapsibleRow = (row) => {
    let content;
    if (row.reason) {
      content = row.reason;
    }
    if (row.contentJson) {
      const data = JSON.parse(row.contentJson);
      if (Array.isArray(data)) {
        const elements = [];
        data.forEach(row => {
          if (typeof row === 'object') {
            if (row.label && row.content) {
              elements.push(
                <Tooltip
                  placement="right"
                  label={row.label}
                  title={<Box whiteSpace="pre-line">{row.content}</Box>}
                />
              );
            }
          } else {
            elements.push(row);
          }
        });
        content = elements.map((row, index) => <div key={index}>{row}</div>);
      }
    }
    if (content) {
      return <TableCell colSpan="100%">{content}</TableCell>;
    }
  };

  const handleSearch = () => {
    const filter = {
      activity: activity?.key || '',
      created: { from, to },
      creator_person_firstName: firstName,
      creator_person_lastName: lastName,
      relatedTable: objectType?.key || ''
    };
    dispatch(setFilter(filter));
    setLoadRows(true);
  };

  if (!activities) {
    return <CenteredLoadingIndicator />;
  }
  if (!objectTypes) {
    return <CenteredLoadingIndicator />;
  }
  return <Grid container direction="row" spacing={4}>
    <Grid item xs={12} lg={3}>
      <VDateTimePicker label={t('log.from')} disabled={isLoading}
        value={from} onChange={(value) => setFrom(value)} fullWidth />
    </Grid>
    <Grid item xs={12} lg={3}>
      <VDateTimePicker label={t('log.to')} disabled={isLoading}
        value={to} onChange={(value) => setTo(value)} fullWidth />
    </Grid>
    <Grid item xs={12} lg={3}>
      <VTextField label={t('log.firstName')} fullWidth
        value={firstName} onChange={(event) => setFirstName(event.target.value)} />
    </Grid>
    <Grid item xs={12} lg={3}>
      <VTextField label={t('log.lastName')} fullWidth
        value={lastName} onChange={(event) => setLastName(event.target.value)} />
    </Grid>
    <Grid item xs={12} lg={3}>
      <VAutocomplete
        value={activity}
        onChange={(event, value) => setActivity(value)}
        options={activities}
        getOptionLabel={(o) => o.title}
        fullWidth
        getOptionSelected={(option, value) => option.key === value.key}
        disabled={isLoading}
        renderInput={(params) =>
          <VTextField {...params} label={t('log.activity')} />
        }
      />
    </Grid>
    <Grid item xs={12} lg={3}>
      <VAutocomplete
        value={objectType}
        onChange={(event, value) => setObjectType(value)}
        options={objectTypes}
        getOptionLabel={(o) => o.title}
        fullWidth
        getOptionSelected={(option, value) => option.key === value.key}
        disabled={isLoading}
        renderInput={(params) =>
          <VTextField {...params} label={t('log.relatedTable')} />
        }
      />
    </Grid>
    <Grid item xs={12} lg={1}>
      <Button onClick={handleSearch} size="small">
        {t('button.search')}
      </Button>
    </Grid>

    {!!loadRows &&
      <Grid item xs={12}>
        <InfiniteScrollTableContainer
          rows={rows}
          columns={columns}
          filter={filter}
          pageable={pageable}
          header={<TableHeaderWithCount count={totalElements}>{t('log.title')}</TableHeaderWithCount>}
          loading={isLoading}
          onUpdatePageable={(pageNumber, sort) => dispatch(updatePageable(pageNumber, sort))}
          onUpdateFilter={(field, value) => dispatch(updateFilter(field, value))}
          onRenderCollapsibleRow={handleRenderCollapsibleRow}
        />
      </Grid>
    }
  </Grid>;
}