import { Box, Grid, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, TableHeader } from 'styleguide';
import { ClassifierType } from '../../../constants/classifierConstants';
import ClassifierSelect from '../../form/ClassifierSelect';
import VTextField from '../../form/VTextField';
import ConfirmButton from '../../table/ConfirmButton';
import SimplePageableTable from '../../table/SimplePageableTable';
import { addPlanApplicant, deletePlanApplicant, fetchOwners, resetSubmitted, setApplicantSelected } from '../../../stores/application/applicationApplicant';
import { useHistory } from 'react-router';
import ExportCSVButton from '../../table/ExportCSVButton';

const useStyles = makeStyles((theme) => ({
  addPartyContainer: {
    marginBottom: 72,
    padding: 26
  },
  ownerRow: {
    opacity: 0.5
  },
}));

function AddPartyForm({ onConfirm, onClose, isLoading, compact }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { register, control, errors, handleSubmit } = useForm({
    defaultValues: {}
  });

  const gridSize = compact ? 12 : 3;

  return <Paper elevation={1} className={classes.addPartyContainer}>
    <form onSubmit={handleSubmit(onConfirm)}>
      <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <Grid item xs={gridSize}>
          <VTextField name="code" label="plan.party.code"
            disabled={isLoading} required maxLength={11}
            register={register} errors={errors} fullWidth
          />
        </Grid>
        <Grid item xs={gridSize}>
          <ClassifierSelect item={{}} name="applicantType" label="plan.party.applicantType"
            disabled={isLoading} required classifierType={ClassifierType.applicantType}
            control={control} errors={errors}
          />
        </Grid>
        <Grid item>
          <Button type="submit" size="small" aria-label="submit new party" disabled={isLoading}>
            {t('button.save')}
          </Button>
          <Box marginLeft={1} display="inline">
            <Button size="small" color="secondary" onClick={onClose} aria-label="cancel" disabled={isLoading}>
              {t('button.cancelAlt')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  </Paper>;
}

export default function PlanPartyTable({ compact, readOnly }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [addingParty, setAddingParty] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const application = useSelector(state => state.application.selected);
  const applicants = useSelector(state => state.applicationApplicant.rows);
  const submitted = useSelector(state => state.applicationApplicant.submitted);
  const isLoading = useSelector(state => state.applicationApplicant.isLoading);

  useEffect(() => submitted && dispatch(resetSubmitted()) && setAddingParty(false), [submitted, dispatch]);

  const applicationId = application.id;
  const canSendNotifications = applicants.some(a => a.isSelected);

  const columns = useMemo(() => {
    const handleAllSelected = (selected) => {
      dispatch(setApplicantSelected(selected));
      setAllSelected(selected);
    };

    let c = [
      { field: 'code', headerName: t('plan.party.code'), filter: true },
      { field: 'fullName', headerName: t('plan.party.name'), filter: true, },
      { field: 'email', headerName: t('plan.party.email'), filter: true, renderCell: (column, row) => row.email?.replace(/;/g, '; ') },
      { field: 'phone', headerName: t('plan.party.phone'), filter: true },
      { field: 'address', headerName: t('plan.party.address'), filter: true },
      {
        field: "applicantType", headerName: t('plan.party.applicantType'), filter: true,
        type: "classifier", classifierType: ClassifierType.applicantType,
      },
      { field: 'cadastres', headerName: t('plan.party.cadastre'), filter: true },
    ];
    if (!readOnly) {
      c.unshift({
        field: 'selected',
        renderHeader: (column) => <Checkbox onChange={(e) => handleAllSelected(e.target.checked)} checked={allSelected} />,
        renderCell: (column, row) => <Checkbox onChange={(e) => dispatch(setApplicantSelected(e.target.checked, row.code))} checked={row.isSelected || false} />,
      });
      c.push({
        field: 'action', width: 50, renderCell: (column, cell) => !!cell.id && !!cell.isPlan &&
          <Box textAlign="right">
            <ConfirmButton
              message={t('form.confirmDelete')} icon="delete"
              onConfirm={() => dispatch(deletePlanApplicant(applicationId, cell.id))}
              aria-label="delete file"
            />
          </Box>
      })
    }
    return c;
  }, [t, applicationId, dispatch, readOnly, allSelected]);

  const handleSendNotifications = () => {
    history.push(`sendNotification`);
  };

  const convertToCsvData = async () => {
    return applicants.map(p => ({
      ...p,
      email: columns.find(c => c.field === 'email').renderCell(null, p)
    }));
  };

  const tableButtons = !readOnly && <>
    <Button
      size="extra-small"
      onClick={() => setAddingParty(true)}
      disabled={isLoading || addingParty}
    >
      {t('plan.party.add')}
    </Button>
    <Button
      size="extra-small"
      color="secondary"
      onClick={() => dispatch(fetchOwners(application.id))}
      disabled={isLoading || addingParty}
    >
      {t('plan.party.findOwners')}
    </Button>
    <Button
      size="extra-small"
      color="secondary"
      onClick={handleSendNotifications}
      disabled={isLoading || !canSendNotifications}
    >
      {t('plan.notification.send')}
    </Button>
    <ExportCSVButton
      handleFetchData={convertToCsvData}
      includeRenderCellColumns
      columns={columns}
      filename={t('plan.view.parties')}
    />
  </>;

  const handleAddParty = (data) => {
    dispatch(addPlanApplicant(application.id, data));
  };


  const showTable = !!applicants && (!compact || !addingParty);

  return <div>
    {addingParty && <AddPartyForm isLoading={isLoading}
      onConfirm={handleAddParty} onClose={() => setAddingParty(false)} compact={compact} />}

    {showTable && <SimplePageableTable
      rows={applicants}
      columns={columns}
      header={<TableHeader buttons={tableButtons}>{t('plan.view.parties')}</TableHeader>}
      sort={{ field: 'name', ascending: true }}
      pageSize={25}
      rowClassNameFunction={(row) => !row?.id && classes.ownerRow}
      onRowSelected={(row) => dispatch(setApplicantSelected(!row.isSelected, row.code))}
    />}
  </div>;
}