import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/admin';
import i18n from '../../i18n';
import StoreUtils from '../../utils/StoreUtils';
import TableUtils from '../../utils/TableUtils';
import { showSuccess } from '../notification';

const initialPageable = {
  pageSize: 999,
  pageNumber: 0,
  sort: {
    field: "title",
    ascending: true
  },
  loaded: false,
  last: true
};

// Slice
const slice = createSlice({
  name: 'classifier',
  initialState: {
    types: [],
    selectedType: null,
    serverClassifiers: [],
    classifiers: [],
    filteredClassifiers: [],
    classifier: {},
    searchValue: '',
    pageable: initialPageable,
    isLoading: false,
    error: false,
    settlements: [],
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    typesSuccess: (state, action) => {
      if (action.payload) {
        state.types = action.payload;
        state.types.sort((a, b) => a.title.localeCompare(b.title));
        if (!state.selectedType) {
          state.selectedType = state.types[0];
        }
        if (state.filteredClassifiers.length === 0) {
          state.filteredClassifiers = state.serverClassifiers.filter(c => c.typeCode === state.selectedType.code);
        }
      }
      state.isLoading = false;
    },
    classifiersSuccess: (state, action) => {
      state.serverClassifiers = action.payload;
      if (state.selectedType) {
        state.filteredClassifiers = state.serverClassifiers.filter(c => c.typeCode === state.selectedType.code);
      }
      state.pageable.loaded = true;
      state.isLoading = false;
    },
    filterClassifiersSuccess: (state, action) => {
      if (!action.payload.selectedType.code) {
        state.selectedType = state.types.find(c => c.code === action.payload.selectedType);
      } else {
        state.selectedType = action.payload.selectedType;
      }
      state.filteredClassifiers = state.serverClassifiers.filter(c => c.typeCode === state.selectedType.code);
      state.searchValue = action.payload.searchValue;
      if (state.searchValue) {
        state.filteredClassifiers = state.filteredClassifiers.filter(c =>
          TableUtils.textSearch(c.itemCode, state.searchValue) ||
          TableUtils.textSearch(c.title, state.searchValue));
      }
      state.filteredClassifiers = TableUtils.sortArrayByString(state.filteredClassifiers, 'title');
    },
    classifierSuccess: (state, action) => {
      state.classifier = action.payload;
      state.isLoading = false;
    },
    classifierFindSuccess: (state, action) => {
      state.classifier = state.serverClassifiers.find(c => c.id === action.payload);
    },
    pageableSuccess: (state, action) => {
      if (action.payload.pageNumber) {
        state.pageable.pageNumber += action.payload.pageNumber;
      }
      if (action.payload.sort) {
        state.pageable.sort = action.payload.sort;
        state.pageable.pageNumber = 0;
        state.filteredClassifiers.sort((a, b) => a[state.pageable.sort.field]?.localeCompare(b[state.pageable.sort.field]) * (state.pageable.sort.ascending ? 1 : -1));
      }
      state.pageable.loaded = true;
    },
    settlementSuccess: (state, action) => {
      state.settlements = action.payload;
      state.isLoading = false;
    },
    changeLanguageSuccess: state => {
      const language = i18n.language;

      if (state.serverClassifiers?.length) {
        const languageSuffix = language === 'en-US' ? 'Eng' : '';
        state.classifiers = TableUtils.sortArrayByString(
          state.serverClassifiers.map(c => ({
            typeCode: c.typeCode,
            itemCode: c.itemCode,
            title: c[`title${languageSuffix}`] || c.title,
            description: c[`description${languageSuffix}`] || c.description,
            valid: c.valid
          })), 'title');
      }
    },
  },
});

export default slice.reducer;

// Actions

const { typesSuccess, classifiersSuccess, filterClassifiersSuccess, classifierSuccess, classifierFindSuccess,
  pageableSuccess, startLoading, hasError, settlementSuccess, changeLanguageSuccess } = slice.actions;

export const updatePageable = (pageNumber, sort) => async dispatch => {
  dispatch(pageableSuccess({ pageNumber: pageNumber, sort: sort }));
};


export const fetchClassifierTypes = () => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get('classifier-item/type').then((response) => dispatch(typesSuccess(response.data)));
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchClassifiers = () => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get('classifier-item').then((response) => {
      dispatch(classifiersSuccess(response.data));
      dispatch(changeLanguageSuccess());
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchClassifier = (classifierId, typeCode) => async dispatch => {
  if (classifierId) {
    dispatch(classifierFindSuccess(classifierId));
  } else if (typeCode) {
    dispatch(classifierSuccess({ id: null, typeCode: typeCode }));
  }
};

export const filterClassifiers = (selectedType, searchValue) => async dispatch => {
  dispatch(filterClassifiersSuccess({ selectedType, searchValue }));
};

export const addClassifier = (classifier) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.post('classifier-item', classifier).then((response) => {
      dispatch(classifierSuccess(response.data));
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const saveClassifier = (classifier) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(`classifier-item/${classifier.id}`, classifier).then((response) => {
      dispatch(classifierSuccess(response.data));
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const invalidateClassifier = (classifier) => async dispatch => {
  dispatch(startLoading());
  try {
    return await api.patch(`classifier-item/${classifier.id}/set-invalid`, classifier.id).then((response) => {
      dispatch(classifierSuccess(response.data));
      dispatch(showSuccess("form.saved"));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const fetchSettlements = () => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get('classifier-item/settlement').then((response) => dispatch(settlementSuccess(response.data)))
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const changeClassifierLanguage = () => async dispatch => {
  dispatch(changeLanguageSuccess());
};