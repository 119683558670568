import React, { useEffect } from 'react';
import PageContainer from '../parts/PageContainer';
import WorkOrderTable from '../components/workOrder/WorkOrderTable';
import { Domain } from '../constants/classifierConstants';
import { Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Authority } from '../constants/authConstants';
import WorkOrderForm from '../components/workOrder/WorkOrderForm';
import SecureRoute from '../components/auth/SecureRoute';
import WorkOrderView from '../components/workOrder/WorkOrderView';
import WorkOrderFulfilForm from '../components/workOrder/WorkOrderFulfilForm';
import AuthUtils from '../utils/AuthUtils';
import WorkOrderTablePartner from '../components/workOrder/WorkOrderTablePartner';
import RouteUtils from '../utils/RouteUtils';

const allowedDomains = [Domain.rainwater, Domain.streetlight, Domain.road, Domain.landscaping, Domain.infrastructure];

function WorkOrderRoute({ domain }) {
  return <SecureRoute path={`/workOrder/${domain}`} render={() => <WorkOrderTable domain={domain} />} exact authority={Authority.UC26_work_orders} />;
}

export default function WorkOrder() {
  const location = useLocation();
  const { authUser } = useSelector(state => state.auth);

  useEffect(() => {
    RouteUtils.setDocumentTitle('workOrder');
  }, []);

  if (AuthUtils.hasAuthority(authUser, Authority.UC27_partner_work_order)) {
    if (location.pathname !== '/workOrder' && location.pathname !== '/workOrder/archive' &&
      !location.pathname.startsWith('/workOrder/view') && !location.pathname.startsWith('/workOrder/fulfil')) {
      return <Redirect to="/workOrder" />;
    }
    return <WorkOrderPartner />;
  }

  if (location.pathname === '/workOrder' || location.pathname === '/workOrder/') {
    const defaultDomain = AuthUtils.getDefaultDomain(authUser, allowedDomains);
    return <Redirect to={`/workOrder/${defaultDomain}`} />
  }

  return <PageContainer>
    <WorkOrderRoute domain={Domain.rainwater} />
    <WorkOrderRoute domain={Domain.streetlight} />
    <WorkOrderRoute domain={Domain.road} />
    <WorkOrderRoute domain={Domain.infrastructure} />
    <WorkOrderRoute domain={Domain.landscaping} />
    <WorkOrderRoute domain="archive" />
    <SecureRoute path='/workOrder/edit/:id?' component={WorkOrderForm} authority={Authority.UC26_work_orders} />
    <SecureRoute path='/workOrder/view/:id' component={WorkOrderView} authority={Authority.UC26_work_orders} />
    <SecureRoute path='/workOrder/fulfil/:id' component={WorkOrderFulfilForm} authority={Authority.UC26_work_orders} />
  </PageContainer>;
}

function WorkOrderPartner() {
  return <PageContainer>
    <SecureRoute path='/workOrder' component={WorkOrderTablePartner} exact authority={Authority.UC27_partner_work_order} />
    <SecureRoute path='/workOrder/archive' render={() => <WorkOrderTablePartner isArchive />} exact authority={Authority.UC27_partner_work_order} />
    <SecureRoute path='/workOrder/view/:id' component={WorkOrderView} authority={Authority.UC27_partner_work_order} />
    <SecureRoute path='/workOrder/fulfil/:id' component={WorkOrderFulfilForm} authority={Authority.UC27_partner_work_order} />
  </PageContainer>;
}