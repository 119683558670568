import { Collapse, Dialog, Divider, Drawer, FormControlLabel, IconButton, List, ListItem, ListItemText, makeStyles, Slide } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Icon, Paragraph, Shadows, Checkbox } from "styleguide";
import { toggleLayerGroup, toggleLayer, toggleSubGroupVisibility, toggleGroupVisibility, toggleSubGroup, toggleLayerDrawer } from "../../stores/map/map";
import MapUtils from "../../utils/MapUtils";
import TableUtils from "../../utils/TableUtils";
import clsx from 'clsx';
import logoEu from '../../assets/images/logo_eu_eesti.png';
import logoRrf from '../../assets/images/logo_rrf.png';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 425,
    marginTop: 102,
    height: 'calc(100vh - 102px)',
    boxShadow: Shadows.shadow2
  },
  drawerDialog: {
    width: 425,
    height: 'calc(100vh - 89px)',
    boxShadow: Shadows.shadow2
  },
  bold: {
    fontWeight: 'bold'
  },
  divider: {
    marginLeft: 16,
    marginRight: 16
  },
  disablePadding: {
    paddingTop: 0,
    paddingBottom: 0
  },
  layersList: {
    paddingTop: 0,
    paddingLeft: 32
  },
  subLayersList: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 32
  },
  legendIcon: {
    paddingRight: 9
  },
  extendedLegend: {
    paddingLeft: 32,
    paddingTop: 0,
    paddingBottom: 0
  },
  subGroupListItem: {
    paddingLeft: 5,
    paddingTop: 0,
    paddingBottom: 0
  },
  extendedLegendImg: {
    paddingLeft: 9
  },
  isPartiallyChecked: {
    backgroundColor: 'rgba(47, 97, 224, 0.05)',
  },
  logoEu: {
    margin: 'auto',
    paddingTop: '24px',
  }
}));

function CheckboxListItem({ item, handleClick }) {
  const classes = useStyles();

  const legend = item.legendType === 'ICON' && MapUtils.getLegendGraphicUrl(item, null, false);
  const extendedLegend = item.visible && item.legendType === 'EXTENDED' && MapUtils.getLegendGraphicUrl(item, null, true);

  return <span>
    <ListItem classes={{ root: classes.disablePadding }}>
      <ListItemText classes={{ root: classes.disablePadding }} secondary={
        <FormControlLabel
          control={<Checkbox checked={item.visible} onClick={handleClick} size="small" />}
          label={
            <Paragraph fontSize='12'>
              {legend && <img src={legend} alt="legend" className={classes.legendIcon} />}
              {item.title}
            </Paragraph>
          }
        />
      } />
    </ListItem>
    {extendedLegend && <ListItem className={classes.extendedLegend}>
      <ListItemText classes={{ root: classes.disablePadding }} secondary={<span>
        <img src={extendedLegend} alt="legend" className={classes.extendedLegendImg} />
      </span>} />
    </ListItem>}
  </span>;
}

function GroupListItem({ group, handleOpenClick, handleVisibilityClick, isSubGroup, isPartiallyChecked }) {
  const classes = useStyles();

  return <ListItem button onClick={() => handleOpenClick(group)}
    classes={{ root: clsx(isPartiallyChecked && classes.isPartiallyChecked, isSubGroup && classes.subGroupListItem) }}>
    <ListItemText
      secondary={<span>
        <Checkbox checked={group.visible} onClick={(e) => { e.stopPropagation(); handleVisibilityClick(group); }} size="small" />
        {group.title}
      </span>}
      classes={{ root: isSubGroup && classes.disablePadding, secondary: !isSubGroup && classes.bold }} />
    <Icon icon={group.open ? "arrowUp" : "arrowDown"} />
  </ListItem>;
}

function LegendTable({ layers }) {
  const { t } = useTranslation();

  return <div style={{ opacity: 0, display: 'none' }}>
    <table id="legend-table">
      <thead>
        <tr>
          <th>{t('map.pdf.legend')}</th>
          <th>{t('map.pdf.group')}</th>
          <th>{t('map.pdf.description')}</th>
        </tr>
      </thead>
      <tbody>
        {layers.filter(l => l.visible).map(l => (
          <tr key={l.id}>
            <td>{l.legendType ? <img src={MapUtils.getLegendGraphicUrl(l, null, true)} alt='legend' /> : l.title}</td>
            <td>{l.mapGroup?.parentGroup ? `${l.mapGroup.parentGroup.title} - ${l.mapGroup.title}` : l.mapGroup.title}</td>
            <td>{l.description}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>;
}

function GroupLayerList({ group, layers, handleGroupClick, handleGroupVisibilityClick, handleSubGroupClick,
  handleSubGroupVisibilityClick, handleLayerClick }) {
  const classes = useStyles();

  const groupLayers = layers.filter(l => l.mapGroup?.id === group.id);
  const subGroupsAndLayers = TableUtils.sortArrayByNumber([...group.subGroups, ...groupLayers], 'order');

  const isLayer = (subGroupOrLayer) => !!subGroupOrLayer.serverUrl;

  let isPartiallyChecked;
  subGroupsAndLayers?.filter(el => el.visible).length > 0 ? isPartiallyChecked = true : isPartiallyChecked = false;

  var subGroupIds = group.subGroups.map(sg => sg.id);
  var subGroupLayers = layers.filter(l => subGroupIds.includes(l.mapGroup?.id));
  if (subGroupLayers?.filter(el => el.visible).length > 0) {
    isPartiallyChecked = true;
  }

  const renderSubGroup = (subGroup, index) => {
    let isSubGroupPartiallyChecked;
    let subGroupLayers = layers.filter(l => l.mapGroup?.id === subGroup.id);
    subGroupLayers?.filter(el => el.visible).length > 0 ? isSubGroupPartiallyChecked = true : isSubGroupPartiallyChecked = false;

    return <div key={index}>
      <GroupListItem group={subGroup} handleOpenClick={handleSubGroupClick} handleVisibilityClick={handleSubGroupVisibilityClick} isSubGroup isPartiallyChecked={isSubGroupPartiallyChecked} />
      <Collapse in={subGroup.open} timeout="auto" unmountOnExit>
        <List dense className={classes.subLayersList}>
          {layers.filter(l => l.mapGroup?.id === subGroup.id).map((layer, index) => (
            <CheckboxListItem key={index} item={layer} handleClick={() => handleLayerClick(layer)} />
          ))}
        </List>
      </Collapse>
    </div>
  }

  const renderLayer = (layer, index) => <CheckboxListItem key={index} item={layer} handleClick={() => handleLayerClick(layer)} />;

  return <span>
    <GroupListItem group={group} handleOpenClick={handleGroupClick} handleVisibilityClick={handleGroupVisibilityClick} isPartiallyChecked={isPartiallyChecked} />
    <Collapse in={group.open} timeout="auto" unmountOnExit>
      <List component="div" dense className={classes.layersList}>
        {subGroupsAndLayers.map((subGroupOrLayer, index) => {
          return isLayer(subGroupOrLayer) ? renderLayer(subGroupOrLayer, index) : renderSubGroup(subGroupOrLayer, index)
        })}
      </List>
    </Collapse>
    <Divider className={classes.divider} />
  </span>
}

function LayerList() {
  const dispatch = useDispatch();
  const { layers, layerGroups } = useSelector(state => state.map);

  const handleGroupClick = (index) => {
    dispatch(toggleLayerGroup(index));
  };

  const handleGroupVisibilityClick = (group) => {
    dispatch(toggleGroupVisibility(group));
  };

  const handleSubGroupClick = (subGroup) => {
    dispatch(toggleSubGroup(subGroup));
  };

  const handleSubGroupVisibilityClick = (subGroup) => {
    dispatch(toggleSubGroupVisibility(subGroup));
  };

  const handleLayerClick = (layer, group) => {
    dispatch(toggleLayer(layer, group));
  };

  return <List dense>
    {layerGroups.map((group, groupIndex) => (
      <GroupLayerList key={groupIndex}
        group={group} layers={layers}
        handleGroupClick={handleGroupClick}
        handleGroupVisibilityClick={handleGroupVisibilityClick}
        handleSubGroupClick={handleSubGroupClick}
        handleSubGroupVisibilityClick={handleSubGroupVisibilityClick}
        handleLayerClick={handleLayerClick}
      />
    ))}
  </List>;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function MobileLayerDrawer({ open, layerGroups }) {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(toggleLayerDrawer());
  };

  return <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
    <IconButton onClick={handleClose} aria-label="close">
      <Icon icon="times" />
    </IconButton>
    <LayerList />
  </Dialog>
}

function DesktopLayerDrawer({ open, layerGroups, isDialog }) {
  const classes = useStyles();
  const drawerClass = !!isDialog ? classes.drawerDialog : classes.drawer;

  return <Drawer anchor="left" open={open} variant="persistent" classes={{ paper: drawerClass }}>
    <LayerList />
    <img src={logoEu} className={classes.logoEu} alt="eu logo" />
    <img src={logoRrf} className={classes.logoEu} alt="eu logo" />
  </Drawer>;
}

export default function LayerDrawer({ isDialog }) {
  const { layerDrawerOpen, layers } = useSelector(state => state.map);
  const desktop = !useSelector(state => state.global.mobileView);

  return <>
    {desktop ?
      <DesktopLayerDrawer
        open={layerDrawerOpen}
        isDialog={isDialog}
      /> :
      <MobileLayerDrawer
        open={layerDrawerOpen}
      />}
    <LegendTable layers={layers} />
  </>;
}