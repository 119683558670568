import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { api } from '../../api/application';
import StoreUtils from '../../utils/StoreUtils';
import TableUtils from '../../utils/TableUtils';
import { showSuccess } from '../notification';

const apiPath = "clause/conf";

// Slice
const slice = createSlice({
  name: 'clauseConf',
  initialState: {
    clause: null,
    isLoading: false,
    error: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    clauseSuccess: (state, action) => {
      let clause = action.payload;
      if (clause) {
        clause.groups = TableUtils.sortArrayByNumber(clause.groups, 'queueNumber');
        clause.groups.forEach((g, index) => {
          g.queueNumber = index + 1;
          g.blocks = TableUtils.sortArrayByNumber(g.blocks, 'queueNumber');
        });
      }
      state.clause = clause;
      state.isLoading = false;
    },
    groupSuccess: (state, action) => {
      const clause = action.payload;
      if (clause.groups.length === 1) {
        const group = clause.groups[0];
        group.blocks = TableUtils.sortArrayByNumber(group.blocks, 'queueNumber');
        let groupIndex = state.clause.groups.findIndex(g => g.id === group.id);
        if (groupIndex === -1) {
          groupIndex = state.clause.groups.findIndex(g => g.notSaved);
        }
        state.clause.groups[groupIndex] = group;
      }
      state.isLoading = false;
    },
    setClauseValueSuccess: (state, action) => {
      state.clause[action.payload.field] = action.payload.value;
    },
    setGroupValueSuccess: (state, action) => {
      const data = action.payload;
      state.clause.groups.find(g => g.id === data.group.id)[data.field] = data.value;
    },
    setBlockSuccess: (state, action) => {
      const data = action.payload;
      const group = state.clause.groups.find(g => g.id === data.group.id);
      if (data.block.id) {
        let block = group.blocks.find(b => b.id === data.block.id);
        block.indent = data.block.indent;
        block.blockType = data.block.blockType;
        block.classifierTypeCode = data.block.classifierTypeCode;
        block.isNumerable = data.block.isNumerable;
        block.content = data.block.content;
      } else {
        group.blocks.push({
          ...data.block,
          id: '_' + Date.now(),
          queueNumber: group.blocks.length + 1,
          notSaved: true
        });
      }
      state.isLoading = false;
    },
    deleteGroupSuccess: (state, action) => {
      state.clause.groups = state.clause.groups.filter(g => g.id !== action.payload);
      state.isLoading = false;
    },
    deleteBlockSuccess: (state, action) => {
      const data = action.payload;
      let group = state.clause.groups.find(g => g.id === data.group.id);
      group.blocks = group.blocks.filter(b => b.id !== data.block.id);
      state.isLoading = false;
    }
  },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, clauseSuccess, groupSuccess, setClauseValueSuccess, setBlockSuccess,
  setGroupValueSuccess, deleteGroupSuccess, deleteBlockSuccess } = slice.actions;

export const fetchClauseConf = (applicationType) => async dispatch => {
  dispatch(clauseSuccess());
  dispatch(startLoading());
  try {
    await api.get(`${apiPath}/${applicationType}`).then((response) => {
      dispatch(clauseSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const saveClause = (inputClause) => async dispatch => {
  dispatch(startLoading());
  try {
    const clause = cloneDeep(inputClause);
    clause.groups.forEach(g => {
      if (g.notSaved) {
        g.id = undefined;
        g.notSaved = undefined;
      }
    });
    await api.patch(`${apiPath}/${clause.applicationType}`, clause).then(response => {
      dispatch(clauseSuccess(response.data));
      dispatch(showSuccess('form.saved'));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const saveGroup = (clause, inputGroup) => async dispatch => {
  dispatch(startLoading());
  try {
    let group = cloneDeep(inputGroup);
    if (group.notSaved) {
      group.id = undefined;
      group.notSaved = undefined;
    }
    group.blocks.forEach(b => {
      if (b.notSaved) {
        b.id = undefined;
        b.notSaved = undefined;
      }
    });
    return await api.patch(`${apiPath}/${clause.applicationType}`, {
      ...clause,
      groups: [group]
    }).then(response => {
      dispatch(groupSuccess(response.data));
      dispatch(showSuccess('form.saved'));
      return true;
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
}

export const setClauseValue = (field, value) => async dispatch => {
  dispatch(setClauseValueSuccess({ field, value }));
};

export const setGroupValue = (group, field, value) => async dispatch => {
  dispatch(setGroupValueSuccess({ group, field, value }));
};

export const setGroupBlock = (group, block) => async dispatch => {
  dispatch(setBlockSuccess({ group, block }));
};

export const deleteGroup = (group) => async dispatch => {
  dispatch(startLoading());
  if (group.notSaved) {
    dispatch(deleteGroupSuccess(group.id));
    return true;
  }
  try {
    return await api.delete(`${apiPath}/group/${group.id}`).then(response => {
      dispatch(deleteGroupSuccess(group.id));
      dispatch(showSuccess('form.saved'));
      return true;
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const deleteBlock = (group, block) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.delete(`${apiPath}/group/block/${block.id}`).then(response => {
      dispatch(deleteBlockSuccess({ group, block }));
      dispatch(showSuccess('form.saved'));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};
