import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Colors, Icon, TimeLine } from 'styleguide';
import { ApplicationStatus } from '../../constants/classifierConstants';
import { fetchInOut } from '../../stores/application/application';
import { CenteredLoadingIndicator } from '../form/LoadingIndicator';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 435,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    borderRadius: 16,
    marginBottom: 40,
  },
  paper: {
    borderRadius: 16,
  },
  title: {
    padding: 16,
  },
  listItem: {
    padding: '8px 16px'
  },
}));

const getItemColor = (item) => {
  switch (item.status) {
    case ApplicationStatus.review:
    case ApplicationStatus.processing:
      return 'blue';
    case ApplicationStatus.entered:
    case ApplicationStatus.editing:
      return 'orange';
    case ApplicationStatus.submitted:
    case ApplicationStatus.processed:
    case ApplicationStatus.initiated:
    case ApplicationStatus.decisionYes:
      return 'green';
    case ApplicationStatus.deleted:
    case ApplicationStatus.cancelled:
    case ApplicationStatus.declined:
    case ApplicationStatus.notInitiated:
    case ApplicationStatus.decisionNo:
      return 'red';
    default:
      return 'grey';
  }
};

const getItemDirection = (item) => {
  switch (item.status) {
    case ApplicationStatus.initiated:
    case ApplicationStatus.notInitiated:
    case ApplicationStatus.processed:
    case ApplicationStatus.editing:
    case ApplicationStatus.cancelled:
    case ApplicationStatus.declined:
    case ApplicationStatus.decisionYes:
    case ApplicationStatus.decisionNo:
      return 'out';
    default:
      return 'in';
  }
};

const convertToTimeline = (rows, t) => {
  return rows.map(row => ({
    id: row.id,
    heading: <Link to={`/application/redirect/${row.id}`}>
      {t(`homepage.inOut.${row.documentType}`, { number: row.documentNumber })}
    </Link>,
    address: row.address,
    details: t('homepage.inOut.handler', { name: row.handlerName || t('homepage.inOut.none') }),
    color: getItemColor(row),
    direction: getItemDirection(row)
  }));
}

export default function ApplicationInOutBlock() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [rows, setRows] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loadedAll, setLoadedAll] = useState(false);

  useEffect(() => {
    async function loadData() {
      const r = await dispatch(fetchInOut());
      setRows(convertToTimeline(r, t));
    }
    loadData();
  }, [dispatch, t]);

  if (!!rows && !rows.length) {
    return "";
  }

  const handleLoadMore = async () => {
    setIsLoading(true);
    const r = await dispatch(fetchInOut(true));
    setRows(convertToTimeline(r, t));
    setLoadedAll(true);
    setIsLoading(false);
  };

  return <div className={classes.container}>
    {!rows && <CenteredLoadingIndicator />}
    {!!rows && <TimeLine data={rows} header={t('homepage.block.inOut')}
      footer={!loadedAll &&
        <Button onClick={handleLoadMore} color='secondary' size='small' fullWidth disabled={isLoading}>
          {isLoading ? <Icon icon="loading" color={Colors.sinineVaal} /> : t('homepage.inOut.loadMore')}
        </Button>
      }
    />}
  </div>;
}