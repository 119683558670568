import { createSlice } from '@reduxjs/toolkit';
import { api } from '../../api/register';
import RegisterUtils from '../../utils/RegisterUtils';
import StoreUtils from '../../utils/StoreUtils';
import { showSuccess } from '../notification';

const apiPath = (objectType, objectId) => `${RegisterUtils.getSuperObjectType(objectType)}/${objectId}/file`;

// Slice
const slice = createSlice({
  name: 'registerDocument',
  initialState: {
    rows: null,
    isLoading: false,
    formSaved: false,
    error: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    rowsSuccess: (state, action) => {
      state.rows = action.payload.slice();
      state.isLoading = false;
    },
    insertedSuccess: (state, action) => {
      state.rows.push(action.payload);
      state.formSaved = true;
      state.isLoading = false;
    },
    deletedSuccess: (state, action) => {
      state.rows = state.rows.filter(r => r.id !== action.payload);
      state.isLoading = false;
    },
    resetSuccess: state => {
      state.rows = null;
    },
    formSavedSuccess: (state, action) => {
      state.formSaved = action.payload;
    },
  },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, rowsSuccess, insertedSuccess, deletedSuccess, resetSuccess, formSavedSuccess } = slice.actions;

export const fetch = (objectType, objectId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(apiPath(objectType, objectId)).then((response) => {
      dispatch(rowsSuccess(response.data.fileInfoList));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const add = (objectType, objectId, data) => async dispatch => {
  dispatch(startLoading());
  try {
    let formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]));
    await api.post(apiPath(objectType, objectId), formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then((response) => {
      dispatch(insertedSuccess(response.data));
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const remove = (objectType, objectId, fileId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.delete(`${apiPath(objectType, objectId)}/${fileId}`).then((response) => {
      dispatch(deletedSuccess(fileId));
      dispatch(showSuccess("form.saved"));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const getDownloadUrl = (objectType, objectId, fileId) => `${api.defaults.baseURL}/${apiPath(objectType, objectId)}/${fileId}`;

export const reset = () => async dispatch => {
  dispatch(resetSuccess());
};

export const resetFormSaved = () => async dispatch => {
  dispatch(formSavedSuccess(false));
};
