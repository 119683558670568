import React, { useEffect } from 'react';
import PageContainer from '../parts/PageContainer';
import { Redirect, Route, useLocation } from 'react-router-dom';
import RegisterView from '../components/register/RegisterView';
import RegisterForm from '../components/register/RegisterForm';
import { Authority } from '../constants/authConstants';
import SecureRoute from '../components/auth/SecureRoute';
import RegisterTable from '../components/register/RegisterTable';
import { Domain } from '../constants/classifierConstants';
import SelectRegisterObject from '../components/register/SelectRegisterObject';
import { useSelector } from 'react-redux';
import AuthUtils from '../utils/AuthUtils';
import RouteUtils from '../utils/RouteUtils';

const allowedDomains = [Domain.rainwater, Domain.streetlight, Domain.road, Domain.landscaping, Domain.infrastructure];

function RegisterDomainRoute({ domain }) {
  return <Route path={`/register/${domain}`} render={() => <RegisterTable domain={domain} />} exact />;
}

export default function Register() {
  const location = useLocation();
  const { authUser } = useSelector(state => state.auth);

  useEffect(() => {
    RouteUtils.setDocumentTitle('register');
  }, []);

  if (location.pathname === '/register') {
    const defaultDomain = AuthUtils.getDefaultDomain(authUser, allowedDomains);
    return <Redirect to={`/register/${defaultDomain}`} />
  }

  return <PageContainer>
    <RegisterDomainRoute domain={Domain.rainwater} />
    <RegisterDomainRoute domain={Domain.streetlight} />
    <RegisterDomainRoute domain={Domain.road} />
    <RegisterDomainRoute domain={Domain.infrastructure} />
    <RegisterDomainRoute domain={Domain.landscaping} />
    <SecureRoute 
      path="/register/servitude" 
      render={() => <RegisterTable domain="servitude" />} 
      authority={[Authority.UC31_register_object_data, Authority.UC31_TV_register_object_aata]} 
      exact
    />
    <Route path="/register/view/:objectType/:id" component={RegisterView} />
    <SecureRoute 
      path="/register/edit/:objectType/:id?" 
      component={RegisterForm} 
      authority={[Authority.UC31_register_object_data, Authority.UC31_TV_register_object_aata]} 
    />
    <Route path="/register/select/:objectType" component={SelectRegisterObject} />
  </PageContainer>;
}