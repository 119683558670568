import { Grid, TableCell } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Paragraph } from 'styleguide';
import { ClassifierType, Domain } from '../../constants/classifierConstants';
import TableUtils from '../../utils/TableUtils';
import TypeReport from './TypeReport';

export default function RoadTypesReport() {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    let columns = [
      {
        field: 'count', headerName: t(`report.table.roadCount`), notSortable: true, renderCell: (column, cell) => {
          const value = `${TableUtils.formatNumber(cell.count) || 0} ${t('report.table.unitPieces')}`;
          if (!cell.type) {
            return <Paragraph fontSize='14B'>{value}</Paragraph>;
          }
          return value;
        }
      },
      {
        field: 'length', headerName: t(`report.table.length`), notSortable: true, renderCell: (column, cell) => {
          const value = `${TableUtils.formatNumber(cell.length) || 0} ${t('report.table.unitM')}`;
          if (!cell.type) {
            return <Paragraph fontSize='14B'>{value}</Paragraph>;
          }
          return value;
        }
      },
    ];
    return columns;
  }, [t]);

  const handleRenderCollapsibleRow = (row) => {
    if (row.open && row.data) {
      return <>
        <TableCell>{row.data.map((d, i) => <div key={i}>{d.title}</div>)}</TableCell>
        <TableCell></TableCell>
        <TableCell>{row.data.map((d, i) => <div key={i}>{`${TableUtils.formatNumber(d.length)} ${t('report.table.unitM')}`}</div>)}</TableCell>
      </>;
    }
  };

  const typeColumnLabel = t('register.roadSection.roadType');

  return <Grid container direction="column">
    <TypeReport
      classifierType={ClassifierType.roadType}
      groupedColumns={columns}
      domain={Domain.road}
      reportName="roadType"
      handleRenderCollapsibleRow={handleRenderCollapsibleRow}
      chartField="length"
      typeColumnLabel={typeColumnLabel}
    />

    <TypeReport hideHeader
      classifierType={ClassifierType.roadType}
      groupedColumns={columns}
      domain={Domain.road}
      reportName="roadTypeLocal"
      handleRenderCollapsibleRow={handleRenderCollapsibleRow}
      chartField="length"
      typeColumnLabel={typeColumnLabel}
    />
  </Grid>;
}