import { createSlice } from '@reduxjs/toolkit';
import { setActiveFeatures, toggleLayerDrawer } from './map';
import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import camelCase from 'camelcase';
import { showWarning, toggleLoadingOverlay } from '../notification';
import MapUtils from '../../utils/MapUtils';
import { XMLParser } from 'fast-xml-parser';
import i18n from 'i18next';
import { parse } from 'date-fns';
import DateUtils from '../../utils/DateUtils';

// Slice
const slice = createSlice({
  name: 'feature',
  initialState: {
    featuresDialogOpen: false,
    objectTypes: [],
    objects: [],
    drawingFeatures: [],
    drawingFeaturesQueue: [],
    highlightFeatures: [],
    isLoading: false,
    error: false,
    loadingFeatures: [],
    cadastre: null
  },
  reducers: {
    startLoading: (state, action) => {
      state.isLoading = true;
      if (action.payload) {
        state.loadingFeatures.push(action.payload);
      }
    },
    hasError: (state, action) => {
      if (action.payload.layerTitle) {
        state.loadingFeatures = state.loadingFeatures.filter(l => l !== action.payload.layerTitle);
        state.error = action.payload.error;
      }
      else {
        state.error = action.payload;
      }
      state.isLoading = false;
    },
    featuresDialogVisibilityChanged: (state, action) => {
      state.featuresDialogOpen = (action.payload !== undefined ? action.payload : !state.featuresDialogOpen);
      state.objectTypes = [];
      state.objects = [];
    },
    clearFeaturesSuccess: (state, action) => {
      state.objectTypes = [];
      state.objects = [];
      state.loadingFeatures = [];
    },
    featuresSuccess: (state, action) => {
      const features = action.payload;
      if (features && features.length) {
        const filteredObjects = features.map(f => {
          let objectFromProps = camelcaseKeys(f.properties);
          objectFromProps.geometry = f.geometry;
          return objectFromProps;
        }).filter(f => !!f.registerObjectType || !!f.procedureType);
        filteredObjects.filter(f => typeof f.registerObjectType === 'string')
          .forEach(f => {
            if (f.registerObjectType) {
              f.registerObjectType = camelCase(f.registerObjectType);
            }
            if (f.procedureType) {
              f.procedureType = camelCase(f.procedureType)
            }
          });
        state.objects = [...state.objects, ...filteredObjects].filter((obj, index, self) =>
          index === self.findIndex((t) => (
            (t.registerObjectType === obj.registerObjectType && t.id === obj.id) ||
            (t.procedureType === obj.procedureType && t.id === obj.id)
          ))
        );
        const objectTypes = [...state.objectTypes, ...filteredObjects.map(f => ({
          type: f.procedureType ? 'procedure' : 'register',
          code: f.procedureType ? f.procedureType : f.registerObjectType
        }))];
        state.objectTypes = objectTypes.filter((v, i, a) => a.findIndex(o => o.code === v.code) === i);
      }
      featuresLoaded(state, 'local');
    },
    externalFeaturesSuccess: (state, action) => {
      let features = action.payload.features;
      if (features.length) {
        if(MapUtils.plankLayers.includes(action.payload.layerLayerName)){
          features = features.map(f => convertPLANKFeature(f));
        }
        features.forEach(f => {
          f.mapLayerTitle = action.payload.layerTitle;
          f.mapLayerLayerName = action.payload.layerLayerName;
        });
        state.objects = [...state.objects, ...features];

        const objectTypes = [...state.objectTypes];
        objectTypes.push({ 
          type: action.payload.objectType || 'json', 
          code: action.payload.layerTitle, 
          title: action.payload.layerTitle 
        });
        state.objectTypes = objectTypes.filter((v, i, a) => a.findIndex(o => o.code === v.code) === i);
      }
      featuresLoaded(state, action.payload.layerTitle);
    },
    drawingFeaturesSuccess: (state, action) => {
      state.drawingFeatures = action.payload;
    },
    addDrawingFeatureQueueSuccess: (state, action) => {
      state.drawingFeaturesQueue.push(action.payload);
    },
    removeDrawingFeatureQueueSuccess: (state, action) => {
      state.drawingFeaturesQueue = [];
    },
    highlightFeaturesSuccess: (state, action) => {
      state.highlightFeatures = action.payload;
    },
    cadastreSuccess: (state, action) => {
      state.cadastre = action.payload;
    },
    updateFeatureSuccess: (state, action) => {
      const newFeature = action.payload;
      const feature = state.objects.find(f => f.id === newFeature.id && f.procedureType === newFeature.procedureType);
      if(feature) {
        if(newFeature.type !== feature.type) {
          state.objects.splice(state.objects.indexOf(feature), 1);
        } else {
          Object.keys(newFeature).forEach(key => {
            feature[key] = newFeature[key];
          });
        }
      }
    }
  },
});

export default slice.reducer;

const featuresLoaded = (state, layer) => {
  state.loadingFeatures = state.loadingFeatures.filter(l => l !== layer);
  if (state.loadingFeatures.length === 0) {
    if (!state.objects.length) {
      state.featuresDialogOpen = false;
      showWarning("table.noRows")
    } else if (state.objects.length === 1) {
      state.featuresDialogOpen = false;
    } else {
      state.featuresDialogOpen = true;
    }
  }
}

// Actions

const { startLoading, hasError, featuresDialogVisibilityChanged, featuresSuccess, externalFeaturesSuccess,
  clearFeaturesSuccess, drawingFeaturesSuccess, addDrawingFeatureQueueSuccess, removeDrawingFeatureQueueSuccess, 
  highlightFeaturesSuccess, cadastreSuccess, updateFeatureSuccess } = slice.actions;

export const clearFeatures = () => async dispatch => {
  dispatch(clearFeaturesSuccess());
  dispatch(setActiveFeatures([], false, true));
}

export const toggleFeaturesDialog = (open) => async dispatch => {
  dispatch(featuresDialogVisibilityChanged(open));
  if (open) {
    dispatch(toggleLayerDrawer(false));
  }
}

export const fetchClickedFeatures = (url) => async dispatch => {
  dispatch(startLoading('local'));
  try {
    await axios.get(url).then((response) => {
      if (typeof response.data === 'string' && response.data.indexOf('ServiceExceptionReport') !== -1) {
        dispatch(showWarning("error.map.loadFeatures"));
      }
      dispatch(featuresSuccess(response.data?.features))
      dispatch(setActiveFeatures(response.data?.features, true, true));
    });
  }
  catch (e) {
    dispatch(hasError({ error: e.message, layerTitle: 'local' }));
    dispatch(showWarning("error.map.loadFeatures", { layer: "" }));
  }
}

export const fetchClickedFeaturesExternal = (layerTitle, layerLayerName, url, format) => async dispatch => {
  dispatch(startLoading(layerTitle));
  try {
    await axios.get(url).then((response) => {
      if (response.status === 200 && response.data) {
        let features;
        if (format === 'application/vnd.ogc.gml') {
          const json = new XMLParser().parse(response.data);
          features = MapUtils.parseFeatures(json);
        } else {
          features = response.data?.features?.map(feature => {
            let parsed = feature.properties;
            parsed.geometry = feature.geometry;
            parsed.id = feature.id;
            return parsed;
          });
        }

        dispatch(externalFeaturesSuccess({ features, layerTitle, layerLayerName }));
      }
    });
  }
  catch (e) {
    dispatch(hasError({ error: e.message, layerTitle: layerTitle }));
    dispatch(showWarning("error.map.loadFeatures", { layer: layerTitle }));
  }
};

export const fetchClickedFeaturesWMTS = (layerTitle, layerLayerName, url, getFeatureInfo, tileMatrixSet, tileMatrix, tileCol, tileRow, pixels) => async dispatch => {
  dispatch(startLoading(layerTitle));
  try {
    getFeatureInfo(url, tileMatrixSet, tileMatrix, tileCol, tileRow, pixels).then((features) => {
      if(features){
        dispatch(externalFeaturesSuccess({ features, layerTitle, layerLayerName }));
        const geojsonFeatures = features.filter(f => f.geometry).map(f => MapUtils.geometryToFeature(f.geometry));
        if(geojsonFeatures.length) {
          dispatch(setActiveFeatures(geojsonFeatures, true, true));
        }
      }
    });
  }
  catch (e) {
    dispatch(hasError({ error: e.message, layerTitle: layerTitle }));
    dispatch(showWarning("error.map.loadFeatures", { layer: layerTitle }));
  }
};

export const fetchCadastreWithCallback = (url, coordinates) => async dispatch => {
  try {
    await axios.get(url).then((response) => {
      if (response.status === 200 && response.data) {
        const features = MapUtils.parseFeatures(response.data);
        if (features.length === 1) {
          const feature = features[0];
          const cadastre = feature.Properties.tunnus;
          const address = feature.Properties.l_aadress;
          dispatch(cadastreSuccess({ cadastre, coordinates, address }));
        } else {
          dispatch(showWarning("error.map.cadastreNotFound"));
          dispatch(toggleLoadingOverlay(false));
        }
      }
    });
  }
  catch (e) {
    dispatch(showWarning("error.map.loadCadastre"));
    dispatch(toggleLoadingOverlay(false));
  }
};

export const fetchWFSGetFeature = (url, featureRequest) => async dispatch => {
  dispatch(startLoading('local'));
  try {
    await axios.post(url, new XMLSerializer().serializeToString(featureRequest), {
      headers: { 'Content-Type': 'text/xml' }
    }).then((response) => {
      if (response.status === 200 && response.data) {
        dispatch(featuresSuccess(response.data?.features))
        dispatch(setActiveFeatures(response.data?.features, true, true));
      }
    })
  }
  catch (e) {
    dispatch(hasError({ error: e.message, layerTitle: 'local' }));
    dispatch(showWarning("error.map.loadFeatures"));
  }
};

export const fetchWFSGetFeatureDirect = (url, featureRequest) => async dispatch => {
  try {
    const rows = await axios.post(url, new XMLSerializer().serializeToString(featureRequest), {
      headers: { 'Content-Type': 'text/xml' }
    }).then((response) => response?.data?.features);
    return rows;
  }
  catch (e) {
    dispatch(hasError({ error: e.message, layerTitle: 'local' }));
    dispatch(showWarning("error.map.loadFeatures"));
    return [];
  }
};

export const setClickedFeatures = (features, layerTitle, layerLayerName, objectType) => async dispatch => {
  dispatch(externalFeaturesSuccess({ features, layerTitle, layerLayerName, objectType }));
  dispatch(setActiveFeatures(features.filter(f => f.geometry).map(f => MapUtils.geometryToFeature(f.geometry)), true, true));
};

export const resetCadastre = () => async dispatch => dispatch(cadastreSuccess());

export const setDrawingFeatures = (olFeatures) => async dispatch => {
  dispatch(drawingFeaturesSuccess(MapUtils.toGeoJSONFeatures(olFeatures)));
};

export const addDrawingGeometryToQueue = (olGeometry) => async dispatch => {
  dispatch(addDrawingFeatureQueueSuccess(MapUtils.geometryToFeature(olGeometry)));
};

export const removeDrawingFeatureFromQueue = (feature) => async dispatch => {
  dispatch(removeDrawingFeatureQueueSuccess(feature));
};

export const setHighlightGeometries = (geometries) => async dispatch => {
  dispatch(highlightFeaturesSuccess(MapUtils.geometriesToFeatures(geometries)));
};

export const setExternalFeatures = (features, layerTitle, layerLayerName) => async dispatch => {
  dispatch(externalFeaturesSuccess({ features, layerTitle, layerLayerName }));
};

export const updateFeature = (feature) => async dispatch => dispatch(updateFeatureSuccess(feature));

const convertPLANKFeature = (feature) => {
  formatPlankKp(feature, 'kehtestkp');
  formatPlankKp(feature, 'vastuvkp');
  formatPlankKp(feature, 'algatkp');

  let result = {};
  const fields = [
    'sysid','plannim','planseis_nimi','kehtestkp','vastuvkp','algatkp','korraldaja',
    'planid','kovid','planksh','muutev','planeesm','planviide','url'
  ];
  fields.forEach(f => result[i18n.t(`procedure.plank.${f}`)] = feature[f]);

  return result;
};

const formatPlankKp = (feature, field) => {
  try {
    if(feature[field]){
      feature[field] = DateUtils.formatDate(parse(feature[field], 'yyyy/MM/dd', new Date()));
    }
  } catch(e) {
    console.error(e);
  }
};
