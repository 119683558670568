import { useContext, useEffect } from "react";
import OLScaleLine from "ol/control/ScaleLine";
import MapContext from "../MapContext";

const ScaleLineControl = () => {
	const { map } = useContext(MapContext);

	useEffect(() => {
		if (!map) return;

		let scaleLineControl = new OLScaleLine();

		map.controls.push(scaleLineControl);

		return () => map.controls.remove(scaleLineControl);
	}, [map]);

	return null;
};

export default ScaleLineControl;