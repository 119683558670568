import i18n from "../i18n";
import RegisterUtils from "./RegisterUtils";

const RouteUtils = {
  registerPathAwareRoute(history, to) {
    if (history.location.pathname.startsWith('/map')) {
      return '/map/f/r' + to;
    }
    return '/register' + to;
  },
  navigateToObject(history, objectType, objectId) {
    switch (objectType) {
      case 'workOrder':
        history.push(`/workOrder/view/${objectId}`);
        break;
      case 'application':
      case 'clause':
        history.push(`/application/redirect/${objectId}`);
        break;
      case 'planning':
        history.push(`/plan/view/${objectId}`);
        break;
      default:
        const objectDef = RegisterUtils.getObjectDef(objectType);
        if (objectDef) {
          history.push(`/register/view/${objectType}/${objectId}`);
        }
    }
  },
  navigateToMap(history, objectType, objectId) {
    switch (objectType) {
      case 'workOrder':
        history.push(`/workOrder/view/${objectId}`);
        break;
      case 'application':
      case 'clause':
        history.push(`/application/redirect/${objectId}`);
        break;
      case 'planning':
        history.push(`/plan/view/${objectId}`);
        break;
      default:
        const objectDef = RegisterUtils.getObjectDef(objectType);
        if (objectDef) {
          history.push(`/map/f/r/viewCenter/${objectType}/${objectId}`);
        }
    }
  },
  setDocumentTitle(page) {
    if (i18n.isInitialized) {
      document.title = i18n.t('header.documentTitle', { page: i18n.t(`${page}.title`) });
    }
  }
};
export default RouteUtils;