import { Box, makeStyles, Paper, Toolbar } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Colors, Heading, Icon, NavButton, Paragraph } from 'styleguide';
import { ClassifierType, Domain } from '../../constants/classifierConstants';
import TableUtils from '../../utils/TableUtils';
import SimplePageableTable from '../table/SimplePageableTable';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { toggleLoadingOverlay } from '../../stores/notification';
import PdfUtils from '../../utils/PdfUtils';
import DateUtils from '../../utils/DateUtils';
import ChartUtils from '../../utils/ChartUtils';
import SettlementSelect from '../table/SettlementSelect';
import { fetch } from '../../stores/register/registerReport';

const useStyles = makeStyles((theme) => ({
  flex: {
    flexGrow: 1
  },
  header: {
    paddingLeft: 16
  },
  toolbar: {
    padding: 0,
  }
}));

export default function SettlementReport() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { domain } = useParams();

  const reportName = 'settlement';
  const rows = useSelector(state => state.registerReport.rows[reportName]);
  const isLoading = useSelector(state => state.registerReport.isLoading);
  const { classifiers } = useSelector(state => state.classifier);
  const compact = useSelector(state => state.global.mobileView);

  const [settlements, setSettlements] = useState([]);

  const title = t(`report.${domain}.settlement`);
  const subTitle = t('report.report');

  let countUnit = Domain.streetlight === domain ? t('report.table.unitPieces') : t('report.table.unitM');

  let types = useMemo(() => {
    let types = [];
    switch (domain) {
      case Domain.rainwater:
        types = TableUtils.getClassifiersByType(classifiers, ClassifierType.pipeType);
        types.unshift({
          itemCode: 'ditch',
          title: t('register.ditch.label')
        });
        break;
      case Domain.streetlight:
        types = TableUtils.getClassifiersByType(classifiers, ClassifierType.lampType);
        break;
      case Domain.road:
        types = TableUtils.getClassifiersByType(classifiers, ClassifierType.roadType);
        break;
      default:
    }
    return types;
  }, [t, domain, classifiers]);


  const totalColumnLabel = t(`report.${domain}.totalCount`);
  const columns = useMemo(() => {
    let columns = [
      {
        field: 'location', headerName: t('report.table.location'), notSortable: true,
        renderCell: (column, cell) => <Paragraph fontSize='14B'>{cell.location || t('report.table.total')}</Paragraph>
      },
      {
        field: 'count', headerName: totalColumnLabel, notSortable: true, renderCell: (column, cell) => {
          const value = `${TableUtils.formatNumber(cell.count) || 0} ${countUnit}`;
          if (!cell.location) {
            return <Paragraph fontSize='14B'>{value}</Paragraph>;
          }
          return value;
        }
      },
    ];
    types.forEach(type => columns.push({
      field: type.itemCode, headerName: t('report.table.incCount', { type: type.title }), notSortable: true,
      renderCell: (column, cell) => {
        const value = `${TableUtils.formatNumber(cell[type.itemCode]) || 0} ${countUnit} | ${ChartUtils.calculatePercentage(cell.count, cell[type.itemCode])}%`;
        if (!cell.location) {
          return <Paragraph fontSize='14B'>{value}</Paragraph>;
        }
        return value;
      }
    }));
    return columns;
  }, [t, types, totalColumnLabel, countUnit]);

  const handleGenerate = () => {
    dispatch(fetch(domain, reportName, settlements));
  };

  const handleClose = () => history.goBack();

  const handlePdfExport = () => {
    dispatch(toggleLoadingOverlay(true));
    document.body.style.cursor = 'progress';

    const reportTableId = 'report-table';
    const reportTable = document.getElementById(reportTableId);
    const reportTableOrigWidth = reportTable.style.width;
    reportTable.style.width = PdfUtils.widthPx() + 'px';

    const pdf = PdfUtils.newPdf();

    PdfUtils.addTitle(pdf, title);

    PdfUtils.addTable(pdf, reportTableId);

    const handlePdfDone = () => {
      PdfUtils.savePdf(pdf, `${subTitle} ${title} ${DateUtils.nowISODate()}`);

      reportTable.style.width = reportTableOrigWidth;
      document.body.style.cursor = 'auto';
      dispatch(toggleLoadingOverlay(false));
    };

    PdfUtils.addImageLandscape(pdf, 'chart').then(handlePdfDone);
  };

  const header = <Box padding={3} display="flex" alignItems="center">
    <SettlementSelect values={settlements} handleChange={setSettlements} disabled={isLoading} autoFocus />
    <Box paddingLeft={3}>
      <Button size="small" onClick={handleGenerate} disabled={isLoading}>{t('report.generate')}</Button>
    </Box>
  </Box>;

  return <div>
    <Toolbar className={classes.toolbar}>
      <NavButton onClick={handleClose} action='back' color='secondary'></NavButton>
      <span className={classes.header}>
        <Heading level={compact ? '4' : '3'}>{title}</Heading>
        <Paragraph fontSize={compact ? '14' : '14B'}>{subTitle}</Paragraph>
      </span>
      <span className={classes.flex}></span>
      <Button size="extra-small" color="tertiary" onClick={handlePdfExport} disabled={isLoading || !rows?.length}>
        <Icon icon="files" color={Colors.sinineVaal} /> {t('report.exportPdf')}
      </Button>
    </Toolbar>

    <Box py={4}>
      <SimplePageableTable
        id="report-table"
        rows={rows}
        columns={columns}
        header={header}
        pageSize={200}
        disableLoading
        loading={isLoading || !rows}
        loadingRowsCount={1}
      />
    </Box>

    {!compact && rows && <Paper>
      <ResponsiveContainer width="100%" height={500} id="chart">
        <BarChart
          width={500}
          height={500}
          data={rows.filter(r => !!r.location)}
          margin={{
            top: 50,
            right: 20,
            left: 0,
            bottom: 60,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis dataKey="location" />
          <YAxis />
          <Tooltip />
          <Legend iconSize={16} iconType="square" formatter={ChartUtils.renderLegendText} wrapperStyle={{ position: 'relative' }} />
          <Bar dataKey="count" name={totalColumnLabel} fill={ChartUtils.getGraphColor(0)} radius={4} label={{ position: 'top' }} />
          {types.map((type, index) =>
            <Bar key={index}
              dataKey={type.itemCode}
              name={type.title}
              fill={ChartUtils.getGraphColor(index + 1)}
              radius={4}
              label={{ position: 'top' }} />
          )}
        </BarChart>
      </ResponsiveContainer>
    </Paper>}
  </div>;
}