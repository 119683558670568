import { useContext, useEffect } from "react";
import MapContext from "../MapContext";
import { useDispatch, useSelector } from "react-redux";
import { clearFeatures, fetchClickedFeatures, fetchClickedFeaturesExternal, fetchClickedFeaturesWMTS, setClickedFeatures } from "../../../stores/map/feature";
import MapUtils from "../../../utils/MapUtils";
import WMTS from 'ol/source/WMTS.js';
import VectorLayer from "ol/layer/Vector";
import { clone } from "lodash";
import { Point } from "ol/geom";
import { buffer } from "ol/extent";

const handleSingleClick = (evt) => {
  const map = evt.map;
  const dispatch = map.dispatch;
  const view = map.getView();
  const viewResolution = view.getResolution();
  const coordinate = evt.coordinate;

  dispatch(clearFeatures());
  let source = null;
  let queryLayers = [];

  map.getLayers().forEach((layer) => {
    if (layer.getVisible() && layer.get('queryable')) {
      if(layer.getSource().getFeatureInfoUrl){
        const url = layer.getSource().getUrl();
        if (url.startsWith('/')) {
          if (!source || url.startsWith('/vaal-admin')) {
            source = layer.getSource();
          }
          queryLayers.push(layer.getSource().getParams()['LAYERS']);
        } else {
          const layersParam = layer.getSource().getParams()['LAYERS'];
          const isCadastreLayer = layersParam?.indexOf(MapUtils.cadastreLayerName) !== -1;
          const infoFormat = layer.get('infoFormat');

          let externalUrl = layer.getSource().getFeatureInfoUrl(
            coordinate,
            viewResolution,
            'EPSG:3301',
            {
              'INFO_FORMAT': infoFormat,
              'FEATURE_COUNT': 100
            }
          );

          if (isCadastreLayer) {
            externalUrl = externalUrl.replace(encodeURIComponent(MapUtils.cadastreWithAddressLayerName), '');
          }

          dispatch(fetchClickedFeaturesExternal(layer.get('title'), layer.get('layerLayerName'), externalUrl, infoFormat));
        }
      } else if(layer.getSource() instanceof WMTS && layer.get('getFeatureInfo')){
        const source = layer.getSource();
        const tileGrid = layer.getSource().getTileGrid();
        const tileCoord = tileGrid.getTileCoordForCoordAndResolution(evt.coordinate, map.getView().getResolution());

        const coordinateColumn = evt.coordinate[0];
        const coordinateRow = evt.coordinate[1];
        const matrixId = tileCoord[0];
        const tileColumn = tileCoord[1];
        const tileRow = tileCoord[2];
        const resolution = tileGrid.getResolution(matrixId);
        const origin = tileGrid.getOrigin(matrixId);
        const originColumn = origin[0];
        const originRow = origin[1];
        const tileSize = tileGrid.getTileSize(matrixId);
        const tileOriginColumn = originColumn + (tileColumn * tileSize * resolution);
        const tileOriginRow = originRow - (tileRow * tileSize * resolution);

        const tileXLength = coordinateColumn - tileOriginColumn;
        const tileYLength = tileOriginRow - coordinateRow;
        const tileXPixel = Math.round(tileXLength / resolution);
        const tileYPixel = Math.round(tileYLength / resolution);

        dispatch(fetchClickedFeaturesWMTS(
          layer.get('title'), 
          layer.get('layerLayerName'), 
          source.urls[0],
          layer.get('getFeatureInfo'),
          source.getMatrixSet(),
          tileGrid.getMatrixId(tileCoord[0]),
          tileCoord[1],
          tileCoord[2],
          [tileYPixel, tileXPixel]
        ));
      } else if(layer instanceof VectorLayer) {
        const resolution = evt.map.getView().getResolution();
        const point = new Point(evt.coordinate);
        const extent = new buffer(point.getExtent(), 10 * resolution);

        const features = [];
        layer.getSource().forEachFeatureIntersectingExtent(extent, (olFeature) => {
          const feature = clone(olFeature.getProperties());
          feature.geometry = MapUtils.toGeoJSONGeometry(olFeature);
          features.push(feature);
        });
        if(features.length) {
          dispatch(setClickedFeatures(features, layer.get('title'), layer.get('type'), features[0].procedureType));
        }
      }
    }
  });
  if (queryLayers.length) {
    const layersJoined = queryLayers.join(',');
    const url = source.getFeatureInfoUrl(
      coordinate,
      viewResolution,
      'EPSG:3301',
      {
        'INFO_FORMAT': 'application/json',
        'QUERY_LAYERS': layersJoined,
        'LAYERS': layersJoined,
        'FEATURE_COUNT': 100,
        'STYLES': ''
      }
    );
    dispatch(fetchClickedFeatures(url));
  }
};

const SelectFeatureControl = ({ onClick }) => {
  const { map } = useContext(MapContext);
  const { selectActive } = useSelector(state => state.map);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!map) return;

    map.dispatch = dispatch;

    return () => {
      map.dispatch = null;
    }
  }, [map, dispatch]);

  useEffect(() => {
    if (!map) return;

    const handleClick = onClick || handleSingleClick;

    if (selectActive) {
      map.on('singleclick', handleClick);
    } else {
      map.un('singleclick', handleClick);
    }
  }, [map, selectActive, onClick]);

  return null;
};

export default SelectFeatureControl;