import { FormControlLabel } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, VAutocomplete } from 'styleguide';
import { fetchSettlements } from '../../stores/admin/classifier';

export default function SettlementSelect({ values, handleChange, disabled, ...rest }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const settlements = useSelector(state => state.classifier.settlements);

  const shouldFetchSettlements = !settlements.length;
  useEffect(() => shouldFetchSettlements && dispatch(fetchSettlements()), [shouldFetchSettlements, dispatch]);

  return <VAutocomplete
    multiple fullWidth disableCloseOnSelect
    label={t('report.table.chooseSettlements')}
    value={values}
    onChange={(event, value) => handleChange(value)}
    options={settlements}
    disabled={disabled}
    renderOption={(option, { selected }) => (
      <FormControlLabel
        control={<Checkbox checked={selected} />}
        label={option}
      />
    )}
    getOptionLabel={(option) => option}
    {...rest}
  />;
}
