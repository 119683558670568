import { Box, makeStyles, Toolbar } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { fetchSelected } from '../../stores/planning/planning';
import { Route, useHistory, useParams, useRouteMatch } from 'react-router';
import AuthUtils from '../../utils/AuthUtils';
import { CenteredLoadingIndicator } from '../form/LoadingIndicator';
import PlanningActions from './PlanningActions';
import PlanningDetails from './PlanningDetails';
import PlanningDocuments from './PlanningDocuments';
import PlanningLocations from './PlanningLocations';
import PlanningNotes from './PlanningNotes';
import PlanningOperations from './PlanningOperations';
import PlanningParticipants from './PlanningParticipants';
import PlanningStatusLabel from './PlanningStatusLabel';
import ScrollToTopOnMount from '../form/ScrollToTopOnMount';
import { Heading, NavButton, Paragraph } from 'styleguide';
import SecureRoute from '../auth/SecureRoute';
import { PlanningAddDocumentForm, PlanningAddDocumentVersionForm, PlanningDecisionDocumentForm, PlanningEditDocumentForm, PlanningEditDocumentLimitedForm } from '../application/plan/PlanDocumentForm';
import { Authority } from '../../constants/authConstants';
import PlanningUtils from '../../utils/PlanningUtils';
import ParticipantForm from './ParticipantForm';
import PlanningEditForm from './PlanningEditForm';
import OperationForm from './OperationForm';
import ProposalForm from './ProposalForm';
import PlanningOpinionForm from './PlanningOpinionForm';
import { PlanningCoordinateForm, PlanningDontCoordinateForm } from './PlanningCoordinationForm';
import { PlanningSendNotificationForm } from '../application/plan/SendNotificationForm';
import NavigationTabs from '../table/NavigationTabs';
import { PlanningStatus } from '../../constants/classifierConstants';

const useStyles = makeStyles((theme) => ({
  flex: {
    flexGrow: 1
  },
  header: {
    paddingLeft: 16
  },
  toolbar: {
    padding: 0
  },
}));

export default function PlanningView({ mapView }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const compact = useSelector(state => state.global.mobileView) || mapView;
  const { path, url } = useRouteMatch();

  const planning = useSelector(state => state.planning.selected);
  const authUser = useSelector(state => state.auth.authUser);

  const planningId = planning?.id;

  //fetch selected
  const shouldFetch = !planningId || planningId !== Number(id);
  useEffect(() => shouldFetch && dispatch(fetchSelected(id)),
    [shouldFetch, id, dispatch]);

  const handleClose = () => {
    history.goBack();
  };

  if (!id || !planning) {
    return <CenteredLoadingIndicator />;
  }

  const title = t('planning.view.header', { number: planning.number });
  const tabs = [
    <NavLink key="main" to={`${url}`} exact replace>{t('planning.view.details')}</NavLink>,
    <NavLink key="location" to={`${url}/location`} replace>{t('planning.view.locations')}</NavLink>,
    <NavLink key="participant" to={`${url}/participant`} replace>{t('planning.view.participants')}</NavLink>,
    <NavLink key="document" to={`${url}/document`} replace>{t('planning.view.documents')}</NavLink>,
    <NavLink key="operation" to={`${url}/operation`} replace>{t('planning.view.operations')}</NavLink>,
  ];

  if (AuthUtils.hasAuthority(authUser, Authority.UC11_planning)) {
    tabs.push(<NavLink key="action" to={`${url}/action`} replace>{t('planning.view.actions')}</NavLink>);
  }

  const isGovUser = AuthUtils.isGov(authUser);
  const readOnly = !isGovUser || !PlanningUtils.isHandler(authUser, planning);
  const planReadOnly = PlanningStatus.initiated !== planning.status || readOnly;

  const showRestricted = isGovUser || planning.isParticipant;

  return <div>
    <ScrollToTopOnMount />
    <Toolbar className={classes.toolbar}>
      <NavButton onClick={handleClose} action='back' color='secondary'></NavButton>
      <span className={classes.header}>
        <Heading level='3'>{title}</Heading>
        <Paragraph fontSize='14B'>{planning.location}</Paragraph>
      </span>

      <span className={classes.flex}></span>

      <Box textAlign="right">
        <PlanningStatusLabel status={planning.status} />
      </Box>
    </Toolbar>

    {(tabs.length > 1) &&
      <Box py={compact ? 2 : 5}>
        <NavigationTabs>
          {tabs}
        </NavigationTabs>
      </Box>
    }

    <Route path={`${path}`} render={() => <PlanningDetails readOnly={readOnly} title={title} />} exact />
    <Route path={`${path}/action`} render={() => <PlanningActions />} />
    <Route path={`${path}/document`} render={() => <PlanningDocuments readOnly={readOnly} showRestricted={showRestricted} />} />
    <Route path={`${path}/location`} render={() => <PlanningLocations readOnly={planReadOnly} />} />
    <Route path={`${path}/participant`} render={() => <PlanningParticipants readOnly={readOnly} showRestricted={showRestricted} />} />
    <Route path={`${path}/operation`} render={() => <PlanningOperations readOnly={readOnly} showRestricted={showRestricted} />} />
    <Route path={`${path}/note`} render={() => <PlanningNotes readOnly={readOnly} />} />

    <SecureRoute path={`${path}/addProposal`} component={ProposalForm} authority={Authority.UC1_application_submit} />
    <SecureRoute path={`${path}/addOpinion/:operationId`} component={PlanningOpinionForm} authority={Authority.UC1_application_submit} />

    <SecureRoute path={`${path}/coordinate/:operationId`} component={PlanningCoordinateForm} authority={Authority.UC27_partner_work_order} />
    <SecureRoute path={`${path}/dontCoordinate/:operationId`} component={PlanningDontCoordinateForm} authority={Authority.UC27_partner_work_order} />

    <SecureRoute path={`${path}/addDocument`} component={PlanningAddDocumentForm} authority={Authority.UC11_planning} />
    <SecureRoute path={`${path}/addOperationDocument/:operationId`} component={PlanningAddDocumentForm} authority={Authority.UC11_planning} />
    <SecureRoute path={`${path}/decision/:decision`} component={PlanningDecisionDocumentForm} authority={Authority.UC11_planning} />
    <SecureRoute path={`${path}/editDocument/:documentId`} component={PlanningEditDocumentForm} authority={Authority.UC11_planning} />
    <SecureRoute path={`${path}/editDocumentLimited/:documentId`} component={PlanningEditDocumentLimitedForm} authority={Authority.UC11_planning} />
    <SecureRoute path={`${path}/addDocumentVersion/:documentId`} component={PlanningAddDocumentVersionForm} authority={Authority.UC11_planning} />
    <SecureRoute path={`${path}/editParticipant/:participantId`} component={ParticipantForm} authority={Authority.UC11_planning} />
    <SecureRoute path={`${path}/edit`} component={PlanningEditForm} authority={Authority.UC11_planning} />
    <SecureRoute path={`${path}/editOperation/:operationId?`} component={OperationForm} authority={Authority.UC11_planning} />
    <SecureRoute path={`${path}/askOpinion`} component={PlanningSendNotificationForm} authority={Authority.UC11_planning} />
    <SecureRoute path={`${path}/sendCoordination`} component={PlanningSendNotificationForm} authority={Authority.UC11_planning} />
    <SecureRoute path={`${path}/sendNotification/:operationId?`} component={PlanningSendNotificationForm} authority={Authority.UC11_planning} />
  </div >;
}