import { TextField } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

export default function VNumberField({ name, label, decimals, required, errors, error, helperText, control, defaultValue, ...rest }) {
  const { t } = useTranslation();

  let rules = {};
  if (required) {
    rules.required = { value: true, message: t('validation.required') };
  }

  if (!error) {
    error = !!(errors && errors[name]);
  }
  if (!helperText) {
    helperText = errors && errors[name] && errors[name].message;
  }

  return <Controller
    render={({ onChange, value }) => (
      <NumberFormat
        customInput={TextField}
        allowNegative={false}
        decimalScale={decimals || 0}
        decimalSeparator=","
        isNumericString
        onValueChange={(v) => onChange(v.floatValue)}
        value={value}
        variant="outlined"
        className="VaalTextField"
        label={t(label)}
        error={error}
        helperText={helperText}
        {...rest}
      />
    )}
    control={control}
    name={name}
    defaultValue={defaultValue || null}
  />;
}