import React, { useEffect } from 'react';
import PageContainer from '../parts/PageContainer';
import { Domain } from '../constants/classifierConstants';
import SecureRoute from '../components/auth/SecureRoute';
import ApplicationTable from '../components/application/ApplicationTable';
import { Authority } from '../constants/authConstants';
import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router';
import ApplicationView from '../components/application/ApplicationView';
import MyApplicationsTable from '../components/application/citizen/MyApplicationsTable';
import AddApplicationTab from '../components/application/citizen/AddApplicationTab';
import ApplicationForm from '../components/application/form/ApplicationForm';
import AuthUtils from '../utils/AuthUtils';
import ClauseForm from '../components/application/clause/ClauseForm';
import PartnerApplicationTable from '../components/application/PartnerApplicationTable';
import ApplicationRedirect from '../components/application/ApplicationRedirect';
import RouteUtils from '../utils/RouteUtils';

const allowedDomains = [Domain.plan, Domain.rainwater, Domain.streetlight, Domain.road];

function ApplicationRoute({ domain, isPartner }) {
  if (isPartner) {
    return <SecureRoute
      path={`/application/${domain}`}
      render={() => <PartnerApplicationTable domain={domain} />} exact authority={Authority.UC19_partner_application}
    />;
  }
  return <SecureRoute
    path={`/application/${domain}`}
    render={() => <ApplicationTable domain={domain} />} exact authority={[Authority.UC7_application, Authority.UC7_view_application]}
  />;
}

export default function Application() {
  const location = useLocation();
  const { authUser } = useSelector(state => state.auth);

  useEffect(() => {
    RouteUtils.setDocumentTitle('application');
  }, []);

  if (location.pathname === '/application' || location.pathname === '/application/') {
    let defaultDomain = authUser?.domain || Domain.rainwater;
    if (AuthUtils.hasAuthority(authUser, Authority.UC6_my_applications)) {
      defaultDomain = 'my';
    } else if (AuthUtils.hasAuthority(authUser, Authority.UC19_partner_application)) {
      defaultDomain = Domain.streetlight;
    } else {
      defaultDomain = AuthUtils.getDefaultDomain(authUser, allowedDomains);
    }
    return <Redirect to={`/application/${defaultDomain}`} />;
  }

  const isPartner = AuthUtils.hasAuthority(authUser, Authority.UC19_partner_application);

  return <PageContainer>
    <ApplicationRoute domain={Domain.plan} isPartner={isPartner} />
    <ApplicationRoute domain={Domain.rainwater} isPartner={isPartner} />
    <ApplicationRoute domain={Domain.streetlight} isPartner={isPartner} />
    <ApplicationRoute domain={Domain.road} isPartner={isPartner} />
    <ApplicationRoute domain="archive" isPartner={isPartner} />

    <Route path="/application/redirect/:id" component={ApplicationRedirect} />
    <Route path="/application/view/:id" component={ApplicationView} />

    <SecureRoute path="/application/my" exact component={MyApplicationsTable} authority={Authority.UC6_my_applications} />
    <SecureRoute path="/application/my/plans" render={() => <MyApplicationsTable plan />} authority={Authority.UC6_my_applications} />
    <SecureRoute path="/application/add" component={AddApplicationTab} authority={Authority.UC1_application_submit} />
    {/* TODO authority? */}
    <SecureRoute path="/application/form/:id?/:step?" component={ApplicationForm} authority={[Authority.UC1_application_submit, Authority.UC37_add_application]} />

    <SecureRoute path="/application/clause/:id?/:step?" component={ClauseForm} authority={[Authority.UC7_application, Authority.UC19_partner_application]} />
  </PageContainer>;
}