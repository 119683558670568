import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { api } from '../../api/application';
import { ApplicantType } from '../../constants/classifierConstants';
import StoreUtils from '../../utils/StoreUtils';
import TableUtils from '../../utils/TableUtils';
import { showSuccess, toggleLoadingOverlay, showWarning } from '../notification';
import { setApplicationField } from './application';

const apiPath = (applicationId) => `application/${applicationId}/applicant`;

// Slice
const slice = createSlice({
  name: 'applicationApplicant',
  initialState: {
    applicants: null,
    applicantStatus: null,
    rows: [],
    isDirtyStore: false,
    submitted: false,
    isLoading: false,
    error: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    rowsSuccess: (state, action) => {
      const data = cloneDeep(action.payload);
      state.applicants = action.payload;
      let allParties = [];
      if (data.applicant) {
        allParties.push(data.applicant);
      }
      if (data.representative) {
        allParties.push(data.representative);
      }
      if (data.parties?.length) {
        allParties = TableUtils.mergeArrayById(allParties, data.parties);
      }
      if (data.planParties?.length) {
        allParties = TableUtils.mergeArrayById(allParties, data.planParties);
      }
      state.rows = allParties;
      state.isLoading = false;
      state.isDirtyStore = false;
    },
    toggleWithRepresentativeSuccess: (state, action) => {
      if (!!action.payload) {
        state.applicants.representative = cloneDeep(state.applicants.applicant);
        state.applicants.applicant = null;
      } else {
        state.applicants.applicant = cloneDeep(state.applicants.representative);
        state.applicants.representative = null;
      }
      state.isDirtyStore = true;
    },
    applicantStatusSuccess: (state, action) => {
      state.applicantStatus = action.payload;
    },
    savedSuccess: (state, action) => {
      state.applicants = action.payload;
      state.applicantStatus = 'OK';
      state.isDirtyStore = false;
      state.isLoading = false;
    },
    deletedSuccess: (state, action) => {
      state.applicants.parties = state.applicants.parties.filter(r => r.id !== action.payload);
      state.isLoading = false;
      state.isDirtyStore = true;
    },
    resetSuccess: state => {
      state.applicants = null;
      state.applicantStatus = null;
      state.isDirtyStore = false;
    },
    addPlanSuccess: (state, action) => {
      let party = cloneDeep(action.payload);
      party.fullName = !!party.firstName ? `${party.firstName} ${party.name}` : party.name;
      state.rows.push(party);
      state.submitted = true;
      state.isLoading = false;
    },
    deletedPlanSuccess: (state, action) => {
      state.rows = state.rows.filter(r => r.id !== action.payload);
      state.isLoading = false;
    },
    submittedSuccess: (state, action) => {
      state.submitted = action.payload;
      state.isLoading = false;
    },
    ownersSuccess: (state, action) => {
      state.rows = [...state.rows?.filter(r => !!r.id && r.applicantType !== ApplicantType.owner), ...action.payload];
      state.isLoading = false;
    },
    setSelectedSuccess: (state, action) => {
      const applicantCode = action.payload.applicantCode;
      if (applicantCode) {
        state.rows.filter(a => !!a.email && a.code === applicantCode).forEach(a => a.isSelected = action.payload.selected);
      } else {
        state.rows.filter(a => !!a.email).forEach(a => a.isSelected = action.payload.selected);
      }
    },
  },
});

export default slice.reducer;

// Actions

const { startLoading, hasError, rowsSuccess, toggleWithRepresentativeSuccess,
  applicantStatusSuccess, savedSuccess, deletedSuccess, resetSuccess,
  addPlanSuccess, deletedPlanSuccess, submittedSuccess, ownersSuccess, setSelectedSuccess } = slice.actions;

export const setApplicants = (applicants) => async dispatch => {
  dispatch(rowsSuccess(applicants));
};

export const fetchApplicants = (applicationId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get(apiPath(applicationId)).then((response) => {
      dispatch(rowsSuccess(response.data));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const toggleWithRepresentative = (withRepresentative) => async dispatch => {
  dispatch(setApplicationField('isPhysicalApplicant', !withRepresentative));
  dispatch(toggleWithRepresentativeSuccess(withRepresentative));
};

export const setApplicantStatus = (stepStatus) => async dispatch => {
  dispatch(applicantStatusSuccess(stepStatus));
};

export const saveApplicants = (applicationId, applicants) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.patch(apiPath(applicationId), applicants).then((response) => {
      dispatch(savedSuccess(response.data));
      dispatch(showSuccess("form.saved"));
    });
  }
  catch (e) {
    dispatch(applicantStatusSuccess(null));
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const checkApplicants = (applicationId, applicants) => async dispatch => {
  dispatch(startLoading());
  try {
    return await api.post(`${apiPath(applicationId)}/check`, applicants).then((response) => {
      if (!!response && response != null) {
        if (response.data.firstName) {
          let firstName = response.data.firstName;
          dispatch(showWarning('ErrorCode.applicantFirstNameIncorrect', { firstName }));
          dispatch(hasError(null));
          return false;
        } else if (response.data.lastName) {
          let lastName = response.data.lastName;
          dispatch(showWarning('ErrorCode.applicantLastNameIncorrect', { lastName }));
          dispatch(hasError(null));
          return false;
        }
      }
      return true;
    });
  }
  catch (e) {
    dispatch(applicantStatusSuccess(null));
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const deleteApplicant = (applicationId, applicantId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.delete(`${apiPath(applicationId)}/${applicantId}`).then((response) => {
      dispatch(deletedSuccess(applicantId));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const resetApplicant = () => async dispatch => {
  dispatch(resetSuccess());
};

export const addPlanApplicant = (applicationId, dto) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.post(`application/${applicationId}/planApplicant`, dto).then((response) => {
      dispatch(addPlanSuccess(response.data));
      dispatch(showSuccess("form.saved"));
    });
  }
  catch (e) {
    dispatch(applicantStatusSuccess(null));
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const deletePlanApplicant = (applicationId, applicantId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.delete(`application/${applicationId}/planApplicant/${applicantId}`).then((response) => {
      dispatch(deletedPlanSuccess(applicantId));
    })
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const resetSubmitted = () => async dispatch => {
  dispatch(submittedSuccess(false));
};

export const fetchOwners = (applicationId) => async dispatch => {
  dispatch(toggleLoadingOverlay(true));
  dispatch(startLoading());
  try {
    await api.get(`application/${applicationId}/owners`).then((response) => {
      dispatch(ownersSuccess(response.data));
      dispatch(toggleLoadingOverlay(false));
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
    dispatch(toggleLoadingOverlay(false));
  }
};

export const setApplicantSelected = (selected, applicantCode) => async dispatch => {
  dispatch(setSelectedSuccess({ selected, applicantCode }));
};

export const sendNotifications = (applicationId, dto) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.post(`application/${applicationId}/planApplicant/notify`, dto).then((response) => {
      dispatch(submittedSuccess(true));
      dispatch(setApplicantSelected(false));
      dispatch(showSuccess("form.saved"));
    });
  }
  catch (e) {
    dispatch(applicantStatusSuccess(null));
    dispatch(StoreUtils.handleError(e, hasError));
  }
};

export const addInvolved = (applicationId) => async dispatch => {
  dispatch(startLoading());
  try {
    return await api.post(`application/${applicationId}/planInvolved`).then((response) => {
      dispatch(addPlanSuccess(response.data));
      return true;
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
};