import { createSlice } from '@reduxjs/toolkit';
import { api } from '../api/auth';
import i18n from '../i18n';
import { changeClassifierLanguage } from './admin/classifier';
import StoreUtils from '../utils/StoreUtils';
import { changeLayersLanguage } from './map/map';
import RegisterUtils from '../utils/RegisterUtils';

// Slice
const slice = createSlice({
  name: 'auth',
  initialState: {
    authenticated: false,
    authUser: null,
    isLoading: true,
    error: false
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    authenticationSuccess: (state, action) => {
      if (action.payload) {
        const user = action.payload;
        state.authUser = user;
        state.authUser.fullName = `${user?.firstName} ${user?.lastName}`;
        state.authenticated = true;

        localStorage.setItem('vaalTokenExpires', action.payload.expires);
      } else {
        state.authUser = {};
        state.authenticated = false;
        localStorage.removeItem('vaalTokenExpires');
      }
      state.isLoading = false;
    },
    logoutSuccess: state => {
      localStorage.removeItem('vaalTokenExpires');
      window.location.href = '/';
    }
  },
});

export default slice.reducer;

// Actions

const { authenticationSuccess, logoutSuccess, startLoading, hasError } = slice.actions;

export const fetchAuthUser = () => async dispatch => {
  const expires = localStorage.getItem('vaalTokenExpires');
  if (expires < (Date.now() / 1000)) {
    await refreshToken();
  }
  dispatch(startLoading());
  try {
    return await api.get('/check').then((response) => {
      dispatch(authenticationSuccess(response.data));
      const language = sessionStorage.getItem('vaalLanguage') || response.data?.language;
      if (language && language !== i18n.language) {
        i18n.changeLanguage(language).then(() => {
          dispatch(changeClassifierLanguage());
          dispatch(changeLayersLanguage());
          RegisterUtils.changeObjectDefLanguage();
        });
      }
      setInterval(() => {
        dispatch(refreshToken());
      }, 25000);
      return response.data;
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
}

export const logout = () => async dispatch => {
  dispatch(startLoading());
  try {
    await api.get('/logout').then((response) => {
      dispatch(logoutSuccess());
      window.open(response.data, '_self', 'noopener,noreferrer');
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
}

export const changeRole = (userId) => async dispatch => {
  dispatch(startLoading());
  try {
    await api.post(`/change/${userId}`).then((response) => {
      window.location.href = '/';
    });
  }
  catch (e) {
    dispatch(StoreUtils.handleError(e, hasError));
  }
}

export const refreshToken = () => async dispatch => {
  const expires = localStorage.getItem('vaalTokenExpires');
  if (expires && (expires - (Date.now() / 1000)) < 300) {
    try {
      await api.get(`/refreshToken`).then((response) => {
        if (response.data) {
          localStorage.setItem('vaalTokenExpires', response.data);
        } else {
          localStorage.removeItem('vaalTokenExpires');
          window.location.href = '/';
        }
      });
    }
    catch (e) {
      dispatch(StoreUtils.handleError(e, hasError));
    }
  }
}