import { Box, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import FileUpload from '../../form/FileUpload';
import { Colors, Paragraph } from 'styleguide';
import { useDispatch, useSelector } from 'react-redux';
import { addFiles, deleteFile, getFileDownloadUrl, saveFileDescription } from '../../../stores/application/applicationFile';
import ConfirmButton from '../../table/ConfirmButton';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../../form/LoadingIndicator';
import VTextField from '../../form/VTextField';
import { debounce } from 'lodash';
import { ClassifierType, ApplicationDocumentType, ApplicationType } from '../../../constants/classifierConstants';
import ClassifierAutocomplete from '../../form/ClassifierAutocomplete';
import CroppedText from '../../table/CroppedText';
import ApplicationUtils from '../../../utils/ApplicationUtils';
import VDatePicker from '../../form/VDatePicker';
import { saveSubmissionDate } from '../../../stores/application/application';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 416,
    margin: '40px auto',
    paddingBottom: 88
  },
  fileRow: {
    backgroundColor: Colors.hall3,
    borderRadius: 4,
    padding: 15,
    marginBottom: 5
  },
  fileLink: {
    textDecoration: 'none',
    fontWeight: 'bold',
    color: Colors.sinineVaal,
    paddingBottom: 15
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px 0',
    textAlign: 'center'
  }
}));

export default function Step3File({ application }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { rows, isLoading } = useSelector(state => state.applicationFile);

  const applicationId = application.id;
  const authUser = useSelector(state => state.auth.authUser);
  const govCanAddApplication = authUser && ApplicationUtils.isGovCanAddApplication(authUser);
  const govCanAddYPApplication = authUser && ApplicationUtils.isGovCanAddYPApplication(authUser);
  const [submissionDate, setSubmissionDate] = useState();

  useEffect(() => {
    if (application) {
      setSubmissionDate(application.submissionDate);
    }
  }, [application])

  const handleUpload = (files) => {
    if (files && files.length) {
      dispatch(addFiles(application.id, files));
    }
  };
  const handleApplicationFileUpload = (files) => {
    if (files && files.length) {
      let description;
      if (ApplicationType.DP === application.applicationType) {
        description = `Taotlus DP-${applicationId}`;
      } else if (ApplicationType.KT === application.applicationType) {
        description = `Taotlus KT-${applicationId}`
      } else if (ApplicationType.YP === application.applicationType) {
        description = `Taotlus YP-${applicationId}`
      }
      dispatch(addFiles(applicationId, files, description));
    }
  };

  const handleChangeSubmissionDate = (submissionDate) => {
    dispatch(saveSubmissionDate(application.id, submissionDate));
  };

  const handleDescriptionChange = debounce((evt, file) => {
    const description = evt.target.value;
    if (file.description !== description) {
      dispatch(saveFileDescription(applicationId, file.id, description, file.documentType));
    }
  }, 300);

  const handleDocumentTypeChange = debounce((type, file) => {
    if (file.documentType !== type?.itemCode) {
      dispatch(saveFileDescription(applicationId, file.id, file.description, type?.itemCode));
    }
  }, 300);

  if (!rows) {
    return <LoadingIndicator />;
  }

  return <div className={classes.container}>
    {(govCanAddApplication || govCanAddYPApplication) && rows.filter(file => file.documentType === 'TAOTLUS').length === 0 &&
      <FileUpload handleUpload={handleApplicationFileUpload} isLoading={isLoading} applicationUpload />}

    {(govCanAddApplication || govCanAddYPApplication) && rows.map((file, index) => (
      file.documentType === ApplicationDocumentType.application &&

      <Grid container key={index} direction="row" className={classes.fileRow} justifyContent="space-between" alignItems="center">
        <Grid item xs={9}>
          <VDatePicker name="submissionDate" label={t('application.submissionDate')}
            disabled={isLoading} value={submissionDate} onChange={(value) => { setSubmissionDate(value); handleChangeSubmissionDate(value); }} fullWidth required />
        </Grid>

        <Grid item xs={9} container direction="column">
          <a href={getFileDownloadUrl(application.id, file.id)}
            target="_blank" rel="noreferrer"
            className={classes.fileLink}
            title={t('fileUpload.download')}>
            <CroppedText value={file.fileName} length={40} />
          </a>

          <Box paddingBottom={2}>
            <VTextField defaultValue={file.description} label={t('application.applicationFile.description')} disabled />
          </Box>

          <Box paddingBottom={2}>
            <VTextField defaultValue={t('application.fileType.application')} label={t('application.applicationFile.documentType')} disabled />
          </Box>
        </Grid>

        <ConfirmButton message={t('fileUpload.confirmDelete')} icon="delete" size="small"
          onConfirm={() => dispatch(deleteFile(application.id, file.id))} aria-label="delete application file" />
      </Grid>
    ))}

    <FileUpload handleUpload={handleUpload} isLoading={isLoading} />

    {rows.map((file, index) => (
      file.documentType !== ApplicationDocumentType.application &&
      <Grid container key={index} direction="row" className={classes.fileRow} justifyContent="space-between" alignItems="center">
        <Grid item xs={9} container direction="column">
          <a href={getFileDownloadUrl(application.id, file.id)}
            target="_blank" rel="noreferrer"
            className={classes.fileLink}
            title={t('fileUpload.download')}>
            <CroppedText value={file.fileName} length={40} />
          </a>

          <Box paddingBottom={2}>
            <VTextField defaultValue={file.description} label={t('application.applicationFile.description')}
              onChange={(evt) => handleDescriptionChange(evt, file)} maxLength={100} />
          </Box>

          <ClassifierAutocomplete codeValue={file.documentType || null} label={t('application.applicationFile.documentType')}
            handleChange={(value) => handleDocumentTypeChange(value, file)} classifierType={ClassifierType.applicationDocumentType}
            getOptionLabel={(o) => o.title || ''} autoHighlight />
        </Grid>

        <ConfirmButton message={t('fileUpload.confirmDelete')} icon="delete" size="small"
          onConfirm={() => dispatch(deleteFile(application.id, file.id))} aria-label="delete application file" />
      </Grid>
    ))}

    <div className={classes.textContainer}>
      <Paragraph fontSize="14">{t('application.applicationFile.sketchText1')}</Paragraph>
      <Paragraph fontSize="14">{t('application.applicationFile.sketchText2')}</Paragraph>

      {ApplicationType.TPT === application.applicationType && <Box paddingTop={1}>
        <Paragraph fontSize="14B">{t('application.applicationFile.roadPaymentText')}</Paragraph>
      </Box>}
    </div>
  </div>;
}
