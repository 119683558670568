import React, { useEffect, useRef, useState } from 'react';
import PageContainer from '../parts/PageContainer';
import { useTranslation } from 'react-i18next';
import { BasicLabel, Button, Checkbox, Heading, Icon, Search, Shadows, TableHeader } from 'styleguide';
import { Box, ClickAwayListener, FormControlLabel, Grid, IconButton, List, ListItem, ListItemText, makeStyles, Popper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, escapeRegExp } from 'lodash';
import { fetchDocuments, fetchSearchRows, fetchSuggestions, getDocumentLink, resetSuggestions, setTableOpen } from '../stores/search';
import { Domain } from '../constants/classifierConstants';
import SimplePageableTable from '../components/table/SimplePageableTable';
import RegisterUtils from '../utils/RegisterUtils';
import { useHistory } from 'react-router';
import { resetFullAddress, searchAddressAll } from '../stores/address';
import { setExtent } from '../stores/map/map';
import { boundingExtent } from 'ol/extent';
import CroppedText from '../components/table/CroppedText';
import RouteUtils from '../utils/RouteUtils';
import AuthUtils from '../utils/AuthUtils';
import { Authority } from '../constants/authConstants';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 177,
      marginRight: 177
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 4,
      marginRight: 4
    },
  },
  searchBar: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 4,
      paddingRight: 4,
    },
    borderRadius: 8,
    backgroundColor: 'white',
    boxShadow: Shadows.shadow1
  },
  searchInput: {
    [theme.breakpoints.up('md')]: {
      height: 104,
      flexGrow: 1,
    },
    [theme.breakpoints.down('md')]: {
      height: 52,
      width: '100%',
    },
    backgroundColor: 'white',
  },
  resultList: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 8,
    marginTop: 5,
    boxShadow: Shadows.shadow1
  },
  suggestionsPopper: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginLeft: 10,
      paddingLeft: 156,
      paddingRight: 156
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 4,
      paddingLeft: 6,
      paddingRight: 6
    },
  },
  documentsCheckbox: {
    paddingLeft: 50,
    paddingTop: 20
  }
}));

const getOBjectTypeLabel = (row) => {
  if (row.object_type_label) {
    return row.object_type_label;
  }
  const objectType = row.objectType;
  const objectDef = RegisterUtils.getObjectDef(objectType);
  if (objectDef) {
    return `register.${objectType}.label`;
  } else {
    return `search.labels.${objectType}`;
  }
};

export function SimpleCollapsibleTable({ domain = "other", rows, columns, header, pageSize = 5, ...rest }) {
  const dispatch = useDispatch();
  const open = useSelector(state => state.search.tableOpen[domain]) || false;

  const handleToggle = (isOpen) => {
    dispatch(setTableOpen(domain, isOpen));
  };

  return <SimplePageableTable
    rows={open ? rows : rows.slice(0, pageSize)}
    columns={columns}
    header={<TableHeader collapsible={rows.length > pageSize} isOpen={open} onToggleOpen={handleToggle}>
      {header}
      <BasicLabel isNumeric>{rows.length}</BasicLabel>
    </TableHeader>}
    pageSize={200}
    disableLoading
    {...rest}
  />;
}

function DomainTable({ domain, rows }) {
  const { t } = useTranslation();
  const history = useHistory();

  const [domainRows, setDomainRows] = useState([]);

  useEffect(() => {
    const filteredRows = rows?.filter(r => domain ? r.domain === domain : !r.domain);
    setDomainRows(filteredRows);
  }, [rows, domain]);

  if (!domainRows?.length) {
    return <></>;
  }

  const handleRowSelection = (row) => {
    const objectType = row.basic_object_type_id || row.objectType;
    RouteUtils.navigateToObject(history, objectType, row.id);
  };

  const handleShowMap = (row) => {
    const objectType = row.basic_object_type_id || row.objectType;
    RouteUtils.navigateToMap(history, objectType, row.id);
  };

  let columns = [
    { field: 'code', headerName: t('search.table.code'), notSortable: true, },
    {
      field: 'title', headerName: t('search.table.title'), notSortable: true, renderCell: (column, cell) => (
        <CroppedText value={cell.title} length={50} />
      )
    },
    {
      field: 'location', headerName: t('search.table.location'), notSortable: true, renderCell: (column, cell) => <CroppedText value={cell.address} length={50} />
    },
    { field: 'created', headerName: t('search.table.created'), notSortable: true, },
    { field: 'type', headerName: t('search.table.type'), notSortable: true, renderCell: (column, cell) => t(getOBjectTypeLabel(cell)) },
  ];
  if (Domain.plan === domain) {
    columns.push({ field: 'status', headerName: t('search.table.status'), notSortable: true, },);
  }
  columns.push({
    field: 'action', width: 80,
    renderCell: (column, cell) => {
      if (cell.has_geometry) {
        return <IconButton size="small" onClick={(e) => { e.stopPropagation(); handleShowMap(cell); }}>
          <Icon icon="map" />
        </IconButton>;
      }
    }
  });

  return <Box paddingTop={6}>
    <SimpleCollapsibleTable
      domain={domain}
      rows={domainRows}
      columns={columns}
      header={t(`search.table.${domain || 'other'}`)}
      handleRowSelection={handleRowSelection}
    />
  </Box>;
}

function AddressTable({ rows }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  if (!rows.length) {
    return <></>;
  }

  const handleShowMap = (row) => {
    if (row.boundingbox) {
      const coordinates = row.boundingbox.split(' ').map(coordsString => coordsString.split(',').map(c => Number(c)));
      dispatch(setExtent(new boundingExtent(coordinates)));
      history.push('/map');
    }
  };

  let columns = [
    {
      field: 'liikVal', headerName: t('search.table.liikVal'), notSortable: true,
      renderCell: (column, cell) => {
        if (cell.liikVal) {
          return t(`search.table.aadressLiik.${cell.liikVal}`);
        }
      }
    },
    { field: 'ads_oid', headerName: t('search.table.ads_oid'), notSortable: true, },
    { field: 'tunnus', headerName: t('search.table.tunnus'), notSortable: true, },
    { field: 'aadresstekst', headerName: t('search.table.aadresstekst'), notSortable: true, },
    {
      field: 'action', width: 80,
      renderCell: (column, cell) => {
        if (cell.boundingbox) {
          return <IconButton size="small" onClick={(e) => { e.stopPropagation(); handleShowMap(cell); }}>
            <Icon icon="map" />
          </IconButton>;
        }
      }
    },
  ];

  return <Box paddingTop={6}>
    <SimpleCollapsibleTable
      domain="address"
      rows={rows}
      columns={columns}
      header={t('search.table.address')}
    />
  </Box>;
}

function DocumentTable({ rows }) {
  const { t } = useTranslation();

  if (!rows?.length) {
    return <></>;
  }

  let columns = [
    { field: 'Number', headerName: t('search.table.number'), notSortable: true, },
    { field: 'Title', headerName: t('search.table.title'), notSortable: true, },
    { field: 'CreateTime', headerName: t('search.table.created'), notSortable: true, },
    { field: 'DocType', headerName: t('search.table.type'), notSortable: true, },
  ];

  const handleRowSelection = (row) => {
    const url = getDocumentLink(row.Id);
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return <Box paddingTop={6}>
    <SimpleCollapsibleTable
      domain="document"
      rows={rows}
      columns={columns}
      header={t('search.table.documentRegistry')}
      handleRowSelection={handleRowSelection}
    />
  </Box>;
}

function SearchBar({ compact, isLoading }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  let suggestionAnchor = useRef(null);

  const { suggestions } = useSelector(state => state.search);
  const authUser = useSelector(state => state.auth.authUser);

  const [value, setValue] = useState('');
  const [searchDocuments, setSearchDocuments] = useState(false);

  const showDocumentsCheckbox = AuthUtils.hasAuthority(authUser, Authority.UC28_search_documents);

  const handleChange = (newValue) => {
    if (!newValue && suggestions.length) {
      dispatch(resetSuggestions());
    }
    setValue(newValue);
  };

  const handleSuggest = (newValue) => {
    dispatch(fetchSuggestions(newValue));
  };
  const [debounceHandleSuggest] = useState(() => debounce(handleSuggest, 200, { 'maxWait': 500 }));

  const handleSelect = (newValue) => {
    setValue(newValue);
    handleSearch(newValue);
  };

  const handleSearch = (newValue) => {
    const term = newValue || value;
    dispatch(resetSuggestions());
    dispatch(fetchSearchRows(term));

    dispatch(resetFullAddress());
    dispatch(searchAddressAll(term));

    if (showDocumentsCheckbox && searchDocuments) {
      dispatch(fetchDocuments(term));
    }
  };

  const highlightedSuggestion = (text) => {
    if (!value.trim()) {
      return <span>{text}</span>
    }
    const regex = new RegExp(`(${escapeRegExp(value)})`, 'gi')
    const parts = text.split(regex)
    return (
      <span>
        {parts.filter(part => part).map((part, i) => (
          regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>
        ))}
      </span>
    )
  };

  return <>
    <Grid container direction="row" alignItems="center" className={classes.searchBar} ref={suggestionAnchor}>
      <Search
        className={classes.searchInput}
        placeholder={t('addressSearch.inputAddressName')}
        onChange={debounceHandleSuggest}
        onChangeInput={handleChange}
        onEnter={handleSearch}
        minCharacters={3}
        value={value}
        disabled={isLoading}
      />
      {!compact && <Button onClick={() => handleSearch()} size="small" disabled={isLoading}>
        {!!isLoading ? <Icon icon="loading" /> : t('button.search')}
      </Button>}
    </Grid>

    {showDocumentsCheckbox &&
      <FormControlLabel
        label={t('search.documentSearch')}
        control={<Checkbox
          onChange={(e) => setSearchDocuments(e.target.checked)}
          disabled={isLoading}
          checked={searchDocuments}
        />}
        className={classes.documentsCheckbox}
      />
    }

    <Popper open={!!suggestions.length} anchorEl={suggestionAnchor.current} placement="bottom-start" className={classes.suggestionsPopper}>
      <ClickAwayListener onClickAway={() => dispatch(resetSuggestions())}>
        <List dense className={classes.resultList}>
          {suggestions.map((suggestion, index) => (
            <ListItem key={index} button onClick={() => handleSelect(suggestion)}>
              <ListItemText secondary={highlightedSuggestion(suggestion)} />
            </ListItem>
          ))}
        </List>
      </ClickAwayListener>
    </Popper>
  </>;
}

export default function SearchPage() {
  const { t } = useTranslation();

  const rows = useSelector(state => state.search.rows);
  const isLoading = useSelector(state => state.search.isLoading);
  const isLoadingDocuments = useSelector(state => state.search.isLoadingDocuments);
  const documents = useSelector(state => state.search.documents);
  const addresses = useSelector(state => state.address.fullRows);
  const isLoadingAddresses = useSelector(state => state.address.isLoading);
  const compact = useSelector(state => state.global.mobileView);

  useEffect(() => {
    RouteUtils.setDocumentTitle('search');
  }, []);

  const isLoadingRows = isLoading || isLoadingAddresses || isLoadingDocuments;
  const noResults = !isLoadingRows && rows && rows.length === 0 && addresses?.length === 0 && !documents;

  return <PageContainer>
    <SearchBar compact={compact} isLoading={isLoadingRows} />

    {noResults &&
      <Box padding={6}>
        <Heading level="3">{t('table.noRows')}</Heading>
      </Box>
    }

    <AddressTable rows={addresses} />
    <DomainTable rows={rows} domain={Domain.plan} />
    <DomainTable rows={rows} />
    <DomainTable rows={rows} domain={Domain.rainwater} />
    <DomainTable rows={rows} domain={Domain.road} />
    <DomainTable rows={rows} domain={Domain.streetlight} />
    <DocumentTable rows={documents} />
  </PageContainer>;
}