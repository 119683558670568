import { clone } from "lodash";
import CircleStyle from "ol/style/Circle";
import Circle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import Text from "ol/style/Text";
import { Colors } from "styleguide";
import MapUtils from "../../utils/MapUtils";


const LayerStyles = {
  activeFeature: new Style({
    image: new Circle({
      radius: 6,
      fill: new Fill({
        color: Colors.withOpacity(Colors.loojang, 0.3)
      }),
      stroke: new Stroke({
        color: Colors.loojang,
        width: 4
      })
    }),
    stroke: new Stroke({
      color: Colors.loojang,
      width: 4,
    }),
    fill: new Fill({
      color: Colors.withOpacity(Colors.loojang, 0.3),
    }),
  }),
  activeFeatureSecondary: new Style({
    image: new Circle({
      radius: 6,
      fill: new Fill({
        color: Colors.withOpacity(Colors.sinineVaal, 0.3)
      }),
      stroke: new Stroke({
        color: Colors.sinineVaal,
        width: 4
      })
    }),
    stroke: new Stroke({
      color: Colors.sinineVaal,
      width: 4,
    }),
    fill: new Fill({
      color: Colors.withOpacity(Colors.sinineVaal, 0.3),
    }),
  }),
  activeFeatureTertiary: new Style({
    image: new Circle({
      radius: 6,
      fill: new Fill({
        color: Colors.withOpacity(Colors.lainehari, 0.3)
      }),
      stroke: new Stroke({
        color: Colors.lainehari,
        width: 4
      })
    }),
    stroke: new Stroke({
      color: Colors.lainehari,
      width: 4,
    }),
    fill: new Fill({
      color: Colors.withOpacity(Colors.lainehari, 0.3),
    }),
  }),
  activeFeatureQuaternary: new Style({
    image: new Circle({
      radius: 6,
      fill: new Fill({
        color: Colors.withOpacity(Colors.lavendel, 0.3)
      }),
      stroke: new Stroke({
        color: Colors.lavendel,
        width: 4
      })
    }),
    stroke: new Stroke({
      color: Colors.lavendel,
      width: 4,
    }),
    fill: new Fill({
      color: Colors.withOpacity(Colors.lavendel, 0.3),
    }),
  }),
  activeNoFillFeature: new Style({
    image: new Circle({
      radius: 6,
      stroke: new Stroke({
        color: Colors.loojang,
        width: 4
      })
    }),
    stroke: new Stroke({
      color: Colors.loojang,
      width: 6,
    }),
  }),
  drawingFeature: new Style({
    image: new Circle({
      radius: 5,
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)',
      }),
      stroke: new Stroke({
        color: Colors.lavendel,
        width: 2
      })
    }),
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.2)',
    }),
    stroke: new Stroke({
      color: Colors.withOpacity(Colors.lavendel, 0.7),
      lineDash: [10, 10],
      width: 2,
    }),
  }),
  //takes rgba object ({r: 153, g: 0, b: 239, a: 1})
  drawedFeatureWithCustomColor: (color, thin) => {
    if (!color) {
      return LayerStyles.drawedFeature();
    }
    const solidColor = MapUtils.convertToRgbString(color);
    let lightColor = clone(color);
    lightColor.a = 0.3
    const lighterColor = MapUtils.convertToRgbString(lightColor);
    const width = thin ? 2 : 5;
    const lineDash = [5, 5];

    return new Style({
      image: new Circle({
        radius: 8,
        fill: new Fill({
          color: lighterColor
        }),
        stroke: new Stroke({
          color: solidColor,
          lineDash,
          width
        })
      }),
      stroke: new Stroke({
        color: solidColor,
        lineDash,
        width
      }),
      fill: new Fill({
        color: lighterColor,
      }),
    });
  },
  drawedFeature: new Style({
    image: new Circle({
      radius: 8,
      fill: new Fill({
        color: Colors.withOpacity(Colors.lavendel, 0.3)
      }),
      stroke: new Stroke({
        color: Colors.lavendel,
        width: 5
      })
    }),
    stroke: new Stroke({
      color: Colors.lavendel,
      width: 5
    }),
    fill: new Fill({
      color: Colors.withOpacity(Colors.lavendel, 0.3),
    }),
  }),
  highlightFeature: new Style({
    image: new Circle({
      radius: 8,
      fill: new Fill({
        color: Colors.withOpacity(Colors.maismaa, 0.3)
      }),
      stroke: new Stroke({
        color: Colors.maismaa,
        width: 6
      })
    }),
    stroke: new Stroke({
      color: Colors.maismaa,
      width: 6,
    }),
    fill: new Fill({
      color: Colors.withOpacity(Colors.maismaa, 0.3),
    }),
  }),
  selectedFeature: new Style({
    image: new Circle({
      radius: 10,
      fill: new Fill({
        color: Colors.withOpacity(Colors.lavendel, 0.4)
      }),
      stroke: new Stroke({
        color: Colors.lavendel,
        width: 6
      })
    }),
    stroke: new Stroke({
      color: Colors.lavendel,
      lineDash: [20, 10],
      width: 6
    }),
    fill: new Fill({
      color: Colors.withOpacity(Colors.lavendel, 0.4),
    }),
  }),
  selectingMeasuring: new Style({
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.2)',
    }),
    stroke: new Stroke({
      color: Colors.withOpacity(Colors.vagaVesi, 0.5),
      lineDash: [10, 10],
      width: 2,
    }),
    image: new CircleStyle({
      radius: 5,
      stroke: new Stroke({
        color: Colors.withOpacity(Colors.vagaVesi, 0.7)
      }),
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)',
      }),
    }),
  }),
  measure: new Style({
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.2)',
    }),
    stroke: new Stroke({
      color: Colors.vagaVesi,
      width: 2,
    }),
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({
        color: Colors.vagaVesi,
      }),
    }),
  }),
  textOnly(text) {
    return new Style({
      text: new Text({
        text: text,
        scale: 2,
        fill: new Fill({
          color: Colors.vagaVesi
        }),
        stroke: new Stroke({
          color: 'white',
          width: 2.5
        })
      })
    });
  }
}

export default LayerStyles;