import React, { useEffect } from 'react';
import { BasicLabel } from 'styleguide';
import PageContainer from '../parts/PageContainer';
import { useTranslation } from 'react-i18next';
import UserTable from '../components/admin/UserTable';
import { NavLink, useLocation, Redirect } from 'react-router-dom';
import UserForm from '../components/admin/UserForm';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import RoleTable from '../components/admin/RoleTable';
import RoleForm from '../components/admin/RoleForm';
import ClassifierTable from '../components/admin/ClassifierTable';
import ClassifierForm from '../components/admin/ClassifierForm';
import AuthUtils from '../utils/AuthUtils';
import SecureRoute from '../components/auth/SecureRoute';
import { Authority } from '../constants/authConstants';
import BasicObjectTypeTable from '../components/admin/BasicObjectTypeTable';
import BasicObjectTypeForm from '../components/admin/BasicObjectTypeForm';
import ApplicationTypeTable from '../components/admin/ApplicationTypeTable';
import ApplicationTypeForm from '../components/admin/ApplicationTypeForm';
import PartnerTable from '../components/admin/PartnerTable';
import PartnerForm from '../components/admin/PartnerForm';
import PartnerUserForm from '../components/admin/PartnerUserForm';
import ActivityLogView from '../components/admin/ActivityLogView';
import NavigationTabs from '../components/table/NavigationTabs';
import ClauseConfForm from '../components/admin/ClauseConfForm';
import RouteUtils from '../utils/RouteUtils';
import MapLayersTable from '../components/admin/MapLayerTable';
import MapLayerForm from '../components/admin/MapLayerForm';

export default function Admin() {
  const { t } = useTranslation();

  const { totalElements: userTotalElements } = useSelector(state => state.user);
  const { rows: roles } = useSelector(state => state.role);
  const { authUser } = useSelector(state => state.auth);
  const { classifiers } = useSelector(state => state.classifier);
  const { rows: basicObjectTypeRows } = useSelector(state => state.basicObjectType);
  const { rows: applicationTypeRows } = useSelector(state => state.applicationType);
  const { totalElements: partnerTotalElements } = useSelector(state => state.partner);
  const { rows: mapLayerRows } = useSelector(state => state.mapLayer);

  useEffect(() => {
    RouteUtils.setDocumentTitle('admin');
  }, []);

  let navTabs = [];
  if (AuthUtils.hasAuthority(authUser, Authority.UC22_admin_users)) {
    navTabs.push({
      to: "/admin/users",
      content: <span>
        {t('admin.usersTitle')}
        {!!userTotalElements && <BasicLabel isNumeric>{userTotalElements}</BasicLabel>}
      </span>
    });
  }
  if (AuthUtils.hasAuthority(authUser, Authority.UC20_admin_roles)) {
    navTabs.push({
      to: "/admin/roles",
      content: <span>
        {t('admin.rolesTitle')}
        {!!roles?.length && <BasicLabel isNumeric>{roles.length}</BasicLabel>}
      </span>
    });
  }
  if (AuthUtils.hasAuthority(authUser, Authority.UC21_admin_partners)) {
    navTabs.push({
      to: "/admin/partners",
      content: <span>
        {t('admin.partnersTitle')}
        {!!partnerTotalElements && <BasicLabel isNumeric>{partnerTotalElements}</BasicLabel>}
      </span>
    });
  }
  if (AuthUtils.hasAuthority(authUser, Authority.UC24_admin_classifiers)) {
    navTabs.push({
      to: "/admin/classifiers",
      content: <span>
        {t('admin.classifiersTitle')}
        {classifiers && classifiers.length > 0 && <BasicLabel isNumeric>{classifiers.length}</BasicLabel>}
      </span>
    });
  }
  if (AuthUtils.hasAuthority(authUser, Authority.UC5_admin_application_types)) {
    navTabs.push({
      to: "/admin/applicationTypes",
      content: <span>
        {t('admin.applicationsTitle')}
        {!!applicationTypeRows?.length && <BasicLabel isNumeric>{applicationTypeRows.length}</BasicLabel>}
      </span>
    });
  }
  if (AuthUtils.hasAuthority(authUser, Authority.UC30_admin_basic_object_types)) {
    navTabs.push({
      to: "/admin/basicObjectTypes",
      content: <span>
        {t('admin.basicObjectType.label')}
        {!!basicObjectTypeRows?.length && <BasicLabel isNumeric>{basicObjectTypeRows.length}</BasicLabel>}
      </span>
    });
  }
  if (AuthUtils.hasAuthority(authUser, Authority.UC33_admin_logs)) {
    navTabs.push({
      to: "/admin/logs",
      content: <span>
        {t('admin.logsTitle')}
      </span>
    });
  }
  if (AuthUtils.hasAuthority(authUser, Authority.UC36_admin_map_layers)) {
    navTabs.push({
      to: "/admin/mapLayers",
      content: <span>
        {t('admin.mapLayersTitle')}
        {!!mapLayerRows?.length && <BasicLabel isNumeric>{mapLayerRows.length}</BasicLabel>}
      </span>
    });
  }

  const location = useLocation();

  if (navTabs.length === 0) {
    return <Redirect to="/" />;
  }

  if (location.pathname === '/admin') {
    //redirect to first allowed page
    return <Redirect to={navTabs[0].to} />
  }

  return <PageContainer>
    <NavigationTabs>
      {navTabs.map((tab, index) => <NavLink key={index} to={tab.to}>{tab.content}</NavLink>)}
    </NavigationTabs>

    <Box my={5}>
      <SecureRoute path="/admin/users" component={UserTable} authority={Authority.UC22_admin_users} />
      <SecureRoute path="/admin/user/:userId?" component={UserForm} authority={Authority.UC22_admin_users} />

      <SecureRoute path="/admin/roles" component={RoleTable} authority={Authority.UC20_admin_roles} />
      <SecureRoute path="/admin/role/:roleId?" component={RoleForm} authority={Authority.UC20_admin_roles} />

      <SecureRoute path="/admin/partners" component={PartnerTable} authority={Authority.UC21_admin_partners} />
      <SecureRoute path="/admin/partner/:partnerId?" component={PartnerForm} authority={Authority.UC21_admin_partners} />
      <SecureRoute path="/admin/partner/:partnerId?/user/:userId?" component={PartnerUserForm} authority={Authority.UC21_admin_partners} />

      <SecureRoute path="/admin/classifiers/:typeCode?" component={ClassifierTable} authority={Authority.UC24_admin_classifiers} />
      <SecureRoute path="/admin/classifier/:classifierId?/:typeCode?" component={ClassifierForm} authority={Authority.UC24_admin_classifiers} />

      <SecureRoute path="/admin/basicObjectTypes" component={BasicObjectTypeTable} authority={Authority.UC30_admin_basic_object_types} />
      <SecureRoute path="/admin/basicObjectType/:id?" component={BasicObjectTypeForm} authority={Authority.UC30_admin_basic_object_types} />

      <SecureRoute path="/admin/applicationTypes" component={ApplicationTypeTable} authority={Authority.UC5_admin_application_types} />
      <SecureRoute path="/admin/applicationType/:id?" component={ApplicationTypeForm} authority={Authority.UC5_admin_application_types} />
      <SecureRoute path="/admin/clauseConf/:applicationType" component={ClauseConfForm} authority={Authority.UC5_admin_application_types} />

      <SecureRoute path="/admin/logs" component={ActivityLogView} authority={Authority.UC33_admin_logs} />

      <SecureRoute path="/admin/mapLayers" component={MapLayersTable} authority={Authority.UC36_admin_map_layers} />
      <SecureRoute path="/admin/mapLayer/:id?" component={MapLayerForm} authority={Authority.UC36_admin_map_layers} />
    </Box>
  </PageContainer>;
}