import { Grid, makeStyles } from "@material-ui/core";
import { cloneDeep } from "lodash";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { resetSubmitted, saveParticipant } from "../../stores/planning/planningParticipant";
import DialogForm from "../form/DialogForm";
import VTextField from "../form/VTextField";
import AddressSearchTextField from "../form/AddressSearchTextField";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    [theme.breakpoints.up('md')]: {
      width: 500,
      margin: 'auto'
    },
  },
}));

export default function ParticipantForm() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { id, participantId } = useParams();
  const { rows: participants, isLoading, submitted } = useSelector(state => state.planningParticipant);

  const participant = participants.find(p => p.id === Number(participantId));

  const { register, errors, handleSubmit, setValue } = useForm({
    defaultValues: participant || {}
  });

  useEffect(() => submitted && dispatch(resetSubmitted()) && history.goBack(), [submitted, dispatch, history]);

  const onSubmit = (inputData) => {
    let data = cloneDeep(inputData);
    dispatch(saveParticipant(id, participantId, data));
  };

  return <DialogForm
    title={t('planning.view.editParticipant', { name: participant.fullName })}
    onSubmit={handleSubmit(onSubmit)}
    disabled={isLoading}
  >
    <Grid container direction="column" spacing={4} justifyContent="center" className={classes.formContainer}>
      <Grid item>
        <VTextField name="email" label="plan.party.email"
          disabled={isLoading} maxLength={100} pattern="email" required
          register={register} errors={errors} fullWidth
        />
      </Grid>
      <Grid item>
        <VTextField name="phone" label={t('plan.party.phone')}
          disabled={isLoading} maxLength={100} pattern="phone"
          register={register} errors={errors} fullWidth
        />
      </Grid>
      <Grid item>
        <AddressSearchTextField name="address" label={t('plan.party.address')}
          disabled={isLoading} setValue={setValue} fullWidth 
          register={register} errors={errors}/>
      </Grid>
    </Grid>
  </DialogForm>;
}
