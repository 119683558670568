import React, { useRef, useState, useEffect } from "react";
import "./Map.css";
import MapContext from "./MapContext";
import * as ol from "ol";
import { useDispatch, useSelector } from "react-redux";
import { setCenterZoom } from "../../stores/map/map";
import { MapConstants } from "../../constants/mapConstants";
import MapUtils from "../../utils/MapUtils";
import { defaults as defaultInteractions } from "ol/interaction.js";

const Map = ({ children, zoom, center, extent, disablePan }) => {
  const mapRef = useRef();
  const dispatch = useDispatch();
  const mobile = useSelector(state => state.global.mobileView);

  const [map, setMap] = useState(null);

  // on component mount
  useEffect(() => {
    let resolutions = [];
    let scalesLength = MapUtils.scales.length;
    if (mobile) {
      // smallest resolution has problems rendering on mobile
      scalesLength--;
    }
    for (var i = 0; i < scalesLength; i++) {
      resolutions.push(MapUtils.getResolutionForScale(MapUtils.scales[i]));
    }
    const extent = MapConstants.extent;
    let options = {
      view: new ol.View({
        projection: MapUtils.projection,
        extent,
        enableRotation: false,
        constrainResolution: true,
        resolutions
      }),
      interactions: defaultInteractions({
        dragAndDrop: !disablePan,
        dragPan: !disablePan,
        keyboardPan: !disablePan,
        mouseWheelZoom: !disablePan,
      }),
      layers: [],
      controls: [],
      overlays: [],
      maxTilesLoading: 5
    };
    let mapObject = new ol.Map(options);
    mapObject.setTarget(mapRef.current);

    setMap(mapObject);
    return () => {
      const view = mapObject.getView();
      dispatch(setCenterZoom(view.getCenter(), view.getZoom()));
      mapObject.setTarget(undefined);
    }
  }, [dispatch, disablePan, mobile]);

  // zoom change handler
  useEffect(() => {
    if (!map) return;
    map.getView().setZoom(zoom);
  }, [map, zoom]);

  // center change handler
  useEffect(() => {
    if (!map) return;
    map.getView().setCenter(center);
  }, [map, center]);

  // extent change handler
  useEffect(() => {
    if (!map || !extent) return;
    const mapSize = map.getSize();
    //const paddings = [mapSize[0] * 0.1, mapSize[1] * 0.1];
    map.getView().fit(extent, {
      //padding: [paddings[0], paddings[1], paddings[0], paddings[1]],
      nearest: false,
      size: mapSize,
      maxZoom: 9
    });
  }, [map, extent]);

  useEffect(() => {
    map && map.updateSize();
  });

  return (
    <MapContext.Provider value={{ map }}>
      <div ref={mapRef} className="ol-map">
        {children}
      </div>
    </MapContext.Provider>
  )
}
export default Map;